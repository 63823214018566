import { Link, useHistory } from "react-router-dom";
import { ContainerWrapper } from './NotFoundPage.styled';
import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import doneAnimation from "../../assets/animations/done.json";
import logo from "../../images/logo0.2.png";
import { Button } from "../UI/Button/Button.styled";

export default function NotFound(props) {
    const {info} = props;
    let history = useHistory();
    return (
        <ContainerWrapper>
            <Grid container>
                <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                    <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <br /><h1>Coming Soon!</h1><br />
                    {info}
                    <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/home")}>
                        Back To Dashboard
                    </Button>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </ContainerWrapper>
    )
}