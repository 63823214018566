import { Alert, Box, Grid, Grow, MenuItem, Select } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./HealthCheck.styled";
import { getActiveHealthCheck, getCompletedHealthCheck, getCompletedHealthCheckList, getHealthCheckDuration, getProcessVariables } from "../../InformationAPI";
import { useKeycloak } from "@react-keycloak/web";
import logo from "../../../../images/logo0.2.png";
import { CardHead, DotSign, ProgressBlock, ProgressCard, ProgressValue, StatsText, TextBlock } from "../Information/Information.styled";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { QuestionNumber } from "../../../../components/Question/QuestionHead/QuestionHead.styled";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ThemeContext from "../../../../store/theme-context";
import { GraphContainer } from "./HealthCheckLandingPage.styled";
import { getDMN } from "../TRA/TRAApi";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { Helmet } from "react-helmet-async";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import LineChart from "../../../../components/Charts/LineChart";

function HealthCheckLandingPage(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [userType, setUserType] = useState(null);
    const [header, setHeader] = useState(null);

    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState("");

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [isHistoryLoading, setHistoryLoading] = useState(true);

    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);
    const [healthCheckCompleted, setHealthCheckCompleted] = useState(false);
    const [totalScore, setTotalScore] = useState(null);
    const [duration, setDuration] = useState({ "remainingDays": 0, "maxDays": 14 });
    const [maturity, setMaturity] = useState({ "currentScore": [0, 0, 0, 0, 0], "requiredScore": [5, 5, 5, 5, 5] });
    const [completedHealthCheckList, setcompletedHealthCheckList] = useState([]);
    const [chartHistoryData, setChartHistoryData] = useState({ labels: [], datasets: [] });
    const [historyLabels, setHistoryLabels] = useState([]);
    const { mode } = useContext(ThemeContext);
    const [userQuestions, setUserQuestions] = useState(null);
    const [selectedHealthCheckID, setSelectedHealthCheckID] = useState(null);
    const [selectedHealthCheckChartData, setSelectedHealthCheckChartData] = useState({
        options: {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: "line",
                            yMin: 3,
                            yMax: 3,
                            borderColor: mode === 'light' ? '#000' : '#fff',
                            borderDash: [8, 8],
                            borderWidth: 1,
                        },
                    },
                },
                tooltip: {
                    enabled: false // <-- this option disables tooltips
                }
            },
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    lineThickness: 0,
                    tickThickness: 0,
                    stacked: true,
                    ticks: { color: mode === 'light' ? '#000' : '#fff', beginAtZero: true }
                },
                y: {
                    grid: {
                        display: false,
                    },
                    lineThickness: 0,
                    tickThickness: 0,
                    stacked: true,
                    ticks: {
                        min: 0,
                        color: mode === 'light' ? '#000' : '#fff', beginAtZero: true,
                        max: 5,
                        stepSize: 1,
                    },
                },
            },
        },
        data: {
            labels: ["Identify", "Protect", "Detect", "Respond", "Recover"],
            offset: true,
            responsive: true,
            datasets: [
                {
                    label: "Current Maturity",
                    barThickness: 50,
                    data: [0, 0, 0, 0, 0],
                    datalabels: {
                        anchor: "center",
                        color: "#fff",
                        font: {
                            size: 16,
                            weight: "bold"
                        }
                    },
                    backgroundColor: "green",
                },
                {
                    label: "Work Remaining",
                    data: [5, 5, 5, 5, 5],
                    barThickness: 50,
                    datalabels: {
                        labels: {
                            title: {
                                color: "red",
                            },
                        },
                    },
                    backgroundColor: "red",
                },
            ],
        },
    });

    //line chart data
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
    };

    var labels = completedHealthCheckList.map(function (item) { return item.date });

    const data = {
        labels,
        datasets: [
            {
                label: 'Health check score',
                data: completedHealthCheckList.map(function (item) { return item.score }),
                borderColor: '#ED1E24',
                backgroundColor: '#ED1E24',
            },
        ],
    };

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);
    ChartJS.defaults.plugins.legend = {
        display: false,
    };

    const maturityBarOptions = {
        maintainAspectRatio: false,

        plugins: {
            title: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        yMin: 3,
                        yMax: 3,
                        borderColor: mode === 'light' ? '#000' : '#fff',
                        borderDash: [8, 8],
                        borderWidth: 1,
                    },
                },
            },
            tooltip: {
                enabled: false // <-- this option disables tooltips
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: { color: mode === 'light' ? '#000' : '#fff', beginAtZero: true }
            },
            y: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: {
                    min: 0,
                    color: mode === 'light' ? '#000' : '#fff', beginAtZero: true,
                    max: 5,
                    stepSize: 1,
                },
            },
        },
    };

    const maturityBarData = {
        labels: ["Identify", "Protect", "Detect", "Respond", "Recover"],
        red: {
            dataSet: maturity.requiredScore,
        },
        green: {
            dataSet: maturity.currentScore,
        },
    };

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Rapid Cyber Security Health Check',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const healthCheckNavHandler = () => history.push("/it-security/health-check/health-check-progress");
    const recommendationsNavHandler = () => history.push("/it-security/recommendations");

    useEffect(() => {
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    function handleNextStage() {
        return healthCheckNavHandler;
    }

    async function handleNewInstance() {
        //function to start new instance
        if (activeHealthCheckID > 0) return;
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${HealthCheckProcess}/instances`,
                data: JSON.stringify({}),
                ...header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.data !== "") {
                    const createdInst = res.data;
                    setActiveHealthCheckID(createdInst);
                } else {
                    toast.error("Failed to start health check");
                }
                setLoading(false);
            });
        } catch (error) {
            toast.error("Failed to start health check");
            setLoading(false);
        }
    }

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
                setUserType(res?.user_type || "");

            });
        }
    }, [keycloak])

    const getHealthCheckQuestions = useCallback(async (taskInstId, taskName, _header, uQuestions, uType) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/contents/input`,
                ..._header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const str = res.data["TaskName"];
                let questionNum = +str.replace(/\D/g, "");
                let currentQuestionNum = +str.replace(/\D/g, "");
                if (questionNum === 33 && str === "AnswerSC33-identify") {
                    questionNum = 33.1;
                    currentQuestionNum = 33;
                } else if (questionNum === 33 && str === "AnswerSC33-protect") {
                    questionNum = 33.2;
                    currentQuestionNum = 34;
                } else if (questionNum === 33 && str === "AnswerSC33-detect") {
                    questionNum = 33.3;
                    currentQuestionNum = 35;
                } else if (questionNum === 33 && str === "AnswerSC33-respond") {
                    questionNum = 33.4;
                    currentQuestionNum = 36;
                } else if (questionNum === 33 && str === "AnswerSC33-recover") {
                    questionNum = 33.5;
                    currentQuestionNum = 37;
                } else if (res.data["NodeName"] === "Presentation Date") {
                    setFetching(false);
                    setHealthCheckCompleted(true);
                    return;
                }
                setQuestionNumber(questionNum);
                setCurrentQuestion(currentQuestionNum);
                const fetchedQuestions = {};
                for (let key in res.data) {
                    if (key.startsWith("Q-")) {
                        fetchedQuestions[key] = res.data[key];
                    }
                }
                const orderedQuestionsKeys = Object.keys(fetchedQuestions).sort();
                let orderedQuestions = orderedQuestionsKeys.map((key, index) => {
                    let questionObj = {
                        id: key,
                        label: fetchedQuestions[key],
                        qId: `#main-${questionNum}-${index + 1}`,
                        qNum: `${questionNum}.${index + 1}`,
                        qHeading: fetchedQuestions[key],
                        qDescription: null,
                        qAnswer: null,
                        qAvailableAnswers: ["Fully", "Partially", "Not at all", "Not applicable"],
                        qType: "mcq",
                    };
                    if (questionNum > 32) {
                        questionObj.qAvailableAnswers = ["Yes", "No"];
                    }
                    return questionObj;
                });

                setQuestions([
                    {
                        qId: `#main-${questionNum}`,
                        qNum: `${questionNum}`,
                        status: "pending",
                        touched: false,
                        title: uType == "novice" && uQuestions ? uQuestions[currentQuestionNum - 1]?.qHeading : taskName,
                        qType: "unit",
                        hasDesc: true,
                        qDescription: "The government has a number of different funding options for cyber security activities. This includes:",
                        subQuestions: orderedQuestions,
                        ans1Index: orderedQuestions[0].qAvailableAnswers.length,
                        helpText: res.data["help-text"]
                    },
                ]);
                setFetching(false);
            });
        } catch (err) {
            setFetching(false);
        }
    }, []);

    const getHealthCheckTask = useCallback(
        async (healthInstance, _header, uQuestions, uType) => {
            try {
                let config = {
                    method: 'get',
                    url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${healthInstance}`,
                    ..._header,
                }
                await fetchInstance.apiRequest(config).then((res) => {
                    if (res.status === 200) {
                        const taskInstId = res.data["active-user-tasks"]["task-summary"][0]["task-id"];
                        const taskIName = res.data["active-user-tasks"]["task-summary"][0]["task-name"];
                        setTaskInstanceId(taskInstId);
                        setLoading(false);
                        getHealthCheckQuestions(taskInstId, taskIName, _header, uQuestions, uType);
                        // if (res.data["active-user-tasks"]["task-summary"][0]["task-status"] !== "InProgress") {
                        //     fetch.put(`/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/states/started`, {}, _header);
                        // }
                    }
                });

            } catch (error) {
                // toast.error(error.message);
                setLoading(false);
            }
        },
        []
    );

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID]);

    useEffect(() => {
        if (!userType) return;
        if (userType === "novice") {
            let nvQuestionKey = "noviceQuestionsRHC";
            let nvQuestionsString = sessionStorage.getItem(nvQuestionKey);
            if (nvQuestionsString) {
                let nvQuestionObj = JSON.parse(nvQuestionsString);
                setUserQuestions(nvQuestionObj);
            } else {
                getDMN(CONTAINER_ID, keycloak.token, nvQuestionKey).then((res) => {
                    if (res.success) {
                        const qData = res.data?.dmnContext?.questions;
                        if (qData) {
                            sessionStorage.setItem(nvQuestionKey, JSON.stringify(qData))
                            setUserQuestions(qData);
                        }
                    }
                });
            }
        } else {
            setUserQuestions([]);
        }
    }, [userType])

    useEffect(() => {

        // if (healthCheckCompleted) {
        //     return;
        // }

        if (activeHealthCheckID === null || header === null || userType === null || userQuestions === null) {
            setLoading(false);
            return;
        };

        if (activeHealthCheckID > 0 && userType !== null && userQuestions !== 0) {
            setLoading(true);
            getHealthCheckTask(activeHealthCheckID, header, userQuestions, userType);
            return;
        } else {
            setFetching(false);
        }
        // else {
        //     setLoading(true);
        //     createNewHealthCheckInstance(header);
        //     return;
        // }
    }, [activeHealthCheckID, healthCheckCompleted, header, userQuestions]);

    useEffect(() => {
        if (activeHealthCheckID === null || kcToken === null) return;
        getProcessVariables(activeHealthCheckID, kcToken).then((response) => {
            const score = response.data.filter(obj => { return obj.name === "TotalScore" })[0] ?? null;
            setTotalScore(Math.round(score?.["value"] ?? 0));

            const startDateMilli = response.data.filter(obj => { return obj.name === "StartDate" })[0];
            setDuration(getHealthCheckDuration(startDateMilli?.["value"] ?? 0));
        });

    }, [activeHealthCheckID, kcToken]);

    useEffect(() => {
        // when completed health check list is updated and no health check is selected then by default select the last health check
        if (completedHealthCheckList?.length > 0) {
            if (!selectedHealthCheckID) {
                let lastHealthCheck = completedHealthCheckList[completedHealthCheckList.length - 1];
                setSelectedHealthCheckID(lastHealthCheck?.id);
                setSelectedHealthCheckChartData(
                    (prev) => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            datasets: prev.data.datasets.map((dataset) => {
                                if (dataset.backgroundColor === "green") {
                                    return {
                                        ...dataset,
                                        data: lastHealthCheck.maturity.map((score) => score)
                                    }
                                } else {
                                    return {
                                        ...dataset,
                                        data: lastHealthCheck.maturity.map((score) => (5 - score))
                                    }
                                }
                            })
                        }
                    })
                )
            }
        }
    }, [completedHealthCheckList, selectedHealthCheckID])

    const handleChangeHealthCheck = useCallback((e) => {
        let healthCheckID = e.target.value;
        let foundHealthCheck = completedHealthCheckList.find((h) => h.id === healthCheckID);
        if (foundHealthCheck) {
            setSelectedHealthCheckID(foundHealthCheck?.id);
            setSelectedHealthCheckChartData(
                (prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        datasets: prev.data.datasets.map((dataset) => {
                            if (dataset.backgroundColor === "green") {
                                return {
                                    ...dataset,
                                    data: foundHealthCheck.maturity.map((score) => score)
                                }
                            } else {
                                return {
                                    ...dataset,
                                    data: foundHealthCheck.maturity.map((score) => (5 - score))
                                }
                            }
                        })
                    }
                })
            )
        }
    }, [completedHealthCheckList]);

    useEffect(() => {
        function handleMaturity(processID) {
            if (processID === null || kcToken === null) return;
            getProcessVariables(processID, kcToken).then((response) => {
                if (response.data === undefined) return;
                const score = response.data.filter(obj => { return obj.name === "CurrentMaturity" })[0];
                var scoreArray = [];
                if (score) {
                    const scoreString = score["value"].slice(1, -1); //remove brackets

                    scoreString.split(',').forEach(currentScore => {
                        scoreArray.push(Math.floor(currentScore));
                    });
                    var requireScoreArray = [];
                    scoreArray.forEach(currentScore => {
                        requireScoreArray.push(5 - currentScore);
                    });

                    setMaturity({
                        "currentScore": scoreArray,
                        "requiredScore": requireScoreArray
                    });
                };

                if (scoreArray.length === 0) {
                    if (completedHealthCheckID > 0) {
                        handleMaturity(completedHealthCheckID);
                    }
                }

            });
        }

        handleMaturity(activeHealthCheckID);
    }, [completedHealthCheckID, kcToken, activeHealthCheckID]);

    function getAllInstance(_email, _kcToken) {
        if (_email === null || _kcToken === null) {
            return;
        }

        setHeader({ headers: { "Authorization": `Bearer ${_kcToken}`, 'Content-Type': 'application/json' } });

        getActiveHealthCheck(_kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });

        getCompletedHealthCheck(_kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });

        getCompletedHealthCheckList(_kcToken, _email).then(async (response) => {
            setcompletedHealthCheckList(response.data);

            let labels = response.data.map(function (item) { return item.date });

            let data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Health check score',
                        data: response.data.map(function (item) { return item.score }),
                        borderColor: '#ED1E24',
                        backgroundColor: '#ED1E24',
                    },
                ],
            };

            setChartHistoryData(data);

            setHistoryLoading(false);
        });
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClick2 = () => {
        setModal2Open(true);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    const handleOneAnswer = useCallback(
        (val, parentIndex, index) => {
            let newQuestions = [...questions];
            newQuestions[parentIndex].touched = true;
            newQuestions[parentIndex].subQuestions[index].qAnswer = val;
            if (index === 0) {
                const indexOfAnswerq1 = newQuestions[parentIndex].subQuestions[index].qAvailableAnswers.indexOf(val);
                newQuestions[parentIndex].ans1Index = indexOfAnswerq1;

                if (indexOfAnswerq1 < 2) {
                    newQuestions[parentIndex].subQuestions[index + 1].qAnswer = "";
                    newQuestions[parentIndex].subQuestions[index + 2].qAnswer = "";
                } else {
                    newQuestions[parentIndex].subQuestions[index + 1].qAnswer = val;
                    newQuestions[parentIndex].subQuestions[index + 2].qAnswer = val;
                }
            }
            setQuestions(newQuestions);
        },
        [questions]
    );

    // if (loading) {
    //     return <Loader height="90vh" />;
    // }

    // if (healthCheckCompleted) {
    //     return (
    //         <StyledComplateContainer>

    //             <Box sx={{ mb: 4 }}>
    //                 <Lottie loop animationData={doneAnimation} />
    //             </Box>
    //             <Box sx={{ mb: 4 }}>
    //                 <img src={logo} width="50%" height="50%"></img>
    //             </Box>
    //             <h2>Congratulations! You have completed the Rapid Health Check, please check out your recommendations based on the answers you provided in the Health Check.</h2><br />
    //             <div>
    //                 <Button $success upper="true" onClick={() => history.push("/dashboard/recommendations")}>
    //                     Recommendations
    //                 </Button>
    //             </div>
    //         </StyledComplateContainer>
    //     );
    // }
    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    function contentInProgress() {
        return (<>
            {
                healthCheckCompleted ? <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={7} xs={12} style={{ marginTop: "15px", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "15%" }} sm={0}></Grid>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <h1 style={{ color: "#46a11b" }}>Congratulations!</h1>
                                        <TextBlock style={{ marginTop: "10px" }}>You have completed the Rapid Health Check. Please check out your recommendations based on the answers you provided in the Health Check.</TextBlock>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => recommendationsNavHandler()}>Recommendations</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={0} sm={0} display={{ xs: "none", lg: "block" }}>
                                <ProgressBlock>
                                    <Lottie loop animationData={doneAnimation} />
                                    <img src={logo} width="50%" height="50%"></img>
                                </ProgressBlock>
                            </Grid>
                            <Grid item md={2} xs={0}></Grid>
                        </Grid>
                    </ProgressCard>
                </Grid> : <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead>Current progress</CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={7} xs={12}>
                                <TextBlock style={{ marginTop: "15px", height: "100%", textAlign: "left", fontSize: "16px" }}><QuestionNumber>{questions[0]?.qNum}</QuestionNumber>{questions[0]?.title}</TextBlock>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <ProgressBlock>
                                    <CircularProgressWithLabel value={(currentQuestion - 1) / 37 * 100}>
                                        <ProgressValue>{Math.round((currentQuestion - 1) / 37 * 100)}% <br />completed</ProgressValue>
                                    </CircularProgressWithLabel>
                                </ProgressBlock>
                            </Grid>
                            <Grid item md={2} xs={0}></Grid>
                            <Grid item md={10} xs={12} style={{ textAlign: "left" }}>
                                <Button $success onClick={(e) => healthCheckNavHandler()}>Continue</Button>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            }

            <Grid item lg={3} md={3} sm={6} xs={12}>
                <ProgressCard>
                    <CardHead>Current Score</CardHead>
                    <ProgressBlock>
                        <CircularProgressWithLabel value={totalScore / 230 * 100} color="#ED1E24">
                            <ProgressValue>{totalScore} points</ProgressValue>
                        </CircularProgressWithLabel>
                    </ProgressBlock>
                    <TextBlock style={{ marginBottom: "15px" }}>
                        <DotSign />
                        <StatsText>Max point (294) </StatsText>
                    </TextBlock>
                    <TextBlock>
                        <DotSign style={{ backgroundColor: "#ED1E24" }} />
                        <StatsText>Current score</StatsText>
                    </TextBlock>
                </ProgressCard>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <ProgressCard>
                    <CardHead>Time Remaining</CardHead>
                    <ProgressBlock>
                        {
                            healthCheckCompleted ? duration.remainingDays > 0 ? "Congratulations! You've successfully completed the Rapid Health Check before the due date." : "" : <h1 style={{ height: "100%" }}>{duration.remainingDays} day{duration.remainingDays > 1 ? "s" : ''}</h1>
                        }

                    </ProgressBlock>
                </ProgressCard>
            </Grid>

        </>);
    }

    function contentNewHealthCheck() {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={6} xs={12} style={{ marginTop: "5%", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <TextBlock>Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.</TextBlock>
                                        <h4 style={{ marginTop: "12px" }}>Maximum time you should spend on this health check activity : 2 Weeks</h4>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => handleNewInstance()}>Start New Rapid Health Check</Button>
                                        <TextBlock style={{ marginTop: "15px" }}>New Health Check will be pre-populated with previous Health Check responses.</TextBlock>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5} xs={0} sm={0} display={{ xs: "none", lg: "block", md: "block" }}>
                                <ProgressBlock>
                                    <img src="https://www.color.com/wp-content/uploads/2022/01/home-program-management-launch-illustration.png" width="70%" height="70%"></img>
                                </ProgressBlock>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            </>
        );
    }

    if (!fetching) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>

                    {
                        activeHealthCheckID === 0 ?
                            contentNewHealthCheck() : contentInProgress()
                    }

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <ProgressCard>
                            <CardHead>Completed Rapid Health Check History</CardHead>
                            {
                                isHistoryLoading ? <Loader></Loader> : completedHealthCheckList.length === 0 ? <>
                                    <br /><Alert severity="info">There's no rapid health check history to show!</Alert>
                                </> : <LineChart data={chartHistoryData} />
                            }
                        </ProgressCard>
                    </Grid>
                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                        <ProgressCard>
                            <CardHead>Last Completed Rapid Health Check</CardHead>
                            <ProgressBlock>
                                <GraphContainer>
                                    <Bar
                                        options={maturityBarOptions}
                                        data={{
                                            labels: maturityBarData.labels,
                                            offset: true,
                                            responsive: true,
                                            datasets: [
                                                {
                                                    label: "Current Maturity",
                                                    barThickness: 50,
                                                    data: maturityBarData.green.dataSet,
                                                    datalabels: {
                                                        anchor: "center",
                                                        color: "#fff",
                                                        font: {
                                                            size: 16,
                                                            weight: "bold"
                                                        }
                                                    },
                                                    backgroundColor: "green",
                                                },
                                                {
                                                    label: "Work Remaining",
                                                    data: maturityBarData.red.dataSet,
                                                    barThickness: 50,
                                                    datalabels: {
                                                        labels: {
                                                            title: {
                                                                color: "red",
                                                            },
                                                        },
                                                    },
                                                    backgroundColor: "red",
                                                },
                                            ],
                                        }}
                                    />
                                </GraphContainer>
                            </ProgressBlock>
                        </ProgressCard>
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <ProgressCard>
                            <CardHead>Last Completed Rapid Health Check</CardHead>
                            {
                                isHistoryLoading ? <Loader></Loader> : <>

                                    <Select size="small" value={selectedHealthCheckID} onChange={handleChangeHealthCheck} sx={{ minWidth: '200px', marginTop: 1.825 }}>
                                        {
                                            completedHealthCheckList?.map((healthCheck) => (
                                                <MenuItem value={healthCheck.id} key={healthCheck.id}>{healthCheck.date} ({healthCheck.score})</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <ProgressBlock>
                                        <GraphContainer>
                                            {
                                                !!selectedHealthCheckID && <Bar
                                                    options={selectedHealthCheckChartData?.options || []}
                                                    data={selectedHealthCheckChartData?.data || {}}
                                                />
                                            }
                                        </GraphContainer>
                                    </ProgressBlock>
                                </>}
                        </ProgressCard>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Rapid Cyber Security Health Check - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    Rapid Cyber Security Health Check
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default HealthCheckLandingPage;
