import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color:  ${({ theme }) => theme.palette.background.default};
`

export const ModalHeadText = styled.p`
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: ${({ theme }) => theme.palette.mode === "light" ? "#4F4F4F" : "#fff"};
    text-align: center;
    margin-top: 32px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        font-size: 24px;
        line-height: 28px;
        margin-top: 24px;
    }
`
export const ModalSubHeadText = styled.p`
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.palette.mode === "light" ? "#4F4F4F" : "#F2F2F2"};
    text-align: center;
    margin-top: 13px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 16px;
    }
`