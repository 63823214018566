import styled,{css} from "styled-components";

export const QuestionHeadWrapper = styled.div`
    display: flex;
    margin-bottom: 43px;

    ${({ $smsize }) =>
        $smsize &&
        css`
            align-items: center;
            margin-bottom: 31px;
        `}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 20px;
        ${({ $smsize }) =>
        $smsize &&
        css`
            margin-bottom: 16px;
        `}
    }
`;

export const QuestionNumber = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width:32px;
    height:32px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #3a8d1c;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 16px;

    ${({ $smsize }) =>
        $smsize &&
        css`
            width: 24px;
            height: 24px;
        `}
`;
