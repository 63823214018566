import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Doughnut } from 'react-chartjs-2';

import { ActionContainer, CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapper, StatsText, TextBlock } from '../BusinessImpactAssessmentLandingPage.styled';
import ThemeContext from '../../../../../store/theme-context';
import Loader from '../../../../../components/UI/Loader/Loader';
import { noChartData } from '../../../../../shared/constants';

function BIASummaryCard({ centerLabelTitle, data, loading }) {

    const history = useHistory();
    const { theme } = useContext(ThemeContext);

    const graphData = data.some((d) => d?.value > 0) ? {
        labels: data.map((d) => d.label),
        datasets: [
            {
                data: data.map((d) => d.value),
                backgroundColor: data.map((d) => d.color), // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ],
    } : {
        labels: [data.map((d) => d.label).join(", ")],
        datasets: [
            {
                data: noChartData.map((d) => d.value),
                backgroundColor: noChartData.map((d) => d.color), // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: '85%', // Inner radius of the Doughnut chart
        borderColor: theme?.palette?.mode === 'dark' ? 'rgb(31, 33, 40)' : '#fff',
        plugins: {
            datalabels: {
                display: false, // Hide data labels
            },
            centerLabelPlugin: {
                title: centerLabelTitle ?? '',
                titleColor: theme?.palette?.text?.primary
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const tooltipValue = data[dataIndex].tooltipValue;
                        return `${tooltipValue}`;
                    },
                },
            },
        },
    };

    return (
        <ProgressCard>
            <ProgressCardContent>
                <ProgressCardContentTop>
                    <CardHead>Progress</CardHead>
                    {
                        loading ? <Loader height="216px" /> : <>
                            <ProgressContainer>
                                <ProgressWrapper>
                                    <Doughnut data={graphData} options={options} />
                                </ProgressWrapper>
                            </ProgressContainer>
                            <LegendContainer>
                                {
                                    data.map((d, index) => (
                                        <TextBlock key={d.label + index}>
                                            <DotSign style={{ backgroundColor: d.color }} />
                                            <StatsText>{d.label}</StatsText>
                                        </TextBlock>
                                    ))
                                }
                            </LegendContainer>
                        </>
                    }
                </ProgressCardContentTop>
                {/* <ActionContainer>
                    <Button color='success' variant='outlined' fullWidth size='large' borderWidth={"2px"} onClick={() => { }}>
                        Continue
                    </Button>
                </ActionContainer> */}
            </ProgressCardContent>
        </ProgressCard>
    );
}
export default BIASummaryCard;   