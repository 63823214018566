export const noChartData = [
    {
        label: '',
        value: 100,
        color: 'rgb(189, 189, 189)',
        tooltipValue: 0
    }
]

export const UserTypes = {
    novice: 'novice',
    intermediate: 'intermediate',
    advance: 'advanced',
};

export const ReportTypes = {
    HEALTH_CHECK: 'Health Check',
    RISK_MATRIX: 'Risk Matrix',
    TRA: 'TRA',
}

export const REPORT_TYPES_OPTIONS = [
    { label: "Health Check", value: "Health Check" },
    { label: "Risk Matrix", value: "Risk Matrix" },
    { label: "TRA", value: "TRA" },
]