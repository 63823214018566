import { Alert, Box, Chip, Grid, Grow } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess, RiskAssessmentProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./RiskAssessment.styled";
import E8QuestionsStepper from "../../components/E8QuestionStepper/E8QuestionStepper";
import questionData from "./riskQuestions.json";
import QuestionProgress from "../../components/QuestionProgress/QuestionProgress";
import logo from "../../../../images/logo0.2.png";
import { useKeycloak } from "@react-keycloak/web";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function RiskAssessment(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const e8NavHandler = (process) => history.push("/it-security/essential-eight/controls/questions", { process });
    const goBack = () => history.goBack();
    const [activeIndex, setActiveIndex] = useState(0);
    const [processID, setProcessID] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState(null);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);
    // const baseUrl="http://localhost:8080/kie-server/services"
    // const containerID="asde8_1.0.0-SNAPSHOT"

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    let content;

    if (!fetching) {
        content = <Loader height="40vh" />;
    }

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
            });
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        if (!processID) return;
        const data = window.sessionStorage.getItem('RISK_ASSESSMENT');
        const index = window.sessionStorage.getItem('RISK_ASSESSMENT_INDEX');
        if (data) {
            setQuestions(JSON.parse(data));
            if (index) setActiveIndex(JSON.parse(index));
        }
        getUserTask(processID);

    }, [processID]);

    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getActiveCaseID();
    }, [CONTAINER_ID, kcToken]);

    async function getActiveCaseID() {
        if (!kcToken) return;
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${RiskAssessmentProcess}/instances?status=open&page=0&pageSize=10&sortOrder=false&withData=false`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    if (response.data !== "") {
                        const caseID = response.data["instances"][0]["case-id"] ?? false;
                        if (caseID) {
                            //store active caseID in local storage
                            getProcessID(caseID)
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getProcessID(caseID) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/processes/instances?page=0&pageSize=10&sortOrder=true`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    setProcessID(response.data["process-instance"][0]["process-instance-id"]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getUserTask(processID) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const task = response.data["active-user-tasks"]["task-summary"][0];
                    const taskID = task["task-id"];
                    setTaskInstanceId(taskID);

                    // getTaskDetail(taskID);
                    getData(processID);

                    if (task["task-status"] !== "InProgress") {
                        var data = JSON.stringify(true);

                        var config2 = {
                            method: 'put',
                            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/states/started`,
                            headers: {
                                "Authorization": `Bearer ${kcToken}`
                            },
                            data: data
                        };

                        fetchInstance.apiRequest(config2)
                            .then(function (response) {
                                //started user task
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                goBack();
            });
    }

    async function getTaskDetail(taskID) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/contents/input`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    var objArr = [];
                    Object.keys(response.data).forEach(key => {
                        if (key.includes("question")) {
                            const questionObj = JSON.parse(response.data[`${key}`]);
                            objArr.push(questionObj);
                        }
                    });
                    objArr.sort((a, b) => { return a.qID - b.qID });
                    setQuestions(objArr);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleOneAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
        const dataString = JSON.stringify(newQuestions);
        window.sessionStorage.setItem("RISK_ASSESSMENT", dataString);
    }, [questions]);

    let userAnsweredAllQuestions = questions.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
    }) : false;

    function getDataToUpload() {
        updateData(activeIndex);
        window.sessionStorage.setItem('RISK_ASSESSMENT_INDEX', JSON.stringify(activeIndex + 1));
        setActiveIndex(activeIndex + 1);
    }

    async function submitAnswers() {
        updateData(activeIndex).then(() => {
            let config = {
                method: 'put',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
                data: JSON.stringify({}),
                headers: { "Authorization": `Bearer ${kcToken}`, 'Content-Type': 'application/json' }
            }
            fetchInstance.apiRequest(config)
                .then((res) => {

                    if (res.status === 201) {
                        sessionStorage.clear();
                        setAssessmentCompleted(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }

    async function updateData(index) {
        var ansObject = { [`ans${index + 1}Data`]: JSON.stringify(questions[index]) }

        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}/variables`,
                data: JSON.stringify(ansObject),
                headers: { "Authorization": `Bearer ${kcToken}`, 'Content-Type': 'application/json' }
            }
            fetchInstance.apiRequest(config)
                .then((res) => {
                    if (res.status === 201) {
                        //success
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }


    async function getData(processID) {

        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}/variables`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            },
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status === 200) {
                    var objArr = [];
                    Object.keys(response.data).forEach(key => {
                        if (key.includes("ans")) {
                            const questionObj = JSON.parse(response.data[`${key}`]);
                            objArr.push(questionObj);
                        }
                    });
                    objArr.sort((a, b) => { return a.qID - b.qID });

                    for (let question of objArr) {
                        if (!("touched" in question)) {
                            setActiveIndex(question.qID - 1);
                            break;
                        };
                    }
                    setQuestions(objArr);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `3rd Party Risk Assessment Progress - ${questions[activeIndex]?.title}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [activeIndex, dispatch, questions])

    if (assessmentCompleted) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> You have completed the 3rd Party Risk Assessment.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={(e) => goBack()}>
                            Done
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    if (fetching && processID && questions.length > 0) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                        <Box sx={{ paddingTop: "40px" }}>
                            <QuestionProgress questionNumber={activeIndex + 1} totalNumber={questions.length} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", position: "relative" }}>
                            <E8QuestionsStepper readOnly={false} questions={[questions[activeIndex]]} qIndex={activeIndex} handleAnswer={handleOneAnswer}></E8QuestionsStepper>
                            <ActionsWrapper hasOne>
                                {
                                    activeIndex === 0 ? "" : <Button $red onClick={(e) => setActiveIndex(activeIndex - 1)} style={{ marginRight: "10px" }}>
                                        back
                                    </Button>
                                }
                                {
                                    questions.length === activeIndex + 1 ? <Button disabled={!userAnsweredAllQuestions} $success onClick={(e) => submitAnswers()}>
                                        Submit
                                    </Button> : <Button disabled={!userAnsweredAllQuestions} $success onClick={(e) => getDataToUpload()}>
                                        next
                                    </Button>
                                }

                            </ActionsWrapper>
                        </Box>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>3rd Party Risk Assessment Progress {(activeIndex + 1).toString()} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    3rd Party Risk Assessment
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default RiskAssessment;
