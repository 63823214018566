import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterStyled = styled.footer`
    box-shadow: ${({ theme }) => theme.boxShadow.shadows};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-size: 17px;
    height:50px;

    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#1F2128"};
    box-shadow: ${({ theme }) =>
        theme.palette.mode === "light"
            ? "0px -2px 4px rgba(0, 0, 0, 0.25)"
            : ""};

    @media screen and (max-width: 599.98px) {
        justify-content: space-evenly;
        gap: 0;
    }
`;

export const FooterLink = styled(Link)`
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#333" : "#fff")};
    font-size: 17px;
    font-weight: 500;

    @media screen and (max-width: 599.98px) {
        font-size: 14px;
    }
`;
