import React, { useEffect, useContext, useState, useCallback } from "react";

import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {
    Table as ReactTable,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell
} from "@table-library/react-table-library/table";
import { useCustom } from '@table-library/react-table-library/table';
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import {
    useSort,
    HeaderCellSort,
} from "@table-library/react-table-library/sort";
import { usePagination } from '@table-library/react-table-library/pagination';

import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';

import {
    TableWrapper,
    PaginationWrapper,
    getTableTheme,
    TableContainer
} from "./Table.styled";
import ThemeContext from "../../../store/theme-context";
import ColumnFilter from "./ColumnFilter";
import Loader from "../Loader/Loader";

function getGridTemplateColumn(columns, hiddenColumns) {

    let customBaseCell = ';'
    let pinLeftIndex = columns.findIndex((c) => c.pinLeft);
    if (pinLeftIndex > -1) {
        customBaseCell = `
            &:nth-of-type(${pinLeftIndex + 1}) {
                left: 0px;
            }
        `
    }

    let pinRightIndex = columns.findIndex((c) => c.pinRight);
    if (pinRightIndex > -1) {
        customBaseCell += `
            &:nth-of-type(${pinRightIndex + 1}) {
                right: 0px;
            }
        `
    }

    return {
        gridTemplateColumn: columns
            .filter((c) => !hiddenColumns.includes(c.id))
            // .map((c) => 'auto')
            .map((c) => {
                let val = c.flex ? `${c.flex}fr` : c.width || 'auto';
                if (c.flex && c.minWidth) {
                    val = `minmax(${c.minWidth}, ${c.flex}fr)`
                } else if (c.minWidth) {
                    val = c.minWidth;
                }
                return val;
            })
            .join(' '),
        customBaseCell: customBaseCell
    };
}

const Table = (props) => {

    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext?.theme?.palette?.mode === "dark" ? true : false;

    const { tableData, columns, rowHeight = '52px', pageSize = 10 } = props;
    const [data, setData] = useState({ nodes: tableData });
    const [columnLabels, setColumnLabels] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);

    useEffect(() => {
        setData({ nodes: tableData })
    }, [tableData])

    useEffect(() => {
        setColumnLabels(
            columns
                .filter((column) => !column.disableColumnFilter)
                .map((column) => ({ label: column.header, value: column.id }))
        );
        setHiddenColumns(
            columns
                .filter((column) => !column.disableColumnFilter)
                .filter((column) => column.defaultHidden)
                .map((column) => column.id)
        );
    }, [])

    // const theme = useTheme([getTheme(), THEME]);
    const theme = useTheme([getTheme(), getTableTheme(isDarkTheme, getGridTemplateColumn(columns, hiddenColumns))]);

    //* Pagination *//
    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: pageSize,
        },
        onChange: (action, state) => { },
    });

    //* Search *//
    const [search, setSearch] = React.useState("");

    useCustom('search', data, {
        state: { search },
        onChange: (action, state) => { pagination.fns.onSetPage(0); },
    });

    //* Sort *//
    const sortFns = columns.reduce((result, item) => {
        if (item.sort) {
            result[item.id] = (array) => array.sort(item.sortFn);
        }
        return result;
    }, {});

    const sort = useSort(
        data,
        {
            onChange: (action, state) => { },
        },
        {
            sortIcon: {
                margin: "0px",
                iconDefault: <UnfoldMoreIcon fontSize="small" />,
                iconUp: <KeyboardArrowUpOutlinedIcon fontSize="small" />,
                iconDown: <KeyboardArrowDownOutlinedIcon fontSize="small" />,
            },
            sortFns
        },
    );

    //* Custom Modifiers *//
    let modifiedNodes = data.nodes;

    // search
    modifiedNodes = modifiedNodes.filter((node) => {
        let searchFields = columns.filter((c) => c.searchable).map((c) => c.id);
        if (searchFields.length === 0) {
            searchFields = columns.map((c) => c.id);
        }
        return searchFields.some((field) =>
            String(node[field]).toLowerCase().includes(search.toLowerCase())
        );
    })

    const toggleHiddenColumn = (column) => {
        setHiddenColumns([...column]);
        // setHiddenColumns((prev) => prev.includes(column) ? prev.filter((v) => v !== column) : [...prev, column]);
    };

    const getCellStyles = useCallback((column) => {
        let cellStyles = {
            minHeight: rowHeight,
            // maxHeight: rowHeight,
        };

        if (column.width) {
            cellStyles.minWidth = column.width;
            cellStyles.width = column.width;
            cellStyles.maxWidth = column.width;
        }
        if (column?.flex > 0) {
            cellStyles.width = "auto";
        }

        return cellStyles;
    }, [rowHeight]);

    return (
        <TableWrapper>
            {
                !props.hideTopBar && <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                        {props?.tableTitle || ''}
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <TextField
                                sx={{ maxWidth: '350px' }}
                                label="Search"
                                variant="outlined"
                                onChange={(e) => setSearch(e.target.value)}
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {props.actionButtons || ''}
                            <ColumnFilter
                                labels={columnLabels}
                                hiddenColumns={hiddenColumns}
                                toggleHiddenColumn={toggleHiddenColumn}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }

            <TableContainer style={{ ...(!isDarkTheme && { borderColor: 'rgba(224, 224, 224, 1)' }) }}>
                <div style={{ width: "100%" }} className="my-table___wrapper">
                    <ReactTable
                        data={{ ...data, nodes: modifiedNodes }}
                        theme={theme}
                        sort={sort}
                        pagination={pagination}
                        layout={{ custom: true }}
                        className="my-table"
                    >
                        {(tableList) => (
                            <>
                                <Header>
                                    <HeaderRow>
                                        {
                                            columns.map((header, index) => header.sort ? (
                                                <HeaderCellSort hide={hiddenColumns.includes(header.id)} key={index} sortKey={header.id} pinLeft={header.pinLeft} pinRight={header.pinRight} resize={header.resize}>
                                                    {header.header}
                                                </HeaderCellSort>
                                            ) : (
                                                <HeaderCell hide={hiddenColumns.includes(header.id)} key={index} pinLeft={header.pinLeft} pinRight={header.pinRight} resize={header.resize}>
                                                    {header.header}
                                                </HeaderCell>
                                            ))
                                        }
                                    </HeaderRow>
                                </Header>

                                <Body>
                                    {
                                        props.loading ? <>
                                            {
                                                props.skeletonLoader ? <>
                                                    {
                                                        Array.from(new Array(pageSize)).map((_, i) => (
                                                            <Row key={'skeletonLoader-' + i} item={i}>
                                                                {
                                                                    columns.map((column, cIndex) => {

                                                                        let cellStyles = getCellStyles(column);

                                                                        return <Cell key={cIndex} hide={hiddenColumns.includes(column.id)} style={{ ...cellStyles }} pinLeft={column.pinLeft} pinRight={column.pinRight}>
                                                                            <Skeleton />
                                                                        </Cell>
                                                                    })
                                                                }
                                                            </Row>
                                                        ))
                                                    }
                                                </> : <Row key="row-loading">
                                                    <Cell
                                                        key="cell-loading"
                                                        gridColumnStart={1}
                                                        gridColumnEnd={(columns.length - hiddenColumns.length)}
                                                        style={{
                                                            padding: 0,
                                                            background: 'none'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                minHeight: `${rowHeight * pageSize}px`,
                                                                width: `${document?.querySelector('.my-table___wrapper')?.clientWidth ? `${document?.querySelector('.my-table___wrapper')?.clientWidth}px` : '100%'}`
                                                            }}
                                                        >
                                                            <Loader />
                                                        </div>
                                                    </Cell>
                                                </Row>
                                            }
                                        </> : <>
                                            {tableList.map((item) => (
                                                <Row key={item.id || item.caseID} item={item}>
                                                    {
                                                        columns.map((column, cIndex) => {

                                                            let cellStyles = getCellStyles(column);

                                                            // Display skeleton if loading
                                                            if (column.loading && column.loading({ row: item, value: item[column.id] }, cIndex)) {
                                                                return <Cell key={`${column.id}-skeletonLoader-${cIndex}`} hide={hiddenColumns.includes(column.id)} style={{ ...cellStyles }} pinLeft={column.pinLeft} pinRight={column.pinRight}>
                                                                    <Skeleton />
                                                                </Cell>
                                                            }

                                                            if (column.renderCell) {
                                                                return <Cell key={`${column.id}-${cIndex}`} hide={hiddenColumns.includes(column.id)} style={{ ...cellStyles }} pinLeft={column.pinLeft} pinRight={column.pinRight}>
                                                                    {column.renderCell({ row: item, value: item[column.id] }, cIndex)}
                                                                </Cell>
                                                            } else {
                                                                return <Cell key={`${column.id}-${cIndex}`} hide={hiddenColumns.includes(column.id)} style={{ ...cellStyles }} pinLeft={column.pinLeft} pinRight={column.pinRight}>
                                                                    {item[column.id]}
                                                                </Cell>
                                                            }
                                                        })
                                                    }
                                                </Row>
                                            ))
                                            }
                                        </>
                                    }
                                </Body>
                            </>
                        )}
                    </ReactTable>
                </div>
                <PaginationWrapper>
                    <TablePagination
                        component="div"
                        count={modifiedNodes.length}
                        page={pagination.state.page}
                        rowsPerPage={pagination.state.size}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        onRowsPerPageChange={(event) =>
                            pagination.fns.onSetSize(parseInt(event.target.value, 10))
                        }
                        onPageChange={(event, page) => pagination.fns.onSetPage(page)}
                        sx={{
                            borderBottom: 'none',
                        }}
                    />
                </PaginationWrapper>
            </TableContainer>
        </TableWrapper>
    );
}

export default Table;