import { Alert, Box, Grid, Grow } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess, RiskMatrixProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, BtnsContainer, StyledComplateContainer, Wrapper } from "./RiskMatrix.styled";
import { getActiveHealthCheck, getActiveRiskMatrix, getCompletedHealthCheck, getCompletedHealthCheckList, getCompletedRiskMatrix, getDuration, getHealthCheckDuration, getProcessVariables, getRiskMatrixTableData } from "../../InformationAPI";
import { useKeycloak } from "@react-keycloak/web";
import logo from "../../../../images/logo0.2.png";
import { CardHead, DotSign, ProgressBlock, ProgressCard, ProgressValue, StatsText, TextBlock } from "../Information/Information.styled";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { QuestionNumber } from "../../../../components/Question/QuestionHead/QuestionHead.styled";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ThemeContext from "../../../../store/theme-context";
import { GraphContainer } from "./RiskMatrix.styled";
import EditableTable from "../../components/EditableTable/EditableTable";
import { DescriptionContent, DescriptionTextHead, DescriptionTextP, DescriptionTextParagraph, StyledInput, StyledList } from "../../components/RiskList/RiskList.styled";
import RiskTabs from "../../components/RiskTabs/RiskTabs";
import PdfTest from "../PdfTest/PdfTest";
import RiskMatrixPDF from "./RiskMatrixPDF/RiskMatrixPDF";
import sorryAnimation from "../../../../assets/animations/sorry.json";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { fetchRiskMatrixData } from "../../../../utility/riskMatrix.action";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { ReportTypes } from "../../../../shared/constants";

function RiskMatrixLandingPage(props) {

    const { keycloak, initialized } = useKeycloak();
    const dispatch = useDispatch();
    const riskMatrixState = useSelector((state) => state.riskMatrix);
    const { completedRiskID, riskMatrixTableData, riskInformation, riskActions, riskConsequence } = riskMatrixState;

    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [header, setHeader] = useState(null);

    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState("");

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [isHistoryLoading, setHistoryLoading] = useState(true);

    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);
    const [healthCheckCompleted, setHealthCheckCompleted] = useState(false);
    const [riskMatrixCompleted, setRiskMatrixCompleted] = useState(false);
    const [activeRiskID, setActiveRiskID] = useState(null);
    // const [completedRiskID, setcompletedRiskID] = useState(null);
    const [totalScore, setTotalScore] = useState(null);
    const [duration, setDuration] = useState({ "remainingDays": 0, "maxDays": 14 });
    const [maturity, setMaturity] = useState({ "currentScore": [0, 0, 0, 0, 0], "requiredScore": [5, 5, 5, 5, 5] });
    const { mode } = useContext(ThemeContext);
    const [activeRiskData, setActiveRiskData] = useState(null);
    const [completedRiskData, setcompletedRiskData] = useState(null);
    // const [riskInformation, setRiskInformation] = useState(null);
    // const [riskActions, setRiskActions] = useState(null);
    // const [riskConsequence, setRiskConsequence] = useState(null);

    //new variables
    // const [tableData, setTableData] = useState({ "tableData": [], "headingColumns": [], "selectedValue": "" });

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Risk Matrix',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const healthCheckNavHandler = () => history.push("/it-security/risk-matrix/risk-matrix-progress");
    const recommendationsNavHandler = () => history.push("/it-security/recommendations");

    useEffect(() => {
        if (riskMatrixState.allowFetch) {
            dispatch(fetchRiskMatrixData(keycloak, email));
        }
    }, [dispatch, email, keycloak, riskMatrixState.allowFetch])

    useEffect(() => {
        setKcToken(keycloak.token);
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    function handleNextStage() {
        return healthCheckNavHandler;
    }

    const handleEdit = (edit) => {
        //
    }

    const updateConsequence = (childData) => {
        //
    }

    async function handleNewInstance() {
        //function to start new instance
        if (activeRiskID > 0) return;
        setLoading(true);
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${RiskMatrixProcess}/instances`,
                data: JSON.stringify({}),
                ...header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.data !== "") {
                    const progressInstance = res.data;
                    setActiveRiskID(progressInstance);
                    getRiskMatrixTask(progressInstance, header);
                }
                setLoading(false);
            });
        } catch (error) {
            toast.error("Failed to start new risk management");
            setLoading(false);
        }
    }

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
            });
        }
    }, [keycloak])

    const getRiskMatrixQuestions = useCallback(async (taskInstId, taskName, header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/contents/input`,
                ...header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const str = res.data['NodeName']
                let questionNum = 0;

                if (str === "Details of Risk Matrix") {
                    questionNum = 0
                } else if (str === "Dimensions") {
                    questionNum = 1
                } else if (str.includes("Likelihood")) {
                    questionNum = 2
                }
                else if (str.includes("Consequence")) {
                    questionNum = 3
                }
                else if (str.includes("Risk Management Plan")) {
                    questionNum = 4
                }
                else if (str.includes("Risk Matrix")) {
                    questionNum = 5
                }


                setActiveRiskData({
                    "qNum": questionNum,
                    "title": str
                });
                setRiskMatrixCompleted(false);
                setFetching(false);
            });

        } catch (err) {
            setFetching(false);
            toast.error(err.message);
        }
    }, [])

    const getRiskMatrixTask = useCallback(async (matrixInstance, header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${matrixInstance}`,
                ...header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const taskInst = res.data["active-user-tasks"]["task-summary"][0]["task-id"];
                const taskIName = res.data["active-user-tasks"]["task-summary"][0]["task-name"];
                setTaskInstanceId(taskInst);
                setFetching(true);
                setLoading(false);
                getRiskMatrixQuestions(taskInst, taskIName, header);
            });

        } catch (error) {
            setFetching(false);
            toast.error(error.message);
        }
    }, []);

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID]);

    useEffect(() => {

        if (activeRiskID === null || header === null) {
            setLoading(false);
            return;
        };

        if (activeRiskID > 0) {
            setLoading(true);
            getRiskMatrixTask(activeRiskID, header);
            return;
        } else {
            setFetching(false);
        }
    }, [activeRiskID, riskMatrixCompleted, header, getRiskMatrixTask]);

    useEffect(() => {
        if (activeHealthCheckID === null || kcToken === null || completedRiskID === null) return;
        // getProcessVariables(activeHealthCheckID, kcToken).then((response) => {
        //     const score = response.data.filter(obj => { return obj.name === "TotalScore" })[0] ?? null;
        //     setTotalScore(Math.round(score?.["value"] ?? 0));

        //     const startDateMilli = response.data.filter(obj => { return obj.name === "StartDate" })[0];
        //     setDuration(getDuration(startDateMilli?.["value"] ?? 0));
        // });

        // getProcessVariables(completedRiskID, kcToken).then((response) => {
        //     setcompletedRiskData(response.data);
        // });

        // getRiskMatrixTableData(completedRiskID, kcToken).then((response) => {
        //     console.log('DEBUG getRiskMatrixTableData = ', response)
        //     if (response.success) {
        //         setTableData(response.data);
        //     }
        // });

    }, [activeHealthCheckID, kcToken, completedRiskID]);


    useEffect(() => {
        if (completedRiskData === null) return;
        // function getLikelihoodData(riskData) {
        //     let likelihoodData = riskData.filter(obj => { return obj.name.startsWith("Likelihood") })
        //     let formattedData = [
        //         {
        //             id: "i1",
        //             head: "Likelihood",
        //             title: "Description",
        //         },
        //     ]
        //     for (let i = 1; i <= (likelihoodData.length / 2); i++) {
        //         formattedData[i] = {
        //             id: `i${i + 1}`,
        //             head: likelihoodData.filter(obj => { return obj.name === `LikelihoodRow${i}` })[0].value ?? "",
        //             title: likelihoodData.filter(obj => { return obj.name === `LikelihoodContent${i}` })[0].value ?? "",
        //         };
        //     }

        //     console.log('DEBUG formattedData = ', formattedData)

        //     setRiskInformation(formattedData);
        // }


        // function getActionData(riskData) {
        //     let fetchedLevels = riskData.filter(obj => { return obj.name.startsWith("RMrow") });
        //     let fetchedActions = riskData.filter(obj => { return obj.name.startsWith("RMaction") });
        //     let fetchedPlans = riskData.filter(obj => { return obj.name.startsWith("RMtreatment") });

        //     const levels = new Array(fetchedLevels.length);
        //     const actions = new Array(fetchedActions.length);
        //     const plans = new Array(fetchedPlans.length);
        //     for (let index = 0; index < levels.length; index++) {
        //         levels[index] = fetchedLevels.filter(obj => { return obj.name === `RMrow${index + 1}` })[0]?.value ?? "";
        //         actions[index] = fetchedActions.filter(obj => { return obj.name === `RMaction${index + 1}` })[0]?.value ?? "";
        //         plans[index] = fetchedPlans.filter(obj => { return obj.name === `RMtreatment${index + 1}` })[0]?.value ?? "";
        //     }

        //     setRiskActions({
        //         levels: levels,
        //         actions: actions,
        //         plans: plans
        //     })
        // }

        // function getCategory(riskData) {
        //     let fetchedTabs = fetchTabs(riskData);
        //     let fetchedLevels = fetchLevels(riskData);
        //     let fetchedFields = fetchField(riskData);
        //     setRiskConsequence({
        //         tabs: fetchedTabs,
        //         levels: fetchedLevels,
        //         fields: fetchedFields
        //     });
        // }

        // getLikelihoodData(completedRiskData);
        // getActionData(completedRiskData);
        // getCategory(completedRiskData);
    }, [completedRiskData])

    function fetchTabs(tabs) {
        return tabs
            .filter(function (obj) {
                return obj.name.startsWith("RiskCategory") || obj.name.startsWith("RiskCategoryRow");
            })
            .map(function (obj) {
                return { "name": obj.value };
            });
    }

    function fetchLevels(tabs) {
        return tabs
            .filter(function (obj) {
                return obj.name.startsWith("ConsequenceCategory");
            })
            .map(function (obj) {
                return obj.value;
            });
    }

    function fetchField(tabs) {
        return tabs
            .filter(function (obj) {
                return obj.name.startsWith("ConsCat");
            })
            .map(function (obj) {
                let str = obj.name;
                let catNum = str.substring(
                    str.indexOf("t") + 1,
                    str.lastIndexOf("R")
                );

                let rowNum = str.split('Row')[1]
                return { "name": `consCat${catNum}Row${rowNum}`, "desc": obj.value }
            });
    }

    function getAllInstance(_email, _kcToken) {
        if (_email === null || _kcToken === null) {
            return;
        }

        setHeader({ headers: { "Authorization": `Bearer ${_kcToken}`, 'Content-Type': 'application/json' } });

        getActiveHealthCheck(_kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });

        getCompletedHealthCheck(_kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });

        getActiveRiskMatrix(_kcToken, _email).then((response) => {
            setActiveRiskID(response.data);
        });

        dispatch(fetchRiskMatrixData(keycloak, _email));
        getCompletedRiskMatrix(_kcToken, _email).then((response) => {
            setcompletedRiskData(response.data);
        });
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClick2 = () => {
        setModal2Open(true);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    const handleCallback = (childData) => {
        //
    }


    let modalTitle = "";
    let modalList = ["Maximum time you should spend on this risk management activity : 1 Weeks"];

    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    function contentInProgress() {
        return (<>
            {
                healthCheckCompleted ? <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={7} xs={12} style={{ marginTop: "15px", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "15%" }} sm={0}></Grid>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <h1 style={{ color: "#46a11b" }}>Congratulations!</h1>
                                        <TextBlock style={{ marginTop: "10px" }}>You have completed the Rapid Health Check. Please check out your recommendations based on the answers you provided in the Health Check.</TextBlock>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => recommendationsNavHandler()}>Recommendations</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={0} sm={0} display={{ xs: "none", lg: "block" }}>
                                <ProgressBlock>
                                    <Lottie loop animationData={doneAnimation} />
                                    <img src={logo} width="50%" height="50%"></img>
                                </ProgressBlock>
                            </Grid>
                            <Grid item md={2} xs={0}></Grid>
                        </Grid>
                    </ProgressCard>
                </Grid> : <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead>In progress</CardHead>
                        {
                            activeRiskData === null ? "" : <Grid container>
                                <Grid item xs={0} md={1}></Grid>
                                <Grid item md={7} xs={12}>
                                    <TextBlock style={{ marginTop: "15px", height: "100%", textAlign: "left", fontSize: "16px" }}><QuestionNumber>{activeRiskData?.qNum + 1}</QuestionNumber>{activeRiskData?.title}</TextBlock>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <ProgressBlock>
                                        <CircularProgressWithLabel value={(activeRiskData?.qNum ?? 0) / 6 * 100}>
                                            <ProgressValue>{Math.round((activeRiskData?.qNum ?? 0) / 6 * 100)}% <br />completed</ProgressValue>
                                        </CircularProgressWithLabel>
                                    </ProgressBlock>
                                </Grid>
                                <Grid item md={2} xs={0}></Grid>
                                <Grid item md={10} xs={12} style={{ textAlign: "left" }}>
                                    <Button $success onClick={(e) => healthCheckNavHandler()}>Continue</Button>
                                </Grid>
                            </Grid>
                        }

                    </ProgressCard>
                </Grid>
            }

            {/* {
                completedRiskID > 0 ? "" : <Grid item lg={3} md={3} sm={6} xs={12}>
                    <ProgressCard>
                        <CardHead>Time Remaining</CardHead>
                        <ProgressBlock>
                            {
                                healthCheckCompleted ? duration.remainingDays > 0 ? "Congratulations! You've successfully completed the Rapid Health Check before the due date." : "" : <h1 style={{ height: "100%" }}>{duration.remainingDays} day{duration.remainingDays > 1 ? "s" : ''}</h1>
                            }

                        </ProgressBlock>
                    </ProgressCard>
                </Grid>
            } */}

        </>);
    }

    function contentNewHealthCheck() {
        return (
            <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={6} xs={12} style={{ marginTop: "10%", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <TextBlock>Use this feature to edit your cyber security risk management tool.</TextBlock>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => handleNewInstance()}>Edit Risk Management</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5} xs={0} sm={0} display={{ xs: "none", lg: "block", md: "block" }}>
                                <ProgressBlock>
                                    <img src="https://www.color.com/wp-content/uploads/2022/01/home-program-management-launch-illustration.png" width="70%" height="70%"></img>
                                </ProgressBlock>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            </>
        );
    }

    if (!fetching && completedHealthCheckID === 0) {
        content = (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to complete Cyber Security Health Check!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/overview")}>
                            Back To Dashboard
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    if (!fetching && completedHealthCheckID > 0) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>

                    {
                        activeRiskID === 0 ?
                            contentNewHealthCheck() : contentInProgress()
                    }
                    {
                        completedRiskData > 0 ? <><Grid item lg={6} md={6} sm={12} xs={12}>
                            <ProgressCard>
                                <CardHead>Current Risk Management Matrix</CardHead>
                                <ProgressBlock >
                                    <GraphContainer>
                                        <EditableTable tableData={riskMatrixTableData.tableData} headingColumns={riskMatrixTableData.headingColumns} title="Consequence" selectedValue={riskMatrixTableData.selectedValue} parentCallback={handleCallback} />
                                    </GraphContainer>
                                </ProgressBlock>
                            </ProgressCard>
                        </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ProgressCard>
                                    <CardHead>Current Risk Management Actions</CardHead>
                                    <ProgressBlock>
                                        <GraphContainer>
                                            {
                                                riskActions !== null ? <StyledList mZero smsize style={{ textAlign: "left" }}>
                                                    <li >
                                                        <DescriptionContent
                                                            actionWidth
                                                        >
                                                            <DescriptionTextHead
                                                                acSmWidth
                                                            >
                                                                Risk Level
                                                            </DescriptionTextHead>
                                                            <DescriptionTextParagraph
                                                                acMdWidth
                                                            >
                                                                Management Action Required
                                                            </DescriptionTextParagraph>
                                                            <DescriptionTextP>Risk Treatment Plan</DescriptionTextP>
                                                        </DescriptionContent>
                                                    </li>
                                                    {riskActions?.levels.map((level, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <DescriptionContent actionWidth>
                                                                    <DescriptionTextHead acSmWidth>
                                                                        {level}
                                                                    </DescriptionTextHead>
                                                                    {
                                                                        <DescriptionTextParagraph acMdWidth>{riskActions.actions[index]}</DescriptionTextParagraph>
                                                                    }
                                                                    {
                                                                        <DescriptionTextP acMdWidth>{riskActions.plans[index]}</DescriptionTextP>
                                                                    }
                                                                </DescriptionContent>
                                                            </li>
                                                        )
                                                    })}
                                                </StyledList> : <Loader></Loader>
                                            }

                                        </GraphContainer>
                                    </ProgressBlock>
                                </ProgressCard>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ProgressCard>
                                    <CardHead>Current Impact Definition</CardHead>
                                    <ProgressBlock>
                                        <GraphContainer style={{ textAlign: "left" }}>
                                            {riskConsequence !== null ? <RiskTabs viewOnly={true} riskConsequence={riskConsequence} handleEdit={handleEdit} parentCallback={updateConsequence} /> : <Loader></Loader>}
                                        </GraphContainer>
                                    </ProgressBlock>
                                </ProgressCard>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ProgressCard>
                                    <CardHead>Current Likelihood Definition</CardHead>
                                    <ProgressBlock>
                                        <GraphContainer>
                                            {
                                                riskInformation !== null ? <StyledList mZero smsize style={{ textAlign: "left" }}>
                                                    {riskInformation?.map((item, index) => (
                                                        <li key={index || item.id}>
                                                            <DescriptionContent
                                                            >
                                                                <DescriptionTextHead
                                                                >
                                                                    {item.head}
                                                                </DescriptionTextHead>
                                                                {
                                                                    <DescriptionTextParagraph>{item.title}</DescriptionTextParagraph>
                                                                }

                                                            </DescriptionContent>
                                                        </li>
                                                    ))}
                                                </StyledList> : <Loader></Loader>
                                            }

                                        </GraphContainer>
                                    </ProgressBlock>
                                </ProgressCard>
                            </Grid>
                        </> : ""
                    }


                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    var pdfTest;
    if (kcToken !== null && email !== null && riskActions !== null && riskInformation !== null && riskConsequence !== null && riskMatrixTableData !== null && completedRiskID > 0) {
        //uncomment to show pdf donwnload button

        pdfTest = (<RiskMatrixPDF kcToken={kcToken} email={email} riskActions={riskActions} riskInformation={riskInformation} riskConsequence={riskConsequence} tableData={riskMatrixTableData}></RiskMatrixPDF>);
    }

    return (
        <>
            <Helmet>
                <title>Risk Matrix - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    Risk Matrix
                </Heading>
                {content}
                <ActionsWrapper hasOne>
                    <BtnsContainer>
                        <Button
                            $success
                            onClick={() => {
                                history.push({
                                    pathname: '/it-security/reports',
                                    state: {
                                        type: ReportTypes.RISK_MATRIX,
                                    }
                                });
                            }}
                        >
                            Download Report
                        </Button>
                        {/* {riskConsequence === null ? "" : pdfTest} */}
                    </BtnsContainer>
                </ActionsWrapper>
            </Wrapper>
        </>
    );
}

export default RiskMatrixLandingPage;
