import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import SolutionBuildingBlockQuestionData from '../SolutionBuildingBlockData.json';

const SolutionBuildingBlockDetailDialog = (props) => {

  // ** Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, toggle, data, handleEditClick } = props;

  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (open && data) {
      setDialogTitle(data["name"] || '');
    }
  }, [open, data])

  return (
    <Dialog
      open={open}
      onClose={toggle}
      scroll="paper"
      size="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ paddingRight: "80px" }} noWrap as={Typography} title={dialogTitle}>
        {dialogTitle}
      </DialogTitle>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <IconButton
          title="edit"
          aria-label="edit"
          onClick={(e) => {
            handleEditClick(data.caseID, data.status, data);
            toggle(e);
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="close"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        {
          open && data && <>
            <Card>
              <CardHeader title="Solution Building Block Details" />
              <Divider />
              {
                SolutionBuildingBlockQuestionData[0].subQuestions.map((question, index) => {
                  let value = data[question.key];

                  if (question.key === "solutionID") {
                    if (data?.solution?.solutionID) {
                      value = data.solution.solutionID;

                      return <React.Fragment key={`${data.caseID}-${question.key}`}>
                        <Box sx={{ px: 2, py: 1.25 }}>
                          <Box >
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                              {question.qHeading}
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                              {typeof value === 'string' ? value : '-'}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 1.25 }}>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                              {"Solution Name"}
                            </Typography>
                            <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                              {data.solution.solutionName || "-"}
                            </Typography>
                          </Box>

                          {
                            data?.project ? <>
                              <Box sx={{ mt: 1.25 }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                  {"Project ID"}
                                </Typography>
                                <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                                  {data.project.projectID || "-"}
                                </Typography>
                              </Box>
                              <Box sx={{ mt: 1.25 }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                  {"Project Name"}
                                </Typography>
                                <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                                  {data.project.name || "-"}
                                </Typography>
                              </Box>
                            </> : null
                          }

                        </Box>
                        <Divider />
                      </React.Fragment>
                    }
                  }

                  if (question.type === "date") {
                    value = "";
                    if (data[question.key] && data[question.key]['java.util.Date']) {
                      value = dayjs(new Date(data[question.key]['java.util.Date'])).format('DD/MM/YYYY')
                    }
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                          {typeof value === 'string' && value ? value : '-'}
                        </Typography>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  }

                  return <React.Fragment key={`${data.caseID}-${question.key}`}>
                    <Box sx={{ px: 2, py: 1.25 }}>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                        {question.qHeading}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                        {typeof value === 'string' && value ? value : '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </React.Fragment>
                })
              }
            </Card>
          </>
        }
      </DialogContent>
    </Dialog>
  );
};

export default SolutionBuildingBlockDetailDialog;