import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { NoteCard } from "./NoteStepper.styled";
import React, { useEffect } from "react";
import { Button } from "../../../../components/UI/Button/Button.styled";
import NewNote from "../NewNote/NewNote";
import AvatarImg from "../../../../images/avatar-img.png";

function NoteStepper(props) {
    const { notes, handleAnswer, onClick, activeNumber, addNote } = props;
    let renderedNotes = notes.map((note, index) => {

        return <NoteDetail key={index} note={note} />;
    });

    return <NoteCard><NewNote activeNumber={activeNumber} addNote={addNote} />
        {renderedNotes}</NoteCard>;
}

export default NoteStepper;

function NoteDetail(props) {
    const { note } = props;
    const [data, setData] = React.useState(null);
    const [dateString, setDateString] = React.useState("");

    useEffect(() => {
        try {
            let dataObj = JSON.parse(note["value"]);
            if (dataObj) {
                setData(dataObj);
            }
        } catch (error) {
            //error
        }
    }, [note])

    useEffect(() => {
        if (data === null) return;
        const date = new Date(note["modification-date"]["java.util.Date"]);
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: "numeric", minute: "numeric" }
        const dateString1 = date.toLocaleDateString("en-AU", dateOptions) + ", " + date.toLocaleTimeString("en-AU", timeOptions);
        setDateString(dateString1);
    }, [data])



    return data !== null ? (<List sx={{ width: '100%' }}>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar alt={`${data["firstName"]} ${data["lastName"]}`} src={AvatarImg} />
            </ListItemAvatar>
            <ListItemText
                primary={dateString}
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {`${data["firstName"]} ${data["lastName"]}`}
                        </Typography>
                        {` - ${data["note"]}`}
                    </React.Fragment>
                }
            />
        </ListItem>
        <Divider variant="inset" component="li" />
    </List>) : ""
}