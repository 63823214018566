import { useState, useEffect, useCallback } from "react";

import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Badge, Card, CardActions, CardContent, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import { DomainCard, QuestionHeadWrapper, QuestionNumber } from "./ControlSelectionDialog.styled";
import QuestionHeading from "../../../../../components/Question/QuestionHead/QuestionHeading/QuestionHeading";
import { Button } from "../../../../../components/UI/Button/Button.styled";
import { toast } from "react-toastify";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function ControlCheckList(props) {
    const { parentIndex, sections, handleCheckBoxClick, singleSelection, selectedControls } = props;

    function isChecked(singleSelection, selectedControls, controlKey) {
        let checked = false;
        if (singleSelection) {
            checked = controlKey === selectedControls;
        } else {
            checked = selectedControls.includes(controlKey);
        }
        return checked;
    }

    return (
        <CardContent>
            <Grid container spacing={{ xs: 1, md: 4 }}>
                {sections.map((section, sIndex) => (
                    <Grid item xs={12} md={6} key={`section-${sIndex}`} >
                        <Typography variant="subtitle1" gutterBottom style={{ fontWeight: "500" }}>
                            {section.sectionID}. {section.sectionHeading}
                        </Typography>
                        <FormControl component="fieldset">
                            {section.controls.map((control, cIndex) => (
                                <FormControlLabel
                                    key={`control-${sIndex}-${cIndex}`}
                                    value="end"
                                    control={
                                        <Checkbox
                                            checked={isChecked(singleSelection, selectedControls, control.key.replaceAll("-", ""))}
                                            // onClick={() => handleCheckBoxClick(parentIndex, sIndex, cIndex)}
                                            onClick={() => handleCheckBoxClick(control.key.replaceAll("-", ""))}
                                            style={{ color: '#3a8d1c' }}
                                        />
                                    }
                                    label={`${control.controlID}. ${control.controlHeading}`}
                                    labelPlacement="end"
                                />
                            ))}
                        </FormControl>
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    );
}

const ControlSelectionDialog = (props) => {
    const { open, toggle, domainData, singleSelection = false, riskTitle } = props;

    const [controls, setControls] = useState([]);
    const [selectedControls, setSelectedControls] = useState(null);
    const [dialogTitle, setDialogTitle] = useState("");

    useEffect(() => {
        if (open && domainData?.length > 0) {
            setControls(domainData.map((item) => ({ ...item, isExpanded: false })));
            setSelectedControls(singleSelection ? props?.selectedControls : (props?.selectedControls || []));
            setDialogTitle(props?.title || "Applicable Controls");
        }
    }, [domainData, open, props?.selectedControls, props?.title, singleSelection])

    function getTotalSelected(domain) {
        let count = 0;
        domain.sections.forEach((section) => {
            section.controls.forEach((control) => {
                let controlKey = control.key.replaceAll("-", "");
                if (selectedControls && (controlKey === selectedControls || selectedControls.includes(controlKey))) count++;
            })
        })
        return count;
    }

    const handleExpandClick = (index) => {
        setControls(
            (prev) => prev.map((control, i) => ({ ...control, isExpanded: index === i ? !control.isExpanded : control.isExpanded }))
        );
    };

    const handleCheckBoxClick = useCallback((controlKey) => {
        if (singleSelection) {
            setSelectedControls((prev) => prev === controlKey ? null : controlKey);
        } else {
            setSelectedControls(
                (prev) => {
                    let newSelectedControls = prev?.length > 0 ? [...prev] : [];
                    if (newSelectedControls.indexOf(controlKey) > -1) {
                        newSelectedControls = newSelectedControls.filter((ctr) => ctr !== controlKey);
                    } else {
                        newSelectedControls.push(controlKey);
                    }
                    return newSelectedControls;
                }
            );
        }
    }, [singleSelection]);

    let renderControls = controls.map((domain, index) => {
        return <div key={index} style={{ marginLeft: "40px" }}>
            <CardActions disableSpacing >
                <Badge badgeContent={getTotalSelected(domain)} color="success">
                    <Typography variant="title1" fontWeight={500} onClick={(e) => handleExpandClick(index)}>{domain?.domainID} . {domain?.domainHeading} &nbsp;</Typography>
                </Badge>
                <ExpandMore
                    expand={domain.isExpanded}
                    onClick={(e) => handleExpandClick(index)}
                    aria-expanded={domain.isExpanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={domain.isExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <ControlCheckList
                        selectedControls={selectedControls}
                        parentIndex={index}
                        key={index}
                        sections={domain.sections}
                        handleCheckBoxClick={handleCheckBoxClick}
                        singleSelection={singleSelection}
                    />
                </CardContent>
            </Collapse>
            <Divider />
        </div>;
    });

    function handleSubmit() {
        if (singleSelection) {
            if (!selectedControls) {
                toast.error("Please select at least one control");
                return;
            }
        } else {
            if (!(selectedControls?.length > 0)) {
                toast.error("Please select at least one control");
                return;
            }
        }

        props.onSubmit(selectedControls);
    }

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={(e, reason) => {
                if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
                    toggle()
                }
            }}
            fullWidth
            maxWidth="lg"
            scroll="paper"
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={toggle}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <DomainCard>
                    {
                        riskTitle?.length > 0 && <QuestionHeadWrapper>
                            <QuestionNumber>2.</QuestionNumber>
                            <QuestionHeading hasInfo={""}>
                                Select applicable controls to treat the risk title "{riskTitle}"
                            </QuestionHeading>
                        </QuestionHeadWrapper>
                    }
                    {renderControls}
                </DomainCard>
            </DialogContent>
            <DialogActions sx={{ padding: "20px 24px" }}>
                <Button
                    $success
                    onClick={(e) => handleSubmit()}
                >
                    Submit
                </Button>
                <Button $error onClick={(e) => toggle()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ControlSelectionDialog;