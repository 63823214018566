import Registration from './form/Registration'
import './Navbar.css'
import keycloak from '../../../../Keycloak';
import logo from '../../../../images/logo0.2.png'
import React, { useState, useEffect } from 'react';
const Navbar = () => {
  const [navbar, setNavbar] = useState(false)
  const changebg = () => {
    if (window.scrollY >= 13) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  // Rest of the code...

  useEffect(() => {
    window.addEventListener('scroll', changebg);
  }, []);

  return (
    <>
      <div className='Navbar'>

        <div className={navbar ? "navbar__main" : 'nav_bg_clr'}>
          <nav className="d-flex  justify-content-between align-items-center" style={{ width: '99vw' }}>
            <div>
              <img src={logo} className="ms-3 image-fluid" alt="" />
            </div>
            <div style={{ overflowX: "hidden" }} className="ms-auto">
              <button

                className="nav_btn me-3"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
                onClick={() => keycloak.login()}
              >
                Login
              </button>
              <button
                data-bs-toggle="modal" data-bs-target="#exampleModal"
                className="nav_btn me-3"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
              >
                Register
              </button>
            </div>
          </nav>
        </div>

      </div>


      {/* modal  */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#000" }}>
              <h2 className="modal-title" id="exampleModalLabel">Registration</h2>
              {/* <div className="title">Registration</div> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundColor: "#fff" }}></button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#000" }}>
              <Registration isSubmit={false} />
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
