import { asyncForEach } from "../../shared/utility";
import { CONTAINER_ID, HealthCheckProcess, OrganisationalDetailsProcess, RiskMatrixProcess, RiskAssessmentProcess, IARmainProcess, IARadvanceProcess } from "../../store/auth-context";
import * as fetchInstance from "../../utility/fetch-instance";
import { beautifyUniqueID } from "../../utility/utility";

// Global Variables for API Requests

//statusCode 1=active, 2=completed
async function getProcessInstance(process, statusCode, token, sub_id) {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances?status=${statusCode}&pageSize=10000&sortOrder=false`,
            headers: { "Authorization": `Bearer ${token}` },
        }
        await fetchInstance.apiRequest(config).then((response) => {

            const dataArr = response.data["process-instance"];
            if (dataArr.length > 0) {
                result["success"] = true;
                result["data"] = filterResponse(dataArr, process, sub_id);
            } else {
                result["success"] = false;
                result["data"] = 0;
            }
        });

    } catch (err) {
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = 0;
    }
    return result;
}

//filter instance id
function filterResponse(dataArr, processID, initiator) {
    function isOrg(org) {
        return org["process-id"] === processID;
    }
    //filter array by processID
    const process = dataArr.find(isOrg);
    return process["process-instance-id"] ?? 0;
}

//filter instance id list
function filterResponseList(dataArr, processID, initiator) {
    var idList = [];
    let filteredArr = dataArr.filter(element => element["process-id"] === processID);
    idList = filteredArr.slice(0, initiator || 5);
    return idList;
}

async function getHCProcessInstanceList(process, statusCode, token, sub_id, size) {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances?status=${statusCode}&pageSize=10000&sortOrder=false`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        await fetchInstance.apiRequest(config).then(async (response) => {

            if (response.data["process-instance"].length > 0) {
                //completed
                const dataArr = response.data["process-instance"];
                const _processInstanceIDList = filterResponseList(dataArr, process, size);

                var instanceList = _processInstanceIDList.map(function (element) {
                    return element?.["process-instance-id"];
                }).sort((a, b) => a - b);

                var myArr = new Array(instanceList.length);

                await asyncForEach(instanceList, async (item, index) => {
                    await getProcessVariables(item, token).then((res) => {
                        const myobj = { "id": item, "index": index };
                        myobj["score"] = res.data.filter(obj => { return obj.name === "TotalScore" })[0]?.value;
                        const endDate = res.data.filter(obj => { return obj.name === "StartDate" })[0]?.value;
                        const date = new Date(parseInt(endDate));
                        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                        myobj["date"] = date.toLocaleDateString("en-US", dateOptions);
                        myobj["datetime"] = parseInt(endDate);

                        const score = res.data.filter(obj => { return obj.name === "CurrentMaturity" })[0];
                        var scoreArray = [];
                        if (score) {
                            const scoreString = score["value"].slice(1, -1); //remove brackets

                            scoreString.split(',').forEach(currentScore => {
                                scoreArray.push(Math.floor(currentScore));
                            });

                            myobj["maturity"] = scoreArray;
                        };
                        myArr[index] = myobj;
                    });
                })

                // instanceList.forEach((item, index) => {
                //     getProcessVariables(item, token).then((res) => {
                //         const myobj = { "id": item, "index": index };
                //         myobj["score"] = res.data.filter(obj => { return obj.name === "TotalScore" })[0]?.value;
                //         const endDate = res.data.filter(obj => { return obj.name === "StartDate" })[0]?.value;
                //         const date = new Date(parseInt(endDate));
                //         const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                //         myobj["date"] = date.toLocaleDateString("en-US", dateOptions);

                //         const score = res.data.filter(obj => { return obj.name === "CurrentMaturity" })[0];
                //         var scoreArray = [];
                //         if (score) {
                //             const scoreString = score["value"].slice(1, -1); //remove brackets

                //             scoreString.split(',').forEach(currentScore => {
                //                 scoreArray.push(Math.floor(currentScore));
                //             });

                //             myobj["maturity"] = scoreArray;
                //         };
                //         myArr[index] = myobj;
                //     });
                // });


                result["success"] = true;
                result["data"] = myArr;
            } else {
                result["success"] = false;
                result["data"] = [];
            }
        });

    } catch (err) {
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }

    return result;
}

async function getProcessInstanceList(process, statusCode, token, sub_id, size) {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances?status=${statusCode}&pageSize=10000&sortOrder=false`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        await fetchInstance.apiRequest(config).then(async (response) => {

            if (response.data["process-instance"].length > 0) {
                //completed
                const dataArr = response.data["process-instance"];
                const _processInstanceIDList = filterResponseList(dataArr, process, size);

                var instanceList = _processInstanceIDList.map(function (element) {
                    return element?.["process-instance-id"];
                }).sort((a, b) => a - b);

                var myArr = new Array(instanceList.length);

                await asyncForEach(instanceList, async (item, index) => {
                    await getProcessVariables(item, token).then((res) => {
                        const myobj = { "id": item, "index": index, data: res.data };
                        const endDate = res.data.filter(obj => { return obj.name === "StartDate" })[0]?.value;
                        const date = new Date(parseInt(endDate));
                        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                        myobj["date"] = date.toLocaleDateString("en-US", dateOptions);
                        myobj["datetime"] = parseInt(endDate);
                        myArr[index] = myobj;
                    });
                })

                result["success"] = true;
                result["data"] = myArr;
            } else {
                result["success"] = false;
                result["data"] = [];
            }
        });

    } catch (err) {
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }

    return result;
}

async function getTPRMProcessInstanceList(process, statusCode, token, sub_id, size) {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances?status=${statusCode}&pageSize=10000&sortOrder=false`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        await fetchInstance.apiRequest(config).then((response) => {

            if (response.data["process-instance"].length > 0) {
                //completed
                const dataArr = response.data["process-instance"];
                const _processInstanceIDList = filterResponseList(dataArr, process);

                var instanceList = _processInstanceIDList.map(function (element) {
                    return element?.["process-instance-id"];
                }).sort((a, b) => a - b);

                result["success"] = true;
                result["data"] = instanceList;
            } else {
                result["success"] = false;
                result["data"] = [];
            }
        });

    } catch (err) {
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }

    return result;
}

/**
 * Get health check process instance ID by initiator
*/
export const getActiveHealthCheck = async (token, email) => {
    return getProcessInstance(HealthCheckProcess, 1, token, email)
}

/**
 * Get health check process instance ID by initiator
*/
export const getCompletedHealthCheck = async (token, email) => {
    return getProcessInstance(HealthCheckProcess, 2, token, email);
}

/**
 * Get completed health check process instance ID list by initiator
*/
export const getCompletedHealthCheckList = async (token, email) => {
    return getHCProcessInstanceList(HealthCheckProcess, 2, token, email, 10);
}

/**
 * Get health check process instance ID by initiator
*/
export const getActiveRiskMatrix = async (token, email) => {
    return getProcessInstance(RiskMatrixProcess, 1, token, email);
}

/**
 * Get health check process instance ID by initiator
*/
export const getCompletedRiskMatrix = async (token, email) => {
    return getProcessInstance(RiskMatrixProcess, 2, token, email);
}

export const getCompletedRiskMatrixList = async (token, email) => {
    return getProcessInstanceList(RiskMatrixProcess, 2, token, email, 10);
}

export const getActiveOrganization = async (token, email) => {
    return getProcessInstance(OrganisationalDetailsProcess, 1, token, email);
}

/**
 * Get health check process instance ID by initiator
*/
export const getCompletedOrganization = async (token, email) => {
    return getProcessInstance(OrganisationalDetailsProcess, 2, token, email);
}

/**
 * Get completed health check process instance ID list by initiator
*/
export const getCompletedTPRMList = async (token, email) => {
    return getTPRMProcessInstanceList(RiskAssessmentProcess, 2, token, email, 20);
}


/**
 * Get process variables
*/
export const getProcessVariables = async (processInstance, token) => {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables/instances`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        const res = await fetchInstance.apiRequest(config);
        result["data"] = res.data["variable-instance"];
        result["status"] = "success";

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

/**
 * Get process variables history
*/
export const getProcessVariableHistory = async (processInstance, token, varName) => {
    const result = { status: "error" };
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables/instances/${varName}`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        const res = await fetchInstance.apiRequest(config);
        result["data"] = res.data["variable-instance"];
        result["status"] = "success";

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

/**
 * update process variables
*/
export const updateProcessVariables = async (processInstance, token, data) => {
    const result = { status: "error" };
    try {
        let config = {
            method: "post",
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables`,
            data: JSON.stringify(data),
            headers: { "Authorization": `Bearer ${token}` }
        }
        const res = await fetchInstance.apiRequest(config);

        if (res.status === 201) {
            result["status"] = "success";
        } else {
            result["status"] = "error";
        }

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

/**
 * Set process variables
*/
export const setProcessVariable = async (processInstance, token, data, varName) => {
    const result = { status: "error" };
    try {
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variable/${varName}`,
            data: JSON.stringify(data),
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }
        }
        const res = await fetchInstance.apiRequest(config);

        if (res.status === 201) {
            result["status"] = "success";
        } else {
            result["status"] = "error";
        }

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

/** 
 * Get previous questions
*/
export const getCompletedQuestions = async (processInstance, token) => {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/nodes/instances?pageSize=1000&`,
            headers: { "Authorization": `Bearer ${token}` }
        }
        const res = await fetchInstance.apiRequest(config);
        let nodes = res.data["node-instance"].filter(node => node["node-name"]?.startsWith('Cal') !== true && node["node-name"] !== null);
        let completedNodes = nodes.filter(node => node["node-completed"] === true);
        result["data"] = completedNodes.reverse();
        result["status"] = "success";

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

export const getTotalScore = async (hcScore = 0, riskScore = 0, token) => {
    const result = {};
    try {
        const requestData = {
            "model-namespace": "https://kiegroup.org/dmn/_B6C525AF-8DDF-479C-B699-090157815E67",
            "model-name": "CalculateCisozenScore",
            "dmn-context":
            {
                "HealthCheckAndRecommendationScore": hcScore,
                "RiskMatrixScore": riskScore

            }
        }

        let config = {
            method: 'post',
            url: `/services/rest/server/containers/${CONTAINER_ID}/dmn`,
            data: JSON.stringify(requestData),
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' }
        }

        var response = await fetchInstance.apiRequest(config)
        var cisozenScore = response.data["result"]["dmn-evaluation-result"]["dmn-context"]["TotalScore"];
        if (cisozenScore) {
            result["status"] = "success";
            result["data"] = { "maxScore": cisozenScore["Max-Score"], "currentScore": cisozenScore["Current-Score"] };
        } else {
            result["status"] = "error";
            result["data"] = { "currentScore": 0, "maxScore": 320 };
        }

    } catch (err) {
        result["status"] = "error";
        result["message"] = err.message;
        result["data"] = { "currentScore": 0, "maxScore": 320 };
    }
    return result;
}

/**
 * Calculate remaining duration for the dashboard.
 */
//get duration from active healthch / if hc was completed and risk matrix process instance is active get from completed hc
export const getDuration = (startDateMilli) => {
    const maxDaysMilli = 1000 * 60 * 60 * 24 * 7 * 4; //4 weeks/28 days
    const currentDateMilli = Date.now(); //current date

    const currentDaysMilli = currentDateMilli - startDateMilli;
    const remainingDays = (Math.round((maxDaysMilli - currentDaysMilli) / (1000 * 60 * 60 * 24))) - 1;
    return { "maxDays": 28, "remainingDays": remainingDays < 0 ? 0 : remainingDays }
}

/**
 * Calculate remaining duration for the health check.
 */
//get duration from active healthch / if hc was completed and risk matrix process instance is active get from completed hc
export const getHealthCheckDuration = (startDateMilli) => {
    const maxDaysMilli = 1000 * 60 * 60 * 24 * 7 * 2; //2 weeks/14 days
    const currentDateMilli = Date.now(); //current date

    const currentDaysMilli = currentDateMilli - startDateMilli;
    const remainingDays = (Math.round((maxDaysMilli - currentDaysMilli) / (1000 * 60 * 60 * 24))) - 1;
    return { "maxDays": 14, "remainingDays": remainingDays < 0 ? 0 : remainingDays }
}

//get from completed hc
export const getMaturityLevel = () => {
    //remaining for the red bar, currentlevel for the green bar
    return { "remaining": [1, 1, 3, 2, 4], "currentLevel": [4, 4, 2, 3, 1] }
}

export const generateRiskMatrixTableDataFromProcessVariableInstance = (data) => {
    const dimensions = data.find(element => element.name === "Dimensions").value[0];
    let tableData = [];
    let headingColumns = [];
    let selectedValue = data.find(element => element.name === "SelectedTolerance").value;

    var valueList = {
        "Low-1": 1,
        "Low-2": 2,
        "Low-3": 3,
        "Low-4": 4,
        "Low-5": 5,
        "Low-6": 6,
        "Medium-1": 7,
        "Medium-2": 8,
        "Medium-3": 9,
        "Medium-4": 10,
        "Medium-5": 11,
        "Medium-6": 12,
        "Medium-7": 13,
        "Medium-8": 14,
        "Medium-9": 15,
        "High-1": 16,
        "High-2": 17,
        "High-3": 18,
        "High-4": 19,
        "High-5": 20,
        "High-6": 21,
        "High-7": 22,
        "Extreme-1": 23,
        "Extreme-2": 24,
        "Extreme-3": 25,
    };


    function isHigher(string1, string2) {
        return valueList[string2] >= valueList[string1]
    }

    function isShowTopBorder(key, rowIndex, isSelected, tableData) {
        const index = rowIndex > 0 ? rowIndex : 0;
        return index === 0 ? isSelected : tableData[rowIndex - 1][`${key}`]["isSelected"] === true ? false : isSelected;
    }

    if (dimensions === "3") {
        headingColumns = [
            "",
            "Insignificant",
            "Moderate",
            "Catastrophic",
        ];

        tableData = [
            {
                number: { "value": "Rare", "isSelected": false, "showLeftBorder": false },
                insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A1").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "B1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B1").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "C1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C1").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Possible", "isSelected": false, "showLeftBorder": false },
                insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A2").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "B2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B2").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "C2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C2").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Almost Certain", "isSelected": false, "showLeftBorder": false },
                insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A3").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "B3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B3").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "C3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C3").value), showLeftBorder: false, showTopBorder: false },
            }
        ];
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showTopBorder: isShowTopBorder("insignificant", index, row.insignificant.isSelected, tableData),
                },
                Moderate: {
                    ...row.Moderate,
                    showTopBorder: isShowTopBorder("Moderate", index, row.Moderate.isSelected, tableData),
                },
                catastrophic: {
                    ...row.catastrophic,
                    showTopBorder: isShowTopBorder("catastrophic", index, row.catastrophic.isSelected, tableData),
                },
            }
        })
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showLeftBorder: row.insignificant.isSelected,
                },
                Moderate: {
                    ...row.Moderate,
                    showLeftBorder: row.insignificant.isSelected ? false : row.Moderate.isSelected,
                },
                catastrophic: {
                    ...row.catastrophic,
                    showLeftBorder: row.Moderate.isSelected ? false : row.catastrophic.isSelected,
                },
            }
        })

    } else if (dimensions === "4") {
        headingColumns = [
            "",
            "Insignificant",
            "Minor",
            "Moderate",
            "Catastrophic",
        ];
        tableData = [
            {
                number: { "value": "Rare", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A1").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B1").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C1").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "D1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D1").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Possible", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A2").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B2").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C2").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "D2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D2").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Likely", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A3").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B3").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C3").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "D3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D3").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Almost Certain", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A4").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B4").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C4").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "D4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D4").value), showLeftBorder: false, showTopBorder: false },
            }
        ];
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showTopBorder: isShowTopBorder("insignificant", index, row.insignificant.isSelected, tableData),
                },
                minor: {
                    ...row.minor,
                    showTopBorder: isShowTopBorder("minor", index, row.minor.isSelected, tableData),
                },
                Moderate: {
                    ...row.Moderate,
                    showTopBorder: isShowTopBorder("Moderate", index, row.Moderate.isSelected, tableData),
                },
                catastrophic: {
                    ...row.catastrophic,
                    showTopBorder: isShowTopBorder("catastrophic", index, row.catastrophic.isSelected, tableData),
                },
            }
        })
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showLeftBorder: row.insignificant.isSelected,
                },
                minor: {
                    ...row.minor,
                    showLeftBorder: row.insignificant.isSelected ? false : row.minor.isSelected,
                },
                Moderate: {
                    ...row.Moderate,
                    showLeftBorder: row.minor.isSelected ? false : row.Moderate.isSelected,
                },
                catastrophic: {
                    ...row.catastrophic,
                    showLeftBorder: row.Moderate.isSelected ? false : row.catastrophic.isSelected,
                },
            }
        })
    } else if (dimensions === "5") {
        headingColumns = [
            "",
            "Insignificant",
            "Minor",
            "Moderate",
            "Major",
            "Catastrophic",
        ];
        tableData = [
            {
                number: { "value": "Rare", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A1").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B1").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C1").value), showLeftBorder: false, showTopBorder: false },
                Major: { "value": data.find(element => element.name === "D1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D1").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "E1").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "E1").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Unlikely", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A2").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B2").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C2").value), showLeftBorder: false, showTopBorder: false },
                Major: { "value": data.find(element => element.name === "D2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D2").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "E2").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "E2").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Possible", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A3").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B3").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C3").value), showLeftBorder: false, showTopBorder: false },
                Major: { "value": data.find(element => element.name === "D3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D3").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "E3").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "E3").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Likely", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A4").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B4").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C4").value), showLeftBorder: false, showTopBorder: false },
                Major: { "value": data.find(element => element.name === "D4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D4").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "E4").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "E4").value), showLeftBorder: false, showTopBorder: false },
            },
            {
                number: { "value": "Almost Certain", "isSelected": false },
                insignificant: { "value": data.find(element => element.name === "A5").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "A5").value), showLeftBorder: false, showTopBorder: false },
                minor: { "value": data.find(element => element.name === "B5").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "B5").value), showLeftBorder: false, showTopBorder: false },
                Moderate: { "value": data.find(element => element.name === "C5").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "C5").value), showLeftBorder: false, showTopBorder: false },
                Major: { "value": data.find(element => element.name === "D5").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "D5").value), showLeftBorder: false, showTopBorder: false },
                catastrophic: { "value": data.find(element => element.name === "E5").value, "isSelected": isHigher(selectedValue, data.find(element => element.name === "E5").value), showLeftBorder: false, showTopBorder: false },
            },
        ];
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showTopBorder: isShowTopBorder("insignificant", index, row.insignificant.isSelected, tableData),
                },
                minor: {
                    ...row.minor,
                    showTopBorder: isShowTopBorder("minor", index, row.minor.isSelected, tableData),
                },
                Moderate: {
                    ...row.Moderate,
                    showTopBorder: isShowTopBorder("Moderate", index, row.Moderate.isSelected, tableData),
                },
                Major: {
                    ...row.Major,
                    showTopBorder: isShowTopBorder("Major", index, row.Major.isSelected, tableData),
                },
                catastrophic: {
                    ...row.catastrophic,
                    showTopBorder: isShowTopBorder("catastrophic", index, row.catastrophic.isSelected, tableData),
                },
            }
        })
        tableData = tableData.map((row, index) => {
            return {
                ...row,
                insignificant: {
                    ...row.insignificant,
                    showLeftBorder: row.insignificant.isSelected,
                },
                minor: {
                    ...row.minor,
                    showLeftBorder: row.insignificant.isSelected ? false : row.minor.isSelected,
                },
                Moderate: {
                    ...row.Moderate,
                    showLeftBorder: row.minor.isSelected ? false : row.Moderate.isSelected,
                },
                Major: {
                    ...row.Major,
                    showLeftBorder: row.Moderate.isSelected ? false : row.Major.isSelected,
                },
                catastrophic: {
                    ...row.catastrophic,
                    showLeftBorder: row.Major.isSelected ? false : row.catastrophic.isSelected,
                },
            }
        })
    }

    return {
        "headingColumns": headingColumns,
        "tableData": tableData,
        "selectedValue": data.find(element => element.name === "SelectedTolerance").value
    };

}

//get from completed risk matrix
export const getRiskMatrixTableData = async (processInstance, token) => {
    let result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables/instances`,
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' }
        }
        const res = await fetchInstance.apiRequest(config);
        const data = res.data["variable-instance"];
        // const dimensions = data.find(element => element.name === "Dimensions").value[0];
        // var tableData = [];
        // var headingColumns = [];

        // if (dimensions === "3") {
        //     headingColumns = [
        //         "",
        //         "Insignificant",
        //         "Moderate",
        //         "Catastrophic",
        //     ];

        //     tableData = [
        //         {
        //             number: { "value": "Rare", "isSelected": false, "showLeftBorder": false },
        //             insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": false, "showLeftBorder": false },
        //             Moderate: { "value": data.find(element => element.name === "B1").value, "isSelected": false, "showLeftBorder": false },
        //             catastrophic: { "value": data.find(element => element.name === "C1").value, "isSelected": false, "showLeftBorder": false },
        //         },
        //         {
        //             number: { "value": "Possible", "isSelected": false, "showLeftBorder": false },
        //             insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": false, "showLeftBorder": false },
        //             Moderate: { "value": data.find(element => element.name === "B2").value, "isSelected": false, "showLeftBorder": false },
        //             catastrophic: { "value": data.find(element => element.name === "C2").value, "isSelected": false, "showLeftBorder": false },
        //         },
        //         {
        //             number: { "value": "Almost Certain", "isSelected": false, "showLeftBorder": false },
        //             insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": false, "showLeftBorder": false },
        //             Moderate: { "value": data.find(element => element.name === "B3").value, "isSelected": false, "showLeftBorder": false },
        //             catastrophic: { "value": data.find(element => element.name === "C3").value, "isSelected": false, "showLeftBorder": false },
        //         }
        //     ];
        // } else if (dimensions === "4") {
        //     headingColumns = [
        //         "",
        //         "Insignificant",
        //         "Minor",
        //         "Moderate",
        //         "Catastrophic",
        //     ];
        //     tableData = [
        //         {
        //             number: { "value": "Rare", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B1").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C1").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "D1").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Possible", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B2").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C2").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "D2").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Likely", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B3").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C3").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "D3").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Almost Certain", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A4").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B4").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C4").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "D4").value, "isSelected": false },
        //         }
        //     ];
        // } else if (dimensions === "5") {
        //     headingColumns = [
        //         "",
        //         "Insignificant",
        //         "Minor",
        //         "Moderate",
        //         "Major",
        //         "Catastrophic",
        //     ];
        //     tableData = [
        //         {
        //             number: { "value": "Rare", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A1").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B1").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C1").value, "isSelected": false },
        //             Major: { "value": data.find(element => element.name === "D1").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "E1").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Unlikely", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A2").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B2").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C2").value, "isSelected": false },
        //             Major: { "value": data.find(element => element.name === "D2").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "E2").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Possible", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A3").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B3").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C3").value, "isSelected": false },
        //             Major: { "value": data.find(element => element.name === "D3").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "E3").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Likely", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A4").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B4").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C4").value, "isSelected": false },
        //             Major: { "value": data.find(element => element.name === "D4").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "E4").value, "isSelected": false },
        //         },
        //         {
        //             number: { "value": "Almost Certain", "isSelected": false },
        //             insignificant: { "value": data.find(element => element.name === "A5").value, "isSelected": false },
        //             minor: { "value": data.find(element => element.name === "B5").value, "isSelected": false },
        //             Moderate: { "value": data.find(element => element.name === "C5").value, "isSelected": false },
        //             Major: { "value": data.find(element => element.name === "D5").value, "isSelected": false },
        //             catastrophic: { "value": data.find(element => element.name === "E5").value, "isSelected": false },
        //         },
        //     ];
        // }
        result["success"] = true;
        result["data"] = generateRiskMatrixTableDataFromProcessVariableInstance(data);

    } catch (err) {
        result["success"] = false;
        result["data"] = err.message;
    }
    return result;
}

//get from completed hc
export const getRecommendations = async (processInstance, token) => {
    const result = {};
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables/instances`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        const res = await fetchInstance.apiRequest(config);
        const dataArr = res.data["variable-instance"];

        const requestData = {
            "model-namespace": "https://kiegroup.org/dmn/_59F8E423-003F-47FD-A331-6E6C306126E1",
            "model-name": "recommendations",
            "dmn-context":
            {
                "answerSC1": dataArr.filter(obj => { return obj.name === "SC1a1" })[0]["value"],
                "answerSC2": dataArr.filter(obj => { return obj.name === "SC2a1" })[0]["value"],
                "answerSC3": dataArr.filter(obj => { return obj.name === "SC3a1" })[0]["value"],
                "answerSC4": dataArr.filter(obj => { return obj.name === "SC4a1" })[0]["value"],
                "answerSC5": dataArr.filter(obj => { return obj.name === "SC5a1" })[0]["value"],
                "answerSC6": dataArr.filter(obj => { return obj.name === "SC6a1" })[0]["value"],
                "answerSC7": dataArr.filter(obj => { return obj.name === "SC7a1" })[0]["value"],
                "answerSC8": dataArr.filter(obj => { return obj.name === "SC8a1" })[0]["value"],
                "answerSC9": dataArr.filter(obj => { return obj.name === "SC9a1" })[0]["value"],
                "answerSC10": dataArr.filter(obj => { return obj.name === "SC10a1" })[0]["value"],
                "answerSC11": dataArr.filter(obj => { return obj.name === "SC11a1" })[0]["value"],
                "answerSC12": dataArr.filter(obj => { return obj.name === "SC12a1" })[0]["value"],
                "answerSC13": dataArr.filter(obj => { return obj.name === "SC13a1" })[0]["value"],
                "answerSC14": dataArr.filter(obj => { return obj.name === "SC14a1" })[0]["value"],
                "answerSC15": dataArr.filter(obj => { return obj.name === "SC15a1" })[0]["value"],
                "answerSC16": dataArr.filter(obj => { return obj.name === "SC16a1" })[0]["value"],
                "answerSC17": dataArr.filter(obj => { return obj.name === "SC17a1" })[0]["value"],
                "answerSC18": dataArr.filter(obj => { return obj.name === "SC18a1" })[0]["value"],
                "answerSC19": dataArr.filter(obj => { return obj.name === "SC19a1" })[0]["value"],
                "answerSC20": dataArr.filter(obj => { return obj.name === "SC20a1" })[0]["value"],
                "answerSC21": dataArr.filter(obj => { return obj.name === "SC21a1" })[0]["value"],
                "answerSC22": dataArr.filter(obj => { return obj.name === "SC22a1" })[0]["value"],
                "answerSC23": dataArr.filter(obj => { return obj.name === "SC23a1" })[0]["value"],
                "answerSC24": dataArr.filter(obj => { return obj.name === "SC24a1" })[0]["value"],
                "answerSC25": dataArr.filter(obj => { return obj.name === "SC25a1" })[0]["value"],
                "answerSC26": dataArr.filter(obj => { return obj.name === "SC26a1" })[0]["value"],
                "answerSC27": dataArr.filter(obj => { return obj.name === "SC27a1" })[0]["value"],
                "answerSC28": dataArr.filter(obj => { return obj.name === "SC28a1" })[0]["value"],
                "answerSC29": dataArr.filter(obj => { return obj.name === "SC29a1" })[0]["value"],
                "answerSC30": dataArr.filter(obj => { return obj.name === "SC30a1" })[0]["value"],
                "answerSC31": dataArr.filter(obj => { return obj.name === "SC31a1" })[0]["value"],
                "answerSC32": dataArr.filter(obj => { return obj.name === "SC32a1" })[0]["value"]
            }
        }

        let config2 = {
            method: 'post',
            url: `/services/rest/server/containers/${CONTAINER_ID}/dmn`,
            data: JSON.stringify(requestData),
            headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' }
        }

        var reponse = await fetchInstance.apiRequest(config2)
        var responseArr = reponse.data["result"]["dmn-evaluation-result"]["dmn-context"]["Recommendations"];
        result["success"] = true;
        const fileterArr = (responseArr.filter((a) => a));
        result["data"] = fileterArr.length > 0 ? fileterArr[0] : { "recommendation": "", "reason": "" } //max 1
        // result["data"] = fileterArr;


    } catch (err) {
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

export const getOpenAssetsRegister = async () => {
    let result = {
        success: true,
        message: null,
        data: null
    };
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${IARadvanceProcess}/instances?status=open&page=0&pageSize=1000&sortOrder=false&withData=true`,
        };

        let response = await fetchInstance.apiRequest(config);
        if (response.status === 200) {
            let cases = response.data.instances;
            let tempAssets = [];
            cases.forEach((data) => {
                let asset = {}
                let assetInfo = data['case-file']['case-data']['assetInfo'] && JSON.parse(data['case-file']['case-data']['assetInfo']);
                if (assetInfo?.assetName !== undefined) {
                    assetInfo["c"] = data['case-file']['case-data']['confidentiality'] || "";
                    assetInfo["i"] = data['case-file']['case-data']['integrity'] || "";
                    assetInfo["a"] = data['case-file']['case-data']['availability'] || "";
                    asset = assetInfo;
                }
                asset["id"] = data['case-id'];
                asset["caseID"] = data['case-id'];
                asset["assetID"] = beautifyUniqueID(data['case-id']);
                asset["status"] = data['case-status'];
                asset["case-data"] = data['case-file']['case-data'];
                tempAssets.push(asset);
            })
            result['data'] = [...tempAssets];
        }

    } catch (err) {
        console.error('DEBUG getAssetsRegister caught = ', err);
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}

export const getClosedAssetsRegister = async () => {
    let result = {
        success: true,
        message: null,
        data: null
    };
    try {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${IARmainProcess}/instances?status=closed&page=0&pageSize=1000&sortOrder=false&withData=true`,
        };

        let response = await fetchInstance.apiRequest(config);
        if (response.status === 200) {
            let cases = response.data.instances;
            let tempAssets = [];
            cases.forEach((data) => {
                let asset = {}
                let assetInfo = data['case-file']['case-data']['assetInfoAdvance'] && JSON.parse(data['case-file']['case-data']['assetInfoAdvance']);
                if (assetInfo?.assetName !== undefined) {
                    assetInfo["c"] = data['case-file']['case-data']['confidentiality'] || "";
                    assetInfo["i"] = data['case-file']['case-data']['integrity'] || "";
                    assetInfo["a"] = data['case-file']['case-data']['availability'] || "";
                    asset = assetInfo;
                }
                let caseQuestions = {
                    c: {},
                    a: {},
                    i: {},
                }
                Object.keys(data['case-file']['case-data']).forEach((key) => {
                    if (key.startsWith("a-c")) {
                        let questionIndex = key.replace("a-c", "");
                        if (data['case-file']['case-data'][key]) {
                            caseQuestions.c[Number(questionIndex)] = JSON.parse(data['case-file']['case-data'][key]);
                        }
                    } else if (key.startsWith("a-a")) {
                        let questionIndex = key.replace("a-a", "");
                        if (data['case-file']['case-data'][key]) {
                            caseQuestions.a[Number(questionIndex)] = JSON.parse(data['case-file']['case-data'][key]);
                        }
                    } else if (key.startsWith("a-i")) {
                        let questionIndex = key.replace("a-i", "");
                        if (data['case-file']['case-data'][key]) {
                            caseQuestions.i[Number(questionIndex)] = JSON.parse(data['case-file']['case-data'][key]);
                        }
                    }
                })
                asset["id"] = data['case-id'];
                asset["caseID"] = data['case-id'];
                asset["assetID"] = beautifyUniqueID(data['case-id']);
                asset["status"] = data['case-status'];
                asset["caseQuestions"] = caseQuestions;
                asset["case-data"] = data['case-file']['case-data'];
                tempAssets.push(asset);
            })
            result['data'] = [...tempAssets];
        }

    } catch (err) {
        console.error('DEBUG getAssetsRegister caught = ', err);
        result["success"] = false;
        result["message"] = err.message;
        result["data"] = [];
    }
    return result;
}