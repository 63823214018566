import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { useKeycloak } from "@react-keycloak/web";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import doneAnimation from "../../../../../assets/animations/done.json";
import { Button } from "../../../../../components/UI/Button/Button.styled";
import Heading from "../../../../../components/UI/Heading/Heading";
import Loader from "../../../../../components/UI/Loader/Loader";
import modalImg from "../../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, RiskAssessment } from "../../../../../store/auth-context";
import InfoModal from "../../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./RiskAssessmentPage.styled";
import E8QuestionsStepper from "../../../components/E8QuestionStepper/E8QuestionStepper";
import logo from "../../../../../images/logo0.2.png";
import ApplicableControls from "./components/ApplicableControls";
import { getCase, getProcessIDByCase, startMileStone, completeUserTask, getUserTask, startUserTask, getCaseFileData, getDMN } from "../TRAApi";
import { toggleAllowFetch, updateDataByCaseId } from "../../../../../utility/dataAction";
import { updateTrackerData } from "../../../../../utility/tracker.action";
import { Helmet } from "react-helmet-async";

function AddRiskAssessment(props) {
    const { data } = useParams();

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [activeIndex] = useState(0);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);
    const [controls, setControls] = useState(null);

    const [caseID, setCaseID] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [taskInstanceIDList, setTaskInstanceIDList] = useState(null);
    const [isControlSelection, setIsControlSelection] = useState(false);
    // const [riskData, setRiskData] = useState(null);
    const [domainData, setDomainData] = useState(null);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Risk Assessment Progress - ${isControlSelection ? "Control Selection" : "Risk Details"}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch, isControlSelection])

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    if (loading) {
        content = <Loader height="40vh" />;
    }

    const handleOneAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
    }, [questions]);

    let userAnsweredAllQuestions = questions.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
    }) : false;

    function userAnsweredControls() {
        let dataObj = [];
        try {
            controls.forEach((domain) => {
                domain.sections.forEach((section) => {
                    section.controls.forEach((control) => {
                        dataObj.push(control.isChecked);
                    });
                });
            });
        } catch (error) {

        }

        return dataObj.some(value => value === true);
    }

    useEffect(() => {
        if (CONTAINER_ID === "") return;
        if (data) {
            setCaseID(data);
        } else {
            getActiveCaseID();
        }
        getDomains();
    }, [CONTAINER_ID])

    //get caseID for riskAssessment
    function getActiveCaseID() {
        setFetching(true);
        getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'open', 1).then((response) => {
            if (response.success) {
                let caseID = response.data.instances[0]['case-id'];
                // let caseData = response.data.instances[0]['case-file']['case-data'];
                // setRiskData(caseData["riskData"]);
                setCaseID(caseID);
                setFetching(false);
            }


            if (response.success === false) {
                setFetching(false);
            }
        })
    }

    function getDomains() {
        getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains').then((response) => {
            if (response.success) {
                let data = response.data["dmnContext"]["domains"][0];
                setDomainData(data);
            }
        })
    }

    //get process instance id
    useEffect(() => {
        if (caseID === null) return;
        getProcessInstanceID(caseID);
        // getMileStones(caseID);
    }, [caseID])

    //get process instance id
    function getProcessInstanceID(_caseID) {
        setFetching(true);
        getProcessIDByCase(CONTAINER_ID, keycloak.token, _caseID)
            .then((response) => {
                if (response.success) {
                    let processInstanceID = response.data['process-instance'][0]['process-instance-id'];
                    setProcessInstanceID(processInstanceID);
                    setFetching(false);
                }
                if (response.success === false) {
                    setFetching(false);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong")
                dispatch(toggleAllowFetch(true));
                history.push("/it-security/risk-assessment");
            })
    }

    function fetchCaseFileData(status = 1) {
        getCaseFileData(CONTAINER_ID, keycloak.token, caseID).then((response) => {
            if (response.success === true) {

                let caseData;
                try {
                    caseData = response.data?.riskData?.["com.cisozen.RiskRegisterData"];
                    dispatch(updateDataByCaseId(keycloak, { caseID: caseID, riskData: { ...caseData, status: status } }))
                } catch (error) {
                    console.error("An error occurred:", error);
                    // Handle the error as needed
                }

                let questionObj = response.data["riskQuestions"];
                if (questionObj) {
                    let question = JSON.parse(questionObj);
                    if (caseData) {
                        let q = question?.subQuestions?.map((subQ) => {
                            let newSubQ = subQ;
                            newSubQ["qAnswer"] = caseData[subQ.key] || "";
                            return newSubQ;
                        });
                        question.subQuestions = q;
                    }
                    setQuestions([question]);
                }

                if (domainData) {
                    try {
                        let applicableControls = response.data["applicableControls"];
                        if (applicableControls === undefined) {
                            applicableControls = [];
                        }
                        let domainList = domainData;
                        domainList.forEach((domain) => {
                            domain.sections.forEach((section) => {
                                section.controls.forEach((control) => {

                                    control.isChecked = applicableControls.includes(control.key.replaceAll("-", ""));
                                });
                            });
                        });
                        setControls(domainList);
                    } catch (error) {

                    }

                }
                setFetching(false);
            }

            if (response.success === false) {
                setFetching(false);
            }
        });
    }

    //get questions
    function getQuestions(_processInstanceID) {
        setFetching(true);
        fetchCaseFileData();
    }

    //trigger risk detail
    useEffect(() => {
        if (caseID === null || processInstanceID === null) return;
        startRiskDetail(caseID);
        startApplicableControl(caseID);
    }, [caseID, processInstanceID]);

    useEffect(() => {
        if (processInstanceID === null) {
            return;
        }
        getQuestions(processInstanceID);
    }, [taskInstanceIDList]);

    //trigger milestone to start risk detail
    function startRiskDetail(_caseID) {
        setFetching(true);
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Risk Details').then((response) => {
            if (response.success === true) {
                getActiveUserTasks(processInstanceID, 'Risk Details');
                // setFetching(false);
            }

            if (response.success === false) {
                // setFetching(false);
            }
        })
    }

    //get active tasks
    function getActiveUserTasks(_processInstanceID, _taskName) {
        // setFetching(true);
        getUserTask(CONTAINER_ID, keycloak.token, _processInstanceID).then((response) => {
            if (response.success === true) {
                try {
                    let taskList = response.data['active-user-tasks']['task-summary'];
                    let detailTask = taskList.find(task => task['task-name'] === 'Risk Details');
                    let controlTask = taskList.find(task => task['task-name'].includes('Applicable Controls'));
                    let tasks = [];
                    if (detailTask !== undefined) {
                        tasks.push(detailTask);
                    }
                    if (controlTask !== undefined) {
                        tasks.push(controlTask);
                    }
                    setTaskInstanceIDList(tasks);
                    setFetching(false);
                } catch (error) {
                    //error
                }
                // setFetching(false);
            }

            if (response.success === false) {
                // setFetching(false);
            }
        });
    }

    //trigger milestone to start applicable controls
    function startApplicableControl(_caseID) {
        setFetching(true)
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Applicable Controls').then((response) => {
            if (response.success) {

                getActiveUserTasks(processInstanceID);
            }
        })
    }

    async function submitData(type) {
        try {
            setLoading(true);

            // Iterate through each task in the taskInstanceIDList
            if (type === "detail") {
                const task = taskInstanceIDList.find((t) => t["task-name"] === "Risk Details");

                let dataObj = {};
                questions[activeIndex].subQuestions.forEach((question) => {
                    dataObj[`${question.key}`] = question.qAnswer.toString();
                });
                let riskObj = {
                    "riskDetail": {
                        "com.cisozen.RiskRegisterData": dataObj
                    }
                }

                // Start the user task and submit data
                await submitUserData(task, riskObj, false, data);
            }

            if (type === "controls") {
                const task = taskInstanceIDList.find((t) => t["task-name"] !== "Risk Details");
                let dataObj = {};
                let applicableControls = [];
                controls.forEach((domain) => {
                    domain.sections.forEach((section) => {
                        section.controls.forEach((control) => {
                            let key = control.key.replaceAll("-", "");
                            if (control.isChecked) {
                                applicableControls.push(`${key}`);
                            }

                        });
                    });
                });
                dataObj["applicableControls"] = {
                    "java.util.List": applicableControls
                };
                // Start the user task and submit data
                await submitUserData(task, dataObj, true, data);
                setAssessmentCompleted(true);
            }

            // After all tasks are completed, redirect to '/page1'
            // history.push('/page1');


            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    function handleBackButton() {
        setIsControlSelection(false)
        startRiskDetail(caseID);
    }


    function submitUserData(task, dataObj, end, caseID) {
        if (task['task-status'] !== "InProgress") {
            startUserTask(CONTAINER_ID, keycloak.token, task['task-id']).then((response) => {
                if (response.success === true) {
                    completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                        if (response.success) {
                            if (end) {
                                endCase();
                            } else {
                                dispatch(toggleAllowFetch(true));
                                setIsControlSelection(true);
                                fetchCaseFileData();
                            }
                        }
                        if (response.success === false) {
                            setLoading(false);
                        }
                    });
                }

                if (response.success === false) {
                    setLoading(false);
                }
            });
        } else {
            completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                if (response.success) {
                    if (end) {
                        endCase();
                    } else {
                        dispatch(toggleAllowFetch(true));
                        setIsControlSelection(true);
                        fetchCaseFileData();
                    }
                }
            });
        }
    }

    function endCase() {
        /** backend automatically end the case after applicable control were submitted **/

        setLoading(false);
        setAssessmentCompleted(true);
        dispatch(toggleAllowFetch(true));
        fetchCaseFileData(2);

    }

    const handleExpandClick = (index) => {
        let newControls = [...controls];
        newControls[index].isExpanded = !newControls[index].isExpanded;
        setControls(newControls);
    };

    const handleCheckBoxClick = (domainIndex, sectionIndex, controlIndex) => {
        let newControls = [...controls];
        newControls[domainIndex].sections[sectionIndex].controls[controlIndex].isChecked = !newControls[domainIndex].sections[sectionIndex].controls[controlIndex].isChecked ?? true;
        setControls(newControls);
    }

    if (assessmentCompleted) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }} alt="" />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> You have completed the Risk Register.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/risk-assessment")}>
                            Done
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    if (!fetching && questions.length > 0) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    {/* <Grid item xs={12}>
                        <Box sx={{ paddingTop: "40px" }}>
                            <QuestionProgress questionNumber={activeIndex + 1} totalNumber={questions.length} />
                        </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", position: "relative" }}>
                            {
                                isControlSelection === true ? controls === null ? "" : <ApplicableControls riskTitle={questions[activeIndex]?.subQuestions[0]?.qAnswer} controls={controls} handleExpandClick={handleExpandClick} handleCheckBoxClick={handleCheckBoxClick} /> : <E8QuestionsStepper readOnly={false} questions={[questions[activeIndex]]} qIndex={activeIndex} handleAnswer={handleOneAnswer}></E8QuestionsStepper>
                            }
                            <ActionsWrapper hasOne>
                                {
                                    isControlSelection === true ? <Button $red onClick={(e) => handleBackButton()} style={{ marginRight: "10px" }}>
                                        back
                                    </Button> : ""
                                }
                                {/* {
                                    data !== "" ? <Button $success onClick={(e) => saveCase()} style={{ marginRight: "10px" }}>
                                        save
                                    </Button> : ""
                                } */}
                                {
                                    isControlSelection === true ? <Button disabled={!userAnsweredControls()} $success onClick={(e) => submitData("controls")}>
                                        submit
                                    </Button> :
                                        <Button disabled={!userAnsweredAllQuestions} $success onClick={(e) => submitData("detail")}>
                                            next
                                        </Button>
                                }

                            </ActionsWrapper>
                        </Box>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Risk Assessment Edit {data} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Risk Assessment
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default AddRiskAssessment;
