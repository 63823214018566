import QuestionTitle from "./QuestionTitle/QuestionTitle";
import { CustomFormControl, CustomFormControlLabel, SubQuestionWrapper, StyledNumberInput, StyledTextInput, StyledDropDown } from "./SubQuestion.styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import AddIcon from "@mui/icons-material/Add";

import React, { useState } from "react";
import validator from "validator";
import MultipleSelectCheckmarks from "./MultipleSelectCheckmarks";

import { Autocomplete, FormControl, FormLabel, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import ImageSelectionComponent from "./ImageSelectionComponent";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PhoneQuestion from "./QuestionType/PhoneQuestion";
import AddressInput from "./QuestionType/AddressInput";
import CompanyABN from "./QuestionType/CompanyABN";
import ContactQuestion from "./QuestionType/ContactQuestion";
import DataStore from "./QuestionType/DataStore";
import DependencyQuestion from "./QuestionType/DependencyQuestion";
import DateQuestion from "./QuestionType/DateQuestion";
import RadioQuestion from "./QuestionType/RadioQuestion";
import OptionQuestion from "./QuestionType/OptionQuestion";
import MultiSelectQuestion from "./QuestionType/MultiSelectQuestion";

export const specialCharacterRegex = new RegExp('^([a-z]|[A-Z]|[0-9]|[@./ -/ _/ ]|){0,255}$');
export const validInput = new RegExp('^([a-z]|[A-Z]|[0-9]|[@./ ]|){0,255}$');
const validPercentageInput = new RegExp('^[0-9][0-9]?$|^100$');

export function formatRecommendedCounterMeasure(data) {
    let recommendedCounterMeasures = [];

    let tmp = [];
    (data || []).forEach((item, index) => {
        if (index === 0) {
            tmp.push(item);
        } else {
            if (item.condition === "And") {
                tmp.push(item);
            } else {
                recommendedCounterMeasures.push(tmp);
                tmp = [];
                tmp.push(item);
            }
        }

        if ((index === (data.length - 1)) && tmp.length > 0) {
            recommendedCounterMeasures.push(tmp);
            tmp = [];
        }
    });
    return recommendedCounterMeasures;
}

export function decomposeCounterMeasure(data) {
    let newData = [];

    let tempArry = [];

    data = data.map((item) => {
        return item.map((sItem) => sItem['com.cisozen.CounterMeasureData'] || sItem);
    })

    data.forEach((item, iIndex) => {
        item.forEach((sItem, sIndex) => {
            if (sIndex === 0) {
                tempArry.push({ ...sItem, id: (iIndex + sIndex), condition: iIndex === 0 ? 'And' : 'Or' });
            } else {
                tempArry.push({ ...sItem, id: (iIndex + sIndex), condition: 'And' });
            }
        })

        newData = newData.concat(tempArry);
        tempArry = [];
    });

    return newData;
}

function isArrayOfStrings(arr) {
    // Check if arr is an array
    if (Array.isArray(arr)) {
        // Check if all elements in the array are strings
        return arr.every((item) => typeof item === 'string');
    }
    return false;
}

const SubQuestion = (props) => {
    const { question, parentIndex, index, handleAnswer, ans1Index, readOnly, domainControlMapper, ...restProps } = props;

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputID, setInputID] = useState(null);
    let errorList = [];



    function handleTextChange(e) {
        const isEmail = question.qHeading.toLowerCase().includes("email");
        const isDate = question.qHeading.toLowerCase().includes("when");
        const isPhone = question.qHeading.toLowerCase().includes("phone");
        const isABN = question.qHeading.toLowerCase().includes("abn");
        const ispercentage = question.qHeading.toLowerCase().includes("percentage");
        const isAllowSpecialCharacters = question.allowSpecialCharacters;

        if (isEmail) {
            if (validator.isEmail(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            } else {
                setIsError(true);
                setErrorMessage("Please enter a valid email! Example : user@email.com");
                handleAnswer("", parentIndex, index);
            }
        } else if (isDate) {
            if (validator.isDate(e.target.value, { format: "dd/mm/yyyy" })) {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            } else {
                setIsError(true);
                setErrorMessage("Please enter a valid date format (dd/mm/yyyy)! Example : 01/12/2022");
                handleAnswer("", parentIndex, index);
            }
        } else if (isABN) {
            if (validator.isNumeric(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            } else {
                setIsError(true);
                setErrorMessage("ABN number should be numeric character!");
                handleAnswer("", parentIndex, index);
            }
        } else if (isPhone) {
            if (validator.isMobilePhone(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            } else {
                setIsError(true);
                setErrorMessage("Phone number should be numeric character!");
                handleAnswer("", parentIndex, index);
            }
        } else if (ispercentage) {

            const _value = Number(e.target.value);
            if (!validPercentageInput.test(_value)) {
                setIsError(true);
                setErrorMessage("Please enter a valid number! Example : 0-100");
                handleAnswer("", parentIndex, index);
            } else {
                setIsError(false);
                setErrorMessage("");
                const value = Math.max(0, Math.min(100, Number(e.target.value)));
                handleAnswer(value, parentIndex, index);
            }
        } else if (isAllowSpecialCharacters) {
            if (!specialCharacterRegex.test(e.target.value)) {
                setIsError(true);
                setErrorMessage("Invalid input!");
                handleAnswer("", parentIndex, index);
            } else {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            }
        } else {
            if (!validInput.test(e.target.value)) {
                setIsError(true);
                setErrorMessage("Invalid input!");
                handleAnswer("", parentIndex, index);
            } else {
                setIsError(false);
                setErrorMessage("");
                handleAnswer(e.target.value, parentIndex, index);
            }
        }
    }

    function handleOptionChange(e) {
        setIsError(false);
        setErrorMessage("");
        handleAnswer(e.target.value, parentIndex, index);
    }

    function handleCheckChange(value) {
        handleAnswer(value, parentIndex, index);
    }

    function getOptionList() {
        let cards = [];
        try {
            cards.push(
                <MenuItem key={question.qId + question.qNum} value={""}>- Select -</MenuItem>
            )

            if (question.qAvailableAnswers) {
                if (isArrayOfStrings(question.qAvailableAnswers)) {
                    question.qAvailableAnswers.forEach((element, index) => {
                        cards.push(
                            <MenuItem key={index} value={element}>{element}</MenuItem>
                        )
                    })
                } else {
                    question.qAvailableAnswers.forEach((element, index) => {
                        cards.push(
                            <MenuItem key={index} value={element.value}>{element.label}</MenuItem>
                        )
                    })
                }
            }
        } catch (error) {
            console.error(error);
        }

        return cards;
    }

    function handleObjectTextChange(key, value) {
        const obj = question.qAnswer;
        obj[`${key}`] = value;
        handleAnswer(obj, parentIndex, index);
    }

    let label = `${question.qNum}. ${question.qHeading}`;

    return (
        <SubQuestionWrapper>
            {/* <QuestionTitle question={question} qNum={question.qNum} /> */}
            <CustomFormControl >
                {
                    question.type === "number" ? <TextField
                        required={question.required ?? false}
                        label={label}
                        error={isError}
                        helperText={errorMessage}
                        variant="outlined"
                        disabled={readOnly}
                        type="number"
                        min="0"
                        max="100"
                        inputProps={{ min: 0, max: 100, inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={(event) => handleTextChange(event)}
                        value={question.qAnswer}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...(question?.prefix && {
                                startAdornment:
                                    <InputAdornment position="start">{question.prefix}</InputAdornment>
                            })
                        }}
                    /> : ""
                }
                {
                    question.type === "text" ? <TextField id="outlined-multiline-flexible" label={label} variant="outlined" disabled={readOnly} error={isError} helperText={errorMessage} multiline minRows={1} maxRows={3} onChange={(event) => handleTextChange(event)} defaultValue={question.qAnswer} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: true }} required={question.required ?? false}></TextField> : ""
                }
                {/* {
                    question.type === "option" ? <FormControl>
                        <FormLabel id={`${question?.key}-label`} required={question.required ?? false}>{label}</FormLabel>
                        <Select id={`${question?.key}-select`} labelId={`${question?.key}-label`} disabled={readOnly} value={question?.qAnswer} onChange={handleOptionChange} label={label}>
                            {
                                getOptionList()
                            }
                        </Select>
                    </FormControl> : ""
                } */}
                {
                    question.type === "object" ? <FormControl>
                        <FormLabel required={question.required ?? false}>{label}</FormLabel>
                        {
                            question.qAvailableAnswers.map((q, index) => {
                                return <GroupedQuestion key={index} question={q} answer={question.qAnswer[`${q}`] ?? ""} handleTextChange={handleObjectTextChange} readOnly={readOnly} required={question.required ?? false} />
                            })
                        }
                    </FormControl> : ""
                }
                {/* {
                    question.type === "radio" ? <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label" required={question.required ?? false}>
                            {label}
                        </FormLabel>
                        <RadioGroup disabled={readOnly} aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={question.qAnswer ?? ""} onChange={handleTextChange}>
                            {question.qAvailableAnswers.map((answer, indexAns) => {
                                return <CustomFormControlLabel key={indexAns} value={answer} control={<Radio style={{ color: '#3a8d1c' }} />} label={answer} />;
                            })}
                        </RadioGroup>
                    </FormControl> : ""
                } */}
                {/* {
                    question.type === "check" ? <MultipleSelectCheckmarks question={question} handleCheckChange={handleCheckChange} title={label} categoryList={question.qAvailableAnswers} defaultValue={question.qAnswer} /> : ""
                } */}
                {
                    // Add the custom ImageSelectionComponent conditionally based on the question type or other criteria
                    question.type === "image_selection" ? (
                        <ImageSelectionComponent
                            options={question.qAvailableAnswers}
                            onSelectionChange={(selectedImages) => handleAnswer(selectedImages, parentIndex, index)}
                        />
                    ) : ""
                }
                {/* {
                    question.type === "date" && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                format="dd/MM/yyyy"
                                label={label}
                                value={question.qAnswer ? new Date(question.qAnswer) : null}
                                onChange={(newDate) => {
                                    if (newDate) {
                                        handleAnswer(newDate.toISOString(), parentIndex, index);
                                    }
                                }}
                                disabled={readOnly}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={isError && question.type === "date"}
                                        helperText={isError && question.type === "date" ? errorMessage : ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: false }}
                                    />
                                )}
                                slotProps={{
                                    textField: {
                                        readOnly: true,
                                        required: question.required ?? false
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    )
                } */}
                {/* {
                    question.type === "ismsMapping" ? <TextField
                        id="outlined-ismsMapping"
                        label={label}
                        variant="outlined"
                        error={isError}
                        helperText={errorMessage}
                        value={(question.qAnswer || []).join(", ")}
                        inputProps={{ maxLength: 255 }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true, // Makes the input non-editable
                            ...(question?.componentProps?.endAdornment && {
                                endAdornment: question.componentProps.endAdornment
                            }),
                        }}
                    /> : ""
                } */}
                {
                    question.type === "controlMapping" ? <Autocomplete
                        {...(question.componentProps || {})}
                        multiple
                        limitTags={1}
                        id={"outlined-ismsMapping-" + question.key}
                        options={[]}
                        disableClearable
                        readOnly
                        value={(question.qAnswer || []).map((control) => domainControlMapper[control] || control)}
                        getOptionLabel={(option) => option.label || option} // Function to get the label for each option
                        renderInput={(params) => (
                            <TextField
                                {...params} // Spread other TextField props if needed
                                value={""}
                                label={label}
                                InputProps={{
                                    ...params.InputProps,
                                    readOnly: true,
                                }}
                                required={question.required ?? false}
                            />
                        )}
                        sx={{
                            '& .MuiAutocomplete-input': {
                                width: '0 !important',
                                minWidth: '0 !important',
                                maxWidth: '0 !important',
                            }
                        }}
                        ChipProps={{
                            onDelete: undefined
                        }}
                        popupIcon={
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    if (restProps && restProps['actionFn'] && restProps['actionFn']['controlMapping'] && restProps['actionFn']['controlMapping']['popupIcon-onClick']) {
                                        restProps['actionFn']['controlMapping']['popupIcon-onClick'](question);
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        }
                    /> : ""
                }
                {
                    question.type === "counterMeasureMapping" ? <Box sx={{ width: "100%" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormLabel required={question.required ?? false}>{label}</FormLabel>
                            <IconButton
                                onClick={() => {
                                    if (restProps && restProps['actionFn'] && restProps['actionFn']['counterMeasureMapping'] && restProps['actionFn']['counterMeasureMapping'] && restProps['actionFn']['counterMeasureMapping']['toggleForm']) {
                                        restProps['actionFn']['counterMeasureMapping']['toggleForm'](question);
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>

                        <TableContainer
                            sx={{
                                '& .MuiTableCell-root': {
                                    borderBottom: 0,
                                    borderRight: '1px solid rgba(81, 81, 81, 1)',
                                    wordBreak: 'break-word',
                                    '&:last-child': {
                                        borderRight: 0,
                                    },
                                },
                                '& .MuiTableCell-head': {
                                    border: '1px solid rgba(81, 81, 81, 1)',
                                },
                                '& .MuiTableRow-root': {
                                    borderLeft: '1px solid rgba(81, 81, 81, 1)',
                                    borderRight: '1px solid rgba(81, 81, 81, 1)',
                                }
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {question.key === "counterMeasureMapping" ? "Counter Measure" : "Title"}
                                        </TableCell>
                                        <TableCell>
                                            Status
                                        </TableCell>
                                        <TableCell>
                                            ISMS Mapping
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (formatRecommendedCounterMeasure(question.qAnswer || []) || []).map((item, index) => {

                                            return <React.Fragment key={index}>
                                                {
                                                    item.map((sItem, sIndex) => {
                                                        return <React.Fragment key={sIndex}>
                                                            {
                                                                sIndex > 0 && <TableRow
                                                                    sx={{
                                                                        '& .MuiTableCell-root': {
                                                                            padding: "0 16px",
                                                                            height: "auto",
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell>
                                                                        <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            <TableRow
                                                                key={`${index}-${sIndex}`}
                                                                sx={{
                                                                    ...(((item.length - 1) === sIndex) && {
                                                                        borderBottom: '1px solid rgba(81, 81, 81, 1)',
                                                                    }),

                                                                }}
                                                            >
                                                                <TableCell>
                                                                    <Typography >{sItem.title}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{sItem.status}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography >{domainControlMapper[sItem.ismsMapping] || sItem.ismsMapping}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </React.Fragment>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider />

                    </Box> : ""
                }

                {
                    question.type === "phone" ? <PhoneQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "address" ? <AddressInput question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "abn" ? <CompanyABN question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "contact" ? <ContactQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "dataStore" ? <DataStore question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "radioDependency" ? <DependencyQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "date" ? <DateQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "radio" ? <RadioQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "option" ? <OptionQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} /> : ""
                }
                {
                    question.type === "check" ? <MultiSelectQuestion question={question} parentIndex={parentIndex} index={index} handleAnswer={handleAnswer} readOnly={readOnly} defaultValue={question.qAnswer} handleCheckChange={handleCheckChange} /> : ""
                }

                {/* TODO: Add % after input */}
            </CustomFormControl >
        </SubQuestionWrapper >
    );
};
export default SubQuestion;


const GroupedQuestion = (props) => {
    const { question, answer, handleTextChange, readOnly, required } = props;
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function errorHandler(e) {
        const isEmail = question.toLowerCase().startsWith("email");
        const isPhone = question.toLowerCase().startsWith("phone");

        if (isEmail) {
            if (validator.isEmail(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                handleTextChange(question, e.target.value);
            } else {
                setIsError(true);
                setErrorMessage("Please enter a valid email! Example : user@email.com");
                handleTextChange(question, "");
            }
        } else if (isPhone) {
            if (validator.isNumeric(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                handleTextChange(question, e.target.value);
            } else {
                setIsError(true);
                setErrorMessage("Phone number should be numeric character!");
                handleTextChange(question, "");
            }
        } else {
            if (!validInput.test(e.target.value)) {
                setIsError(true);
                setErrorMessage("Invalid input!");
                handleTextChange(question, e.target.value);
            } else {
                setIsError(false);
                setErrorMessage("");
                handleTextChange(question, e.target.value);
            }
        }
    }

    return (
        <TextField id="outlined-multiline-flexible" label={question} variant="outlined" disabled={readOnly} error={isError} helperText={errorMessage} multiline minRows={1} maxRows={3} onChange={(event) => errorHandler(event)} defaultValue={answer} style={{ marginTop: "20px" }} inputProps={{ maxLength: 255 }} InputLabelProps={{ shrink: true }} required={required}></TextField>
    )

}