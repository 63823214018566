import { Tooltip } from "@mui/material";
import { QuestionHeadWrapper, QuestionNumber } from "./QuestionHead.styled";
import QuestionHeading from "./QuestionHeading/QuestionHeading";

const QuestionHead = (props) => {
    const { question, $smsize, onClick, helpText } = props;
   
    return (
        <Tooltip title={helpText ?? ""} placement="bottom" arrow>
        <QuestionHeadWrapper $smsize={$smsize}>
            <QuestionNumber $smsize={$smsize}>{question.qNum}</QuestionNumber>
            <QuestionHeading hasInfo={question.hasDesc} $smsize={$smsize} handleClick={(e) => onClick()}>
                {question.title}
            </QuestionHeading>
        </QuestionHeadWrapper>
        </Tooltip>
    );
};
export default QuestionHead;
