import React, { useEffect, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import {
    StyledQuestion,
    RadioContainer,
    StyledInput,
    StyledContent,
    StyledRadio,
    RecContainer,
} from "./RecommendationItem.styled";
import QuestionHead from "../../../../components/Question/QuestionHead/QuestionHead";

const RecommendationItem = ({
    index,
    number,
    heading,
    helpText,
    onClick,
    reason,
    handleReasonsChange,
    isCompleted,
    isAccepted,
    handleOptionChange,
    readOnly
}) => {

    const [accepted, setAccepted] = useState(isAccepted);
    const [_reason, setReason] = useState(null);
    const [userReason, setUserReason] = useState(null)
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleAcceptedChange = (event) => {
        const val = event.target.value;
        if (val === "false") {
            setReason('');
            setUserReason('');
        }
        handleOptionChange(val, number);
        setAccepted(event.target.value);
    };

    const handleInputChange = (e) => {
        const validInput = new RegExp('^([a-z]|[A-Z]|[0-9]|[@./ ]|){0,255}$');
        if (!validInput.test(e.target.value)) {
            setIsError(true);
            setErrorMessage("Invalid input!");
            setUserReason('');
        } else {
            setIsError(false);
            setErrorMessage("");
            setUserReason(e.target.value);
        }
    }

    useEffect(() => {
        if (userReason === null) return;
        const delayDebounceFn = setTimeout(() => {
            handleReasonsChange(userReason, (number));
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [userReason])

    useEffect(() => {
        setAccepted(isAccepted);
        if (isAccepted === "false" && reason === "accepted") {
            setReason('');
        } else {
            setReason(reason);
        }
    }, [isAccepted, reason]);

    return (
        <StyledContent>
            <QuestionHead
                index={number}
                question={{ qNum: index, title: heading, hasDesc: false }}
                onClick={onClick}
            ></QuestionHead>
            <div style={{ padding: "0px 40px 0px 40px" }}><label> * {helpText}</label></div><br />
            {
                isCompleted ? <div style={{ padding: "0px 40px 0px 40px" }}><label><>{reason !== "accepted" ? "Rejected : " : ""}</>{reason !== null ? reason.charAt(0).toUpperCase() + reason.slice(1) : "Accepted" || "Accepted"}</label></div> : <RecContainer>
                    <RadioContainer>
                        <StyledQuestion>Do you accept this recommendation?</StyledQuestion>
                        <FormControl>
                            <RadioGroup row value={accepted} onChange={handleAcceptedChange}>
                                <FormControlLabel disabled={readOnly}
                                    value={true}
                                    control={<StyledRadio />}
                                    label="Yes"
                                />
                                <FormControlLabel disabled={readOnly}
                                    value={false}
                                    control={<StyledRadio />}
                                    label="No"
                                />
                            </RadioGroup>
                        </FormControl>
                    </RadioContainer>
                    {accepted === "false" && (
                        <StyledInput disabled={readOnly}
                            multiline
                            maxRows={4}
                            inputProps={{maxLength:255}}
                            variant="outlined"
                            error={isError} helperText={errorMessage}
                            type="text"
                            placeholder="if No, tell us the reason (max 255 characters)"
                            defaultValue={_reason}
                            onChange={handleInputChange}
                            maxLength='255'
                        />
                    )}
                </RecContainer>
            }

            <br /><hr style={{ border: "none", borderTop: "dotted 1px" }} />
        </StyledContent>
    );
};

export default RecommendationItem;
