import Heading from "../../../../components/UI/Heading/Heading";
import { Wrapper } from "../HealthCheck/HealthCheck.styled";
import ThreatSection from "./components/ThreatSection";
import { Helmet } from "react-helmet-async";

function ThreatLandingPage() {

    return (
        <>
            <Helmet>
                <title>Threats - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo={false} handleClick={() => { }}>
                    Threats
                </Heading>

                <ThreatSection />

            </Wrapper>
        </>
    );
}

export default ThreatLandingPage;
