import React, { useState } from 'react';

import Button from "@mui/material/Button";

const TruncateItems = ({ children, more, moreClassNames, less, lessClassNames, itemCount, slots }) => {

    const [expanded, setExpanded] = useState(false);

    const toggleLines = event => {
        event.preventDefault();
        setExpanded(!expanded);
    };

    if (!children) return null;
    if (!Array.isArray(children)) return children;

    return (
        <>
            {(children && children.length < itemCount) || expanded ? children : children.slice(0, itemCount)}
            {children && children.length > itemCount && !expanded && (
                <>
                    {
                        slots?.more ? <>{slots.more({ onClick: toggleLines })}</> : <div className="w-100 text-center">
                            <Button
                                className={`p-0 ${moreClassNames}`}
                                style={{ textDecoration: 'underline' }}
                                onClick={toggleLines}
                            >
                                {more}
                            </Button>
                        </div>
                    }
                </>
            )}
            {children && children.length > itemCount && expanded && (
                <>
                    <div className="w-100 text-center">
                        <Button
                            className={`p-0 ${lessClassNames}`}
                            style={{ textDecoration: 'underline' }}
                            onClick={toggleLines}
                        >
                            {less}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

TruncateItems.defaultProps = {
    itemCount: 5, // Default render item count
    more: 'view more',
    moreClassNames: '',
    less: 'less',
    lessClassNames: '',
};

export default TruncateItems;
