import { getCase, getCaseFileData } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, Project } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { fetchData as fetchSolutionData } from './solution.action';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_PROJECT_ALLOW_FETCH', payload: allow };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_PROJECT_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractProjectDataFromCaseFile(caseFile) {
  let newProject = {};

  let projectDetail = caseFile["case-file"]["case-data"]["projectData"];
  let projectID = caseFile["case-file"]["case-data"]["projectID"];
  if (projectDetail) {
    newProject = { ...projectDetail };
  }

  newProject["date"] = caseFile["case-started-at"] || 0;
  newProject["status"] = caseFile["case-status"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);

  newProject["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  newProject.caseID = caseFile["case-id"];
  // newProject.projectID = beautifyUniqueID(projectID);
  newProject.projectID = beautifyUniqueID(caseFile["case-id"]);
  return newProject;
}

export const getProjectData = (caseFile) => {
  let newProject = extractProjectDataFromCaseFile(caseFile);
  newProject.caseID = caseFile["case-id"];
  return newProject;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().projects; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-projects');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_PROJECT_DATA_SUCCESS', payload: { data: cachedData } });
      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let projects = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, Project, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, Project, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const projectData = [...closedData, ...openedData];

        if (projectData?.length > 0) {
          projectData.forEach((project, index) => {
            let newProject = {};

            let projectDetail = project["case-file"]["case-data"]["projectData"];
            let projectID = project["case-file"]["case-data"]["projectID"];
            if (projectDetail) {
              newProject = { ...projectDetail };
            }

            newProject["date"] = project["case-started-at"] || 0;
            newProject["status"] = project["case-status"] || 0;
            let cDate = new Date(project["case-started-at"]);

            newProject["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            newProject.caseID = project["case-id"];
            // newProject.projectID = beautifyUniqueID(projectID);
            newProject.projectID = beautifyUniqueID(project["case-id"]);
            if (newProject["name"]) {
              projects.push(newProject);
            }
          })

          projects.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_PROJECT_DATA_SUCCESS', payload: { data: projects }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'PROJECT_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-projects', projects);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_PROJECT_FETCH_COUNTER' });

          // dispatch fetch solution data if it's for the first time
          {
            const { fetchCounter } = getState().solutions;
            if (fetchCounter === 0 && projectData?.length > 0) {
              dispatch(fetchSolutionData(keycloak, 0));
            }
          }

        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_PROJECT_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().projects; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-projects');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let projects = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, Project, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, Project, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const projectData = [...closedData, ...openedData];

      if (projectData) {
        projectData.forEach((project, index) => {
          let newProject = {};

          let projectDetail = project["case-file"]["case-data"]["projectData"];
          let projectID = project["case-file"]["case-data"]["projectID"];
          if (projectDetail) {
            newProject = { ...projectDetail };
          }

          newProject["date"] = project["case-started-at"] || 0;
          newProject["status"] = project["case-status"] || 0;
          let cDate = new Date(project["case-started-at"]);

          newProject["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          newProject.caseID = project["case-id"];
          // newProject.projectID = beautifyUniqueID(projectID);
          newProject.projectID = beautifyUniqueID(project["case-id"]);
          if (newProject["name"]) {
            projects.push(newProject);
          }
        })

        if (cachedData?.length > 0) {
          projects = [...cachedData, ...projects];
        }

        projects.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_PROJECT_DATA_SUCCESS', payload: { data: projects }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'PROJECT_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_PROJECT_FETCH_COUNTER' });

          // dispatch fetch solution data if it's for the first time
          {
            const { fetchCounter } = getState().solutions;
            if (fetchCounter === 0) {
              dispatch(fetchSolutionData(keycloak, 0));
            }
          }
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-projects', projects);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_PROJECT_DATA_ERROR', payload: error.message });
    }
  };
};

export const updateDataByCaseID = (keycloak, { caseID, data, refetchData = false }) => {
  return async (dispatch, getState) => {

    const cachedData = await getCachedData(keycloak.token, 'cached-projects');

    let projectData = [...(cachedData || [])];

    let dataIndex = projectData.findIndex((c) => c["caseID"] === caseID);
    if (dataIndex > -1) {
      projectData = projectData.map((c, index) => {
        if (index === dataIndex) {
          return { ...c, ...data }
        }
        return { ...c }
      })
    } else {
      projectData.push({
        ...data,
        caseID: caseID,
      })
    }

    projectData.sort((a, b) => b["date"] - a["date"]);

    // Dispatch update project data
    dispatch({ type: 'UPDATE_PROJECT_DATA', payload: projectData });

    // Cache the fetched data
    cacheData(keycloak.token, 'cached-projects', projectData);

    // Refetch case data
    if (refetchData) {
      dispatch(fetchAndUpdateDataByCaseID(keycloak, caseID));
    }

  }
}

export const fetchAndUpdateDataByCaseID = (keycloak, caseID) => {
  return async (dispatch, getState) => {

    let caseFileDataResponse = await getCaseFileData(CONTAINER_ID, keycloak.token, caseID);
    if (caseFileDataResponse.success) {

      let caseData;
      try {
        caseData = caseFileDataResponse.data?.projectData?.["com.cisozen.ProjectData"];
        let projectID = caseFileDataResponse.data?.["projectID"];
        // caseData.projectID = beautifyUniqueID(projectID);
        caseData.projectID = beautifyUniqueID(caseID);
        if (caseData) {
          dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { ...caseData } }))
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error as needed
      }
    }
  }
}

export const removeEmptyCase = (keycloak) => {
  return async (dispatch, getState) => {
    try {
      let cachedData = await getCachedData(keycloak.token, 'cached-projects');
      if (cachedData?.length > 0) {
        cachedData = cachedData.filter((c) => c["name"]);
        cachedData = cachedData.map((c) => ({ ...c }));
      }

      cachedData.sort((a, b) => b["date"] - a["date"]);

      dispatch({ type: 'FETCH_PROJECT_DATA_SUCCESS', payload: { data: cachedData }, });

      // Cache the fetched data
      cacheData(keycloak.token, 'cached-projects', cachedData);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  }
}