/* eslint-disable no-unused-vars */ // remove it after refactor
import { Box, CssBaseline } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../components/UI/Loader/Loader";
import { CONTAINER_ID, setContainerID } from "../../store/auth-context";
import Header from "./components/Header/Header";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import { ContentWrapper, MainContent } from "./Dashboard.styled";
import HealthCheck from "./nested/HealthCheck/HealthCheck";
import Help from "./nested/Help/Help";
import Information from "./nested/Information/Information";
import PdfTest from "./nested/PdfTest/PdfTest";
import Recommendations from "./nested/Recommendations/Recommendations";
import RiskMatrix from "./nested/RiskMatrix/RiskMatrix";
import Settings from "./nested/Settings/Settings";
import { Cookies } from "../../shared/utility";
import HealthCheckLandingPage from "./nested/HealthCheck/HealthCheckLandingPage";
import RiskAssessment from "./nested/RiskAssessment/RiskAssessment";
import RiskAssessmentLandingPage from "./nested/RiskAssessment/RiskAssessmentLandingPage";
import jwt_decode from "jwt-decode";
import MainDrawer from "./components/SideDrawer/MainDrawer";
import NotFound from "../../components/ErrorPage/NotFoundPage";

var drawerWidth = 259;

function ISMS(props) {
    let { path } = useRouteMatch();

    const { keycloak, initialized } = useKeycloak();
    const kcToken = keycloak?.token ?? '';

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isShrink, setIsShrink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [containerName, setContainerName] = useState(null);
    const [signal, setSignal] = useState(0);

    const location = useLocation()

    const history = useHistory();

    useEffect(() => {
        if (keycloak) {
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(50).then((refreshed) => {
                    if (refreshed) {
                        setSignal(keycloak.tokenParsed.exp);
                    } else {
                        // console.log('not refreshed ' + new Date());
                    }
                })
            }
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };

    }, [keycloak.authenticated]);

    useEffect(() => {
        if (!keycloak.authenticated) {
            // history.replace("/")
        } else {
            try {
                var decoded = jwt_decode(kcToken);
                var resource = decoded["resource_access"];
                let userName = decoded["name"] ?? "";
                setUsername(userName);
                // var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
                // const orgName = clientRoles.filter(role => role !== "cz-users")[0];
                // setContainerID(orgName + "_0.1");
                // setContainerName(orgName + "_0.1");
            } catch (error) {
                console.log(error);
            }
        }
    }, [history, keycloak.authenticated, location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleShrinkToggle = () => {
        if (!isShrink) {
            drawerWidth = 75
        } else {
            drawerWidth = 259
        }
        setIsShrink(!isShrink);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    let content;

    if (loading) {
        content = <Loader height="90vh" />;
    }


    content = (
        <MainContent>
            <Switch>
                <Route path={`${path}/`} exact>
                    <NotFound />
                </Route>
            </Switch>
        </MainContent>
    );


    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                <MainDrawer container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerShrink={handleShrinkToggle} drawerWidth={drawerWidth} />
            </Box>
            <ContentWrapper drawerWidth={drawerWidth}>
                <Header username={username} email={email} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
                {content}
            </ContentWrapper>
        </Box>
    );
}

export default ISMS;

