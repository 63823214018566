// dataReducer.js

const initialState = {
  data: [],
  graphData: null,
  domainData: null,
  loading: false,
  error: null,
  allowFetch: false, // Initialize as false
  updatedCaseID: null,
  allowRiskUpdate: true, // Initialize as true
  lastPageFetched: { // Initialize last page fetched as 0, this is to track last fetched page number 
    open: 0,
    closed: 0
  },
  hasMore: { // Initialize as true, as this will help to call the api when more data is available
    open: true,
    closed: true
  },
  fetchingBackgroundData: true, // Initialize as true, as this will help to know if the background data is fetching
  organisationDetail: { organisationalName: "", organisationSize: "", industryType: "", emailAddress: "", subscriptionType: "", serviceHours: 0, paymentType: "", subscriptionEndDate: "" },
  organisationList: [],
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_ALLOW_RISK_UPDATE':
      return { ...state, allowRiskUpdate: action.payload };
    case 'TOGGLE_ALLOW_FETCH':
      return { ...state, allowFetch: action.payload };
    case 'TOGGLE_RISK_UPDATE':
      return { ...state, updatedCaseID: action.payload };
    case 'FETCH_DATA_SUCCESS':
      return { ...state, data: action.payload, graphData: action.graphData, domainData: action.domainData, loading: false, error: null };
    case 'FETCH_DATA_ERROR':
      return { ...state, data: [], graphData: null, loading: false, error: action.payload };
    case 'PAGINATION_TRACKER':
      return { ...state, lastPageFetched: action.payload.lastPageFetched, hasMore: action.payload.hasMore };
    case 'TOGGLE_FETCHING_BACKGROUND_DATA':
      return { ...state, fetchingBackgroundData: action.payload };
    case 'SET_ORGANISATION_DETAIL':
      return { ...state, organisationDetail: action.payload };
    case 'SET_ORGANISATION_LIST':
      return { ...state, organisationList: action.payload };
    case 'SET_SELECTED_ORGANISATION':
      return { ...state, selectedOrganisation: action.payload };
    case 'SET_PROJECT_DATA':
      return { ...state, projectDataFetch: action.payload };
    default:
      return state;
  }
};

export default dataReducer;
