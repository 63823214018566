import { CardActions, CardContent, Collapse, Divider, Grid, IconButton, Typography } from "@mui/material";
import { PolicyCard } from "../TermsConditions.styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function TermsConditionsCard(props) {
    const { data, handleExpandClick } = props;

    let renderedData = data.map((policy, index) => {
        return <div key={index}>
            <CardActions disableSpacing>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6">
                            {policy.mainID} {policy.mainHeading}
                        </Typography>
                    </Grid>
                </Grid>
                <ExpandMore
                    expand={policy.isExpanded}
                    onClick={(e) => handleExpandClick(index)}
                    aria-expanded={policy.isExpanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={policy.isExpanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={12} md={12}>
                            <Typography>
                                {policy.mainDescription}
                            </Typography>
                        </Grid>
                        {
                            policy.subSections.map((section) => {
                                return (
                                    <Grid item xs={12} key={section.sectionID}>
                                        <Typography variant="subtitle1">{section.sectionID} {section.sectionHeading}</Typography>
                                        <Divider />
                                        <Typography display={"block"}>{section.sectionDescription}</Typography>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Collapse>
            <Divider />
        </div>;
    });

    return <PolicyCard>{renderedData}</PolicyCard>;
}

export default TermsConditionsCard;