import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircleLoader, CustomizedCardHeader, CustomizedCardContent } from "../Common/Graph.styled";
import { useKeycloak } from '@react-keycloak/web';
import ReactForceGraph2d from "react-force-graph-2d";
import {
  mitigationDefaultColor,
  mitHighlighColor,
  riskNodeColor,
  green1,
  red1,
  edgeHighlightColor,
  edgeDefaultColor,
  grey,
  demainDefaultColor,
  apiCall_error,
  mitigation_details_error,
  domain_details_error,
  risk_details_error,
  darkred,
  linkColor
} from "../Common/GraphConfig";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Popover from '@mui/material/Popover';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from '@mui/material/Typography';
import { CustomDialog, CustomDialogTitle } from "../Common/CustomizedDialog";
import CardContent from '@mui/material/CardContent';
import LegendPage from "./Legend";
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, toggleAllowFetch } from "../../../../../utility/dataAction";
import * as fetchInstance from "../../../../../utility/fetch-instance";
import useMediaQuery from '@mui/material/useMediaQuery';
import RiskDetailDialog from "../Common/RiskDetailDialog";

export function isRiskAboveOrEqualTolerance(currentRisk, toleranceRisk) {
  try {
    const extractRiskInfo = (riskString) => {
      const [category, number] = riskString.split('-');
      return { category, number: parseInt(number) };
    };

    const currentRiskInfo = extractRiskInfo(currentRisk);
    const toleranceRiskInfo = extractRiskInfo(toleranceRisk);

    if (currentRiskInfo.category !== toleranceRiskInfo.category) {
      // If categories are different, compare based on category order
      const riskOrder = ['Low', 'Medium', 'High', 'Extreme'];
      const currentCategoryIndex = riskOrder.indexOf(currentRiskInfo.category);
      const toleranceCategoryIndex = riskOrder.indexOf(toleranceRiskInfo.category);
      return currentCategoryIndex > toleranceCategoryIndex;
    } else {
      // If categories are the same, compare based on numbers
      return currentRiskInfo.number >= toleranceRiskInfo.number;
    }
  } catch (e) {
    return false;
  }
}

const theme = createTheme({
  palette: {
    background: {
      paper: 'transparent', // // card theme color
    },
  },
});


// ----- GLOBAL letIABLES ------
let w = window.innerWidth;
let h = window.innerHeight;

// GRAPH VARIABLES
let cx = w / 2;
let cy = h / 2;

const CombinedGraph = (props) => {
  const { keycloak } = useKeycloak();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const forceRef = useRef();
  const nodes = [];
  const links = [];

  const [graph, setGraph] = useState({ nodes, links });
  const [showLoader, setShowLoader] = useState("none");
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessgae, setAlertMessgae] = useState("");
  const [filter, setFilter] = useState('');
  const [serverList, setServerList] = useState([])
  const [highlightNodes, setActiveNodes] = useState(new Set());
  const [highlightLinks, setActiveLinks] = useState(new Set());
  const [nodeClicked, setNodeClicked] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(205);
  const [left, setLeft] = useState(75);
  const [open, setOpen] = React.useState(false);
  const [riskDetais, setRiskDetais] = useState([]);
  const [riskTitle, setRiskTitle] = useState("");
  const [showModal, setShowModal] = useState("none");
  const [selectedNode, setSelectedNode] = useState(null);
  const [riskOnlySelected, setRiskOnlySelected] = useState(false);
  const [addTextNode, setAddTextNode] = useState(false);
  const [riskCounts, setRiskCounts] = useState({
    extreme: '',
    high: '',
    medium: '',
    low: '',
  });

  const [riskMits, setriskMits] = useState([]);
  const [domainMits, setdomainkMits] = useState([]);
  const [dialogRiskDetails, setDialogRiskDetails] = useState(null);

  const dispatch = useDispatch();
  const { graphData, domainData, loading, error, allowFetch } = useSelector(
    (state) => state.data
  );

  useEffect(() => {
    if (graphData) {
      // dispatch(toggleAllowFetch(false));
      // setRisks(data);
      setriskMits(graphData);
      setdomainkMits(domainData);
    }

    if (graphData === null && allowFetch === false) {
      dispatch(toggleAllowFetch(true))
    }


  }, [graphData, allowFetch])

  useEffect(() => {
    if (allowFetch) {
      dispatch(fetchData(keycloak));
    }
  }, [dispatch, allowFetch]);

  useEffect(() => {
    // setShowLoader("block");
    let authToken = keycloak.token;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${authToken}`);

    let raw = JSON.stringify({});

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    //Api call to get risk mitigation domain graph data    
    // fetch("/models/RiskMitigationDomainGraph/dmnresult", requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     setShowLoader("none");
    //       let risksMitsDomains = result.dmnContext.riskMitigationDomainGraph;//list from api
    //       //risksMitsDomains = risksMitsDomains.splice(0,100); //for 100 nodes just for testing
    //       nodeCreator(risksMitsDomains);
    //       edgeCreator(risksMitsDomains);
    //       updateLegendCount(risksMitsDomains);
    //       setServerList(risksMitsDomains);//store api response for further usages
    //     }
    //   )
    //   .catch(error => {
    //     setShowLoader("none");
    //     console.log('error', error)
    //     setAlertMessgae(apiCall_error)
    //     setOpenAlertModal(true);
    //   });

    // let risksMitsDomains = APIData.dmnContext.riskMitigationDomainGraph;//list from api
    // risksMitsDomains = risksMitsDomains.splice(0,50); //for 100 nodes just for testing
    nodeCreator(riskMits, domainMits);
    edgeCreator(riskMits, domainMits);
    updateLegendCount(riskMits);
    setServerList(riskMits);
    //to avoid overlapping of nodes. Higher the charge value farther the nodes will be from each other
    forceRef.current.d3Force("charge").strength(-50);
    forceRef.current.d3Force("link").distance(100);
    forceRef.current.zoom(0.1, 10);
  }, [riskMits])

  /** to create the node list for the graph */
  function nodeCreator(inputNodes, domainNodes, inputMitList) {
    let mitList;
    if (typeof inputMitList != "undefined") {
      mitList = { ...inputMitList }
    }
    else { mitList = { ...inputNodes[0] }; } //let mitList = {...inputNodes[0]}; //to add mitigation & mitigation connections
    for (let key in mitList) {
      mitList[key] = 0;
    }
    let mitCount; //to check count of the mitigation connection
    var extremeList = [];
    inputNodes.forEach(element => {
      //set node size based on residualRiskRating
      let RiskVal = 10;
      const riskRating = element?.residualRiskRating || "";
      const risk = (element?.detail && element?.detail["Residual Risk Rating"]) || "";

      switch (riskRating) {
        case "High": RiskVal = RiskVal * 5;
          break;
        case "Medium": RiskVal = RiskVal * 3;
          break;
        case "Low": RiskVal = RiskVal * 1;
          break;
        case "Extreme": RiskVal = RiskVal * 7;
          break;
        default:
      }
      //adding risk nodes

      nodes.push({ id: element.riskID, color: riskNodeColor, label: element.title, val: RiskVal, riskLevel: riskRating, date: element.date, detail: element.detail, risk: risk });

      //adding extreme risks
      if (riskRating === "Extreme") {
        extremeList.push(element);
      }

      //adding score to mitigation
      for (let key in element) {
        mitCount = mitList[key]; //takes the current mitigation connection count
        if (key.startsWith('m')) {
          if (element[key] !== null) {
            mitList[key] = mitCount + 1; //inceases the mitigation connection count if matches
          }
        }
        //}
      }
    })
    // setExtremeNodes(extremeList);

    //adding mitigation as node
    const sumValues = Object.values(mitList).reduce((a, b) => a + b, 0);
    //set mitigation node size based on its connection count
    let scale = 0;
    switch (true) {
      case sumValues > 900:
        scale = 3;
        break;
      case sumValues > 600:
        scale = 2;
        break;
      default:
        scale = 1
    }
    /**
     * first get the connection percentage value based on total mitigation connection number for mitigation size
     * add minitigation nodes to node list
     */
    // for (let keyy in mitList) {
    //   if (mitList[keyy] !== mitList.riskID && mitList[keyy] !== mitList.residualRiskRating && mitList[keyy] !== mitList.date) {
    //     const connectionPerent = Math.floor((mitList[keyy] / sumValues) * 100);
    //     nodes.push({ id: keyy, label: keyy, color: mitigationDefaultColor, val: connectionPerent * scale, isMiti: true });
    //   }
    // }
    // // delete riskid, residualRiskRating and date from mitigation object
    // delete mitList.riskID;
    // delete mitList.residualRiskRating;
    // delete mitList.date;

    domainNodes?.forEach(element => {
      //add the domain nodes to node list 
      element.domainScore = 3;
      nodes.push({ id: "d" + element.domainID, color: demainDefaultColor, label: element.domainHeading, val: (element.domainScore === 0 ? 25 : element.domainScore * 50) });
      element.sections.forEach((section) => {
        section.controls.forEach((mit) => {
          //add the mitigation nodes to node list associated to domains
          mit.mitigationScore = 3;
          nodes.push({ id: "m" + mit.controlID, color: mitigationDefaultColor, label: mit.controlHeading, val: (mit.mitigationScore === 0 ? 5 : mit.mitigationScore * 10) });
        })
      });
    });

    // forceRef.current.d3Force("charge").distanceMax(4 * nodes.length)
    setGraph({ ...graph, nodes: nodes, links: links }); //set the graph with updated nodes and links
  }

  /** to create the node list for the graph */
  // function nodeCreator(inputNodes) {
  //   var extremeList = [];
  //   inputNodes.forEach(element => {
  //     //set node size based on residualRiskRating
  //     let RiskVal = 10;
  //     const riskRating = element?.residualRiskRating || "";

  //     switch (riskRating) {
  //       case "High": RiskVal = RiskVal * 5;
  //         break;
  //       case "Medium": RiskVal = RiskVal * 3;
  //         break;
  //       case "Low": RiskVal = RiskVal * 1;
  //         break;
  //       case "Extreme": RiskVal = RiskVal * 7;
  //         break;
  //       default:
  //     }
  //     //adding risk nodes

  //     nodes.push({ id: element.riskID, color: riskNodeColor, label: element.title, val: RiskVal, riskLevel: riskRating, date: element.date, detail: element.detail });

  //     //adding extreme risks
  //     if (riskRating === "Extreme") {
  //       extremeList.push(element);
  //     }

  //     //adding score to mitigation

  //   });
  //   const { mitList, domainList } = mitigationCal(inputNodes)
  //   for (let key in mitList) {
  //     nodes.push({ id: key, color: mitigationDefaultColor, val: (mitList[key] === 0 ? 100 : mitList[key] * 100), isMiti: true });

  //   }
  //   for (let keyy in domainList) {
  //     nodes.push({ id: keyy, color: demainDefaultColor, val: (domainList[[keyy]] === 0 ? 200 : domainList[[keyy]] * 200) });
  //   }
  //   setGraph({ ...graph, nodes: nodes, links: links });
  // }

  /** to create the node list for the graph for risk only filter to show data in concentric circle*/
  function riskNodeCreator(inputNodes) {
    inputNodes.forEach(element => {
      //adding risk nodes
      let RiskVal = 100;
      switch (element.residualRiskRating) {
        case "High": RiskVal = RiskVal * 7;
          break;
        case "Medium": RiskVal = RiskVal * 4;
          break;
        case "Low": RiskVal = RiskVal * 1;
          break;
        case "Extreme": RiskVal = RiskVal * 12;
          break;
        default:
      }
      nodes.push({ id: element.riskID, color: riskNodeColor, label: element.riskID, val: RiskVal, riskLevel: element.residualRiskRating, date: element.date });
    })
    setGraph({ ...graph, nodes: nodes, links: [] });
    //increase zoom level to get clear view of concentric circle
    forceRef.current.zoomToFit();
    forceRef.current.d3Force("charge").strength(-50);
  }

  function mitigationCal(inputNodes) {
    let serverListCopy
    if (inputNodes.length > 0) {
      serverListCopy = [...inputNodes]
    }
    let mitList = {}
    let domainList = {}
    domainData.forEach((domain) => {
      domainList[domain.domainID] = domain?.domainScore || 0;
      domain.sections.forEach((section) => {
        section.controls.forEach((item) => {
          mitList[item.mitigationID] = 0
        });
      });
    });

    domainData.forEach((domain) => {
      domain.sections.forEach((section) => {
        section.controls.forEach((val) => {
          if (val["controlScore"] !== null) {
            mitList[val["controlID"]] = val["controlScore"]
          }
        })
      })
    })
    return { mitList, domainList }
  }

  /** create the edges/links list for the graph */
  function edgeCreator(inputList, domainList) {
    let i = 0;
    for (const element of inputList) {
      for (const key of Object.keys(element)) {
        if (key.startsWith('m')) {
          if (element[key] !== null) {
            links.push({
              id: i + 1,
              source: element.riskID,
              target: key.replace(new RegExp('-', 'g'), '.'),
              hidden: false,
              color: edgeDefaultColor,
              neighbors: []
            });
          }
          i++;
        }
      }
    }


    domainList.forEach((element) => {
      element.sections.forEach((section) => {
        section.controls.forEach((mit) => {
          mit.mitigationScore = 1;
          if (mit.mitigationScore !== null) {
            const id = links.length + 1;
            const source = "d" + element.domainID;
            const target = "m" + mit.controlID;
            const hidden = false;
            const color = edgeDefaultColor;

            links.push({ id, source, target, hidden, color });
          }
        });
      });
    });

    // inputList.forEach((element) => {
    //   element.domains.forEach((domain) => {
    //     domain.mitigations.forEach((mitigation) => {
    //       if (mitigation["mitigationScore"] !== null) {
    //         links.push({
    //           id: i + 1,
    //           source: element.riskID,
    //           target: mitigation["mitigationID"],
    //           hidden: false,
    //           color: edgeDefaultColor
    //         });
    //       }
    //       i++;
    //     });
    //   });
    // });

    // const firstEle = inputList[0];
    // firstEle.domains.forEach((domain) => {
    //   domain.mitigations.forEach((mitigation) => {
    //     links.push({
    //       id: i + 1,
    //       source: domain["domainID"],
    //       target: mitigation.mitigationID,
    //       hidden: false,
    //       color: edgeDefaultColor
    //     });
    //   });
    // });

    setGraph({
      ...graph,
      nodes: nodes,
      links: links
    });
  }


  //to get the risk count of each type of risk
  const updateLegendCount = (nodes) => {
    const riskCounts = nodes.reduce((acc, node) => {
      const count = acc[node.residualRiskRating] || 0;
      return { ...acc, [node.residualRiskRating]: count + 1 };
    }, { Extreme: 0, High: 0, Medium: 0, Low: 0 });

    setRiskCounts({ ...riskCounts, extreme: riskCounts.Extreme, medium: riskCounts.Medium, high: riskCounts.High, low: riskCounts.Low });
  }

  //reset the nodes back to default mode 
  const resetHighLight = () => {
    if (nodeClicked != null && !addTextNode) {
      setNodeClicked(null);
      forceRef.current.zoomToFit();
      highlightNodes.clear();
      highlightLinks.clear();

      graph.nodes.forEach((graphnode) => {
        if (graphnode.id.startsWith("Risk")) {
          graphnode.color = riskNodeColor;//set risk nodes back to default color
        }
        else if (graphnode.id.startsWith("d")) {
          graphnode.color = demainDefaultColor; //set mitigation nodes back to default color
        }
        else if (graphnode.id.startsWith("m")) {
          graphnode.color = mitigationDefaultColor;//set domain node back to default color
        } else {
          graphnode.color = riskNodeColor;
        }
      })

      graph.links.forEach((linknode) => {
        linknode.color = edgeDefaultColor;//set edges back to default color
      })
      setGraph({ ...graph });
      setNodeClicked(null);
    }
  }

  //to highlight links and nodes associated with clicked risk/ddomain nodes
  const highlightLinksAndNodes = (links, sourceNode, targetNode, highlightLinks) => {
    links.forEach((linknode) => {
      if (linknode.source.id === sourceNode.id) {
        linknode.source.color = (targetNode === "riskID" ? red1 : green1);
        linknode.target.color = mitHighlighColor;
        linknode.color = edgeHighlightColor;
        forceRef.current.emitParticle(linknode);
        highlightLinks.add(linknode);
      } else {
        if (linknode.source.color !== green1)
          linknode.source.color = grey;
        if (linknode.target.color === mitigationDefaultColor) {
          linknode.target.color = grey;
        } else if (linknode.target.color === mitHighlighColor && linknode.source.id.startsWith('d')) {
          linknode.source.color = green1;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
        }
        if (!linknode.source.color == green1) linknode.source.color = grey;
        if (!linknode.color == edgeHighlightColor) linknode.color = grey;
        if (!linknode.source.color == red1) linknode.source.color = grey;
      }
    });
  };

  //to highlight risk and mitigation associated with clicked nodes
  const highlightRiskAndMitigation = (links, highlightLinks, selectedNode) => {
    links.forEach((graphLinks) => {
      highlightLinks.forEach((element) => {
        if (element.target.id === graphLinks.target.id && graphLinks.source.id.startsWith('Risk')) {
          graphLinks.source.color = red1;
          graphLinks.color = edgeHighlightColor;
          forceRef.current.emitParticle(graphLinks);
          highlightLinks.add(graphLinks);
        }
      });
    });
  };
  //to highlight Mitigation And Connected Nodes
  const highlightMitigationAndConnectedNodes = (links, highlightLinks, selectedNode) => {
    links.forEach((linknode) => {
      if (linknode.target.id === selectedNode.id) {
        linknode.target.color = mitHighlighColor;
        linknode.source.color = red1;
        if (linknode.source.id.startsWith('d')) linknode.source.color = green1;
        linknode.color = edgeHighlightColor;
        forceRef.current.emitParticle(linknode);
        highlightLinks.add(linknode);
      } else {
        if (!linknode.source.color == red1) linknode.source.color = grey;
        if (!linknode.source.color == green1) linknode.source.color = grey;
        if (linknode.source.color === demainDefaultColor) linknode.source.color = grey;
        if (linknode.source.color === riskNodeColor) linknode.source.color = grey;
        linknode.target.color = grey;
        linknode.color = grey;
      }
    });
  };

  //to highlight the nodes on node Click
  const handleNodeClick = (selectedNode) => {
    if (!addTextNode) {
      highlightNodes.clear();
      highlightLinks.clear();
      resetHighLight();

      setNodeClicked(selectedNode || null);
      // Center/zoom on selected node
      forceRef.current.centerAt(selectedNode.x, selectedNode.y, 10);
      forceRef.current.zoom(1.1, 20);

      //if risk node selected
      if (selectedNode.id.startsWith('R')) {
        highlightLinksAndNodes(graph.links, selectedNode, 'riskID', highlightLinks);
      }
      //if domain node selected
      else if (selectedNode.id.startsWith('d')) {
        highlightLinksAndNodes(graph.links, selectedNode, 'domainID', highlightLinks);
        highlightRiskAndMitigation(graph.links, highlightLinks, selectedNode);
      }
      // on mitigation node clicked
      else if (selectedNode.id.startsWith('m')) {
        highlightMitigationAndConnectedNodes(graph.links, highlightLinks, selectedNode);
      }

      updateHighlight();
    }
  };


  const updateHighlight = () => {
    setActiveNodes(highlightNodes);
    setActiveLinks(highlightLinks);
  };

  //to show option to view node details
  const onNodeRightClick = (node, event) => {
    if (node) {
      setShowPopup(true);
      setTop(event.pageY);
      setLeft(event.pageX);
      setSelectedNode(node);
    }
  }

  //to handle node details
  const onClickViewDetails = () => {
    setShowLoader("block");
    setShowPopup(false);

    let authToken = keycloak.token;

    let apiHeaders = new Headers();
    apiHeaders.append("Content-Type", "application/json");
    apiHeaders.append("Authorization", "Bearer " + authToken);

    if (selectedNode.id.startsWith("RA")) {
      callRiskDetailsApi(apiHeaders);
    }
    else if (selectedNode.id.startsWith("m")) {
      callMitigationDetailsApi(apiHeaders);
    }
    else {
      callDomainDetailsApi(apiHeaders);
    }
  }

  const toggleRiskDetailDialog = (e, data = null) => {
    setOpen(prev => !prev);
    setDialogRiskDetails(data);
    // setShowPopup(false);
    // setShowModal("none");
  };

  //to show risk node details
  // const callRiskDetailsApi = (apiHeaders) => {
  //   let raw = JSON.stringify({
  //     "model-namespace": "https://kiegroup.org/dmn/_F6FFF2DF-2557-410E-9C01-6076D0831A16",
  //     "model-name": "RiskDetail",
  //     "dmn-context": {
  //       "riskID": selectedNode.id
  //     }
  //   });

  //   let requestOptions = {
  //     method: 'POST',
  //     headers: apiHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };
  //   //Api call to get risk details for selected Risk     
  //   fetch(baseUrl, requestOptions)
  //     .then(response => response.json())
  //     .then(data => {
  //       setShowLoader("none");
  //       setShowModal("block");
  //       const details = data.result["dmn-evaluation-result"]["dmn-context"].detail;
  //       let temp = [];
  //       if (details !== null) {
  //         temp.push({
  //           "Inherent Risk Rating": details.inherentRiskRating,
  //           "Residual Likelihood": details.residualLikelihood,
  //           "Inherent Consequence": details.inherentConsequence,
  //           "Residual RiskRating": details.residualRiskRating,
  //           "Description": details.description,
  //           "Possible": details.possible
  //         })
  //         setRiskDetais(temp);
  //         setRiskTitle(selectedNode.id);
  //         setOpen(true);
  //       }
  //       else {
  //         setAlertMessgae(risk_details_error)
  //         setOpenAlertModal(true);
  //       }
  //     })
  //     .catch(error => {
  //       setShowLoader("none");
  //       console.log('error', error)
  //       setAlertMessgae(apiCall_error)
  //       setOpenAlertModal(true);
  //     });
  // }
  const callRiskDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_F6FFF2DF-2557-410E-9C01-6076D0831A16",
      "model-name": "RiskDetail",
      "dmn-context": {
        "riskID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      url: '/',
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };

    setShowLoader("none");
    setShowModal("block");
    let tempData = [{
      "Risk Category": "Confidentiality",
      "High Level Risk Scenario": "In approriate testing of the applications might result in generating incorrect pen test results",
      "Detailed Description of Risk": "While testing an application or a web page, if the applications are not properly configured, it might result in incorrect result( false positive or false negative )",
      "Motivation for Attacker": "High",
      "CIA Class": "C,I,A",
      "System": "Office 365, Staff laptops",
      "Inherent Likelihood": "Likely",
      "Inherent Consequence": "Major",
      "Inherent Risk Rating": "High-4",
      "Threat Actors": "Internal",
      "Current Control Maturity": "2.00",
      "Residual Likelihood": "Possible",
      "Residual Consequence": "Moderate",
      "Residual Risk Rating": "Medium-7",
    }]
    // setRiskDetais(selectedNode.detail);
    // setRiskTitle(selectedNode.label);
    // setOpen(true);
    setShowLoader("none");

    toggleRiskDetailDialog(null, { riskTitle: selectedNode.label, riskDetails: selectedNode.detail })

    //Api call to get risk details for selected Risk     
    // fetchInstance.apiRequest(requestOptions)
    //   .then(response => response.json())
    //   .then(data => {
    //     setShowLoader("none");
    //     setShowModal("block");
    //     const details = data.result["dmn-evaluation-result"]["dmn-context"].detail;
    //     let temp = [];
    //     if (details !== null) {
    //       temp.push({
    //         "Inherent Risk Rating": details.inherentRiskRating,
    //         "Residual Likelihood": details.residualLikelihood,
    //         "Inherent Consequence": details.inherentConsequence,
    //         "Residual RiskRating": details.residualRiskRating,
    //         "Description": details.description,
    //         "Possible": details.possible
    //       })
    //       setRiskDetais(temp);
    //       setRiskTitle(selectedNode.id);
    //       setOpen(true);
    //     }
    //     else {
    //       setAlertMessgae(risk_details_error)
    //       setOpenAlertModal(true);
    //     }
    //   })
    //   .catch(error => {
    //     setShowLoader("none");
    //     console.log('error', error)
    //     setAlertMessgae(apiCall_error)
    //     setOpenAlertModal(true);
    //   });
  }

  //to show mitigation node details
  const callMitigationDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_17F4956C-9B2A-4DD7-A83C-A39BFBA3B527",
      "model-name": "MitigationList",
      "dmn-context": {
        "mitigationID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      url: "/",
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };
    //Api call to get mitigation details for selected mitigation     
    fetchInstance.apiRequest(requestOptions)
      .then(response => response.json())
      .then(data => {
        setShowLoader("none");
        setShowModal("block");
        const details = data.result["dmn-evaluation-result"]["dmn-context"].mitigation;
        let temp = [];
        if (details !== null) {
          temp.push({
            "Name": (details.name === undefined || details.name === null) ? "" : details.name,
            "Domain ID": (details.domainID === undefined || details.domainID === null) ? "" : details.domainID,
            "description": (details.description === undefined || details.description === null) ? "" : details.description,
          })
          setRiskDetais(temp);
          setRiskTitle(selectedNode.id);
          setOpen(true);
        }
        else {
          setAlertMessgae(mitigation_details_error)
          setOpenAlertModal(true);
        }
      })
      .catch(error => {
        setShowLoader("none");
        console.log('error', error)
        setAlertMessgae(apiCall_error)
        setOpenAlertModal(true);
      });
  }
  //to show domain node details
  const callDomainDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_9F15CAC4-9633-4657-A4B8-49B0ACA4ADBC",
      "model-name": "DomainDetail",
      "dmn-context": {
        "domainID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      url: '/',
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };
    //Api call to get domain details for selected domain     
    fetchInstance.apiRequest(requestOptions)
      .then(response => response.json())
      .then(data => {
        setShowLoader("none");
        setShowModal("block");
        const details = data.result["dmn-evaluation-result"]["dmn-context"].detail;
        let temp = [];
        if (details !== null) {
          temp.push({
            "Name": (details.name === undefined || details.name === null) ? "" : details.name,
            "Score": (details.score === undefined || details.score === null) ? "" : details.score,
          })
          setRiskDetais(temp);
          setRiskTitle(selectedNode.id);
          setOpen(true);
        }
        else {
          setAlertMessgae(domain_details_error)
          setOpenAlertModal(true);
        }
      })
      .catch(error => {
        setShowLoader("none");
        console.log('error', error)
        setAlertMessgae(apiCall_error)
        setOpenAlertModal(true);
      });
  }

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
    setAlertMessgae("");
  };

  //to filter only extreme risk
  function extremeHandler(e) {
    const serverListCopy = [...serverList];
    const extremeRisks = serverListCopy.filter((ele) => {
      return ele.residualRiskRating === "Extreme"
    })
    if (extremeRisks.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      nodeCreator(extremeRisks, domainMits);
      edgeCreator(extremeRisks, domainMits);
      updateLegendCount(extremeRisks);
    }
  }

  //to filter critical mitigation   
  function criticalMitigationsFilter(e) {
    try {


      const serverListCopy = [...serverList];
      const { mitList: mitigationsObjCopy } = mitigationCal(serverListCopy);
      const maxMiti = Object.keys(mitigationsObjCopy).reduce((a, b) => mitigationsObjCopy[a] > mitigationsObjCopy[b] ? a : b);
      const maxMitValue = mitigationsObjCopy[maxMiti];
      const eightyPercent = ((80 * maxMitValue) / 100);
      const absoluteNo = Math.floor(eightyPercent);

      let topMiti = {};
      for (let key in mitigationsObjCopy) {
        if (mitigationsObjCopy[key] >= absoluteNo) { //checking if mitigation in the desired range
          topMiti[key] = mitigationsObjCopy[key]; //if condition matches adding the mitingation
        }
      }

      let apetiteMiti = serverListCopy.map((ele) => {
        console.log(ele);
        const temp = ele.domains.map((domain) => { //domain list with critical mit containing witout filter(empaty array)
          const criticalMiti = domain.mitigations.filter((mitigation) => { //critical miti list 
            return mitigation.mitigationID in topMiti //filtering critical mitigations
          })
          return {
            domainID: domain.domainID,
            domainScore: domain.domainScore,
            mitigations: criticalMiti
          }
        })
        const filteredCriticalMitDomainList = temp.filter((item) => {
          return item.mitigations.length > 0
        })
        return {
          domains: filteredCriticalMitDomainList,
          residualRiskRating: ele.residualRiskRating,
          riskID: ele.riskID
        }
      })
      const connectedRiskList = apetiteMiti.map((item) => {
        const localDomainList = item.domains.map((domain) => {
          const nullRemovedMit = domain.mitigations.filter((mit) => {
            return mit.mitigationScore !== null
          })
          if (nullRemovedMit.length > 0) {
            return {
              domainID: domain.domainID,
              domainScore: domain.domainScore,
              mitigations: nullRemovedMit
            }
          }
        })
        if (localDomainList.some(el => el !== undefined)) {
          return {
            domains: localDomainList.filter((item) => {
              return item !== undefined
            }),
            residualRiskRating: item.residualRiskRating,
            riskID: item.riskID
          }
        }
      })
      const removedNullApetiteList = connectedRiskList.filter((item) => {
        return item !== undefined
      })
      if (removedNullApetiteList.length === 0) {
        setAlertMessgae("No data available.")
        setOpenAlertModal(true);
      } else {
        nodeCreator(removedNullApetiteList, domainMits);
        edgeCreator(removedNullApetiteList, domainMits);
        updateLegendCount(removedNullApetiteList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //to filter risk added in last 7 days
  function newRiskHandler(e) {
    try {
      const serverListCopy = [...serverList];
      const currentMilisecond = new Date().getTime()
      const sevenDayMili = 86400000 * 10
      const lastSevenday = currentMilisecond - sevenDayMili
      const newRisk = serverListCopy.filter((ele) => {
        return ele.date > lastSevenday
      })
      console.log(newRisk);
      if (newRisk.length === 0) {
        setAlertMessgae("No data available.")
        setOpenAlertModal(true);
      } else {
        nodeCreator(newRisk, domainMits);
        edgeCreator(newRisk, domainMits);
        updateLegendCount(newRisk);
      }
    } catch (err) {
      console.log(err);
    }

  }

  //to create node list for graph for filter only risk as separate nodes
  function nodeCreatorV2() {
    const serverListCopy = [...serverList];
    serverListCopy.forEach((element) => {
      let RiskVal = 25;
      const risk = (element?.detail && element?.detail["Residual Risk Rating"]) || "";
      switch (element.residualRiskRating) {
        case "High": RiskVal = RiskVal * 4;
          break;
        case "Medium": RiskVal = RiskVal * 3;
          break;
        case "Low": RiskVal = RiskVal * 2;
          break;
        case "Extreme": RiskVal = RiskVal * 7;
          break;
        default:
      }
      nodes.push({ id: element.riskID, color: riskNodeColor, label: element.riskID, val: RiskVal, riskLevel: element.residualRiskRating, date: element.date, risk: risk })
    })
    const rskRatingArr = [
      { id: 'Extreme Risk', color: riskNodeColor, label: "Extreme", val: 200, riskLevel: "Extreme", },
      { id: 'High Risk', color: riskNodeColor, label: "High", val: 100, riskLevel: "High", },
      { id: 'Medium Risk', color: riskNodeColor, label: "Medium", val: 75, riskLevel: "Medium", },
      { id: 'Low Risk', color: riskNodeColor, label: "Low", val: 50, riskLevel: "Low", },
    ]
    rskRatingArr.forEach((element) => {
      nodes.push({ id: element.id, color: riskNodeColor, label: element.label, })
    })

    setGraph({ ...graph, nodes: nodes, links: links });
    updateLegendCount(serverListCopy);
  }
  //to create edges/links list for graph for filter only risk as separate nodes
  function edgeCreatorV2() {
    const serverListCopy = [...serverList];
    serverListCopy.forEach((ele) => {
      switch (true) {
        case ele.residualRiskRating === "Extreme":
          links.push({ id: links.length + 1, source: ele.riskID, target: "Extreme Risk", hidden: false, color: edgeDefaultColor })
          return
        case ele.residualRiskRating === "High":
          links.push({ id: links.length + 1, source: ele.riskID, target: "High Risk", hidden: false, color: edgeDefaultColor })
          return
        case ele.residualRiskRating === "Medium":
          links.push({ id: links.length + 1, source: ele.riskID, target: "Medium Risk", hidden: false, color: edgeDefaultColor })
          return
        case ele.residualRiskRating === "Low":
          links.push({ id: links.length + 1, source: ele.riskID, target: "Low Risk", hidden: false, color: edgeDefaultColor })
          return
        default:
          return
      }
    })
    setGraph({ ...graph, nodes: nodes, links: links });
    //forceRef.current.d3Force("charge").strength(-500);
  }
  //to filter only risk as separate nodes
  function onlyRiskHandlerV2(e) {
    nodeCreatorV2()
    edgeCreatorV2()
  }
  //to filter only risk as concentric circle
  function onlyRiskHandler(e) {
    const serverListCopy = [...serverList];
    const allowedResidualRiskRating = ["Extreme", "Medium", "High", "Low"];
    const extremeRisks = serverListCopy.filter((ele) => allowedResidualRiskRating.includes(ele.residualRiskRating));

    if (extremeRisks.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      riskNodeCreator(extremeRisks);
      updateLegendCount(extremeRisks);
    }
  }

  //to filter complaint domain 
  // const compliantDomainFilterHandler = (e) => {
  //   const serverListCopy = [...serverList];
  //   const cDomain = domainMits.filter((ele) => {
  //     return ele.domainScore >= 3;
  //   })
  //   console.log('DEBUG cDomain = ', cDomain);
  //   console.log('DEBUG serverListCopy = ', serverListCopy);
  //   const compliantDomain = serverListCopy.map((ele) => {
  //     const localFilter = cDomain.filter((domain) => { //filtering domain which are greater than and equal to 3
  //       return (domain.domainScore >= 3);
  //     })
  //     let connectedMitDomain = localFilter.map((domain) => {
  //       const nonNullMit = domain.mitigations.filter((mitigation) => {
  //         return mitigation.mitigationScore !== null
  //       })
  //       if (nonNullMit.length > 0) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     })
  //     if (connectedMitDomain.includes(true)) {
  //       return {
  //         riskID: ele.riskID,
  //         residualRiskRating: ele.residualRiskRating,
  //         domains: localFilter
  //       }
  //     }
  //   })
  //   const removedNull = compliantDomain.filter((item) => {
  //     return item !== undefined
  //   })
  //   if (compliantDomain.length === 0) {
  //     setAlertMessgae("No data available.")
  //     setOpenAlertModal(true);
  //   } else {
  //     nodeCreator(removedNull, domainMits);
  //     edgeCreator(removedNull, domainMits);
  //     updateLegendCount(removedNull);
  //   }
  // }

  const compliantDomainFilterHandler = (e) => {
    const serverListCopy = [...serverList];

    const compliantDomain = serverListCopy.filter((ele) => {
      return ele.domainScore >= 3;
    })
    if (compliantDomain.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      highlightFilteredList(compliantDomain);
    }
  }

  const highlightFilteredList = (list) => {
    list.forEach((list) => {
      graph.links.forEach((linknode) => {
        if (linknode.source.id === list.domainID) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = green1;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
        }
        else {
          if (linknode.source.color === demainDefaultColor)
            linknode.source.color = grey;
          if (linknode.target.color === mitigationDefaultColor)
            linknode.target.color = grey;
          if (!linknode.color == edgeHighlightColor)
            linknode.color = grey;
        }
      })
    })
  }

  //to filter non complaint domain 
  // const nonCompliantDomainFilterHandler = (e) => {
  //   const serverListCopy = [...riskMits];
  //   const nonCDomain = domainMits.filter((ele) => {
  //     return ele.domainScore < 3;
  //   })

  //   const nonCompliantDomain = serverListCopy.map((ele) => {
  //     nonCDomain.map((domain) => {
  //       return domain.domainID
  //     })
  //     const localFilter = ele.domains.filter((domain) => {
  //       return ele.domainScore < 3;
  //     })
  //     return {
  //       riskID: ele.riskID,
  //       residualRiskRating: ele.residualRiskRating,
  //       domains: localFilter
  //     }
  //   })

  //   if (nonCDomain.length === 0) {
  //     setAlertMessgae("No data available.")
  //     setOpenAlertModal(true);
  //   } else {
  //     nodeCreator(nonCompliantDomain, domainMits);
  //     edgeCreator(nonCompliantDomain, domainMits);
  //     updateLegendCount(nonCompliantDomain);
  //   }
  // }

  const nonCompliantDomainFilterHandler = (e) => {
    const serverListCopy = [...serverList];
    const nonCompliantDomain = serverListCopy.filter((ele) => {
      return ele.domainScore < 3;
    })
    if (nonCompliantDomain.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      highlightFilteredList(nonCompliantDomain);
    }
  }

  //to set the graph back to default mode
  function resetFilterHandler() {
    const serverListCopy = [...serverList];
    nodeCreator(serverListCopy, domainData);
    edgeCreator(serverListCopy, domainData);
    updateLegendCount(serverListCopy);
  }

  //to handle filter operation
  function handleFilter(e) {
    setFilter(e.target.value);
    setRiskOnlySelected(false);
    setAddTextNode(false);
    forceRef.current.d3Force("charge").strength(-50);
    if (e.target.value === "extreme") {
      extremeHandler(e);
    } else if (e.target.value === "mitigations") {
      criticalMitigationsFilter(e)
    } else if (e.target.value === "new") {
      newRiskHandler(e)
    } else if (e.target.value === "only_risk") {
      onlyRiskHandler(e);
      setRiskOnlySelected(true);
    } else if (e.target.value === "only_risk_v2") {
      onlyRiskHandlerV2(e);
      setAddTextNode(true);
    } else if (e.target.value === "non-compliant") {
      nonCompliantDomainFilterHandler(e)
    } else if (e.target.value === "compliant") {
      compliantDomainFilterHandler(e)
    } else {
      resetFilterHandler()
    }
  }


  const handleModalClose = () => {
    setOpen(false);
    setShowPopup(false);
    setShowModal("none");
  };

  //to update residual rsk rating color based ot is severity
  const getColor = (details) => {
    let color = "white";
    switch (details["Inherent Risk Rating"]) {
      case "Medium": color = "orange";
        break;
      case "Extreme": color = "red";
        break;
      case "High": color = "red";
        break;
      default:
    }
    return color;
  }

  const paintRing = useCallback((node, ctx, globalScale) => {
    //to update graph for risk only filter
    // showOnlyRisk(node, ctx, globalScale);

    //to add ring around extreme risk nodes
    // const ringRadius = 15 * 2.4;
    // const extremeRisks = graph.nodes.filter(ele => ele.riskLevel === "Extreme");
    // ctx.beginPath();
    // ctx.arc(node.x, node.y, ringRadius, 0, 2 * Math.PI, false);

    // for (const element of extremeRisks) {
    //   if (nodeClicked === null && node.id === element.id) {
    //     element.color = darkred;
    //     ctx.fillStyle = "red";
    //     ctx.fill();
    //   } else if (nodeClicked !== null && nodeClicked.id !== node.id && node.id === element.id && element.color !== red1 && element.color !== green1 && element.color !== demainDefaultColor) {
    //     element.color = grey;
    //     ctx.fillStyle = grey;
    //     ctx.fill();
    //   }
    // }

    if (nodeClicked !== null) {
      const isFound = Array.from(highlightLinks).find(link => link.source.id === node.id) ?? false;
      if (isFound) {
        loopAni(node, ctx, riskNodeColor);
      } else {
        loopAni(node, ctx, grey);
      }
    } else {
      loopAni(node, ctx, riskNodeColor);
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [graph.nodes, nodeClicked]);

  function loopAni(node, ctx, color) {
    node.color = color;
    if (node['rSize'] === undefined) {
      node['rSize'] = 10
      node['rD'] = false
    } else {
      if (node.rD === false) {
        node.rSize = node.rSize + 1;
        if (node.rSize === 40) node['rD'] = true;
      } else if (node.rD === true) {
        node.rSize = node.rSize - 1;
        if (node.rSize === 10) node['rD'] = false;
      }
    }

    //change size of circle
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(node.x, node.y, node.rSize, 0, 2 * Math.PI);
    ctx.fill();

    //change size of ring
    ctx.beginPath();
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.arc(node.x, node.y, node.rSize + 10, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.arc(node.x, node.y, node.rSize + 25, 0, 2 * Math.PI);
    ctx.stroke();

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showOnlyRisk = (node, ctx, globalScale) => {
    //if only risk filter selected to show in concentric mode
    if (riskOnlySelected) {
      let annulus = getAnnulus(node.riskLevel);
      let position = verifyPosition(node.x, node.y, annulus[0], annulus[1]);
      node.x = position[0];
      node.y = position[1];
    }
    //if only risk filter selected to show as separate node
    if (addTextNode) {
      addNodeText(node, ctx, globalScale)
    }
  }

  //to add text as node for risk only common filter   
  function addNodeText(node, ctx, globalScale) {
    if (node.id === "Extreme Risk" || node.id === "High Risk" || node.id === "Medium Risk" || node.id === "Low Risk") {
      const label = node.id;
      const fontSize = 12 / globalScale;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(label).width;
      const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = node.color;
      ctx.fillText(label, node.x, node.y);

      node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
    }
  }

  //function to verify if X in the correct position
  function verifyPosition(x, y, small_r, big_r) {
    let point;
    //verify if P is in annulus defined by small_r and big_r
    if ((Math.pow(x - cx, 2) + Math.pow(y - cy, 2)) <= Math.pow(small_r, 2)) {
      // P inside small circle
      point = recalculateP(x, y, small_r);
    } else if ((Math.pow(x - cx, 2) + Math.pow(y - cy, 2)) > Math.pow(big_r, 2)) {
      // P outside big circle
      point = recalculateP(x, y, big_r);
    } else {
      point = [x, y];
    }
    return point;
  }

  //places point off circle on circle ring
  function recalculateP(x, y, r) {
    let vx = x - cx;
    let vy = y - cy;
    let norm = Math.sqrt(Math.pow(vx, 2) + Math.pow(vy, 2));
    let new_x = cx + vx / norm * r;
    let new_y = cy + vy / norm * r;
    return [new_x, new_y];
  }

  //function returns small and big radiuses of annulus based on Point year
  function getAnnulus(riskLevel) {
    let big_radius;
    let separator = 500;
    if (riskLevel === "Extreme") {
      big_radius = 3 * separator;
    }
    else if (riskLevel === "High") {
      big_radius = 6 * separator;
    }
    else if (riskLevel === "Medium") {
      big_radius = 9 * separator;
    }
    else {
      big_radius = 12 * separator;
    }
    return [big_radius - separator, big_radius];
  }

  return (
    <>

      <CircleLoader style={{ display: showLoader }} />
      <Grid container sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, paddingTop: { xs: 4, sm: 'unset' } }}>
        <Grid item xs={12} sm={8} md={9} lg={10} id="graph-container">
          <ThemeProvider theme={theme}>
            <Card sx={{ margin: 0 }} elevation={0} variant="outlined">
              <CustomizedCardContent sx={{ minHeight: 100, padding: '0' }}>
                <ReactForceGraph2d
                  backgroundColor="transparent"
                  ref={forceRef}
                  graphData={graph}
                  // width={window.innerWidth - 10}
                  // height={window.innerHeight}
                  {...document.querySelector('#graph-container')?.clientWidth > 0 && { width: document.querySelector('#graph-container').clientWidth }}
                  {...document.querySelector('#graph-box')?.clientHeight > 0 && { height: document.querySelector('#graph-box').clientHeight - (isSmallScreen ? 96 : 2) }}
                  warmupTicks={200}
                  // nodeCanvasObjectMode={node => node.riskLevel?.startsWith("Extreme") || node.riskLevel?.startsWith("High") || node.riskLevel?.startsWith("Medium") ? 'after' : null}
                  // onEngineStop={() => forceRef.current.zoomToFit()}
                  nodeCanvasObjectMode={
                    (node) => isRiskAboveOrEqualTolerance(node.risk, props.toleranceRisk) ? 'after' : null
                  }
                  nodeLabel={"label"}
                  onNodeClick={handleNodeClick}
                  onBackgroundClick={resetHighLight}
                  onNodeRightClick={onNodeRightClick}
                  linkColor={(link) => linkColor}
                  linkDirectionalParticleColor={() => "red"}
                  linkWidth={link => highlightLinks.has(link) ? 3 : 0.5}
                  linkDirectionalParticles={2}
                  linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 4 : 0}
                  linkDirectionalArrowLength={3.5}
                  linkDirectionalArrowRelPos={1}
                  linkCurvature={0.35}
                  linkOpacity={0.2}
                  nodeCanvasObject={paintRing}
                  autoPauseRedraw={true}
                  minZoom={0.01}
                  maxZoom={2}
                  onNodeDragEnd={node => {
                    node.fx = node.x;
                    node.fy = node.y;
                    node.fz = node.z;
                  }}
                  dagMode={'zout'}
                  nodeVisibility={(node) => node.id !== "0"}
                />
              </CustomizedCardContent>
            </Card>
          </ThemeProvider>
        </Grid>
        <Divider />
        <Grid item sx={{ padding: 2 }} xs={12} sm={4} md={3} lg={2}>
          <Grid container spacing={2}>
            {
              props.GraphPicker && <Grid item xs={12}>
                {props.GraphPicker}
              </Grid>
            }
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Filter</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={filter}
                  label="Filter"
                  onChange={handleFilter}
                >
                  <MenuItem value={"extreme"}>Risks beyond appetite</MenuItem>
                  <MenuItem value={"mitigations"}>Most critical mitigations</MenuItem>
                  <MenuItem value={"new"}>New Risk</MenuItem>
                  {/* <MenuItem value={"only_risk"}>Only Risk</MenuItem>
            <MenuItem value={"only_risk_v2"}>Only Risk v2</MenuItem> */}
                  <MenuItem value={"non-compliant"}>Non-compliant domains</MenuItem>
                  <MenuItem value={"compliant"}>Compliant domains</MenuItem>
                  <MenuItem value={"reset"}>Reset filter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LegendPage {...riskCounts} toleranceRisk={props.toleranceRisk} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* start of Popover to show option on node right click  */}
      <Popover
        id={'simple-popover'}
        open={showPopup}
        anchorReference="anchorPosition"
        anchorPosition={{ top: top, left: left }}
        onClose={() => { setShowPopup(false); }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <List>
          <ListItem onClick={onClickViewDetails}>View Details</ListItem>
        </List>
      </Popover>
      {/******** end of Popover to show option on node right click  ********/}

      {/* dialog show details of selected node */}
      <RiskDetailDialog
        open={open}
        toggle={toggleRiskDetailDialog}
        data={dialogRiskDetails}
      />

      {/* start of common alert dialog */}
      <CustomDialog
        open={openAlertModal}
        onClose={handleCloseAlertModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" paddingLeft={2} paddingRight={5}>
            {alertMessgae}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertModal} style={{ color: "#46a11b" }} autoFocus>
            OK</Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default CombinedGraph;