import React from "react";

import { DescriptionContent, DescriptionTextHead, DescriptionTextParagraph, StyledList, StyledInput } from "./RiskList.styled";
import { useDispatch, useSelector } from "react-redux";

const RiskList = ({
    tabNum,
    mZero,
    smWidth,
    mdWidth,
    bgWidth,
    smPad,
    actionWidth,
    acSmWidth,
    acMdWidth,
    actionPad,
    maxheight,
    bigfont,
    smsize,
    isEdit,
    parentCallback
}) => {

    const dispatch = useDispatch();
    const { riskConsequence } = useSelector((state) => state.riskMatrix);
    const { levels, fields, tabs } = riskConsequence;

    function handleTextChange(e, value, rowIndex) {
        const isFound = fields.find(field => field.name === `consCat${tabNum}Row${rowIndex + 1}`);
        let newFields = [...fields];

        if (!isFound) {
            newFields.push({ name: `consCat${tabNum}Row${rowIndex + 1}`, desc: e.target.value });
        }
        else {
            const index = newFields.findIndex((element) => element["name"] === value["name"]);

            value["desc"] = e.target.value;
            newFields[index] = value;
        }

        dispatch({
            type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
            payload: {
                tabs: tabs,
                levels: levels,
                fields: newFields
            }
        })

    }

    parentCallback(dataForApi());

    function dataForApi() {
        var obj = {};
        tabs.forEach(function (value, i) {
            //data from category tabs
            obj[`riskCategoryRow${i + 1}`] = value["name"];
            //data from descriptions
            for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
                const value = fields.find(field => field.name === `consCat${levelIndex + 1}Row${i + 1}`);
                obj[`consCat${levelIndex + 1}Row${i + 1}`] = value ? value["desc"] : "";
                obj[`consequenceCategory${levelIndex + 1}`] = levels[levelIndex];
            }
        });
        return obj
    }

    return (
        <StyledList
            mZero={mZero}
            actionPad={actionPad}
            maxheight={maxheight}
            smsize={smsize}
        >
            <li >
                <DescriptionContent
                    bgWidth={bgWidth}
                    actionWidth={actionWidth}
                >
                    <DescriptionTextHead
                        smWidth={smWidth}
                        acSmWidth={acSmWidth}
                        bigfont={bigfont}
                    >
                        Consequence
                    </DescriptionTextHead>
                    <DescriptionTextParagraph
                        acMdWidth={acMdWidth}
                        mdWidth={mdWidth}
                        smPad={smPad}
                    >
                        Description
                    </DescriptionTextParagraph>
                </DescriptionContent>
            </li>
            {levels.map((level, index) => {
                const value = fields.find(field => field.name === `consCat${index + 1}Row${tabNum}`) || { name: `consCat${index + 1}Row${tabNum}`, desc: "" };
                return (
                    <li key={index}>
                        <DescriptionContent
                            bgWidth={bgWidth}
                            actionWidth={actionWidth}
                        >
                            <DescriptionTextHead
                                smWidth={smWidth}
                                acSmWidth={acSmWidth}
                                bigfont={bigfont}
                            >
                                {level}
                            </DescriptionTextHead>
                            {
                                isEdit ? <StyledInput type="text"
                                    defaultValue={value.desc}
                                    id={index}
                                    maxLength='500' onChange={(event) => handleTextChange(event, value, index)} />
                                    : <DescriptionTextParagraph>{value.desc}</DescriptionTextParagraph>
                            }
                        </DescriptionContent>
                    </li>
                )
            })}
        </StyledList>
    );
};

// const RiskList = ({
//     tabs,
//     levels,
//     fields,
//     tabNum,
//     mZero,
//     smWidth,
//     mdWidth,
//     bgWidth,
//     smPad,
//     actionWidth,
//     acSmWidth,
//     acMdWidth,
//     actionPad,
//     maxheight,
//     bigfont,
//     smsize,
//     isEdit,
//     parentCallback
// }) => {


//     function handleTextChange(e, value, rowIndex) {
//         const isFound = fields.find(field => field.name === `consCat${tabNum}Row${rowIndex + 1}`);

//         if (!isFound) {
//             fields.push({ name: `consCat${tabNum}Row${rowIndex + 1}`, desc: e.target.value });
//         }
//         else {
//             const index = fields.findIndex((element) => element["name"] === value["name"]);

//             value["desc"] = e.target.value;
//             fields[index] = value;
//         }


//     }

//     parentCallback(dataForApi());

//     function dataForApi() {
//         //riskCategoryRow1 : tabs
//         //consCat1Row1 : fields
//         //consequenceCategory1 : levels
//         var obj = {};
//         tabs.forEach(function (value, i) {
//             //data from category tabs
//             obj[`riskCategoryRow${i + 1}`] = value["name"];
//             //data from descriptions
//             for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
//                 const value = fields.find(field => field.name === `consCat${levelIndex+1}Row${i + 1}`);
//                 obj[`consCat${levelIndex + 1}Row${i + 1}`] = value ? value["desc"] : "";
//                 obj[`consequenceCategory${levelIndex+1}`] = levels[levelIndex];
//             }
//         });

//         return obj
//     }

//     return (
//         <StyledList
//             mZero={mZero}
//             actionPad={actionPad}
//             maxheight={maxheight}
//             smsize={smsize}
//         >
//             <li >
//                 <DescriptionContent
//                     bgWidth={bgWidth}
//                     actionWidth={actionWidth}
//                 >
//                     <DescriptionTextHead
//                         smWidth={smWidth}
//                         acSmWidth={acSmWidth}
//                         bigfont={bigfont}
//                     >
//                         Consequence
//                     </DescriptionTextHead>
//                     <DescriptionTextParagraph
//                         acMdWidth={acMdWidth}
//                         mdWidth={mdWidth}
//                         smPad={smPad}
//                     >
//                         Description
//                     </DescriptionTextParagraph>
//                 </DescriptionContent>
//             </li>
//             {levels.map((level, index) => {
//                 const value = fields.find(field => field.name === `consCat${index + 1}Row${tabNum}`) || { name: `consCat${index + 1}Row${tabNum}`, desc: "" };
//                 return (
//                     <li key={index}>
//                         <DescriptionContent
//                             bgWidth={bgWidth}
//                             actionWidth={actionWidth}
//                         >
//                             <DescriptionTextHead
//                                 smWidth={smWidth}
//                                 acSmWidth={acSmWidth}
//                                 bigfont={bigfont}
//                             >
//                                 {level}
//                             </DescriptionTextHead>
//                             {/* <DescriptionTextParagraph
//                                 acMdWidth={acMdWidth}
//                                 mdWidth={mdWidth}
//                                 smPad={smPad}
//                             >
//                                 {value.desc}
//                             </DescriptionTextParagraph> */}
//                             {
//                                 isEdit ? <StyledInput type="text"
//                                 defaultValue={value.desc}
//                                     id={index}
//                                     maxLength='500' onChange={(event) => handleTextChange(event, value, index)} />
//                                     : <DescriptionTextParagraph>{value.desc}</DescriptionTextParagraph>
//                             }
//                         </DescriptionContent>
//                     </li>
//                 )
//             })}
//         </StyledList>
//     );
// };

export default RiskList;
