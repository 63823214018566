import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Heading from '../../../../components/UI/Heading/Heading'
import { StyledComplateContainer, Wrapper } from './ThreatElicitationPage.styled';
import ProjectSection from './components/ProjectSection';
import SolutionSection from './components/SolutionSection';
import { useSelector } from 'react-redux';
import SolutionBuildingBlockSection from './components/SolutionBuildingBlockSection';
import { Helmet } from "react-helmet-async";

const ThreatsEllicitationPage = () => {

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  return <>
    <Helmet>
      <title>Threat Elicitation - CisoZen</title>
    </Helmet>
    <Wrapper>
      <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
        Project and Solutions
      </Heading>
      {/* <BackgroundFetchLoader /> */}

      <Box sx={{ mb: 10 }}>
        <ProjectSection />
      </Box>

      {
        <Box sx={{ mb: 10 }}>
          <SolutionSection />
        </Box>
      }

      {
        <Box sx={{ mb: 10 }}>
          <SolutionBuildingBlockSection />
        </Box>
      }

      {/* {
      solutionBuildingBlockFetchCounter > 0 && <Box sx={{ mb: 10 }}>
        <ThreatSection />
      </Box>
    } */}

    </Wrapper>
  </>

  // return (
  //   <>
  //     <center>
  //       <Heading marginMd="32px 0" marginSm="24px 18px" >
  //         Project and Solutions
  //       </Heading>
  //     </center>
  //     <ThreatsEllicitationTable questionData={ThreatCreateData} />

  //     <br />
  //     <ThreatsEllicitationTable questionData={SolutionData} />
  //   </>

  // )
}

export default ThreatsEllicitationPage