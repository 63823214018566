import { Grid } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, AssessMaturity } from "../../../../store/auth-context";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { useKeycloak } from "@react-keycloak/web";
import { getMileStones, getActiveCases, updateProcessID, getVariablesfromApi, getVariablesValuesfromApi } from "./SecurityControlMaturityAssessmentApi";
import Lottie from "lottie-react";
import logo from "../../../../images/logo0.2.png";
import doneAnimation from "../../../../assets/animations/done.json";
import {
    Wrapper,
    CardHead,
    MainGrid,
    ProgressBlock,
    ProgressCard,
    ProgressValue,
    StatsText,
    StatsWrapper,
    TextBlock,
} from "./SecurityControlMaturityAssessment.styled";
import { getCase, startMileStone } from "../TRA/TRAApi";
import { StyledComplateContainer } from "../HealthCheck/HealthCheck.styled";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowRiskUpdate } from "../../../../utility/dataAction";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { Helmet } from "react-helmet-async";

function SecurityControlMaturityAssessment(props) {
    var history = useHistory();
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [caseID, setCaseID] = useState(null);
    const [controls, setControls] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [header, setHeader] = useState(null);
    const [variables, setVariables] = useState(null);
    const [apiVariables, setApiVariables] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);
    const [oldScores, setOldScores] = useState(false);
    const [activeScores, setActiveScores] = useState(false);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Security Control Maturity Assessment`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    useEffect(() => {
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;

        try {
            let dataString = window.sessionStorage.getItem("MA_CONTROLS");
            let controlData = JSON.parse(dataString);
            if (controlData) {
                setControls(controlData);
            }

        } catch (error) {

        }
        getActiveCasesApi();
        getClosedMaturity();
    }, [keycloak.token, CONTAINER_ID]);

    useEffect(() => {
        if (caseID === null) return;
        updateProcessIDApi();
    }, [caseID]);

    function getClosedMaturity() {

        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'closed', 1).then((response) => {
            if (response.success) {
                setLoading(false)
                let maturityList = response.data.instances;
                if (maturityList.length > 0) {
                    let caseData = maturityList[0]['case-file']['case-data'];
                    if (caseData) {
                        let domainList = [];
                        Object.keys(caseData).map((key) => {
                            if (key.startsWith('Domain')) {
                                let domain = JSON.parse(caseData[key]);
                                if (domain) {
                                    domain.isExpanded = false;
                                    domainList.push(domain);
                                }
                            }
                        });


                        if (caseData["maturityScores"]) {
                            setOldScores(caseData["maturityScores"]);
                        }

                        // let sortedList = domainList.sort((a, b) => a.domainID - b.domainID);
                        // setDomainList(sortedList);
                    }
                }
            }
        });
    }

    //api call to get last active case
    function getActiveCasesApi() {
        // setLoading(true);
        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'open', 1).then((response) => {
            if (response.success) {
                let instances = response.data.instances;
                if (instances) {
                    if (instances.length > 0) {
                        const caseID = response.data.instances[0]["case-id"];
                        setCaseID(caseID);
                        let caseData = response.data.instances[0]['case-file']['case-data'];
                        if (caseData) {
                            if (caseData["maturityScores"]) {
                                setActiveScores(caseData["maturityScores"]);
                            }
                        }
                    }
                    setLoading(false);
                }
            }
        });

    }

    function updateProcessIDApi() {

        updateProcessID(CONTAINER_ID, caseID, keycloak).then((response) => {
            //check status code
            if (response.status == 200) {
                const processInstanceID = response.data["process-instance"][0]["process-instance-id"];
                setProcessInstanceID(processInstanceID);
            }
        })
            .catch(function (error) {
                //
            });
    }

    //api to get process id
    async function getProcessID(controlNumber) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/processes/instances?page=0&pageSize=10&sortOrder=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const processInstanceID = response.data["process-instance"][0]["process-instance-id"];
                    window.sessionStorage.setItem("MA_ACTIVE_CONTROL", controlNumber);
                    window.sessionStorage.setItem("MA_ACTIVE_PROCESS", processInstanceID);
                    window.sessionStorage.setItem("MA_CONTROLS", JSON.stringify(controls));
                    history.push("/it-security/maturity-assessment/question")
                }
            })
            .catch(function (error) {
                //
            });
    }

    //method to call the api to get milestones related to active case
    function getMileStonesApi() {

        getMileStones(CONTAINER_ID, caseID, keycloak).then((response) => {
            //check status code
            if (response.status == 200) {
                var controlList = [];
                response.data["milestones"].forEach(element => {
                    let name = element["milestone-name"];
                    if (name.startsWith('Control') && name !== "Control Scores") {
                        var control = {};
                        const mileStone = element["milestone-name"].split('.')[0];
                        control['milestone-name'] = element["milestone-name"];
                        control["domain-name"] = `${element["milestone-name"].split('.')[1]}`;
                        control["controlNumber"] = mileStone.split('Control')[1].replaceAll(' ', '')
                        control["total-questions"] = getVariables(control.controlNumber);
                        control["current-score"] = getDomainValue(control.controlNumber);
                        control["total-completed"] = getCompletedSections(control.controlNumber, control["total-questions"]);
                        controlList.push(control)
                    }
                });

                //sort controList by controlNumber
                const sortedcontrolList = [...controlList].sort((a, b) => {
                    return Number(a.controlNumber) > Number(b.controlNumber) ? 1 : -1
                })

                setControls(sortedcontrolList);
                // getProcessVariables();
            }
        })
            .catch(function (error) {
                console.log(error);
            });


    }

    useEffect(() => {
        if (processInstanceID === null) return;
        getVariablesfromApiCall()
        getVariablesValuesfromApiCall()
    }, [processInstanceID])

    useEffect(() => {
        if (apiVariables === null || variables === null) return;
        getMileStonesApi()
    }, [apiVariables, variables])


    function handleClick(controlNumber, milestoneName) {

        startMileStone(CONTAINER_ID, keycloak.token, caseID, milestoneName).then((response) => {
            if (response.success) {
                getProcessID(controlNumber);
            }
        });
    }

    //method to call the api to get list of variable values by active maturity assessment process instance ID
    function getVariablesValuesfromApiCall() {

        getVariablesValuesfromApi(CONTAINER_ID, processInstanceID, keycloak).then((response) => {
            setVariables(response.data);
        })

            .catch(function (error) {
                // console.log(error);
            });
    }

    //method to call the api to get list of variables key in maturity assessment
    function getVariablesfromApiCall(controlNumber) {

        getVariablesfromApi(CONTAINER_ID, AssessMaturity, keycloak).then((response) => {
            setApiVariables(response.data);

        })
            .catch(function (error) {
                // console.log(error);
            });

    }
    function getVariables(controlNumber) {

        if (apiVariables === null) return '';
        let sections = Object.fromEntries(Object.entries(apiVariables.variables).filter(([key]) => key.includes(`Average-${controlNumber}`)));
        return Object.keys(sections).length;

    }
    //get domain score
    function getDomainValue(controlNumber) {
        //variable-instance
        let variablesArr = variables["variable-instance"];
        let result = variablesArr.find(element => element[`name`] === `AverageControl-${controlNumber}`)?.value ?? 0;
        return Math.round(result);
    }
    //get completed sections per domain
    function getCompletedSections(controlNumber, totalSections) {
        var totalCompletedSections = 0;
        //variable-instance
        let variablesArr = variables["variable-instance"];
        for (let index = 0; index < totalSections; index++) {
            let result = variablesArr.find(element => element[`name`] === `Average-${controlNumber}-${index + 1}`)?.value ?? null;
            if (result !== null) {
                totalCompletedSections++;
            }
        }

        return totalCompletedSections;
    }

    async function getProcessVariables() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/definitions/${AssessMaturity}/variables`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    //to get total sections for domain
                    setVariables(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        window.sessionStorage.setItem("MA_CONTROLS", JSON.stringify(controls));
        var items = [];
        controls.forEach((item, index) => {
            items.push(<MainGrid item lg={3} md={3} sm={6} xs={12} key={index}>
                <ProgressCard>
                    <CardHead>{item["controlNumber"]}. {item["domain-name"]}</CardHead>
                    <div>
                        <ProgressBlock>
                            <CircularProgressWithLabel value={item["total-completed"] / item["total-questions"] * 100}>
                                <ProgressValue>{item["total-completed"]}/{item["total-questions"]}</ProgressValue>
                            </CircularProgressWithLabel>
                        </ProgressBlock>
                        <StatsText>Current Score ({Math.floor(activeScores[`averageControl_${item["controlNumber"]}`] || 0)})</StatsText>
                        <Button $success style={{ textTransform: 'capitalize', width: '100%' }} onClick={() => handleClick(item["controlNumber"], item["milestone-name"])}>
                            assess
                        </Button>
                    </div>
                </ProgressCard>
            </MainGrid>)
        })
        setItemList(items);
    }, [controls])

    function endCase() {
        setLoading(true);
        startMileStone(CONTAINER_ID, keycloak.token, caseID, 'End').then((response) => {
            if (response.success === true) {
                setLoading(false);
                setAssessmentCompleted(true);
                dispatch(toggleAllowRiskUpdate(false));
            }
        })
    }

    if (assessmentCompleted) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> You have completed the Maturity Assessment.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/maturity-assessment")}>
                            Done
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    return (
        <>
            <Helmet>
                <title>Maturity Assessment New Progress - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Fragment>
                    <StatsWrapper>
                        <Heading marginMd="32px 0" marginSm="24px 18px">
                            Security Control Maturity Assessment
                        </Heading>
                        <Grid item xs={12} marginBottom="15px">
                            <Button $success onClick={(e) => endCase()}>Complete Assessment</Button>
                        </Grid>
                        <Grid container spacing={2}>
                            <>
                                {loading ? <Loader height="40vh" /> : itemList}
                            </>
                        </Grid>
                    </StatsWrapper>
                </Fragment>
            </Wrapper>
        </>
    );
}

export default SecurityControlMaturityAssessment;
