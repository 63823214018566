
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { setCurrentOrganisation } from '../../utility/miscellaneous.action';

const OrganisationSelect = () => {

    // ** Hooks
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    // ** Redux states
    const { currentOrganisation, organisationList } = useSelector((state) => state.miscellaneous)

    if (!(organisationList?.length > 1)) return <></>;

    return (
        <FormControl variant="outlined" fullWidth>
            <Select
                value={currentOrganisation}
                placeholder='Select Organisation'
                onChange={(e) => {
                    dispatch(setCurrentOrganisation(keycloak, e?.target?.value))
                }}
            >
                {
                    organisationList.map((orgName) => <MenuItem key={orgName} value={orgName}>{orgName}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}

export default OrganisationSelect;