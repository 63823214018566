import styled from "styled-components";
import { Drawer, List, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

export const CustomDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
        background-image: none;
        overflow: hidden;
    }
`;

export const DrawerWrapper = styled.div`
    overflow: hidden;
    padding-top: 14px;
    height: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-top: 0px;
    }
`;

export const CustomNavLink = styled(NavLink)`
    &.active {
        & .MuiMenuItem-root {
            background-color: #3a8d1c;
            & .MuiSvgIcon-root {
                color: #fff;
            }
        }
    }
`;

export const CustomMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        height: 54px;
        justify-content: center;
    }
    & .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.mode === "light" ? "#11142D" : "#fff"};
    }
`;

export const CustomMenuItemIcon = styled(ListItemIcon)`
    
`;

export const CustomMenuItemText = styled(ListItemText)`
 color: ${({ theme }) => theme.palette.mode === "light" ? "#11142D" : "#fff"};
`;

export const SideDrawerLogo = styled.img`
    display: block;
    margin: 0 auto 32px;
    max-width: 54px;
    object-fit: cover;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        display: none;
    }
`;

export const CustomList = styled(List)`
    &.MuiList-root {
        max-height: 100%;
        overflow-y: auto;
        padding: 0;
        &::-webkit-scrollbar {
            height: 7px;
            width: 8px;
            background-color: ${({ theme }) => theme.palette.action.selected};
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            margin-left: 2px;
            background: #3a8d1c;
            border-radius: 10px;
            cursor: pointer;
        }
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            max-height: calc(100% - 96px);
        }
    }
`;
