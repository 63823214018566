import { useContext } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapperRadar, StatsText, TextBlock } from '../Information.styled';
import ThemeContext from '../../../../../store/theme-context';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

function BusinessImpactCard() {

    const { theme } = useContext(ThemeContext);

    let data = [
        {
            label: '',
            data: [2, 8, 23],
            backgroundColor: 'rgba(237, 30, 36, 0.2)',
            borderColor: 'rgba(237, 30, 36, 1)',
            borderWidth: 1,
        }
    ];

    const graphData = {
        labels: ['Integrity', 'Availability', 'Confidentiality'],
        datasets: data
    }

    let options = {
        scales: {
            r: {
                suggestedMin: 0,
                angleLines: {
                    color: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(105, 105, 105, 0.2)',
                    lineWidth: 1,
                },
                grid: {
                    color: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(105, 105, 105, 0.2)',
                    lineWidth: 1,
                }
            },
        },
    }

    return (
        <ProgressCard>
            <ProgressCardContent>
                <ProgressCardContentTop>
                    <CardHead>Business Impact</CardHead>
                    <ProgressContainer>
                        <ProgressWrapperRadar>
                            <Radar data={graphData} options={options} />
                        </ProgressWrapperRadar>
                    </ProgressContainer>
                    {/* <LegendContainer> */}
                    {/* {
                        data.map((d, index) => (
                            <TextBlock key={d.label + index}>
                                <DotSign style={{ backgroundColor: d.color }} />
                                <StatsText>{d.label}</StatsText>
                            </TextBlock>
                        ))
                    } */}
                    {/* </LegendContainer> */}
                </ProgressCardContentTop>
            </ProgressCardContent>
        </ProgressCard>
    );
}
export default BusinessImpactCard;   