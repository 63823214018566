
import { useState, useEffect, useCallback } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MuiLoadingButton from "@mui/lab/LoadingButton";

import SyncIcon from "@mui/icons-material/Sync";

import { Button, CustomLoadingButton } from "../../../../../components/UI/Button/Button.styled";
import { toggleCreateNewCaseLoading, toggleGenerateDocumentLoading, updateDocumentContentData } from "../../../../../utility/documents.action";
import { closeCase, completeUserTask, getCaseDocumentContent, getProcessIDByCase, getUserTask, initGenerateDocumentContent, startDocumentProcess, startUserTask } from "../../TRA/TRAApi";
import { CONTAINER_ID } from "../../../../../store/auth-context";
import ImageZoomInOut from "../../ThreatsEllicitation/components/ImageZoomInOut";

const imageRegex = new RegExp('^(.+)\.(png|jpe?g|gif)$', 'i');

const FileSelection = (props) => {

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    const { documentList, documentCaseData, generateDocumentLoading, createNewCaseLoading } = useSelector((state) => state.documents);

    const { open, caseData, selectedTRA, imageURL, setImageURL } = props;

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [generatDocumentLoading, setGenerateDocumentLoading] = useState(false);


    useEffect(() => {
        if (open) {
            setSelectedDocument(null);
            let foundDoc = (documentList || []).find((doc) => doc.id === selectedTRA?.documentID)
            if (selectedTRA?.documentID && foundDoc) {
                setSelectedDocument(foundDoc);
                if (foundDoc?.isDocumentGenerated) {
                    downloadDocument(foundDoc);
                } else {
                    generateDocument(foundDoc);
                }
            }
        }
    }, [open])

    function regenerateCase() {
        dispatch(toggleCreateNewCaseLoading(true));
        closeCase(CONTAINER_ID, keycloak.token, documentCaseData['case-id'])
            .then((response) => {
                if (response.success) {
                    startDocumentProcess();
                } else {
                    dispatch(toggleCreateNewCaseLoading(false));
                    toast.error(response.error);
                }
            })
            .catch((err) => {
                dispatch(toggleCreateNewCaseLoading(false));
                toast.error(err?.message || err);
            });
    }

    async function generateDocument(document) {
        try {
            dispatch(toggleGenerateDocumentLoading(true, document['id']));
            setGenerateDocumentLoading(true);
            const caseID = documentCaseData['case-id'];

            // Check if the document is already generate or not
            let res = await getCaseDocumentContent(caseID, `FILE${document['id']}`);
            if (res.data && res.data['org.kie.server.api.model.type.JaxbByteArray'] && res.data['org.kie.server.api.model.type.JaxbByteArray']['value']) {
                let documentContent = res.data['org.kie.server.api.model.type.JaxbByteArray']['value'];
                dispatch(updateDocumentContentData(keycloak, document['id'], documentContent));
                return;
            } else {

                // Initialize generate content
                const res1 = await initGenerateDocumentContent(caseID);
                const res2 = await getProcessIDByCase(CONTAINER_ID, keycloak?.token, caseID);
                if (!res2.success) {
                    setGenerateDocumentLoading(false);
                    return;
                }
                const res3 = await getUserTask(CONTAINER_ID, keycloak.token, res2.data['process-instance'][0]['process-instance-id']);
                if (res3.success) {
                    const task = res3.data["active-user-tasks"]["task-summary"].find((t) => t["task-name"] === "GetContentTask");
                    if (task) {
                        const taskID = task["task-id"];
                        if (task["task-status"] !== "InProgress") {
                            const res4 = await startUserTask(CONTAINER_ID, keycloak.token, taskID);
                        }
                        const data = {
                            "DocumentID": document['id'],
                        }
                        const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, taskID, data);
                    }
                }
                setGenerateDocumentLoading(false);
            }
        } catch (e) {
            setGenerateDocumentLoading(false);
            dispatch(toggleGenerateDocumentLoading(false));
            toast.error('Failed to generate file. Please try again.')
        }

    }

    const downloadDocument = useCallback((doc) => {

        const base64String = doc.fileContent;

        // Decode the base64 string into a byte array
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Convert the byte array into a Blob
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        props.onChangeImage({ doc, imgSrc: url });
    }, [props])

    useEffect(() => {
        if (selectedDocument && !selectedDocument.isDocumentGenerated) {
            let foundDocument = documentList.find((doc) => doc['id'] === selectedDocument['id']);
            if (foundDocument) {
                setSelectedDocument(foundDocument);
                if (foundDocument.isDocumentGenerated) {
                    downloadDocument(foundDocument);
                }
            }
        }
    }, [documentList, downloadDocument, selectedDocument])

    return (
        <>

            {
                imageURL ? <Box>
                    <Box
                        sx={{
                            background: "rgb(31, 33, 40)",
                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                            borderRadius: "8px",
                            marginBottom: "24px",
                        }}
                    >
                        <ImageZoomInOut
                            // image={URL.createObjectURL(imageURL)}
                            image={imageURL}
                            imageName={selectedDocument?.name || ''}
                        />
                    </Box>
                    <Button
                        $success
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        onClick={(e) => {
                            props.onChangeImage({ doc: null, imgSrc: null });
                            setSelectedDocument(null);
                        }}
                    >
                        Change Image
                    </Button>
                </Box> : <>
                    <Box sx={{ marginBottom: "12px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>Select Document</Typography>
                            <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => { regenerateCase() }}
                                loading={createNewCaseLoading}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                        <Divider sx={{ background: "rgba(81, 81, 81, 1)" }} />
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                disablePortal
                                id="document-selection-autocomplete"
                                getOptionLabel={(option) => option.name}
                                // options={documentList || []}
                                options={
                                    (documentList || [])
                                        .filter((d) => imageRegex.test(d.name))
                                        .sort((a, b) => -(b.title || '').localeCompare(a.title || ''))
                                }
                                onChange={(e, value) => {
                                    setSelectedDocument(value);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        {/* <FormControl>
                    <RadioGroup
                        name="document-radio-group"
                        value={selectedDocument?.id}
                        onChange={(e) => {
                            setSelectedDocument(documentList.find((i) => i.id === e.target.value));
                        }}
                    >
                        {
                            (documentList || [])
                                .filter((d) => imageRegex.test(d.name))
                                .map((document) => {
                                    return <FormControlLabel
                                        value={document.id}
                                        control={<Radio />}
                                        label={document.name}
                                    />
                                })
                        }
                        {
                            documentList.map((document) => {
                                return <img src={document.fileContent} alt="" />
                            })
                        }
                    </RadioGroup>
                </FormControl> */}
                    </Box>
                    <CustomLoadingButton
                        success
                        component="label"
                        variant="contained"
                        tabIndex={-1}
                        disabled={!selectedDocument || createNewCaseLoading}
                        loading={generateDocumentLoading}
                        onClick={(e) => {
                            e.preventDefault();
                            if (selectedDocument?.isDocumentGenerated) {
                                downloadDocument(selectedDocument);
                            } else {
                                generateDocument(selectedDocument);
                            }
                        }}
                    >
                        {selectedDocument?.isDocumentGenerated ? "Download file" : "Generate file"}
                    </CustomLoadingButton>
                </>
            }
        </>
    )

}

export default FileSelection;