import { Chip } from '@mui/material';
import React from 'react';

const getColorForRating = (rating) => {
  if (!rating) {
    return '#757575'; // Gray for unknown ratings or when the rating is not provided
  } else if (rating.includes('Low')) {
    return '#82b74b'; // Pastel green
  } else if (rating.includes('Medium')) {
    return '#fdd835'; // Pastel yellow
  } else if (rating.includes('High')) {
    return '#ffa726'; // Pastel orange
  } else if (rating.includes('Extreme')) {
    return '#ff5f5f'; // Pastel red
  } else {
    return '#757575'; // Gray for unknown ratings
  }
};

const RiskChip = ({ rating }) => {
  const chipStyle = {
    marginLeft: '20px',
    backgroundColor: getColorForRating(rating),
    color: '#000',
    width: "90px",
    textAlign: "center"
  };

  return (
    <Chip
      label={rating || ''}
      style={chipStyle}
    />
  );
};

export default RiskChip;
