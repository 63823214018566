// Register chart component globally
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const centerLabelPlugin = {
    id: 'centerLabelPlugin',
    beforeDraw: (chart, args, options) => {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        // const fontSize = (height / 14).toFixed(2); // Adjust font size as needed
        const fontSize = options.titleFontSize; // Adjust font size as needed

        // Set text content and positioning
        const text = options.title;
        const textX = Math.round(width / 2);
        const textY = height / 2;

        // Set text styles
        ctx.font = `${fontSize}px Rubik`;
        ctx.fillStyle = options.titleColor; // Adjust text color as needed
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Draw the text
        ctx.fillText(text, textX, textY);
    },
    defaults: {
        title: '',
        titleColor: '',
        titleFontSize: 14,
    }
}

ChartJS.register(centerLabelPlugin);