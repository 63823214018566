import styled from "styled-components";

// Function to toggle between light and dark themes
export const getTableTheme = (isDarkMode, { gridTemplateColumn, customBaseCell }) => {
    if (isDarkMode) {
        return {
            Table: `
                grid-template-columns: ${gridTemplateColumn};
                width: 100%;
                height: auto;
                color: #fff; // Set text color for dark theme
                font-size: 0.875rem;
                font-family: 'Rubik',sans-serif;
            `,
            HeaderRow: `
                height: 56px;
                min-height: 56px;
                max-height: 56px;
                line-height: 56px;
                border-color: rgba(81, 81, 81, 1);
                box-sizing: border-box;
                border-bottom: 1px solid;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                background: #000;
                color: #fff;
            `,
            HeaderCell: `
                background: #000;
                font-weight: 500;
                border-bottom: 1px solid;
                border-color: rgba(81, 81, 81, 1);
                font-size: 0.875rem;
            `,
            Row: `
                border-bottom: 1px solid;
                border-color: rgba(81, 81, 81, 1);
                
                &:not(:last-of-type)>.td {
                    border-bottom: inherit;
                }
            `,
            Cell: `
                border-bottom: 1px solid;
                border-color: rgba(81, 81, 81, 1);
                & > div {
                    white-space: normal;
                }
            `,
            BaseCell: `
                color: #fff;
                height: inherit;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: normal;
                overflow-wrap: break-word;
                background: #121212de;
                border-bottom: 1px solid;
                border-color: rgba(81, 81, 81, 1);
                font-size: 0.875rem;
                ${customBaseCell}
            `,
        };
    } else {
        return {
            Table: `
                grid-template-columns: ${gridTemplateColumn};
                width: 100%;
                height: auto;
                color: rgba(0, 0, 0, 0.87);
                font-size: 0.875rem;
                font-family: 'Rubik',sans-serif;
            `,
            HeaderRow: `
                height: 56px;
                min-height: 56px;
                max-height: 56px;
                line-height: 56px;
                border-color: rgba(224, 224, 224, 1);
                box-sizing: border-box;
                border-bottom: 1px solid;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                background: #ddd;
            `,
            HeaderCell: `
                font-weight: 500;
                border-bottom: 1px solid;
                border-color: rgba(224, 224, 224, 1);
                font-size: 0.875rem;
            `,
            Row: `
                border-bottom: 1px solid;
                border-color: rgba(224, 224, 224, 1);
                
                &:not(:last-of-type)>.td {
                    border-bottom: inherit;
                }
            `,
            Cell: `
                border-bottom: 1px solid;
                border-color: rgba(224, 224, 224, 1);
                white-space: normal;
                & > div {
                    white-space: normal;
                }
            `,
            BaseCell: `
                color: rgba(0, 0, 0, 0.87);
                height: inherit;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: normal;
                overflow-wrap: break-word;
                border-bottom: 1px solid;
                border-color: rgba(224, 224, 224, 1);
                font-size: 0.875rem;
                ${customBaseCell}
            `,
        };
    }
};


export const TableWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 4px;
    gap: 20px;
`;

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(81, 81, 81, 1);
    border-radius: 4px;
`;

export const TopBarWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    td {
        height: auto;
    }
`;


export const SearchContainer = styled.div`
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: 1px solid #7d7d7d;
    border-radius: 4px;
    transition: border-color 0.1s ease-in-out;
    &:focus-within {
        border-color: #FFF;
      }

    > input {
        background: transparent;
        width: 100%;
        height: 30px;
        border: none;
        outline: none;
        color: white;
    }
`; 
