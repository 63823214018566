const initialState = {
  data: null,
  loading: false,
  error: null,
  allowFetch: false, // Initialize as false
  updatedCaseID: null,
  lastPageFetched: { // Initialize last page fetched as 0, this is to track last fetched page number 
    open: 0,
    closed: 0
  },
  hasMore: { // Initialize as true, as this will help to call the api when more data is available
    open: true,
    closed: true
  },
  fetchingBackgroundData: true, // Initialize as true, as this will help to know if the background data is fetching
  fetchCounter: 0,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_PROJECT_ALLOW_FETCH':
      return { ...state, allowFetch: action.payload };
    case 'TOGGLE_PROJECT_UPDATE':
      return { ...state, updatedCaseID: action.payload };
    case 'FETCH_PROJECT_DATA_SUCCESS':
      return { ...state, data: action.payload.data, loading: false, error: null };
    case 'UPDATE_PROJECT_DATA':
      return { ...state, data: action.payload };
    case 'FETCH_PROJECT_DATA_ERROR':
      return { ...state, data: [], loading: false, error: action.payload };
    case 'PROJECT_PAGINATION_TRACKER':
      return { ...state, lastPageFetched: action.payload.lastPageFetched, hasMore: action.payload.hasMore };
    case 'TOGGLE_PROJECT_FETCHING_BACKGROUND_DATA':
      return { ...state, fetchingBackgroundData: action.payload };
    case 'INCREMENT_PROJECT_FETCH_COUNTER':
      return { ...state, fetchCounter: state.fetchCounter + 1 };
    default:
      return state;
  }
};

export default projectReducer;
