import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

import './MainLandingPage.css'
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CzWebsite from "./CzWebsite";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 1150,
            lg: 1670
        },
    },
    palette: {
        mode: 'dark',
    },
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const LandingPage = () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
            offset: 250,
        });
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <CzWebsite />
        </ThemeProvider>
    );
};

export default LandingPage;
