import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;

    margin-top: 64px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 0;
        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        right: 20px;
        bottom: 20px;

        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    }
`;