import React, { useContext, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
    ContentContainer,
    ImgContainer,
    ImgIcon,
    InfoContainer,
    InfoContent,
    InfoHeading,
    InfoParagraph,
    InfoText,
    InfoTextContainer,
    PersonImg,
    BtnsContainer,
    SettingsImg,
    StyledBackdrop,
    StyledOverlay,
    AvatarHeading,
    ImgsContainer,
    AvatarImage,
    OverlayBtns,
    StyledQuestion,
    AvatarCenter,
    StyledInput,
    StyledInputInfo,
    StyledTextInput,
    FormCenter,
    SettingsInput,
    StyledDropDown,
    StyledComplateContainer,
    ProgressCard
} from "./Settings.styled";

import imgSettings from "../../../../images/settings.png";
import personImg1 from "../../../../images/avatar-img.png";
import personImg2 from "../../../../images/avatar-img2.png";
import personImg3 from "../../../../images/avatar-img3.png";
import personImg4 from "../../../../images/avatar-img4.png";
import personImg5 from "../../../../images/avatar-img5.png";
import personImg6 from "../../../../images/avatar-img6.png";
import personImg7 from "../../../../images/avatar-img7.png";
import SubcriptionBadgeImage from "../../../../images/UserTypeSelection/SubscriptionBadge.png"
import AuthContext, { CONTAINER_ID, OrganisationalDetailsProcess, UserSelectionProcess, UserTypes, paymentUrlInstructor, paymentUrlMonthly, paymentUrlYearly } from "../../../../store/auth-context";
import { useHistory } from "react-router";
import Heading from "../../../../components/UI/Heading/Heading";
import { CenterHeadingContainer } from "../RiskMatrix/RiskMatrix.styled";
import { Button } from "../../../../components/UI/Button/Button.styled";
import { useKeycloak } from "@react-keycloak/web";
import OrganizationDetailsForm from "../../components/OrganizationDetailsForm/OrganizationDetailsForm";
import OrganisationDetailsModal from "../../components/OrganisationDetailsBlock/OrganisationDetailsBlock";
import { getCompletedOrganization, getProcessVariables } from "../../InformationAPI";
import WorkspacePremium from "@mui/icons-material/WorkspacePremium";
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography, styled } from "@mui/material";
import InfoModal from "../../components/InfoModal/InfoModal";
import ModalImageSrc from "../../../../images/reccomendationImg.png";
import { industries, sizes } from "../../../Auth/components/LandingPage/form/Registration";
import { Button as MuiButton } from "@mui/material";
import UserTypeCardSettings from "../../components/UserTypeCardSettings/UserTypeCardSettings";
import Subscription from "../../components/Subscription/Subscription";
import UserTypeGroup from "../../components/UserTypeGroup/UserTypeGroup";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close'
import InfoModalWithItem from "../../components/InfoModal/InfoModalWithItem";
import { toast } from "react-toastify";
import { retrieveData } from "../../../../shared/utility";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from 'react-redux';
import { Helmet } from "react-helmet-async";

const imagesSrc = [
    {
        id: "img1",
        imageSrc: personImg1,
    },
    {
        id: "img2",
        imageSrc: personImg2,
    },
    {
        id: "img3",
        imageSrc: personImg3,
    },
    {
        id: "img4",
        imageSrc: personImg4,
    },
    {
        id: "img5",
        imageSrc: personImg5,
    },
    {
        id: "img6",
        imageSrc: personImg6,
    },
    {
        id: "img7",
        imageSrc: personImg7,
    },
];

const BackDrop = ({ onHideOverlay }) => {
    return <StyledBackdrop onClick={onHideOverlay}></StyledBackdrop>;
};

const Overlay = ({
    onHideOverlay,
    onSelectImg,
    onChooseImg,
    selectedImg,
    showImages,
    showQuestion,
    showFormOverlayHandler,
}) => {
    const history = useHistory();
    const { logout } = useContext(AuthContext);

    const submitFormHandler = (e) => {
        e.preventDefault();
        logout();
        history.push("/");
    };

    if (showImages) {
        return (
            <StyledOverlay images>
                <AvatarHeading>Select Avatar </AvatarHeading>
                <ImgsContainer>
                    {imagesSrc.map((img) => (
                        <AvatarImage
                            key={img.id}
                            borderColor={
                                img.imageSrc === selectedImg && "greenBorder"
                            }
                            src={img.imageSrc}
                            onClick={() => onSelectImg(img.imageSrc)}
                        />
                    ))}
                </ImgsContainer>

                <OverlayBtns>
                    <Button $red onClick={onHideOverlay}>
                        Cancel
                    </Button>
                    <Button
                        $success
                        onClick={() => {
                            onChooseImg();
                            onHideOverlay();
                        }}
                    >
                        Select
                    </Button>
                </OverlayBtns>
            </StyledOverlay>
        );
    }

    if (showQuestion) {
        return (
            <StyledOverlay padding maxWidth>
                <AvatarCenter>
                    <StyledQuestion bigSize>
                        Are you sure you want to de-activate your account?
                    </StyledQuestion>
                </AvatarCenter>

                <OverlayBtns>
                    <Button $red onClick={onHideOverlay}>
                        No
                    </Button>
                    <Button $success onClick={showFormOverlayHandler}>
                        Yes
                    </Button>
                </OverlayBtns>
            </StyledOverlay>
        );
    }

    return (
        <StyledOverlay paddingHigh maxWidth>
            <StyledQuestion pb form="form">
                Why do you want to de-activate your account?
            </StyledQuestion>

            <form onSubmit={submitFormHandler}>
                <StyledInput
                    label="Reason"
                    required
                    variant="outlined"
                    focuscolor="#0070BF"
                    fullWidth
                />
                <StyledQuestion pb form="form">
                    To continue, please re-enter your credentials
                </StyledQuestion>
                <FormCenter>
                    <StyledInputInfo
                        label="Email"
                        variant="outlined"
                        focuscolor="#0070BF"
                    />
                    <StyledInputInfo
                        label="Password"
                        variant="outlined"
                        focuscolor="#0070BF"
                    />
                </FormCenter>
                <OverlayBtns mt>
                    <Button $red onClick={onHideOverlay} type="button">
                        Cancel
                    </Button>
                    <Button $success type="submit">
                        Confirm
                    </Button>
                </OverlayBtns>
            </form>
        </StyledOverlay>
    );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        backgroundImage: "",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
        borderRadius: "16px",
        border: "0"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Settings = () => {

    const dispatch = useDispatch();

    const [userInfo, setUserInfo] = useState(null);
    const { keycloak, initialized } = useKeycloak();

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [imgSrc, setImgSrc] = useState(personImg1);
    const [selectedImg, setSelectedImg] = useState(personImg1);
    const [settingsImg, setSettingsImg] = useState(imgSettings);
    const [organisationID, setOrganisationID] = useState(null);
    const [organisationDetail, setOrganisationDetail] = useState({ organisationalName: "", organisationSize: "", industryType: "", emailAddress: "", subscriptionType: "", serviceHours: 0, paymentType: "", subscriptionEndDate: "" });
    const [activateID, setActiveID] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    var isUpdated = false;
    const [isRefresh, setRefresh] = useState(false);
    const [oldData, setOldData] = useState(null);
    const [userType, setUserType] = useState("");
    const [userTypeInfo, setUserTypeInfo] = useState({});

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Settings',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const userTypeChange = (newType) => {
        setUserType(newType);
        updateUserType(newType);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showBackdropHandler = () => setShowBackdrop(true);
    const hideBackdropHandler = () => setShowBackdrop(false);

    const showImagesHandler = () => setShowImages(true);
    const hideImagesHandler = () => setShowImages(false);

    const showQuestionHandler = () => setShowQuestion(true);
    const hideQuestionHandler = () => setShowQuestion(false);

    const showFormHandler = () => setShowForm(true);
    const hideFormHandler = () => setShowForm(false);

    const showImageOverlay = () => {
        showBackdropHandler();
        showImagesHandler();
    };

    const showQuestionOverlay = () => {
        showBackdropHandler();
        showQuestionHandler();
    };

    const showFormOverlayHandler = () => {
        hideQuestionHandler();
        showFormHandler();
    };

    const hideOverlayHandler = () => {
        hideBackdropHandler();
        hideImagesHandler();
        hideQuestionHandler();
        hideFormHandler();
    };

    const chooseImgHandler = () => {
        setImgSrc(selectedImg);
    };

    const settingsImageHandler = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setSettingsImg(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setUserInfo(res);
                const _type = res["user_type"] || "novice";
                setUserType(_type);
            });
        } else {
            setUserInfo("novice");
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        const currentType = UserTypes.filter((e) => e.title == userType);
        if (currentType.length > 0) {
            setUserTypeInfo(currentType[0])
        }
    }, [userType])

    //get completed org id or create new org
    //get all data
    //submit data

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getAllInstance(userInfo?.sub, keycloak?.token);
    }, [userInfo, keycloak, CONTAINER_ID]);

    useEffect(() => {
        if (organisationID > 0) {
            //get variables
            getProcessVariables(organisationID, keycloak?.token).then((response) => {
                const orgData = {
                    "organisationalName": response.data.filter(obj => { return obj.name === "OrganisationName" })[0]?.value ?? "",
                    "organisationSize": response.data.filter(obj => { return obj.name === "OrganisationSize" })[0]?.value ?? "",
                    "industryType": response.data.filter(obj => { return obj.name === "IndustryType" })[0]?.value ?? "",
                    "emailAddress": response.data.filter(obj => { return obj.name === "EmailAddress" })[0]?.value ?? "",
                    "subscriptionType": response.data.filter(obj => { return obj.name === "SubscriptionType" })[0]?.value ?? "",
                    "serviceHours": response.data.filter(obj => { return obj.name === "ServiceHours" })[0]?.value * 1 ?? 0,
                    "totalDuration": response.data.filter(obj => { return obj.name === "TotalDuration" })[0]?.value * 1 ?? 0,
                    "paymentType": response.data.filter(obj => { return obj.name === "PaymentType" })[0]?.value ?? "",
                    "subscriptionEndDate": response.data.filter(obj => { return obj.name === "SubscriptionEndDate" })[0]?.value ?? "",
                };
                setOrganisationDetail(orgData);
            });
            return;
        } else {
            setOldData(organisationDetail);
        }
    }, [organisationID]);

    function getAllInstance(_email, _kcToken) {
        if (_email === null || _kcToken === null) {
            return;
        }

        getCompletedOrganization(_kcToken, _email).then((response) => {
            setOrganisationID(response.data);
        });
    }

    async function updateUserType(userType) {
        const data = {
            "userType": userType,
            "env": process.env.REACT_APP_DOMAIN
        }
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${UserSelectionProcess}/instances`,
                data: JSON.stringify(data),
                headers: { "Authorization": `Bearer ${keycloak?.token}`, 'Content-Type': 'application/json' },
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.status === 201) {
                    toast.success("User type updated!");
                } else {
                    toast.error("Failed to update user type!");
                }
            });

        } catch (error) {
            console.log(error.message);
        }
    };

    async function createNewOrganizationInstance() {
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${OrganisationalDetailsProcess}/instances`,
                data: JSON.stringify({}),
                headers: { "Authorization": `Bearer ${keycloak?.token}`, 'Content-Type': 'application/json' }
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.status === 201) {
                    const createdInst = res.data;
                    setActiveID(createdInst);
                    getOrganisationDetailsTask(createdInst);
                }
            });

        } catch (error) {
            console.log(error.message);
        }
    };

    async function getOrganisationDetailsTask(instanceID) {
        try {
            const header = { headers: { "Authorization": `Bearer ${keycloak?.token}` }, 'Content-Type': 'application/json' };
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${instanceID}`,
                ...header
            }
            fetchInstance.apiRequest(config).then((res) => {
                const taskInst = res.data["active-user-tasks"]["task-summary"][0]["task-id"];
                if (res.data["active-user-tasks"]["task-summary"][0]["task-status"] !== 'InProgress') {
                    let config2 = {
                        method: 'put',
                        url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInst}/states/started`,
                        data: JSON.stringify({}),
                        ...header
                    }
                    fetchInstance.apiRequest(config2).then(() => {
                        uploadOrganisationDetail(taskInst);
                    });
                }
            });

        } catch (error) {
            console.log(error.message);
        }
    };

    async function uploadOrganisationDetail(taskInstanceId) {
        try {
            const header = { headers: { "Authorization": `Bearer ${keycloak?.token}` }, 'Content-Type': 'application/json' };

            let config = {
                method: 'put',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
                data: JSON.stringify({
                    "organisationalName": organisationDetail.organisationalName ?? "",
                    "organisationSize": organisationDetail.organisationSize ?? "",
                    "industryType": organisationDetail.industryType ?? "",
                    "emailAddress": organisationDetail.emailAddress ?? "",
                    "subscriptionType": organisationDetail.subscriptionType ?? "",
                    "serviceHours": organisationDetail.serviceHours ?? 0,
                    "payment": organisationDetail.paymentType ?? "",
                    "subscriptionEndDate": organisationDetail.subscriptionEndDate ?? "",
                }),
                ...header,
            }
            fetchInstance.apiRequest(config).then((res) => {
                //success
                setRefresh(!isRefresh);
            });
        } catch (error) {
            console.log(error.message);
        }
    };

    function handleEditButtonClick() {
        if (isEdit && isUpdated) {
            submitOrganisationData();
        }
        setIsEdit(!isEdit);
    }

    function handleTextChange(e) {
        isUpdated = true;
        const org = organisationDetail;
        org[`${e.target.id}`] = e.target.value;
        setOrganisationDetail(org);
        // riskInformation[e.target.id].title = e.target.value;
    }

    function submitOrganisationData() {
        createNewOrganizationInstance();
        // const header = { headers: { "Authorization": `Bearer ${keycloak?.token}` }, 'Content-Type': 'application/json' };
        // try {
        //     let config = {
        //         method: 'post',
        //         url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${organisationID}/variables`,
        //         data: JSON.stringify(organisationDetail),
        //         ...header,
        //     }
        //     fetchInstance.apiRequest(config).then((res) => {
        //         console.log(res);
        //     });
        // } catch (err) {
        //     console.log(err.message);
        // }
    };

    function handleSelectChange(e) {
        isUpdated = true;
        const org = organisationDetail;
        const id = e.target.id;
        if (id === "size") {
            org['organisationSize'] = e.target.value;
        }
        if (id === "industry") {
            org['industryType'] = e.target.value;
        }

        setOrganisationDetail(org);
    }

    function handleCancelButtonClick(e) {
        setOrganisationDetail(oldData);
        setIsEdit(false);
    }

    function handlePopUp() {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>

                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>

                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        )
    }

    return (
        <>
            <Helmet>
                <title>Settings - CisoZen</title>
            </Helmet>
            <InfoModalWithItem open={open} handleClose={handleClose} content={<UserTypeGroup userType={userType} userTypeChange={userTypeChange} handleClose={handleClose}></UserTypeGroup>} />
            <CenterHeadingContainer>
                <Heading marginMd="34px 0 32px" marginSm="24px 0 20px">
                    Settings
                </Heading>
            </CenterHeadingContainer>
            {/* <InfoModalWithItem open={open} handleClose={handleClose} content={<UserTypeGroup></UserTypeGroup>}/> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={9}>
                    <ContentContainer>
                        <ImgContainer>
                            <SettingsImg src={settingsImg} alt="settings" />
                            <SettingsInput
                                type="file"
                                id="input"
                                accept="image/*"
                                onChange={settingsImageHandler}
                            />
                            <label htmlFor="input">
                                <ImgIcon />
                            </label>
                        </ImgContainer>

                        <InfoContainer>
                            <InfoContent>
                                <InfoHeading>Organization</InfoHeading>
                                <InfoTextContainer>
                                    <InfoText>Organization Name :</InfoText>

                                    {
                                        isEdit ? <StyledTextInput type="text"
                                            defaultValue={organisationDetail.organisationalName}
                                            id="organisationalName"
                                            maxLength='255' onChange={(event) => handleTextChange(event)} />
                                            : <InfoParagraph>{organisationDetail.organisationalName}</InfoParagraph>
                                    }
                                </InfoTextContainer>
                                <InfoTextContainer>
                                    <InfoText>Size :</InfoText>
                                    {
                                        isEdit ? <StyledDropDown id="size" defaultValue={organisationDetail.organisationSize} onChange={handleSelectChange}>
                                            <option value=""></option>
                                            {
                                                sizes.map((size) => {
                                                    return (
                                                        <option value={size.value} key={size.value}>{size.label}</option>
                                                    )
                                                })
                                            }
                                        </StyledDropDown>
                                            : <InfoParagraph>{organisationDetail.organisationSize}</InfoParagraph>
                                    }
                                </InfoTextContainer>
                                <InfoTextContainer>
                                    <InfoText>Industry :</InfoText>
                                    {
                                        isEdit ? <StyledDropDown id="industry" defaultValue={organisationDetail.industryType} onChange={handleSelectChange} style={{ maxWidth: "260px" }}>
                                            <option value=""></option>
                                            {
                                                industries.map((industry) => {
                                                    return (
                                                        <option value={industry.value} key={industry.value}>{industry.label}</option>
                                                    )
                                                })
                                            }
                                        </StyledDropDown>
                                            : <InfoParagraph>{organisationDetail.industryType}</InfoParagraph>
                                    }
                                </InfoTextContainer>
                                <InfoTextContainer>
                                    <InfoText>Email address :</InfoText>

                                    {
                                        isEdit ? <StyledTextInput type="text"
                                            defaultValue={organisationDetail.emailAddress}
                                            id="emailAddress"
                                            maxLength='255' onChange={(event) => handleTextChange(event)} />
                                            : <InfoParagraph>{organisationDetail.emailAddress}</InfoParagraph>
                                    }
                                </InfoTextContainer>
                                {/* <InfoTextContainer>
                            <InfoText>Established Since :</InfoText>
                            {
                                isEdit ? <StyledTextInput type="text"
                                    id="EstablishedDate"
                                    defaultValue={organisationDetail.EstablishedDate}
                                    maxLength='255' onChange={(event) => handleTextChange(event)} />
                                    : <InfoParagraph>{organisationDetail.EstablishedDate}</InfoParagraph>
                            }
                        </InfoTextContainer> */}
                                <InfoTextContainer style={{ paddingLeft: "77%" }}>
                                    {
                                        !isEdit ? <Button $success onClick={handleEditButtonClick}>EDIT</Button> : <Button $success onClick={handleEditButtonClick}>SAVE</Button>
                                    }
                                </InfoTextContainer>
                            </InfoContent>

                        </InfoContainer>
                    </ContentContainer>
                    <ContentContainer mt pb pl pt gap>
                        <ImgContainer>
                            <PersonImg src={imgSrc} alt="user image" />
                            {/* <Button $edit onClick={showImageOverlay} upper="true" $sm>
                        change avatar
                    </Button> */}
                        </ImgContainer>

                        <InfoContainer mt>
                            <InfoContent>
                                <InfoTextContainer></InfoTextContainer>
                                <InfoTextContainer>
                                    <InfoText sw>Name :</InfoText>
                                    <InfoParagraph>{userInfo?.name}</InfoParagraph>
                                </InfoTextContainer>
                                <InfoTextContainer>
                                    <InfoText sw>Email :</InfoText>
                                    <InfoParagraph>{userInfo?.email}</InfoParagraph>
                                </InfoTextContainer>
                                {/* <InfoTextContainer>
                            <InfoText sw>Password : </InfoText>
                            <InfoParagraph>***********</InfoParagraph>
                        </InfoTextContainer>
                        <InfoTextContainer>
                            <InfoText sw>Member Since :</InfoText>
                            <InfoParagraph>Dec. 2021</InfoParagraph>
                        </InfoTextContainer> */}
                            </InfoContent>
                        </InfoContainer>
                    </ContentContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <UserTypeCardSettings title={userTypeInfo["title"]}
                        description={userTypeInfo["desc"]}
                        imgSrc={userTypeInfo["icon"]} handleClickOpen={handleClickOpen} handleClose={handleClose} />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9}>
                    <ProgressCard>
                        <Typography variant="h6" textAlign="center">Usage History</Typography>
                        <Alert severity="info" style={{ marginTop: "30px" }}>Coming Soon!</Alert>

                    </ProgressCard>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <ProgressCard>
                        <Subscription
                            type={organisationDetail.subscriptionType}
                            validUntil={organisationDetail.subscriptionEndDate}
                            timeRemaining={organisationDetail.serviceHours}
                            imgSrc={SubcriptionBadgeImage}
                            email={userInfo?.email}
                        />
                    </ProgressCard>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ProgressCard>
                        <Typography variant="h6" textAlign="center">Access Request History</Typography>
                        <Alert severity="info" style={{ marginTop: "30px" }}>Coming Soon!</Alert>
                    </ProgressCard>
                </Grid>
            </Grid>

            <BtnsContainer>
                {/* <Button $deactBtn $red onClick={showQuestionOverlay}>
                    Deactivate Account
                </Button> */}
                {
                    //isEdit ? <Button $red onClick={handleCancelButtonClick}>Cancel</Button> : ""
                }
                {/* {
                    !isEdit ? <Button $edit onClick={handleEditButtonClick}>EDIT</Button> : <Button $success onClick={handleEditButtonClick}>SAVE</Button>
                } */}
            </BtnsContainer>
            {
                showBackdrop &&
                createPortal(
                    <BackDrop onHideOverlay={hideOverlayHandler} />,
                    document.getElementById("backdrop-root")
                )
            }
            {
                showImages &&
                createPortal(
                    <Overlay
                        showImages={showImages}
                        selectedImg={selectedImg}
                        onSelectImg={setSelectedImg}
                        onChooseImg={chooseImgHandler}
                        onHideOverlay={hideOverlayHandler}
                    />,
                    document.getElementById("overlay-root")
                )
            }
            {
                showForm &&
                createPortal(
                    <Overlay onHideOverlay={hideOverlayHandler} />,
                    document.getElementById("overlay-root")
                )
            }
            {
                showQuestion &&
                createPortal(
                    <Overlay
                        showQuestion={showQuestion}
                        onHideOverlay={hideOverlayHandler}
                        showFormOverlayHandler={showFormOverlayHandler}
                    />,
                    document.getElementById("overlay-root")
                )
            }
        </>
    );
};

export default Settings;
