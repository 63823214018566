import * as React from 'react';
import { styled } from '@mui/material/styles';

const DoubleArrowContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const Arrow = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  borderTop: `20px solid transparent`,
  borderBottom: `20px solid transparent`,
  borderLeft: `20px solid #4672c4`,
}));

const LeftArrow = styled(Arrow)(({ theme }) => ({
  borderLeft: '0',
  borderRight: `20px solid #4672c4`,
}));

const RightArrow = styled(Arrow)(({ theme }) => ({
  borderLeft: `20px solid #4672c4`,
}));

const Spacer = styled("div")(({ theme }) => ({
  width: '100%',
  minWidth: "24px",
  height: "20px",
  backgroundColor: "#4672c4",
}))

const DoubleArrow = () => {
  return (
    <DoubleArrowContainer>
      <LeftArrow />
      <Spacer /> {/* Empty spacer for visual separation */}
      <RightArrow />
    </DoubleArrowContainer>
  );
};

export default DoubleArrow;
