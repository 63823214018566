import styled, { css } from "styled-components";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField } from "@mui/material";

export const SubQuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 31px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 32px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

export const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin-left: 59px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
            margin-left: 50px;
        }
        @media screen and (max-width: 400px) {
            margin-left: 0;
        }
        & .MuiFormGroup-root {
            flex-direction: row;
            justify-content: flex-start;
            gap: 15px 50px;
            max-width: 90%;
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
                max-width: 100%;
            }

            ${({ $between }) =>
        $between &&
        css`
                    justify-content: space-between;
                    gap: 0px;
                `}
        }
    }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
    & .MuiTypography-root {
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : theme.palette.text.primary)};
    }
    & .MuiRadio-root {
        &.Mui-checked {
            color: #007dff;
        }
    }
`;


export const StyledTextInput = styled(TextField)`
    
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    width:50%;

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;

export const StyledNumberInput = styled.input`
    outline: none;
    border: none;
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};
    width:80px;

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;

export const StyledDropDown = styled.select`
outline: none;
    border: none;
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};
    width: 50%;

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;