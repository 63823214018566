import { useEffect, useState } from "react"
import { getCase, getProcessIDByCase, getProcessVariables, startMileStone, startNewCase } from "../TRA/TRAApi";
import { VulnerabilitiesProcess } from "../../../../store/auth-context";
import { updateProcessVariables } from "../../InformationAPI";
import { retrieveData } from "../../../../shared/utility";

const useGetWafResult = (CONTAINER_ID, jwtToken, userInfo) => {
    const [isLoading, setLoading] = useState(true);
    const [wafData, setData] = useState(null);
    const [started, setStarted] = useState(false);
    const [scanDate, setScanDate] = useState(null);

    const getActiveCase = () => {
        getCase(CONTAINER_ID, jwtToken, VulnerabilitiesProcess, "open").then((res) => {
            if (res.success) {
                const instances = res.data["instances"];
                if (instances) {
                    if (instances.length > 0) {
                        const result = instances[0]["case-file"]["case-data"]["wafResult"] ?? null;
                        const startedDate = instances[0]["case-started-at"];
                        const date = new Date(startedDate);
                        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                        const dateString = date.toLocaleDateString("en-AU", dateOptions);
                        setScanDate(dateString);
                        setData(result);
                        setLoading(false);
                    } else {
                        //start new case
                        if (started === false) {
                            startCase();
                            setStarted(true);
                            setLoading(false);
                        }
                    }
                }
            }

        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    const startCase = () => {
        const caseData = {
            "case-data": {
                "orgName": CONTAINER_ID.split('_')[0],
                "domain": process.env.REACT_APP_DOMAIN
            },
            "case-user-assignments": process.env.REACT_APP_DEPLOYMENT === "PROD" ? {
                "admin": "b8ca5ed8-92c4-4dac-8f21-6967753c49fe",
                "admin": "fac88bc1-c694-48fe-9a55-e832d47c9351",
                "admin": "f04be590-f010-4c7c-97e3-162498d6a238",
                "owner": userInfo.sub
            } : {
                "admin": "cc87ca86-a4e7-40bb-b239-7ad1c91a2ce6",
                "admin": "2101efe7-aa5f-4dd3-bb46-2a37590449ce",
                "owner": userInfo.sub
            },
            "case-group-assignments": {},
            "case-data-restrictions": {}
        };

        startNewCase(CONTAINER_ID, jwtToken, VulnerabilitiesProcess, caseData).then((res) => {
            if (res.success) {
                setStarted(true);
                let caseID = res.data;
                getProcessIDByCase(CONTAINER_ID, jwtToken, caseID).then((processRes) => {
                    if (processRes.data) {
                        const processInstances = processRes.data["process-instance"];
                        if (processInstances.length > 0) {
                            const processInstance = processInstances[0]["process-instance-id"] ?? 0

                            if (processInstance) {
                                updateProcessVariables(processInstance, jwtToken, {
                                    "orgName": CONTAINER_ID.split('_')[0],
                                    "env": userInfo.email.split('@')[1]
                                }).then((variableRes) => {

                                    startMileStone(CONTAINER_ID, jwtToken, caseID, "Start Token").then(() => {
                                        startMileStone(CONTAINER_ID, jwtToken, caseID, "Start Public").then(() => {
                                            startMileStone(CONTAINER_ID, jwtToken, caseID, "Start Pipeline").then(() => {
                                                getActiveCase();
                                            })
                                        })
                                    })

                                })
                            }
                        }
                    }

                }).catch((err) => {
                    setLoading(false);
                })
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        getActiveCase();
    }, [])

    return { isLoading, wafData, scanDate };
}

export default useGetWafResult;