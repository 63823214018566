import styled from "styled-components";
import { CardHeader } from '@mui/material';

export const CircleLoader = styled.div`
        position: absolute;
        top: calc(50% - 4em);
        left: calc(50% - 4em);
        width: 3rem;
        height: 3rem;
        border: .3rem solid #46a11b;
        border-left: .3rem solid #f2f7f0;
        border-radius: 50%;
        animation: load8 1.1s infinite linear;
        transform: translate(0) scale(1);
        opacity: 1;
        z-index: 999; 
        @keyframes load8 {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        
`;

export const CustomizedCardHeader = styled(CardHeader)`
    & .MuiTypography-root {
        background-color: grey;
        font-size:16px;
        line-height:1.5;
    }
`;

export const CustomizedCardContent = styled.div`
MuiCardContent-root:last-child {
  padding-bottom: 2px;
}
`;


export const LegendBox = styled.div`
background-color:transparent;
height: 200px;
color: white;
padding: 0px 0px 0px 19px;
border: 5px;
border-radius: 9px;
position: relative;
bottom:-125px;
`;

export const HorizontalRuler = styled.hr`
padding-left:-7px ;
`;
export const RedIndicator = styled.div`
background-color: red;
width: 25px;
height:25px;
border-radius: 50%;
border: none;

`;
export const YellowIndicator = styled.div`
background-color: yellow;
width: 25px;
height:25px;
border-radius: 50%;
border: none;
`;
export const ExtremeRisk = styled.div`
border-radius: 50%;
height: 25px;
width: 25px;
  background-color: red;
  border: 3px solid rgb(0 0 0 / 87%);
  box-shadow: 0 0 0 1px red;
`;
export const HighRisk = styled(ExtremeRisk)`
height: 20px;
width: 20px;
position: relative;
left: 2px;
top: 3px;
border: none;
`;
export const MediumRisk = styled(ExtremeRisk)`
height: 15px;
width: 15px;
position: relative;
left: 4px;
top: 5px;
border: none;
`;
export const LowRisk = styled(ExtremeRisk)`
height: 12px;
width: 12px;
position: relative;
left: 6px;
top: 7px;
border: none;
`;

export const MyTextColor = styled.p`
color: white;
text-align: center;
`;

export const Item = styled.div`
padding-top: 5px;
`;
export const Item2 = styled(Item)`
font-size: 12px;
margin-top:100;
`;

export const Domain = styled.div`
border: none;
border-radius: 50%;
height: 25px;
width: 25px;
background-color: green;
`;
