import { Button, Chip, Grid, Typography } from "@mui/material";
import { useState } from "react";

function ApplicableControlsCard({ controls }) {

  const [isHidden, setHidden] = useState(true);

  function handleControls(hidden) {
    setHidden(!hidden);
  }

  return (<Grid item xs={12} md={12} style={{ paddingTop: "16px" }}>
    {
      !isHidden && (
        <Typography variant="subtitle2" onClick={() => handleControls(isHidden)}>Applicable Controls </Typography>
      )
    }
    {
      isHidden && (
        <Button color="success" variant="outlined" style={{ textTransform: "capitalize" }} onClick={() => handleControls(isHidden)}>Show Applicable Controls..</Button>
      )
    }
    {!isHidden ? controls ? controls.length > 0 ? (
      <Grid container spacing={1}>
        {controls.map((control) => (
          <Grid item key={control.key}>
            <Chip label={`${control.controlID} : ${control.controlHeading}`} variant="outlined" />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography variant="body1">None</Typography>
    ) : "" : ""}
  </Grid>);
}

export default ApplicableControlsCard;