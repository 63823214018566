import { getCompletedRiskMatrix, getProcessVariables, getRiskMatrixTableData } from "../pages/Dashboard/InformationAPI";
import { cacheData, getCachedData } from './indexdb';

export const toggleAllowFetchRiskMatrix = (allow) => {
  return { type: 'RM_TOGGLE_ALLOW_FETCH', payload: allow };
};

export const toggleLoadingRiskMatrix = (loading) => {
  return { type: 'RM_TOGGLE_LOADING', payload: loading };
};

function fetchTabs(tabs) {
  return tabs
    .filter(function (obj) {
      return obj.name.startsWith("RiskCategory") || obj.name.startsWith("RiskCategoryRow");
    })
    .map(function (obj) {
      return { "name": obj.value };
    });
}

function fetchLevels(tabs) {
  return tabs
    .filter(function (obj) {
      return obj.name.startsWith("ConsequenceCategory");
    })
    .map(function (obj) {
      return obj.value;
    });
}

function fetchField(tabs) {
  return tabs
    .filter(function (obj) {
      return obj.name.startsWith("ConsCat");
    })
    .map(function (obj) {
      let str = obj.name;
      let catNum = str.substring(
        str.indexOf("t") + 1,
        str.lastIndexOf("R")
      );

      let rowNum = str.split('Row')[1]
      return { "name": `consCat${catNum}Row${rowNum}`, "desc": obj.value }
    });
}

export function getLikelihoodData(riskData) {
  let likelihoodData = riskData.filter(obj => { return obj.name.startsWith("Likelihood") })
  let formattedData = [
    {
      id: "i1",
      head: "Likelihood",
      title: "Description",
    },
  ]
  for (let i = 1; i <= (likelihoodData.length / 2); i++) {
    formattedData[i] = {
      id: `i${i + 1}`,
      head: likelihoodData.filter(obj => { return obj.name === `LikelihoodRow${i}` })[0].value ?? "",
      title: likelihoodData.filter(obj => { return obj.name === `LikelihoodContent${i}` })[0].value ?? "",
    };
  }

  return formattedData;
}

export function getActionData(riskData) {
  let fetchedLevels = riskData.filter(obj => { return obj.name.startsWith("RMrow") });
  let fetchedActions = riskData.filter(obj => { return obj.name.startsWith("RMaction") });
  let fetchedPlans = riskData.filter(obj => { return obj.name.startsWith("RMtreatment") });

  const levels = new Array(fetchedLevels.length);
  const actions = new Array(fetchedActions.length);
  const plans = new Array(fetchedPlans.length);
  for (let index = 0; index < levels.length; index++) {
    levels[index] = fetchedLevels.filter(obj => { return obj.name === `RMrow${index + 1}` })[0]?.value ?? "";
    actions[index] = fetchedActions.filter(obj => { return obj.name === `RMaction${index + 1}` })[0]?.value ?? "";
    plans[index] = fetchedPlans.filter(obj => { return obj.name === `RMtreatment${index + 1}` })[0]?.value ?? "";
  }

  return {
    levels: levels,
    actions: actions,
    plans: plans
  }
}

export function getCategory(riskData) {
  let fetchedTabs = fetchTabs(riskData);
  let fetchedLevels = fetchLevels(riskData);
  let fetchedFields = fetchField(riskData);
  return {
    tabs: fetchedTabs,
    levels: fetchedLevels,
    fields: fetchedFields
  };
}

export function getMatrixExists(riskData) {
  let data = riskData.find((obj) => obj.name === "Exist_Matrix");
  return data?.value || null;
}

export function getMatrixDimensions(riskData) {
  let data = riskData.find((obj) => obj.name === "Dimensions");
  return data?.value || null;
}

export const fetchRiskMatrixData = (keycloak, email, skipCache = false) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().riskMatrix; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-risk-matrix');
      const cachedRiskMatrixTableData = await getCachedData(keycloak.token, 'cached-risk-matrix-tableData');
      const cachedRiskMatrixCompletedRiskIDData = await getCachedData(keycloak.token, 'cached-risk-matrix-completedRiskID');

      if (!skipCache && cachedData && allowFetch === false) {
        let riskInformation = getLikelihoodData(cachedData);
        let riskActions = getActionData(cachedData);
        let riskConsequence = getCategory(cachedData);
        let riskMatrixExists = getMatrixExists(cachedData);
        let riskMatrixDimensions = getMatrixDimensions(cachedData);
        dispatch({
          type: 'RM_FETCH_DATA_SUCCESS',
          payload: {
            completedRiskID: cachedRiskMatrixCompletedRiskIDData,
            completedRiskMatrixData: cachedData,
            riskMatrixTableData: cachedRiskMatrixTableData,
            riskInformation: riskInformation,
            riskActions: riskActions,
            riskConsequence: riskConsequence,
            riskMatrixExists: riskMatrixExists,
            riskMatrixDimensions: riskMatrixDimensions,
          }
        });
      } else {

        // Enable loading
        dispatch(toggleLoadingRiskMatrix(true));

        let completedRiskIDResponse = await getCompletedRiskMatrix(keycloak.token, email);
        if (completedRiskIDResponse?.data) {
          let completedRiskID = completedRiskIDResponse.data;

          let completedRiskDataResponse = await getProcessVariables(completedRiskID, keycloak.token);
          let completedRiskData = completedRiskDataResponse.data;

          let riskInformation = getLikelihoodData(completedRiskData);
          let riskActions = getActionData(completedRiskData);
          let riskConsequence = getCategory(completedRiskData);
          let riskMatrixExists = getMatrixExists(completedRiskData);
          let riskMatrixDimensions = getMatrixDimensions(completedRiskData);

          let tableData;
          let tableDataResponse = await getRiskMatrixTableData(completedRiskID, keycloak.token);
          if (tableDataResponse?.success) {
            tableData = tableDataResponse.data;
          }

          // Dispatch the fetched data
          dispatch({
            type: 'RM_FETCH_DATA_SUCCESS',
            payload: {
              completedRiskID: completedRiskIDResponse.data,
              completedRiskMatrixData: completedRiskData,
              riskMatrixTableData: tableData,
              riskInformation: riskInformation,
              riskActions: riskActions,
              riskConsequence: riskConsequence,
              riskMatrixExists: riskMatrixExists,
              riskMatrixDimensions: riskMatrixDimensions,
            }
          });

          // Cache the fetched data
          cacheData(keycloak.token, 'cached-risk-matrix', completedRiskData);
          cacheData(keycloak.token, 'cached-risk-matrix-tableData', tableData);
          cacheData(keycloak.token, 'cached-risk-matrix-completedRiskID', completedRiskIDResponse.data);
          dispatch(toggleAllowFetchRiskMatrix(false));

        } else {
          dispatch(toggleLoadingRiskMatrix(false));
        }
      }
    } catch (error) {
      // console.error('DEBUG error = ', error)
      dispatch(toggleLoadingRiskMatrix(false));
      dispatch({ type: 'RM_FETCH_DATA_ERROR', payload: error.message });
    }
  };
};