import { useState } from "react";
import { FormControl, FormLabel, RadioGroup, Radio } from "@mui/material";
import { CustomFormControlLabel } from "../SubQuestion.styled";
import AppGuidance from "../components/AppGuidance";

const RadioQuestion = (props) => {

    const { isSub, value, readOnly } = props;

    //This is only used by dependencies in sub.
    const [toggle, setToggle] = useState(value?.main === "Yes");

    if (isSub) {
        const { sub, onSubChangeHandler } = props;

        const subInSub = sub.dependencies;

        const onRadioChangeHandler = (e) => {
            const ans = e.target.value;
            if (subInSub) {
                if (ans === "Yes") {
                    //prevent select yes again.
                    if (value.main === "Yes") {
                        return;
                    }
                    setToggle(true);
                    onSubChangeHandler(sub.qHeading, { main: ans, dependency: "" });
                } else {
                    setToggle(false);
                    onSubChangeHandler(sub.qHeading, { main: ans, dependency: undefined });
                }
            } else {
                onSubChangeHandler(sub.qHeading, ans);
            }
        }

        const onSubInSubChangeHandler = (e) => {
            onSubChangeHandler(sub.qHeading, { ...value, dependency: e.target.value });
        }

        return (
            <>
                <FormControl style={{ marginTop: "20px" }}>
                    <FormLabel required={sub.required}>
                        {/* {sub.qNum + ". " + sub.qHeading + " "} <AppGuidance guidance={sub.guide} /> */}
                        {`${sub.qNum}. ${sub.qHeading}`}
                    </FormLabel>
                    <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={value.main ? value.main : value} onChange={onRadioChangeHandler} disabled={readOnly}>
                        {
                            sub.qAvailableAnswers.map((answer, indexAns) => {
                                return <CustomFormControlLabel key={indexAns} value={answer} control={<Radio style={{ color: '#3a8d1c' }} />} label={answer} />;
                            })
                        }
                    </RadioGroup>
                </FormControl>

                {subInSub && toggle && (
                    subInSub.map((s, index) => (
                        <FormControl key={index} style={{ marginTop: "20px" }}>
                            <FormLabel required={s.required}>
                                {/* {s.qNum + "- " + s.qHeading + " "} <AppGuidance guidance={s.guide} /> */}
                                {`${s.qNum}. ${s.qHeading}`}
                            </FormLabel>
                            <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={value.dependency} onChange={onSubInSubChangeHandler} disabled={readOnly}>
                                {
                                    s.qAvailableAnswers.map((answer, indexAns) => {
                                        return <CustomFormControlLabel key={indexAns} value={answer} control={<Radio style={{ color: '#3a8d1c' }} />} label={answer} />;
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    ))
                )}
            </>
        );
    } else {
        const { question, parentIndex, index, handleAnswer } = props;

        const onRadioChangeHandler = (e) => {
            handleAnswer(e.target.value, parentIndex, index);
        }

        return (
            <FormControl>
                <FormLabel required={question.required}>
                    {/* {question.qNum + ". " + question.qHeading + " "} <AppGuidance guidance={question.guide} /> */}
                    {`${question.qNum}. ${question.qHeading}`}
                </FormLabel>
                <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={question.qAnswer ?? ""} onChange={onRadioChangeHandler} disabled={readOnly}>
                    {
                        question.qAvailableAnswers.map((answer, indexAns) => {
                            return <CustomFormControlLabel key={indexAns} value={answer} control={<Radio style={{ color: '#3a8d1c' }} />} label={answer} />;
                        })
                    }
                </RadioGroup>
            </FormControl>
        );
    }
}

export default RadioQuestion;