/* eslint-disable no-unused-vars */ // remove it after refactor
import { Box, CssBaseline } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../components/UI/Loader/Loader";
import { CONTAINER_ID, setContainerID } from "../../store/auth-context";
import Header from "./components/Header/Header";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import { ContentWrapper, MainContent } from "./Dashboard.styled";
import HealthCheck from "./nested/HealthCheck/HealthCheck";
import Help from "./nested/Help/Help";
import Information from "./nested/Information/Information";
import PdfTest from "./nested/PdfTest/PdfTest";
import Recommendations from "./nested/Recommendations/Recommendations";
import RiskMatrix from "./nested/RiskMatrix/RiskMatrix";
import Settings from "./nested/Settings/Settings";
import { Cookies } from "../../shared/utility";
import HealthCheckLandingPage from "./nested/HealthCheck/HealthCheckLandingPage";
import RiskAssessment from "./nested/RiskAssessment/RiskAssessment";
import RiskAssessmentLandingPage from "./nested/RiskAssessment/RiskAssessmentLandingPage";
import jwt_decode from "jwt-decode";
import MainDrawer from "./components/SideDrawer/MainDrawer";
import NotFound from "../../components/ErrorPage/NotFoundPage";
import MemoryIcon from '@mui/icons-material/Memory';
import PublicIcon from '@mui/icons-material/Public';
import DevicesIcon from '@mui/icons-material/Devices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SecurityIcon from '@mui/icons-material/Security';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import GppGoodIcon from '@mui/icons-material/GppGood';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Helmet } from "react-helmet-async";

var drawerWidth = 259;

function OTSecurity(props) {
    let { path } = useRouteMatch();

    const menuCategoryList = [
        {
            text: "Initial Risk Assessment",
            menuIconList: [
                {
                    icon: <PublicIcon />,
                    path: "/risks",
                    text: "Global & Company Risks",
                },
                {
                    icon: <DevicesIcon />,
                    path: "/device-inventory",
                    text: "Device Inventory"
                },
                {
                    icon: <MiscellaneousServicesIcon />,
                    path: "/scenarios",
                    text: "Scenarios",
                },
                {
                    icon: <SecurityIcon />,
                    path: "/security-level",
                    text: "Security Level",
                },
                {
                    icon: <BorderStyleIcon />,
                    path: "/zone-model",
                    text: "Zone Model",
                },
                {
                    icon: <GppGoodIcon />,
                    path: "/incident-response",
                    text: "Incident Response",
                },
            ]
        },
        {
            text: "High Level Risk Assessment",
            menuIconList: [
                {
                    icon: <AutoGraphIcon />,
                    path: "/threat-likelihood",
                    text: "Threat Likelihood",
                },
                {
                    icon: <AnalyticsIcon />,
                    path: "/threat-assessment",
                    text: "Threat Assessment"
                },
                {
                    icon: <GppGoodIcon />,
                    path: "/countermeasures-zone",
                    text: "Countermeasures",
                },
                {
                    icon: <TrendingDownIcon />,
                    path: "/revised-zone-model",
                    text: "Revised Zone Model",
                },
                {
                    icon: <MemoryIcon />,
                    path: "/residual-risk",
                    text: "Residual Risk",
                },
            ]
        },
        {
            text: "Detailed Level Risk Assessment",
            menuIconList: [
                {
                    icon: <MemoryIcon />,
                    path: "/zone-risks",
                    text: "Zone Risks",
                },
                {
                    icon: <MemoryIcon />,
                    path: "/conduit-risks",
                    text: "Conduit Risks"
                },
                {
                    icon: <MemoryIcon />,
                    path: "/countermeasures",
                    text: "Countermeasures",
                },
                {
                    icon: <MemoryIcon />,
                    path: "/final-zone-model",
                    text: "Final Zone Model",
                },
            ]
        },
    ];
    const { keycloak, initialized } = useKeycloak();
    const kcToken = keycloak?.token ?? '';

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isShrink, setIsShrink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [containerName, setContainerName] = useState(null);
    const [signal, setSignal] = useState(0);

    const location = useLocation()

    const history = useHistory();

    useEffect(() => {
        if (keycloak) {
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(50).then((refreshed) => {
                    if (refreshed) {
                        setSignal(keycloak.tokenParsed.exp);
                    } else {
                        // console.log('not refreshed ' + new Date());
                    }
                })
            }
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };

    }, [keycloak.authenticated]);

    useEffect(() => {
        if (!keycloak.authenticated) {
            // history.replace("/")
        } else {
            try {
                var decoded = jwt_decode(kcToken);
                var resource = decoded["resource_access"];
                let userName = decoded["name"] ?? "";
                setUsername(userName);
                // var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
                // const orgName = clientRoles.filter(role => role !== "cz-users")[0];
                // setContainerID(orgName + "_0.1");
                // setContainerName(orgName + "_0.1");
            } catch (error) {
                console.log(error);
            }
        }
    }, [history, keycloak.authenticated, location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleShrinkToggle = () => {
        if (!isShrink) {
            drawerWidth = 75
        } else {
            drawerWidth = 259
        }
        setIsShrink(!isShrink);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    let content;

    if (loading) {
        content = <Loader height="90vh" />;
    }

    let targetList = {
        "risks": "targeted end of July 2024",
        "device-inventory": "targeted end of August 2024"
    };



    content = (
        <MainContent>
            <Switch>
                <Route path={`${path}/risks`} exact>
                    <NotFound info="Targeted end of  July 2024" />
                </Route>
                <Route path={`${path}/device-inventory`} exact>
                    <NotFound info="Targeted end of  August 2024" />
                </Route>
                <Route path={`${path}/scenarios`} exact>
                    <NotFound info="Targeted end of  September 2024" />
                </Route>
                <Route path={`${path}/security-level`} exact>
                    <NotFound info="Targeted end of  September 2024" />
                </Route>
                <Route path={`${path}/zone-model`} exact>
                    <NotFound info="Targeted end of  October 2024" />
                </Route>
                <Route path={`${path}/incident-response`} exact>
                    <NotFound info="Targeted end of  October 2024" />
                </Route>
                <Route path={`${path}/threat-likelihood`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/threat-assessment`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/countermeasures-zone`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/revised-zone-model`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/residual-risk`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/zone-risk`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/conduit-risks`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/countermeasures`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route path={`${path}/final-zone-model`} exact>
                    <NotFound info="Targeted end of  November 2024" />
                </Route>
                <Route>
                    <NotFound info="Targeted end of  July 2024" />
                </Route>
            </Switch>
        </MainContent>
    );

    return (
        <>
            <Helmet>
                <title>OT Security - CisoZen</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <SideDrawer menuCategoryList={menuCategoryList} container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerShrink={handleShrinkToggle} drawerWidth={drawerWidth} />
                </Box>
                <ContentWrapper drawerWidth={drawerWidth}>
                    <Header username={username} email={email} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
                    {content}
                </ContentWrapper>
            </Box>
        </>
    );
}

export default OTSecurity;

