import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiLoadingButton from '@mui/lab/LoadingButton';

import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import Table from '../../../../../components/UI/Table/Table';

import { AiOutlineSync, AiOutlineDownload } from "react-icons/ai";
import RHCDownloadReportButton from './RHCDownloadReportButton';
import { REPORT_TYPES_OPTIONS, ReportTypes } from '../../../../../shared/constants';
import { generateTRAReport } from '../../../../../utility/tra.report.utils';
import RiskMatrixDownloadReportButton from './RiskMatrixDownloadReportButton';

const ReportsTable = (props) => {

    // ** Hooks
    const history = useHistory();
    const { data: risks } = useSelector((state) => state.data);
    const { data: threats } = useSelector((state) => state.threats);

    // ** Props
    const { email, kcToken, domainControlMapper } = props;

    // ** States
    const [selectedType, setSelectedType] = useState(null);
    const [listData, setListData] = useState([]);

    useEffect(() => {
        if (selectedType) {
            setListData(
                (props.data || [])
                    .filter((item) => item.type === selectedType)
                    .map((item) => ({ ...item }))
            )
        } else {
            setListData((props.data || []).map((item) => ({ ...item })));
        }
    }, [props.data, selectedType])

    useEffect(() => {
        if (history?.location?.state?.type) {
            setSelectedType(history.location.state.type);
        }
    }, [history?.location?.state])

    const handleDownloadTRAReport = useCallback((e, traData) => {
        e?.preventDefault();
        const threatsData = threats.filter((t) => t["traID"] === traData["caseID"]);
        let risksData = risks?.filter(
            (r) => (
                (r.caseID === traData['aRiskID'])
                || (r.caseID === traData['cRiskID'])
                || (r.caseID === traData['iRiskID'])
            )
        )
        generateTRAReport(traData, risksData, threatsData, domainControlMapper)
    }, [threats, risks, domainControlMapper])

    const handleTypeChange = (e) => {
        setSelectedType(e?.target?.value)
    }

    const columns = [
        {
            id: "datetime",
            header: "Date",
            sort: true,
            width: '150px',
            sortFn: (a, b) => {
                const dateA = new Date(a.datetime);
                const dateB = new Date(b.datetime);

                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                return dayjs(row.datetime).format('DD/MM/YYYY')
            }
        },
        {
            flex: 0.7,
            id: "title",
            header: "Title",
            minWidth: "250px",
            searchable: true,
        },
        {
            flex: 0.3,
            id: "type",
            header: "Type",
            minWidth: "120px",
            searchable: true,
        },
        {
            id: "actions",
            header: "",
            disableColumnFilter: true,
            pinRight: true,
            width: "60px",
            renderCell: ({ row }) => {
                if (row.type === ReportTypes.HEALTH_CHECK) {
                    return <RHCDownloadReportButton key={row.id} date={dayjs(row.datetime).format('DD/MM/YYYY')} email={email} kcToken={kcToken} data={row} historyData={row.historyData || []}>
                        {({ onClick, loading }) => {
                            if (loading) {
                                return <MuiLoadingButton as={IconButton} loading>
                                    <AiOutlineDownload />
                                </MuiLoadingButton>
                            }
                            return <MuiLoadingButton
                                as={IconButton}
                                onClick={onClick}
                                title='Download'
                            >
                                <AiOutlineDownload color={'#46a11b'} />
                            </MuiLoadingButton>
                        }}
                    </RHCDownloadReportButton>
                } else if (row.type === ReportTypes.RISK_MATRIX) {
                    return <RiskMatrixDownloadReportButton key={row.id} date={dayjs(row.datetime).format('DD/MM/YYYY')} data={row} kcToken={kcToken} email={email} riskActions={row?.riskActions} riskInformation={row?.riskInformation} riskConsequence={row?.riskConsequence} tableData={row?.riskMatrixTableData}>
                        {({ onClick, loading }) => {
                            if (loading) {
                                return <MuiLoadingButton as={IconButton} loading>
                                    <AiOutlineDownload />
                                </MuiLoadingButton>
                            }
                            return <MuiLoadingButton
                                as={IconButton}
                                onClick={onClick}
                                title='Download'
                            >
                                <AiOutlineDownload color={'#46a11b'} />
                            </MuiLoadingButton>
                        }}
                    </RiskMatrixDownloadReportButton>
                } else if (row.type === ReportTypes.TRA) {
                    return <MuiLoadingButton
                        as={IconButton}
                        onClick={(e) => handleDownloadTRAReport(e, row)}
                        title='Download'
                    >
                        <AiOutlineDownload color={'#46a11b'} />
                    </MuiLoadingButton>
                }

                return '';
            }
        },
    ];

    return (
        <DomainCard>
            <Grid container spacing={2} sx={{ marginBottom: '24px' }}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h5">
                        Reports List
                    </Typography>
                </Grid>
            </Grid>
            <Table
                skeletonLoader
                loading={props.loading}
                tableData={listData}
                columns={columns}
                tableTitle={
                    <>
                        <FormControl fullWidth sx={{ maxWidth: '275px' }}>
                            <InputLabel shrink id="select-type-label">Report Type</InputLabel>
                            <Select
                                notched
                                size="small"
                                labelId="select-type-label"
                                id="select-type"
                                value={selectedType}
                                label="Report Type"
                                onChange={handleTypeChange}
                            >
                                {
                                    REPORT_TYPES_OPTIONS.map((option, i) => (
                                        <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
                actionButtons={
                    <>
                        <Box>
                            {/* <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => { props.regenerateCase() }}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton> */}
                        </Box>
                    </>
                }
            />
        </DomainCard>
    );
};

export default ReportsTable;