import styled, { css } from "styled-components";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField, {
    shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
    "& label.Mui-focused": {
        color: p.focuscolor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: p.focuscolor,
        },
    },
    input: {
        border: "none",
        boxSizing: "border-box",
        padding: "0 18px",
        backgroundColor: "inherit",
        borderRadius: "4px",
        height: "48px",
        color: `${({ theme }) =>
            theme.palette.mode === "light" ? "#4F4F4F" : "#fff"}`,
    },
    div: {
        width: "100%",
        marginBottom: "24px",
        "@media screen and (max-width: 599.98px)": {
            width: "100%",
        },
        "@media screen and (max-width: 320px)": {
            width: "100%",
        },
    },

    label: {
        fontSize: "16px",
        lineHeight: "19px",
    },
}));

export const DescriptionContent = styled.div`
    box-sizing: border-box;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    max-width: ${({ bgWidth }) => (bgWidth ? "990px" : "660px")};
    max-width: ${({ actionWidth }) => (actionWidth ? "875px" : "")};
    box-sizing: border-box;
`;

export const StyledList = styled.ul`
    overflow: ${({ overflow }) => (overflow ? "auto" : "")};
    margin-top: ${({ mZero }) => (mZero ? "0px" : "38px")};
    padding: ${({ actionPad }) => (actionPad ? "0 51px 16px 51px" : "0")};

    @media (max-width: 599.98px) {
        margin-top: ${({ mZero }) => (mZero ? "0px" : "16px")};
        padding: ${({ actionPad }) => (actionPad ? "0 5px 16px 5px" : "0")};
    }

    li {
        &:not(:first-of-type) {
            p {
                max-height: ${({ maxheight }) => (maxheight ? "52px" : "")};

                @media (max-width: 599.98px) {
                    max-height: ${({ maxheight }) => (maxheight ? "none" : "")};
                }
            }
        }
    }

    li:first-of-type {
        p {
            font-weight: 500;
            font-size: ${({ smsize }) => (smsize ? "14px" : "17px")};
        }
    }
`;

export const DescriptionTextHead = styled.p`
    background: red;
    padding: 14px 16px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
    width: ${({ smWidth }) => (smWidth ? " 154px" : "163px")};
    width: ${({ acSmWidth }) => (acSmWidth ? "133px" : "")};
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: ${({ bigfont }) => (bigfont ? 500 : 400)};
    font-size: ${({ bigfont }) => (bigfont ? "17px" : "14px")};
    align-self: stretch;
    @media (max-width: 599.98px) {
        min-width: 140px;
        width: ${({ acSmWidth }) => (acSmWidth ? " 82px" : "")};
        padding: ${({ acSmWidth }) => (acSmWidth ? "16px 0px 16px 8px" : "")};
        max-width: 140px;
    }

    ${({ $action }) =>
        $action &&
        css`
            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 120px;
                min-width: 120px;
            }
        `}
`;

export const DescriptionTextParagraph = styled.p`
    // max-width: ${({ mdWidth }) => (mdWidth ? "836px" : "497px")};
    // max-width: ${({ acMdWidth }) => (acMdWidth ? "436px" : "")};
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};
    & .MuiFormControl-root {
        flex-grow: 1;
    }
    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;

export const DescriptionTextP = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 306px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 599.98px) {
        padding: 16px 10px 16px 10px;
        flex: 0;
    }
`;

export const GraphContainer = styled.div`
    & canvas {
        width: 40%;
        height: 300px;
    }
    @media (max-width: 599.98px) {
        & canvas {
            width: 100% !important;
            height: 300px;
        }
    }
`;

export const Wrapper = styled.div`
    text-align: center;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;

    margin-top: 64px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 0;
        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        right: 20px;
        bottom: 20px;

        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    }
`;

export const StyledComplateContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;
