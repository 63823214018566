import styled from "styled-components";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const CustomModal = styled(Modal)`
    backdrop-filter: blur(8px);
`;

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 630px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        width: 85%;
    }
`;

export const CustomWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        width: 85%;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg - 1}px) {
        width: 85%;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        width: 100%;
        top:80%;
        position: absolute;
    }
`;

export const VectorWrapper = styled.div`
    position: relative;
    margin: 0 80px;
    height: 206px;
    background: #46a11b;
    border-radius: 16px;
    margin-top: ${(props) => `-${props.elevate}px`};
    transform: ${(props) => `translateY(${props.elevate}px)`};
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin: 0 40px;
    }
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: ${(props) => `${props.elevate}px`};
        background: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    }
`;
export const VectorImage = styled.img`
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
    height: ${(props) => (props.height ? props.height : "188px")};
    transform: ${(props) =>
        props.translateY ? `translateY(${props.translateY})` : "translateY(0)"};
`;

export const TextBox = styled.div`
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    border-radius: 16px;
    margin: auto;
    padding: 47px 15px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 47px 25px;
        width: 100%;
    }
`;

export const ModalHeading = styled.h4`
    font-weight: 300;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: 26px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const InfoList = styled.ul`
    margin-left: 40px;
    padding: 0;
    list-style: disc;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-left: 20px;
    }
    li {
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        letter-spacing: 0.15px;
        color: ${({ theme }) => theme.palette.text.primary};
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: -60px;
    right: 0;
    cursor: pointer;

    &.MuiSvgIcon-root {
        font-size: 40px;
        color: #fff;
    }	

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        top: -30px;

        &.MuiSvgIcon-root {
        font-size: 35px;
    }
    }
`
