import Question from "../../../../components/Question/Question";
import { QuestionCard } from "./QuestionsStepper.styled";

function QuestionsStepper(props) {
    const { questions, handleAnswer, onClick, readOnly, activeNumber} = props;
    let renderedQuestions = questions.map((question, index) => {
        if(index !== activeNumber-1) return;
        return <Question readOnly={readOnly} helpText={question.helpText} onClick={onClick} key={question.qId} question={question} index={index} handleAnswer={handleAnswer} ans1Index={question.ans1Index} />;
    });

    return <QuestionCard>{renderedQuestions}</QuestionCard>;
}

export default QuestionsStepper;
