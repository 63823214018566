import { useContext } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapperRadar, StatsText, TextBlock } from '../Information.styled';
import ThemeContext from '../../../../../store/theme-context';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


function OrganisationMaturityCard({ data }) {

    const { theme } = useContext(ThemeContext);

    const average = Math.floor(data.reduce((a, b) => a + b, 0) / data.length) ?? 0;


    const datasets = [
        {
            label: `Current Maturity (${average})`,
            data: data,
            backgroundColor: 'rgba(70, 161, 27, 0.5)',
            borderColor: 'rgba(70, 161, 27, 1)',
            borderWidth: 2,
        },
        {
            label: 'Recommended Maturity (3)',
            data: [3, 3, 3, 3, 3],
            backgroundColor: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(89, 89, 89, 0.5)',
            borderColor: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(89, 89, 89, 1)',
            borderWidth: 2,
        },
    ];

    const graphData = {
        labels: ['Identify', 'Protect', 'Detect', 'Respond', 'Recover'],
        datasets: datasets
    }

    let options = {
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: 5,
                angleLines: {
                    color: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(105, 105, 105, 0.5)',
                    lineWidth: 1,
                },
                grid: {
                    color: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(105, 105, 105, 0.5)',
                    lineWidth: 1,
                },
                ticks: {
                    display: false,
                    stepSize: 1,
                },
                pointLabels: {
                    color: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(105, 105, 105, 1)',
                    font: {
                        weight: 'bold',
                        size: 14,
                    },
                },
            },
        },
    }

    return (
        <ProgressCard id="currentmaturity">
            <ProgressCardContent>
                <ProgressCardContentTop>
                    <CardHead>Organisation Maturity</CardHead>
                    <ProgressContainer>
                        <ProgressWrapperRadar>
                            <Radar data={graphData} options={options} />
                        </ProgressWrapperRadar>
                    </ProgressContainer>
                    <LegendContainer>
                        {
                            datasets.map((d, index) => (
                                <TextBlock key={d.label + index}>
                                    <DotSign style={{ backgroundColor: d.borderColor }} />
                                    <StatsText>{d.label}</StatsText>
                                </TextBlock>
                            ))
                        }
                    </LegendContainer>
                </ProgressCardContentTop>
            </ProgressCardContent>
        </ProgressCard>
    );
}
export default OrganisationMaturityCard;   