
import {
  LegendBox,
  YellowIndicator,
  ExtremeRisk,
  HighRisk,
  MediumRisk,
  LowRisk,
  Item,
  Item2
} from "./Graph.styled";
import Grid from '@mui/material/Grid';
const LegendPage = ({ ...props }) => {


  return (
      <LegendBox>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* <Grid md={12}><MyTextColor><Item2>Legend</Item2></MyTextColor></Grid> */}
          <Grid item sm={3} md={3}>
            <Item><YellowIndicator /></Item>
          </Grid>
          <Grid item sm={9} md={9}>
            <Item2><p style={{ paddingTop: 2 }}>Mitigation</p></Item2>
          </Grid>
          <Grid item sm={3} md={3}>
            <Item><ExtremeRisk /></Item>
          </Grid>
          <Grid item sm={9} md={9}>
            <Item2><p style={{ paddingTop: 5 }}>Extreme Risk : {props.extreme}</p></Item2>
          </Grid>
          <Grid item sm={3} md={3}>
            <Item><HighRisk /></Item>
          </Grid>
          <Grid item sm={9} md={9}>
            <Item2><p style={{ paddingTop: 5 }}>High Risk : {props.high}</p></Item2>
          </Grid>
          <Grid item sm={3} md={3}>
            <Item><MediumRisk /></Item>
          </Grid>
          <Grid item sm={9} md={9}>
            <Item2><p style={{ paddingTop: 5 }}>Medium Risk : {props.medium}</p></Item2>
          </Grid>
          <Grid item sm={3} md={3}>
            <Item><LowRisk /></Item>
          </Grid>
          <Grid item sm={9} md={9}>
            <Item2><p style={{ paddingTop: 5 }}>Low Risk : {props.low}</p></Item2>
          </Grid>
        </Grid>
      </LegendBox>
  );
};

export default LegendPage;