import React, { useContext, useState } from 'react';
import {
    Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Box, IconButton, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { DomainCard } from './ApplicableControl.styled';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ThemeContext from '../../../../../../store/theme-context';

const RiskReductionTable = (props) => {
    const {tableData, setTableData, handleUpdate} = props;
    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext.theme.palette.mode === "dark" ? true : false;
    const [editing, setEditing] = useState(false);

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleSaveClick = () => {
        setEditing(false);
        handleUpdate();
    };

    const handleCellValueChange = (index, column, value) => {
        const newData = [...tableData];
        newData[index][column] = value;
        setTableData(newData);
    };

    return (
        <DomainCard style={{ padding: "20px" }}>
            <Grid container spacing={2}>
                <Grid item xs={9} md={9}>
                    <Typography variant='h5'>Risk Reduction Table</Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                    {/* <Box display="flex" justifyContent="flex-end" marginBottom="10px" >
                        {editing ? (
                            <IconButton
                                color="success"
                                onClick={handleSaveClick}
                            >
                                <DoneIcon style={{ color: isDarkTheme ? "#fff" : "#000" }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="primary"
                                onClick={handleEditClick}
                            >
                                <EditIcon style={{ color: isDarkTheme ? "#fff" : "#000" }} />
                            </IconButton>
                        )}
                    </Box> */}
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table style={{ width: '100%' }}>
                            <TableHead>
                                <TableRow style={{ background: isDarkTheme ? "#000" : "#ddd" }}>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        Control Maturity
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        Max Likelihood Reduction
                                    </TableCell>
                                    <TableCell align="center" style={{ border: '1px solid black' }}>
                                        Max Consequence Reduction
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" style={{ border: '1px solid black' }}>
                                            <span
                                                style={{
                                                    color: row.ControlMaturity >= 3 ? 'green' : 'red',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {row.ControlMaturity}
                                            </span>
                                        </TableCell>
                                        <TableCell align="center" style={{ border: '1px solid black' }}>
                                            {editing ? (
                                                <FormControl fullWidth>
                                                    <Select
                                                        variant="outlined"
                                                        size="small"
                                                        value={row.MaxLikelihoodReduction}
                                                        onChange={(e) =>
                                                            handleCellValueChange(index, 'MaxLikelihoodReduction', e.target.value)
                                                        }
                                                    >
                                                        {[0, -1, -2, -3, -4, -5].map((value) => (
                                                            <MenuItem key={value} value={value}>
                                                                {value === 0 ? 'N/A' : value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (

                                                row.MaxLikelihoodReduction === 0 ? 'N/A' : row.MaxLikelihoodReduction
                                            )}
                                        </TableCell>
                                        <TableCell align="center" style={{ border: '1px solid black' }}>
                                            {editing ? (
                                                <FormControl fullWidth>
                                                    <Select
                                                        variant="outlined"
                                                        size="small"
                                                        value={row.MaxConsequenceReduction}
                                                        onChange={(e) =>
                                                            handleCellValueChange(index, 'MaxConsequenceReduction', e.target.value)
                                                        }
                                                    >
                                                        {[0, -1, -2, -3, -4, -5].map((value) => (
                                                            <MenuItem key={value} value={value}>
                                                                {value === 0 ? 'N/A' : value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                row.MaxConsequenceReduction === 0 ? 'N/A' : row.MaxConsequenceReduction

                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </DomainCard>
    );
};

export default RiskReductionTable;
