import { createContext, useCallback, useState } from "react";

const RiskContext = createContext({
    step: 0,
    question1: null,
    question2: null,
    informationValue: null,
    confirm: null,
    info3ChangeHandler: () => {},
    info4ChangeHandler: () => {},
    info5ChangeHandler: () => {},
    hideInfoHandler: () => {},
    nextStep: () => {},
    backStep: () => {},
    question1ChangeHandler: () => {},
    question2ChangeHandler: () => {},
    showConfirmHandler: () => {},
    hideConfirmHandler: () => {},
    reset: () => {},
    navigateStepHandler: () => {},
});

export const RiskContextProvider = (props) => {
    const [infoValue, setInfoValue] = useState(null);
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const [step, setStep] = useState(0);
    const [confirm, setConfirm] = useState(false);

    const question1ChangeHandler = (event) => {
        setQuestion1(event.target.value);
    };

    const question2ChangeHandler = (event) => {
        setQuestion2(event.target.value);
    };

    const info3ChangeHandler = () => {
        setInfoValue(3);
    };

    const info4ChangeHandler = () => {
        setInfoValue(4);
    };

    const info5ChangeHandler = () => {
        setInfoValue(5);
    };

    const hideInfoHandler = () => {
        setInfoValue(null);
    };

    const showConfirmHandler = () => {
        setConfirm(true);
    };

    const hideConfirmHandler = () => {
        setConfirm(false);
    };

    const nextStepHandler = () => {
        if (step === 5) return;
        setStep((prevStep) => prevStep + 1);
    };

    const backStepHandler = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const navigateStepHandler = useCallback((step) => {
        setStep(step);
    }, [])

    const reset = () => {
        setConfirm(false);
        setStep(0);
        setQuestion1(null);
        setQuestion2(null);
        setInfoValue(null);
    };

    const value = {
        confirm,
        step,
        question1,
        question2,
        nextStep: nextStepHandler,
        backStep: backStepHandler,
        question1ChangeHandler,
        question2ChangeHandler,
        informationValue: infoValue,
        info3ChangeHandler,
        info4ChangeHandler,
        info5ChangeHandler,
        showConfirmHandler,
        hideConfirmHandler,
        hideInfoHandler,
        reset,
        navigateStepHandler,
    };

    return (
        <RiskContext.Provider value={value}>
            {props.children}
        </RiskContext.Provider>
    );
};

export default RiskContext;
