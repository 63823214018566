export const Table3Data = [
    {
        number: "Rare",
        insignificant: "Low",
        Moderate: "Low",
        catastrophic: "High",
    },
    {
        number: "Possible",
        insignificant: "Low",
        Moderate: "Medium",
        catastrophic: "High",
    },
    {
        number: "Almost Certain",
        insignificant: "Medium",
        Moderate: "High",
        catastrophic: "Extreme",
    },
];

export const Table4Data = [
    {
        number: "Rare",
        insignificant: "Low",
        minor: "Low",
        Moderate: "Low",
        catastrophic: "High",
    },
    {
        number: "Possible",
        insignificant: "Low",
        minor: "Medium",
        Moderate: "Medium",
        catastrophic: "High",
    },
    {
        number: "likely",
        insignificant: "Medium",
        minor: "Medium",
        Moderate: "High",
        catastrophic: "Extreme",
    },
    {
        number: "Almost Certain",
        insignificant: "Medium",
        minor: "Medium",
        Moderate: "High",
        catastrophic: "Extreme",
    },
];

export const Table5Data = [
    {
        number: "Rare",
        insignificant: "Low",
        minor: "Low",
        Moderate: "Low",
        Major: "Medium",
        catastrophic: "High",
    },
    {
        number: "Unlikely",
        insignificant: "Low",
        minor: "Medium",
        Moderate: "Medium",
        Major: "Medium",
        catastrophic: "High",
    },
    {
        number: "Possible",
        insignificant: "Low",
        minor: "Medium",
        Moderate: "Medium",
        Major: "High",
        catastrophic: "High",
    },
    {
        number: "likely",
        insignificant: "Medium",
        minor: "Medium",
        Moderate: "High",
        Major: "High",
        catastrophic: "Extreme",
    },
    {
        number: "Almost Certain",
        insignificant: "Medium",
        minor: "Medium",
        Moderate: "High",
        Major: "Extreme",
        catastrophic: "Extreme",
    },
];
