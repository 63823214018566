import { useState } from 'react';
import dayjs from 'dayjs';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiLoadingButton from "@mui/lab/LoadingButton";

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Table from "../../../../../components/UI/Table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowFetch } from '../../../../../utility/project.action';
import TruncateText from '../../../../../components/TruncateItems/TruncateText';
import ProjectDetailDialog from './ProjectDetailDialog';

const ProjectTable = (props) => {

    const dispatch = useDispatch();
    const { fetchingBackgroundData } = useSelector((state) => state.projects);

    const { tableData, handleOpenModal, createNewCaseLoading, disableActions, disableActionMeta, handleEditClick } = props;

    // ** States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    const columns = [
        {
            id: "projectID",
            header: "Project ID",
            sort: true,
            width: '120px',
            pinLeft: true,
            sortFn: (a, b) => (a.projectID || '').localeCompare(b.projectID || '')
        },
        {
            flex: 0.1,
            id: "name",
            header: "Name",
            sort: true,
            searchable: true,
            minWidth: '150px',
            sortFn: (a, b) => (a.name || '').localeCompare(b.name || '')
        },
        {
            id: "leadName",
            header: "Lead Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.leadName || '').localeCompare(b.leadName || '')
        },
        {
            id: "managerName",
            header: "Manager Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.managerName || '').localeCompare(b.managerName || '')
        },
        {
            flex: 0.5,
            id: "description",
            header: "Description",
            sort: false,
            minWidth: '250px',
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["description"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.5,
            id: "specifications",
            header: "Specifications",
            sort: false,
            minWidth: '250px',
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["specifications"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "startDate",
            header: "Start Date",
            sort: true,
            width: '120px',
            sortFn: (a, b) => {
                let dateA = a.startDate && a.startDate['java.util.Date'] ? a.startDate['java.util.Date'] : 0;
                let dateB = b.startDate && b.startDate['java.util.Date'] ? b.startDate['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row.startDate && row.startDate['java.util.Date']) {
                    return dayjs(new Date(row.startDate['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: "endDate",
            header: "End Date",
            sort: true,
            width: '120px',
            sortFn: (a, b) => {
                let dateA = a.endDate && a.endDate['java.util.Date'] ? a.endDate['java.util.Date'] : 0;
                let dateB = b.endDate && b.endDate['java.util.Date'] ? b.endDate['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row.endDate && row.endDate['java.util.Date']) {
                    return dayjs(new Date(row.endDate['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: 'actions',
            header: '',
            sortable: false,
            disableColumnFilter: true,
            width: '100px',
            pinRight: true,
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        title="edit"
                        onClick={(e) => handleEditClick(row.caseID, row.status)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                tableData={tableData}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <MuiLoadingButton
                                aria-label="create new case"
                                title="create new case"
                                as={IconButton}
                                size="small"
                                onClick={handleOpenModal}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <AddCircleIcon />
                            </MuiLoadingButton>
                            <MuiLoadingButton
                                aria-label="refresh"
                                title="refresh"
                                as={IconButton}
                                size="small"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={fetchingBackgroundData}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />

            <ProjectDetailDialog
                open={dialogOpen}
                toggle={toggleDetailDialog}
                data={dialogData}
                handleEditClick={handleEditClick}
            />
        </>
    )
}

export default ProjectTable;