import LinearProgressWithLabel from "./LinearProgressWithLabel/LinearProgressWithLabel";

function QuestionProgress(props) {
    const { questionNumber, totalNumber } = props;

    let progress = ((questionNumber - 1) / totalNumber) * 100;

    return <LinearProgressWithLabel value={progress} />;
}

export default QuestionProgress;
