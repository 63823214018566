import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect, useState } from "react";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import jwt_decode from "jwt-decode";

import Layout from "./components/Layout/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import ThemeContext from "./store/theme-context";
import { PrivateRoute } from "./utility/PrivateRoute";
import Loader from "./components/UI/Loader/Loader";
import MainMenu from "./pages/Dashboard/MainMenu";
import ISMS from "./pages/Dashboard/ISMS";
import OTSecurity from "./pages/Dashboard/OTSecurity";
import Documents from "./pages/Dashboard/Documents";
import PublicRoute from "./pages/Public/PublicRoute";
import Training from "./pages/Dashboard/Training";
import { retrieveData } from "./shared/utility";
import TelemetryProvider from "./telemetry-provider";
import { getAppInsights } from "./TelemetryService";

import { toast } from "react-toastify";
import SocketIOInstance from "./socket";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentData, getDocumentContentData, toggleAllowFetch as toggleAllowFetchDocument, toggleGenerateDocumentLoading } from "./utility/documents.action.js";
import { fetchData, fetchOrganisationDetails, toggleAllowFetch, toggleAllowRiskUpdate } from "./utility/dataAction";
import jwtDecode from "jwt-decode";
import { setAuthToken } from "./utility/fetch-instance";
import { HelmetProvider } from 'react-helmet-async';
import { fetchRiskMatrixData, toggleAllowFetchRiskMatrix } from "./utility/riskMatrix.action";
import { toggleAllowFetch as toggleAllowFetchThreat, fetchData as fetchThreatData, toggleThreatHunting } from "./utility/threat.action";

import './utility/ChartGlobal';
import { CONTAINER_ID, setContainerID } from "./store/auth-context";
import { toggleCreateNewCaseLoading } from "./utility/documents.action";
import { fetchData as fetchProjectData } from "./utility/project.action";
import { fetchData as fetchTRAData } from "./utility/tra.action";
import { fetchData as fetchInformationAssetRegisterData } from "./utility/informationAssetRegister.action";
import SearchPage from "./pages/Dashboard/SearchPage";
import { fetchDomainData, toggleAllowDomainFetch, setOrganisationList } from "./utility/miscellaneous.action";

const App = () => {
  var appInsights = null;

  const dispatch = useDispatch();
  const location = useLocation();

  // ** Redux states
  const { organisationDetail } = useSelector((state) => state.data);
  const { generateDocumentLoading } = useSelector((state) => state.documents);
  const { domains, allowDomainFetch, currentOrganisation } = useSelector((state) => state.miscellaneous);

  const themeCtx = useContext(ThemeContext);
  const { keycloak } = useKeycloak();
  const currentURL = window.location.origin;
  const redirectUrl = retrieveData(process.env.REACT_APP_REDIRECT_ULR1);
  const redirectUrl2 = retrieveData(process.env.REACT_APP_REDIRECT_ULR2);
  const aiKey = retrieveData(process.env.REACT_APP_AI);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [email, setEmail] = useState(null);

  const [fooEvents, setFooEvents] = useState([]);

  // Timer settings
  const apiDelay = 5000; // Delay in milliseconds
  let apiTimer;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (keycloak.authenticated) {
    SocketIOInstance.connect(`${process.env.REACT_APP_SOCKET_URL}`, keycloak.token);
  }

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      keycloak.loadUserInfo().then((res) => {
        setEmail(res.sub);
      });
    }
  }, [isLoading, keycloak])

  useEffect(() => {
    let timer;

    // Start timer when generate document loading starts
    if (generateDocumentLoading) {
      timer = setTimeout(() => {
        // Reset generate document loading and show toast message
        dispatch(toggleGenerateDocumentLoading(false));
        toast.error("Failed to generate document. Please try again.");
      }, 60000); // 1 min
    }

    // Cleanup timer
    return () => clearTimeout(timer);
  }, [generateDocumentLoading, dispatch]);

  useEffect(() => {

    if (SocketIOInstance.socket === null) return;

    function onConnect() {
      // setIsConnected(true);
    }

    function onDisconnect() {
      // setIsConnected(false);
    }

    function onRiskAssessmentUpdate(value) {
      // clearTimeout(apiTimer);
      // apiTimer = setTimeout(updateRisk, apiDelay);
      let userName;
      try {
        let token = jwtDecode(keycloak.token);
        userName = token?.name;
      } catch (error) {

      }
      dispatch(toggleAllowRiskUpdate(true));
      dispatch(toggleAllowFetch(true));
      dispatch(fetchData(keycloak));
      dispatch(toggleAllowFetchRiskMatrix(true));
      dispatch(fetchRiskMatrixData(keycloak, email));


      if (location.pathname.includes("risk-assessment")) {
        toast.dismiss();
        toast.success(<div>Hi {userName}, your Risk Assessment has been updated! <Link to="/it-security/risk-assessment">Click here</Link></div>, {
          autoClose: 5000
        });
      }
    }

    function onDocumentListReady(value) {
      dispatch(toggleCreateNewCaseLoading(false));
      dispatch(toggleAllowFetchDocument(true));
      dispatch(fetchDocumentData(keycloak));
    }

    function onDocumentContentReady(value) {
      dispatch(getDocumentContentData(keycloak, value['caseId'], value['caseFile'].replace('caseFile_FILE', '')))
    }

    function onThreatListUpdated(value) {
      dispatch(toggleAllowFetchThreat(true));
      dispatch(fetchThreatData(keycloak, 0));
      let message = value?.message || "New threats has been added!";
      //clear all toast before showing new toast
      toast.dismiss();
      toast.success(<div>{message} <Link to="/it-security/threats" style={{color:"green"}}>View threats</Link></div>, {
        autoClose: 5000,
        progressStyle: { backgroundColor: 'green' },
        hideProgressBar: false
      });
    }

    function onThreatHuntingInProgress(value) {
      let message = value?.message || "Automated threat hunting is in-progress!";
      let status = value?.status || false;
      dispatch(toggleThreatHunting({status, message}));
      dispatch(toggleAllowFetchThreat(true));
      dispatch(fetchThreatData(keycloak, 0));
      //clear all toast before showing new toast
      toast.dismiss();
      toast.success(<div>{message} <Link to="/it-security/threats" style={{color:"green"}}>View threats</Link></div>, {
        autoClose: 5000,
        progressStyle: { backgroundColor: 'green' },
        hideProgressBar: false
      });
    }

    SocketIOInstance.on('connect', onConnect);
    SocketIOInstance.on('disconnect', onDisconnect);
    SocketIOInstance.on('riskAssessment', onRiskAssessmentUpdate);
    SocketIOInstance.on('documentListReady', onDocumentListReady);
    SocketIOInstance.on('contentReady', onDocumentContentReady);
    SocketIOInstance.on('threatHuntingInProgress', onThreatHuntingInProgress);

  }, [keycloak, SocketIOInstance.socket]);

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      dispatch(fetchData(keycloak));
    }
  }, [dispatch, isLoading, keycloak])

  // Fetch document Data
  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      dispatch(fetchDocumentData(keycloak));
    }
  }, [dispatch, isLoading, keycloak])

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.loadUserInfo().then((res) => {
        setUserInfo(res);
        // const _type = res["user_type"] || "intermediate";
        // setUserType(_type);
      });
    }
  }, [keycloak.authenticated])

  function getAllInstance(_email, keycloak) {
    if (_email === null || keycloak?.token === null) {
      return;
    }
    dispatch(fetchOrganisationDetails(keycloak, _email));
  }

  useEffect(() => {
    if (CONTAINER_ID === "") return;
    getAllInstance(userInfo?.sub, keycloak);
  }, [userInfo, keycloak, CONTAINER_ID]);

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      keycloak.loadUserInfo().then((res) => {
        dispatch(fetchRiskMatrixData(keycloak, res.sub));
      });
    }
  }, [dispatch, isLoading, keycloak])

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      dispatch(fetchProjectData(keycloak, 0));
      // setTimeout(() => dispatch(fetchProjectData(keycloak, 0)), 500);
    }
  }, [dispatch, isLoading, keycloak])

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      dispatch(fetchInformationAssetRegisterData(keycloak, 0));
    }
  }, [dispatch, isLoading, keycloak])

  // ** Fetch TRA data 
  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      dispatch(fetchTRAData(keycloak, 0));
    }
  }, [dispatch, isLoading, keycloak])

  // ** Fetch domain data 
  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      if (allowDomainFetch) {
        dispatch(fetchDomainData(keycloak, 0));
      }
    }
  }, [dispatch, isLoading, keycloak, allowDomainFetch])

  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      if (domains === null && allowDomainFetch === false) {
        dispatch(toggleAllowDomainFetch(true))
      }
    }
  }, [dispatch, isLoading, keycloak, domains, allowDomainFetch])

  // Set organisation list and current organisation data
  useEffect(() => {
    if (!isLoading && keycloak.authenticated) {
      try {
        var decoded = jwt_decode(keycloak?.token || '');
        var resource = decoded["resource_access"];
        var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
        const organisationList = clientRoles.filter(role => role !== "cz-users");
        dispatch(setOrganisationList(keycloak, organisationList));
      } catch (error) {
        console.log('DEBUG error = ', error);
      }
    }
  }, [dispatch, isLoading, keycloak])

  useEffect(() => {
    if (currentOrganisation) {
      setContainerID(currentOrganisation + "_0.1");
    }
  }, [currentOrganisation])

  if (isLoading) {
    return <Loader height="90vh" />;
  }

  let routes;

  if (keycloak.authenticated) {
    setAuthToken(keycloak.token);
    routes = (
      <Switch>
        {/* Private routes for authenticated users */}
        <PrivateRoute path="/home">
          <MainMenu />
        </PrivateRoute>
        <PrivateRoute path="/it-security">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/ot-security">
          <OTSecurity />
        </PrivateRoute>
        <PrivateRoute path="/isms">
          <ISMS />
        </PrivateRoute>
        <PrivateRoute path="/map">
          <Documents />
        </PrivateRoute>
        <PrivateRoute path="/search">
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute path="/training">
          <Training />
        </PrivateRoute>
        {/* Redirect to /home if no match */}
        <PrivateRoute path="/">
          <Redirect to="/home" />
        </PrivateRoute>
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        {/* Public routes for non-authenticated users */}
        <PublicRoute />
      </Switch>
    );
  }

  return (
    <HelmetProvider>
      <TelemetryProvider instrumentationKey={aiKey} after={() => { appInsights = getAppInsights() }}>
        <StyleSheetManager
          stylisPlugins={themeCtx.direction === "rtl" && [rtlPlugin]}
        >
          <Layout dir={themeCtx.direction}>{routes}</Layout>
        </StyleSheetManager>
      </TelemetryProvider>
    </HelmetProvider>
  );
};

export default App;
