import { useState } from "react";
import { Button } from "../../../../components/UI/Button/Button.styled";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import { DescriptionContent, DescriptionTextHead, DescriptionTextParagraph, StyledList, StyledInput } from "../RiskList/RiskList.styled";
import { CenteredDiv, ContentContainer } from "./RiskInformation.styled";
import { useDispatch, useSelector } from "react-redux";

const RiskInformation = ({ handleEdit, parentCallback }) => {

    const dispatch = useDispatch();

    const { riskInformation } = useSelector((state) => state.riskMatrix);

    const [isEdit, setIsEdit] = useState(false);

    parentCallback(dataForApi());

    function dataForApi() {
        var dataObj = {};
        for (let index = 1; index < riskInformation.length; index++) {
            const element = riskInformation[index];
            dataObj[`likelihoodRow${index}`] = element["head"];
            dataObj[`likelihoodContent${index}`] = element["title"];
        }
        return dataObj;
    }

    function handleEditButtonClick() {
        // parentCallback(riskInformation);
        setIsEdit(!isEdit);
        handleEdit(!isEdit);
    }

    function handleTextChange(e) {
        // riskInformation[e.target.id].title = e.target.value;
        dispatch({
            type: 'RM_UPDATE_RISK_MATRIX_INFORMATION',
            payload: riskInformation.map((item, index) => {
                if (index === parseInt(e.target.id)) {
                    return {
                        ...item,
                        title: e.target.value
                    }
                }
                return item
            })
        })
    }

    return (
        <ContentContainer>
            <RecHeading maxWidth>Change the definition of likelihood and the description of likelihood to match your business requirements or accept the following :</RecHeading>
            <StyledList smsize>
                {riskInformation.map((item, index) => (
                    <li key={index || item.id}>
                        <DescriptionContent
                        >
                            <DescriptionTextHead
                            >
                                {item.head}
                            </DescriptionTextHead>
                            {
                                index > 0 ? isEdit ? <StyledInput type="text"
                                    defaultValue={item.title}
                                    id={index}
                                    maxLength='500' onChange={(event) => handleTextChange(event)} />
                                    : <DescriptionTextParagraph>{item.title}</DescriptionTextParagraph> : <DescriptionTextParagraph>{item.title}</DescriptionTextParagraph>
                            }

                        </DescriptionContent>
                    </li>
                ))}
            </StyledList>
            <CenteredDiv>
                <Button $edit onClick={handleEditButtonClick}>
                    {
                        !isEdit ? "Edit" : "Save"
                    }
                </Button>
            </CenteredDiv>
        </ContentContainer>
    );
};

// const RiskInformation = ({ riskInformation, handleEdit, parentCallback }) => {

//     const [isEdit, setIsEdit] = useState(false);

//     parentCallback(dataForApi());

//     function dataForApi() {
//         var dataObj = {};
//         for (let index = 1; index < riskInformation.length; index++) {
//             const element = riskInformation[index];
//             dataObj[`likelihoodRow${index}`] = element["head"];
//             dataObj[`likelihoodContent${index}`] = element["title"];
//         }
//         return dataObj;
//     }

//     function handleEditButtonClick() {
//         // parentCallback(riskInformation);
//         setIsEdit(!isEdit);
//         handleEdit(!isEdit);
//     }

//     function handleTextChange(e) {
//         riskInformation[e.target.id].title = e.target.value;
//     }

//     return (
//         <ContentContainer>
//             <RecHeading maxWidth>Change the definition of likelihood and the description of likelihood to match your business requirements or accept the following :</RecHeading>
//             <StyledList smsize>
//                 {riskInformation.map((item, index) => (
//                     <li key={index || item.id}>
//                         <DescriptionContent
//                         >
//                             <DescriptionTextHead
//                             >
//                                 {item.head}
//                             </DescriptionTextHead>
//                             {
//                                 index > 0 ? isEdit ? <StyledInput type="text"
//                                 defaultValue={item.title}
//                                     id={index}
//                                     maxLength='500' onChange={(event) => handleTextChange(event)} />
//                                     : <DescriptionTextParagraph>{item.title}</DescriptionTextParagraph> : <DescriptionTextParagraph>{item.title}</DescriptionTextParagraph>
//                             }

//                         </DescriptionContent>
//                     </li>
//                 ))}
//             </StyledList>
//             <CenteredDiv>
//                 <Button $edit onClick={handleEditButtonClick}>
//                     {
//                         !isEdit ? "Edit" : "Save"
//                     }
//                 </Button>
//             </CenteredDiv>
//         </ContentContainer>
//     );
// };

export default RiskInformation;

