import LoadingButton from "@mui/lab/LoadingButton";
import styled, { css } from "styled-components";
import { styled as MuiStyled } from '@mui/material/styles';

export const CustomButton = styled(LoadingButton)`
    &.MuiButton-root {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 145px;
        height: 48px;
        margin-top: 26px;
        background: #fff;
        font-size: 16px;
        color: #000;
        text-transform: capitalize;
        transition: 0.3s ease-out;
        &:hover {
            background: #000;
            color: #fff;
        }
        & .MuiLoadingButton-loadingIndicator {
            color: #fff;
        }
        &.Mui-disabled {
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

//text-transform: ${({ upper }) => (upper ? "uppercase" : "")};
export const Button = styled(LoadingButton)`
    &.MuiButton-root {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 8px 22px;
        font-family: "Rubik", sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 4px;
        color: #fff;
        align-self: ${({ center }) => center};
        box-sizing: border-box;

        ${({ $sm }) =>
        $sm &&
        css`
                font-size: 14px;
                width: 139px;
                height: 32px;
                padding: 0;
            `}
        ${({ $next }) =>
        $next &&
        css`
                width: 138px;
                height: 42px;
            `}
        ${({ $deactBtn }) =>
        $deactBtn &&
        css`
                width: 218px;
            `}
        ${({ $completeBtn }) =>
        $completeBtn &&
        css`
                width: 127px;
            `}
        ${({ $pdfBtn }) =>
        $pdfBtn &&
        css`
                width: 166px;
            `}
        ${({ $success }) =>
        $success &&
        css`
                background-color: #46a11b;
                &:hover {
                    background-color: #3a8d1c;
                }
            `}
        ${({ $red }) =>
        $red &&
        css`
                background-color: #ff0000;
                &:hover {
                    background-color: #ff0000;
                }
            `}
        ${({ $edit }) =>
        $edit &&
        css`
                background-color: #2f75b5;
                &:hover {
                    background-color: #2f75b5;
                }
            `}
        ${({ $download }) =>
        $download &&
        css`
                background-color: #eb5757;
                &:hover {
                    background-color: #eb5757;
                }
            `}
        ${({ $error }) =>
        $error &&
        css`
                background-color: #ed1e24;
                &:hover {
                    background-color: #ed1e24;
                }
            `}

            ${({ $hidden }) =>
        $hidden &&
        css`
                @media (max-width: 991px) {
                    display: none;
                }
            `}

            ${({ $small }) =>
        $small &&
        css`
                display: none;
                width: 105px;
                height: 32px;
                font-size: 14px;
                padding: 4px 10px;
                @media (max-width: 991px) {
                    display: inline-block;
                }
            `}
        &.Mui-disabled {
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.12),
                    rgba(0, 0, 0, 0.12)
                ),
                #ffffff;
            color: rgba(0, 0, 0, 0.26);
        }
    }
`;

export const CustomFeedbackHelpButton = styled(LoadingButton)`
    &.MuiButton-root {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 145px;
        height: 48px;
        margin-top:0;
        margin-left: 30px;
        background: #000;
        font-size: 12px;
        color: #fff;
        border:1px solid #46a11b;
        text-transform: capitalize;
        transition: 0.3s ease-out;
        &:hover {
            background: #fff;
            color: #fff;
        }
        & .MuiLoadingButton-loadingIndicator {
            color: #fff;
        }
        &.Mui-disabled {
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

export const StyledButton = styled(LoadingButton)`
    &.MuiButton-root {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin-top:0;
        margin-left: 30px;
        background: #000;
        font-size: 12px;
        color: #fff;
        border:1px solid #46a11b;
        text-transform: capitalize;
        transition: 0.3s ease-out;
        &:hover {
            background: #46a11b;
            color: #fff;
        }
        & .MuiLoadingButton-loadingIndicator {
            color: #fff;
        }
        &.Mui-disabled {
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

export const buttonWrapper = styled.div`
    float: right;
`;

export const CustomLoadingButton = MuiStyled(LoadingButton)(({ theme, success }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 22px',
    fontFamily: "Rubik",
    fontSize: '15px',
    fontWeight: 500,
    textTransform: 'capitalize',
    borderRadius: '4px',
    color: '#fff',
    boxSizing: 'border-box',

    ...(success && {
        backgroundColor: '#46a11b',
        '&:hover': {
            backgroundColor: "#3a8d1c",
        }
    })
}));

export const VisuallyHiddenInput = MuiStyled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});