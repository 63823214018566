import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { AvatarWrapper, CustomAppBar, CustomAvatar, DraweToggler, HeaderLogo, HeaderWrapper, CustomToolbar, CustomIconButton } from "./Header.styled";

// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Popover from "@mui/material/Popover";

import MoreVertIcon from "@mui/icons-material/MoreVert";
// import SearchIcon from "@mui/icons-material/Search";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AvatarImg from "../../../../images/avatar-img.png";
import logoImgSrc from "../../../../images/sidebar-logo.png";
import { useContext, useState } from "react";
// import HeaderSearchModal from "../HeaderSearchModal/HeaderSearchModal";
// import { useTheme } from "@emotion/react";
import { Button } from "../../../../components/UI/Button/Button.styled";
// import Search from "../../../../components/Search/Search";
import logoImgSrc2 from "../../../../images/logo0.2.png";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import InfoModal from "../InfoModal/InfoModal";
import ModalImageSrc from "../../../../images/reccomendationImg.png";
import { paymentUrlInstructor, paymentUrlMonthly, paymentUrlYearly } from "../../../../store/auth-context";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router";
import ThemeContext from "../../../../store/theme-context";
import ButtonStyled from "../../../../components/UI/CustomButton/CustomButton.styled";
import { cleanupSessionData } from "../../../../utility/indexdb";
import AppSearch from "../../../../components/AppSearch/AppSearch";
import SubscriptionDialog from "../../../../components/Dialog/SubscriptionDialog";
import { useSelector } from "react-redux";

const settings = ['Setting', 'Theme', 'Privacy Policy', 'Logout'];

function Header(props) {
    let { drawerWidth, handleDrawerToggle, username, email } = props;
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => setOpenModal(false);
    const { toggleMode } = useContext(ThemeContext);

    const { organisationDetail } = useSelector((state) => state.data);


    // const theme = useTheme();
    // const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));

    // const [searchModalOpened, setSearchModalOpened] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    // const openSearchModalHandler = () => {
    //     setSearchModalOpened(true);
    // };
    // const closeSearchModalHandler = () => {
    //     setSearchModalOpened(false);
    // };

    const openIntroductionHandler = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const closeIntroductionHandler = () => {
        setAnchorEl(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logOutHandler = () => {
        cleanupSessionData();
        history.replace("/login");
        sessionStorage.clear();
        localStorage.removeItem('current-organisation');
        keycloak.logout()
    };

    function handleButtonClick(setting) {

        if (setting === "Logout") {
            logOutHandler();
        }

        if (setting === "Theme") {
            toggleMode();
        }

        if (setting === "Privacy Policy") {
            history.replace("/home/privacy-policy");
        }

        if (setting === "Setting") {
            history.replace("/it-security/settings");
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <HeaderWrapper>
                <CustomAppBar $drawerWidth={drawerWidth} position="fixed">
                    <CustomToolbar sx={{ position: "static" }}>
                        <HeaderLogo src={logoImgSrc2} />
                        {/* {isMobileUp ? <Search /> : <HeaderSearchModal open={searchModalOpened} handleClose={closeSearchModalHandler}></HeaderSearchModal>} */}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <AppSearch />
                        </Box>
                        <Box
                            sx={{
                                display: { xs: "none", sm: "block" },
                                alignItems: { xs: "center" },
                                marginLeft: "10px",
                            }}
                        >
                            {/* <CustomIconButton
                            $black
                            aria-haspopup="true"
                            color="inherit"
                            onClick={openSearchModalHandler}
                            sx={{ display: { sm: "none" } }}
                        >
                            <SearchIcon />
                        </CustomIconButton>
                        <CustomIconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={() => { }}
                        >
                            <CustomBadge badgeContent={4} color="error">
                                <NotificationsNoneIcon />
                            </CustomBadge>
                        </CustomIconButton> */}
                            {
                                !(organisationDetail?.serviceHours > 0) && <Button $success variant="filled" startIcon={<WorkspacePremiumIcon />} style={{ marginRight: "20px" }} onClick={() => setOpenModal(true)}>
                                    Upgrade Subscription
                                </Button>
                            }

                            {/* <IconButton aria-describedby={id} onClick={openIntroductionHandler} sx={{ color: "#3A8D1C", display: { sm: "none" } }}>
                                <MoreVertIcon />
                            </IconButton> */}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <AvatarWrapper>
                                        <CustomAvatar src={AvatarImg} onClick={() => { }} alt="Profile Pic" />
                                        <h6>{username}</h6>
                                    </AvatarWrapper>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '55px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleButtonClick(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <DraweToggler onClick={handleDrawerToggle}>
                            <KeyboardDoubleArrowRightIcon />
                        </DraweToggler>
                    </CustomToolbar>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={closeIntroductionHandler}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Button $edit>introduction to Cisozen</Button>
                    </Popover>
                </CustomAppBar>
            </HeaderWrapper>

            <SubscriptionDialog
                email={email}
                open={openModal}
                toggle={handleCloseModal}
            />
        </>
    );
}

export default Header;
