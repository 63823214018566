import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '../../../../components/UI/Button/Button.styled';


const validInput = new RegExp('^([a-z]|[A-Z]|[0-9]|[@./ ]|){0,255}$');

export default function NewNote(props) {
    const { activeNumber, addNote } = props;
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState("");
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        addNote(activeNumber, text);
        setOpen(false);
    }

    const handleTextChange = (e) => {
        if(!validInput.test(e.target.value)){
            setIsError(true);
            setErrorMessage("Invalid input!");
        } else {
            setIsError(false);
            setErrorMessage("");
            setText(e.target.value);
        }
    }

    return (
        <div>
            <Button $success onClick={handleClickOpen} style={{ display: "block", margin: "0 auto" }}>
                Add Note 
            </Button>
            <Dialog open={open} fullWidth onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        id="standard-multiline-static"
                        autoFocus
                        margin="dense"
                        label="New Note (max 255 characters)"
                        type="text"
                        fullWidth
                        multiline
                        maxRows={5}
                        minRows={5}
                        variant="standard"
                        onChange={handleTextChange}
                        inputProps={{ maxLength: 255 }}
                        error={isError} helperText={errorMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button $success onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}