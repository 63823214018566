import { Alert, Box, Grid, Grow } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess, e8controlProcess, e8MainProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./E8LandingPage.styled";
import { useKeycloak } from "@react-keycloak/web";
import logo from "../../../../images/logo0.2.png";
import { CardHead, DotSign, ProgressBlock, ProgressCard, ProgressValue, StatsText, TextBlock } from './E8.styled';
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { QuestionNumber } from "../../../../components/Question/QuestionHead/QuestionHead.styled";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ThemeContext from "../../../../store/theme-context";
import { GraphContainer } from "./E8LandingPage.styled";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { Helmet } from "react-helmet-async";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";

function E8LandingPage(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [header, setHeader] = useState(null);
    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [healthCheckCompleted, setHealthCheckCompleted] = useState(false);
    const [completedHealthCheckList, setcompletedHealthCheckList] = useState([]);
    const { mode } = useContext(ThemeContext);
    const [activeMainCase, setActiveMainCase] = useState(null);
    const [activeNode, setActiveNode] = useState(null);
    const [controlCount, setControlCount] = useState(null);
    // list of available control milestones
    const controls = [
        { "id": 1, "title": "Application whitelisting" },
        { "id": 2, "title": "Patch applications" },
        { "id": 3, "title": "Patch operating systems" },
        { "id": 4, "title": "Minimize users with administrative privileges" },
        { "id": 5, "title": "Disable untrusted Microsoft Office macros" },
        { "id": 6, "title": "User application hardening" },
        { "id": 7, "title": "Multi-factor authentication" },
        { "id": 8, "title": "Routine backup of important information" },
    ];

    const healthCheckNavHandler = () => history.push("/it-security/health-check-progress");
    const recommendationsNavHandler = () => history.push("/it-security/recommendations");
    const e8NavHandler = (caseID) => history.push("/it-security/essential-eight/controls", { caseID });

    function handleNextStage() {
        return healthCheckNavHandler;
    }

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
            });
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        if (!CONTAINER_ID) return;
        getActiveCaseID();

    }, [CONTAINER_ID]);

    async function getActiveCaseID() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${e8controlProcess}/instances?status=open&page=0&pageSize=10&sortOrder=false&withData=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                setFetching(false);
                if (response.status == 200) {

                    const caseID = response.data.instances[0]["case-id"];
                    if (caseID !== null) {
                        //store active caseID in local storage
                        window.sessionStorage.setItem("E8_ACTIVE_CASE", JSON.stringify(caseID));
                        setActiveMainCase(response.data.instances[0]);

                        const caseData = response.data.instances[0]["case-file"]["case-data"];
                        const ansArr = Object.keys(caseData);
                        setControlCount(ansArr.length)
                        let tempArr = [...controls];
                        ansArr.map((ans) => {
                            const qnum = ans.charAt(7);
                            const index = tempArr.findIndex((e) => e.id == qnum);
                            tempArr.splice(index, 1);
                        });
                        setActiveNode(tempArr[0]);
                    }
                }
            })
            .catch(function (error) {
                setFetching(false);
                console.log(error);
            });
    }

    async function startNewMainCase() {
        setFetching(true);
        let data = JSON.stringify({});
        var config = {
            method: 'post',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${e8controlProcess}/instances`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: data
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                setFetching(false);
                getActiveCaseID();
            })
            .catch(function (error) {
                setFetching(false);
                console.log(error);
            });
    }


    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClick2 = () => {
        setModal2Open(true);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "";
    let modalList = [""];

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `ACSC Essential Eight`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    function handleContinue(nodeName) {
        e8NavHandler(activeMainCase);
    }

    function contentInProgress() {
        return (<>
            {
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead>Current progress</CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={7} xs={12}>
                                {
                                    controlCount < 8 ? <TextBlock style={{ marginTop: "15px", height: "100%", textAlign: "left", fontSize: "16px" }}><QuestionNumber>{activeNode?.id}</QuestionNumber>{activeNode?.title}</TextBlock> : <TextBlock style={{ marginTop: "15px", height: "100%", textAlign: "left", fontSize: "16px" }}>Congratulations! You have finished the ACSC E8 Assessment.</TextBlock>
                                }

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <ProgressBlock>
                                    <CircularProgressWithLabel value={(controlCount) / controls.length * 100}>
                                        <ProgressValue>{Math.round((controlCount) / controls.length * 100)}% <br />completed</ProgressValue>
                                    </CircularProgressWithLabel>
                                </ProgressBlock>
                            </Grid>
                            <Grid item md={2} xs={0}></Grid>
                            <Grid item md={10} xs={12} style={{ textAlign: "left" }}>
                                <Button $success onClick={(e) => handleContinue(activeNode)}>Continue</Button>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            }

        </>);
    }

    function contentNewHealthCheck() {
        return (
            <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={6} xs={12} style={{ marginTop: "5%", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        {/* TODO: update text for essential 8 */}
                                        <TextBlock>
                                            The Australian Cyber Security Centre (ACSC) helps organisations protect themselves against various cyber threats. The most effective of these mitigation strategies are the Essential Eight (E8).
                                        </TextBlock>
                                        {/* <h4 style={{ marginTop: "12px" }}>Maximum time you should spend on this health check activity : 2 Weeks</h4> */}
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => startNewMainCase()}>Start ACSC E8 Assessment</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5} xs={0} sm={0} display={{ xs: "none", lg: "block", md: "block" }}>
                                <ProgressBlock>
                                    <img src="https://abcountrywide.com/wp-content/uploads/2020/10/ASD.jpg" width="70%" height="70%"></img>
                                </ProgressBlock>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            </>
        );
    }

    if (!fetching) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    {
                        activeMainCase === null ?
                            contentNewHealthCheck() : contentInProgress()
                    }
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>ACSC Essential Eight - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    ACSC Essential Eight
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default E8LandingPage;
