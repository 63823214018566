
const initialState = {
  path: null,
  meta: null,
};

const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TRACKER_UPDATE_DATA':
      return {
        ...state,
        path: action.payload.path,
        meta: action.payload.meta,
      };
    default:
      return state;
  }
};

export default trackerReducer;
