import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import { fetchNextPageData, toggleAllowFetch } from '../../../../../../utility/dataAction';
import RiskChip from './RiskChip';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, Typography, TextField, InputAdornment, Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import RiskDetailDialog from './RiskDetailDialog';
import { DomainCard } from './ApplicableControl.styled';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddCircleOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Loader from "../../../../../../components/UI/Loader/Loader";
import ThemeContext from "../../../../../../store/theme-context";
import Table from '../../../../../../components/UI/Table/Table';
import TruncateText from '../../../../../../components/TruncateItems/TruncateText';

const RiskMCardNewTable = (props) => {

  // ** Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // ** Redux states
  const { fetchingBackgroundData } = useSelector((state) => state.data);

  const { risks, handleEditClick, handleAddButton, disableActions, disableActionMeta, createNewCaseLoading } = props;

  const [riskDetailDialogOpen, setRiskDetailDialogOpen] = useState(false);
  const [riskDetailDialogData, setRiskDetailDialogData] = useState(null);

  const toggleRiskDetailDialog = (e, riskData = null) => {
    setRiskDetailDialogOpen(prev => !prev);
    setRiskDetailDialogData(riskData);
  }

  useEffect(() => {
    if (history?.location?.state?.from === "search") {
      if (history?.location?.state?.caseData) {
        toggleRiskDetailDialog(null, JSON.parse(history.location.state.caseData));
      }
    }
  }, [history?.location?.state])

  const columns = [
    // { field: 'caseID', headerName: 'Case ID', minWidth: 170 },
    {
      flex: 1,
      id: 'riskTitle',
      header: 'Risk Name',
      sort: true,
      searchable: true,
      minWidth: "250px",
      sortFn: (a, b) => (a.riskTitle || '').localeCompare(b.riskTitle || ''),
      renderCell: ({ row }) => {
        return <>
          <TruncateText
            text={row["riskTitle"] || ''}
            onClickReadMore={(e) => toggleRiskDetailDialog(e, row)}
          />
        </>
      }
    },
    {
      id: 'inherentRiskRating',
      header: 'Inherent Risk Rating',
      sort: true,
      width: "180px",
      sortFn: (a, b) => {
        const getOrder = (rating) => {
          if (!rating) return 0;
          const match = rating.match(/(\w+)-(\d+)/);
          if (match) {
            const category = match[1];
            const level = parseInt(match[2]);

            // Assign weights based on sequence
            switch (category) {
              case 'Extreme':
                return level + 40;
              case 'High':
                return level + 30;
              case 'Medium':
                return level + 20;
              case 'Low':
                return level + 10;
              default:
                return 0;
            }
          }
          return 0;
        };

        const orderA = getOrder(a.inherentRiskRating || '');
        const orderB = getOrder(b.inherentRiskRating || '');

        return orderA - orderB;
      },
      renderCell: (params) => {
        if (params.value) {
          return <RiskChip rating={params.value} />
        }
        return ""
      }
    },
    {
      id: 'residualRiskRating',
      header: 'Residual Risk Rating',
      sort: true,
      width: "200px",
      sortFn: (a, b) => {
        const getOrder = (rating) => {
          if (!rating) return 0;
          const match = rating.match(/(\w+)-(\d+)/);
          if (match) {
            const category = match[1];
            const level = parseInt(match[2]);

            // Assign weights based on sequence
            switch (category) {
              case 'Extreme':
                return level + 40;
              case 'High':
                return level + 30;
              case 'Medium':
                return level + 20;
              case 'Low':
                return level + 10;
              default:
                return 0;
            }
          }
          return 0;
        };

        const orderA = getOrder(a.residualRiskRating || '');
        const orderB = getOrder(b.residualRiskRating || '');

        return orderA - orderB;
      },
      renderCell: ({ value, row }) => {
        if (row["inherentRiskRating"] && value) {
          return <RiskChip rating={value} />
        }
        return ""
        // return params.row.riskTreatment === "Completed" ? <RiskChip rating={params.value ? params.value : params.value} /> : "",
      }
    },
    {
      id: 'riskTreatment',
      header: 'Risk Treatment',
      sort: true,
      minWidth: "150px",
      sortFn: (a, b) => (a.riskTreatment || '').localeCompare(b.riskTreatment || '')
    },
    {
      id: 'date',
      header: 'Last Updated Date',
      sort: true,
      width: "170px",
      sortFn: (a, b) => (a.date || 0) - (b.date || 0),
      renderCell: ({ row }) => row.createdDate
    },
    {
      id: 'actions',
      header: '',
      sortable: false,
      disableColumnFilter: true,
      pinRight: true,
      width: "100px",
      renderCell: ({ row }) => (
        <>
          <MuiLoadingButton
            aria-label="edit"
            title="edit"
            onClick={(e) => handleEditClick(row.caseID, row.status)}
            as={IconButton}
            disabled={disableActions}
            loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
          >
            <EditIcon />
          </MuiLoadingButton>
          <IconButton
            onClick={(e) => {
              toggleRiskDetailDialog(e, row);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    // Add more columns as needed...
  ];

  return (
    <>
      <DomainCard style={{ width: '100%' }}>
        <Table
          tableData={risks}
          columns={columns}
          tableTitle={
            <Typography variant="h5">Risk List</Typography>
          }
          actionButtons={
            <>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <MuiLoadingButton
                  aria-label="create new case"
                  title="create new case"
                  as={IconButton}
                  size="small"
                  onClick={handleAddButton}
                  loading={createNewCaseLoading}
                  disabled={disableActions}
                >
                  <AddCircleIcon />
                </MuiLoadingButton>
                <MuiLoadingButton
                  aria-label="refresh"
                  title="refresh"
                  as={IconButton}
                  size="small"
                  onClick={(e) => {
                    e?.preventDefault();
                    dispatch(toggleAllowFetch(true));
                  }}
                  loading={fetchingBackgroundData}
                  disabled={disableActions}
                >
                  <SyncIcon />
                </MuiLoadingButton>
              </Box>
            </>
          }
        />
      </DomainCard>

      <RiskDetailDialog
        open={riskDetailDialogOpen}
        toggle={toggleRiskDetailDialog}
        riskData={riskDetailDialogData}
        handleEditClick={handleEditClick}
      />

    </>
  );
};

export default RiskMCardNewTable;