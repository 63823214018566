import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledHeading = styled.h3`
    display: inline-flex;
    position: relative;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: ${({ theme }) =>
        theme.palette.mode === "light"
            ? "#4F4F4F"
            : theme.palette.text.primary};
    max-width: 90%;
    margin-top: 3px;
    letter-spacing: 0.46px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        font-size: 16px;
        line-height: 26px;
        max-width: 100%;
    }
`;

export const CustomIconButton = styled(IconButton)`
    &.MuiIconButton-root {
        padding: 0;
        position: absolute;
        top: -10px;
        right: -30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        & .MuiSvgIcon-root {
            fill: ${({ theme }) =>
        theme.palette.mode === "light"
            ? "rgba(70, 161, 27, 0.5)"
            : "rgba(70, 161, 27, 0.5)"};
        }
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
            right: -13px;
        }
    }
`;
