import { Alert, ButtonBase, Chip, Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useHistory, } from "react-router";

import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, VulnerabilitiesProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";

import { useKeycloak } from "@react-keycloak/web";
import { CardHead, MainGrid, ProgressBlock, ProgressCard, ProgressValue, StatsText, Wrapper, } from "./Vulnerabilities.styled";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { getCase } from "../TRA/TRAApi";
import useGetWafResult from "./VulnerabilitiesAPI";
import { useDispatch } from "react-redux";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { Helmet } from "react-helmet-async";

function Vulnerabilities(props) {


    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isLoading, wafData, scanDate } = useGetWafResult(CONTAINER_ID, keycloak.token, keycloak.userInfo)
    const { modalTitle, setModalTitle } = useState("");

    let modalList = [];

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Vulnerabilities`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    }

    useEffect(() => {
        try {
            let _modalTitle = `Web application tested on wwww.${keycloak.userInfo?.email.split('@')[1]} at ${scanDate}`;
            setModalTitle(_modalTitle);
        } catch (error) {

        }

    }, [])

    if (!loading) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <MainGrid item lg={2} md={3} sm={6} xs={12}>
                        <ProgressCard>
                            <CardHead>Web Application Firewall</CardHead>
                            <ProgressBlock>
                                {
                                    isLoading ? <Loader height="50px"></Loader> : wafData !== null ? wafData == 0 ? <div style={{ height: "200px" }}><Alert severity="info" >WAF was not detected </Alert></div> : <CircularProgressWithLabel value={(wafData ?? 0) / 100 * 100}>
                                        <ProgressValue>{Math.round((wafData || 0) / 100 * 100)}%</ProgressValue>
                                    </CircularProgressWithLabel> : <Alert severity="info">WAF report will be created once the automated test is completed.</Alert>
                                }
                            </ProgressBlock>

                            {
                                wafData !== null ? <Button $success style={{ textTransform: 'capitalize', width: '100%' }} onClick={handleClick}>
                                    Details
                                </Button> : ""
                            }

                        </ProgressCard>
                    </MainGrid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Vulnerabilities - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px">
                    Vulnerabilities
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default Vulnerabilities;
