import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledHeading = styled.h2`
    display: inline-flex;
    position: relative;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin: ${(props) => props.marginMd};
    color: ${({ theme }) => theme.palette.mode === "light" ? "#4F4F4F" : theme.palette.text.primary};
    text-transform: capitalize;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        font-size: 24px;
        line-height: 28px;
        margin: ${(props) => props.marginSm};
    }
`;

export const CustomIconButton = styled(IconButton)`
    &.MuiIconButton-root {
        padding: 0;
        position: absolute;
        top: -10px;
        right: -30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        & .MuiSvgIcon-root {
            fill: ${({ theme }) => theme.palette.mode === "light" ? "rgba(70, 161, 27, 0.5)" : "rgba(70, 161, 27, 0.5)"};
        }
    }
`;

export const StyledSubHeading = styled.h5`
    display: inline-flex;
    position: relative;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: ${({ theme }) => theme.palette.mode === "light" ? "#4F4F4F" : theme.palette.text.primary};
    text-transform: capitalize;
`;