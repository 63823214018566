import { MuiTelInput } from 'mui-tel-input';
import { validatePhone } from "../validation";
import { useState } from "react";

const PhoneQuestion = (props) => {

    const { question, parentIndex, index, handleAnswer, readOnly } = props;

    // ** States
    const [phone, setPhone] = useState(question.qAnswer);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
    const [phoneIsError, setPhoneIsError] = useState(false);

    const onPhoneChangeHandler = (value, info) => {
        setPhone(value);
        if (validatePhone(info.nationalNumber)) {
            setPhoneIsError(false);
            setPhoneErrorMessage("");
            handleAnswer(value, parentIndex, index);
        } else {
            setPhoneIsError(true);
            setPhoneErrorMessage("Invalid phone number");
            handleAnswer("", parentIndex, index);
        }
    }

    return (
        <MuiTelInput
            forceCallingCode
            required={!!question?.required}
            disabled={readOnly}
            error={phoneIsError}
            helperText={phoneErrorMessage}
            label={question.qHeading}
            defaultCountry="AU"
            value={phone}
            onChange={onPhoneChangeHandler}
        />
    );
}

export default PhoneQuestion;