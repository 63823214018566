import { useCallback } from "react";
import {
  UsertypecardsettingsRoot, Infoalignment, ImgIcon, Title, DescriptionGroup, Description,
  Buttonalignment
} from "./UserTypeCardSettings.styled";
import { Button } from "../../../../components/UI/Button/Button.styled";
import { List, ListItem, Typography } from "@mui/material";


{/* <UserTypeCardSettings title={"Advanced"} 
              description1={"You have substantial prior experience and/or training in cybersecurity."} 
              description2={"You will require minimal guidance to use CisoZen."} 
              description3={"You will be presented with a complete view of CisoZen."} 
              imgSrc={AdvancedImg}/> */}

const UserTypeCardSettings = (
  { title,
    description,
    imgSrc, handleClickOpen, handleClose }
) => {
  function onClickEvent() {
    // On Click event
    handleClickOpen()
  };

  return (
    <UsertypecardsettingsRoot>
      <Infoalignment>
        <ImgIcon alt="" src={imgSrc} />
        <Typography variant="h6" textTransform="capitalize">{title}</Typography>
        <DescriptionGroup>
          <List sx={{ listStyleType: 'disc', pl: 1 }}>
            {
              description ? description.map((desc, index) => {
                return (
                  <ListItem sx={{ display: 'list-item' }} key={index}>
                    <Typography variant="body2">
                      {desc}
                    </Typography>
                  </ListItem>
                )
              }) : ""
            }
          </List>
        </DescriptionGroup>
      </Infoalignment>
      <Buttonalignment>
        <Button $success onClick={handleClickOpen}>Change</Button>
      </Buttonalignment>
    </UsertypecardsettingsRoot>
  );
};

export default UserTypeCardSettings;