import { baseUrl } from '../store/auth-context';

let authToken = null;

export function setAuthToken(token) {
    authToken = token;
}

function buildHeaders() {
    const headers = {
        'Content-Type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    return headers;
}

export const apiRequest = ({ url, ...restOptions }) => {
    let apiUrl = `${baseUrl}${url}`;
    return fetchInstance(apiUrl, restOptions)
}

const fetchInstance = (url, options = {}) => {
    return new Promise(async (resolve, reject) => {
        const headers = buildHeaders();

        const requestOptions = {
            method: options?.method || 'GET',
            headers: {
                ...headers,
                ...(options?.headers && { ...options.headers }),
            },
            ...(options.data && { body: options?.data ? options.data : undefined }),
        };

        try {
            // const response = await fetch(`${baseUrl}/services/rest/server${options.url}`, requestOptions);
            const response = await fetch(url, requestOptions);

            let data;
            try {
                data = await response.json();
            } catch (error) {
                data = await response.body;
            }

            if (!response.ok) {
                reject(data.message || 'Something went wrong');
                return;
            }

            resolve({
                status: response.status,
                data: data
            });
        } catch (error) {
            reject(error.message || 'Something went wrong');
        }
    })
}

export default fetchInstance;