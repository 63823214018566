import { CardActions, Grid } from "@mui/material";
import styled from "styled-components";

export const RiskCardAction = styled(CardActions)`
    padding: 16px;
    background: ${({ theme }) => theme.palette.mode === "light" ? "#fff" : "#121212"};
`;

export const RiskHeader = styled(Grid)`
    padding: 16px;
    background: ${({ theme }) => theme.palette.mode === "light" ? "#ddd" : "#000"};
`;