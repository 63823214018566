import React, { useContext, useCallback, useState } from "react";
// import { Button } from "../../../../components/UI/Button/Button.styled.js";
import { StyledContainer, ContentContainer, StyledHead, StyledList, GraphContainer, ImgContainer, BoxesContainer, StyledBoxes, StyledBox, StyledHeadingCenter, StyledBackgroundDiv, CustomGrid } from "./InformationMaturity.styled.js";

import { Button } from "../../../../components/UI/Button/Button.styled.js";
import imgCastle from "../../../../images/csMt0.png";
import imgCastle1 from "../../../../images/csMt1.png";
import imgCastle2 from "../../../../images/csMt2.png";
import imgCastle3 from "../../../../images/csMt3.png";
import imgCastle4 from "../../../../images/csMt4.png";
import imgCastle5 from "../../../../images/csMt5.png";
import annotationPlugin from "chartjs-plugin-annotation";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CONTAINER_ID } from "../../../../store/auth-context.js";
import { Box } from "@mui/system";
import { Grid, Step, StepContent, StepLabel, Stepper } from "@mui/material";
// import { toast } from "react-toastify";
import ThemeContext from "../../../../store/theme-context";
import ImageZoom from "react-medium-image-zoom";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Loader from "../../../../components/UI/Loader/Loader.js";
import * as fetchInstance from "../../../../utility/fetch-instance.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);

export var options = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
        },
        annotation: {
            annotations: {
                line1: {
                    type: "line",
                    yMin: 3,
                    yMax: 3,
                    borderColor: "#fff",
                    borderDash: [8, 8],
                    borderWidth: 1,
                },
            },
        },
    },

    responsive: true,
    scales: {
        x: {
            grid: {
                display: false,
            },
            lineThickness: 0,
            tickThickness: 0,
            stacked: true,
            ticks: { color: 'white', beginAtZero: true }
        },
        y: {
            grid: {
                display: false,
            },
            lineThickness: 0,
            tickThickness: 0,
            stacked: true,
            ticks: {
                min: 0,

                max: 5,
                stepSize: 1,
            },
            ticks: { color: 'white', beginAtZero: true }
        },
    },
};
ChartJS.defaults.plugins.legend = {
    display: false,
};

const InformationMaturity = ({ onDate, progress, idealProgress, score, firstLabels, secondLabels, oldSecondLabels, stageNumber1, stageNumber2, stageLevel1, stageLevel2, stageHealth1, stageHealth2, link, idealLink }) => {
    //const [fetching, setFetching] = React.useState(false);
    //const [maturity, setMaturity] = React.useState(null);
    const { mode } = useContext(ThemeContext);
    const castleList = [imgCastle, imgCastle1, imgCastle2, imgCastle3, imgCastle4, imgCastle5]
    const castleIndex1 = stageNumber1;
    const castleIndex2 = castleIndex1 < 5 ? castleIndex1 + 1 : castleList.length - 1

    const average = Math.floor(oldSecondLabels.reduce((a, b) => a + b, 0) / oldSecondLabels.length) ?? 0;

    options = {
        maintainAspectRatio: false,

        plugins: {
            title: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        yMin: 3,
                        yMax: 3,
                        borderColor: mode === 'light' ? '#000' : '#fff',
                        borderDash: [8, 8],
                        borderWidth: 1,
                    },
                },
            },
            tooltip: {
                enabled: false // <-- this option disables tooltips
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: { color: mode === 'light' ? '#000' : '#fff', beginAtZero: true }
            },
            y: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: {
                    min: 0,
                    color: mode === 'light' ? '#000' : '#fff', beginAtZero: true,
                    max: 5,
                    stepSize: 1,
                },
            },
        },
    };

    const nextStageHandler = () => {
        link();
    };
    const data = {
        labels: ["Identify", "Protect", "Detect", "Respond", "Recover"],
        red: {
            dataSet: firstLabels,
        },
        green: {
            dataSet: secondLabels,
        },
    };
    const getHealthCheckAnsweredQuestions = React.useCallback(async (processInstance) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variable/CurrentMaturity`
            }
            const res = await fetchInstance.apiRequest(config);
            console.log(res.data)
            // setFetching(false);
        } catch (err) {
            // setFetching(false);
            //toast.error(err.message);
        }
    }, [])

    // React.useEffect(() => {
    //     if (!healthInstance) return ;
    //     getHealthCheckAnsweredQuestions(healthInstance.id);
    // }, [getHealthCheckAnsweredQuestions, healthInstance])

    function contentsForSecurityPosture() {
        return (
            <ContentContainer $flex1>
                <Grid container spacing={2} style={{ height: "100%", marginTop: "0" }}>
                    <CustomGrid item xs={12} md={6}>
                        <StyledHeadingCenter>
                            <div style={{ height: "140px" }}>
                                <StyledHead $mzero> {onDate !== "" ? `Your security posture compared to the recommended posture on ${onDate}` : "Your security posture"}</StyledHead>
                            </div>
                            <div style={{ maringTop: "10px" }}>
                                <ImgContainer>
                                    <ZoomImage src={castleList[castleIndex1]} style={{ height: "90%", width: "90%" }}></ZoomImage>
                                </ImgContainer>
                                <StyledBox $lightblue>
                                    <h6>Cisozen score</h6>
                                    <p>{score.currentScore}</p>
                                </StyledBox>
                                <StyledBox $gray>
                                    <h6>Maturity</h6>
                                    <p>{average}/5</p>
                                </StyledBox>
                                <StyledBox >
                                    <h6>Progress</h6>
                                    <p>{progress !== "" ? progress : "-"}</p>
                                </StyledBox>
                            </div>
                        </StyledHeadingCenter>
                    </CustomGrid>
                    <CustomGrid item xs={12} md={6} $back>
                        <StyledHeadingCenter>
                            <div style={{ height: "140px" }}>
                                <StyledHead $panel2 $mzero>Ideal security posture</StyledHead>
                            </div>

                            <div style={{ maringTop: "10px" }}>
                                <ImgContainer>
                                    <ZoomImage src={castleList[3]} style={{ height: "90%", width: "90%" }}></ZoomImage>
                                </ImgContainer>
                                <StyledBox $lightblue>
                                    <h6>Cisozen score</h6>
                                    <p>{236}</p>
                                </StyledBox>
                                <StyledBox $gray>
                                    <h6>Maturity</h6>
                                    <p>3/5</p>
                                </StyledBox>
                                <StyledBox>
                                    <h6>Progress</h6>
                                    <p>{idealProgress}</p>
                                </StyledBox>
                            </div>
                        </StyledHeadingCenter>
                    </CustomGrid>
                </Grid>
            </ContentContainer>
        );
    }

    return (
        <StyledContainer>
            <ContentContainer>
                <div id="currentmaturity">
                    <StyledHead>Current Maturity</StyledHead>
                    <GraphContainer>
                        <Bar
                            options={options}
                            data={{
                                labels: data.labels,
                                offset: true,
                                responsive: true,
                                datasets: [
                                    {
                                        label: "Current Maturity",
                                        barThickness: 50,
                                        data: data.green.dataSet,
                                        datalabels: {
                                            anchor: "center",
                                            color: "#fff",
                                            font: {
                                                size: 16,
                                                weight: "bold"
                                            }
                                        },
                                        backgroundColor: "green",
                                    },
                                    {
                                        label: "Work Remaining",
                                        data: data.red.dataSet,
                                        barThickness: 50,
                                        datalabels: {
                                            labels: {
                                                title: {
                                                    color: "red",
                                                },
                                            },
                                        },
                                        backgroundColor: "red",
                                    },
                                ],
                            }}
                        />
                    </GraphContainer>
                    <StyledList>
                        <li>
                            <span></span>
                            <p>Current Maturity</p>
                        </li>
                        <li>
                            <span></span>
                            <p>Recommended Maturity</p>
                        </li>
                    </StyledList>
                </div>
            </ContentContainer>
            {
                contentsForSecurityPosture()
            }
            {/* <StyledBackgroundDiv></StyledBackgroundDiv>
                <StyledBackgroundDiv $back></StyledBackgroundDiv>

                <StyledHeadingCenter>
                    <StyledHead $mzero>Your security posture!</StyledHead>
                    {link && (
                        <Button $success upper="true" $small onClick={nextStageHandler}>
                            Next Step
                        </Button>
                    )}
                </StyledHeadingCenter>
                <BoxesContainer>
                    <ImgContainer>
                        <img src={castleList[castleIndex1]} alt="" style={{ height: "35%", width: "35%" }} />
                        {link && (
                            <Button $hidden $success upper="true" $next onClick={nextStageHandler}>
                                Next Step
                            </Button>
                        )}
                    </ImgContainer>

                    <StyledBoxes $marginleft>
                        <StyledBox>
                            <h6>Stage</h6>
                            <p>{stageNumber1}</p>
                        </StyledBox>
                        <StyledBox>
                            <h6>Stage</h6>
                            <p>{stageNumber2}</p>
                        </StyledBox>
                    </StyledBoxes>
                </BoxesContainer>
                <StyledBoxes $center>
                    <StyledBox $gray>
                        <h6>Maturity level</h6>
                        <p>{stageLevel1}</p>
                    </StyledBox>
                    <StyledBox $gray>
                        <h6>Maturity level</h6>
                        <p>{castleIndex2}/5</p>
                    </StyledBox>
                </StyledBoxes>

                <BoxesContainer>
                    <StyledBoxes $marginsmall>
                        <StyledBox $lightblue>
                            <h6>Health</h6>
                            <p>{stageHealth1}</p>
                        </StyledBox>
                        <StyledBox $lightblue>
                            <h6>Health</h6>
                            <p>{stageHealth2}</p>
                        </StyledBox>
                    </StyledBoxes>
                    <ImgContainer $end>
                        <img src={castleList[castleIndex2]} alt="" style={{ height: "35%", width: "35%", marginLeft: "65%" }} />
                    </ImgContainer>
                </BoxesContainer>
                <h6>Ideal security posture</h6> */}
        </StyledContainer>
    );
};

export default InformationMaturity;


export const ZoomImage = ({ src }) => {
    const [isZoomed, setIsZoomed] = useState(false)

    const handleImgLoad = useCallback(() => {
        setIsZoomed(true)
    }, [])

    const handleZoomChange = useCallback(shouldZoom => {
        setIsZoomed(shouldZoom)
    }, [])

    return (
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange} zoomMargin={100}>
            <img
                alt=""
                src={src}
                style={{ width: '80%' }}
            />
        </ControlledZoom>
    )
}