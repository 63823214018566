import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import InsertChartRoundedIcon from "@mui/icons-material/InsertChartRounded";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import SecurityIcon from '@mui/icons-material/Security';
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import LiveHelpIcon from '@mui/icons-material/LiveHelp';


import { useHistory } from "react-router";

import { DrawerWrapper, CustomMenuItem, CustomDrawer, SideDrawerLogo, CustomNavLink, CustomList, helpBgColor, CustomMenuItemIcon, CustomMenuItemText } from "./SideDrawer.styled";
import AuthContext from "../../../../store/auth-context";
import ThemeContext from "../../../../store/theme-context";

import { useKeycloak } from '@react-keycloak/web';

import sidebarLogo from "../../../../images/sidebar-logo.png";
import "./SideDrawer.styled";
import { PopupModal } from "react-calendly";
import sidebarLogo2 from "../../../../images/logo0.2.png";
import { Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip } from "@mui/material";
import { FaHome, FaHouseUser } from "react-icons/fa";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import OrganisationSelect from "../../../../components/OrganisationSelect/OrganisationSelect";

function SideDrawer(props) {

    let { container, mobileOpen, handleDrawerToggle, handleDrawerShrink, drawerWidth, menuCategoryList } = props;

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const trackerData = useSelector((state) => state.tracker);

    const { organisationDetail } = useSelector((state) => state.data);

    let { path } = useRouteMatch();
    const history = useHistory();
    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext?.theme?.palette?.mode === "dark" ? true : false;
    const [isOpen, setIsOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState(0);
    const [customAnswers, setCustomAnswers] = useState({});
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        setCustomAnswers(
            (prev) => ({
                ...prev,
                a1: trackerData?.meta?.title || '',
                a2: trackerData?.meta?.href || '',
            })
        )
    }, [trackerData])

    const handleClick = (index, urlPath) => () => {
        // if (index === openIndex) {
        //     setOpenIndex(-1);
        // } else {
        //     setOpenIndex(index);
        // }
        history.push(`/it-security${urlPath}`)
    };

    const logOutHandler = () => {
        history.replace("/login");
        sessionStorage.clear();
        keycloak.logout()
    };

    const helpHandler = () => {
        setIsOpen(true)
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setUserInfo(res);
            });
        }
    }, [keycloak.authenticated])

    const drawer = (
        <DrawerWrapper>
            {/* <SideDrawerLogo src={sidebarLogo} style={{ opacity: "0" }} /> */}
            <Box sx={{ height: 82, padding: '8px 16px' }}>
                <OrganisationSelect />
            </Box>
            <Divider />
            <CustomList sx={{ p: 0 }}>
                <CustomNavLink to={`/home`}>
                    <CustomMenuItem>
                        <Tooltip title="Home" placement="right-start">
                            {
                                drawerWidth > 75 ? <><CustomMenuItemIcon><FaHome /></CustomMenuItemIcon><CustomMenuItemText>Home</CustomMenuItemText></> : <><CustomMenuItemIcon><FaHome /></CustomMenuItemIcon></>
                            }
                        </Tooltip>
                    </CustomMenuItem>
                </CustomNavLink>
            </CustomList>
            <Divider />
            <CustomList sx={{ p: 0 }} style={{ paddingBottom: "100px" }}>
                {
                    menuCategoryList.map((category, catIndex) => {
                        return (
                            <li key={catIndex}>
                                <ul>
                                    <ListSubheader>{category.text}</ListSubheader>
                                    {category.menuIconList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <CustomNavLink to={`${path}${item.path}`} key={index} >
                                                <CustomMenuItem style={{ backgroundColor: path.includes(item.path) ? "#3a8d1c" : "" }}>
                                                    <Tooltip title={item.text} placement="right-start">
                                                        {
                                                            drawerWidth > 75 ? <><CustomMenuItemIcon>{item.icon}</CustomMenuItemIcon><CustomMenuItemText>{item.text}</CustomMenuItemText></> : item.icon
                                                        }
                                                    </Tooltip>
                                                </CustomMenuItem>
                                            </CustomNavLink>
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </li>
                        )
                    })
                }
                <li>
                    <ul>
                        <Divider />
                        {
                            organisationDetail?.serviceHours > 0 && <CustomMenuItem onClick={helpHandler}>
                                <Tooltip title="Security Expert" placement="right-start">
                                    {
                                        drawerWidth > 75 ? <><CustomMenuItemIcon><SupportAgentIcon /></CustomMenuItemIcon><CustomMenuItemText>Security Expert</CustomMenuItemText></> : <LiveHelpIcon />
                                    }
                                </Tooltip>
                            </CustomMenuItem>
                        }
                        <CustomMenuItem
                            onClick={() => {
                                handleDrawerShrink();
                            }}
                        >
                            {
                                drawerWidth > 75 ? <><CustomMenuItemIcon><KeyboardDoubleArrowLeftIcon /></CustomMenuItemIcon><CustomMenuItemText>Minimize</CustomMenuItemText></> : <KeyboardDoubleArrowRightIcon />
                            }
                        </CustomMenuItem>
                    </ul>
                </li>
            </CustomList>
        </DrawerWrapper >
    );

    return (
        <>
            <PopupModal
                pageSettings={{
                    backgroundColor: isDarkTheme ? '1f2128' : 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '3a8d1c',
                    textColor: isDarkTheme ? 'ffffff' : '4d5055'
                }}
                prefill={{
                    email: userInfo?.email || '',
                    firstName: userInfo?.given_name || '',
                    lastName: userInfo?.family_name || '',
                    name: userInfo?.name || '',
                    customAnswers: customAnswers
                }}
                url="https://calendly.com/cisozen/support"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
            />
            {mobileOpen ?
                <CustomDrawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: `${drawerWidth}px`,
                        },
                    }}
                >
                    {drawer}
                </CustomDrawer>
                :
                <CustomDrawer
                    variant="persistent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </CustomDrawer>
            }
        </>
    );
}

export default SideDrawer;
