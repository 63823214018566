import React, { useState } from "react";

import '../../../nested/RiskMatrix/RiskMatrixPDF/RiskMatrixPDF.css';

import { CONTAINER_ID } from "../../../../../store/auth-context";
import { jsPDF } from "jspdf";
import logo2 from '../../../../../images/logo0.2.png';
import pdfBackground from '../../../../../images/pdf-background.PNG';
import { renderToString } from 'react-dom/server';
import { getCompletedOrganization } from "../../../InformationAPI";
import EditableTable from "../../../components/EditableTable/EditableTable";
import * as fetchInstance from "../../../../../utility/fetch-instance";

function RiskMatrixDownloadReportButton(props) {

    const containerID = CONTAINER_ID;
    var organisationName = "Organisation";

    const { kcToken, email, riskConsequence, riskActions, riskInformation, tableData, data, date = "16/06/2022" } = props;
    const [isLoading, setIsloading] = useState(false);
    const header = { headers: { "Authorization": `Bearer ${kcToken}`, 'Content-Type': 'application/json' } };

    function download() {
        setIsloading(true);

        const content = renderToString(<Prints />);

        let doc = new jsPDF('p', 'pt', 'a4');

        doc.html(content, {
            autoPaging: true,
            callback() {
                doc.deletePage(doc.internal.getNumberOfPages());
                doc.deletePage(doc.internal.getNumberOfPages());
                if (tableData.tableData.length > 3) doc.deletePage(doc.internal.getNumberOfPages());
                const pageCount = doc.internal.getNumberOfPages();
                // From HTML
                var finalY = doc.lastAutoTable.finalY || 120
                doc.setPage(pageCount - 1)

                doc.setDrawColor("green");
                doc.line(55, 50, 334, 50);
                doc.setFontSize(12);
                doc.text(`${date}`, 534, 55, { align: "right" });
                doc.setFontSize(32);
                doc.text("Current Likelihood Definition", 55, finalY, {})
                doc.autoTable({
                    startY: finalY + 30,
                    html: `#mytable-${data?.id}`,
                    theme: 'grid',
                    margin: { left: 55, right: 55, bottom: 120 },
                    headStyles: {
                        fillColor: '#46a11b',
                        textColor: '#fff'
                    },
                    didDrawPage: function (data) {
                        // Footer
                        var str = `${organisationName.substring(0, 32)}${organisationName.length > 32 ? "..." : ""} - Risk Management | ` + "Page " + doc.getCurrentPageInfo().pageNumber;

                        doc.setFontSize(10);

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        var pageSize = doc.internal.pageSize;
                        var pageHeight = pageSize.height
                            ? pageSize.height
                            : pageSize.getHeight();
                        if (doc.getCurrentPageInfo().pageNumber > 5) {

                            doc.addImage(logo2, 'PNG', data.settings.margin.left, pageHeight - 60, 130, 30);
                            doc.text(str, pageSize.width - 50, pageHeight - 40, { align: 'right' });

                        }

                    }
                });

                finalY = doc.lastAutoTable.finalY || 200
                doc.setFontSize(32);
                doc.text("Current Risk Management Actions", 55, finalY + 50, {})
                doc.autoTable({
                    startY: finalY + 70,
                    html: `#mytable2-${data?.id}`,
                    theme: 'grid',
                    margin: { left: 55, right: 55, bottom: 120 },
                    headStyles: {
                        fillColor: '#46a11b',
                        textColor: '#fff'
                    },
                    didDrawPage: function (data) {
                        // Footer
                        var str = `${organisationName.substring(0, 32)}${organisationName.length > 32 ? "..." : ""} - Risk Management | ` + "Page " + doc.getCurrentPageInfo().pageNumber;

                        doc.setFontSize(10);

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        var pageSize = doc.internal.pageSize;
                        var pageHeight = pageSize.height
                            ? pageSize.height
                            : pageSize.getHeight();
                        if (doc.getCurrentPageInfo().pageNumber > 5) {

                            doc.addImage(logo2, 'PNG', data.settings.margin.left, pageHeight - 60, 130, 30);
                            doc.text(str, pageSize.width - 50, pageHeight - 40, { align: 'right' });

                        }

                    }
                })


                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                {
                    let isAdded = false;
                    let lastPageNum1 = 2;
                    let lastPageNum2 = 2;
                    finalY = doc.lastAutoTable.finalY;
                    doc.text("Current Impact Definition", 55, finalY + 50, {})
                    riskConsequence.tabs.map((tab, key) => {
                        if (doc.getCurrentPageInfo().pageNumber > lastPageNum1) {
                            lastPageNum1 = doc.getCurrentPageInfo().pageNumber;
                            doc.setDrawColor("green");
                            doc.line(55, 50, 334, 50);
                            doc.setFontSize(12);
                            doc.text(`${date}`, 534, 55, { align: "right" });
                        }
                        finalY = doc.lastAutoTable.finalY || 200 + ((key) + 1 * 100)
                        doc.autoTable({
                            startY: finalY + (key === 0 ? 70 : 30),
                            html: `#riskTable${key}-${data?.id}`,
                            theme: 'grid',
                            margin: { left: 55, right: 55, top: 75, bottom: 120 },
                            headStyles: {
                                fillColor: '#46a11b',
                                textColor: '#fff'
                            },
                            didDrawPage: function (data) {
                                // Footer
                                var str = `${organisationName.substring(0, 32)}${organisationName.length > 32 ? "..." : ""} - Risk Management | ` + "Page " + doc.getCurrentPageInfo().pageNumber;

                                doc.setFontSize(10);

                                if (doc.getCurrentPageInfo().pageNumber > lastPageNum2) {
                                    lastPageNum2 = doc.getCurrentPageInfo().pageNumber;
                                    doc.addImage(logo2, 'PNG', data.settings.margin.left, pageHeight - 65, 130, 30);
                                    doc.text(str, pageSize.width - 50, pageHeight - 40, { align: 'right' });

                                }

                            }
                        })
                    })
                }

                setIsloading(false);
                window.open(doc.output('bloburl'));
            }
        });

    }

    const handleCallback = (childData) => {
        //
    }

    function RecommendationsPage(props) {
        var { date, companyName } = props;
        return (
            <div className="container-center-horizontal">
                <div className="highlights-page screen">
                    <div className="flex-row3">
                        <div className="line-4"></div>
                        <div className="date2 inter-medium-balck-12px">
                            <span className="inter-medium-black-12px">{date}</span>
                        </div>
                    </div>
                    <h1 className="title3 inter-medium-black-36px">
                        <span className="inter-medium-black-36px">Risk Management Matrix</span>
                    </h1>
                    <div style={{ height: '600px' }} className="inter-normal-black-12px">
                        <EditableTable
                            tableData={tableData.tableData}
                            headingColumns={tableData.headingColumns}
                            title="Consequence"
                            selectedValue={tableData.selectedValue}
                            parentCallback={handleCallback}
                        />
                    </div>
                    <div className="flex-row-3-1">
                        <img className="logo-highlights" src={logo2} alt="" />
                        <p className="bottom-date inter-normal-black-10px">
                            <span className="inter-normal-black-10px">{`${companyName.substring(0, 32)}${companyName.length > 32 && "..."}`} - Risk Management | Page 3</span>
                        </p>
                    </div>
                </div>
            </div >
        );
    }

    function TableOfContentsPage(props) {
        return (
            <div className="container-center-horizontal">
                <div className="table-of-contents-page screen">
                    <img className="rectangle-3" src={pdfBackground} alt="" />
                    <h1 className="title2 inter-normal-black-48px">
                        <span className="inter-normal-black-48px">TABLE OF CONTENTS</span>
                    </h1>
                    <br></br>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Risk Management Matrix</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">3</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Likelihood Definition</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">4</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Risk Management Actions</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">4</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Risk Impact Definition</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">4</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <img className="logo-sm" src={logo2} alt="" />
                </div>

            </div>
        );
    }

    function CoverPage(props) {
        var { companyName, date } = props;

        return (

            <div className="container-center-horizontal" >
                <div className="cover-page screen" id="coverpage">
                    <div className="rectangle-2"></div>
                    <div className="flex-col">
                        <div className="flex-row">
                            <img className="rectangle-1" src={logo2} alt="" />
                            <div className="line-1"></div>
                        </div>
                        <h1 className="title inter-bold-emperor-48px">
                            <span className="inter-bold-emperor-48px">Risk Management</span>
                        </h1>
                        <div className="line-2"></div>
                        <div className="securemation inter-normal-storm-dust-24px">
                            <span className="inter-normal-storm-dust-24px">{companyName}</span>
                        </div>
                        <div className="date inter-light-emperor-48px">
                            <span className="inter-light-emperor-48px">{date}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const Prints = () => (
        <div>
            <div className="container-fixed" id="report">
                <CoverPage companyName={organisationName} date={date}></CoverPage>
                <TableOfContentsPage></TableOfContentsPage>
                <RecommendationsPage
                    date={date}
                    companyName={organisationName}
                />
            </div>
        </div>
    );

    //api 1 get completed health check
    //api 2 get completed org info
    //api 3 get list of recommendations



    const getOrganisationProcessInstance = () => {
        getCompletedOrganization(kcToken, email).then((response) => {
            const _processInstanceID = response.data;
            if (_processInstanceID < 1) {
                //no record found
                download();

            } else {
                getOrganisationVariables(_processInstanceID);
            }
        });
    }

    const getOrganisationVariables = (processInstanceId) => {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${containerID}/processes/instances/${processInstanceId}/variables/instances`,
            ...header,
        }
        fetchInstance
            .apiRequest(config)
            .then((response) => {
                var responseArr = response.data["variable-instance"];
                organisationName = responseArr.filter(obj => { return obj.name === "OrganisationName" })[0]["value"].toUpperCase();
                download();

            })
            .catch((error) => {
                setIsloading(false);
                console.log({ error });
                // Handle error
            });
    }

    return (
        <div>
            <table id={`mytable-${data?.id}`} style={{ display: 'none' }}>
                <caption>Likelihood Definition</caption>
                <thead style={{ backgroundColor: "green" }}>
                    <tr>
                        <th><span className="inter-bold-black-14px">{riskInformation[0].head}</span></th>
                        <th><span className="inter-bold-black-14px">{riskInformation[0].title}</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        riskInformation.map((value, key) => {
                            if (key > 0) {
                                return (
                                    <tr key={`mytable${key}-${data?.id}`}>
                                        <td><span className="inter-normal-black-10px">{value.head}</span></td>
                                        <td><span className="inter-normal-black-10px">{value.title}</span> </td>
                                    </tr>
                                )
                            } else {
                                return (<></>)
                            }
                        })
                    }
                </tbody>
            </table>
            <table id={`mytable2-${data?.id}`} style={{ display: 'none' }}>
                <thead style={{ backgroundColor: "green" }}>
                    <tr>
                        <td><span className="inter-normal-black-10px">Risk Level</span></td>
                        <td><span className="inter-normal-black-10px">Management Action Required</span></td>
                        <td><span className="inter-normal-black-10px">Risk Treatment Plan</span></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        riskActions.levels.map((value, key) => {
                            return (
                                <tr key={`mytable2${key}-${data?.id}`}>
                                    <td><span className="inter-normal-black-10px">{value}</span></td>
                                    <td><span className="inter-normal-black-10px">{riskActions.actions[key]}</span></td>
                                    <td><span className="inter-normal-black-10px">{riskActions.plans[key]}</span> </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                riskConsequence.tabs.map((tab, tabNum) => {
                    return (
                        <table id={`riskTable${tabNum}-${data?.id}`} style={{ display: 'none' }}>
                            <thead style={{ backgroundColor: "green" }}>
                                <tr>
                                    <th colSpan={2}>
                                        <span>{tabNum + 1 + "." + tab.name}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th><span className="inter-bold-black-14px">Consequence</span></th>
                                    <th><span className="inter-bold-black-14px">Description</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    riskConsequence.levels.map((level, index) => {
                                        const value = riskConsequence.fields.find(field => field.name === `consCat${index + 1}Row${tabNum + 1}`) || { name: `consCat${index + 1}Row${tabNum + 1}`, desc: "" };
                                        return (
                                            <tr key={tabNum + index}>
                                                <td><span className="inter-normal-black-10px">{level}</span></td>
                                                <td><span className="inter-normal-black-10px">{value.desc}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    )
                })
            }

            {props.children({ onClick: getOrganisationProcessInstance, loading: isLoading })}


            {/* {
                isLoading ? <LoadingButton loading={isLoading} loadingPosition="center" variant="contained" color="success" style={{padding: "8px 22px"}}>View Report</LoadingButton> : <Button $success onClick={getHealthCheckProcessInstance} disabled={isLoading}>View Report</Button>
            } */}
            {/* <Button $success onClick={getOrganisationProcessInstance} disabled={isLoading}>
                {
                    isLoading ? <CircularProgress style={{ height: "26px", width: "87px", padding: "0px 30px" }}>Download Report</CircularProgress> : "Download Report"
                }
            </Button> */}
        </div>
    );
}

export default RiskMatrixDownloadReportButton;
