import { useState } from "react";
import { MuiTelInput } from 'mui-tel-input';

import { TextField, FormControl, FormLabel } from "@mui/material";

import { validateEmail, validateName, validatePhone } from "../validation";

const ContactQuestion = (props) => {

    const { question, parentIndex, index, handleAnswer, readOnly } = props;

    const [nameIsError, setNameIsError] = useState(false);
    const [emailIsError, setEmailIsError] = useState(false);
    const [phoneIsError, setPhoneIsError] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

    const [phone, setPhone] = useState(question.qAnswer.phone);

    const onNameChangHandler = (e) => {
        if (validateName(e.target.value)) {
            setNameIsError(false);
            setNameErrorMessage("");
            handleAnswer({ ...question.qAnswer, name: e.target.value }, parentIndex, index);
        } else {
            setNameIsError(true);
            setNameErrorMessage("Invalid name, at least 4 characters starting with abc...");
            handleAnswer({ ...question.qAnswer, name: "" }, parentIndex, index);
        }
    }

    const onPhoneChangeHandler = (value, info) => {
        setPhone(value);
        if (validatePhone(info.nationalNumber)) {
            setPhoneIsError(false);
            setPhoneErrorMessage("");
            handleAnswer({ ...question.qAnswer, phone: value }, parentIndex, index);
        } else {
            setPhoneIsError(true);
            setPhoneErrorMessage("Phone number should only be numbers more than 6");
            handleAnswer({ ...question.qAnswer, phone: "" }, parentIndex, index);
        }
    }

    const onEmailChangeHandler = (e) => {
        if (validateEmail(e.target.value)) {
            setEmailIsError(false);
            setEmailErrorMessage("");
            handleAnswer({ ...question.qAnswer, email: e.target.value }, parentIndex, index);
        } else {
            setEmailIsError(true);
            setEmailErrorMessage("Please enter a valid email! Example : user@email.com");
            handleAnswer({ ...question.qAnswer, email: "" }, parentIndex, index);
        }
    }

    return (
        <FormControl style={{ border: '1px solid #696969', padding: '10px', borderRadius: '10px' }}>
            <FormLabel>{question.qHeading}</FormLabel>
            <TextField
                label={question.qAvailableAnswers[0]}
                variant="outlined"
                error={nameIsError}
                helperText={nameErrorMessage}
                onChange={onNameChangHandler}
                defaultValue={question.qAnswer.name}
                disabled={readOnly}
                style={{ marginTop: "20px" }}
                inputProps={{ maxLength: 255 }}
                InputLabelProps={{ shrink: true }}
            />
            <MuiTelInput
                style={{ marginTop: "20px" }}
                error={phoneIsError}
                helperText={phoneErrorMessage}
                label={question.qAvailableAnswers[1]}
                disabled={readOnly}
                defaultCountry="AU"
                forceCallingCode
                value={phone}
                onChange={onPhoneChangeHandler}
                InputLabelProps={{ shrink: true }}
            />
            {/* <TextField label={question.qAvailableAnswers[1]} variant="outlined" error={phoneIsError} helperText={phoneErrorMessage} onChange={onPhoneChangeHandler} defaultValue={question.qAnswer.phone} style={{ marginTop: "20px" }} inputProps={{ maxLength: 255 }} /> */}
            <TextField
                label={question.qAvailableAnswers[2]}
                variant="outlined"
                error={emailIsError}
                disabled={readOnly}
                helperText={emailErrorMessage}
                onChange={onEmailChangeHandler}
                defaultValue={question.qAnswer.email}
                style={{ marginTop: "20px" }}
                inputProps={{ maxLength: 255 }}
                InputLabelProps={{ shrink: true }}
            />

        </FormControl>
    );

};
export default ContactQuestion;
