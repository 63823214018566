import { useState, useEffect, useCallback } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { CustomLoadingButton } from "../../../../../components/UI/Button/Button.styled";
import E8QuestionsStepper from "../../../components/E8QuestionStepper/E8QuestionStepper";
import Loader from "../../../../../components/UI/Loader/Loader";

const SOAFormDialog = (props) => {

    const { open, toggle, handleSubmit, submittingForm } = props;

    // ** States
    const [questions, setQuestions] = useState(props.questions);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (open) {
            setQuestions(JSON.parse(JSON.stringify(props.questions)));
        }
    }, [open, props.questions])

    // ** flag to check if the form data is filled
    let isFormFilled = questions?.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        if (subQuestion.required) {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        } else {
            return true;
        }
    }) : false;

    const handleAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
    }, [questions]);

    const onSubmit = useCallback((e) => {
        e?.preventDefault();
        handleSubmit(questions);
    }, [handleSubmit, questions])

    return (
        <>
            <Dialog
                open={!!open}
                onClose={toggle}
                fullWidth
                maxWidth="sm"
                scroll="paper"
            >
                <DialogTitle>SOA</DialogTitle>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <IconButton
                        title="close"
                        aria-label="close"
                        onClick={toggle}
                        sx={{

                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent dividers scroll="paper">
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                open && <>
                                    {
                                        questions?.length > 0 ? <E8QuestionsStepper
                                            questions={[...questions]}
                                            qIndex={activeIndex}
                                            handleAnswer={handleAnswer}
                                        /> : <Loader />
                                    }

                                </>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: "20px 24px" }}>
                    <CustomLoadingButton
                        success
                        loading={submittingForm}
                        disabled={!isFormFilled}
                        variant='contained'
                        onClick={onSubmit}
                    >
                        Submit
                    </CustomLoadingButton>
                </DialogActions>
            </Dialog>


        </>
    )

}

export default SOAFormDialog;