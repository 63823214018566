
import * as fetchInstance from "../../../../utility/fetch-instance";

//file to reuse api calls in Maturity Assessment

//api call to get milestones related to active case
export async function getMileStones(CONTAINER_ID, caseID, keycloak) {
    let result = { status: false };
    var config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/milestones?achievedOnly=false&pageSize=20`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };

    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}

//get active maturity assessment
export async function getActiveCases(CONTAINER_ID, AssessMaturity, keycloak) {
    let result = {};
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${AssessMaturity}/instances?status=open&page=0&pageSize=10&sortOrder=false&withData=true`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };

    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}

//get close maturity assessment
export async function getClosedCases(CONTAINER_ID, AssessMaturity, keycloak) {
    let result = {};
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${AssessMaturity}/instances?status=closed&page=0&pageSize=10&sortOrder=false&withData=true`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };

    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}

//update process id of active case
export async function updateProcessID(CONTAINER_ID, caseID, keycloak) {
    let result = {};
    var config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/processes/instances?page=0&pageSize=10&sortOrder=true`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };

    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}
//get list of variable keys from api
export async function getVariablesfromApi(CONTAINER_ID, AssessMaturity, keycloak) {
    let result = {};
    var config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/processes/definitions/${AssessMaturity}/variables`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };

    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}
//get list of variable values from api
export async function getVariablesValuesfromApi(CONTAINER_ID, processInstanceID, keycloak) {
    let result = {};
    var config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstanceID}/variables/instances`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`
        }
    };
    await fetchInstance.apiRequest(config)
        .then(function (response) {
            result = response;
        })
        .catch(function (error) {
            result["status"] = 404;
        });

    return result;
}







