import React, { useState, useEffect, useRef } from "react";
import { CircleLoader, CustomizedCardHeader, CustomizedCardContent } from "../Common/Graph.styled";
import { useKeycloak } from '@react-keycloak/web';
import ForceGraph2D from "react-force-graph-2d";
import {
  mitigationDefaultColor,
  mitHighlighColor,
  green1,
  edgeHighlightColor,
  edgeDefaultColor,
  grey,
  demainDefaultColor,
  apiCall_error,
  mitigation_details_error,
  domain_details_error,
  riskNodeColor,
  red1,
  linkColor
} from "../Common/GraphConfig";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Popover from '@mui/material/Popover';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from '@mui/material/Typography';
import { CustomDialog, CustomDialogTitle } from "../Common/CustomizedDialog";
import CardContent from '@mui/material/CardContent';
import LegendPage from "./Legend";
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, toggleAllowFetch } from "../../../../../utility/dataAction";
import * as fetchInstance from "../../../../../utility/fetch-instance";
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
  palette: {
    background: {
      paper: 'transparent', // card theme color
    },
  },
});

const DomainGraphPage = (props) => {
  const { keycloak } = useKeycloak();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const forceRef = useRef();
  const nodes = [];
  const links = [];

  const [graph, setGraph] = useState({ nodes, links });
  const [serverList, setServerList] = useState([])
  const [showLoader, setShowLoader] = useState("none");
  const [highlightNodes, setActiveNodes] = useState(new Set());
  const [highlightLinks, setActiveLinks] = useState(new Set());
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessgae, setAlertMessgae] = useState("");
  const [filter, setFilter] = useState('');
  const [nodeClicked, setNodeClicked] = useState(null);
  const [resetFilterHighlight, setResetFilterHighlight] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [top, setTop] = useState(205);
  const [left, setLeft] = useState(75);
  const [open, setOpen] = React.useState(false);
  const [riskDetais, setRiskDetais] = useState([]);
  const [riskTitle, setRiskTitle] = useState("");
  const [showModal, setShowModal] = useState("none");
  const [selectedNode, setSelectedNode] = useState(null);
  const [domainMits, setdomainkMits] = useState([]);

  const dispatch = useDispatch();
  const { domainData, loading, error, allowFetch } = useSelector(
    (state) => state.data
  );

  useEffect(() => {
    if (domainData) {
      // dispatch(toggleAllowFetch(false));
      // setRisks(data);
      console.log(domainData);
      setdomainkMits(domainData);
    }

    if (domainData === null && allowFetch === false) {
      dispatch(toggleAllowFetch(true))
    }


  }, [domainData, allowFetch])

  useEffect(() => {
    if (allowFetch) {
      dispatch(fetchData(keycloak));
    }
  }, [dispatch, allowFetch]);

  var baseURL = ""
  useEffect(() => {

    if (domainData.length === 0) return;
    setShowLoader("block");
    nodeCreator(domainMits);
    edgeCreator(domainMits);
    setServerList(domainMits);
    setShowLoader("none");

    //let authToken = keycloak.token;

    // let apiHeaders = new Headers();
    // apiHeaders.append("Content-Type", "application/json");
    // apiHeaders.append("Authorization", "Bearer " + authToken);

    // let raw = JSON.stringify({});

    // let requestOptions = {
    //   method: 'POST',
    //   headers: apiHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };
    //Api call to get domain mitigation graph data      
    // fetch(baseURL+"/models/MitigationDomainGraph/dmnresult", requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //       setShowLoader("none");
    //     let domainMits = result.dmnContext.mitigationDomainGraph[0]; //list from api
    //       nodeCreator(domainMits);
    //       edgeCreator(domainMits); 
    //       setServerList(domainMits); //store api response for further usages
    //   })
    //   .catch(error => {
    //     setShowLoader("none");
    //     console.log('error', error)
    //     setAlertMessgae(apiCall_error)
    //     setOpenAlertModal(true);
    //   });

    //to avoid overlapping of nodes. Higher the charge value farther the nodes will be from each other 
    forceRef.current.d3Force("charge").strength(-50);
    forceRef.current.d3Force("link").distance(100);
    forceRef.current.zoom(0.1, 10);
  }, [domainMits])

  /** to create the node list for the graph */
  function nodeCreator(inputNodes) {
    inputNodes.forEach(element => {
      //add the domain nodes to node list 
      nodes.push({ id: element.domainID, color: demainDefaultColor, label: element.domainHeading, val: (element.domainScore === 0 ? 25 : element.domainScore * 50) });
      element.sections.forEach((section) => {
        section.controls.forEach((mit) => {
          //add the mitigation nodes to node list associated to domains
          mit.mitigationScore = 3;
          nodes.push({ id: mit.controlID, color: mitigationDefaultColor, label: mit.controlHeading, val: (mit.mitigationScore === 0 ? 5 : mit.mitigationScore * 10) });
        })
      });
    });
    setGraph({ ...graph, nodes: nodes, links: links });//set the graph with updated nodes and links

  }

  /** to create the edges/links list for the graph */
  function edgeCreator(node) {
    const links = [];
    node.forEach((element) => {
      element.sections.forEach((section) => {
        section.controls.forEach((mit) => {
          mit.mitigationScore = 1;
          if (mit.mitigationScore !== null) {
            const id = links.length + 1;
            const source = element.domainID;
            const target = mit.controlID;
            const hidden = false;
            const color = edgeDefaultColor;

            links.push({ id, source, target, hidden, color });
          }
        });
      });
    });
    setGraph({ ...graph, nodes, links });//set the graph with updated  links
    setShowLoader("none");
  }

  //to highlight the nodes on node Click
  const handleNodeClick2 = (selectedNode) => {

    highlightNodes.clear();
    highlightLinks.clear();
    resetHighLight();

    setNodeClicked(selectedNode || null);

    // Center/zoom on selected node
    selectedNode.fx = selectedNode.x;
    selectedNode.fy = selectedNode.y;
    selectedNode.fz = selectedNode.z;
    forceRef.current.zoom(1, 10);
    forceRef.current.centerAt(selectedNode.x, selectedNode.y, 10);


    // Highlight links connected to a Risk node
    if (selectedNode?.id.length < 3) {

      graph.links.forEach((linknode) => {
        var count = 1;
        if (linknode.source.id === selectedNode.id) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = demainDefaultColor;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
          count++;
        } else {
          linknode.source.color = grey;
          if (linknode.target.color === mitigationDefaultColor) {
            linknode.target.color = grey;
          }
          linknode.color = grey;
        }
      });
    }
    // Highlight links connected to a Mitigation node
    else if (selectedNode && selectedNode.id.left >= 3) {
      graph.links.forEach((linknode) => {
        if (linknode.target.id === selectedNode.id) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = red1;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
        } else {
          if (linknode.source.color !== red1) {
            linknode.source.color = grey;
          }
          if (linknode.source.color === riskNodeColor) {
            linknode.source.color = grey;
          }
          linknode.target.color = grey;
          linknode.color = grey;
        }
      });
    }
    // Reset all node colors to grey
    else {
      graph.nodes.forEach((graphnode) => {
        graphnode.color = grey;
      });
    }

    updateHighlight();
    updatePosition(selectedNode);
  };
  const handleNodeClick = (selectedNode) => {
    highlightLinks.clear();
    resetHighLight();

    setNodeClicked(selectedNode || null);
    forceRef.current.centerAt(selectedNode.x, selectedNode.y, 10);
    forceRef.current.zoom(1, 20);

    if (selectedNode.id.length < 3) {
      handleDomainNodeClick(selectedNode);
    } else if (selectedNode.id.length >= 3) {
      handleMitigationNodeClick(selectedNode);
    } else {
      handleOtherNodeClick();
    }

    updateHighlight();
  }

  //surround the selected node with related nodes
  function updatePosition(node) {
    let tempLinks = new Set();
    let maxNodes = 12;
    let radius = 2 * maxNodes;

    //update the position of connected nodes
    Array.from(highlightLinks).map((link, index) => {
      let multi = index + 1 <= maxNodes ? 1 : Math.ceil(index / maxNodes);
      if (node.id.startsWith('RA')) {
        link.target.fx = (node.x + (radius * maxNodes * multi) * Math.sin(2 * Math.PI * index / maxNodes));
        link.target.fy = (node.y + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        link.target.fz = (node.z + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
      } else {
        link.source.fx = (node.x + (radius * maxNodes * multi) * Math.sin(2 * Math.PI * index / maxNodes));
        link.source.fy = (node.y + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        link.source.fz = (node.z + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
      }
      tempLinks.add(link);
    });
    setActiveLinks(tempLinks);
  };

  const updateHighlight = () => {
    setActiveNodes(highlightNodes);
    setActiveLinks(highlightLinks);
  };

  //to handle domain node Click for highlighting of nodes
  const handleDomainNodeClick = (selectedNode) => {
    graph.links.forEach((linknode) => {
      if (linknode.source.id === selectedNode.id) {
        handleDomainLink(linknode);
      } else {
        handleNonDomainLink(linknode);
      }
    });
  }

  //to handle mitigation node Click for highlighting of nodes
  const handleMitigationNodeClick = (selectedNode) => {
    graph.links.forEach((linknode) => {
      if (linknode.target.id === selectedNode.id) {
        handleMitigationLink(linknode);
      } else {
        handleNonMitigationLink(linknode);
      }
    });
  }

  //to handle any other node Click for highlighting of nodes
  const handleOtherNodeClick = () => {
    graph.nodes.forEach((graphnode) => {
      graphnode.color = grey;
    });
  }

  //to highlight edges associated with selected domain node
  const handleDomainLink = (linknode) => {
    linknode.target.color = mitHighlighColor;
    linknode.source.color = demainDefaultColor;
    linknode.color = edgeHighlightColor;
    forceRef.current.emitParticle(linknode);
    highlightLinks.add(linknode);
  }

  //to greyout edges not associated with selected domain node
  const handleNonDomainLink = (linknode) => {
    linknode.source.color = grey;
    if (linknode.target.color === mitigationDefaultColor) {
      linknode.target.color = grey;
    }
    linknode.color = grey;
  }

  //to highlight edges associated with selected mitigation node
  const handleMitigationLink = (linknode) => {
    linknode.target.color = mitHighlighColor;
    linknode.source.color = green1;
    linknode.color = edgeHighlightColor;
    forceRef.current.emitParticle(linknode);
    highlightLinks.add(linknode);
  }
  //to greyout edges not associated with selected mitigation node
  const handleNonMitigationLink = (linknode) => {
    if (!linknode.source.color === green1) {
      linknode.source.color = grey;
    }
    if (linknode.source.color === demainDefaultColor) {
      linknode.source.color = grey;
    }
    linknode.target.color = grey;
    linknode.color = grey;
  }

  //reset the nodes back to default mode 
  const resetHighLight = () => {
    if (nodeClicked != null || resetFilterHighlight) {
      setNodeClicked(null);
      forceRef.current.zoom(0.1, 10);
      highlightLinks.clear();
      graph.nodes.forEach((graphnode) => {
        if (graphnode.id.length < 3) {
          graphnode.color = demainDefaultColor;//set domain nodes back to default
        }
        else {
          graphnode.color = mitigationDefaultColor; //set mitigation nodes back to default
        }
      })

      graph.links.forEach((linknode) => {
        linknode.color = edgeDefaultColor; //set edges back to default color
      })
      setGraph({ ...graph });
    }
  }

  //to shown option to view node details
  const onNodeRightClick = (node, event) => {
    if (node) {
      setShowPopup(true);
      setTop(event.pageY);
      setLeft(event.pageX);
      setSelectedNode(node);
    }
  }

  //to handle node details
  const onClickViewDetails = () => {
    setShowLoader("block");
    setShowPopup(false);

    let authToken = keycloak.token;

    let apiHeaders = new Headers();
    apiHeaders.append("Content-Type", "application/json");
    apiHeaders.append("Authorization", "Bearer " + authToken);

    if (selectedNode.id.startsWith("m")) {
      callMitigationDetailsApi(apiHeaders);
    }
    else {
      callDomainDetailsApi(apiHeaders);
    }
  }

  //to handle mitigation details
  const callMitigationDetailsApi = (apiHeaders) => {

    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_17F4956C-9B2A-4DD7-A83C-A39BFBA3B527",
      "model-name": "MitigationList",
      "dmn-context": {
        "mitigationID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      url: baseURL,
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };
    //Api call to get mitigation details for selected mitigation     
    fetchInstance.apiRequest(requestOptions)
      .then(response => response.json())
      .then(data => {
        setShowLoader("none");
        setShowModal("block");
        const details = data.result["dmn-evaluation-result"]["dmn-context"].mitigation;
        let temp = [];
        if (details !== null) {
          temp.push({
            "Name": (details.name === undefined || details.name === null) ? "" : details.name,
            "Domain ID": (details.domainID === undefined || details.domainID === null) ? "" : details.domainID,
            "description": (details.description === undefined || details.description === null) ? "" : details.description,
          })
          setRiskDetais(temp);
          setRiskTitle(selectedNode.id);
          setOpen(true);
        }
        else {
          setAlertMessgae(mitigation_details_error)
          setOpenAlertModal(true);
        }

      })
      .catch(error => {
        setShowLoader("none");
        console.log('error', error)
        setAlertMessgae(apiCall_error)
        setOpenAlertModal(true);
      });
  }

  //to show domain node details
  const callDomainDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_9F15CAC4-9633-4657-A4B8-49B0ACA4ADBC",
      "model-name": "DomainDetail",
      "dmn-context": {
        "domainID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      url: baseURL,
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };
    //Api call to get domain details for selected domain     
    fetchInstance.apiRequest(requestOptions)
      .then(response => response.json())
      .then(data => {
        setShowLoader("none");
        setShowModal("block");
        const details = data.result["dmn-evaluation-result"]["dmn-context"].detail;
        let temp = [];
        if (details !== null) {
          temp.push({
            "Name": (details.name === undefined || details.name === null) ? "" : details.name,
            "Score": (details.score === undefined || details.score === null) ? "" : details.score,
          })
          setRiskDetais(temp);
          setRiskTitle(selectedNode.id);
          setOpen(true);
        }
        else {
          setAlertMessgae(domain_details_error)
          setOpenAlertModal(true);
        }

      })
      .catch(error => {
        setShowLoader("none");
        console.log('error', error)
        setAlertMessgae(apiCall_error)
        setOpenAlertModal(true);
      });
  }

  //to handle filter operation
  function handleFilter(e) {
    setFilter(e.target.value);
    //reset to initial state before setting filter highlight
    setResetFilterHighlight(true);
    resetHighLight();

    if (e.target.value === "non-compliant") {
      nonCompliantDomainFilterHandler(e);
    } else if (e.target.value === "compliant") {
      compliantDomainFilterHandler(e)
    } else {
      resetFilterHandler();
    }
  }

  //to filter non complaint domain 
  const nonCompliantDomainFilterHandler = (e) => {
    const serverListCopy = [...serverList];
    const nonCompliantDomain = serverListCopy.filter((ele) => {
      return ele.domainScore < 3;
    })
    if (nonCompliantDomain.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      highlightFilteredList(nonCompliantDomain);
    }
  }

  //to filter complaint domain 
  const compliantDomainFilterHandler = (e) => {
    const serverListCopy = [...serverList];

    const compliantDomain = serverListCopy.filter((ele) => {
      return ele.domainScore >= 3;
    })
    if (compliantDomain.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      highlightFilteredList(compliantDomain);
    }
  }
  //to highlight domains based on complaint filter 
  const highlightFilteredList = (list) => {
    list.forEach((list) => {
      graph.links.forEach((linknode) => {
        if (linknode.source.id === list.domainID) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = green1;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
        }
        else {
          if (linknode.source.color === demainDefaultColor)
            linknode.source.color = grey;
          if (linknode.target.color === mitigationDefaultColor)
            linknode.target.color = grey;
          if (!linknode.color == edgeHighlightColor)
            linknode.color = grey;
        }
      })
    })
  }

  //to set the graph back to default mode
  function resetFilterHandler() {
    const serverListCopy = [...serverList];
    nodeCreator(serverListCopy);
    edgeCreator(serverListCopy);
  }

  const handleModalClose = () => {
    setOpen(false);
    setShowPopup(false);
    setShowModal("none");
  };



  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
    setAlertMessgae("");
  };

  return (
    <>
      <CircleLoader style={{ display: showLoader }} />
      <Grid container sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, paddingTop: { xs: 4, sm: 'unset' } }}>
        <Grid item xs={12} sm={8} md={9} lg={10} id="graph-container">
          <ThemeProvider theme={theme}>
            <Card sx={{ margin: 0 }} elevation={0} variant="outlined">
              <CustomizedCardContent style={{ minHeight: 100, padding: 0 }}>
                <ForceGraph2D
                  backgroundColor="transparent"
                  ref={forceRef}
                  graphData={graph}
                  // width={window.innerWidth - 30}
                  // height={window.innerHeight - 150}
                  {...document.querySelector('#graph-container')?.clientWidth > 0 && { width: document.querySelector('#graph-container').clientWidth }}
                  {...document.querySelector('#graph-box')?.clientHeight > 0 && { height: document.querySelector('#graph-box').clientHeight - (isSmallScreen ? 96 : 2) }}
                  cooldownTime={0}
                  warmupTicks={200}
                  nodeCanvasObjectMode={() => "after"}
                  // onEngineStop={() => forceRef.current.zoom(0.1, 10)}
                  nodeLabel={"label"}
                  onNodeClick={handleNodeClick}
                  onBackgroundClick={resetFilterHandler}
                  onNodeRightClick={onNodeRightClick}
                  linkColor={(link) => linkColor}
                  linkDirectionalParticleColor={() => "red"}
                  linkWidth={link => highlightLinks.has(link) ? 3 : 1}
                  linkDirectionalParticles={2}
                  linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 4 : 0}
                  linkDirectionalArrowLength={3.5}
                  linkDirectionalArrowRelPos={1}
                  linkCurvature={0.35}
                  linkOpacity={0.2}
                  onNodeDragEnd={node => {
                    node.fx = node.x;
                    node.fy = node.y;
                    node.fz = node.z;
                  }}
                  nodeVisibility={(node) => node.id !== "0"}
                  minZoom={0.01}
                  maxZoom={2}
                />
              </CustomizedCardContent>
            </Card>
          </ThemeProvider>
        </Grid>
        <Divider />
        <Grid item sx={{ padding: 2 }} xs={12} sm={4} md={3} lg={2}>
          <Grid container spacing={2}>
            {
              props.GraphPicker && <Grid item xs={12}>
                {props.GraphPicker}
              </Grid>
            }
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Filter</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={filter}
                  label="Filter"
                  onChange={handleFilter}
                >
                  <MenuItem value={"non-compliant"}>Non-compliant domains</MenuItem>
                  <MenuItem value={"compliant"}>compliant domains</MenuItem>
                  <MenuItem value={"reset"}>Reset filter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LegendPage />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* start of Popover to show option on node right click  */}
      <Popover
        id={'simple-popover'}
        open={showPopup}
        anchorReference="anchorPosition"
        anchorPosition={{ top: top, left: left }}
        onClose={() => { setShowPopup(false); }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <List>
          <ListItem onClick={onClickViewDetails}>View Details</ListItem>
        </List>
      </Popover>
      {/******** end of Popover to show option on node right click  ********/}

      {/* start of dialog show details of selected node  */}
      <CustomDialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={open}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleModalClose}>
          {"Details of " + riskTitle}
        </CustomDialogTitle>
        <DialogContent dividers>
          <Typography>
            <div style={{ display: showModal }}>
              <Grid container spacing={1} >
                {riskDetais.length > 0 ?
                  Object.keys(riskDetais[0]).map((ele) =>
                    <Grid item xs={12} sm={4} md={4} key={ele}>
                      <Card shadow={4} style={{ minWidth: '450', margin: 'auto' }}>
                        <CustomizedCardHeader title={ele} style={{ textAlign: 'center', padding: 0 }} />
                        <CardContent sx={{ minHeight: 100, textAlign: 'center' }} >
                          {riskDetais[0][ele]}
                        </CardContent>
                      </Card>
                    </Grid>

                  )
                  : ""}
              </Grid>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions />
      </CustomDialog>
      {/******** end of dialog show details of selected node  ********/}

      {/* start of common alert dialog */}
      <CustomDialog
        open={openAlertModal}
        onClose={handleCloseAlertModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" paddingLeft={2} paddingRight={5}>
            {alertMessgae}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertModal} style={{ color: "#46a11b" }} autoFocus>
            OK</Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default DomainGraphPage;