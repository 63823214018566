import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Chip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const AssetDetailDialog = (props) => {

  const { open, toggle, data, handleEditClick } = props;

  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (open && data) {
      setDialogTitle(data["assetName"]);
    }
  }, [open, data])

  return (
    <Dialog
      open={open}
      onClose={toggle}
      scroll="paper"
    >
      <DialogTitle sx={{ paddingRight: "80px" }}>{dialogTitle}</DialogTitle>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <IconButton
          title="edit"
          aria-label="edit"
          onClick={(e) => {
            handleEditClick(data);
            toggle(e);
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="close"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>

        {/* Display the details of the selected row here */}
        {
          data && <Grid container spacing={{ xs: 4, md: 4 }} >
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Asset Category
              </Typography>
              <Chip label={data.assetCategory}></Chip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Asset Description
              </Typography>
              <Typography>
                {data.assetdescription}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Processing
              </Typography>
              <Typography>
                {data.processing}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Storing
              </Typography>
              <Typography>
                {data.storing}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Consuming
              </Typography>
              <Typography>
                {data.consuming}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Risk Owner
              </Typography>
              <Typography>
                {data.riskOwner}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Asset Custodian
              </Typography>
              <Typography>
                {data.assetCustodian}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Asset Owner
              </Typography>
              <Typography>
                {data.assetOwner}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Original Acquisition Date
              </Typography>
              <Typography>
                {data.originalAcquisitionDate}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Asset Constraints
              </Typography>
              <Typography>
                {data.assetConstraints}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Information Exchange Capability
              </Typography>
              <Typography>
                {data.informationExchangeCapability}
              </Typography>
            </Grid>
          </Grid>
        }
      </DialogContent>
    </Dialog>
  );
};

export default AssetDetailDialog;