import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Alert, Box, Grid, ListItem, Tab } from "@mui/material";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import ModalImageSrc from "../../../../images/reccomendationImg.png";
import { CONTAINER_ID, HealthCheckProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import RecommendationItem from "../../components/RecommendationItem/RecommendationItem";
import { CenterHeadingContainer } from "../RiskMatrix/RiskMatrix.styled";
import { BoxContainer, BtnsContainer, CenteredDiv, ContentContainer, StyledBox, StyledComplateContainer, StyledInformationIcon, StyledText, TabsContainer, ActionsWrapper } from "./Recommendations.styled";
import { format } from 'date-fns'
import Lottie from "lottie-react";
import doneAnimation from "../../../../assets/animations/done.json";
import underMaintenanceAnimation from "../../../../assets/animations/under-maintenance.json";
import sorryAnimation from "../../../../assets/animations/sorry.json";
import { useKeycloak } from "@react-keycloak/web";
import { getActiveHealthCheck, getCompletedHealthCheck, getProcessVariables, updateProcessVariables } from "../../InformationAPI";
import PdfTest from "../PdfTest/PdfTest";
import logo from "../../../../images/logo0.2.png";
import { RecommendationsTextBlock } from "../Information/Information.styled";
import dayjs from 'dayjs';
import * as fetchInstance from '../../../../utility/fetch-instance';
import { Helmet } from 'react-helmet-async';
import { updateTrackerData } from '../../../../utility/tracker.action';
import { useDispatch } from 'react-redux';
import { ReportTypes } from '../../../../shared/constants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <BoxContainer value={value === 0 ? "true" : ""}>{children}</BoxContainer>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Recommendations = (props) => {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [header, setHeader] = useState(null);

    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);

    const history = useHistory();

    const [healthCheckAnswers, setHealthCheckAnswers] = useState([]);
    const [reasons, setReasons] = useState([]);

    const [healthCheckNotCompleted, setHealthCheckNotCompleted] = useState(null);

    const [noRecommendations, setNoRecommendations] = useState(false);
    const [recommendations, setRecommendations] = useState(null);
    const [recommendationsReasonsSubmitted, setRecommendationsReasonsSubmitted] = useState(false);

    const [presentationTask, setPresentationTask] = useState(null);
    const [presentationTaskSubmitted, setPresentationTaskSubmitted] = useState(false);

    const [taskInstanceId, setTaskInstanceId] = useState("");
    const [taskOwner, setTaskOwner] = useState(null);
    const [isViewOnly, setViewOnly] = useState(false);
    const [currentAnsArray, setCurrentAnsArray] = useState(null);

    const [fetchingAnswers, setFetchingAnswers] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [gettingTask, setGettingTask] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [value, setValue] = useState(0);
    let newDate = new Date;
    const [firstDate, setFirstDate] = useState(dayjs(newDate.toDateString()));

    const [openModal, setOpenModal] = useState(false);

    const [firstDateIsTouched, setFirstDateIsTouched] = useState(false);

    const [buttonEnabled, setButtonEnabled] = useState(true);

    const firstDateIsInvalid = firstDateIsTouched && firstDate === "";

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Recommendations',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const handleChange = (event, newValue) => {
        if (isViewOnly) return;
        if (recommendationsReasonsSubmitted === false) {
            toast.error("Required to finish all the recommendations!");
            return;
        }
        setValue(newValue);
    };

    const handleOptionChange = useCallback((value, id) => {
        const clonedRecommendations = [...recommendations];
        let editedItemIndex = clonedRecommendations.findIndex(item => item.id === id)
        clonedRecommendations[editedItemIndex].isAccepted = value;
        if (value === 'true') {
            clonedRecommendations[editedItemIndex].reason = ''
        }
        setRecommendations(clonedRecommendations);
        updateData(recommendations[editedItemIndex]);
    }, [recommendations])

    const handleReasonsChange = useCallback((value, id) => {
        const clonedRecommendations = [...recommendations];
        let editedItemIndex = clonedRecommendations.findIndex(item => item.id === id)
        clonedRecommendations[editedItemIndex].reason = value;
        setRecommendations(clonedRecommendations);
        updateData(recommendations[editedItemIndex]);
    }, [recommendations])

    function updateData(item) {

        let payloadObj = {}
        payloadObj[`Reason${item.id}`] = item.reason === "" ? 'accepted' : item.reason;
        updateProcessVariables(activeHealthCheckID, kcToken, payloadObj).then((response) => {
            //process updated
        });
    }

    useEffect(() => {
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    //get token from keycloak
    useEffect(() => {
        setFetching(true);
        if (keycloak?.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
            });
        }
    }, [keycloak]);

    useEffect(() => {
        if (taskOwner === null) return;

        if (taskOwner === email) {
            setViewOnly(false);
        } else {
            setViewOnly(true);
        }
    }, [taskOwner])

    // useEffect(() => {
    //     if (activeHealthCheckID === null) return;
    //     getProcessVariables(activeHealthCheckID, kcToken).then((response) => {
    //         console.log(response.data.filter(element => element.name.startsWith("Reason")));
    //     });
    // }, activeHealthCheckID);

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "" || kcToken === null || email === null) return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID]);

    function getAllInstance(_email, _kcToken) {
        if (!_email || !_kcToken) {
            return;
        }

        setHeader({ headers: { "Authorization": `Bearer ${_kcToken}`, 'Content-Type': 'application/json' } });

        setFetching(true);

        getActiveHealthCheck(_kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });

        getCompletedHealthCheck(_kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });
    }

    useEffect(() => {
        if (recommendations === null) return;
        const isFound = recommendations.find(item => item.isAccepted === '');
        if (isFound) {
            setButtonEnabled(true);
        } else {
            const isFound2 = recommendations.find(item => item.isAccepted === 'false' && (item.reason === '' || item.reason === null));
            if (isFound2) {
                setButtonEnabled(true);
            } else {
                setButtonEnabled(false);
            }
        }
    }, [recommendations])

    const firstDateBlurHandler = () => {
        setFirstDateIsTouched(true);
    };

    const submitFormHandler = (e) => {
        e.preventDefault();
        setValue(0)
        setFirstDate(new Date());
        setFirstDateIsTouched(false);
    };

    const getHealthCheckAnsweredQuestions = useCallback(async (processInstance, _header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstance}/variables/instances`,
                ..._header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.data['variable-instance'].length === 0) {
                    setHealthCheckNotCompleted(true)
                }
                const filteredData = res.data['variable-instance'].filter(item => item.name.startsWith('SC') && item.name.endsWith('a1'))
                const formattedData = filteredData.map((item, index) => {
                    const questionNum = index + 1;
                    const questionKey = `SC${questionNum}`
                    let obj = {}
                    obj[questionKey] = item.value
                    return obj
                })
                const filteredReason = res.data['variable-instance'].filter(item => item.name.startsWith('Reason'));
                setReasons(filteredReason);
                setHealthCheckAnswers(formattedData);
                setFetchingAnswers(false);
            });
        } catch (err) {
            setFetchingAnswers(false);
            toast.error(err.message);
        }
    }, [])

    useEffect(() => {

        if (activeHealthCheckID === null || header === null || completedHealthCheckID === null) {
            return;
        };

        if (presentationTaskSubmitted === true) return;

        if (activeHealthCheckID > 0) {
            setFetchingAnswers(true);
            setHealthCheckAnswers([]);
            getHealthCheckAnsweredQuestions(activeHealthCheckID, header);
            return;
        };

        //show completed recommendations
        if (completedHealthCheckID > 0 && activeHealthCheckID === 0) {
            setFetchingAnswers(true);
            setHealthCheckAnswers([]);
            getHealthCheckAnsweredQuestions(completedHealthCheckID, header);
            return;
        };

        setFetching(false);

    }, [activeHealthCheckID, completedHealthCheckID, getHealthCheckAnsweredQuestions, header, presentationTaskSubmitted]);

    useEffect(() => {

        if (activeHealthCheckID === null || presentationTask === null) {
            return;
        };

        if (presentationTaskSubmitted === true) return;
        if (!presentationTask) {
            setFetchingAnswers(true);
            getHealthCheckAnsweredQuestions(completedHealthCheckID, header);
            return;
        }

        setFetching(false);

    }, [presentationTask, activeHealthCheckID]);

    const retieveReccommendations = useCallback(async (_header) => {
        if (healthCheckAnswers.length === 0) return;
        try {
            let payloadObj = {}
            healthCheckAnswers.forEach((item, index) => {
                payloadObj[`answerSC${index + 1}`] = item[`SC${index + 1}`]
            });
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/dmn`,
                data: JSON.stringify({
                    "model-namespace": "https://kiegroup.org/dmn/_59F8E423-003F-47FD-A331-6E6C306126E1",
                    "model-name": "recommendations",
                    "dmn-context": payloadObj
                }),
                ..._header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                setFetching(false);
                if (!res.data['result']['dmn-evaluation-result']['dmn-context']['Recommendations']) {
                    setNoRecommendations(true);
                    return;
                }
                const recommendationsList = res.data['result']['dmn-evaluation-result']['dmn-context']['Recommendations'].filter(i => i !== null).map((item, index) => {
                    const userReason = reasons.find(({ name }) => name === `Reason${item['control-number']}`)?.["value"] ?? null;

                    return {
                        id: item['control-number'],
                        value: item['recommendation'],
                        helpText: item['reason'],
                        reason: userReason,
                        isAccepted: userReason === null ? "" : userReason === "accepted" || userReason === "" ? "true" : "false"
                    }
                })
                setRecommendations(recommendationsList)
            });
        } catch (err) {
            setFetching(false);
            toast.error(err.message);
        }
    }, [healthCheckAnswers])

    useEffect(() => {
        if (presentationTaskSubmitted === null || activeHealthCheckID === null || healthCheckAnswers.length === 0 || header === null) return;
        if (presentationTaskSubmitted === true) return;
        setFetching(true);
        retieveReccommendations(header);
    }, [healthCheckAnswers, activeHealthCheckID, retieveReccommendations, header, presentationTaskSubmitted])

    const getHealthCheckQuestions = useCallback(async (taskInstId, taskName, _header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/contents/input`,
                ..._header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const str = res.data["NodeName"];
                setGettingTask(false);
                if (str === "Presentation Date") {
                    setPresentationTask(true);
                    setHealthCheckNotCompleted(false);
                    return;
                } else {
                    setActiveHealthCheckID(0);
                }
            });
        } catch (err) {
            setGettingTask(false);
            toast.error(err.message);
        }
    }, []);

    const getHealthCheckTask = useCallback(
        async (healthInstance, _header) => {

            try {
                let config = {
                    method: 'get',
                    url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${healthInstance}`,
                    ..._header,
                }
                await fetchInstance.apiRequest(config).then((res) => {
                    if (res.data["active-user-tasks"]["task-summary"].length === 0) {
                        setPresentationTaskSubmitted(true);
                        return;
                    }
                    const taskInstId = res.data["active-user-tasks"]["task-summary"][0]["task-id"];
                    const taskIName = res.data["active-user-tasks"]["task-summary"][0]["task-name"];
                    const taskIOwner = res.data["active-user-tasks"]["task-summary"][0]["task-actual-owner"];
                    if (taskIOwner !== "") {
                        setTaskOwner(taskIOwner);
                    }

                    setTaskInstanceId(taskInstId);
                    getHealthCheckQuestions(taskInstId, taskIName, _header);
                    setGettingTask(false);

                    if (res.data["active-user-tasks"]["task-summary"][0]["task-status"] !== "InProgress") {
                        let config2 = {
                            method: 'put',
                            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/states/started`,
                            data: JSON.stringify({}),
                            ..._header
                        }
                        fetchInstance.apiRequest(config2);
                    }
                });
            } catch (error) {
                toast.error("Something went wrong, Please try again");
                setGettingTask(false);
            }
        },
        [getHealthCheckQuestions]
    );

    useEffect(() => {

        if (presentationTaskSubmitted === null || activeHealthCheckID === null || header === null) return;
        if (presentationTaskSubmitted) return;

        if (activeHealthCheckID > 0) {
            setGettingTask(true);
            getHealthCheckTask(activeHealthCheckID, header);
            return;
        }
    }, [getHealthCheckTask, activeHealthCheckID, header, presentationTaskSubmitted]);

    const submitRecommendationsReasons = useCallback(async () => {
        if (header === null) return;
        try {
            let payloadObj = {}
            recommendations.forEach((item, index) => {

                if (item.reason !== '') {
                    const objKey = `Reason${item.id}`
                    payloadObj[objKey] = item.reason
                }
            })
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${activeHealthCheckID}/variables`,
                data: JSON.stringify(payloadObj),
                ...header
            }
            fetchInstance.apiRequest(config).then((res) => {
                setSubmitting(false);
                setRecommendationsReasonsSubmitted(true);
                setValue(1);
            });
        } catch (err) {
            setSubmitting(false);
            toast.error(err.message);
        }
    }, [activeHealthCheckID, recommendations, header])

    const handleSubmitPresentation = useCallback(async () => {
        if (header === null) return;
        try {
            let config = {
                method: 'put',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
                data: JSON.stringify({
                    "Presentation-Date": dayjs(firstDate).format('DD/MM/YYYY')
                }),
                ...header
            }
            fetchInstance.apiRequest(config)
                .then((res) => {
                    setSubmitting(false);
                    setPresentationTaskSubmitted(true);
                })

        } catch (err) {
            setFetching(false);
            toast.error(err.message);
        }
    }, [firstDate, getHealthCheckTask, activeHealthCheckID, taskInstanceId, header])

    const submitButtonHandler = () => {
        setSubmitting(true);
        if (value === 0) {
            submitRecommendationsReasons();
        } else if (value === 1) {
            handleSubmitPresentation();
        }
    }

    if (presentationTaskSubmitted) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> You have completed the Recommendations.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/overview")}>
                            Back To Dashboard
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    function handlePopUp() {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to complete Cyber Security Health Check!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/health-check")}>
                            Back To Health Check
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        )
    }

    // if (noRecommendations) {
    //     return (
    //         <StyledComplateContainer>
    //             <Box sx={{ mb: 4 }} >
    //                 <Lottie loop animationData={underMaintenanceAnimation} />
    //             </Box>
    //             <h1>Recommendations is Curretly on Development</h1>
    //             <div>
    //                 <Button $success upper="true" onClick={() => history.push("/dashboard/information")}>
    //                     Back To Dashboard
    //                 </Button>
    //             </div>
    //         </StyledComplateContainer>
    //     )
    // }


    let recommendationsContent;

    if (fetching) {
        recommendationsContent = <Loader />;
    } {
        if (healthCheckNotCompleted === true) {
            return handlePopUp();
        }

        if (activeHealthCheckID > 0 && completedHealthCheckID === 0) {
            if (healthCheckNotCompleted) {
                return handlePopUp();
            }
        }

        if (activeHealthCheckID === 0 && completedHealthCheckID === 0) {
            return handlePopUp();
        }
    }


    if (recommendations !== null) {
        if (recommendations.length === 0) {
            recommendationsContent = (<ListItem><RecommendationsTextBlock><Alert severity="info">There's no recommendations for now!</Alert></RecommendationsTextBlock></ListItem>);
        } else {
            recommendationsContent = (
                recommendations.map((item, index) => {
                    return <RecommendationItem
                        key={index}
                        index={index + 1}
                        number={item.id}
                        heading={item.value}
                        reason={item.reason}
                        helpText={item.helpText}
                        handleReasonsChange={handleReasonsChange}
                        isCompleted={!presentationTask}
                        isAccepted={item.isAccepted}
                        handleOptionChange={handleOptionChange}
                        recommendations={recommendations}
                        readOnly={isViewOnly}
                    />
                })
            )
        }
    }

    if (!fetching && recommendations === null) {
        recommendationsContent = (<ListItem><RecommendationsTextBlock><Alert severity="info">There's no recommendations for now!</Alert></RecommendationsTextBlock></ListItem>);
    }

    if (recommendationsReasonsSubmitted) {
        recommendationsContent = (
            <StyledComplateContainer style={{ boxShadow: 'none' }}>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> Now that you have successfully processed the recommendations please proceed to organize the date on which you present the Recommendations to your Senior Management.</p>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        )
    }

    let presentationContent;

    if (gettingTask) {
        presentationContent = <Loader />;
    }

    if (presentationTask && recommendationsReasonsSubmitted) {
        presentationContent = (
            <Fragment>
                <StyledText maxWidth={true}>When do you plan to present the recommendations that you have accepted to your senior stakeholders in the business ?</StyledText>
                <CenteredDiv>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker
                            disablePast
                            displayStaticWrapperAs="desktop"
                            value={firstDate}
                            onChange={(newValue) => {
                                setFirstDate(newValue);
                            }}
                        // renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </CenteredDiv>
            </Fragment>
        )
    } else {
        presentationContent = (
            <Fragment>
                <StyledText maxWidth={true}>Please finish the recommendations. You will be able to choose date to present the recommendations that you have accepted to your senior stakeholders in the business.</StyledText>
                <CenteredDiv>
                </CenteredDiv>
            </Fragment>
        );
    }

    var pdfTest;
    if (kcToken !== null && email !== null) {
        //uncomment to show pdf donwnload button
        pdfTest = (<PdfTest kcToken={keycloak.token} email={email}></PdfTest>);
    }

    let content;

    if (gettingTask || fetching || fetchingAnswers) {
        content = <Loader height='70vh' />;
    }

    if (recommendations !== null && presentationTask && !fetching) {
        content = (
            <>
                <form onSubmit={submitFormHandler}>
                    <ContentContainer>
                        <StyledBox>
                            <TabsContainer
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                allowScrollButtonsMobile
                                scrollButtons="auto"
                                error2={firstDateIsInvalid ? "true" : ""}
                            >
                                <Tab label="Recommendations" {...a11yProps(0)} />
                                <Tab onBlur={firstDateBlurHandler} label="Presentation Date" {...a11yProps(1)} disabled={!recommendationsReasonsSubmitted} />
                            </TabsContainer>
                        </StyledBox>
                        <TabPanel value={value} index={0}>
                            {recommendationsContent}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {presentationContent}
                        </TabPanel>
                    </ContentContainer>
                    {
                        isViewOnly ? "" : <ActionsWrapper hasOne>
                            <BtnsContainer value={value === 2 ? "true" : ""}>
                                <Button $success onClick={submitButtonHandler} type="submit" loading={submitting} disabled={buttonEnabled}>
                                    {value === 1 ? "Submit" : "Next"}
                                </Button>
                            </BtnsContainer>
                        </ActionsWrapper>
                    }

                </form>
            </>
        )
    }

    if (recommendations !== null && !presentationTask && !fetching) {
        content = (
            <>
                <ContentContainer>
                    <StyledBox>
                        <TabsContainer
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            allowScrollButtonsMobile
                            scrollButtons="auto"
                            error2={firstDateIsInvalid ? "true" : ""}
                        >

                        </TabsContainer>
                    </StyledBox>
                    <TabPanel value={value} index={0}>
                        {recommendationsContent}
                    </TabPanel>
                </ContentContainer>
                <ActionsWrapper hasOne>
                    <BtnsContainer>
                        <Button
                            $success
                            onClick={() => {
                                history.push({
                                    pathname: '/it-security/reports',
                                    state: {
                                        type: ReportTypes.HEALTH_CHECK,
                                    }
                                });
                            }}
                        >
                            Download Report
                        </Button>
                        {/* {pdfTest} */}
                    </BtnsContainer>
                </ActionsWrapper>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Recommendations - CisoZen</title>
            </Helmet>
            <Fragment>
                <CenterHeadingContainer>
                    <Heading marginMd="32px 0" marginSm="24px 0 18px">
                        Recommendations
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                </CenterHeadingContainer>
                {content}
                <InfoModal
                    open={openModal}
                    handleClose={handleCloseModal}
                    imgSrc={ModalImageSrc}
                    title="Points will be awarded for presenting the recommendations to the senior executives in your organization. This will help you secure senior management support for securing your organisation. It will also help with requesting budget for enhancing your security. Please note you will not be able to modify your responses to the recommendations after submitting the presentation date."
                    list={["Maximum time you should spend on this recommendations activity : 1 Weeks"]}
                />
            </Fragment>
        </>
    );
};

export default Recommendations;
