import { Box, Grid, Hidden } from "@mui/material";
import Lottie from "lottie-react";
import React, { Component, Fragment, useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";


import Heading from "../../../../components/UI/Heading/Heading";

import logo from '../../../../images/sidebar-logo.png';
import logo2 from '../../../../images/Logo.png';
import pdfBackground from '../../../../images/pdf-background.PNG';
import { renderToString } from 'react-dom/server';
import { Cookies } from "../../../../shared/utility";
import { InlineWidget } from "react-calendly";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./Help.styled";

function Help(props) {

    const [isOpen, setIsOpen] = useState(false);
    return (
        <Wrapper>
            <Heading marginMd="32px 0" marginSm="24px 18px">
                Book an appointment to chat with us
            </Heading>
            <InlineWidget styles={{height:"650px"}} pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: '00a2ff',
                textColor: '4d5055'
            }}
                url="https://calendly.com/cisozen/support"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
            />
        </Wrapper>
    );
}

export default Help;
