import ExcelJS from "@nbelyh/exceljs";
import dayjs from 'dayjs';
import { beautifyUniqueID } from "./utility";


let table = [
  {
    "name": "ID",
    "colId": "0",
    "id": 1,
    "key": "threatID",
  },
  {
    "name": "Title",
    "colId": "1",
    "id": 2,
    "key": "title",
  },
  {
    "name": "Description",
    "colId": "2",
    "id": 3,
    "key": "description",
  },
  {
    "name": "Element",
    "colId": "3",
    "id": 4,
    "key": "",
  },
  {
    "name": "S",
    "colId": "4",
    "id": 5,
    "key": "threatCategory",
    "value": "Spoofing"
  },
  {
    "name": "T",
    "colId": "5",
    "id": 6,
    "key": "threatCategory",
    "value": "Tampering"
  },
  {
    "name": "R",
    "colId": "6",
    "id": 7,
    "key": "threatCategory",
    "value": "Repudiation"
  },
  {
    "name": "I",
    "colId": "7",
    "id": 8,
    "key": "threatCategory",
    "value": "Information Disclosure"
  },
  {
    "name": "D",
    "colId": "8",
    "id": 9,
    "key": "threatCategory",
    "value": "Denial of Service"
  },
  {
    "name": "E",
    "colId": "9",
    "id": 10,
    "key": "threatCategory",
    "value": "Elevation of Privilege"
  },
  {
    "name": "CWE",
    "colId": "10",
    "id": 11,
    "key": "attackVectors",
    "value": "CWE",
  },
  {
    "name": "CAPEC",
    "colId": "11",
    "id": 12,
    "key": "attackVectors",
    "value": "CAPEC",
  },
  {
    "name": "OWASP Top Ten",
    "colId": "12",
    "id": 13,
    "key": "attackVectors",
    "value": "OWASP Top Ten",
  },
  {
    "name": "CVE",
    "colId": "13",
    "id": 14,
    "key": "attackVectors",
    "value": "CVE",
  },
  {
    "name": "Risk Category (CIA)",
    "colId": "14",
    "id": 15,
    "key": "riskCategory"
  },
  {
    "name": "Current Controls",
    "colId": "15",
    "id": 16,
    "key": "currentControls",
  },
  {
    "name": "Priority",
    "colId": "16",
    "id": 17,
    "key": "priority",
  },
  {
    "name": "Reference",
    "colId": "17",
    "id": 18,
    "key": "reference",
  },
  {
    "name": "Recommended countermeasures",
    "colId": "18",
    "id": 19,
    "key": "counterMeasureMapping",
    "value": "title",
  },
  {
    "name": "Status",
    "colId": "19",
    "id": 20,
    "key": "counterMeasureMapping",
    "value": "status",
  },
  {
    "name": "IS Team Notes",
    "colId": "20",
    "id": 21,
    "key": "informationSecurityTeamNote",
  },
  {
    "name": "Evidence/Status to be completed by Implementation Team",
    "colId": "21",
    "id": 22,
    "key": "counterMeasureMapping",
    "value": "implementationEvidence"
  },
  {
    "name": "Compensating controls",
    "colId": "22",
    "id": 23,
    "key": "compensatingControls",
    "value": "title"
  },
  {
    "name": "Implementation Team Notes",
    "colId": "23",
    "id": 24,
    "key": "implementationTeamNote",
  }
]

let tableTechnology = [
  {
    "name": "ID",
    "colId": "0",
    "id": 1,
    "key": "threatID",
  },
  {
    "name": "Title",
    "colId": "1",
    "id": 2,
    "key": "title",
  },
  {
    "name": "Description",
    "colId": "2",
    "id": 3,
    "key": "description",
  },
  {
    "name": "Element",
    "colId": "3",
    "id": 4,
    "key": "",
  },
  {
    "name": "S",
    "colId": "4",
    "id": 5,
    "key": "threatCategory",
    "value": "Spoofing"
  },
  {
    "name": "T",
    "colId": "5",
    "id": 6,
    "key": "threatCategory",
    "value": "Tampering"
  },
  {
    "name": "R",
    "colId": "6",
    "id": 7,
    "key": "threatCategory",
    "value": "Repudiation"
  },
  {
    "name": "I",
    "colId": "7",
    "id": 8,
    "key": "threatCategory",
    "value": "Information Disclosure"
  },
  {
    "name": "D",
    "colId": "8",
    "id": 9,
    "key": "threatCategory",
    "value": "Denial of Service"
  },
  {
    "name": "E",
    "colId": "9",
    "id": 10,
    "key": "threatCategory",
    "value": "Elevation of Privilege"
  },
  {
    "name": "CVE",
    "colId": "13",
    "id": 14,
    "key": "attackVectors",
    "value": "CVE",
  },
  {
    "name": "Risk Category (CIA)",
    "colId": "14",
    "id": 15,
    "key": "riskCategory"
  },
  {
    "name": "Current Controls",
    "colId": "15",
    "id": 16,
    "key": "currentControls",
  },
  {
    "name": "Priority",
    "colId": "16",
    "id": 17,
    "key": "priority",
  },
  {
    "name": "Reference",
    "colId": "17",
    "id": 18,
    "key": "reference",
  },
  {
    "name": "Recommended countermeasures",
    "colId": "18",
    "id": 19,
    "key": "counterMeasureMapping",
    "value": "title",
  },
  {
    "name": "Status",
    "colId": "19",
    "id": 20,
    "key": "counterMeasureMapping",
    "value": "status",
  },
  {
    "name": "IS Team Notes",
    "colId": "20",
    "id": 21,
    "key": "informationSecurityTeamNote",
  },
  {
    "name": "Evidence/Status to be completed by Implementation Team",
    "colId": "21",
    "id": 22,
    "key": "counterMeasureMapping",
    "value": "implementationEvidence",
  },
  {
    "name": "Compensating controls",
    "colId": "22",
    "id": 23,
    "key": "compensatingControls",
    "value": "title"
  },
  {
    "name": "Implementation Team Notes",
    "colId": "23",
    "id": 24,
    "key": "implementationTeamNote",
  }
]

let tableEvidences = [
  {
    "name": "ID",
    "colId": "0",
    "id": 1,
    "key": "threatID",
  },
  {
    "name": "Evidence/Status to be completed by Implementation Team",
    "colId": "21",
    "id": 22,
    "key": "counterMeasureMapping",
    "value": "implementationEvidence",
  },
]

export async function generateTRAReport(tra, risks, threats, domainControlMapper, onSuccess = null) {

  var docUrl = "/assets/cisozen-tra.xlsx"

  //load the e8 template file from remote url
  const f = await (await fetch(docUrl)).arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(f);

  // populate risk tab
  const riskWorksheet = workbook.getWorksheet("Risk");
  risks.forEach((risk, index) => {
    let row = riskWorksheet.getRow(index + 4);

    [
      { label: 'High Level  Risk Scenarios', key: '' },
      { label: 'Specific worst case scenario', key: '' },
      { label: 'Title', key: 'riskTitle' },
      { label: 'Description', key: 'riskDescription' },
      { label: 'Motivation for Attacker', key: 'attackerMotivation' },
      { label: 'CIA Class', key: 'ciaClass' },
      { label: 'System', key: 'system' },
      { label: 'Inherent Likelihood', key: 'inherentLikelihood' },
      { label: 'Inherent Consequence', key: 'inherentConsequence' },
      { label: 'Inherent Risk rating', key: 'inherentRiskRating' },
      { label: 'Threat Actors', key: 'threatActors' },
      { label: 'Residual Likelihood', key: 'residualLikelihood' },
      { label: 'Residual Consequence (Identified controls do not reduce impact to the business) ', key: 'residualConsequence' },
      { label: 'Residual Risk rating', key: 'residualRiskRating' },
      { label: 'Target Risk Rating', key: 'targetResidualRiskRating' },
      { label: 'Threat IDs contributing to risk', key: 'threatList' },
      { label: 'Remediated threats', key: 'remediatedThreats', value: 'threatList' },
      { label: 'Recommendation', key: '' },
      { label: 'Securemation Security Analyst Comments', key: '' },
    ].forEach((column, cIndex) => {

      // if (index % 2 === 0) {
      //   row.getCell(cIndex + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffd9d9d9' } };
      // }

      if (column?.key) {
        if (column.key === "threatList") {
          row.getCell(cIndex + 1).value = Object.keys(risk[column.key] || {}).map((id) => beautifyUniqueID(id)).join(', ');
        } else if (column.key === "remediatedThreats") {
          let threatList = Object.keys(risk[column.value] || {}).map((id) => beautifyUniqueID(id));
          let remediatedThreatList = threats
            .filter((threat) => threatList.includes(threat.threatID))
            .filter((threat) => threat?.counterMeasureMapping?.every((counterMeasure) => counterMeasure.every((measure) => measure?.status === "Completed")))
            .map((threat) => threat.threatID);

          row.getCell(cIndex + 1).value = remediatedThreatList.join(', ');
        } else {
          row.getCell(cIndex + 1).value = risk[column.key] ?? '';
        }
      }
    })

    // Commit a completed row to stream
    row.commit();

  })

  let threatData = {
    "People": [],
    "Process": [],
    "Technology": [],
  }

  threats.forEach((threat) => {
    threatData[threat.threatType || "Technology"].push(threat);
  })

  // populate people tab
  if (threatData["People"]?.length > 0) {
    const worksheet = workbook.getWorksheet("People");

    threatData["People"].forEach((threat, index) => {
      const row = worksheet.getRow(index + 5);

      table.forEach((column, cIndex) => {
        if (column?.key) {
          if (column.key === "threatCategory") {
            if (threat[column.key] && (threat[column.key] === column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "attackVectors") {
            if ((threat[column.key] || []).includes(column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "currentControls") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${domainControlMapper[c]} \n` }))
              };
            }
          } else if (column.key === "reference") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${c} \n` }))
              };
            }
          } else if (column.key === "counterMeasureMapping" || column.key === "compensatingControls") {
            if ((threat[column.key] || []).length > 0) {
              let stack = [];
              let count = 1;
              if (column.value === "status") {
                let completedCounterMeasure = threat[column.key].find((counterMeasure) => {
                  return counterMeasure.every((measure) => measure.status === "Completed")
                })
                if (completedCounterMeasure) {
                  stack.push({ text: 'Completed' });
                } else {
                  stack.push({ text: 'In Progress' });
                }
              } else if (column.value === "implementationEvidence") {
                threat[column.key].forEach((counterMeasure, index) => {
                  counterMeasure.forEach((measure, mIndex) => {
                    if (measure.status === "Completed") {
                      stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    }
                    count++;
                  })
                })
              } else {
                threat[column.key].forEach((counterMeasure, index) => {

                  if (index > 0) {
                    stack.push({ text: '\nOR\n\n' });
                  }

                  counterMeasure.forEach((measure, mIndex) => {

                    if (mIndex > 0) {
                      stack.push({ text: 'AND\n' });
                    }

                    stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    count++;

                  })
                })
              }
              row.getCell(cIndex + 1).value = { richText: stack };
            }
          } else {
            row.getCell(cIndex + 1).value = threat[column.key] ?? '';
          }
        }
      })

      // Commit a completed row to stream
      row.commit();

    })

  }

  // populate process tab
  if (threatData["Process"]?.length > 0) {
    const worksheet = workbook.getWorksheet("Process");

    threatData["Process"].forEach((threat, index) => {
      const row = worksheet.getRow(index + 5);

      table.forEach((column, cIndex) => {
        if (column?.key) {
          if (column.key === "threatCategory") {
            if (threat[column.key] && (threat[column.key] === column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "attackVectors") {
            if ((threat[column.key] || []).includes(column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "currentControls") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${domainControlMapper[c]} \n` }))
              };
            }
          } else if (column.key === "reference") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${c} \n` }))
              };
            }
          } else if (column.key === "counterMeasureMapping" || column.key === "compensatingControls") {
            if ((threat[column.key] || []).length > 0) {
              let stack = [];
              let count = 1;
              if (column.value === "status") {
                let completedCounterMeasure = threat[column.key].find((counterMeasure) => {
                  return counterMeasure.every((measure) => measure.status === "Completed")
                })
                if (completedCounterMeasure) {
                  stack.push({ text: 'Completed' });
                } else {
                  stack.push({ text: 'In Progress' });
                }
              } else if (column.value === "implementationEvidence") {
                threat[column.key].forEach((counterMeasure, index) => {
                  counterMeasure.forEach((measure, mIndex) => {
                    if (measure.status === "Completed") {
                      stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    }
                    count++;
                  })
                })
              } else {
                threat[column.key].forEach((counterMeasure, index) => {

                  if (index > 0) {
                    stack.push({ text: '\nOR\n\n' });
                  }

                  counterMeasure.forEach((measure, mIndex) => {

                    if (mIndex > 0) {
                      stack.push({ text: 'AND\n' });
                    }

                    stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    count++;

                  })
                })
              }
              row.getCell(cIndex + 1).value = { richText: stack };
            }
          } else {
            row.getCell(cIndex + 1).value = threat[column.key] ?? '';
          }
        }
      })

      // Commit a completed row to stream
      row.commit();

    })

  }

  // populate technology tab
  if (threatData["Technology"]?.length > 0) {
    const worksheet = workbook.getWorksheet("Technology");

    threatData["Technology"].forEach((threat, index) => {
      const row = worksheet.getRow(index + 5);

      tableTechnology.forEach((column, cIndex) => {
        if (column?.key) {
          if (column.key === "threatCategory") {
            if (threat[column.key] && (threat[column.key] === column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "attackVectors") {
            if ((threat[column.key] || []).includes(column.value)) {
              row.getCell(cIndex + 1).value = "✓";
            }
          } else if (column.key === "currentControls") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${domainControlMapper[c]} \n` }))
              };
            }
          } else if (column.key === "reference") {
            if ((threat[column.key] || []).length > 0) {
              row.getCell(cIndex + 1).value = {
                richText: threat[column.key].map((c) => ({ text: `${c} \n` }))
              };
            }
          } else if (column.key === "counterMeasureMapping" || column.key === "compensatingControls") {
            if ((threat[column.key] || []).length > 0) {
              let stack = [];
              let count = 1;
              if (column.value === "status") {
                let completedCounterMeasure = threat[column.key].find((counterMeasure) => {
                  return counterMeasure.every((measure) => measure.status === "Completed")
                })
                if (completedCounterMeasure) {
                  stack.push({ text: 'Completed' });
                } else {
                  stack.push({ text: 'In Progress' });
                }
              } else if (column.value === "implementationEvidence") {
                threat[column.key].forEach((counterMeasure, index) => {
                  counterMeasure.forEach((measure, mIndex) => {
                    if (measure.status === "Completed") {
                      stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    }
                    count++;
                  })
                })
              } else {
                threat[column.key].forEach((counterMeasure, index) => {

                  if (index > 0) {
                    stack.push({ text: '\nOR\n\n' });
                  }

                  counterMeasure.forEach((measure, mIndex) => {

                    if (mIndex > 0) {
                      stack.push({ text: 'AND\n' });
                    }

                    stack.push({ text: `${count}. ${measure[column.value]}\n` });
                    count++;

                  })
                })
              }
              row.getCell(cIndex + 1).value = { richText: stack };
            }
          } else {
            row.getCell(cIndex + 1).value = threat[column.key] ?? '';
          }
        }
      })

      // Commit a completed row to stream
      row.commit();

    })

  }

  // populate evidences tab
  if (threats?.length > 0) {
    const worksheet = workbook.getWorksheet("Evidences");

    threats
      .filter((threat) => {
        return threat.counterMeasureMapping.some((cm) => cm.every((m) => m.status === "Completed"));
      })
      .forEach((threat, index) => {
        const row = worksheet.getRow(index + 4);

        tableEvidences.forEach((column, cIndex) => {
          if (column?.key) {
            if (column.key === "counterMeasureMapping") {
              if ((threat[column.key] || []).length > 0) {
                let stack = [];
                let count = 1;
                if (column.value === "implementationEvidence") {
                  threat[column.key].forEach((counterMeasure, index) => {
                    counterMeasure.forEach((measure, mIndex) => {
                      if (measure.status === "Completed") {
                        stack.push({ text: `${count}. ${measure[column.value]}\n` });
                      }
                      count++;
                    })
                  })
                } else {
                  threat[column.key].forEach((counterMeasure, index) => {

                    if (index > 0) {
                      stack.push({ text: '\nOR\n\n' });
                    }

                    counterMeasure.forEach((measure, mIndex) => {

                      if (mIndex > 0) {
                        stack.push({ text: 'AND\n' });
                      }

                      stack.push({ text: `${count}. ${measure[column.value]}\n` });
                      count++;

                    })
                  })
                }
                row.getCell(cIndex + 1).value = { richText: stack };
              }
            } else {
              row.getCell(cIndex + 1).value = threat[column.key] ?? '';
            }
          }
        })

        // Commit a completed row to stream
        row.commit();
      })

  }

  // populate summary tab
  const summaryWorksheet = workbook.getWorksheet("Summary");
  if (summaryWorksheet) {
    summaryWorksheet.getCell('C2').value = {
      richText: [
        { text: 'Threat & Risk Assessment (TRA)\n' },
        { text: `Solution: ${tra?.solutionName || ''}` },
      ]
    };

    summaryWorksheet.getCell('C13').value = dayjs().format('DD/MM/YYYY');

    summaryWorksheet.getCell('B17').value = `${((threatData["People"] || [])?.length / threats?.length * 100).toFixed(2)}%`;
    summaryWorksheet.getCell('C17').value = `${((threatData["Process"] || [])?.length / threats?.length * 100).toFixed(2)}%`;
    summaryWorksheet.getCell('D17').value = `${((threatData["Technology"] || [])?.length / threats?.length * 100).toFixed(2)}%`;
  }

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  let date = dd + '-' + mm + '-' + yyyy;

  //write workbook
  workbook.xlsx.writeBuffer().then(function (e) {
    //excel xlsx file type
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const data = new Blob([e], { type: fileType });
    var fileUrl = URL.createObjectURL(data);

    // Create a link and click it to start the download
    var link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = `TRA-${tra?.solutionName || ''}-${date}.xlsx`; //download name
    link.click();

    setTimeout(() => { onSuccess && onSuccess(); }, 100);

  });
}