export const getFileName = (name) => {
    let fileName = name || '';
    let split = fileName.split('.');
    split.pop();
    return split.join('.')
}

export const getFileType = (name) => {
    let fileName = name || '';
    let split = fileName.split('.');

    return `.${split[split.length - 1]}`
}

export const beautifyUniqueID = (input) => {
    const regex = /([a-zA-Z]+)-0+(\d+)/g;
    const result = (input || '').replace(regex, "$1-$2");
    return result;
}