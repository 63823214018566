import { Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import { Button } from "../../components/UI/Button/Button.styled";
import ModalImageSrc from "../../images/reccomendationImg.png";
import { subscriptions } from "../../store/auth-context";
import InfoDialog from "../../pages/Dashboard/components/InfoModal/InfoDialog";

function SubscriptionDialog(props) {

    let { email, open, toggle } = props;

    return (
        <>
            <InfoDialog
                open={open}
                imgSrc={ModalImageSrc}
                handleClose={toggle}
                title="Payment Options"
                list={subscriptions.map((sub, index) => (
                    <Fragment key={"subscription-" + index}>
                        <Button
                            $success
                            variant="contained"
                            onClick={() => window.open(`${sub.paymentLink}?prefilled_email=${email || ''}`, '_blank', 'noreferrer')}
                        >
                            {sub.title}
                        </Button><br />
                        <Typography sx={{ marginTop: "10px" }}>
                            {sub.description}
                        </Typography>
                        <Divider />
                    </Fragment>
                ))}
            />
        </>
    );
}

export default SubscriptionDialog;
