import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, Chip } from '@mui/material';
import ApplicableControlsCard from './ApplicableControlsCard';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const RiskDetailDialog = (props) => {

  const { open, toggle, riskData, handleEditClick } = props;

  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (open && riskData) {
      setDialogTitle(riskData["riskTitle"] || '');
    }
  }, [open, riskData])

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={toggle}
    >
      <DialogTitle sx={{ paddingRight: "80px" }}>{dialogTitle}</DialogTitle>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <IconButton
          title="edit"
          aria-label="edit"
          onClick={(e) => {
            handleEditClick(riskData.caseID, riskData.status);
            toggle(e);
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="close"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>

        {/* Display the details of the selected row here */}
        {
          riskData && <Grid container spacing={{ xs: 4, md: 4 }} >
            <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              <Typography variant="subtitle2" fontWeight={500}>
                Risk Category
              </Typography>
              <Chip label={riskData.ciaClass}></Chip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Risk Description
              </Typography>
              <Typography>
                {riskData.riskDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Attacker Motivation
              </Typography>
              <Chip label={riskData.attackerMotivation}></Chip>
            </Grid>
            {/* <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">
                                CIA Class
                            </Typography>
                            <Typography>
                                {risk.ciaClass}
                            </Typography>
                        </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                System
              </Typography>
              <Typography>
                {riskData.system}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Residual Likelihood
              </Typography>
              <Chip label={riskData.residualLikelihood ? riskData.residualLikelihood : riskData.inherentLikelihood} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Residual Consequence
              </Typography>
              <Chip label={riskData.residualConsequence ? riskData.residualConsequence : riskData.inherentConsequence} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Inherent Likelihood
              </Typography>
              <Chip label={riskData.inherentLikelihood} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Inherent Consequence
              </Typography>
              <Chip label={riskData.inherentConsequence} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Risk Category
              </Typography>
              <Chip label={riskData.ciaClass} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Threat actors
              </Typography>
              <Chip label={riskData.threatActors} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" fontWeight={500}>
                Maturity Score
              </Typography>
              <Typography sx={{ color: (riskData?.maturityScore || 0) <= 2.5 ? "red !important" : "green !important" }} >
                {riskData?.maturityScore || '0'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              <Typography variant="subtitle2" fontWeight={500}>
                Status
              </Typography>
              <Chip label={riskData.riskTreatment} />
            </Grid>
            <ApplicableControlsCard controls={riskData.controls} />
          </Grid>
        }
      </DialogContent>
    </Dialog>
  );
};

export default RiskDetailDialog;