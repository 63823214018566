import StarIcon from "@mui/icons-material/Star";
import { Alert, Grid } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Lottie from "lottie-react";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import treasureAnimation from "../../../../assets/animations/treasure.json";
import warriorsAnimation from "../../../../assets/animations/warriors.json";
import InformationMaturity from "../../components/InformationMaturity/InformationMaturity";
import NewInformationMaturity from "./components/InformationMaturity";
import Loader from "../../../../components/UI/Loader/Loader";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";

import {
    BottomBannerImg,
    BottomBannerWrapper,
    CardHead,
    DotSign,
    InfoWrapper,
    MainGrid,
    ProgressBlock,
    ProgressCard,
    ProgressValue,
    RecommendationsBlock,
    RecommendationsList,
    RecommendationsTextBlock,
    RiskMatrixBlock,
    RiskMatrixHead,
    RiskMatrixHeadBtn,
    RistMatrixTableWrapper,
    StatBox,
    StatsText,
    StatsWrapper,
    TextBlock,
    WelcomeBlock,
    WelcomeBlockHeadText,
    WelcomeBottomBtn,
    WelcomeBottomText,
    WelcomeStatsBlock,
    TextContainer,
    Text
} from "./Information.styled";
import { getDuration, getTotalScore, getRecommendations, getRiskMatrixTableData, getActiveHealthCheck, getProcessVariables, getCompletedHealthCheck, getCompletedRiskMatrix, getActiveRiskMatrix, getHealthCheckDuration } from "../../InformationAPI";
import EditableTable from "../../components/EditableTable/EditableTable";
import { useKeycloak } from "@react-keycloak/web";
import { CONTAINER_ID, UserSelectionProcess } from "../../../../store/auth-context";
import InfoModalWithItem from "../../components/InfoModal/InfoModalWithItem";
import UserTypeGroup from "../../components/UserTypeGroup/UserTypeGroup";
import { toast } from "react-toastify";
import Card from "../InformationAssetRegister/components/NewInformationAssetCard";
import { retrieveData } from "../../../../shared/utility";
import NewInformation from "./components/NewInformation";
import Fab from '@mui/material/Fab';
import HelpIcon from '@mui/icons-material/QuestionMark';
import GuideContext, { GuidanceKeyPrefix } from "../../../../store/guide-context";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { getDashboardIntroSteps } from "../../../../shared/intro-data";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function Information(props) {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);

    const history = useHistory();
    const [healthCheckTaskReady, setHealthCheckTaskReady] = useState(false);
    const [riskTaskReady, setRiskTaskReady] = useState(false);

    //data to show in dashboard
    const [duration, setDuration] = useState({ "remainingDays": 0, "maxDays": 28 });
    const [healthCheckDuration, setHealthCheckDuration] = useState({ "remainingDays": 0, "maxDays": 14 });
    const [score, setScore] = useState({ "currentScore": 0, "maxScore": 320 });
    const [oldScore, setOldScore] = useState({ "currentScore": 0, "maxScore": 320 });
    const [totalMaturityScore, setTotalMaturityScore] = useState(0);
    const [maturity, setMaturity] = useState({ "currentScore": [0, 0, 0, 0, 0], "requiredScore": [5, 5, 5, 5, 5] });
    const [totalOldMaturityScore, setTotalOldMaturityScore] = useState(0);
    const [oldMaturity, setOldMaturity] = useState({ "currentScore": [0, 0, 0, 0, 0], "requiredScore": [5, 5, 5, 5, 5] });
    const [onDate, setOnDate] = useState("");
    const [tableData, setTableData] = useState({ "tableData": [], "headingColumns": [], "selectedValue": "" });
    const [recommendation, setRecommendation] = useState({ "recommendation": "", "reason": "" });
    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);
    const [activeRiskID, setActiveRiskID] = useState(null);
    const [completedRiskID, setCompletedRiskID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRecommendationLoading, setRecommendationLoading] = useState(true);
    const [isRiskLoading, setRiskLoading] = useState(true);
    const [isPostureLoading, setPostureLoading] = useState(true);
    const [progress, setProgress] = useState(null);
    const [idealProgress, setIdealProgress] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [userType, setUserType] = useState(null);
    const [userTypeInfo, setUserTypeInfo] = useState({});
    const [totalScore, setTotalScore] = useState(null);

    const { hasTakenTourBefore, launchTour, isIntroActive } = useContext(GuideContext);

    const userTypeChange = (newType) => {
        setUserType(newType);
        updateUserType(newType)
        console.log(newType);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const healthCheckNavHandler = () => history.push("/it-security/health-check");
    const riskMatrixNavHandler = () => history.push("/it-security/risk-matrix");
    const recommendationsNavHandler = () => history.push("/it-security/recommendations");

    const welcomeButtonClickedHandler = () => {
        // if (!organisationTaskReady) {
        //     organizationDetailsNavHandler();
        // } else if (organisationTaskReady) {
        //     healthCheckNavHandler();
        // }
        healthCheckNavHandler();
    };

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Dashboard - IT Security',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    function handleNextStage() {
        if (activeHealthCheckID > 0) return healthCheckNavHandler;
        if (activeRiskID > 0) return riskMatrixNavHandler;
    }

    function handleIdealStage() {
        if (duration.remainingDays < 7) {
            //risk management
            return riskMatrixNavHandler;
        } else if (duration.remainingDays < 14) {
            //recommendation
            return recommendationsNavHandler;
        } else {
            //hc
            return healthCheckNavHandler;
        }
    }

    const handleCallback = (childData) => {
        //
    }

    useEffect(() => {
        if (userType === "") {
            handleClickOpen();
        }
    }, [userType])

    useEffect(() => {
        if (keycloak?.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
                setUsername(res.name);
                const _type = res["user_type"] || "";
                setUserType(_type);
            });
        }
    }, [keycloak?.authenticated])

    async function updateUserType(userType) {
        let config = {
            method: 'POST',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${UserSelectionProcess}/instances`,
            data: JSON.stringify({
                "userType": userType,
                "env": process.env.REACT_APP_DOMAIN
            }),
            headers: { "Authorization": `Bearer ${keycloak?.token}`, 'Content-Type': 'application/json' },
        }
        try {
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.status === 201) {
                    toast.success("User type updated!");
                }
            });

        } catch (error) {
            console.log(error);
        }
    };

    const getHealthCheckTaskInstanceHandler = useCallback((activeID, completedID) => {
        if (completedID > 0 || activeID > 0) {
            setHealthCheckTaskReady(true);
            return;
        } else {
            setHealthCheckTaskReady(false);
            return;
        }

    });

    useEffect(() => {
        getHealthCheckTaskInstanceHandler(activeHealthCheckID, completedHealthCheckID);
    }, [activeHealthCheckID, completedHealthCheckID, getHealthCheckTaskInstanceHandler]);

    const getRiskMatrixTaskInstanceHandler = useCallback(() => {
        if (completedRiskID > 0) {
            setRiskTaskReady(true);
            return;
        } else {
            setRiskTaskReady(false);
            return;
        }
    }, [completedRiskID]);

    useEffect(() => {
        if (activeRiskID === null || completedRiskID === null) return;
        getRiskMatrixTaskInstanceHandler();
    }, [activeRiskID, completedRiskID, getRiskMatrixTaskInstanceHandler]);

    function getAllInstance(_email, _kcToken) {
        if (_email === null || _kcToken === null) {
            return;
        }
        getActiveHealthCheck(kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });

        getCompletedHealthCheck(kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });

        getActiveRiskMatrix(kcToken, _email).then((response) => {
            setActiveRiskID(response.data);
        });

        getCompletedRiskMatrix(kcToken, _email).then((response) => {
            setCompletedRiskID(response.data);
        });
    }

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID]);

    //useEffect to control fetchData
    useEffect(() => {
        //update duration
        function handleDuration(activeProcess, completedProcess) {
            const processID = activeProcess ? activeProcess : completedProcess ? completedProcess : null;
            if (processID === null) return;
            getProcessVariables(processID, kcToken).then((response) => {
                const startDateMilli = response.data.filter(obj => { return obj.name === "StartDate" })[0] ?? false;
                if (startDateMilli) setDuration(getDuration(startDateMilli["value"]));
            });
        }

        function handleScore(processID, riskID) {
            if (kcToken === null) return;
            if (processID === null || processID === 0) {
                getTotalScore(0, 0, kcToken).then((responeScore) => {
                    setScore(responeScore.data)
                });
            } else {
                getProcessVariables(processID, kcToken).then((response) => {
                    const hcScore = response.data.filter(obj => { return obj.name === "TotalScore" })[0] ?? 0;

                    if (hcScore) {
                        if (riskID === null || riskID === 0) {
                            getTotalScore(hcScore["value"], 0, kcToken).then((responeScore) => {
                                setScore(responeScore.data)
                            });
                        } else {
                            getProcessVariables(riskID, kcToken).then((res) => {
                                const riskScore = res.data.filter(obj => { return obj.name === "TotalScore" })[0];
                                if (riskScore) {
                                    getTotalScore(parseInt(hcScore["value"]), parseInt(riskScore["value"]), kcToken).then((resScore) => {
                                        setScore(resScore.data)
                                    });
                                }
                            });
                        }
                    };
                });
            }
        }

        function handleOldScore(processID, riskID) {
            if (kcToken === null) return;
            if (processID === null || processID === 0) {
                getTotalScore(0, 0, kcToken).then((responeScore) => {
                    setOldScore(responeScore.data)
                });
            } else {
                getProcessVariables(processID, kcToken).then((response) => {
                    const hcScore = response.data.filter(obj => { return obj.name === "TotalScore" })[0] ?? 0;
                    const endDate = response.data.filter(obj => { return obj.name === "EndDate" })[0] ?? 0;

                    if (endDate) {
                        const newDate = new Date(endDate.value * 1);
                        const yyyy = newDate.getFullYear();
                        let mm = newDate.getMonth() + 1; // Months start at 0!
                        let dd = newDate.getDate();

                        if (dd < 10) dd = '0' + dd;
                        if (mm < 10) mm = '0' + mm;

                        const formattedOnDate = dd + '/' + mm + '/' + yyyy;
                        setOnDate(formattedOnDate);
                    }

                    if (hcScore) {
                        if (riskID === null || riskID === 0) {
                            getTotalScore(hcScore["value"], 0, kcToken).then((responeScore) => {
                                setOldScore(responeScore.data)
                            });
                        } else {
                            getProcessVariables(riskID, kcToken).then((res) => {
                                const riskScore = res.data.filter(obj => { return obj.name === "TotalScore" })[0];
                                if (riskScore) {
                                    getTotalScore(parseInt(hcScore["value"]), parseInt(riskScore["value"]), kcToken).then((resScore) => {
                                        setOldScore(resScore.data)
                                    });
                                }
                            });
                        }
                    };
                });
            }
        }

        function handleRecommendation(processID) {
            if (processID === null) return;
            setRecommendationLoading(true);
            getRecommendations(processID, kcToken).then((response) => {
                setRecommendationLoading(false);
                if (response.success) {
                    setRecommendation(response.data);
                }
            });
        }

        function handleMaturity(processID) {

            if (processID === null) return;

            getProcessVariables(processID, kcToken).then((response) => {

                const score = response.data.filter(obj => { return obj.name === "CurrentMaturity" })[0] ?? false;
                var scoreArray = [];
                if (score) {
                    const scoreString = score["value"].slice(1, -1); //remove brackets

                    scoreString.split(',').forEach(currentScore => {
                        scoreArray.push(Math.floor(currentScore));
                    });
                    var requireScoreArray = [];
                    scoreArray.forEach(currentScore => {
                        requireScoreArray.push(5 - currentScore);
                    });

                    const reducer = (accumulator, curr) => accumulator + curr;
                    const overallMaturity = Math.floor(scoreArray.reduce(reducer) / 5);
                    setTotalMaturityScore(overallMaturity);
                    setMaturity({
                        "currentScore": scoreArray,
                        "requiredScore": requireScoreArray
                    });
                }
                if (scoreArray.length === 0) {
                    if (completedHealthCheckID > 0) {
                        handleMaturity(completedHealthCheckID);
                    }
                }
                setPostureLoading(false);

            });
        }

        function handleOldMaturity(processID) {

            if (processID === null) return;

            getProcessVariables(processID, kcToken).then((response) => {

                const score = response.data.filter(obj => { return obj.name === "CurrentMaturity" })[0] ?? false;
                var scoreArray = [];
                if (score) {
                    const scoreString = score["value"].slice(1, -1); //remove brackets

                    scoreString.split(',').forEach(currentScore => {
                        scoreArray.push(Math.floor(currentScore));
                    });
                    var requireScoreArray = [];
                    scoreArray.forEach(currentScore => {
                        requireScoreArray.push(5 - currentScore);
                    });

                    const reducer = (accumulator, curr) => accumulator + curr;
                    const overallMaturity = Math.floor(scoreArray.reduce(reducer) / 5);
                    setTotalOldMaturityScore(overallMaturity);
                    setOldMaturity({
                        "currentScore": scoreArray,
                        "requiredScore": requireScoreArray
                    });
                }
                if (scoreArray.length === 0) {
                    if (completedHealthCheckID > 0) {
                        handleOldMaturity(completedHealthCheckID);
                    }
                }
                setPostureLoading(false);

            });
        }

        function handleRiskMatrix(processID) {
            if (processID === null) return;
            //update risk matrix table data
            setRiskLoading(true);
            getRiskMatrixTableData(processID, kcToken).then((response) => {
                setRiskLoading(false);
                if (response.success) {
                    setRiskTaskReady(true);
                    setTableData(response.data);
                }
            });

        }

        function handleProgress(hcID, riskID) {
            if (hcID > 0) {
                setProgress("Rapid Health Check");
                return;
            }

            if (riskID > 0) {
                setProgress("Risk Management");
                return;
            }
        }

        handleProgress(activeHealthCheckID, activeRiskID);
        handleDuration(activeHealthCheckID, completedHealthCheckID);

        if (activeHealthCheckID > 0) {
            handleScore(activeHealthCheckID, completedRiskID);
        } else if (activeHealthCheckID === 0 && completedHealthCheckID > 0) {
            handleScore(completedHealthCheckID, completedRiskID);
        }
        handleOldScore(completedHealthCheckID, completedRiskID);
        handleRecommendation(completedHealthCheckID);
        handleMaturity(activeHealthCheckID);
        handleOldMaturity(completedHealthCheckID);
        handleRiskMatrix(completedRiskID);

    }, [activeHealthCheckID, completedHealthCheckID, completedRiskID]);

    useEffect(() => {
        if (duration.remainingDays < 7) {
            //risk management
            setIdealProgress("Risk Management");
        } else if (duration.remainingDays < 14) {
            //recommendation
            setIdealProgress("Recommendations");
        } else {
            //hc
            setIdealProgress("Rapid Health Check");
        }
    }, [duration]);

    useEffect(() => {
        if (activeHealthCheckID === null || kcToken === null) return;
        getProcessVariables(activeHealthCheckID, kcToken).then((response) => {
            const score = response.data.filter(obj => { return obj.name === "TotalScore" })[0] ?? null;
            setTotalScore(Math.round(score?.["value"] ?? 0));

            const startDateMilli = response.data.filter(obj => { return obj.name === "StartDate" })[0];
            setHealthCheckDuration(getHealthCheckDuration(startDateMilli?.["value"] ?? 0));
        });
    }, [activeHealthCheckID, kcToken]);

    useEffect(() => {
        if (isPostureLoading) return;
        if (!userType) return;
        // Start tour if user is visiting the dashboard for the first time 
        if (!hasTakenTourBefore(GuidanceKeyPrefix.DASHBOARD)) {
            launchTour(GuidanceKeyPrefix.DASHBOARD, getDashboardIntroSteps(username, userType));
        }
    }, [isPostureLoading, userType]);

    if (loading) {
        return <Loader height="90vh" />;
    }

    // if (!activeHealthCheckID && !completedHealthCheckID && !activeRiskID && !completedRiskID) {
    //     return <Loader height="90vh" />;
    // }

    /* if ( !organisationInstance || !healthCheckInstance || !riskMatrixInstance) {
        return <Typography variant="h3" component="div" sx={{ textAlign: 'center', marginTop: 5, color: 'text.primary' }}>Something went wrong, an Instance is missing Please refresh the page</Typography>
    } */

    let content;
    let newPostureContent;
    if (!healthCheckTaskReady && completedHealthCheckID !== null && activeHealthCheckID !== null) {
        content = (
            <WelcomeBlock>
                <Grid container spacing={2}>
                    <Grid item lg={5} md={12} sm={12} xs={12}>
                        <InfoWrapper>
                            <WelcomeBlockHeadText>
                                Welcome to your Kingdom &nbsp;
                                <span
                                    style={{
                                        color: "#46A11B",
                                        display: "inline-block",
                                    }}
                                >
                                    General {username}
                                </span>
                                !
                            </WelcomeBlockHeadText>
                            <WelcomeStatsBlock>
                                <StatBox
                                    style={{
                                        backgroundColor: "#FFDCF4",
                                        alignSelf: "end",
                                    }}
                                >
                                    Step 1.<br /> Complete your Health Check
                                </StatBox>
                                <StatBox
                                    style={{
                                        backgroundColor: "#C1BBDD",
                                        alignSelf: "center",
                                    }}
                                >
                                    Step 2.<br />Process the recommendations
                                </StatBox>
                                <StatBox
                                    style={{
                                        backgroundColor: "#ADD8E6",
                                        alignSelf: "start",
                                    }}
                                >
                                    Step 3.<br />Finalise your cyber security risk management
                                </StatBox>
                            </WelcomeStatsBlock>
                            <WelcomeBottomText>Let’s see how you are protecting your organisation’s treasure chest!</WelcomeBottomText>
                            <div style={{ textAlign: "center" }}>
                                <WelcomeBottomBtn onClick={welcomeButtonClickedHandler}>Start Rapid Cyber Security Health Check</WelcomeBottomBtn>
                            </div>
                        </InfoWrapper>
                    </Grid>
                    <Grid item lg={7} md={0} sm={0} xs={0}></Grid>
                </Grid>
                <BottomBannerWrapper>
                    <Grid container spacing={2}>
                        <Grid item lg={5} md={12} sm={12} xs={12}></Grid>
                        <Grid item lg={7} md={0} sm={0} xs={0}>
                            <BottomBannerImg>
                                <Lottie loop animationData={treasureAnimation} />
                                <Lottie loop animationData={warriorsAnimation} />
                            </BottomBannerImg>
                        </Grid>
                    </Grid>
                </BottomBannerWrapper>
            </WelcomeBlock>
        );
    }

    if (healthCheckTaskReady) {
        // content = isPostureLoading ? <Loader></Loader> : (
        //     <InformationMaturity onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
        // );
        // newPostureContent = isPostureLoading ? <Loader></Loader> : (
        //     <NewInformationMaturity loading={isPostureLoading} onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
        // );
        newPostureContent = <NewInformationMaturity loading={isPostureLoading} onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
    }
    if (healthCheckTaskReady && riskTaskReady) {
        // content = isPostureLoading ? <Loader></Loader> : (
        //     <InformationMaturity onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
        // );
        // newPostureContent = isPostureLoading ? <Loader></Loader> : (
        //     <NewInformationMaturity onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
        // );
        newPostureContent = <NewInformationMaturity loading={isPostureLoading} onDate={onDate} progress={progress} idealProgress={idealProgress} score={oldScore} stageNumber1={totalOldMaturityScore} stageNumber2="2" stageLevel1={totalOldMaturityScore + "/5"} stageLevel2={(totalOldMaturityScore + 1) + "/5"} stageHealth1="?" stageHealth2="?" link={handleNextStage()} idealLink={handleIdealStage()} firstLabels={maturity.requiredScore} secondLabels={maturity.currentScore} oldSecondLabels={oldMaturity.currentScore} />
    }

    //const hasSeenGuidance = localStorage.getItem('hasSeenGuidance') === 'true';

    return (
        <><Helmet>
            <title>Overview - CisoZen</title>
        </Helmet>
            <Fragment>
                <StatsWrapper>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <NewInformation
                                hasTakenTourBefore={hasTakenTourBefore(GuidanceKeyPrefix.DASHBOARD)}
                                isIntroActive={isIntroActive}
                                score={score}
                                oldScore={oldScore}
                                duration={duration}
                                healthCheck={{ duration: healthCheckDuration, score: totalScore }}
                                maturity={maturity}
                                oldMaturity={oldMaturity}
                                recommendation={
                                    <MainGrid item lg={3} md={6} sm={12} xs={12} style={{ minWidth: "320px" }}>
                                        <RecommendationsBlock id="recommendation">
                                            <CardHead>Recommendations</CardHead>
                                            {
                                                isRecommendationLoading ? <Loader></Loader> : <RecommendationsList>
                                                    {
                                                        recommendation.recommendation.length > 0 && (
                                                            <ListItem onClick={(e) => recommendationsNavHandler()}>
                                                                <RecommendationsTextBlock>
                                                                    <p><StarIcon sx={{ fontSize: "20px", color: "#ED1E24", marginRight: "10px", }} /></p>
                                                                    <StatsText><p>{recommendation.recommendation} <br /><br /><b>Why?</b><br />{recommendation.reason.substring(0, recommendation.reason.length / 2)}...</p></StatsText>
                                                                </RecommendationsTextBlock>
                                                            </ListItem>
                                                        )
                                                    }
                                                    {/* {
                                                        recommendation.recommendation.length === 0 ? <ListItem><RecommendationsTextBlock><Alert severity="info">Recommendations will be created once you have completed the Rapid Health Check.</Alert></RecommendationsTextBlock></ListItem> : ""
                                                    } */}
                                                    {
                                                        recommendation.recommendation.length === 0 ? <ListItem>
                                                            <RecommendationsTextBlock>
                                                                {/* <Alert severity="info">Recommendations will be created once you have completed the Rapid Health Check.</Alert> */}
                                                                <Alert severity="info">There's no recommendations for now!</Alert>
                                                            </RecommendationsTextBlock>
                                                        </ListItem> : ""
                                                    }
                                                </RecommendationsList>
                                            }
                                        </RecommendationsBlock>
                                    </MainGrid>
                                }
                                cyberSecurityRiskManagement={
                                    <MainGrid item lg={5} md={6} sm={12} xs={12} style={{ minWidth: "320px" }}>
                                        <RiskMatrixBlock id="riskmanagement">
                                            <RiskMatrixHead>
                                                <CardHead>Cyber Security Risk Management</CardHead>
                                                {
                                                    isRiskLoading ? "" : <>{!riskTaskReady && healthCheckTaskReady && (
                                                        <RiskMatrixHeadBtn variant="contained" size="large" onClick={riskMatrixNavHandler}>
                                                            Continue
                                                        </RiskMatrixHeadBtn>
                                                    )}</>
                                                }
                                            </RiskMatrixHead>
                                            {
                                                isRiskLoading ? <Loader></Loader> : <>{
                                                    riskTaskReady ? <RistMatrixTableWrapper $blur={!riskTaskReady}>
                                                        <EditableTable tableData={tableData.tableData} headingColumns={tableData.headingColumns} title="Consequence" selectedValue={tableData.selectedValue} parentCallback={handleCallback} />
                                                    </RistMatrixTableWrapper> : <div><br /><Alert severity="info">Risk management table will be provided once you have finished defining the business impact and likelihood levels in the Risk Management Section.</Alert></div>
                                                }</>
                                            }

                                        </RiskMatrixBlock>
                                    </MainGrid>
                                }
                                isPostureLoading={isPostureLoading}
                                onDate={onDate}
                                progress={progress}
                                idealProgress={idealProgress}
                                totalOldMaturityScore={totalOldMaturityScore}
                                handleNextStage={() => handleNextStage()}
                                handleIdealStage={() => handleIdealStage()}
                            />
                        </Grid>
                        {/* <MainGrid item lg={2} md={3} sm={6} xs={12}>
                        <ProgressCard id="duration">
                            <CardHead>Duration</CardHead>
                            <ProgressBlock>
                                <CircularProgressWithLabel value={duration.remainingDays / duration.maxDays * 100}>
                                    <ProgressValue>{duration.remainingDays} days left</ProgressValue>
                                </CircularProgressWithLabel>
                            </ProgressBlock>
                            <TextBlock style={{ marginBottom: "15px" }}>
                                <DotSign />
                                <StatsText>Total Duration</StatsText>
                            </TextBlock>
                            <TextBlock>
                                <DotSign style={{ backgroundColor: "#46A11B" }} />
                                <StatsText>Time Left</StatsText>
                            </TextBlock>
                        </ProgressCard>
                    </MainGrid>
                    <MainGrid item lg={2} md={3} sm={6} xs={12}>
                        <ProgressCard id="score">
                            <CardHead>Cisozen Score</CardHead>
                            <ProgressBlock>
                                <CircularProgressWithLabel value={score.currentScore / score.maxScore * 100} color="#ED1E24">
                                    <ProgressValue>{score.currentScore} points</ProgressValue>
                                </CircularProgressWithLabel>
                            </ProgressBlock>
                            <TextBlock style={{ marginBottom: "15px" }}>
                                <DotSign />
                                <StatsText>Ideal Customer ({score.maxScore})</StatsText>
                            </TextBlock>
                            <TextBlock>
                                <DotSign style={{ backgroundColor: "#ED1E24" }} />
                                <StatsText>Your Score ({score.currentScore})</StatsText>
                            </TextBlock>
                        </ProgressCard>
                    </MainGrid>
                    <MainGrid item lg={3} md={6} sm={12} xs={12} style={{ minWidth: "320px" }}>
                        <RecommendationsBlock id="recommendation">
                            <CardHead>Recommendations</CardHead>
                            {
                                isRecommendationLoading ? <Loader></Loader> : <RecommendationsList>
                                    {
                                        recommendation.recommendation.length > 0 && (
                                            <ListItem onClick={(e) => recommendationsNavHandler()}>
                                                <RecommendationsTextBlock>
                                                    <p><StarIcon sx={{ fontSize: "20px", color: "#ED1E24", marginRight: "10px", }} /></p>
                                                    <StatsText><p>{recommendation.recommendation} <br /><br /><b>Why?</b><br />{recommendation.reason.substring(0, recommendation.reason.length / 2)}...</p></StatsText>
                                                </RecommendationsTextBlock>
                                            </ListItem>
                                        )
                                    }
                                    {
                                        recommendation.recommendation.length === 0 ? <ListItem><RecommendationsTextBlock><Alert severity="info">Recommendations will be created once you have completed the Rapid Health Check.</Alert></RecommendationsTextBlock></ListItem> : ""
                                    }


                                    </RecommendationsList>
                                }

                            </RecommendationsBlock>
                        </MainGrid>
                        <MainGrid item lg={5} sm={12} xs={12} style={{ minWidth: "320px" }}>
                            <RiskMatrixBlock id="riskmanagement">
                                <RiskMatrixHead>
                                    <CardHead>Cyber Security Risk Management</CardHead>
                                    {
                                        isRiskLoading ? "" : <>{!riskTaskReady && healthCheckTaskReady && (
                                            <RiskMatrixHeadBtn variant="contained" size="large" onClick={riskMatrixNavHandler}>
                                                Continue
                                            </RiskMatrixHeadBtn>
                                        )}</>
                                    }
                                </RiskMatrixHead>
                                {
                                    isRiskLoading ? <Loader></Loader> : <>{
                                        riskTaskReady ? <RistMatrixTableWrapper $blur={!riskTaskReady}>
                                            <EditableTable tableData={tableData.tableData} headingColumns={tableData.headingColumns} title="Consequence" selectedValue={tableData.selectedValue} parentCallback={handleCallback} />
                                        </RistMatrixTableWrapper> : <div><br /><Alert severity="info">Risk management table will be provided once you have finished defining the business impact and likelihood levels in the Risk Management Section.</Alert></div>
                                    }</>
                                }

                        </RiskMatrixBlock>
                    </MainGrid> */}
                    </Grid>
                </StatsWrapper>
                {content}
                <InfoModalWithItem open={open} handleClose={handleClose} content={<UserTypeGroup userType={userType} userTypeChange={userTypeChange} handleClose={handleClose}></UserTypeGroup>} />
                <Fab
                    color="success"
                    aria-label="help"
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                    }}
                    onClick={() => {
                        launchTour(GuidanceKeyPrefix.DASHBOARD, getDashboardIntroSteps(username, userType));
                    }}
                >
                    <HelpIcon color="#fff" />
                </Fab>
            </Fragment>
        </>
    );
}

export default Information;
