import styled, { css } from "styled-components";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export const SubQuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 31px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 32px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

export const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin-left: 59px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
            margin-left: 50px;
        }
        @media screen and (max-width: 400px) {
            margin-left: 0;
        }
        & .MuiFormGroup-root {
            flex-direction: row;
            justify-content: flex-start;
            gap: 15px 50px;
            max-width: 90%;
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
                max-width: 100%;
            }

            ${({ $between }) =>
                $between &&
                css`
                    justify-content: space-between;
                    gap: 0px;
                `}
        }
    }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
    & .MuiTypography-root {
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : theme.palette.text.primary)};
    }
    & .MuiRadio-root {
        &.Mui-checked {
            color: #007dff;
        }
    }
`;
