import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

let reactPlugin = null;
let appInsights = null;
let clickPlugin = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{clickAnalyticsPlugin: ClickAnalyticsPlugin,reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        }

        reactPlugin = new ReactPlugin();
        clickPlugin = new ClickAnalyticsPlugin();

        // Click Analytics configuration
        const clickPluginConfig = {
            customDataPrefix: "",
            parentDataTag: "",
            dntDataTag: "ai-dnt",
            captureAllMetaDataContent: true,
            useDefaultContentNameOrId: true,
            autoCapture: true
        };

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin, clickPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    },
                    [clickPlugin.identifier]: {
                        clickPluginConfig
                    }
                }
            }
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((env) => {
            env.tags = env.tags || [];
            env.tags["ai.cloud.role"] = "czFrontend";
        });
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
export const getReactPlugin = () => reactPlugin;