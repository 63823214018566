import { Box, Grid, Tooltip } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess, OrganisationalDetailsProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import QuestionProgress from "../../components/QuestionProgress/QuestionProgress";
import QuestionsStepper from "../../components/QuestionsStepper/QuestionsStepper";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./HealthCheck.styled";
import { jsPDF } from "jspdf";
import { getActiveHealthCheck, getCompletedHealthCheck, getProcessVariables, getCompletedQuestions, getProcessVariableHistory, updateProcessVariables, setProcessVariable } from "../../InformationAPI";
import { useKeycloak } from "@react-keycloak/web";
import logo from "../../../../images/logo0.2.png";
import NoteStepper from "../../components/NoteStepper/NoteStepper";
import { getDMN } from "../TRA/TRAApi";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { getAppInsights, getReactPlugin } from "../../../../TelemetryService";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { updateTrackerData } from "../../../../utility/tracker.action";

function HealthCheck(props) {
    const appInsights = getReactPlugin();

    const keyArrayIdentify = [
        "InformationSecurityManagementSystem",
        "ThreatModelling",
        "PenetrationTestingofNetworks",
        "RegularVulnerabilityAssessment",
        "GovernanceRiskandCompliance",
        "FullASDEssential8Compliance",
        "InformationSecurityAudit",
        "PenetrationTestingofWebApplications",
        "PenetrationTestingofMobileApplications",
        "PenetrationTestingofStandardoperatingenvironment",
        "InformationSecurityReview",
    ];

    const keyObjectIdentify = {
        "InformationSecurityManagementSystem": "Information Security Management System",
        "ThreatModelling": "Threat Modelling",
        "PenetrationTestingofNetworks": "Penetration Testing of Networks",
        "RegularVulnerabilityAssessment": "Regular Vulnerability Assessment",
        "GovernanceRiskandCompliance": "Governance Risk and Compliance",
        "FullASDEssential8Compliance": "Full ASD Essential8 Compliance",
        "InformationSecurityAudit": "Information Security Audit",
        "PenetrationTestingofWebApplications": "Penetration Testing of Web Applications",
        "PenetrationTestingofMobileApplications": "Penetration Testing of Mobile Applications",
        "PenetrationTestingofStandardoperatingenvironment": "Penetration Testing of Standard operating environment",
        "InformationSecurityReview": "Information Security Review",
    };

    const keyArrayProtect = [
        "WebApplicationFirewall",
        "NetworkFirewall",
        "IdentityAccessManagement",
        "RegularVulnerabilityRemediation",
        "AntivirusSolutionsonWorkstationsandServers",
        "EncryptionofSensitiveDataatRest",
        "EncryptionofSensitiveDatainTransit",
        "MultiFactorAuthentication",
        "PasswordManagementTools",
        "NetworkAccessControl",
        "WirelessSecurity",
        "NetworkSegregation",
        "IntrusionPreventionSystem",
        "EmailContentFiltering",
        "WebContentFiltering",
        "EmailProtection",
        "VPNGateway",
        "ApplicationGateway",
        "LoadBalancer",
        "AdvancedThreatProtection",
        "MalwareProtectiononServersContainersandWorkstations",
        "ProtectionofLogInformation",
        "DataLossPrevention",
    ];

    const keyObjectProtect = {
        "WebApplicationFirewall": "Web Application Firewall",
        "NetworkFirewall": "Network Firewall",
        "IdentityAccessManagement": "Identity Access Management",
        "RegularVulnerabilityRemediation": "Regular Vulnerability Remediation",
        "AntivirusSolutionsonWorkstationsandServers": "Antivirus Solutions on Workstations and Servers",
        "EncryptionofSensitiveDataatRest": "Encryption of Sensitive Data at Rest",
        "EncryptionofSensitiveDatainTransit": "Encryption of Sensitive Data in Transit",
        "MultiFactorAuthentication": "Multi Factor Authentication",
        "PasswordManagementTools": "Password Management Tools",
        "NetworkAccessControl": "Network Access Control",
        "WirelessSecurity": "Wireless Security",
        "NetworkSegregation": "Network Segregation",
        "IntrusionPreventionSystem": "Intrusion Prevention System",
        "EmailContentFiltering": "Email Content Filtering",
        "WebContentFiltering": "Web Content Filtering",
        "EmailProtection": "Email Protection",
        "VPNGateway": "VPN Gateway",
        "ApplicationGateway": "Application Gateway",
        "LoadBalancer": "Load Balancer",
        "AdvancedThreatProtection": "Advanced Threat Protection",
        "MalwareProtectiononServersContainersandWorkstations": "Malware Protectionon Servers Containers and Workstations",
        "ProtectionofLogInformation": "Protection of Log Information",
        "DataLossPrevention": "Data Loss Prevention",
    };

    const keyArrayDetect = [
        "ContinuousSecurityMonitoringwithSecurityInformationandEventManagement",
        "SendInfrastructureLogstotheSIEM",
        "SendApplicationLogstotheSIEM",
        "SendIdentityandAccessManagementLogstoSIEM",
        "MappingofInformationClassification",
        "Regulartestingofdetectioncontrols",
        "LoggingUserActivityonBusinessCriticalSystems",
        "IntrusionDetectionSystem",
        "DocumentedDataflowsforBusinessCriticalSystems",
        "Honeypot",
        "DataIntegrityMonitoring"

    ];

    const keyObjectDetect = {
        "ContinuousSecurityMonitoringwithSecurityInformationandEventManagement": "Continuous Security Monitoring with Security Information and Event Management",
        "SendInfrastructureLogstotheSIEM": "Send Infrastructure Logs to the SIEM",
        "SendApplicationLogstotheSIEM": "Send Application Logs to the SIEM",
        "SendIdentityandAccessManagementLogstoSIEM": "Send Identity and Access Management Logs to SIEM",
        "MappingofInformationClassification": "Mapping of Information Classification",
        "Regulartestingofdetectioncontrols": "Regular testing of detection controls",
        "LoggingUserActivityonBusinessCriticalSystems": "Logging User Activity on Business Critical Systems",
        "IntrusionDetectionSystem": "Intrusion Detection System",
        "DocumentedDataflowsforBusinessCriticalSystems": "Documented Data flows for Business Critical Systems",
        "Honeypot": "Honeypot",
        "DataIntegrityMonitoring": "Data Integrity Monitoring"

    };

    const keyArrayRespond = [
        "TicketingSystemstoTrackIncidentManagement",
        "InformationSecurityTeamorManagedSecurityServiceProvider",
        "SecurityOperationsCenter",
        "ContactwithCyberSecurityAuthorities",
    ];

    const keyObjectRespond = {
        "TicketingSystemstoTrackIncidentManagement": "Ticketing Systems to Track Incident Management",
        "InformationSecurityTeamorManagedSecurityServiceProvider": "Information Security Team or Managed Security Service Provider",
        "SecurityOperationsCenter": "Security Operations Center",
        "ContactwithCyberSecurityAuthorities": "Contact with Cyber Security Authorities",
    };

    const keyArrayRecover = [
        "DisasterRecoveryPlan",
        "BusinessContinuityPlan",
        "InformationSecurityContinuityPlanaspartofDRPandorBCP",
        "RestorationTestingofBackupData",
        "CyberLiabilityInsurance",
        "BackupsofSensitiveData",
    ];

    const keyObjectRecover = {
        "DisasterRecoveryPlan": "Disaster Recovery Plan",
        "BusinessContinuityPlan": "Business Continuity Plan",
        "InformationSecurityContinuityPlanaspartofDRPandorBCP": "Information Security Continuity Plan as part of DRP and or BCP",
        "RestorationTestingofBackupData": "Restoration Testing of Backup Data",
        "CyberLiabilityInsurance": "Cyber Liability Insurance",
        "BackupsofSensitiveData": "Backups of Sensitive Data",
    };

    const { signal } = props;
    const { keycloak, initialized } = useKeycloak();
    const dispatch = useDispatch();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [userType, setUserType] = useState(null);
    const [header, setHeader] = useState(null);

    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [notes, setNotes] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState(null);
    const [taskName, setTaskName] = useState(null);
    const [taskOwner, setTaskOwner] = useState(null);
    const [isViewOnly, setViewOnly] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);
    const [healthCheckCompleted, setHealthCheckCompleted] = useState(false);
    const [oldAnsArray, setOldAnsArray] = useState(null);
    const [currentAnsArray, setCurrentAnsArray] = useState(null);
    const [oldQuestions, setOldQuestions] = useState(null);
    const [userQuestions, setUserQuestions] = useState(null);

    const trackHealthCheckSubmit = useTrackEvent(appInsights, 'HealthCheckSubmit', `Question ${activeStep + 1}`);
    const trackHealthCheckBack = useTrackEvent(appInsights, 'HealthCheckBack', `From Question ${activeStep + 1}`);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        trackHealthCheckBack();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const goToQuestionHandler = useCallback((index) => {
        setActiveStep(index);
    }, []);

    const submitMainQuestion = useCallback(() => {
        let newQuestions = [...questions];
        newQuestions[activeStep].status = "answered";
        trackHealthCheckSubmit();
        setQuestions(newQuestions);
        handleNext();
    }, [activeStep, questions]);

    useEffect(() => {
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
                setUserType(res?.user_type || "");
            });
        }
    }, [props.signal])

    useEffect(() => {
        if (!userType) return;
        if (userType === "novice") {
            let nvQuestionKey = "noviceQuestionsRHC";
            let nvQuestionsString = sessionStorage.getItem(nvQuestionKey);
            if (nvQuestionsString) {
                let nvQuestionObj = JSON.parse(nvQuestionsString);
                setUserQuestions(nvQuestionObj);
            } else {
                getDMN(CONTAINER_ID, keycloak.token, nvQuestionKey).then((res) => {
                    if (res.success) {
                        const qData = res.data?.dmnContext?.questions;
                        if (qData) {
                            sessionStorage.setItem(nvQuestionKey, JSON.stringify(qData))
                            setUserQuestions(qData);
                        }
                    }
                });
            }
        } else {
            setUserQuestions([]);
        }
    }, [userType])

    useEffect(() => {
        if (taskOwner === null) return;
        if (taskOwner === email) {
            setViewOnly(false);
        } else {
            setViewOnly(true);
        }
    }, [taskOwner])

    useEffect(() => {
        let data = {
            path: window.location.pathname,
            meta: {
                title: `Rapid Cyber Security Health Check Progress ${(activeStep + 1).toString()}`,
                href: window.location.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [activeStep, dispatch])

    const getHealthCheckQuestions = useCallback(async (taskInstId, taskName, _header, ansArray, currentAnsArray, completedQuestions, uQuestions, uType) => {

        try {
            setLoading(true);

            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/contents/input`,
                ..._header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const str = res.data["TaskName"];
                let questionNum = +str.replace(/\D/g, "");
                let currentQuestionNum = +str.replace(/\D/g, "");
                if (questionNum === 33 && str === "AnswerSC33-identify") {
                    questionNum = 33.1;
                    currentQuestionNum = 33;
                } else if (questionNum === 33 && str === "AnswerSC33-protect") {
                    questionNum = 33.2;
                    currentQuestionNum = 34;
                } else if (questionNum === 33 && str === "AnswerSC33-detect") {
                    questionNum = 33.3;
                    currentQuestionNum = 35;
                } else if (questionNum === 33 && str === "AnswerSC33-respond") {
                    questionNum = 33.4;
                    currentQuestionNum = 36;
                } else if (questionNum === 33 && str === "AnswerSC33-recover") {
                    questionNum = 33.5;
                    currentQuestionNum = 37;
                } else if (res.data["NodeName"] === "Presentation Date") {
                    setHealthCheckCompleted(true);
                    return;
                }

                setCurrentQuestion(currentQuestionNum);
                setQuestionNumber(questionNum);

                const fetchedQuestions = {};
                for (let key in res.data) {
                    if (key.startsWith("Q-")) {
                        fetchedQuestions[key] = res.data[key];
                    }
                }
                const orderedQuestionsKeys = Object.keys(fetchedQuestions).sort(function (a, b) {
                    const dataA = a.slice(2), dataB = b.slice(2);
                    return dataA - dataB;
                });

                let customQuestions = ["How well is the control implemented?", "How well has this control been documented and is part of a process?", "How well are you measuring performance and using the results for continuous improvement?"]

                var questions = [];

                completedQuestions.map((completedQuestion, index) => {
                    var indexNum = 1.0;
                    if (index === 32) {
                        indexNum = 33.1;
                    } else if (index === 33) {
                        indexNum = 33.2;
                    } else if (index === 34) {
                        indexNum = 33.3;
                    } else if (index === 35) {
                        indexNum = 33.4;
                    } else if (index === 36) {
                        indexNum = 33.5;
                    } else {
                        indexNum = index + 1
                    }

                    let ansArr = generateAnswerArray(currentAnsArray, indexNum, (index + 1));
                    var orderedQuestionsList = [];
                    if (indexNum < 33) {
                        let orderedQuestions = customQuestions.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${index + 1}-${qIndex + 1}`,
                                qNum: `${index + 1}.${qIndex + 1}`,
                                qHeading: key,
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Fully", "Partially", "Not at all", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    } else if (indexNum === 33.1) {
                        let orderedQuestions = keyArrayIdentify.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${indexNum}-${qIndex + 1}`,
                                qNum: `${indexNum}.${qIndex + 1}`,
                                qHeading: keyObjectIdentify[key],
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Yes", "No", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    } else if (indexNum === 33.2) {
                        let orderedQuestions = keyArrayProtect.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${indexNum}-${qIndex + 1}`,
                                qNum: `${indexNum}.${qIndex + 1}`,
                                qHeading: keyObjectProtect[key],
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Yes", "No", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    }
                    else if (indexNum === 33.3) {
                        let orderedQuestions = keyArrayDetect.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${indexNum}-${qIndex + 1}`,
                                qNum: `${indexNum}.${qIndex + 1}`,
                                qHeading: keyObjectDetect[key],
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Yes", "No", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    }
                    else if (indexNum === 33.4) {
                        let orderedQuestions = keyArrayRespond.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${indexNum}-${qIndex + 1}`,
                                qNum: `${indexNum}.${qIndex + 1}`,
                                qHeading: keyObjectRespond[key],
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Yes", "No", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    }
                    else if (indexNum === 33.5) {
                        let orderedQuestions = keyArrayRecover.map((key, qIndex) => {
                            let questionObj = {
                                id: key,
                                label: key,
                                qId: `#main-${indexNum}-${qIndex + 1}`,
                                qNum: `${indexNum}.${qIndex + 1}`,
                                qHeading: keyObjectRecover[key],
                                hasDesc: false,
                                qDescription: null,
                                qAnswer: ansArr.length > 0 ? ansArr[qIndex] : "",
                                qAvailableAnswers: ["Yes", "No", "Not applicable"],
                                qType: "mcq",
                                touched: true
                            };
                            return questionObj;
                        });
                        orderedQuestionsList = orderedQuestions;
                    }

                    let newQuestion = {
                        qId: `#main-${(indexNum)}`,
                        qNum: `${indexNum}`,
                        status: "pending",
                        touched: false,
                        title: uType == "novice" && uQuestions ? uQuestions[index]?.qHeading : completedQuestion["node-name"],
                        qType: "unit",
                        hasDesc: false,
                        qDescription: "",
                        subQuestions: orderedQuestionsList,
                        ans1Index: orderedQuestionsList[0].qAvailableAnswers.indexOf(ansArr.length > 0 ? ansArr[0] : ""),
                        helpText: ""
                    };
                    questions.push(newQuestion);
                });

                let ansArr = generateAnswerArray(ansArray, questionNum, currentQuestionNum);
                let orderedQuestions = orderedQuestionsKeys.map((key, index) => {
                    let questionObj = {
                        id: key,
                        label: fetchedQuestions[key],
                        qId: `#main-${questionNum}-${index + 1}`,
                        qNum: `${questionNum}.${index + 1}`,
                        qHeading: fetchedQuestions[key],
                        qDescription: null,
                        qAnswer: ansArr.length > 0 ? ansArr[index] : "",
                        qAvailableAnswers: ["Fully", "Partially", "Not at all", "Not applicable"],
                        qType: "mcq",
                    };
                    if (questionNum > 32) {
                        questionObj.qAvailableAnswers = ["Yes", "No", "Not applicable"];
                    }
                    return questionObj;
                });

                let qHead = uQuestions.find(q => q.id == `${questionNum}`);
                questions.push(
                    {
                        qId: `#main-${questionNum}`,
                        qNum: `${questionNum}`,
                        status: "pending",
                        touched: false,
                        title: uType == "novice" && qHead ? qHead?.qHeading : taskName,
                        qType: "unit",
                        hasDesc: true,
                        qDescription: "The government has a number of different funding options for cyber security activities. This includes:",
                        subQuestions: orderedQuestions,
                        ans1Index: orderedQuestions[0].qAvailableAnswers.indexOf(ansArr.length > 0 ? ansArr[0] : ""),
                        helpText: res.data["help-text"]
                    }
                );
                setQuestions(questions);
                setActiveStep(questions.length - 1);
                setFetching(false);
                setLoading(false);
            });


        } catch (err) {
            setLoading(false);
            setFetching(false);
        }
    }, []);

    function generateAnswerArray(ansArray, questionNum, currentQuestionNum) {
        var ansArr = [];
        if (ansArray && currentQuestionNum < 33) {
            const oldAns1 = ansArray.filter(obj => { return obj.name === `SC${currentQuestionNum}a1` })[0]?.value ?? "";
            const oldAns2 = ansArray.filter(obj => { return obj.name === `SC${currentQuestionNum}a2` })[0]?.value ?? "";
            const oldAns3 = ansArray.filter(obj => { return obj.name === `SC${currentQuestionNum}a3` })[0]?.value ?? "";
            ansArr = [oldAns1, oldAns2, oldAns3];
        }

        if (ansArray && questionNum === 33.1) {
            keyArrayIdentify.forEach(key => {
                const oldANs = ansArray.filter(obj => { return obj.name === key })[0]?.value ?? "";
                ansArr.push(oldANs);
            });
        }

        if (ansArray && questionNum === 33.2) {
            keyArrayProtect.forEach(key => {
                const oldANs = ansArray.filter(obj => { return obj.name === key })[0]?.value ?? "";
                ansArr.push(oldANs);
            });
        }

        if (ansArray && questionNum === 33.3) {
            keyArrayDetect.forEach(key => {
                const oldANs = ansArray.filter(obj => { return obj.name === key })[0]?.value ?? "";
                ansArr.push(oldANs);
            });
        }

        if (ansArray && questionNum === 33.4) {
            keyArrayRespond.forEach(key => {
                const oldANs = ansArray.filter(obj => { return obj.name === key })[0]?.value ?? "";
                ansArr.push(oldANs);
            });
        }

        if (ansArray && questionNum === 33.5) {
            keyArrayRecover.forEach(key => {
                const oldANs = ansArray.filter(obj => { return obj.name === key })[0]?.value ?? "";
                ansArr.push(oldANs);
            });
        }

        return ansArr;
    }

    useEffect(() => {
        if (completedHealthCheckID === null) return;
        getProcessVariables(completedHealthCheckID, kcToken).then((response) => {
            //get data from previous completed health check
            setOldAnsArray(response.data);
        });

    }, [completedHealthCheckID]);

    useEffect(() => {
        if (activeHealthCheckID === null) return;

        getCompletedHCQuestions(activeHealthCheckID);

    }, [activeHealthCheckID]);

    function getCompletedHCQuestions(activeHealthCheckID) {
        getCompletedQuestions(activeHealthCheckID, kcToken).then((response) => {
            //get data from completed health check tasks
            setOldQuestions(response.data);
        });

        getProcessVariables(activeHealthCheckID, kcToken).then((response) => {
            //get data from previous completed health check
            setCurrentAnsArray(response.data);
        });
    }

    useEffect(() => {
        if (taskInstanceId === null || taskName === null || oldAnsArray === null || currentAnsArray === null || oldQuestions === null || userType === null || userQuestions === null) return;

        if (oldAnsArray && currentAnsArray && oldQuestions) {
            if (taskInstanceId && taskName) {
                getHealthCheckQuestions(taskInstanceId, taskName, header, oldAnsArray, currentAnsArray, oldQuestions, userQuestions, userType);
            }
        }

    }, [taskInstanceId, taskName, oldAnsArray, currentAnsArray, oldQuestions, userQuestions, userType]);

    const getHealthCheckTask = useCallback(async (healthInstance, _header) => {
        setLoading(true);
        setFetching(true);
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${healthInstance}`,
                ..._header,
            }
            const res = await fetchInstance.apiRequest(config);

            if (res.status === 200 && res.data) {
                const taskInstId = res.data["active-user-tasks"]["task-summary"][0]["task-id"] ?? null;
                const taskIName = res.data["active-user-tasks"]["task-summary"][0]["task-name"] ?? null;
                const taskIOwner = res.data["active-user-tasks"]["task-summary"][0]["task-actual-owner"];

                if (taskIOwner !== "") {
                    setTaskOwner(taskIOwner);
                }

                if (res.data["active-user-tasks"]["task-summary"][0]["task-status"] !== "InProgress") {
                    let config2 = {
                        method: 'put',
                        url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/states/started`,
                        data: JSON.stringify({}),
                        ..._header,
                    }
                    await fetchInstance.apiRequest(config2);
                }

                setTaskName(taskIName);
                setTaskInstanceId(taskInstId);
                setLoading(false);
            }
        } catch (error) {
            // Handle the error here
            // console.error("Error in getHealthCheckTask:", error);
        } finally {
            setFetching(false);
        }
    }, []);


    const createNewHealthCheckInstance = useCallback(async (_header) => {
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${HealthCheckProcess}/instances`,
                data: JSON.stringify({}),
                ..._header,
            }
            const res = fetchInstance.apiRequest(config);
            if (res.status === 201 || (await res).status === 200) {
                const createdInst = res.data;
                setActiveHealthCheckID(createdInst);
            }
            setLoading(false);
        } catch (error) {
            toast.error("Failed to start health check");
            setLoading(false);
        }
        setLoading(false);
    }, []);

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID]);

    useEffect(() => {

        if (healthCheckCompleted) {
            return;
        }

        if (activeHealthCheckID === null || header === null) {
            setLoading(false);
            return;
        };

        if (activeHealthCheckID > 0) {
            setLoading(true);
            //get completed questions
            getNotes(activeHealthCheckID);
            getHealthCheckTask(activeHealthCheckID, header);
            return;
        }

        // if (activeHealthCheckID === 0) {
        //     setLoading(true);
        //     createNewHealthCheckInstance(header);
        //     return;
        // }
    }, [activeHealthCheckID, healthCheckCompleted]);


    useEffect(() => {
        if (activeHealthCheckID === null) return;
        getNotes(activeHealthCheckID);
    }, [activeStep])

    function getNotes(id) {
        getProcessVariableHistory(id, keycloak.token, `Note${activeStep + 1}`).then((res) => {
            if (res.status === "success") {
                setNotes(res.data);
            }
        })
    }

    function addNote(id, data) {
        let dataObj = {
            "firstName": keycloak.userInfo?.given_name,
            "lastName": keycloak.userInfo?.family_name,
            "email": keycloak.userInfo?.email,
            "note": data,
        };
        setProcessVariable(activeHealthCheckID, keycloak.token, JSON.stringify(dataObj), `Note${id}`).then((res) => {
            if (res.status === "success") {
                getNotes(activeHealthCheckID);
            }
        });
    }

    function getAllInstance(_email, _kcToken) {
        if (_email === null || _kcToken === null) {
            return;
        }

        setHeader({ headers: { "Authorization": `Bearer ${_kcToken}`, 'Content-Type': 'application/json' } });

        getCompletedHealthCheck(_kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });

        getActiveHealthCheck(_kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClick2 = () => {
        setModal2Open(true);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    const handleOneAnswer = useCallback(
        (val, parentIndex, index) => {
            let newQuestions = [...questions];
            newQuestions[parentIndex].touched = true;
            newQuestions[parentIndex].subQuestions[index].qAnswer = val;

            if (currentQuestion < 33) {
                if (index === 0) {
                    const indexOfAnswerq1 = newQuestions[parentIndex].subQuestions[index].qAvailableAnswers.indexOf(val);
                    newQuestions[parentIndex].ans1Index = indexOfAnswerq1;

                    if (indexOfAnswerq1 >= 2) {
                        newQuestions[parentIndex].subQuestions[index + 1].qAnswer = val;
                        newQuestions[parentIndex].subQuestions[index + 2].qAnswer = val;
                    }
                }
            } else if (currentQuestion === 35) {
                if (index === 0) {
                    if (val === "No" || val === "Not applicable") {
                        newQuestions[parentIndex].subQuestions[1].qAnswer = val;
                        newQuestions[parentIndex].subQuestions[2].qAnswer = val;
                        newQuestions[parentIndex].subQuestions[3].qAnswer = val;
                    }
                }
            } else if (currentQuestion === 37) {
                if (index === 1) {
                    if (val === "No" || val === "Not applicable") {
                        newQuestions[parentIndex].subQuestions[index + 1].qAnswer = val;
                    }
                }
            }

            setQuestions(newQuestions);
        },
        [questions]
    );

    const submitAllQuestions = useCallback(() => {
        if (!header) return;
        setSubmitting(true);
        setFetching(true);
        setLoading(true);
        if (taskInstanceId) {
            let payloadObj = {};
            if (questionNumber < 33) {
                questions[questions.length - 1].subQuestions.forEach((question, index) => {
                    payloadObj[`ans${index + 1}`] = question.qAnswer;
                });
                // if (!payloadObj["ans2"]) {
                //     payloadObj["ans2"] = "Fully";
                // }
                // if (!payloadObj["ans3"]) {
                //     payloadObj["ans3"] = "Fully";
                // }
            } else if (questionNumber === 33.1) {
                payloadObj = {
                    InformationSecurityManagementSystem: "",
                    ThreatModelling: "",
                    PenetrationTestingofNetworks: "",
                    RegularVulnerabilityAssessment: "",
                    GovernanceRiskandCompliance: "",
                    FullASDEssential8Compliance: "",
                    InformationSecurityAudit: "",
                    PenetrationTestingofWebApplications: "",
                    PenetrationTestingofMobileApplications: "",
                    PenetrationTestingofStandardoperatingenvironment: "",
                    InformationSecurityReview: "",
                };
                Object.keys(payloadObj).forEach((key, index) => {
                    payloadObj[key] = questions[questions.length - 1].subQuestions[index].qAnswer;
                });
            } else if (questionNumber === 33.2) {
                payloadObj = {
                    WebApplicationFirewall: "",
                    NetworkFirewall: "",
                    IdentityAccessManagement: "",
                    RegularVulnerabilityRemediation: "",
                    AntivirusSolutionsonWorkstationsandServers: "",
                    EncryptionofSensitiveDataatRest: "",
                    EncryptionofSensitiveDatainTransit: "",
                    MultiFactorAuthentication: "",
                    PasswordManagementTools: "",
                    NetworkAccessControl: "",
                    WirelessSecurity: "",
                    NetworkSegregation: "",
                    IntrusionPreventationSystem: "",
                    EmailContentFiltering: "",
                    WebContentFiltering: "",
                    EmailProtection: "",
                    VPNGateway: "",
                    ApplicationGateway: "",
                    LoadBalancer: "",
                    AdvancedThreatProtection: "",
                    MalwareProtectiononServersContainersandWorkstations: "",
                    ProtectionofLogInformation: "",
                    DataLossPrevention: "",
                };
                Object.keys(payloadObj).forEach((key, index) => {
                    payloadObj[key] = questions[questions.length - 1].subQuestions[index].qAnswer;
                });
            } else if (questionNumber === 33.3) {
                payloadObj = {
                    ContinuousSecurityMonitoringwithSecurityInformationandEventManagement: "",
                    SendInfrastructureLogstoSIEM: "",
                    SendApplicationLogstoSIEM: "",
                    SendIdentityandAccessManagementLogstoSIEM: "",
                    MappingofInformationClassification: "",
                    RegularTestingofDetectionControls: "",
                    LoggingUserActivityonBusinessCriticalSystems: "",
                    IntrusionDetectionSystem: "",
                    DocumentedDataflowsforBusinessCriticalSystems: "",
                    Honeypot: "",
                    DataIntegrityMonitoring: "",
                };
                Object.keys(payloadObj).forEach((key, index) => {
                    payloadObj[key] = questions[questions.length - 1].subQuestions[index].qAnswer;
                });
            } else if (questionNumber === 33.4) {
                payloadObj = {
                    TicketingSystemstoTrackIncidentManagement: "",
                    InformationSecurityTeamorManagedSecurityServiceProvider: "",
                    SecurityOperationsCenter: "",
                    ContactwithCyberSecurityAuthorities: "",
                };
                Object.keys(payloadObj).forEach((key, index) => {
                    payloadObj[key] = questions[questions.length - 1].subQuestions[index].qAnswer;
                });
            } else if (questionNumber === 33.5) {
                payloadObj = {
                    DisaterRecoveryPlan: "",
                    BusinessContinuityPlan: "",
                    InfoSecDRPandBCP: "",
                    RestorationTestingofBackupData: "",
                    CyberLiabilityInsurance: "",
                    BackupsofSensitiveData: "",
                };
                Object.keys(payloadObj).forEach((key, index) => {
                    payloadObj[key] = questions[questions.length - 1].subQuestions[index].qAnswer;
                });
            }

            let config = {
                method: 'put',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
                data: JSON.stringify(payloadObj),
                ...header,
            }

            fetchInstance.apiRequest(config)
                .then((res) => {
                    //print response
                    if (res.status === 201) {
                        if (questionNumber === 33.5) {
                            setHealthCheckCompleted(true);
                            // completeInstanceHandler(processInstanceId, HealthCheckProcess);
                            return;
                        }
                        setTaskName(null);
                        setTaskInstanceId(null);
                        setCurrentAnsArray(null);
                        setOldQuestions(null);
                        getCompletedHCQuestions(activeHealthCheckID);
                        getHealthCheckTask(activeHealthCheckID, header);
                        setFetching(true);
                        setLoading(false);
                        setSubmitting(false);
                    }
                })
                .catch((err) => {
                    toast.error("Something went wrong, Please try again");
                    setLoading(false);
                    setSubmitting(false);
                    setFetching(false);
                });

        }
    }, [taskInstanceId, questionNumber, questions, activeHealthCheckID, getHealthCheckTask, header]);

    let userAnsweredAllQuestions = questions.every((question) => {
        return question.subQuestions.every((subQuestion) => {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        });
    });

    if (healthCheckCompleted) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1 style={{ color: "white" }}>Congratulations!</h1><br /><p> You have completed the Rapid Health Check. Please check out your recommendations based on the answers you provided in the Health Check.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/recommendations")}>
                            Recommendations
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }
    let content;

    // if (loading) {
    //     content = <Loader height="40vh" />;
    // }

    if (loading || (fetching && activeHealthCheckID === null)) {
        content = <Loader height="40vh" />;
    }


    if ((questions.length > 0) & !fetching) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                        <Box sx={{ paddingTop: "40px" }}>
                            <QuestionProgress questionNumber={activeStep + 1} totalNumber={37} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", position: "relative" }}>
                            <QuestionsStepper activeNumber={activeStep + 1} readOnly={isViewOnly} questions={questions} handleAnswer={handleOneAnswer} onClick={handleClick2} />
                            <Heading marginMd="32px 0" marginSm="24px 18px">
                                Notes
                            </Heading>
                            <NoteStepper activeNumber={activeStep + 1} readOnly={isViewOnly} notes={notes} handleAnswer={handleOneAnswer} onClick={handleClick2} addNote={addNote}></NoteStepper>
                            {
                                isViewOnly ? <ActionsWrapper hasOne={activeStep === questions.length - 1}>
                                    {activeStep !== 0 && (
                                        <Button $error onClick={handleBack}>
                                            back
                                        </Button>
                                    )}
                                    {activeStep < questions.length - 1 && (
                                        <Button $success onClick={submitMainQuestion}>
                                            next
                                        </Button>
                                    )}
                                </ActionsWrapper> :
                                    <ActionsWrapper hasOne={activeStep === questions.length - 1}>
                                        {activeStep !== 0 && (
                                            <Button $error onClick={handleBack} style={{ marginRight: "10px" }}>
                                                back
                                            </Button>
                                        )}
                                        {activeStep < questions.length - 1 && (
                                            <Button $success onClick={submitMainQuestion}>
                                                next
                                            </Button>
                                        )}
                                        {activeStep === questions.length - 1 && (
                                            <Button disabled={!userAnsweredAllQuestions} loading={submitting} $success onClick={submitAllQuestions}>
                                                submit
                                            </Button>
                                        )}
                                    </ActionsWrapper>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[activeStep].helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Rapid Cyber Security Health Check Progress {(activeStep + 1).toString()} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    Rapid Cyber Security Health Check
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default HealthCheck;
