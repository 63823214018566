import React, { useContext, useCallback, useState } from "react";
import { StyledContainer, ContentContainer, StyledHead, StyledList, GraphContainer, ImgContainer, StyledBox, StyledHeadingCenter, CustomGrid } from "./InformationMaturity.styled.js";

import imgCastle from "../../../../../images/csMt0.png";
import imgCastle1 from "../../../../../images/csMt1.png";
import imgCastle2 from "../../../../../images/csMt2.png";
import imgCastle3 from "../../../../../images/csMt3.png";
import imgCastle4 from "../../../../../images/csMt4.png";
import imgCastle5 from "../../../../../images/csMt5.png";
import annotationPlugin from "chartjs-plugin-annotation";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ThemeContext from "../../../../../store/theme-context";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { CardHead, CardSubHead, ProgressCard, ProgressCardContent } from "../Information.styled.js";
import Loader from "../../../../../components/UI/Loader/Loader.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);

export var options = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
        },
        annotation: {
            annotations: {
                line1: {
                    type: "line",
                    yMin: 3,
                    yMax: 3,
                    borderColor: "#fff",
                    borderDash: [8, 8],
                    borderWidth: 1,
                },
            },
        },
    },

    responsive: true,
    scales: {
        x: {
            grid: {
                display: false,
            },
            lineThickness: 0,
            tickThickness: 0,
            stacked: true,
            ticks: { color: 'white', beginAtZero: true }
        },
        y: {
            grid: {
                display: false,
            },
            lineThickness: 0,
            tickThickness: 0,
            stacked: true,
            ticks: {
                min: 0,

                max: 5,
                stepSize: 1,
            },
            ticks: { color: 'white', beginAtZero: true }
        },
    },
};
ChartJS.defaults.plugins.legend = {
    display: false,
};

const InformationMaturity = (props) => {

    const { loading, onDate, progress, score, oldSecondLabels, stageNumber1 } = props;

    //const [fetching, setFetching] = React.useState(false);
    //const [maturity, setMaturity] = React.useState(null);
    const { mode } = useContext(ThemeContext);
    const castleList = [imgCastle, imgCastle1, imgCastle2, imgCastle3, imgCastle4, imgCastle5]
    const castleIndex1 = stageNumber1;
    const average = Math.floor(oldSecondLabels.reduce((a, b) => a + b, 0) / oldSecondLabels.length) ?? 0;

    options = {
        maintainAspectRatio: false,

        plugins: {
            title: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        yMin: 3,
                        yMax: 3,
                        borderColor: mode === 'light' ? '#000' : '#fff',
                        borderDash: [8, 8],
                        borderWidth: 1,
                    },
                },
            },
            tooltip: {
                enabled: false // <-- this option disables tooltips
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: { color: mode === 'light' ? '#000' : '#fff', beginAtZero: true }
            },
            y: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: {
                    min: 0,
                    color: mode === 'light' ? '#000' : '#fff', beginAtZero: true,
                    max: 5,
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <>
            <ProgressCard>
                <ProgressCardContent style={{ justifyContent: "unset" }}>
                    <CardHead>
                        {"Security posture"}
                    </CardHead>
                    {
                        loading ? <Loader /> : <>
                            <CardSubHead>
                                {onDate !== "" ? `Your security posture compared to the recommended posture on ${onDate}` : "Your security posture"}
                            </CardSubHead>
                            <Box sx={{ marginTop: '21px', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-evenly', gap: 2 }}>
                                <Grid container spacing={2} id="usersecurityposture">
                                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <ImgContainer>
                                            <ZoomImage src={castleList[castleIndex1]} style={{ height: "100%", width: "100%" }} />
                                        </ImgContainer>
                                    </Grid>
                                    <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px' }}>
                                        <Typography variant="h6" textAlign={"center"} sx={{ fontSize: '1rem' }}>
                                            Current security posture
                                        </Typography>
                                        <StyledBox $lightblue>
                                            <span>Cisozen score</span>
                                            <span>{score.currentScore}</span>
                                        </StyledBox>
                                        <StyledBox $gray>
                                            <span>Maturity</span>
                                            <span>{average}/5</span>
                                        </StyledBox>
                                        <StyledBox >
                                            <span>Progress</span>
                                            <span>{progress !== "" ? progress : "-"}</span>
                                        </StyledBox>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} id="idealsecurityposture">
                                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <ImgContainer>
                                            <ZoomImage src={castleList[3]} style={{ height: "100%", width: "100%" }} />
                                        </ImgContainer>
                                    </Grid>
                                    <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px' }}>
                                        <Typography variant="h6" textAlign={"center"} sx={{ fontSize: '1rem' }}>
                                            Ideal security posture
                                        </Typography>
                                        <StyledBox $lightblue>
                                            <span>Cisozen score</span>
                                            <span>{236}</span>
                                        </StyledBox>
                                        <StyledBox $gray>
                                            <span>Maturity</span>
                                            <span>3/5</span>
                                        </StyledBox>
                                        {/* <StyledBox>
                            <span>Progress</span>
                            <span>{idealProgress}</span>
                        </StyledBox> */}
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    }
                </ProgressCardContent>
            </ProgressCard >
        </>
    );
};

export default InformationMaturity;


export const ZoomImage = ({ src }) => {
    const [isZoomed, setIsZoomed] = useState(false)

    const handleImgLoad = useCallback(() => {
        setIsZoomed(true)
    }, [])

    const handleZoomChange = useCallback(shouldZoom => {
        setIsZoomed(shouldZoom)
    }, [])

    return (
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange} zoomMargin={100}>
            <img
                alt=""
                src={src}
                style={{ width: '100%', objectFit: 'cover' }}
            />
        </ControlledZoom>
    )
}