import styled from "styled-components";

export const ImgIcon = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius:50px;
  object-fit: cover;
`;
export const Title = styled.b`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  font-weight: 500;
  font-size:20px;
`;
export const Statustext = styled.div`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 24px;
  display: inline-block;
  width: 100px;
  height: 27px;
  flex-shrink: 0;
`;

export const Responsetext = styled.div`
  position: relative;
  letter-spacing: 0.46px;
  line-height: 24px;
  display: inline-block;
  width: 130px;
  height: 27px;
  flex-shrink: 0;
`;
export const Contentgroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
export const Contentgroup1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
  text-align: right;
`;
export const Textsection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
`;
export const SubscriptionRoot = styled.div`
  position: relative;
  background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
  border-radius: 8px;
  width: 100%;
  height: 420px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
  font-family: "Rubik", sans-serif;
  width: 100%;
`;