import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import LandingPage from "../Auth/nested/Landing/MainLandingPage";
// import RiskAssessmentForm from "./RiskAssessment/RiskAssessment";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import { Typography } from "@mui/material";
import { retrieveData } from "../../shared/utility";

const PublicRoute = () => {
    let { path } = useRouteMatch();

    return (

        <Switch>
            <Route exact path={`${path}`}>
                <LandingPage />
            </Route>
            {/* <Route exact path={`${path}form/risk-assessment`}>
                <RiskAssessmentForm />
            </Route> */}
            <Route exact path={`${path}privacy-policy`}>
                <PrivacyPolicy />
            </Route>
            <Route exact path={`${path}version`}>
                <Typography color="white">v{retrieveData(process.env.REACT_APP_VERSION)}</Typography>
            </Route>
            <Route path="*" >
                <Redirect to="/" />
            </Route>
        </Switch>
    );
};

export default PublicRoute;
