import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import HelpIcon from '@mui/icons-material/QuestionMark';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "../../../../../components/UI/Button/Button.styled";
import Heading from "../../../../../components/UI/Heading/Heading";
import Loader from "../../../../../components/UI/Loader/Loader";
import modalImg from "../../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, AssessMaturity } from "../../../../../store/auth-context";
import InfoModal from "../../../components/InfoModal/InfoModal";
import QuestionProgress from "../../../components/QuestionProgress/QuestionProgress";
import QuestionsStepper from "../../../components/QuestionsStepper/QuestionsStepper";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./ControlMaturityQuestion.styled";
import { getProcessVariables } from "../../TRA/TRAApi";
import * as fetchInstance from "../../../../../utility/fetch-instance";
import GuideContext, { GuidanceKeyPrefix } from "../../../../../store/guide-context";
import { getMAIntroSteps } from "../../../../../shared/intro-data";
import { updateTrackerData } from "../../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function NextButton({ activeStep, questions, submitMainQuestion, submitAllQuestions }) {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const timeoutIdRef = useRef(null);

    const isAllQuestionAnsweredForActiveStep = useCallback(() => {
        let isAllAnswered = true;
        if (questions[activeStep]?.subQuestions?.length > 0) {
            isAllAnswered = questions[activeStep].subQuestions.every((subQuestion) => {
                return subQuestion.qAnswer !== null;
            })
        }
        return isAllAnswered;
    }, [activeStep, questions]);

    const isAllQuestionAnsweredForAllStep = useCallback(() => {
        return questions.every((question) => {
            return question.subQuestions.every((subQuestion) => {
                return subQuestion.qAnswer !== null;
            })
        });
    }, [questions]);

    const handleButtonClick = useCallback(() => {
        if (activeStep < questions.length - 1) {
            submitMainQuestion();
        } else {
            if (!isAllQuestionAnsweredForAllStep()) {
                setTooltipOpen(true);

                // Clear existing timeout
                if (timeoutIdRef.current) {
                    clearTimeout(timeoutIdRef.current);
                }

                // Automatically hide tooltip after 2 seconds (adjust the time as needed)
                timeoutIdRef.current = setTimeout(() => {
                    setTooltipOpen(false);
                }, 2000);
            } else {
                submitAllQuestions();
            }
        }
    }, [activeStep, isAllQuestionAnsweredForAllStep, questions.length, submitAllQuestions, submitMainQuestion]);

    // const handleButtonClick = useCallback(() => {
    //     if (!isAllQuestionAnsweredForActiveStep()) {
    //         setTooltipOpen(true);

    //         // Clear existing timeout
    //         if (timeoutIdRef.current) {
    //             clearTimeout(timeoutIdRef.current);
    //         }

    //         // Automatically hide tooltip after 2 seconds (adjust the time as needed)
    //         timeoutIdRef.current = setTimeout(() => {
    //             setTooltipOpen(false);
    //         }, 2000);
    //     } else {
    //         if (activeStep < questions.length - 1) {
    //             submitMainQuestion();
    //         } else {
    //             // If it is last question submit all 
    //             submitAllQuestions();
    //         }
    //     }
    // }, [activeStep, isAllQuestionAnsweredForActiveStep, questions.length, submitAllQuestions, submitMainQuestion]);

    const handleMouseEnter = useCallback(() => {
        setTooltipOpen(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setTooltipOpen(false);
    }, []);

    // Clear the timeout when the component unmounts
    useEffect(() => {
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, []);

    if (activeStep === questions.length - 1) {
        return <>
            {
                isAllQuestionAnsweredForAllStep() ? <>
                    <Button $success onClick={handleButtonClick}>
                        {activeStep === questions.length - 1 ? "submit" : "next"}
                    </Button>
                </> : <>
                    <Tooltip
                        title="Please answer all questions to proceed further"
                        open={tooltipOpen}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span className="pointer" onClick={handleButtonClick}>
                            <Button $success disabled >
                                {activeStep === questions.length - 1 ? "submit" : "next"}
                            </Button>
                        </span>
                    </Tooltip>
                </>
            }
        </>
    }

    return <Button $success onClick={handleButtonClick}>
        {activeStep === questions.length - 1 ? "submit" : "next"}
    </Button>

    return <>
        {
            isAllQuestionAnsweredForActiveStep() ? <>
                <Button $success onClick={handleButtonClick}>
                    {activeStep === questions.length - 1 ? "submit" : "next"}
                </Button>
            </> : <>
                <Tooltip
                    title="Please answer all questions to proceed further"
                    open={tooltipOpen}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <span className="pointer" onClick={handleButtonClick}>
                        <Button $success disabled >
                            {activeStep === questions.length - 1 ? "submit" : "next"}
                        </Button>
                    </span>
                </Tooltip>
            </>
        }

    </>
}

function ControlMaturityQuestion(props) {
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);

    const [activeStep, setActiveStep] = useState(0);
    const [taskInstanceId, setTaskInstanceId] = useState("");

    const [loading, setLoading] = useState(false);

    const [healthCheckTaskReady, setHealthCheckTaskReady] = useState(false);
    const [controlNumber, setControlNumber] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [domainName, setDomainName] = useState("");
    const [taskID, setTaskID] = useState(null);
    const [variables, setVariables] = useState(null);
    const [qAvailableAnswers, setAvailableAnswers] = useState([]);
    const [qAvailableAnswersScore, setAvailableScore] = useState([]);
    const [oldVariables, setOldVariables] = useState(null);

    const { hasTakenTourBefore, launchTour } = useContext(GuideContext);
    // const qFeedbackCtrlType= [
    //     "Global Scope Control", "Information Asset Scope Control"
    // ];

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Maturity Assessment Progress ${domainName} - ${controlNumber}.${activeStep + 1}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [activeStep, controlNumber, dispatch, domainName])

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            let newStep = prevActiveStep + 1;

            setTimeout(() => {
                let guidanceKey = `${GuidanceKeyPrefix.MATURITY_ASSESSMENT}_${questions[newStep]?.qNum}`;
                if (!hasTakenTourBefore(guidanceKey)) {
                    launchTour(guidanceKey, getMAIntroSteps(questions[newStep]?.qNum));
                }
            }, 500)

            return newStep
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const goToQuestionHandler = useCallback((index) => {
        setActiveStep(index);
    }, []);

    const handleTextChange = () => {

    };
    let modalTitle = "You will get points for answering the questions asked during the health check :";
    let modalList = ["Maximum duration : 2 weeks from first login"];

    const handleOneAnswer = useCallback(
        (val, parentIndex, index, qNum) => {
            let newQuestions = [...questions];
            newQuestions[parentIndex].touched = true;
            newQuestions[parentIndex].subQuestions[index].qAnswer = val;
            setQuestions(newQuestions);

            //update the variables
            let processID = window.sessionStorage.getItem("MA_ACTIVE_PROCESS");

            updateVariables(qNum.replaceAll('.', ''), val);
        },
        [questions]
    );

    useEffect(() => {
        setLoading(true);
        let controlNumber = window.sessionStorage.getItem("MA_ACTIVE_CONTROL");
        setControlNumber(controlNumber);
        let oldVar = window.sessionStorage.getItem("MA_OLD_VAR");
        try {
            let _variables = JSON.parse(oldVar);
            setOldVariables(_variables);
        } catch (error) {

        }
    }, []);

    useEffect(() => {
        if (controlNumber === null) return;
        let processID = window.sessionStorage.getItem("MA_ACTIVE_PROCESS");
        setProcessInstanceID(processID);
        getTasks(controlNumber, processID);
        getVariables(processID);
    }, [controlNumber]);

    useEffect(() => {
        if (taskID === null || qAvailableAnswers.length === 0) return;
        getUserTaskInput(taskID);
    }, [taskID, qAvailableAnswers]);

    function getVariables(processID) {
        getProcessVariables(CONTAINER_ID, keycloak.token, processID).then((response) => {
            if (response.success) {
                try {
                    const dataArr = response.data['variable-instance'];
                    const availableAnswersObj = dataArr.find(data => data.name == "caseFile_AvailableAnswers")["value"];
                    const availableAnswers = JSON.parse(availableAnswersObj);
                    const answers = [];
                    const scores = [];
                    availableAnswers.map((answer) => {
                        answers.push(answer["answerHeading"]);
                        scores.push(answer["answer"])
                    })
                    setVariables(dataArr);
                    setAvailableAnswers(answers.reverse());
                    setAvailableScore(scores.reverse());
                } catch (error) {
                    // console.log(error);
                }
            }
        })
    }

    //api call to update variables 
    async function updateVariables(qnum, val) {
        let score = qAvailableAnswers.indexOf(val) - 5;
        let scoreObject = {};

        scoreObject[`score${qnum.replaceAll('.', '')}`] = Math.abs(score);
        let data = JSON.stringify(
            scoreObject
        );

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstanceID}/variables`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: data
        };

        fetchInstance.apiRequest(config)
            .then((response) => {
                // console.log(response.status);
            })
            .catch((error) => {
                //
            });

    }

    //api call to get tasks 
    async function getTasks(cNumber, processID) {

        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then((response) => {
                let tasks = response.data["active-user-tasks"]["task-summary"];
                const found = tasks.find(element => element["task-name"].split('.')[0] === cNumber);

                if (found) {
                    setTaskID(found["task-id"]);
                    if (found["task-status"] !== "InProgress") {
                        startUserTask(found["task-id"]);
                    }
                }
            })
            .catch((error) => {
            });

    }

    async function startUserTask(taskInstanceId) {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/started`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify(true)
        };

        fetchInstance.apiRequest(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));

            })
            .catch((error) => {
                // console.log(error);
            });
    }

    //api call to get information to show in questions
    async function getUserTaskInput(taskID) {
        let data = JSON.stringify(true);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/contents/input`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then((response) => {
                let questions = [];
                let cNumber = controlNumber;
                setDomainName(response.data["NodeName"]?.replaceAll(".", ". "));

                let dataObj = JSON.parse(response.data[`domain${controlNumber}`]);
                let sections = dataObj.sections;
                if (sections) {
                    sections.map((section, sectionIndex) => {
                        let subQuestions = [];
                        let controls = section.controls;
                        if (controls) {

                            controls.map((control) => {
                                var qAnswer = null;
                                try {
                                    let score = variables.find(v => v["name"] == `score${control.controlID.replaceAll('.', '')}`)["value"];
                                    const index = Math.abs(Math.floor(score) - 5);
                                    qAnswer = qAvailableAnswers[index]
                                } catch (error) {

                                }

                                if (qAnswer === null) {
                                    try {
                                        let score = oldVariables[`score${control.controlID.replaceAll('.', '')}`];
                                        const index = Math.abs(Math.floor(score) - 5);
                                        qAnswer = qAvailableAnswers[index]
                                    } catch (error) {

                                    }
                                }


                                let subQuestion = {
                                    qId: "#main-" + control.controlID,
                                    qNum: control.controlID,
                                    qHeading: control.controlHeading,
                                    hasDesc: false,
                                    qDescription: false,
                                    qAnswer: qAnswer,
                                    qAvailableAnswers: qAvailableAnswers,
                                    qType: "maturity",
                                    expanded: true,
                                    key: control.key
                                    //sqFeedbackCtrlType: qFeedbackCtrlType
                                }
                                subQuestions.push(subQuestion);
                            })
                        }

                        let question = {
                            qId: "#main-" + section.sectionID,
                            qNum: section.sectionID,
                            status: "pending",
                            touched: false,
                            title: section.sectionHeading,
                            qType: "unit",
                            hasDesc: false,
                            qDescription: "",
                            subQuestions: subQuestions
                        };
                        questions.push(question);
                    })
                }

                setQuestions(questions);
                setLoading(false);

                setTimeout(() => {
                    let guidanceKey = `${GuidanceKeyPrefix.MATURITY_ASSESSMENT}_${questions[activeStep]?.qNum}`;
                    if (!hasTakenTourBefore(guidanceKey)) {
                        launchTour(guidanceKey, getMAIntroSteps(questions[activeStep]?.qNum));
                    }
                }, 500)
            })
            .catch((error) => {
                //
            });

    }

    const submitMainQuestion = useCallback(() => {
        let newQuestions = [...questions];
        newQuestions[activeStep].status = "answered";
        setQuestions(newQuestions);
        handleNext();
    }, [activeStep, questions]);

    //submit user task
    const submitAllQuestions = useCallback(() => {
        setLoading(true);
        let dataObj = {};
        questions.map((question, index) => {
            question.subQuestions.map((subQuestion) => {
                let score = qAvailableAnswers.indexOf(subQuestion.qAnswer);
                let indexOfAnswer = Math.abs(score);
                dataObj[`${subQuestion.key}`] = qAvailableAnswersScore[indexOfAnswer];
                //dataObj[`Answer${subQuestion.qFeedbackCtrlType.replaceAll('.', '')}`] = qFeedbackCtrlType[indexOfAnswer];

            })
        })

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/states/completed`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify(dataObj)
        };

        fetchInstance.apiRequest(config)
            .then((response) => {

                //form submitted
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
            });

        setLoading(false);
        history.push("/it-security/maturity-assessment/new");

    }, [history, taskInstanceId, questions]);

    let userAnsweredAllQuestions = questions.every((question) => {
        return question.subQuestions.every((subQuestion) => {
            return subQuestion.qAnswer !== null;
        });
    });

    if (loading) {
        return <Loader height="40vh" />
    }

    return (
        <>
            <Helmet>
                <title>Maturity Assessment New Progress {(activeStep + 1).toString()} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    {domainName}
                </Heading>
                <Grid container spacing={{ xs: 1, md: 3 }}>
                    <Grid item xs={12}>
                        <QuestionProgress questionNumber={activeStep + 1} totalNumber={questions.length} gotToQuestion={goToQuestionHandler} />
                    </Grid>
                    <Grid item xs={12}>
                        <QuestionsStepper activeStep={activeStep} questions={questions} handleAnswer={handleOneAnswer} activeNumber={activeStep + 1} readOnly={false} />
                    </Grid>

                    <Grid item xs={12}>
                        <ActionsWrapper hasOne={activeStep === 0}>
                            {
                                getMAIntroSteps(questions[activeStep]?.qNum)?.length > 0 && <Fab
                                    color="success"
                                    aria-label="help"
                                    sx={{
                                        marginRight: "10px"
                                    }}
                                    size="small"
                                    onClick={() => {
                                        let guidanceKey = `${GuidanceKeyPrefix.MATURITY_ASSESSMENT}_${questions[activeStep]?.qNum}`;
                                        launchTour(guidanceKey, getMAIntroSteps(questions[activeStep]?.qNum));
                                    }}
                                >
                                    <HelpIcon color="#fff" />
                                </Fab>
                            }
                            {activeStep !== 0 && (
                                <Button $error onClick={handleBack} style={{ marginRight: "10px" }}>
                                    back
                                </Button>
                            )}
                            <NextButton
                                activeStep={activeStep}
                                questions={questions}
                                submitMainQuestion={submitMainQuestion}
                                submitAllQuestions={submitAllQuestions}
                            />
                            {/* {activeStep < questions.length - 1 && (
                            <Button $success onClick={submitMainQuestion}>
                                next
                            </Button>
                        )} */}
                            {/* {activeStep === questions.length - 1 && (
                            <Button disabled={!userAnsweredAllQuestions} $success onClick={submitAllQuestions}>
                                submit
                            </Button>
                        )} */}

                        </ActionsWrapper>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
            </Wrapper >
        </>
    );
}

export default ControlMaturityQuestion;
