import React, { useState, useEffect } from "react";
import RiskGraphPage from "./Risk&Mitigation/Risk&Mitigation";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import DomainGraphPage from "./Domain&Mitigation/Domain&Mitigation";
import CombinedGraph from "./RiskMitigation&Domain/CombinedGraph";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Loader from "../../../../components/UI/Loader/Loader";
import { StyledComplateContainer } from "./ZenView.styled";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import sorryAnimation from "../../../../assets/animations/sorry.json";
import logo from "../../../../images/logo0.2.png";
import { Button } from "../../../../components/UI/Button/Button.styled";
import { getCompletedRiskMatrix, getRiskMatrixTableData } from "../../InformationAPI";
import { useKeycloak } from "@react-keycloak/web";
import { updateTrackerData } from "../../../../utility/tracker.action";

function ZenViewLoader() {
    const { fetchingBackgroundData } = useSelector((state) => state.data);

    const [fetching, setFetching] = useState(fetchingBackgroundData);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showSuccessAlert])

    useEffect(() => {

        if (fetching !== fetchingBackgroundData) {
            if (fetching && !fetchingBackgroundData) {
                setFetching(fetchingBackgroundData);
                setShowSuccessAlert(true);
            }
        }

    }, [fetching, fetchingBackgroundData])

    return <>
        <Collapse in={fetchingBackgroundData}>
            <Alert severity="warning">Zenview is being updated.  Please wait...</Alert>
        </Collapse>
        <Collapse in={showSuccessAlert}>
            <Alert severity="success">Zenview is now fully loaded</Alert>
        </Collapse>
    </>
}

const ZenView = () => {

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { loading, data, graphData, fetchingBackgroundData } = useSelector((state) => state.data);
    const history = useHistory();

    const [selectedGraphType, setSelectedGraphType] = useState('graph1');
    const [riskLoading, setRiskLoading] = useState(true);
    const [tableData, setTableData] = useState({ "tableData": [], "headingColumns": [], "selectedValue": "" });

    const handleGraphTypeChange = (event) => {
        setSelectedGraphType(event.target.value);
    };

    function handleRiskMatrix(processID) {
        if (processID === null) return;
        //update risk matrix table data
        getRiskMatrixTableData(processID, keycloak?.token)
            .then((response) => {
                if (response.success) {
                    setTableData(response.data);
                }
            })
            .finally(() => {
                setRiskLoading(false);
            })
    }

    useEffect(() => {
        if (keycloak?.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                let email = res.sub;
                if (email) {
                    getCompletedRiskMatrix(keycloak?.token, email)
                        .then((response) => {
                            let completedRiskID = response.data;
                            if (completedRiskID > 0) {
                                handleRiskMatrix(completedRiskID)
                            } else {
                                setRiskLoading(false);
                            }
                        })
                        .catch(() => {
                            setRiskLoading(false);
                        })
                }
            });
        }
    }, [keycloak?.authenticated])


    useEffect(() => {
        let graphMap = {
            'graph1': 'Risk & Mitigation',
            'graph2': 'Domain & Mitigation',
            'graph3': 'Risk, Mitigation & Domain',
        }
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `ZenView - ${graphMap[selectedGraphType]}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch, selectedGraphType])

    // Render the selected graph based on user selection
    let selectedGraph;
    switch (selectedGraphType) {
        case 'graph1':
            selectedGraph = <RiskGraphPage
                toleranceRisk={tableData?.selectedValue}
                GraphPicker={
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Select Graph Type</InputLabel>
                        <Select
                            value={selectedGraphType}
                            onChange={handleGraphTypeChange}
                            label="Select Graph Type"
                        >
                            <MenuItem value="graph1">Risk & Mitigation</MenuItem>
                            {/* <MenuItem value="graph2">Domain & Mitigation</MenuItem> */}
                            {/* <MenuItem value="graph3">Risk, Mitigation & Domain</MenuItem> */}
                        </Select>
                    </FormControl>
                }
            />;
            break;
        case 'graph2':
            selectedGraph = <DomainGraphPage
                toleranceRisk={tableData?.selectedValue}
                GraphPicker={
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Select Graph Type</InputLabel>
                        <Select
                            value={selectedGraphType}
                            onChange={handleGraphTypeChange}
                            label="Select Graph Type"
                        >
                            <MenuItem value="graph1">Risk & Mitigation</MenuItem>
                            <MenuItem value="graph2">Domain & Mitigation</MenuItem>
                            <MenuItem value="graph3">Risk, Mitigation & Domain</MenuItem>
                        </Select>
                    </FormControl>
                }
            />;
            break;
        case 'graph3':
            selectedGraph = <CombinedGraph
                toleranceRisk={tableData?.selectedValue}
                GraphPicker={
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Select Graph Type</InputLabel>
                        <Select
                            value={selectedGraphType}
                            onChange={handleGraphTypeChange}
                            label="Select Graph Type"
                        >
                            <MenuItem value="graph1">Risk & Mitigation</MenuItem>
                            <MenuItem value="graph2">Domain & Mitigation</MenuItem>
                            <MenuItem value="graph3">Risk, Mitigation & Domain</MenuItem>
                        </Select>
                    </FormControl>
                }
            />;
            break;
        default:
            selectedGraph = <div>No graph selected</div>;
    }

    if (loading || riskLoading) {
        return <Loader height="40vh" />;
    }

    if (fetchingBackgroundData && !(data?.length > 0 && graphData?.length > 0)) {
        selectedGraph = '';
    }

    if (!loading && !fetchingBackgroundData && !(data?.length > 0 && graphData?.length > 0)) {
        selectedGraph = <StyledComplateContainer>
            <Grid container>
                <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                    <img alt="" src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <br /><h1>You have to add Risk Assessment!</h1><br />
                    <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/risk-assessment")}>
                        Back To Risk Assessment
                    </Button>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </StyledComplateContainer>
    }

    return (
        <>
            <Helmet>
                <title>ZenView - CisoZen</title>
            </Helmet>
            <Box sx={{ height: 'calc(100vh - 96px)', overflow: 'auto' }} id="graph-box">
                <>
                    <ZenViewLoader />
                    {/* Render the selected graph */}
                    {selectedGraph}
                </>
            </Box>
        </>
    )
}

export default ZenView;