import { React, useEffect, useState } from "react";

import {
    StyledBgTd,
    StyledHeading,
    StyledTable,
    StyledTableContainer,
    TableContainerTitle,
} from "./EditableTable.styled";

const EditableTableV2 = ({ tableData, headingColumns, title, selectedValue, parentCallback }) => {
    // let rowData = [];
    const [isToleranceUpdated, setToleranceUpdated] = useState(false);
    const [tableDataLocal, settableDataLocal] = useState(tableData);

    // const data = tableDataLocal.map((row, index) => {

    //     let rowData = [];
    //     let i = 0;
    //     for (const key in row) {
    //         rowData.push({
    //             key: headingColumns[i],
    //             val: row[key]["value"],
    //             isSelected: row[key]["isSelected"],
    //             showLeftBorder: row[key]["showLeftBorder"],
    //             position: [index, i],
    //             showTopBorder: row[key]["showTopBorder"]
    //         });

    //         i++;
    //     }

    //     return (
    //         <tr key={index} >
    //             {rowData.map((data, index) => {
    //                 return (
    //                     <StyledBgTd
    //                         key={index}
    //                         data-heading={data.val}
    //                         datavalue={{ "value": data.val, "isSelected": data.isSelected, "showLeftBorder": data.showLeftBorder, "showTopBorder": data.showTopBorder }}
    //                         onClick={(e) => handleCellClick(data.position, data.val)}>
    //                         {data.val}
    //                     </StyledBgTd>
    //                 );
    //             })}
    //         </tr>
    //     );
    // });

    var valueList = {
        "Low-1": 1,
        "Low-2": 2,
        "Low-3": 3,
        "Low-4": 4,
        "Low-5": 5,
        "Low-6": 6,
        "Medium-1": 7,
        "Medium-2": 8,
        "Medium-3": 9,
        "Medium-4": 10,
        "Medium-5": 11,
        "Medium-6": 12,
        "Medium-7": 13,
        "Medium-8": 14,
        "Medium-9": 15,
        "High-1": 16,
        "High-2": 17,
        "High-3": 18,
        "High-4": 19,
        "High-5": 20,
        "High-6": 21,
        "High-7": 22,
        "Extreme-1": 23,
        "Extreme-2": 24,
        "Extreme-3": 25,
    };


    function isHigher(string1, string2) {
        return valueList[string2] >= valueList[string1]
    }

    function isShowTopBorder(key, rowIndex, isSelected) {
        const index = rowIndex > 0 ? rowIndex : 0;
        return index === 0 ? isSelected : tableDataLocal[rowIndex - 1][`${key}`]["isSelected"] === true ? false : isSelected;
    }

    function handleCellClick(position, value) {
        var dataToSendBack = { "SelectedTolerance": value };

        //update the tolerance line
        if (position[1] > 0) {
            if (tableDataLocal.length === 3) {
                //update data for 3x3
                for (let rowIndex = 0; rowIndex < tableDataLocal.length; rowIndex++) {
                    const element = tableDataLocal[rowIndex];
                    var likelihood = rowIndex === 0 ? "Rare" : rowIndex === 1 ? "Possible" : "Almost Certain";
                    var selectedInsignificant = isHigher(value, element["insignificant"]["value"]);
                    var selectedModerate = isHigher(value, element["Moderate"]["value"]);
                    var selectedCata = isHigher(value, element["catastrophic"]["value"]);

                    tableDataLocal[rowIndex] = {
                        number: { "value": likelihood, "isSelected": false },
                        insignificant: { "value": element["insignificant"]["value"], "isSelected": selectedInsignificant, "showLeftBorder": selectedInsignificant, "showTopBorder": isShowTopBorder("insignificant", rowIndex, selectedInsignificant) },
                        Moderate: { "value": element["Moderate"]["value"], "isSelected": isHigher(value, element["Moderate"]["value"]), "showLeftBorder": selectedInsignificant === true ? false : selectedModerate, "showTopBorder": isShowTopBorder("Moderate", rowIndex, selectedModerate) },
                        catastrophic: { "value": element["catastrophic"]["value"], "isSelected": isHigher(value, element["catastrophic"]["value"]), "showLeftBorder": selectedModerate === true ? false : selectedCata, "showTopBorder": isShowTopBorder("catastrophic", rowIndex, selectedCata) },
                    };
                    dataToSendBack["a" + (rowIndex + 1)] = element["insignificant"]["value"];
                    dataToSendBack["b" + (rowIndex + 1)] = element["Moderate"]["value"];
                    dataToSendBack["c" + (rowIndex + 1)] = element["catastrophic"]["value"];
                    settableDataLocal([...tableDataLocal])
                }
            } else if (tableDataLocal.length === 4) {
                //update data for 4x4
                for (let rowIndex = 0; rowIndex < tableDataLocal.length; rowIndex++) {
                    const element = tableDataLocal[rowIndex];
                    var likelihood = rowIndex === 0 ? "Rare" : rowIndex === 1 ? "Possible" : rowIndex === 2 ? "Likely" : "Almost Certain";
                    var selectedInsignificant = isHigher(value, element["insignificant"]["value"]);
                    var selectedModerate = isHigher(value, element["Moderate"]["value"]);
                    var selectedCata = isHigher(value, element["catastrophic"]["value"]);
                    var selectedMinor = isHigher(value, element["minor"]["value"]);

                    tableDataLocal[rowIndex] = {
                        number: { "value": likelihood, "isSelected": false },
                        insignificant: {
                            "value": element["insignificant"]["value"], "isSelected": selectedInsignificant,
                            "showLeftBorder": selectedInsignificant,
                            "showTopBorder": isShowTopBorder("insignificant", rowIndex, selectedInsignificant)
                        },
                        minor: {
                            "value": element["minor"]["value"], "isSelected": isHigher(value, element["minor"]["value"]),
                            "showLeftBorder": selectedInsignificant === true ? false : selectedMinor,
                            "showTopBorder": isShowTopBorder("minor", rowIndex, selectedMinor)
                        },
                        Moderate: {
                            "value": element["Moderate"]["value"], "isSelected": isHigher(value, element["Moderate"]["value"]),
                            "showLeftBorder": selectedMinor === true ? false : selectedModerate,
                            "showTopBorder": isShowTopBorder("Moderate", rowIndex, selectedModerate)
                        },
                        catastrophic: {
                            "value": element["catastrophic"]["value"], "isSelected": isHigher(value, element["catastrophic"]["value"]),
                            "showLeftBorder": selectedModerate === true ? false : selectedCata,
                            "showTopBorder": isShowTopBorder("catastrophic", rowIndex, selectedCata)
                        },
                    };
                    dataToSendBack["a" + (rowIndex + 1)] = element["insignificant"]["value"];
                    dataToSendBack["b" + (rowIndex + 1)] = element["minor"]["value"];
                    dataToSendBack["c" + (rowIndex + 1)] = element["Moderate"]["value"];
                    dataToSendBack["d" + (rowIndex + 1)] = element["catastrophic"]["value"];
                    settableDataLocal([...tableDataLocal])
                }
            } else if (tableDataLocal.length === 5) {
                //update data for 5x5
                for (let rowIndex = 0; rowIndex < tableDataLocal.length; rowIndex++) {
                    const element = tableDataLocal[rowIndex];
                    var likelihood = rowIndex === 0 ? "Rare" : rowIndex === 1 ? "Unlikely" : rowIndex === 2 ? "Possible" : rowIndex === 3 ? "Likely" : "Almost Certain";
                    var selectedInsignificant = isHigher(value, element["insignificant"]["value"]);
                    var selectedModerate = isHigher(value, element["Moderate"]["value"]);
                    var selectedCata = isHigher(value, element["catastrophic"]["value"]);
                    var selectedMinor = isHigher(value, element["minor"]["value"]);
                    var selectedMajor = isHigher(value, element["Major"]["value"]);

                    tableDataLocal[rowIndex] = {
                        number: { "value": likelihood, "isSelected": false },
                        insignificant: {
                            "value": element["insignificant"]["value"], "isSelected": selectedInsignificant,
                            "showLeftBorder": selectedInsignificant,
                            "showTopBorder": isShowTopBorder("insignificant", rowIndex, selectedInsignificant)
                        },
                        minor: {
                            "value": element["minor"]["value"], "isSelected": isHigher(value, element["minor"]["value"]),
                            "showLeftBorder": selectedInsignificant === true ? false : selectedMinor,
                            "showTopBorder": isShowTopBorder("minor", rowIndex, selectedMinor)
                        },
                        Moderate: {
                            "value": element["Moderate"]["value"], "isSelected": isHigher(value, element["Moderate"]["value"]),
                            "showLeftBorder": selectedMinor === true ? false : selectedModerate,
                            "showTopBorder": isShowTopBorder("Moderate", rowIndex, selectedModerate)
                        },
                        Major: {
                            "value": element["Major"]["value"], "isSelected": isHigher(value, element["Major"]["value"]),
                            "showLeftBorder": selectedModerate === true ? false : selectedMajor,
                            "showTopBorder": isShowTopBorder("Major", rowIndex, selectedMajor)
                        },
                        catastrophic: {
                            "value": element["catastrophic"]["value"], "isSelected": isHigher(value, element["catastrophic"]["value"]),
                            "showLeftBorder": selectedMajor === true ? false : selectedCata,
                            "showTopBorder": isShowTopBorder("catastrophic", rowIndex, selectedCata)
                        },
                    };

                    dataToSendBack["a" + (rowIndex + 1)] = element["insignificant"]["value"];
                    dataToSendBack["b" + (rowIndex + 1)] = element["minor"]["value"];
                    dataToSendBack["c" + (rowIndex + 1)] = element["Moderate"]["value"];
                    dataToSendBack["d" + (rowIndex + 1)] = element["Major"]["value"];
                    dataToSendBack["e" + (rowIndex + 1)] = element["catastrophic"]["value"];
                    settableDataLocal([...tableDataLocal])
                };
            };
            parentCallback(dataToSendBack);
            if (!selectedValue) {
                setToleranceUpdated(isToleranceUpdated === true ? false : true);
            } else {
                setToleranceUpdated(true);
            }

        }
    }

    useEffect(() => {
        if (selectedValue) {
            handleCellClick([1, 1], selectedValue);
        }
    }, []);

    return (
        <StyledTableContainer>
            <TableContainerTitle>
                <h2>{title}</h2>
            </TableContainerTitle>

            <div style={{ display: "flex" }}>
                <StyledHeading>Likelihood</StyledHeading>
                <StyledTable>
                    <thead>
                        <tr>
                            {headingColumns.map((col, index) => {
                                return <th key={index}>{col}</th>;
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {
                            tableDataLocal.map((row, index) => {

                                let rowData = [];
                                let i = 0;
                                for (const key in row) {
                                    rowData.push({
                                        key: headingColumns[i],
                                        val: row[key]["value"],
                                        isSelected: row[key]["isSelected"],
                                        showLeftBorder: row[key]["showLeftBorder"],
                                        position: [index, i],
                                        showTopBorder: row[key]["showTopBorder"]
                                    });

                                    i++;
                                }

                                return (
                                    <tr key={index} >
                                        {rowData.map((data, index) => {
                                            return (
                                                <StyledBgTd
                                                    key={index}
                                                    data-heading={data.val}
                                                    datavalue={{ "value": data.val, "isSelected": data.isSelected, "showLeftBorder": data.showLeftBorder, "showTopBorder": data.showTopBorder }}
                                                    onClick={(e) => handleCellClick(data.position, data.val)}>
                                                    {data.val}
                                                </StyledBgTd>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </StyledTable>
            </div>
        </StyledTableContainer>
    );
};

export default EditableTableV2;