import Grid from '@mui/material/Grid';
import HealthCheckCard from './HealthCheckCard';
import RiskSummaryCard from './RiskSummaryCard';
import OrganisationMaturityCard from './OrganisationMaturityCard';
import CisoZenScoreCard from './CisoZenScoreCard';
import CisoZenSupportCard from './CisoZenSupportCard';
import { useSelector } from 'react-redux';
import InformationMaturity from './InformationMaturity';
import { IntroCisoZenScoreData, IntroCisoZenSupportData, IntroHealthCheckCardData, IntroOrganisationMaturityData, IntroSecurityPostureData } from '../../../../../shared/intro-data';

function NewInformation({ score, oldScore, duration, healthCheck, maturity, recommendation, cyberSecurityRiskManagement, ...restProps }) {

    const { isIntroActive, hasTakenTourBefore, isPostureLoading, onDate, progress, idealProgress, totalOldMaturityScore, oldMaturity, handleNextStage, handleIdealStage } = restProps;

    const { organisationDetail } = useSelector((state) => state.data);

    return <Grid container spacing={2}>
        <Grid item lg={2} md={3} sm={6} xs={12}>
            <HealthCheckCard
                centerLabelTitle={`${healthCheck.duration.remainingDays} days remaining`}
                remainingDays={healthCheck?.duration?.remainingDays || 0}
                data={[
                    {
                        label: 'Your Progress',
                        value: healthCheck.score / 294 * 100,
                        color: 'rgb(237, 30, 36)',
                        tooltipValue: healthCheck.score
                    },
                    {
                        label: 'Target Progress',
                        value: 100 - (healthCheck.score / 294 * 100),
                        color: 'rgb(64, 64, 64)',
                        tooltipValue: 294
                    },
                    // {
                    //     label: 'Time Left',
                    //     value: 19,
                    //     color: 'rgb(189, 189, 189)'
                    // },
                ]}
                {...(isIntroActive && !hasTakenTourBefore && { ...IntroHealthCheckCardData })}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
            <CisoZenScoreCard
                centerLabelTitle={`${oldScore.currentScore} / ${oldScore.maxScore}`}
                data={[
                    {
                        label: 'Your Score',
                        value: oldScore.currentScore / oldScore.maxScore * 100,
                        color: 'rgb(70, 161, 27)',
                        tooltipValue: oldScore.currentScore
                    },
                    {
                        label: 'Target Score',
                        value: 100 - (oldScore.currentScore / oldScore.maxScore * 100),
                        color: 'rgb(189, 189, 189)',
                        tooltipValue: oldScore.maxScore
                    }
                ]}
                {...(isIntroActive && !hasTakenTourBefore && { ...IntroCisoZenScoreData })}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
            <RiskSummaryCard
                isIntroActive={isIntroActive}
                hasTakenTourBefore={hasTakenTourBefore}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
            <CisoZenSupportCard
                centerLabelTitle={`${organisationDetail.serviceHours} days left`}
                remainingDays={organisationDetail.serviceHours}
                data={[
                    {
                        label: 'Time Left',
                        value: organisationDetail.serviceHours / organisationDetail.totalDuration * 100,
                        color: 'rgb(70, 161, 27)',
                        tooltipValue: organisationDetail.serviceHours,
                    },
                    {
                        label: 'Total Duration',
                        color: 'rgb(189, 189, 189)',
                        value: 100 - (organisationDetail.serviceHours / organisationDetail.totalDuration * 100),
                        tooltipValue: organisationDetail.totalDuration,
                    }
                ]}
                {...(isIntroActive && !hasTakenTourBefore && { ...IntroCisoZenSupportData })}
            />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <OrganisationMaturityCard
                data={maturity?.currentScore}
                {...(isIntroActive && !hasTakenTourBefore && { data: IntroOrganisationMaturityData })}
            />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <InformationMaturity
                loading={isPostureLoading}
                onDate={onDate}
                progress={progress}
                // idealProgress={idealProgress}
                score={oldScore}
                stageNumber1={totalOldMaturityScore}
                // stageNumber2="2"
                // stageLevel1={totalOldMaturityScore + "/5"}
                // stageLevel2={(totalOldMaturityScore + 1) + "/5"}
                // stageHealth1="?"
                // stageHealth2="?"
                // link={handleNextStage()}
                // idealLink={handleIdealStage()}
                // firstLabels={maturity.requiredScore}
                // secondLabels={maturity.currentScore}
                oldSecondLabels={oldMaturity.currentScore}
                {...(isIntroActive && !hasTakenTourBefore && { ...IntroSecurityPostureData })}
            />
        </Grid>
        {recommendation}
        {cyberSecurityRiskManagement}
        {/* <Grid item lg={4} md={6} sm={12} xs={12}>
            <BusinessImpactCard />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <ThreatSummaryCard />
        </Grid> */}
    </Grid>

}

export default NewInformation;