// store.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk'; // Middleware for handling asynchronous actions
import dataReducer from './reducers/dataReducer';
import riskMatrixReducer from './reducers/riskMatrix.reducer';
import documentsReducer from './reducers/documents.reducer';
import projectReducer from './reducers/project.reducer';
import solutionReducer from './reducers/solution.reducer';
import solutionBuildingBlockReducer from './reducers/solutionBuildingBlock.reducer';
import threatReducer from './reducers/threat.reducer';
import traReducer from './reducers/tra.reducer';
import informationAssetRegisterReducer from './reducers/informationAssetRegister.reducer';
import soaReducer from './reducers/soa.reducer';
import miscellaneousReducer from './reducers/miscellaneous.reducer';
import trackerReducer from './reducers/tracker.reducer';
import riskRegisterReducer from './reducers/riskRegister.reducer';

const rootReducer = combineReducers({
  data: dataReducer,
  riskMatrix: riskMatrixReducer,
  documents: documentsReducer,
  projects: projectReducer,
  solutions: solutionReducer,
  solutionBuildingBlocks: solutionBuildingBlockReducer,
  threats: threatReducer,
  tras: traReducer,
  informationAssetRegisters: informationAssetRegisterReducer,
  soa: soaReducer,
  miscellaneous: miscellaneousReducer,
  tracker: trackerReducer,
  riskRegister: riskRegisterReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
