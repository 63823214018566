import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { QuestionCard } from '../../../pages/Dashboard/components/QuestionsStepper/QuestionsStepper.styled';


const ImageSelectionComponent = ({ options, onSelectionChange }) => {
    const [selectedImages, setSelectedImages] = useState([]);

    // const handleToggle = (value) => () => {
    //     const currentIndex = selectedImages.indexOf(value);
    //     let newSelectedImages = [...selectedImages];
    //     if (currentIndex === -1) {
    //         newSelectedImages.push(value);
    //     } else {
    //         newSelectedImages.splice(currentIndex, 1);
    //     }

    //     setSelectedImages(newSelectedImages);
    //     onSelectionChange(newSelectedImages);
    // };
    const handleToggle = (value) => () => {
        if (selectedImages === value) {
            setSelectedImages(null);
        } else {
            setSelectedImages(value);
        }
        onSelectionChange(selectedImages === value ? null : value);
    };
    return (
        <>
            <p style={{ marginBottom: '20px' }}>Please select from the options below:</p>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {options.map((option, index) => {
                    const labelId = `checkbox-list-secondary-label-${index}`;
                    return (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={handleToggle(option)}>
                                <ListItemText id={labelId} primary={option} />
                                <Checkbox
                                    edge="end"
                                    checked={selectedImages.includes(option)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemButton>
                            <Divider sx={{ backgroundColor: "white" }} />
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

export default ImageSelectionComponent;
