import styled, { css } from "styled-components";

export const UserTypeCard1 = styled.div`
// background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
  // min-height: 483px;
  width:80%;
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: 8px;
  display: flex;
  position: relative;
  isolation: isolate;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 72% 30px 30px 30px;
  box-sizing: border-box;
  height: auto;
  margin-top:66px;
  transition: border-color 0.3s ease;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
    width: 100%;
    padding: 72% 30px 30px 30px;
}
@media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
  width: 100%;
    padding: 72% 30px 30px 30px;
}
`;

export const Title = styled.div`
  text-align: center;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-family: Rubik;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: capitalize;
  align-self: stretch;
  margin: 0px;
`;

export const DescriptionGroup = styled.div`
  font-style: normal;
  font-family: Rubik;
  font-weight: 400;
  font-size: 16px;
`;
export const Description = styled.div`
  text-align: left;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 24px;
  text-transform: none;
  align-self: stretch;
  margin: 5px 0px 0px 0px;

  &::before {
    content: '•';
    margin-right: 8px;
  }
`;

export const Icon1 = styled.img`
  position: absolute;
  margin: 0 !important;
  top: -20%;
  height: 320px;
  width:80%;
  object-fit: contain;
  z-index: 2;
`;