
import {
  LegendBox,
  YellowIndicator,
  Domain,
  Item,
  Item2
} from "../Common/Graph.styled";
import Grid from '@mui/material/Grid';
const LegendPage = () => {

  return (
    //  <LegendBox>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {/* <Grid md={12}><MyTextColor><Item2>Legend</Item2></MyTextColor></Grid> */}
      <Grid item sm={3} md={3}>
        <Item><Domain /></Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>Domain</p></Item2>
      </Grid>
      <Grid item sm={3} md={3}>
        <Item><YellowIndicator /></Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>Mitigation</p></Item2>
      </Grid>
    </Grid>
    // </LegendBox>
  );
};

export default LegendPage;