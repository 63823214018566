
const initialState = {
  allowFetch: false, // Initialize as false
  loading: false,
  completedRiskMatrixData: null,
  error: null,
  riskMatrixTableData: { "tableData": [], "headingColumns": [], "selectedValue": "" },
  riskInformation: null,
  riskActions: null,
  riskConsequence: { levels: [], fields: [], tabs: [], rawData: [] },
  riskMatrixExists: null,
  riskMatrixDimensions: null,
  completedRiskID: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RM_TOGGLE_ALLOW_FETCH':
      return { ...state, allowFetch: action.payload };
    case 'RM_TOGGLE_LOADING':
      return { ...state, loading: action.payload };
    case 'RM_FETCH_DATA_SUCCESS':
      return {
        ...state,
        completedRiskID: action.payload.completedRiskID,
        completedRiskMatrixData: action.payload.completedRiskMatrixData,
        riskMatrixTableData: action.payload.riskMatrixTableData,
        riskInformation: action.payload.riskInformation,
        riskActions: action.payload.riskActions,
        riskConsequence: action.payload.riskConsequence,
        riskMatrixExists: action.payload.riskMatrixExists,
        riskMatrixDimensions: action.payload.riskMatrixDimensions,
        loading: false,
        error: null
      };
    case 'RM_FETCH_DATA_ERROR':
      return {
        ...state,
        completedRiskID: null,
        completedRiskMatrixData: null,
        riskMatrixTableData: initialState.riskMatrixTableData,
        loading: false,
        error: action.payload
      };
    case 'RM_UPDATE_RISK_MATRIX_EXISTS':
      return {
        ...state,
        riskMatrixExists: action.payload,
      };
    case 'RM_UPDATE_RISK_MATRIX_DIMENSIONS':
      return {
        ...state,
        riskMatrixDimensions: action.payload,
      };
    case 'RM_UPDATE_RISK_MATRIX_INFORMATION':
      return {
        ...state,
        riskInformation: action.payload,
      };
    case 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE':
      return {
        ...state,
        riskConsequence: action.payload,
      };
    case 'RM_UPDATE_RISK_MATRIX_ACTIONS':
      return {
        ...state,
        riskActions: action.payload,
      };
    case 'RM_UPDATE_RISK_MATRIX_TOLERANCE':
      return {
        ...state,
        riskMatrixTableData: {
          ...state.riskMatrixTableData,
          selectedValue: action.payload
        },
      };
    case 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE_RAW_DATA':
      return {
        ...state,
        riskConsequence: {
          ...state.riskConsequence,
          rawData: action.payload
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
