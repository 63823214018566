import React, { useEffect, useState } from "react";
import "./Registration.css";
import { authenticate, startNewInstance } from "./RegistrationAPI";
import Loader from "../../../../../components/UI/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../../../../../components/UI/Button/Button.styled";
import { toast } from "react-toastify";
import { retrieveData } from "../../../../../shared/utility";
export const industries = [
  {
    value: 'Agriculture, forestry and fishing',
    label: 'Agriculture, forestry and fishing',
  },
  {
    value: 'Mining',
    label: 'Mining',
  },
  {
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    value: 'Electricity, gas, water and waste services',
    label: 'Electricity, gas, water and waste services',
  },
  {
    value: 'Construction',
    label: 'Construction',
  },
  {
    value: 'Wholesale trade',
    label: 'Wholesale trade',
  },
  {
    value: 'Retail trade',
    label: 'Retail trade',
  },
  {
    value: 'Accommodation and food services',
    label: 'Accommodation and food services',
  },
  {
    value: 'Transport, postal and warehousing',
    label: 'Transport, postal and warehousing',
  },
  {
    value: 'Information media and telecommunications',
    label: 'Information media and telecommunications',
  },
  {
    value: 'Rental, hiring and real estate services',
    label: 'Rental, hiring and real estate services',
  },
  {
    value: 'Professional, scientific and technical services',
    label: 'Professional, scientific and technical services',
  },
  {
    value: 'Administrative and support services',
    label: 'Administrative and support services',
  },
  {
    value: 'Public administration and safety (private)',
    label: 'Public administration and safety (private)',
  },
  {
    value: 'Education and training (private)',
    label: 'Education and training (private)',
  },
  {
    value: 'Health care and social assistance (private)',
    label: 'Health care and social assistance (private)',
  },
  {
    value: 'Arts and recreation services',
    label: 'Arts and recreation services',
  },
  {
    value: 'Other services',
    label: 'Other services',
  }
];

export const sizes = [
  {
    value: '1-19',
    label: '1-19',
  },
  {
    value: '20-199',
    label: '20-199',
  },
  {
    value: '200+',
    label: '200+',
  }
]

const Registration = (props) => {
  const { isSubmit } = props;

  //firstName, lastName, email, industry, size, organisationName
  const [isSubmitted, setIsSubmitted] = useState(isSubmit);
  const [loading, setLoading] = useState(false);
  const [isValidRecaptcha, setValidRecaptcha] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    industry: "",
    size: "",
    organisationName: ""
  });
  let name, value;
  const inputHandler = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUserData({ ...userData, [name]: value });
  };

  var successMessage = "Congratulations! Your data was submitted, we will notify by email when your account is ready!"

  function handleSubmit(e) {
    const { firstName, lastName, email, industry, size, organisationName } = userData;
    e.preventDefault();
    if (!firstName || !lastName || !email || !organisationName || !industry || !size) {
      toast.error("Please fill all the required fields!")
      return;
    }

    setLoading(true);

    authenticate().then((tokenData) => {
      if (tokenData.success) {
        //start new process instance
        startNewInstance(userData, tokenData.id).then((response) => {
          if (response.success) {
            setIsSubmitted(true);
            setLoading(false);
          } else {
            setIsSubmitted(false);
          }
          
        });
      } else {
        setLoading(false);
      }
    });

  }

  const onCaptchaChange = async (e) => {
    if (e) {
      setValidRecaptcha(true);
    } else {
      setValidRecaptcha(false);
    }
  }

  useEffect(() => {
    return () => {
      setValidRecaptcha(false);
    }
  }, []);

  return (
    <>
      <div className="container">
        {loading ? <Loader></Loader> :
          isSubmitted ? successMessage : <div className="Registration_form">
            <div className="content">
              <form action="#">
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">First Name*</span>
                    <input
                      type="text"
                      placeholder="Enter your first name"
                      required
                      name="firstName"
                      value={userData.firstName}
                      onChange={inputHandler}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Last Name*</span>
                    <input
                      type="text"
                      placeholder="Enter your last name"
                      required
                      name="lastName"
                      value={userData.lastName}
                      onChange={inputHandler}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Email*</span>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      required
                      name="email"
                      value={userData.email}
                      onChange={inputHandler}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Organisation Name*</span>
                    <input
                      type="text"
                      placeholder="Enter organisation name"
                      required
                      name="organisationName"
                      value={userData.organisationName}
                      onChange={inputHandler}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Industry*</span>
                    <select id="industry" name="industry" onChange={inputHandler}>
                    <option value="" >Select industry</option>
                      {
                        industries.map((industry)=>{
                          return(
                            <option value={industry.value} key={industry.value}>{industry.label}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="input-box">
                    <span className="details">Size*</span>
                    <select id="size" name="size" onChange={inputHandler}>
                      <option value="">Select size</option>
                      {
                        sizes.map((size)=>{
                          return(
                            <option value={size.value} key={size.value}>{size.label}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="gender-details" onClick={inputHandler}>
                  <input type="radio" name="gender" id="dot-1" value="male" onClick={inputHandler} />
                  <input type="radio" name="gender" id="dot-2" value="female" onClick={inputHandler} />
                  <input type="radio" name="gender" id="dot-3" value="other" onClick={inputHandler} />
                  <label></label>
                </div>
                <ReCAPTCHA sitekey={retrieveData(process.env.REACT_APP_CAPTCHA_KEY)} onChange={(e) => onCaptchaChange(e)} />
                <div className="button">
                  <Button $success type="success" data-action='submit' onClick={handleSubmit} >Submit</Button>
                </div>
              </form>

            </div>
          </div>
        }

      </div>
    </>
  );
};

export default Registration;