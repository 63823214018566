import styled from "styled-components";

export const ContentContainer = styled.div`
    box-sizing: border-box;
    color: #fff;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    border-radius: 8px;
    font-family: "Rubik", sans-serif;
    padding: 34px 42px 16px;
    overflow: auto;
    @media (max-width: 599.98px) {
        padding: 34px 7px 19px 17px;
        max-width: 100vw;
    }
`;

export const CenteredDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    flex-wrap: wrap;
`;

export const CustomSpan = styled.span`
    min-width: 200px;
`;

export const HiddenDiv = styled.div`
    width: 40px;
    height: 40px;
    opacity: 0;
`;

