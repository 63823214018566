import { Fragment, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import { Wrapper } from "./DocumentsLandingPage.styled";
import { useKeycloak } from "@react-keycloak/web";
import DocumentsTable from "./components/DocumentsTable";
import { CONTAINER_ID } from "../../../../store/auth-context";
import { closeCase, completeUserTask, getCaseDocumentContent, getProcessIDByCase, getUserTask, initGenerateDocumentContent, startDocumentProcess, startUserTask } from "../TRA/TRAApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentData, toggleCreateNewCaseLoading, toggleGenerateDocumentLoading, updateDocumentContentData } from "../../../../utility/documents.action";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

function DocumentsLandingPage(props) {

    const { keycloak } = useKeycloak();

    const dispatch = useDispatch();
    const documentsState = useSelector((state) => state.documents);
    const { documentCaseData, } = documentsState;

    useEffect(() => {
        if (documentsState.allowFetch) {
            dispatch(fetchDocumentData(keycloak));
        }
    }, [])

    const [modalOpen, setModalOpen] = useState(false);

    const [fetching, setFetching] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [disableActionMeta, setDisableActionMeta] = useState(null);
    const [generatDocumentLoading, setGenerateDocumentLoading] = useState(false);

    const toggleDisableAction = (meta = null) => {
        setDisableActions(prev => !prev);
        setDisableActionMeta(meta);
    }

    async function generateDocument(documentData) {
        try {
            dispatch(toggleGenerateDocumentLoading(true, documentData['id']));
            setGenerateDocumentLoading(true);
            const caseID = documentCaseData['case-id'];

            // Check if the document is already generate or not
            let res = await getCaseDocumentContent(caseID, `FILE${documentData['id']}`);
            if (res.data && res.data['org.kie.server.api.model.type.JaxbByteArray'] && res.data['org.kie.server.api.model.type.JaxbByteArray']['value']) {
                let documentContent = res.data['org.kie.server.api.model.type.JaxbByteArray']['value'];
                dispatch(updateDocumentContentData(keycloak, documentData['id'], documentContent));
                return;
            } else {

                // Initialize generate content
                const res1 = await initGenerateDocumentContent(caseID);
                const res2 = await getProcessIDByCase(CONTAINER_ID, keycloak?.token, caseID);
                if (!res2.success) {
                    setGenerateDocumentLoading(false);
                    return;
                }
                const res3 = await getUserTask(CONTAINER_ID, keycloak.token, res2.data['process-instance'][0]['process-instance-id']);
                if (res3.success) {
                    const task = res3.data["active-user-tasks"]["task-summary"].find((t) => t["task-name"] === "GetContentTask");
                    if (task) {
                        const taskID = task["task-id"];
                        if (task["task-status"] !== "InProgress") {
                            const res4 = await startUserTask(CONTAINER_ID, keycloak.token, taskID);
                        }
                        const data = {
                            "DocumentID": documentData['id'],
                        }
                        const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, taskID, data);
                    }
                }
                setGenerateDocumentLoading(false);
            }
        } catch (e) {
            setGenerateDocumentLoading(false);
            dispatch(toggleGenerateDocumentLoading(false));
            toast.error('Failed to generate document. Please try again.')
        }

    }

    function downloadDocument(doc) {
        const base64String = doc.fileContent;

        // Decode the base64 string into a byte array
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Convert the byte array into a Blob
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link for the download
        const link = document.createElement('a');
        link.href = url;
        link.download = doc.name; // Specify the name and type of the file here
        link.click();

        // deleteCaseDocumentFile(documentCaseData['case-id'], `FILE${doc['id']}`)
        //     .then((response) => {
        //         dispatch(updateDocumentContentData(keycloak, doc['id'], null))
        //     })
        //     .catch((error) => {
        //         dispatch(updateDocumentContentData(keycloak, doc['id'], null))
        //     });
    }

    function regenerateCase() {
        dispatch(toggleCreateNewCaseLoading(true));
        closeCase(CONTAINER_ID, keycloak.token, documentCaseData['case-id'])
            .then((response) => {
                if (response.success) {
                    startDocumentProcess();
                } else {
                    dispatch(toggleCreateNewCaseLoading(false));
                    toast.error(response.error);
                }
            })
            .catch((err) => {
                dispatch(toggleCreateNewCaseLoading(false));
                toast.error(err?.message || err);
            });
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    let content;

    if (documentsState.loading || fetching) {
        content = <Loader height="40vh" />;
    }

    if (!fetching && !documentsState.loading) {
        content = (
            <Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DocumentsTable
                            regenerateCase={regenerateCase}
                            generateDocument={generateDocument}
                            downloadDocument={downloadDocument}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Documents - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Documents
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default DocumentsLandingPage;
