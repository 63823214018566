import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
`;

export const StatsWrapper = styled.div`
    padding-top: 17px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-top: 28px;
    }
`;

export const ActionContainer = styled.div`
    margin-top: 21px;
    & .MuiButton-root {
        border-color: rgb(58, 141, 28);
        border-width: 2px;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};

        &:hover {
            border-width: 2px;
        }
    }
`

export const ProgressCard = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 15px 16px;
`;

export const ProgressCardContent = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const ProgressCardContentTop = styled.div`
    
`;

export const CardHead = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
`;

export const CardSubHead = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    opacity: 0.75;
    margin-top: 4px;
`;

export const ProgressContainer = styled.div`
    margin-top: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProgressWrapper = styled.div`
    width: 150px;
    height: 150px;
`;

// background: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : 'rgb(51, 51, 51)')};
export const ProgressWrapperRadar = styled.div`
    width: 350px;
    height: 350px;
`;

export const ProgressWrapperBar = styled.div`
    width: 100% !important;
        
    & canvas {
        width: 100% !important;
    }
`;

export const LegendContainer = styled.div`
    margin-top: 21px;
`;

export const TextBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const DotSign = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 13px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#BDBDBD" : "#fff")};
`;

export const StatsText = styled.div`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
`;