import React, { useState } from "react";

import { Button } from "../../../../components/UI/Button/Button.styled";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import { DescriptionContent, DescriptionTextHead, DescriptionTextP, DescriptionTextParagraph, StyledList, StyledInput } from "../RiskList/RiskList.styled";
import { CenteredDiv } from "../RiskTabs/RiskTabs.styled";
import { useDispatch, useSelector } from "react-redux";

const RiskActions = (props) => {

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const { parentCallback, handleEdit } = props;
    const { riskActions } = useSelector((state) => state.riskMatrix);
    const { levels, actions, plans } = riskActions;

    parentCallback(dataForApi());

    function dataForApi() {
        //rmRow1 : first col
        //rmAction1 : second col
        //rmTreatment1 : last col
        var obj = {};
        for (let index = 0; index < levels.length; index++) {
            obj[`rmRow${index + 1}`] = levels[index];
            obj[`rmAction${index + 1}`] = actions[index];
            obj[`rmTreatment${index + 1}`] = plans[index];
        }
        return obj
    }

    function handleEditButtonClick() {
        setIsEdit(!isEdit);
        handleEdit(!isEdit);
    }

    function handleDescriptionChange(e) {
        let newActions = [...riskActions.actions];
        newActions[e.target.id] = e.target.value;
        dispatch({
            type: 'RM_UPDATE_RISK_MATRIX_ACTIONS',
            payload: {
                ...riskActions,
                actions: newActions
            }
        })
    }

    function handlePlanChange(e) {
        let newPlans = [...riskActions.plans];
        newPlans[e.target.id] = e.target.value;
        dispatch({
            type: 'RM_UPDATE_RISK_MATRIX_ACTIONS',
            payload: {
                ...riskActions,
                plans: newPlans
            }
        })
    }

    return (
        <>
            <RecHeading MdWidth actionsPad>
                Change the details of risk management action to match your
                business requirements or accept the following:
            </RecHeading>

            <StyledList
                mZero
                actionPad
                smsize
            >
                <li >
                    <DescriptionContent
                        actionWidth
                    >
                        <DescriptionTextHead
                            acSmWidth
                        >
                            Risk Level
                        </DescriptionTextHead>
                        <DescriptionTextParagraph
                            acMdWidth
                        >
                            Management Action Required
                        </DescriptionTextParagraph>
                        <DescriptionTextP>Risk Treatment Plan</DescriptionTextP>
                    </DescriptionContent>
                </li>
                {levels.map((level, index) => {
                    return (
                        <li key={index}>
                            <DescriptionContent actionWidth>
                                <DescriptionTextHead acSmWidth>
                                    {level}
                                </DescriptionTextHead>
                                {
                                    isEdit ? <StyledInput type="text"
                                        defaultValue={actions[index]}
                                        id={index}
                                        maxLength='500' acMdWidth onChange={(event) => handleDescriptionChange(event)} />
                                        : <DescriptionTextParagraph acMdWidth>{actions[index]}</DescriptionTextParagraph>
                                }
                                {
                                    isEdit ? <StyledInput type="text"
                                        defaultValue={plans[index]}
                                        id={index}
                                        maxLength='500' acMdWidth onChange={(event) => handlePlanChange(event)} />
                                        : <DescriptionTextP acMdWidth>{plans[index]}</DescriptionTextP>
                                }
                            </DescriptionContent>
                        </li>
                    )
                })}
            </StyledList>

            <CenteredDiv acMargin>
                <Button $edit onClick={handleEditButtonClick}>
                    {
                        !isEdit ? "Edit" : "Save"
                    }
                </Button>
            </CenteredDiv>
        </>
    );
};

// const RiskActions = (props) => {

//     const [isEdit, setIsEdit] = useState(false);
//     const {riskActions, parentCallback, handleEdit} = props
//     const { levels, actions, plans } = riskActions;

//     parentCallback(dataForApi());

//     function dataForApi() {
//         //rmRow1 : first col
//         //rmAction1 : second col
//         //rmTreatment1 : last col
//         var obj = {};
//         for (let index = 0; index < levels.length; index++) {
//             obj[`rmRow${index+1}`] = levels[index];
//             obj[`rmAction${index+1}`] = actions[index];
//             obj[`rmTreatment${index+1}`] = plans[index];
//         }
//         return obj
//     }

//     function handleEditButtonClick() {
//         setIsEdit(!isEdit);
//         handleEdit(!isEdit);
//     }

//     function handleDescriptionChange(e) {
//         actions[e.target.id] = e.target.value;
//     }

//     function handlePlanChange(e) {
//         plans[e.target.id] = e.target.value;
//     }

//     return (
//         <>
//             <RecHeading MdWidth actionsPad>
//                 Change the details of risk management action to match your
//                 business requirements or accept the following:
//             </RecHeading>

//             <StyledList
//                 mZero
//                 actionPad
//                 smsize
//             >
//                 <li >
//                     <DescriptionContent
//                         actionWidth
//                     >
//                         <DescriptionTextHead
//                             acSmWidth
//                         >
//                             Risk Level
//                         </DescriptionTextHead>
//                         <DescriptionTextParagraph
//                             acMdWidth
//                         >
//                             Management Action Required
//                         </DescriptionTextParagraph>
//                         <DescriptionTextP>Risk Treatment Plan</DescriptionTextP>
//                     </DescriptionContent>
//                 </li>
//                 {levels.map((level, index) => {
//                     return (
//                         <li key={index}>
//                             <DescriptionContent actionWidth>
//                                 <DescriptionTextHead acSmWidth>
//                                     {level}
//                                 </DescriptionTextHead>
//                                 {
//                                 isEdit ? <StyledInput type="text"
//                                 defaultValue={actions[index]}
//                                     id={index}
//                                     maxLength='500' acMdWidth onChange={(event) => handleDescriptionChange(event)}/>
//                                     : <DescriptionTextParagraph acMdWidth>{actions[index]}</DescriptionTextParagraph>
//                                 }
//                                 {
//                                 isEdit ? <StyledInput type="text"
//                                 defaultValue={plans[index]}
//                                     id={index}
//                                     maxLength='500' acMdWidth onChange={(event) => handlePlanChange(event)}/>
//                                     : <DescriptionTextP acMdWidth>{plans[index]}</DescriptionTextP>
//                                 }
//                             </DescriptionContent>
//                         </li>
//                     )
//                 })}
//             </StyledList>

//             <CenteredDiv acMargin>
//             <Button $edit onClick={handleEditButtonClick}>
//                         {
//                             !isEdit ? "Edit" : "Save"
//                         }
//                     </Button>
//             </CenteredDiv>
//         </>
//     );
// };

export default RiskActions;
