import React, { Fragment, useEffect, useState, useCallback } from "react";
import Lottie from "lottie-react";

import Heading from "../../../../../components/UI/Heading/Heading";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import QuestionHeading from "../../../../../components/Question/QuestionHead/QuestionHeading/QuestionHeading";
import { Button, StyledButton } from "../../../../../components/UI/Button/Button.styled";
import { useKeycloak } from "@react-keycloak/web";
import { CONTAINER_ID, AssessMaturity, TRAProcess } from "../../../../../store/auth-context";
import { getMileStones, getActiveCases, updateProcessID, getVariablesfromApi, getVariablesValuesfromApi, getClosedCases } from "./../SecurityControlMaturityAssessmentApi";
import MaturityTableData from "../../../components/MaturityScoreInformation/MaturityTableData";
import SectionTableData from "../../../components/MaturityScoreInformation/SectionTableData";
import DomainTable from "../../../components/MaturityScoreInformation/DomainTable";
import sorryAnimation from "../../../../../assets/animations/sorry.json";
import logo from "../../../../../images/logo0.2.png";


import {
    Wrapper,
    SubHeading,
    StatsWrapper,
    StyledSubHeading,
    CustomAccordion,
    CustomSubQAccordionDetails,
    QuestionHeadWrapper,
    QuestionNumber,
    CustomFormControl,
    ButtonWrapper,
    StyledCompleteContainer
} from "./MaturityScores.styled";
import { getCase, startNewCase, updateCaseFileData } from "../../TRA/TRAApi";
import { useHistory } from "react-router";
import Loader from "../../../../../components/UI/Loader/Loader";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getCompletedRiskMatrix } from "../../../InformationAPI";
import { Helmet } from "react-helmet-async";
import { updateTrackerData } from "../../../../../utility/tracker.action";
import { useDispatch } from "react-redux";

function MaturityScores(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const history = useHistory();
    const [itemList, setItemList] = useState([]);
    const [title, setTitle] = useState("Expand All");
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const [domainList, setDomainList] = useState(null);
    const [scores, setScores] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [completedRiskID, setCompletedRiskID] = useState(null);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Maturity Assessment`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    const handleClick = () => {
        setTitle(state => {
            return state === "Expand All" ? "Collapse All" : "Expand All";
        });

        if (title === "Expand All") {
            //create a new array containing index values of all the accordions and then set this new array to be the state array, which effectively means that all accordions are expanded
            const newArray = [];
            domainList.forEach((c, index) => newArray.push(index));
            setExpandedAccordions(newArray);

            let newDomainList = domainList.map((domain) => {
                domain.isExpanded = true;
                return domain;
            })
            setDomainList(newDomainList);
        }
        else {
            setExpandedAccordions([]); //clear the state array to [], which will trigger all the accordions to collapse
            let newDomainList = domainList.map((domain) => {
                domain.isExpanded = false;
                return domain;
            })
            setDomainList(newDomainList);
        }

    }

    function checkRiskMatrix() {
        getCompletedRiskMatrix(keycloak.token, "").then((response) => {
            setCompletedRiskID(response.data);
            if (response.data > 0) {
                getClosedMaturity();
            } else {
                setLoading(false)
            }
        });
    }

    useEffect(() => {
        if (CONTAINER_ID === "") return;

        checkRiskMatrix();
    }, [CONTAINER_ID])

    function getClosedMaturity() {

        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'closed', 1).then((response) => {
            if (response.success) {
                setLoading(false)
                let maturityList = response.data.instances;
                if (maturityList.length > 0) {
                    let caseData = maturityList[0]['case-file']['case-data'];
                    if (caseData) {
                        let domainList = [];
                        Object.keys(caseData).map((key) => {
                            if (key.startsWith('Domain')) {
                                let domain = JSON.parse(caseData[key]);
                                if (domain) {
                                    domain.isExpanded = false;
                                    domainList.push(domain);
                                }
                            }
                        });


                        if (caseData["maturityScores"]) {
                            setScores(caseData["maturityScores"]);
                            window.sessionStorage.setItem("MA_OLD_VAR", JSON.stringify(caseData["maturityScores"]));
                        }

                        let sortedList = domainList.sort((a, b) => a.domainID - b.domainID);
                        setDomainList(sortedList);
                    }
                }
            }
        });
    }


    function formatData(scoreId, scoreTxt, description) {
        return { scoreId, scoreTxt, description };
    }

    const assessmentScaleData = [
        formatData(0, "Non-existent", "The control is not implemented"),
        formatData(1, "Initial/Ad Hoc", "The approach is ad hoc and disorganized"),
        formatData(2, "Repeatable but Intuitive", "The control is typically dependent on the knowledge and motivation of individuals"),
        formatData(3, "Defined", "The control is implemented using a documented process"),
        formatData(4, "Managed & Measurable", "The control’s performance is measured for effectiveness"),
        formatData(5, "Optimized", "The control’s performance measurements are used to continuously improve quality"),
    ];

    function startNewMaturityAssessment() {

        startNewCase(CONTAINER_ID, keycloak.token, "startMaturityAssessment", {}).then((response) => {
            if (response.success === true) {

                setTimeout(() => {
                    getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'open', 1).then(async (response) => {
                        if (response.success) {
                            let instances = response.data.instances;
                            if (instances?.length > 0) {
                                let caseID = response.data.instances[0]['case-id'];
                                let data = {
                                    "maturityScores": {
                                        "com.cisozen.MaturityScores": {
                                            ...scores,
                                        }
                                    }
                                }

                                updateCaseFileData(CONTAINER_ID, keycloak.token, caseID, data).then(async (response) => {
                                    setIsLoading(false);
                                    history.push('/it-security/maturity-assessment/new');
                                })
                            }
                        }
                    })
                }, 1500)

            } else {
                setIsLoading(false);
                toast.error("Something went wrong. Please try again later.");
            }
        });
    }

    const handleNewMaturity = () => {
        setIsLoading(true);
        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'open', 1).then((response) => {
            if (response.success) {
                let instances = response.data.instances;
                if (instances) {
                    if (instances.length > 0) {
                        setIsLoading(false);
                        history.push('/it-security/maturity-assessment/new');
                    } else {
                        startNewMaturityAssessment();
                    }
                }
            }
        })
    }

    function handleCollapse(index) {
        let newList = [...domainList];
        newList[index].isExpanded = !newList[index].isExpanded;
        setDomainList(newList);
    }

    useEffect(() => {
        if (domainList === null || domainList.length === 0) return;
        var items = [];
        domainList.forEach((domain, index) => {
            items.push(<QuestionHeadWrapper key={index}>
                <QuestionNumber >{domain.domainID}</QuestionNumber>
                <QuestionHeading >{domain.domainHeading}</QuestionHeading>
                <CustomFormControl >
                    <CustomAccordion
                        onChange={() => handleCollapse(index)}
                        expanded={domain.isExpanded}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} />
                        <CustomSubQAccordionDetails>
                            {
                                domain && scores ? <>
                                    <DomainTable domain={domain} scores={scores}></DomainTable><br />
                                    <SectionTableData sections={domain["sections"]} data={domain} scores={scores}> </SectionTableData>
                                </> : ""
                            }
                        </CustomSubQAccordionDetails>
                    </CustomAccordion>
                </CustomFormControl >
            </QuestionHeadWrapper>)
        })
        setItemList(items);
    }, [domainList])

    let content;
    if (loading) {
        content = <Loader />
    }

    if (!loading && completedRiskID !== 0) {
        content = <>
            <StatsWrapper>
                <SubHeading marginMd="32px 0" marginSm="24px 18px">Control Effectiveness Assessment Scale</SubHeading><br />
                <MaturityTableData data={assessmentScaleData}></MaturityTableData>
            </StatsWrapper><br /><br /><br />
            <StatsWrapper>
                {
                    itemList.length === 0 ? '' : <>
                        <StatsWrapper>
                            <Heading marginMd="32px 0" marginSm="24px 18px">
                                Maturity Assessment Result
                            </Heading>
                            {
                                itemList.length === 0 ? "" :
                                    <Button $success style={{ float: "right", marginTop: "20px" }} onClick={handleClick}>{title}</Button>
                            }
                        </StatsWrapper>
                        <StyledSubHeading marginMd="32px 0" marginSm="24px 18px">
                            {/* Aggregate Score (Overall): <span className="agregateScore">2.0</span> */}
                        </StyledSubHeading>
                    </>
                }
                <>
                    {itemList}
                </>
                {
                    !(completedRiskID > 0) ? <></> : <ButtonWrapper>
                        {/* <Button $success onClick={handleNewMaturity}>Edit Maturity Assessment</Button> */}
                        <Button $success onClick={handleNewMaturity} disabled={isLoading}>
                            {
                                isLoading ? <CircularProgress style={{ height: "26px", width: "87px", padding: "0px 30px" }}>Edit Maturity Assessment</CircularProgress> : "Edit Maturity Assessment"

                            }
                        </Button>
                    </ButtonWrapper>
                }
            </StatsWrapper>
        </>
    }

    if (!loading && !(completedRiskID > 0)) {
        content = <StyledCompleteContainer>
            <Grid container>
                <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                    <img src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }} alt="" />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <br /><h1>You have to complete Risk Matrix!</h1><br />
                    <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/risk-matrix")}>
                        Back To Risk Matrix
                    </Button>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </StyledCompleteContainer>
    }

    return (
        <>
            <Helmet>
                <title>Maturity Assessment - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Fragment>
                    <StatsWrapper>
                        <Heading marginMd="32px 0" marginSm="24px 18px">
                            Maturity Assessment
                        </Heading>
                        {content}
                    </StatsWrapper>
                </Fragment>
            </Wrapper>
        </>
    );
}



export default MaturityScores;
