import styled, { css } from "styled-components";

export const UserTypeCardOnClickEvent = styled.div`
  height: 100%;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
  border-color: rgba(93, 229, 106, 1);
  `}
`;
export const PleaseChooseWhich = styled.p`
  margin: 0;
`;

export const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.46px;
  line-height: 26px;
  font-weight: 400;
  font-size: 26px;
  margin-bottom: 127px;
`;

export const Cardgroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0px 35px;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-left: -16px;
  padding-top: 66px;
  margin-right: -16px;
  text-align: center;
  font-size: 32px;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    gap: 140px 30px;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    gap: 140px;
  }
  margin-bottom: 40px;
  
`;
export const UsertypecardgroupRoot = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: #1f2128;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
  font-size: 24px;
  color: #fff;
  font-family: Rubik;
`;

export const ButtonAlignment = styled.div`
  text-align: right;
`;
