import { getCase, startNewCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, RiskAssessment, RiskRegisterProcessID } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_RISK_REGISTER_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'TOGGLE_RISK_REGISTER_LOADING', payload: loading };
};

export const initiateNewCase = (keycloak) => {
  return async (dispatch) => {
    let obj = {
      "case-group-assignments": {
        "admin": "cz-sa-riskassessment",
        "owner": CONTAINER_ID.replaceAll("_0.1", "")
      }
    }

    const newCaseResponse = await startNewCase(CONTAINER_ID, keycloak.token, RiskRegisterProcessID, obj);
    if (newCaseResponse.success) {
      dispatch(fetchData(keycloak, 0));
    } else {
      dispatch({ type: 'FETCH_RISK_REGISTER_DATA_ERROR', payload: newCaseResponse.error?.message || "Failed to create new case" });
    }
  }
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().riskRegister; // Get the allowFetch value from the state
    const { domainControlMapper = {} } = getState().miscellaneous;

    // Enable loading
    dispatch(toggleLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-riskRegister');
      const cachedCaseID = await getCachedData(keycloak.token, 'cached-riskRegister-caseID');
      const cachedCaseData = await getCachedData(keycloak.token, 'cached-riskRegister-caseData');

      //get risk assessment data
      const cachedRiskAssessmentData = await getCachedData(keycloak.token, 'cached-risks');
      const cachedThreatData = await getCachedData(keycloak.token, 'cached-threats');
      // const cachedTraData = await getCachedData(keycloak.token, 'cached-tras');
      const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');

      

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_RISK_REGISTER_DATA_SUCCESS', payload: { data: cachedData, caseID: cachedCaseID, caseData: cachedCaseData } });
      } else {

        let riskRegisters = [];
        let caseID = null;
        let caseData = null;

        // const openCases = await getCase(CONTAINER_ID, keycloak.token, RiskRegisterProcessID, 'open', 1, pageParam);
        // const openedData = openCases.success ? openCases.data?.instances : [];

        // let riskRegisterData = [...openedData];

        // If not open case then check closed case
        // if (!(riskRegisterData?.length > 0)) {
        //   const closedCases = await getCase(CONTAINER_ID, keycloak.token, RiskRegisterProcessID, 'closed', 1, pageParam);
        //   const closedData = closedCases.success ? closedCases.data?.instances : [];

        //   riskRegisterData = [...closedData];
        // }

        // // Check if not case found start new case
        // if (!(riskRegisterData?.length > 0)) {
        //   dispatch(initiateNewCase(keycloak));
        //   return;
        // }

        // console.log('DEBUG riskRegisterData = ', riskRegisterData);

        // if (riskRegisterData?.length > 0) {
        //   riskRegisterData.forEach((s, index) => {
        //     caseData = {}
        //     caseData["date"] = s["case-started-at"] || 0;
        //     caseData["status"] = s["case-status"] || 0;
        //     let cDate = new Date(s["case-started-at"]);

        //     caseData["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
        //       hour: '2-digit',
        //       minute: '2-digit',
        //       hour12: true
        //     });

        //     caseData.caseID = s["case-id"];
        //     caseID = s["case-id"];

        //     let riskRegisterList = s['case-file']['case-data']['riskRegisterList'] ?? {};

        //     riskRegisters = Object.keys(riskRegisterList).map((key) => {
        //       return {
        //         ...riskRegisterList[key],
        //         key: key,
        //         caseID: key,
        //       }
        //     })

        //     riskRegisters = riskRegisters.sort((a, b) => (a["riskID"] || '').localeCompare(b["riskID"] || ''))

        //   })
        // }

        //effectiveness of current controls mapping Excellent,Good,Fair,Marginal,Poor or Non-Existent
        let effectivenessOfCurrentControls = ["Non-Existent", "Poor", "Marginal", "Fair", "Good", "Excellent"];

        //risk regsiter mapping in frontend
        let currentControls = [];
        let controlAreas = [];
        let counterMeasures = [];
        cachedRiskAssessmentData.forEach(riskAssessment => {
          try {
            //round down maturity score
            riskAssessment.maturityScore = parseFloat(riskAssessment.maturityScore);
            //get current controls

            riskAssessment.controls.forEach(control => {
              let currentControl = `${control.controlID} - ${control.controlHeading}`;
              currentControls.push(currentControl);
              let domainID = control.controlID.split('.')[0];
              let sectionID = control.controlID.split('.')[1];
              //add domain and section to controlAreas but unique only
              if (!controlAreas.includes(`${domainID} - ${cachedDomainData[domainID - 5].domainHeading}`)) {
                controlAreas.push(`${domainID} - ${cachedDomainData[domainID - 5].domainHeading}`);
              }
              //add section to controlAreas but unique only
              if (!controlAreas.includes(`${domainID}.${sectionID} - ${cachedDomainData[domainID - 5].sections[sectionID - 1].sectionHeading}`)) {
                controlAreas.push(`${domainID}.${sectionID} - ${cachedDomainData[domainID - 5].sections[sectionID - 1].sectionHeading}`);
              }
            });

            //counter measures
            if (riskAssessment.threatList) {
              Object.keys(riskAssessment.threatList).forEach((threatKey) => {
                // find data of threat using threatKey
                let threats = cachedThreatData.filter((th) => { return th.caseID === threatKey });
                threats.forEach(threatData => {
                  threatData.counterMeasureMapping.forEach((counterMeasureList) => {
                    counterMeasureList.forEach((counterMeasure) => {
                      console.log(counterMeasure.title);
                      //add counter measure to counterMeasures but unique only
                      if (!counterMeasures.includes(`${counterMeasure.title}`)) {
                        counterMeasures.push(`${counterMeasure.title}`);
                      }
                    });
                  });
                });
              });
            }

          } catch (error) {
            //
            
          }

          let riskRegister = {
            "riskID": riskAssessment.caseID,
            "riskStatement": riskAssessment.riskStatement,
            "currentRiskRating": riskAssessment.inherentRiskRating,
            "riskStatement": riskAssessment.riskDescription || riskAssessment.riskTitle,
            "informationAssets": [],
            "informationSecurityClassification": [],
            "controlAreasForExistingControls": controlAreas,
            "effectivenessOfCurrentControls": effectivenessOfCurrentControls[riskAssessment.maturityScore] || "",
            "currentLikelihood": riskAssessment.inherentLikelihood,
            "currentConsequence": riskAssessment.inherentConsequence,
            "currentLikelihoodxConsequence": `${riskAssessment.inherentLikelihood || ""} x ${riskAssessment.inherentConsequence || ""}`,
            "currentRiskRating": riskAssessment.inherentRiskRating,
            "currentRiskComments": riskAssessment.riskComments,
            "riskTreatmentRecommendation": riskAssessment.riskTreatmentRecommendation,
            "riskTreatmentAction": counterMeasures,
            "controlAreasForNewTreatmentMeasures": [

            ],
            "targetLikelihood": riskAssessment.targetLikelihood,
            "targetConsequence": riskAssessment.targetConsequence,
            "targetLikelihoodxConsequence": `${riskAssessment.targetLikelihood} x ${riskAssessment.targetConsequence}`,
            "targetResidualRiskRating": riskAssessment.targetResidualRiskRating,
            "riskTreatmentOwner": "",
            "riskTreatmentStatus": riskAssessment.riskTreatment,
            "riskTreatmentDueDate": riskAssessment.riskTreatmentDueDate || {},
            "riskTreatmentImplementedDate": riskAssessment.riskTreatmentImplementedDate || {},
            "businessCriticality": "Important",
            "controlMaturityScore": riskAssessment.maturityScore,
            "currentControls": currentControls,
            "actualResidualRiskRating": riskAssessment.residualRiskRating,
            "dateOfISSCApproval": {

            },
            "dateOfReviewAndNatureOfUpdate": "",
          };
          riskRegisters.push(riskRegister);
          currentControls = [];
          controlAreas = [];
          counterMeasures = [];
        });


        dispatch({ type: 'FETCH_RISK_REGISTER_DATA_SUCCESS', payload: { data: riskRegisters, caseID: caseID, caseData: caseData }, });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-riskRegister', riskRegisters);
        cacheData(keycloak.token, 'cached-riskRegister-caseID', caseID);
        cacheData(keycloak.token, 'cached-riskRegister-caseData', caseData);
        dispatch(toggleAllowFetch(false));

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_RISK_REGISTER_DATA_ERROR', payload: error.message });
    }
  };
};