import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { styled } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import "./SubscriptionPlans.css";
import SubscriptionDialog from "../../../../../components/Dialog/SubscriptionDialog";

const PlanNameTypo = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '1.875rem'
}));

const PlanTaglineTypo = styled(Typography)(({ theme }) => ({
    marginTop: '0.5rem',
    minHeight: 50
}));

const PriceContainer = styled(Box)(({ theme }) => ({
    height: 85,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '1.225rem',
    marginBottom: '1.225rem',
    '& .MuiBox-root': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiTypography-h3': {
            fontWeight: 700,
            fontSize: '2.25rem',
            lineHeight: 1.22,
            letterSpacing: '0.2px'
        }
    }
}));

function SubscriptionPlans() {

    const isTabViewMode = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [activeTab, setActiveTab] = useState(1);
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

    const toggleSubscriptionDialog = (e) => {
        setSubscriptionDialogOpen((prev) => !prev);
    }

    const plans = [
        {
            isPopular: true,
            classes: 'popular top',
            label: 'Free',
            planName: 'Free',
            tagline: 'All the self-service CisoZen benefits',
            price: '$0',
            priceStyles: {
                color: '#1DB45A'
            },
            priceAbbreviation: '',
            priceAbbreviationStyles: {},
            priceDescription: '',
            priceDescriptionStyles: {},
            planDescription: [
                'Free for up to 5 users per organisation'
            ],
            planDescriptionStyles: {},
            // actions: <Button
            //     fullWidth
            //     sx={{ maxWidth: 235 }}
            //     variant="outlined"
            //     color="success"
            //     style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
            //     onClick={toggleSubscriptionDialog}
            // >
            //     Get started
            // </Button>,
            actions: <Button
                fullWidth
                sx={{ maxWidth: 235 }}
                variant="outlined"
                color="success"
                style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                Get started
            </Button>
        },
        {
            label: '10 Days',
            planName: '10 Days',
            tagline: 'Specialised cyber security services',
            price: '$8,000',
            priceStyles: {
                color: '#1DB45A'
            },
            priceAbbreviation: '/ year',
            priceAbbreviationStyles: {},
            priceDescription: '66% discount based on Australian market rate',
            priceDescriptionStyles: {},
            planDescription: [
                'One year of commitment.'
            ],
            planDescriptionStyles: {},
            // actions: <Button
            //     fullWidth
            //     sx={{ maxWidth: 235 }}
            //     variant="contained"
            //     color="success"
            //     style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
            //     onClick={toggleSubscriptionDialog}
            // >
            //     Buy Now
            // </Button>,
            actions: <Button
                fullWidth
                sx={{ maxWidth: 235 }}
                variant="contained"
                color="success"
                style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                Buy Now
            </Button>,
        },
        {
            label: '20 Days',
            planName: '20 Days',
            tagline: 'Specialised cyber security services',
            price: '$15,000',
            priceStyles: {
                color: '#1DB45A'
            },
            priceAbbreviation: '/ year',
            priceAbbreviationStyles: {},
            // priceDescription: '$1,000 cheaper',
            priceDescription: '68% discount based on Australian market rate',
            priceDescriptionStyles: {},
            planDescription: [
                'One year of commitment.',
            ],
            planDescriptionStyles: {},
            // actions: <Button
            //     fullWidth
            //     sx={{ maxWidth: 235 }}
            //     variant="contained"
            //     color="success"
            //     style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
            //     onClick={toggleSubscriptionDialog}
            // >
            //     Buy Now
            // </Button>,
            actions: <Button
                fullWidth
                sx={{ maxWidth: 235 }}
                variant="contained"
                color="success"
                style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                Buy Now
            </Button>,
        },
        {
            label: 'Training',
            planName: 'Training',
            tagline: 'Instructor led training',
            price: '$2,000',
            priceStyles: {
                color: '#1DB45A'
            },
            priceAbbreviation: '',
            priceAbbreviationStyles: {},
            priceDescription: 'Price for whole organisation',
            priceDescriptionStyles: {},
            planDescription: [
                'This training does not require any previous experience within Cyber Security or IT'
            ],
            planDescriptionStyles: {},
            // actions: <Button
            //     fullWidth
            //     sx={{ maxWidth: 235 }}
            //     variant="contained"
            //     color="success"
            //     style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
            //     onClick={toggleSubscriptionDialog}
            // >
            //     Buy now
            // </Button>,
            actions: <Button
                fullWidth
                sx={{ maxWidth: 235 }}
                variant="contained"
                color="success"
                style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
            >
                Buy now
            </Button>,
        },
    ]

    return <>
        <Container maxWidth="xl" className="subscription-plans">
            <Box py={8}>
                <Grid container columns={10}>
                    <Grid item xs={10} sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Tabs
                            value={activeTab}
                            onChange={(e, newValue) => {
                                setActiveTab(newValue);
                            }}
                            indicatorColor="#008000"
                            textColor="inherit"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            centered
                            sx={{
                                backgroundColor: 'rgb(31, 33, 40)',
                                border: '1px solid',
                                borderRadius: '12px',
                                borderColor: 'rgb(31, 33, 40)',
                                "& .MuiTab-root": {
                                    fontWeight: 700,
                                    fontSize: '0.875rem',
                                    color: "inherit", // Inherit text color
                                    textDecoration: "none",
                                    textTransform: "none",
                                    "&.Mui-selected": {
                                        color: "#008000", // Green text color for selected tab
                                        // backgroundColor: "#F3F6F9",
                                        backgroundColor: "#FFFFFF15"
                                    },
                                },
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#008000", // Green indicator color
                                },
                            }}
                        >
                            {
                                plans.map((plan, index) => (
                                    <Tab label={plan.label} value={index + 1} />
                                ))
                            }
                        </Tabs>
                    </Grid>

                    <>
                        <Grid item xs={10} md={2} p={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Box>
                                <PlanNameTypo variant="body2">Plans</PlanNameTypo>
                                <img alt="" src="assets/images/advanced-2@2x.png" style={{ width: '100%', maxWidth: '200px' }} />
                            </Box>
                        </Grid>
                        {
                            plans.map((plan, index) => {
                                let show = (!isTabViewMode || (isTabViewMode && activeTab === (index + 1)));
                                if (!show) return null;
                                return <Grid item xs={10} md={2} p={2} sx={{ textAlign: "center" }} key={'plan' + index} className={plan.classes}>
                                    <Box
                                        sx={{
                                            padding: { xs: 2, md: 0 },
                                            border: { xs: '1px solid', md: 'none' },
                                            borderColor: 'rgb(31, 33, 40) !important',
                                            borderRadius: '12px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                            height: '100%',
                                            position: 'relative'
                                        }}
                                    >
                                        <Box>
                                            <PlanNameTypo variant="body2">
                                                {plan.planName}
                                            </PlanNameTypo>
                                            <PlanTaglineTypo variant="body2">
                                                {plan.tagline}
                                            </PlanTaglineTypo>
                                            <PriceContainer>
                                                <Box>
                                                    <Typography variant="h3" sx={{ ...plan.priceStyles }}>
                                                        {plan.price}
                                                    </Typography>
                                                    {plan?.priceAbbreviation?.length > 0 && <Box sx={{ width: '5px' }} />}
                                                    <Typography variant="body2" >
                                                        {plan.priceAbbreviation}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="caption" >
                                                    {plan.priceDescription}
                                                </Typography>
                                            </PriceContainer>
                                            <Box>
                                                {
                                                    plan.planDescription.map((description, index) => {
                                                        return <Typography variant="body2" key={'description' + index} sx={{ fontSize: '0.775rem' }}>
                                                            {description}
                                                        </Typography>
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                        {
                                            plan.actions ? <Box sx={{ marginTop: '1rem' }}>
                                                {plan.actions}
                                            </Box> : ''
                                        }
                                        {
                                            !!plan.isPopular && <Box
                                                sx={{
                                                    position: { xs: 'unset', md: 'absolute' },
                                                    top: -30,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '30px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'rgb(70, 161, 27)',
                                                    fontWeight: 400,
                                                    fontSize: '1rem',
                                                    letterSpacing: '0.00938em',
                                                    paddingTop: { xs: '12px', md: 0 }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderRadius: '15px',
                                                        backgroundColor: 'rgb(21, 21, 21)',
                                                        border: '1px solid rgb(70, 161, 27)',
                                                        boxSizing: 'border-box',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '103px',
                                                        height: '30px',
                                                        color: 'rgb(70, 161, 27)',
                                                        fontWeight: 400,
                                                        fontSize: '1rem',
                                                        letterSpacing: '0.00938em',
                                                    }}
                                                >
                                                    <Typography>
                                                        Most used
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            })
                        }
                    </>

                    <Grid item xs={10}>
                        <Box sx={{ padding: '8px 16px 8px', fontWeight: 700, fontSize: '0.875rem', lineHeight: 1.5, backgroundColor: 'rgb(31, 33, 40)', border: '1px solid', borderRadius: '12px', borderColor: 'rgb(31, 33, 40)' }}>
                            CisoZen Features
                        </Box>
                    </Grid>
                    <>
                        {
                            [
                                {
                                    title: 'Rapid Health Check (RHC)',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'RHC Recommendations',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Risk Management',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Third Party Risk Management',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'ASD Essential Eight(ASDE8)',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Maturity Assessment',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Risk Assessment',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Reports',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Threat Elicitation',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                },
                                {
                                    title: 'Other features as they are released',
                                    plan1: true,
                                    plan2: true,
                                    plan3: true,
                                    plan4: true,
                                }
                            ].map((item, index) => (
                                <Grid container item columns={10} xs={10} className="features" key={'features' + index}>
                                    <Grid item xs={5} md={2} className="title">
                                        {item.title}
                                    </Grid>
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 1)) && <Grid item xs={5} md={2} className="popular middle">
                                            {item.plan1 && <img src="assets/images/tick-12@2x.png" alt="" />}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 2)) && <Grid item xs={5} md={2}>
                                            {item.plan2 && <img src="assets/images/tick-12@2x.png" alt="" />}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 3)) && <Grid item xs={5} md={2}>
                                            {item.plan3 && <img src="assets/images/tick-12@2x.png" alt="" />}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 4)) && <Grid item xs={5} md={2}>
                                            {item.plan4 && <img src="assets/images/tick-12@2x.png" alt="" />}
                                        </Grid>
                                    }
                                </Grid>
                            ))
                        }
                    </>

                    <Grid item xs={10}>
                        <Box sx={{ padding: '8px 16px 8px', fontWeight: 700, fontSize: '0.875rem', lineHeight: 1.5, backgroundColor: 'rgb(31, 33, 40)', border: '1px solid', borderRadius: '12px', borderColor: 'rgb(31, 33, 40)' }}>
                            Support
                        </Box>
                    </Grid>
                    <>
                        {
                            [
                                {
                                    title: 'Offline cyber security services',
                                    plan1: '-',
                                    plan2: '10 days/year',
                                    plan3: '20 days/year',
                                    plan4: '-',
                                },
                                {
                                    title: 'Features training (8 hours with 1 hour break)',
                                    plan1: '-',
                                    plan2: '-',
                                    plan3: '-',
                                    plan4: <>
                                        <img src="assets/images/tick-12@2x.png" alt="" />
                                    </>,
                                },
                            ].map((item, index) => (
                                <Grid container item columns={10} xs={10} className="features" key={'features' + index}>
                                    <Grid item xs={5} md={2} className="title">
                                        {item.title}
                                    </Grid>
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 1)) && <Grid item xs={5} md={2} className="popular middle">
                                            {item.plan1}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 2)) && <Grid item xs={5} md={2}>
                                            {item.plan2}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 3)) && <Grid item xs={5} md={2}>
                                            {item.plan3}
                                        </Grid>
                                    }
                                    {
                                        (!isTabViewMode || (isTabViewMode && activeTab === 4)) && <Grid item xs={5} md={2}>
                                            {item.plan4}
                                        </Grid>
                                    }
                                </Grid>
                            ))
                        }
                    </>
                </Grid>
            </Box>
        </Container >

        <SubscriptionDialog
            open={subscriptionDialogOpen}
            toggle={toggleSubscriptionDialog}
        />
    </>
}

export default SubscriptionPlans;