import { styled as MuiStyled } from '@mui/material/styles';

import MuiCard from '@mui/material/Card';
import MuiPaper from '@mui/material/Paper';

export const ToolbarActionCard = MuiStyled(MuiCard)(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === "light" ? "#fff" : "#1F2128",
  borderRadius: '8px',
}))

export const RFCard = MuiStyled(MuiPaper)(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === "light" ? "#fff" : "#1F2128",
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px',
  borderRadius: '8px',
}))

export const RFNodeCard = MuiStyled(MuiPaper)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme?.palette?.mode === "light" ? "#fff" : "#1F2128",
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px',
  borderRadius: '8px',
  border: "2px dashed red",
}))