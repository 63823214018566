import { FooterStyled, FooterLink } from "./Footer.styled";

function Footer(props) {
    const { handleClickOpen, toggleTnC } = props;
    return (
        <>
            <FooterStyled>
                <FooterLink to="/" onClick={handleClickOpen}>Privacy Policy</FooterLink>
                <FooterLink to="/" onClick={toggleTnC}>Terms & Conditions</FooterLink>
                <FooterLink to="/">info@cisozen.com.au</FooterLink>
            </FooterStyled>
        </>
    );
};

export default Footer;
