import React, { useState } from "react";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { Button } from "../../../../components/UI/Button/Button.styled";
import { SubscriptionRoot, ImgIcon, Title, Textsection, Contentgroup, Statustext, Responsetext, Contentgroup1 } from "./Subscription.styled";

import ModalImageSrc from "../../../../images/reccomendationImg.png";
import InfoModal from "../InfoModal/InfoModal";
import { paymentUrlInstructor, paymentUrlMonthly, paymentUrlYearly } from '../../../../store/auth-context';
import SubscriptionDialog from "../../../../components/Dialog/SubscriptionDialog";

const Subscription = ({ type, validUntil, timeRemaining, imgSrc, email }) => {

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <SubscriptionRoot>
        <ImgIcon alt="" src={imgSrc} />
        <Title>Subscription</Title>
        <Textsection>
          <Contentgroup>
            <Statustext>Type:</Statustext>
            <Statustext>Valid until:</Statustext>
            <Statustext>Time Remaining:</Statustext>
          </Contentgroup>
          <Contentgroup1>
            <Responsetext>{type}</Responsetext>
            <Responsetext>{validUntil}</Responsetext>
            <Responsetext>{timeRemaining} days</Responsetext>
            {/* <Button $success>View</Button> */}
          </Contentgroup1>
        </Textsection>
        <Button
          $success
          variant="filled"
          startIcon={<WorkspacePremiumIcon />}
          fullWidth
          onClick={() => setOpenModal(true)}
          style={{ marginTop: "20px" }}
        >
          Upgrade Subscription
        </Button>
      </SubscriptionRoot>
      <SubscriptionDialog
        email={email}
        open={openModal}
        toggle={handleCloseModal}
      />
    </>
  );
};

export default Subscription;