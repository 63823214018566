import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { Button } from "../../../../components/UI/Button/Button.styled";
import RiskContext from "../../../../store/risk-context";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import RiskList from "../RiskList/RiskList";
import { ContentContainer, StyledBox, TabsContainer, CustomTabpanel, CenteredDiv } from "./RiskTabs.styled";
import { useDispatch, useSelector } from "react-redux";
import RiskListV2 from "../RiskList/RiskListV2";
import E8QuestionsStepper from "../E8QuestionStepper/E8QuestionStepper";
import { toast } from "react-toastify";

const QuestionData = [
    {
        "qID": 1,
        "qNum": 1,
        "title": "Create New Impact Definition",
        "subQuestions": [
            {
                "qID": "1.1",
                "qNum": "1.1",
                "key": "name",
                "qHeading": "Name",
                "type": "text",
                "required": true
            }
        ]
    }
]

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <CustomTabpanel role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </CustomTabpanel>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const AddNewRiskCategory = (props) => {

    const { mode, open, toggle, dataToEdit } = props;

    const dispatch = useDispatch();
    const { riskConsequence } = useSelector((state) => state.riskMatrix);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (open) {
            setQuestions(
                QuestionData.map((question) => ({
                    ...question,
                    ...(mode === "edit" && { title: "Edit Impact Definition" }),
                    subQuestions: question.subQuestions.map((subQuestion) => ({
                        ...subQuestion,
                        qAnswer: (mode === "edit" && dataToEdit?.name) ? dataToEdit?.name : "",
                    }))
                }))
            )
        }
    }, [dataToEdit, mode, open])

    const handleAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
    }, [questions]);

    // ** flag to check if the form data is filled
    let isFormFilled = questions?.length > 0 ? questions[0].subQuestions.every((subQuestion) => {
        if (subQuestion.required) {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        } else {
            return true;
        }
    }) : false;

    const handleSubmit = useCallback((e) => {
        e?.preventDefault();
        if (!isFormFilled) return;
        let newCategoryName = (questions[0]?.subQuestions[0]?.qAnswer || '').trim();
        if (mode === "add") {
            let found = riskConsequence.tabs.find((tab) => tab.name.toLowerCase() === newCategoryName.toLowerCase());
            if (found) {
                toast.error('Please enter unique impact definition');
                return;
            }
            let tabs = [...riskConsequence.tabs, { name: newCategoryName }];
            let fields = [...riskConsequence.fields];

            riskConsequence.levels.forEach((_, index) => {
                fields.push({ name: `consCat${index + 1}Row${tabs.length}`, desc: "" });
            })

            dispatch({
                type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
                payload: {
                    tabs: tabs,
                    levels: riskConsequence.levels,
                    fields: fields,
                    // rawData: [
                    //     ...riskConsequence.rawData,
                    //     {
                    //         ...riskConsequence.levels.reduce((acc, level) => {
                    //             return { ...acc, [level]: "" }
                    //         }, {})
                    //     }
                    // ]
                }
            })
        } else {
            let found = riskConsequence.tabs.find((tab, index) => index !== dataToEdit.index && tab.name.toLowerCase() === newCategoryName.toLowerCase());
            if (found) {
                toast.error('Please enter unique impact definition');
                return;
            }

            dispatch({
                type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
                payload: {
                    tabs: riskConsequence.tabs.map((tab, index) => index === dataToEdit.index ? { ...tab, name: newCategoryName } : { ...tab }),
                    levels: riskConsequence.levels,
                    fields: riskConsequence.fields,
                    // rawData: [
                    //     ...riskConsequence.rawData,
                    //     {
                    //         ...riskConsequence.levels.reduce((acc, level) => {
                    //             return { ...acc, [level]: "" }
                    //         }, {})
                    //     }
                    // ]
                }
            })
        }

        // Close dialog
        toggle(e);

    }, [dataToEdit, dispatch, isFormFilled, mode, questions, riskConsequence, toggle])

    return (<>
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={toggle}
        >
            <DialogTitle>
                Impact Definition
            </DialogTitle>
            <Box
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <IconButton
                    title="close"
                    aria-label="close"
                    onClick={toggle}
                    sx={{

                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
                <E8QuestionsStepper
                    questions={questions}
                    qIndex={0}
                    handleAnswer={handleAnswer}
                />
            </DialogContent>
            <DialogActions sx={{ padding: "20px 24px" }}>
                <Button $error onClick={(e) => toggle()}>Cancel</Button>
                <Button $success onClick={(e) => handleSubmit(e)} disabled={!isFormFilled}>Save</Button>
            </DialogActions>
        </Dialog>

    </>
    );
};

export default AddNewRiskCategory;