import { getCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, TRAProcess } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_TRA_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'TOGGLE_TRA_LOADING', payload: loading };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_TRA_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractTRADataFromCaseFile(caseFile) {
  let newTRA = {};

  let traDetail = caseFile["case-file"]["case-data"]["traData"];
  let iRiskID = caseFile["case-file"]["case-data"]["iRiskID"];
  let aRiskID = caseFile["case-file"]["case-data"]["aRiskID"];
  let cRiskID = caseFile["case-file"]["case-data"]["cRiskID"];
  let documentID = caseFile["case-file"]["case-data"]["documentID"];
  let solutionName = caseFile["case-file"]["case-data"]["solutionName"];
  if (traDetail) {
    newTRA = { ...traDetail };
  }

  newTRA["date"] = caseFile["case-started-at"] || 0;
  newTRA["status"] = caseFile["case-status"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);

  newTRA["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  newTRA.caseID = caseFile["case-id"];
  newTRA.iRiskID = iRiskID;
  newTRA.aRiskID = aRiskID;
  newTRA.cRiskID = cRiskID;
  newTRA.documentID = documentID;
  newTRA.solutionName = solutionName;
  return newTRA;
}

export const getTRAData = async (caseFile) => {
  let newTRA = extractTRADataFromCaseFile(caseFile);
  newTRA.caseID = caseFile["case-id"];
  return newTRA;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().tras; // Get the allowFetch value from the state

    // Enable loading
    dispatch(toggleLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-tras');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_TRA_DATA_SUCCESS', payload: { data: cachedData } });

      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let tras = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, TRAProcess, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, TRAProcess, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const traData = [...closedData, ...openedData];

        if (traData?.length > 0) {
          traData.forEach((tra, index) => {
            let newTRA = {};

            let traDetail = tra["case-file"]["case-data"]["traData"];
            let iRiskID = tra["case-file"]["case-data"]["iRiskID"];
            let aRiskID = tra["case-file"]["case-data"]["aRiskID"];
            let cRiskID = tra["case-file"]["case-data"]["cRiskID"];
            let documentID = tra["case-file"]["case-data"]["documentID"];
            let solutionName = tra["case-file"]["case-data"]["solutionName"];
            if (traDetail) {
              newTRA = { ...traDetail };
            }

            newTRA["date"] = tra["case-started-at"] || 0;
            newTRA["status"] = tra["case-status"] || 0;
            let cDate = new Date(tra["case-started-at"]);

            newTRA["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            newTRA.caseID = tra["case-id"];
            newTRA.iRiskID = iRiskID;
            newTRA.aRiskID = aRiskID;
            newTRA.cRiskID = cRiskID;
            newTRA.documentID = documentID;
            newTRA.solutionName = solutionName;

            tras.push(newTRA);
          })

          tras.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_TRA_DATA_SUCCESS', payload: { data: tras }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'TRA_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-tras', tras);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_TRA_FETCH_COUNTER' });
        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_TRA_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().tras; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-tras');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let tras = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, TRAProcess, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, TRAProcess, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const traData = [...closedData, ...openedData];

      if (traData) {
        traData.forEach((tra, index) => {
          let newTRA = {};

          let traDetail = tra["case-file"]["case-data"]["traData"];
          let iRiskID = tra["case-file"]["case-data"]["iRiskID"];
          let aRiskID = tra["case-file"]["case-data"]["aRiskID"];
          let cRiskID = tra["case-file"]["case-data"]["cRiskID"];
          let documentID = tra["case-file"]["case-data"]["documentID"];
          let solutionName = tra["case-file"]["case-data"]["solutionName"];
          if (traDetail) {
            newTRA = { ...traDetail };
          }

          newTRA["date"] = tra["case-started-at"] || 0;
          newTRA["status"] = tra["case-status"] || 0;
          let cDate = new Date(tra["case-started-at"]);

          newTRA["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          newTRA.caseID = tra["case-id"];
          newTRA.iRiskID = iRiskID;
          newTRA.aRiskID = aRiskID;
          newTRA.cRiskID = cRiskID;
          newTRA.documentID = documentID;
          newTRA.solutionName = solutionName;
        })

        if (cachedData?.length > 0) {
          tras = [...cachedData, ...tras];
        }

        tras.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_TRA_DATA_SUCCESS', payload: { data: tras }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'TRA_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_TRA_FETCH_COUNTER' });
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-tras', tras);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_TRA_DATA_ERROR', payload: error.message });
    }
  };
};