import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";

import Heading from "../../../../components/UI/Heading/Heading";
import { Wrapper } from "./ReportsLandingPage.styled";
import Loader from "../../../../components/UI/Loader/Loader";
import { generateRiskMatrixTableDataFromProcessVariableInstance, getCompletedHealthCheckList, getCompletedRiskMatrixList } from "../../InformationAPI";
import ReportsTable from "./components/ReportsTable";
import { ReportTypes } from "../../../../shared/constants";
import { getActionData, getCategory, getLikelihoodData, getMatrixDimensions, getMatrixExists } from "../../../../utility/riskMatrix.action";
import { CONTAINER_ID } from "../../../../store/auth-context";
import { getDMN } from "../TRA/TRAApi";

function ReportsLandingPage(props) {

    // ** Hooks
    const { keycloak } = useKeycloak();
    const { data: tras, loading: traLoading } = useSelector((state) => state.tras);

    // ** States
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState(null);
    const [fetchingHealthCheck, setFetchingHealthCheck] = useState(false);
    const [healthCheckList, setHealthCheckList] = useState([]);
    const [fetchingRiskMatrix, setFetchingRiskMatrix] = useState(false);
    const [riskMatrixList, setRiskMatrixList] = useState([]);
    const [traList, setTRAList] = useState([]);
    const [domainData, setDomainData] = useState(null);
    const [domainControlMapper, setDomainControlMapper] = useState({});

    //get token from keycloak
    useEffect(() => {
        setLoading(true);
        if (keycloak?.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setEmail(res.sub);
                setLoading(false);
            });
        }
    }, [keycloak]);

    useEffect(() => {
        setFetchingHealthCheck(true);
        getCompletedHealthCheckList(keycloak?.token, email).then((response) => {
            const hcList = response.data;
            setHealthCheckList(
                hcList.map(
                    (hc, index) => {
                        let historyData = [hc];
                        if (hcList[index - 1]) {
                            historyData.push(hcList[index - 1]);
                        }
                        return {
                            ...hc,
                            title: `RHC Report - ${hc.date} ( ${hc.score} )`,
                            historyData: historyData,
                            type: ReportTypes.HEALTH_CHECK,
                        }
                    }
                )
            );
            setFetchingHealthCheck(false);
        });
    }, [email, keycloak?.token])

    useEffect(() => {
        setFetchingRiskMatrix(true);
        getCompletedRiskMatrixList(keycloak.token, email).then((response) => {
            let rmList = response.data;
            setRiskMatrixList(
                rmList.map(
                    (rm) => {

                        let riskInformation = getLikelihoodData(rm.data);
                        let riskActions = getActionData(rm.data);
                        let riskConsequence = getCategory(rm.data);
                        let riskMatrixExists = getMatrixExists(rm.data);
                        let riskMatrixDimensions = getMatrixDimensions(rm.data);
                        let riskMatrixTableData = generateRiskMatrixTableDataFromProcessVariableInstance(rm.data);

                        return {
                            ...rm,
                            title: `Risk Matrix Report - ${rm.date}`,
                            riskInformation: riskInformation,
                            riskActions: riskActions,
                            riskConsequence: riskConsequence,
                            riskMatrixExists: riskMatrixExists,
                            riskMatrixDimensions: riskMatrixDimensions,
                            riskMatrixTableData: riskMatrixTableData,
                            type: ReportTypes.RISK_MATRIX,
                        }
                    }
                )
            )
            setFetchingRiskMatrix(false)
        });
    }, [email, keycloak?.token])

    useEffect(() => {
        setTRAList((tras || []).map((tra) => {
            return {
                ...tra,
                datetime: tra.date,
                title: `TRA Report - ${tra?.solutionName || ''}`,
                type: ReportTypes.TRA
            }
        }))
    }, [tras])

    function getDomains() {
        getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains').then((response1) => {
            if (response1.success) {
                let data = response1.data["dmnContext"]["domains"][0];
                setDomainData(data);
            }
        })
    }

    useEffect(() => {
        if (!CONTAINER_ID) return;
        getDomains();
    }, [CONTAINER_ID])

    useEffect(() => {
        if (domainData) {
            let domainControlMapper = {};
            domainData.forEach((domain) => {
                domain.sections.forEach((section) => {
                    section.controls.forEach((control) => {
                        domainControlMapper[`${control.key.replaceAll("-", "")}`] = `${control.controlID} : ${control.controlHeading}`;
                    })
                })
            })
            setDomainControlMapper(domainControlMapper);
        }
    }, [domainData])

    const handleClick = () => {
        setModalOpen(true);
    };

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    } else {
        content = <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportsTable
                        email={email}
                        kcToken={keycloak?.token}
                        loading={loading || fetchingHealthCheck || fetchingRiskMatrix || traLoading}
                        data={[...healthCheckList, ...riskMatrixList, ...traList].sort((a, b) => b.datetime - a.datetime)}
                        domainControlMapper={domainControlMapper}
                    />
                </Grid>
            </Grid>
        </>
    }

    return (
        <>
            <Helmet>
                <title>Reports - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Reports
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default ReportsLandingPage;
