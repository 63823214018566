import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@mui/material/styles';

import Heading from "../../../../components/UI/Heading/Heading";
import { StatsWrapper, Wrapper } from "./BusinessImpactAssessmentLandingPage.styled";
import ConfidentialityCard from "./components/ConfidentialityCard";
import IntegrityCard from "./components/IntegrityCard";
import AvailabilityCard from "./components/AvailabilityCard";
import BusinessImpactCard from "../Information/components/BusinessImpactCard";
import BusinessCriticalityCard from "./components/BusinessCriticalityCard";
import InformationSecurityClassificationCard from "./components/InformationSecurityClassificationCard";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import BIASummaryCard from "./components/BIASummaryCard";
import { Helmet } from "react-helmet-async";
import { fetchData, toggleAllowFetch } from "../../../../utility/informationAssetRegister.action";

const defaultState = [
    // {
    //     label: 'Extreme Risks',
    //     value: 0,
    //     color: '#ff0000',
    //     key: 'Extreme',
    // },
    {
        label: 'High',
        value: 0,
        color: '#ffc000',
        tooltipValue: 0,
    },
    {
        label: 'Medium',
        value: 0,
        color: '#ffff00',
        tooltipValue: 0,
    },
    {
        label: 'Low',
        value: 0,
        color: '#92d050',
        tooltipValue: 0,
    }
]

const defaultBIASummary = {
    title: '',
    data: [
        {
            label: 'Completed',
            value: 0,
            color: 'rgb(70, 161, 27)',
            tooltipValue: 0
        },
        {
            label: 'Total',
            value: 0,
            color: 'rgb(189, 189, 189)',
            tooltipValue: 0
        },
    ]
}

function BusinessImpactAssessmentLandingPage() {

    // ** Hooks
    const theme = useTheme();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    // ** Redux states
    const { data, error, loading, allowFetch } = useSelector((state) => state.informationAssetRegisters);

    const defaultState = [
        // {
        //     label: 'Extreme Risks',
        //     value: 0,
        //     color: '#ff0000',
        //     key: 'Extreme',
        // },
        {
            label: 'High',
            value: 0,
            // color: '#ffc000',
            color: theme.palette.impact.high,
            tooltipValue: 0,
        },
        {
            label: 'Medium',
            value: 0,
            color: theme.palette.impact.medium,
            tooltipValue: 0,
        },
        {
            label: 'Low',
            value: 0,
            color: theme.palette.impact.low,
            tooltipValue: 0,
        }
    ]

    const [assets, setAssetList] = useState([]);
    const [confidentialitySummary, setConfidentialitySummary] = useState(defaultState);
    const [integritySummary, setIntegritySummary] = useState(defaultState);
    const [availabilitySummary, setAvailabilitySummary] = useState(defaultState);
    const [biaSummary, setBIASummary] = useState(defaultBIASummary);

    useEffect(() => {
        if (data) {
            setAssetList(data);
        }

        if (data === null && allowFetch === false) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    // async function getAssets() {
    //     setLoading(true);
    //     let assets = [];

    //     let openAssetsRegisterResponse = await getOpenAssetsRegister();
    //     if (openAssetsRegisterResponse?.data?.length > 0) {
    //         assets = [...assets, ...openAssetsRegisterResponse.data];
    //     }
    //     let closeAssetsRegisterResponse = await getClosedAssetsRegister();
    //     if (closeAssetsRegisterResponse?.data?.length > 0) {
    //         assets = [...assets, ...closeAssetsRegisterResponse.data];
    //     }
    //     setAssetList([...assets]);
    //     setLoading(false);
    // }

    // useEffect(() => {
    //     if (!CONTAINER_ID) return;
    //     getAssets();
    // }, [CONTAINER_ID]);

    function updateConfidentialitySummary(assets) {
        if (assets?.length > 0) {
            let summary = assets.reduce((acc, asset) => {
                return { ...acc, [asset.c]: (acc[asset.c] || 0) + 1 }
            }, {});
            setConfidentialitySummary(
                prev => prev.map((p) => {
                    if (summary[p.label]) {
                        return { ...p, value: summary[p.label], tooltipValue: summary[p.label] }
                    } else {
                        return { ...p }
                    }
                })
            )
        } else {
            setConfidentialitySummary(defaultState);
        }
    }

    function updateIntegritySummary(assets) {
        if (assets?.length > 0) {
            let summary = assets.reduce((acc, asset) => {
                return { ...acc, [asset.i]: (acc[asset.i] || 0) + 1 }
            }, {});
            setIntegritySummary(
                prev => prev.map((p) => {
                    if (summary[p.label]) {
                        return { ...p, value: summary[p.label], tooltipValue: summary[p.label] }
                    } else {
                        return { ...p }
                    }
                })
            )
        } else {
            setIntegritySummary(defaultState);
        }
    }

    function updateAvailabilitySummary(assets) {
        if (assets?.length > 0) {
            let summary = assets.reduce((acc, asset) => {
                return { ...acc, [asset.a]: (acc[asset.a] || 0) + 1 }
            }, {});
            setAvailabilitySummary(
                prev => prev.map((p) => {
                    if (summary[p.label]) {
                        return { ...p, value: summary[p.label], tooltipValue: summary[p.label] }
                    } else {
                        return { ...p }
                    }
                })
            )
        } else {
            setAvailabilitySummary(defaultState);
        }
    }

    function updateBIASummary(assets) {
        if (assets?.length > 0) {
            setBIASummary({
                title: `${assets.filter((a) => a.status === 2).length} / ${assets.length}`,
                data: [
                    {
                        label: 'Completed',
                        value: assets.filter((a) => a.status === 2).length,
                        color: theme.palette.progress.completed,
                        tooltipValue: assets.filter((a) => a.status === 2).length
                    },
                    {
                        label: 'Total',
                        value: (assets.length - assets.filter((a) => a.status === 2).length),
                        color: theme.palette.progress.main,
                        tooltipValue: assets.length
                    },
                ]
            })
        } else {
            setBIASummary(defaultBIASummary);
        }
    }

    useEffect(() => {
        updateConfidentialitySummary(assets);
        updateIntegritySummary(assets);
        updateAvailabilitySummary(assets);
        updateBIASummary(assets);
    }, [assets])

    return <>
        <Helmet>
            <title>Business Impact Assessment - CisoZen</title>
        </Helmet>
        <Wrapper>
            <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={() => { }}>
                Business Impact Assessment
            </Heading>
            <StatsWrapper>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <ConfidentialityCard
                            loading={loading}
                            centerLabelTitle={confidentialitySummary?.reduce((a, b) => a + (b.value || 0), 0)}
                            data={confidentialitySummary}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <IntegrityCard
                            loading={loading}
                            centerLabelTitle={integritySummary?.reduce((a, b) => a + (b.value || 0), 0)}
                            data={integritySummary}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AvailabilityCard
                            loading={loading}
                            centerLabelTitle={availabilitySummary?.reduce((a, b) => a + (b.value || 0), 0)}
                            data={availabilitySummary}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <BIASummaryCard
                            loading={loading}
                            centerLabelTitle={biaSummary?.title || '0'}
                            data={biaSummary?.data}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={10} xs={10}>
                    <BusinessImpactCard />
                </Grid> */}
                    <Grid item xs={12}>
                        <InformationSecurityClassificationCard
                            assets={assets}
                            fetchingAssets={loading}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={10} xs={10}>
                    <BusinessCriticalityCard />
                </Grid> */}
                </Grid>
            </StatsWrapper>
        </Wrapper>
    </>
}

export default BusinessImpactAssessmentLandingPage;