//Code component for custom_database to drag and drop from sidebar

import { useState, useEffect, useRef, useContext } from 'react';
import { Handle, Position, NodeResizer, useUpdateNodeInternals, NodeToolbar } from 'reactflow';

import { styled as MuiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import xPic from './x-icon.png';
import { Button } from './components/button';

const LabelBox = MuiStyled(Box)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: 'black',
  position: 'absolute',
  width: '100%',
}))

const OutOfBoxImgContainer = MuiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '5px',
  '& img': {
    width: '20px',
    height: '20px',
  }
}))

function TrustBoundaryNode({ id, data, selected, isConnectable, width, height }) {
  //   const onChange = useCallback((evt) => {
  //     console.log(evt.target.value);
  //   }, []);

  // return (
  //   <div className="text-updater-node">
  //     <Handle type="target" position={Position.Top} isConnectable={isConnectable} />

  //     <div className="database_label">{data.label}</div>
  //     <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
  //   </div>
  // );
  const [label, setLabel] = useState(data.label);
  const [isEditing, setIsEditing] = useState(false);
  const textAreaRef = useRef(null);
  const [isX, setIsX] = useState(false);


  useEffect(() => {
    if (isEditing) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const handleInputChange = (event) => {
    setLabel(event.target.value);
  };

  const toggleEditing = () => {
    if (label === '') {
      setLabel('Trust Boundary');
    }
    setIsEditing(!isEditing);
  };

  const toggleX = () => {
    setIsX(!isX);
  };

  return (
    <>

      <NodeToolbar isVisible={data.toolbarVisible} position={'relative'}>
        <Box>
          <Button error size="small" onClick={toggleX}>
            Toggle Out of Scope
          </Button>
        </Box>
      </NodeToolbar>
      {/* <Handle type="source" position="bottom" style={{ background: '#555' }} /> */}
      <Handle type="source" position="left" style={{ background: '#555' }} />
      {/* <Handle type="target" position="top" style={{ background: '#555' }} /> */}
      <Handle type="target" position="right" style={{ background: '#555' }} />
      <NodeResizer isVisible={selected} direction="bottom-right" />
      <Box>
        {isEditing ? (
          <textarea ref={textAreaRef} value={label} onChange={handleInputChange} onBlur={toggleEditing} style={{ whiteSpace: 'pre-wrap', width: '100%' }} autoFocus />
        ) : (
          <LabelBox onClick={toggleEditing}>{label}</LabelBox>
        )}
        {isX ? (
          <OutOfBoxImgContainer>
            <img src={xPic} alt='out-of-scope' />
          </OutOfBoxImgContainer>
        ) : (
          <div />
        )}
      </Box>
    </>
  );
}

export default TrustBoundaryNode;