import { CustomIconButton, StyledHeading } from "./QuestionHeading.styled";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const QuestionHeading = (props) => {
    const { hasInfo, handleClick, $smsize } = props;

    let icon = (
        <CustomIconButton onClick={handleClick} $smsize={$smsize}>
            <ErrorOutlinedIcon />
        </CustomIconButton>
    );
    return (
        <StyledHeading $smsize={$smsize}>
            {props.children}
            {hasInfo && icon}
        </StyledHeading>
    );
};

export default QuestionHeading;
