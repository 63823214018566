import { styled as MuiStyled } from '@mui/material/styles';
import MuiButton from "@mui/material/Button";
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

export const Button = MuiStyled(MuiButton)(({ theme, success, error, black }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: "Rubik",
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: '4px',
  color: '#fff',
  boxSizing: 'border-box',

  ...(success && {
    backgroundColor: '#46a11b',
    '&:hover': {
      backgroundColor: "#3a8d1c",
    }
  }),

  ...(error && {
    backgroundColor: '#ed1e24',
    '&:hover': {
      backgroundColor: "#a51519",
    }
  }),

}));

export const StyledToggleButtonGroup = MuiStyled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
  {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
  '& .MuiToggleButton-root': {
    '& .MuiBox-root': {
      width: '18px',
      height: '18px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
    }
  }
}));