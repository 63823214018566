import React from "react";
import {
    StyledBgTd,
    StyledHeading,
    StyledTable,
    StyledTableContainer,
    TableContainerTitle,
} from "./Table.styled";

const Table = ({ tableData, headingColumns, title }) => {
    const data = tableData.map((row, index) => {
        let rowData = [];
        let i = 0;

        for (const key in row) {
            rowData.push({
                key: headingColumns[i],
                val: row[key],
            });

            i++;
        }

        return (
            <tr key={index}>
                {rowData.map((data, index) => {
                    return (
                        <StyledBgTd
                            key={index}
                            data-heading={data.val}
                            datavalue={data.val}
                        >
                            {data.val}
                        </StyledBgTd>
                    );
                })}
            </tr>
        );
    });

    return (
        <StyledTableContainer>
            <TableContainerTitle>
                <h2>{title}</h2>
            </TableContainerTitle>

            <div style={{ display: "flex" }}>
                <StyledHeading>Likelihood</StyledHeading>
                <StyledTable>
                    <thead>
                        <tr>
                            {headingColumns.map((col, index) => {
                                return <th key={index}>{col}</th>;
                            })}
                        </tr>
                    </thead>

                    <tbody>{data}</tbody>
                </StyledTable>
            </div>
        </StyledTableContainer>
    );
};

export default Table;
