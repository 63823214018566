import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const columns = [
  {
    key: "securityControlClauses",
    header: "Security Control Clauses",
  },
  {
    key: "securityCategories",
    header: "Security Categories",
  },
  {
    key: "securityControl",
    header: "Security Control",
  },
  {
    key: "applicability",
    header: "Applicability",
  },
  {
    key: "reason",
    header: "Reason",
    // renderCell: ({ row }) => (<>
    //   {row.reason?.length > 0 ? `This control is applicable as it is a countermeasure for threat ID: ${row.reason}` : ""}
    // </>)
  },
  {
    key: "owner",
    header: "Owner/Position",
  },
  {
    key: "implementation",
    header: "Implementation Status",
  },
  {
    key: "gapAssessment",
    header: "Maturity Assessment",
  },
];

const SOADetailDialog = (props) => {

  // ** Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, toggle, data, handleEditClick } = props;

  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (open && data) {
      setDialogTitle("Statement Of Applicability Details");
    }
  }, [open, data])

  return (
    <Dialog
      open={open}
      onClose={toggle}
      scroll="paper"
      size="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ paddingRight: "80px" }} noWrap as={Typography} title={dialogTitle}>
        {dialogTitle}
      </DialogTitle>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <IconButton
          title="edit"
          aria-label="edit"
          onClick={(e) => {
            handleEditClick(data.caseID, data.status, data);
            toggle(e);
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="close"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        {
          open && data && <>
            <Card>
              {/* <CardHeader title="Statement Of Applicability Details" />
              <Divider /> */}

              {
                columns.map((column, index) => {
                  let value = data[column.key];

                  if (value && column.key === "reason") {
                    value = `This control is applicable as it is a countermeasure for threat ID: ${data.reason}`
                  }

                  // if (question.type === "date") {
                  // value = "";
                  // if (data[question.key] && data[question.key]['java.util.Date']) {
                  //   value = dayjs(new Date(data[question.key]['java.util.Date'])).format('DD/MM/YYYY')
                  // }
                  // return <React.Fragment key={`${data.caseID}-${question.key}`}>
                  //   <Box sx={{ px: 2, py: 1.25 }}>
                  //     <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                  //       {question.qHeading}
                  //     </Typography>
                  //     <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                  //       {typeof value === 'string' ? value : '-'}
                  //     </Typography>
                  //   </Box>
                  //   <Divider />
                  // </React.Fragment>
                  // }

                  if (Array.isArray(value)) {
                    return <React.Fragment key={`${data.caseID}-${column.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {column.header}
                        </Typography>
                        <Box>
                          {
                            value.length > 0 ? value.map((val) => <Chip label={val} key={val} />) : "-"
                          }
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  } else if (column.key === "gapAssessment") {
                    return <React.Fragment key={`${data.caseID}-${column.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {column.header}
                        </Typography>
                        <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                          <span style={{ color: value <= 2.5 ? "red" : "green" }}>
                            {value}
                          </span>
                        </Typography>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  }

                  return <React.Fragment key={`${data.caseID}-${column.key}`}>
                    <Box sx={{ px: 2, py: 1.25 }}>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                        {column.header}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                        {typeof value === 'string' && value ? value : '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </React.Fragment>
                })
              }
            </Card>
          </>
        }
      </DialogContent>
    </Dialog>
  );
};

export default SOADetailDialog;