import { CustomIconButton, StyledHeading } from "./QuestionTextstyled";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const QuestionText = (props) => {
    const { hasInfo, handleClick } = props;

    let icon = (
        <CustomIconButton onClick={handleClick}>
            <ErrorOutlinedIcon />
        </CustomIconButton>
    );

    return (
        <StyledHeading>
            {props.children}
            {hasInfo && icon}
        </StyledHeading>
    );
};

export default QuestionText;
