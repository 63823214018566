// Polyfills
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./styles/App.styled";
// import { AuthContextProvider } from "./store/auth-context";
import { ThemeContextProvider } from "./store/theme-context";
import { RiskContextProvider } from "./store/risk-context";
// import "./i18n";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./utility/store";
import { GuideContextProvider } from "./store/guide-context";
const eventLogger = (event: unknown, error: unknown) => {
    //console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: unknown) => {
    //console.log("onKeycloakTokens", tokens);
};

const app = (
    <React.StrictMode>
        <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
            <ThemeContextProvider>
                <RiskContextProvider>
                    <GuideContextProvider>
                    <GlobalStyle />
                    <BrowserRouter>
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </BrowserRouter>
                    </GuideContextProvider>
                </RiskContextProvider>
            </ThemeContextProvider>
        </ReactKeycloakProvider>
    </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
