import React, { useEffect, useState } from 'react';
import { PleaseChooseWhich, Text1, Cardgroup, UserTypeCardOnClickEvent, UsertypecardgroupRoot, ButtonAlignment } from "./UserTypeGroup.styled";
import {
  CongratulationYouHave, LetsStartTasks, LetsStartTasksContainer, CzMap1Icon, LinesIcon, Map1, Mapgroup, Usertypecardgroup2, ConfirmationscreengroupRoot,
  Button1, Button2, Button3, Button4, Button5, Button6,
  Radio1, Radio2, Radio3, Radio4, Radio5, Radio6
} from "./UserTypeConfirmation.styled";
import NoviceImg from "../../../../images/UserTypeSelection/novice.png";
import IntermediateImg from "../../../../images/UserTypeSelection/intermediate.png";
import AdvancedImg from "../../../../images/UserTypeSelection/advanced.png";
import UserTypeCard from "../UserTypeCard/UserTypeCard";
import Castle from "../../../../images/UserTypeSelection/castle.png";
import CastleLines from "../../../../images/UserTypeSelection/castleLines.svg";
import { Button } from "../../../../components/UI/Button/Button.styled";

import { Divider, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import styled from "styled-components";
import { UserTypes } from '../../../../store/auth-context';
import UserTypeCardV2 from '../UserTypeCard/UserTypeCardV2';
import { borderRadius } from '@mui/system';

const UserTypeGroup = ({ currentPage, userType, userTypeChange, handleClose }) => {
  var c = currentPage;
  const [activeItem, setActiveItem] = useState(null);
  const [currentPageState, setCurrentPageState] = useState(currentPage);
  const [_userType, setUserType] = useState(userType);
  const types = ["novice", "intermediate", "advanced"];

  const handleItemClick = (itemIndex) => {
    setActiveItem(itemIndex);
    userTypeChange(types[itemIndex - 1]);
    // handleCurrentPageState(2)
    handleClose();
  };
  const handleCurrentPageState = (itemIndex) => {
    setCurrentPageState(itemIndex);
  };

  useEffect(() => {
    switch (_userType) {
      case "novice":
        setActiveItem(1);
        break;
      case "intermediate":
        setActiveItem(2);
        break;
      case "advanced":
        setActiveItem(3);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      {currentPageState != 2 && (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography variant='h6'>Please select the user type that most closely resembles your level of cyber security expertise.</Typography>
              <Typography>The quality of the security service delivered to your organisation will not be affected by your choice.</Typography>
              <Divider />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={4} md={3} >
              <UserTypeCardOnClickEvent active={activeItem === 1} onClick={() => handleItemClick(1)}>
                <UserTypeCard title={UserTypes[0].title}
                  description={UserTypes[0].desc}
                  imgSrc={UserTypes[0].icon}
                  borderColor={activeItem === 1 ? 'rgba(93, 229, 106, 1)' : 'initial'}
                  style={{ border: activeItem === 1 ? "1px solid" : "none" }} />
              </UserTypeCardOnClickEvent>
            </Grid>
            <Grid item xs={12} sm={4} md={3} >
              <UserTypeCardOnClickEvent active={activeItem === 1} onClick={() => handleItemClick(2)}>
                <UserTypeCard title={UserTypes[1].title}
                  description={UserTypes[1].desc}
                  imgSrc={UserTypes[1].icon}
                  borderColor={activeItem === 2 ? 'rgba(93, 229, 106, 1)' : 'initial'}
                  border={activeItem === 2 ? "1px solid" : "none"} />
              </UserTypeCardOnClickEvent>
            </Grid>
            <Grid item xs={12} sm={4} md={3} >
              <UserTypeCardOnClickEvent active={activeItem === 1} onClick={() => handleItemClick(3)}>
                <UserTypeCard title={UserTypes[2].title}
                  description={UserTypes[2].desc}
                  imgSrc={UserTypes[2].icon}
                  borderColor={activeItem === 3 ? 'rgba(93, 229, 106, 1)' : 'initial'}
                  border={activeItem === 3 ? "1px solid" : "none"} />
              </UserTypeCardOnClickEvent>
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid> */}

            <Grid item xs={12} sx={{ marginTop: '52px !important' }}>
              <Grid xs={12} item container spacing={{ xs: 12, sm: 2 }} sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={4} md={3}>
                  <UserTypeCardOnClickEvent
                    active={activeItem === 1}
                    onClick={() => handleItemClick(1)}
                  >
                    <UserTypeCardV2
                      title={UserTypes[0].title}
                      description={UserTypes[0].desc}
                      imgSrc={UserTypes[0].icon}
                      borderColor={activeItem === 1 ? 'rgba(93, 229, 106, 1)' : 'initial'}

                    />
                  </UserTypeCardOnClickEvent>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <UserTypeCardOnClickEvent
                    active={activeItem === 2}
                    onClick={() => handleItemClick(2)}
                  >
                    <UserTypeCardV2
                      title={UserTypes[1].title}
                      description={UserTypes[1].desc}
                      imgSrc={UserTypes[1].icon}
                      borderColor={activeItem === 2 ? 'rgba(93, 229, 106, 1)' : 'initial'}
                      border={activeItem === 2 ? "1px solid" : "none"}
                    />
                  </UserTypeCardOnClickEvent>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <UserTypeCardOnClickEvent
                    active={activeItem === 3}
                    onClick={() => handleItemClick(3)}
                  >
                    <UserTypeCardV2
                      title={UserTypes[2].title}
                      description={UserTypes[2].desc}
                      imgSrc={UserTypes[2].icon}
                      borderColor={activeItem === 3 ? 'rgba(93, 229, 106, 1)' : 'initial'}
                      border={activeItem === 3 ? "1px solid" : "none"}
                    />
                  </UserTypeCardOnClickEvent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/**
          <UsertypecardgroupRoot>
            {/* <Text1>
          <PleaseChooseWhich>
            Please choose which type of user describes you best.
          </PleaseChooseWhich>
          <PleaseChooseWhich>
            You will be able to change your user type at any point of time through
            your user profile setting. The security of your organization will not
            be impacted by the user type selection.
          </PleaseChooseWhich>
        </Text1> */}

          {/* <Cardgroup>
              {}
              <UserTypeCardOnClickEvent active={activeItem === 1} onHover={() => console.log("hove on")} onClick={() => handleItemClick(1)}>
                <UserTypeCard title={"Novice"}
                  description1={"You do not have any prior experience or training in cybersecurity."}
                  description2={"You will require considerable guidance to use CisoZen."}
                  description3={"You will be presented with a simplified view of CisoZen."}
                  imgSrc={NoviceImg}
                  borderColor={activeItem === 1 ? 'rgba(93, 229, 106, 1)' : 'initial'} />
              </UserTypeCardOnClickEvent>
              <UserTypeCardOnClickEvent active={activeItem === 2} onClick={() => handleItemClick(2)}>
                <UserTypeCard title={"Intermediate"}
                  description1={"You have some prior experience or training in cybersecurity."}
                  description2={"You will require reduced guidance to use CisoZen."}
                  description3={"You will be presented with an enhanced view of CisoZen compared to the novice user."}
                  imgSrc={IntermediateImg}
                  borderColor={activeItem === 2 ? 'rgba(93, 229, 106, 1)' : 'initial'} />
              </UserTypeCardOnClickEvent>
              <UserTypeCardOnClickEvent active={activeItem === 3} onClick={() => handleItemClick(3)}>
                <UserTypeCard title={"Advanced"}
                  description1={"You have substantial prior experience and/or training in cybersecurity."}
                  description2={"You will require minimal guidance to use CisoZen."}
                  description3={"You will be presented with a complete view of CisoZen."}
                  imgSrc={AdvancedImg}
                  borderColor={activeItem === 3 ? 'rgba(93, 229, 106, 1)' : 'initial'} />
              </UserTypeCardOnClickEvent>
            </Cardgroup>
            {}
            <ButtonAlignment>
              <Button onClick={() => handleCurrentPageState(2)} disabled={activeItem === null} $success>Confirm</Button>
            </ButtonAlignment>
          </UsertypecardgroupRoot>  */}
        </>
      )}

      {currentPageState == 2 && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Congratulation! You have finished the User Type Selection.
              </Typography>
              <Typography>
                Let’s start tasks in the Maturity scores to obtain more CisoZen
                scores!
              </Typography>
            </Grid>
          </Grid>
          <ConfirmationscreengroupRoot>
            <Mapgroup>
              <Typography variant='h6'>
              </Typography>
              <Typography>
              </Typography>
              <Map1>
                <CzMap1Icon alt="" src={Castle} />
                <LinesIcon alt="" src={CastleLines} />
              </Map1>
              <Button1>
                <Button variant="contained" color="success">
                  1. Rapid Health Check
                </Button>
              </Button1>
              <Button2>
                <Button variant="contained" color="success">
                  2. Recommendation
                </Button>
              </Button2>
              <Button3>
                <Button variant="contained" color="success">
                  3. Risk Matrix
                </Button>
              </Button3>
              <Button4>
                <Button variant="contained" color="success">
                  4. Maturity Scores
                </Button>
              </Button4>
              <Button5>
                <Button variant="contained" color="success">
                  5. Threat and Risk Assessment
                </Button>
              </Button5>
              <Button6>
                <Button variant="contained" color="success">
                  6. Vulnerabilities
                </Button>
              </Button6>
              <Radio1
                label=""
                control={<Radio color="success" checked size="medium" />}
              />
              <Radio2
                label=""
                control={<Radio color="success" checked size="medium" />}
              />
              <Radio3
                label=""
                control={<Radio color="success" checked size="medium" />}
              />
              <Radio4
                label=""
                control={<Radio color="success" checked disabled size="medium" />}
              />
              <Radio5
                label=""
                control={<Radio color="error" checked disabled size="medium" />}
              />
              <Radio6
                label=""
                control={<Radio color="error" checked disabled size="medium" />}
              />
            </Mapgroup>
          </ConfirmationscreengroupRoot>
        </>)}
    </>
  );
};

export default UserTypeGroup;
