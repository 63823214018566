const initialState = {
  caseID: null,
  caseData: null,
  data: null,
  loading: false,
  error: null,
  allowFetch: false, // Initialize as false
};

const soaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SOA_ALLOW_FETCH':
      return { ...state, allowFetch: action.payload };
    case 'TOGGLE_SOA_LOADING':
      return { ...state, loading: action.payload };
    case 'FETCH_SOA_DATA_SUCCESS':
      return {
        ...state,
        data: action.payload.data,
        caseID: action.payload.caseID,
        caseData: action.payload.caseData,
        loading: false,
        error: null
      };
    case 'FETCH_SOA_DATA_ERROR':
      return {
        ...state,
        data: [],
        caseID: null,
        caseData: null,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default soaReducer;
