import { FormControlLabel, Grid, RadioGroup } from "@mui/material";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import ModalImageSrc from "../../../../images/risk.png";
import { CONTAINER_ID, HealthCheckProcess, RiskMatrixProcess } from "../../../../store/auth-context";
import RiskContext from "../../../../store/risk-context";
import FinalRisk from "../../components/FinalRisk/FinalRisk";
import InfoModal from "../../components/InfoModal/InfoModal";
import { RecHeading, StyledRadio } from "../../components/RecommendationItem/RecommendationItem.styled";
import RiskActions from "../../components/RiskActions/RiskActions";
import RiskInformation from "../../components/RiskInformation/RiskInformation";
import RiskTabs from "../../components/RiskTabs/RiskTabs";
import Table from "../../components/Table/Table";
import { Table3Data, Table4Data, Table5Data } from "../../components/Table/TableData";
import { StyledInformationIcon } from "../Recommendations/Recommendations.styled";
import { BtnsContainer, CenterHeadingContainer, ContentContainer, StyledComplateContainer, StyledFormControl, StyledTableContainer, StyledTableFormControl } from "./RiskMatrix.styled";
import { useKeycloak } from "@react-keycloak/web";
import { getActiveHealthCheck, getActiveRiskMatrix, getCompletedHealthCheck, getCompletedRiskMatrix } from "../../InformationAPI";
import logo from "../../../../images/logo0.2.png";
import sorryAnimation from "../../../../assets/animations/sorry.json";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowRiskUpdate } from "../../../../utility/dataAction";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { Helmet } from "react-helmet-async";
import { fetchRiskMatrixData, toggleAllowFetchRiskMatrix } from "../../../../utility/riskMatrix.action";
import { updateTrackerData } from "../../../../utility/tracker.action";
import RiskTabsV2 from "../../components/RiskTabs/RiskTabsV2";

const RiskMatrix = (props) => {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const riskMatrixState = useSelector((state) => state.riskMatrix);
    const { riskMatrixExists, riskMatrixDimensions, riskInformation, riskConsequence, riskActions, riskMatrixTableData } = riskMatrixState;

    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [header, setHeader] = useState(null);
    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);
    const [completedHealthCheckID, setcompletedHealthCheckID] = useState(null);
    const [activeRiskID, setActiveRiskID] = useState(null);
    const [completedRiskID, setcompletedRiskID] = useState(null);

    const history = useHistory();
    const riskCtx = useContext(RiskContext);
    const { step, question1, question1ChangeHandler, nextStep, navigateStepHandler, question2, question2ChangeHandler, reset } = riskCtx;
    const [openModal, setOpenModal] = useState(false);

    // const [processInstanceId, setProcessInstanceId] = useState(null);
    const [riskMatrixCompleted, setRiskMatrixCompleted] = useState(false);
    const [taskInstanceId, setTaskInstanceId] = useState(null);
    //const [taskTitle, setTaskTitle] = useState('');
    // const [questions, setQuestions] = useState([])
    //const [questionNumber, setQuestionNumber] = useState(0)
    const [fetching, setFetching] = useState(false)
    //const [userAnswers, setUserAnswers] = useState({});
    // const [riskInformation, setRiskInformation] = useState(null);
    // const [submittedRiskInfo, setSubmittedRiskInfo] = useState(null);

    // const [riskConsequence, setRiskConsequence] = useState(null);
    // const [submittedRiskConcequence, setSubmittedRiskConcequence] = useState(null);

    // const [riskActions, setRiskActions] = useState(null);
    // const [submittedRiskActions, setSubmittedRiskActions] = useState(null);

    const [riskFinal, setRiskFinal] = useState(null);
    const [riskFinalSubmitted, setRiskFinalSubmitted] = useState(null);
    const [riskFinalData, setRiskFinalData] = useState({});
    // var riskFinalData = {};
    var riskLikelihoodData = {};
    var riskConsequenceData = {};
    var riskManagementData = {};

    const [loading, setIsLoading] = useState(true);
    const [submittingReq, setSubmittingReq] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {

        let stepTitle = {
            0: "Risk Management",
            1: "Risk Management Dimensions",
            2: "Likelihood Description",
            3: "Impact Definition",
            4: "Risk Management Actions",
            5: "Risk Management Matrix",
        }

        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Risk Matrix Progress - ${stepTitle[riskCtx.step] || ''}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch, riskCtx?.step])

    const handleEdit = (edit) => {
        setIsEdit(edit);
    }

    const handleCallback = useCallback((childData) => {
        // riskFinalData = childData;
        setRiskFinalData(childData);

        if (childData["SelectedTolerance"] && (childData["SelectedTolerance"] !== riskMatrixTableData.selectedValue)) {
            dispatch({
                type: "RM_UPDATE_RISK_MATRIX_TOLERANCE",
                payload: childData["SelectedTolerance"]
            })
        }
    }, [riskMatrixTableData])

    const updateLikelihood = (childData) => {
        riskLikelihoodData = childData;
    }

    const updateConsequence = (childData) => {
        riskConsequenceData = childData;
    }

    const updateManagement = (childData) => {
        riskManagementData = childData;
    }

    useEffect(() => {
        setKcToken(keycloak.token);
        setHeader({ headers: { "Authorization": `Bearer ${keycloak.token}`, 'Content-Type': 'application/json' } });
    }, [props.signal])

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
            });
        }
    }, [keycloak.authenticated])

    function getAllInstance(_email, _kcToken) {
        if (!_email || !_kcToken) {
            return;
        }

        setHeader({ headers: { "Authorization": `Bearer ${_kcToken}`, 'Content-Type': 'application/json' } });

        getActiveHealthCheck(_kcToken, _email).then((response) => {
            setActiveHealthCheckID(response.data);
        });

        getCompletedHealthCheck(_kcToken, _email).then((response) => {
            setcompletedHealthCheckID(response.data);
        });

        getActiveRiskMatrix(_kcToken, _email).then((response) => {
            setActiveRiskID(response.data);
            let activeRiskID = response.data;
            if (activeRiskID > 0) {
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                // setProcessInstanceId(activeRiskID);
                return;
            } else {
                setIsLoading(true);
                createNewRiskMatrixInstance(header);
            }
        });

        // dispatch(fetchRiskMatrixData(keycloak, _email));
        // getCompletedRiskMatrix(_kcToken, _email).then((response) => {
        //     setcompletedRiskID(response.data);
        // });
    }

    //useEffect to control ids
    useEffect(() => {
        if (CONTAINER_ID === "") return;
        dispatch(fetchRiskMatrixData(keycloak, email));
    }, [email, kcToken, dispatch, keycloak]);

    useEffect(() => {
        if (CONTAINER_ID === "") return;
        if (riskMatrixState.loading) return;
        getAllInstance(email, kcToken);
    }, [email, kcToken, CONTAINER_ID, riskMatrixState?.loading]);

    const getRiskMatrixQuestions = useCallback(async (taskInstId, taskName, header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstId}/contents/input`,
                ...header,
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const str = res.data['NodeName']
                let questionNum = +str.replace(/\D/g, '');
                if (str === 'Details of Risk Matrix') {
                    navigateStepHandler(0);
                } else if (str === 'Dimensions') {
                    navigateStepHandler(1);
                } else if (str.endsWith('Likelihood Description')) {
                    const fetchedfields = {};
                    for (let key in res.data) {
                        if (key.startsWith('likelihood')) {
                            fetchedfields[key] = res.data[key];
                        }
                    }
                    const fetchedfieldsKeys = Object.keys(fetchedfields).sort()
                    const keysLength = fetchedfieldsKeys.length;
                    let formattedData = [
                        {
                            id: "i1",
                            head: "Likelihood",
                            title: "Description",
                        },
                    ]
                    for (let i = 1; i <= (keysLength / 2); i++) {
                        formattedData[i] = {
                            id: `i${i + 1}`,
                            head: fetchedfields[`likelihoodRow${i}`],
                            title: (riskInformation && riskInformation[i]?.title) || fetchedfields[`likelihoodContent${i}`],
                        };
                    }
                    navigateStepHandler(2);
                    dispatch({
                        type: 'RM_UPDATE_RISK_MATRIX_INFORMATION',
                        payload: formattedData
                    })
                    // setSubmittedRiskInfo(fetchedfields);
                    // setRiskInformation(formattedData)
                } else if (str.endsWith('Consequence Description')) {
                    let fetchedTabs = [];
                    let fetchedLevels = [];
                    let fetchedfields = [];
                    var tabIndex = 1;
                    for (let key in res.data) {
                        if (key.startsWith('riskCategoryRow') || key.startsWith('riskCategory')) {
                            fetchedTabs.push({ name: res.data[key], id: tabIndex, levels: [] })
                        }
                        if (key.startsWith('consCat')) {
                            fetchedfields.push({ name: key, desc: res.data[key] })
                        }
                        if (key.startsWith('consequenceCategory')) {
                            fetchedLevels.push(res.data[key])
                        }
                    }

                    let newFields = riskConsequence.fields.map((f) => ({ ...f }));

                    if (riskConsequence.levels.length < fetchedLevels.length) {
                        for (let i = riskConsequence.levels.length; i < fetchedLevels.length; i++) {
                            riskConsequence.tabs.forEach((_, index) => {
                                let name = `consCat${i + 1}Row${index + 1}`;
                                newFields.push({ name: name, desc: res.data[name] || '' });
                            })
                        }
                    }

                    navigateStepHandler(3);
                    let userFieldData = riskConsequence?.fields?.reduce((acc, { name, desc }) => ({ ...acc, [name]: desc }), {}) || {};
                    dispatch({
                        type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
                        payload: {
                            tabs: riskConsequence.tabs.map((t) => ({ ...t })),
                            levels: fetchedLevels,
                            fields: newFields,
                            // rawData: rawData
                        }
                    })


                    // let fetchedTabs = [];
                    // let fetchedLevels = [];
                    // let fetchedfields = [];
                    // var tabIndex = 1;
                    // for (let key in res.data) {
                    //     if (key.startsWith('riskCategoryRow') || key.startsWith('riskCategory')) {
                    //         fetchedTabs.push({ name: res.data[key], id: tabIndex, levels: [] })
                    //     }
                    //     if (key.startsWith('consCat')) {
                    //         fetchedfields.push({ name: key, desc: res.data[key] })
                    //     }
                    //     if (key.startsWith('consequenceCategory')) {
                    //         fetchedLevels.push(res.data[key])
                    //     }
                    // }
                    // // let rawData = [];
                    // // if (res?.data && res.data["impactDefinitionList"]) {
                    // //     let levelCount = ((Object.keys(res.data["impactDefinitionList"][0]["com.cisozen.ImpactDefinitionData"]).length - 1) / 2);
                    // //     res.data["impactDefinitionList"].forEach((obj) => {
                    // //         // Add Tabs
                    // //         fetchedTabs.push({ name: obj["com.cisozen.ImpactDefinitionData"]["title"] });
                    // //         let rawDataObj = {};
                    // //         for (let i = 1; i <= levelCount; i++) {
                    // //             // Add fields
                    // //             fetchedfields.push({
                    // //                 name: obj["com.cisozen.ImpactDefinitionData"][`c${i}Key`],
                    // //                 desc: obj["com.cisozen.ImpactDefinitionData"][`c${i}Value`],
                    // //             })
                    // //             // Add raw data
                    // //             rawDataObj[obj["com.cisozen.ImpactDefinitionData"][`c${i}Key`]] = obj["com.cisozen.ImpactDefinitionData"][`c${i}Value`];
                    // //         }
                    // //         rawData.push(rawDataObj);
                    // //     })
                    // //     for (let i = 1; i <= levelCount; i++) {
                    // //         fetchedLevels.push(res.data["impactDefinitionList"][0]["com.cisozen.ImpactDefinitionData"][`c${i}Key`]);
                    // //     }
                    // // }
                    // navigateStepHandler(3);
                    // let userFieldData = riskConsequence?.fields?.reduce((acc, { name, desc }) => ({ ...acc, [name]: desc }), {}) || {};
                    // dispatch({
                    //     type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
                    //     payload: {
                    //         tabs: fetchedTabs.reverse(),
                    //         levels: fetchedLevels,
                    //         fields: fetchedfields.map((field) => {
                    //             if (userFieldData[field.name]) {
                    //                 return { ...field, desc: userFieldData[field.name] };
                    //             } else {
                    //                 return { ...field };
                    //             }
                    //         }),
                    //         // rawData: rawData
                    //     }
                    // })
                } else if (str.endsWith('Risk Management Plan')) {
                    const fetchedLevels = [];
                    const fetchedActions = [];
                    const fetchedPlans = [];
                    for (let key in res.data) {
                        const id = key.slice(-1);

                        if (key.startsWith('rmRow')) {
                            fetchedLevels.push({ "id": id, "value": res.data[key] })
                        }
                        if (key.startsWith('rmAction')) {
                            fetchedActions.push({ "id": id, "value": res.data[key] })
                        }
                        if (key.startsWith('rmTreatment')) {
                            fetchedPlans.push({ "id": id, "value": res.data[key] })
                        }
                    }

                    const levels = new Array(fetchedLevels.length);
                    const actions = new Array(fetchedActions.length);
                    const plans = new Array(fetchedPlans.length);
                    for (let index = 0; index < levels.length; index++) {
                        levels[index] = fetchedLevels.find(level => level["id"] == index + 1).value;
                        actions[index] = riskActions?.actions[index] ? riskActions.actions[index] : fetchedActions.find(action => action["id"] == index + 1).value;
                        plans[index] = riskActions?.plans[index] ? riskActions.plans[index] : fetchedPlans.find(plan => plan["id"] == index + 1).value;
                    }

                    dispatch({
                        type: 'RM_UPDATE_RISK_MATRIX_ACTIONS',
                        payload: {
                            levels: levels,
                            actions: actions,
                            plans: plans
                        }
                    })

                    // setRiskActions({
                    //     levels: levels,
                    //     actions: actions,
                    //     plans: plans
                    // })
                    // setSubmittedRiskActions(res.data)
                    navigateStepHandler(4);
                } else if (str.endsWith('Risk Matrix')) {
                    const num = +res.data['NodeName'].charAt(0);
                    setRiskFinal(num);
                    setRiskFinalSubmitted(res.data);
                    navigateStepHandler(5);
                } else {
                    //setQuestionNumber(questionNum - 1)
                    navigateStepHandler(questionNum - 1);
                }
                setTimeout(() => { setFetching(false); }, 1000);
            });

        } catch (err) {
            setFetching(false);
            toast.error(err.message);
        }
    }, [dispatch, navigateStepHandler, riskActions, riskConsequence, riskInformation])

    const getRiskMatrixTask = useCallback(async (matrixInstance, header) => {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${matrixInstance}`,
                ...header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                const taskInst = res.data["active-user-tasks"]["task-summary"][0]["task-id"];
                const taskIName = res.data["active-user-tasks"]["task-summary"][0]["task-name"];
                setTaskInstanceId(taskInst);
                //setTaskTitle(res.data["task-summary"][0]["task-name"]);
                setFetching(true);
                setIsLoading(false);
                getRiskMatrixQuestions(taskInst, taskIName, header);
                if (res.data["active-user-tasks"]["task-summary"][0]["task-status"] !== 'InProgress') {
                    let config2 = {
                        method: 'put',
                        url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInst}/states/started`,
                        data: JSON.stringify({}),
                        ...header
                    }
                    fetchInstance.apiRequest(config2)
                }
            });

        } catch (error) {
            toast.error("Something went wrong, Please try again");
        }
    }, [getRiskMatrixQuestions]);

    const createNewRiskMatrixInstance = useCallback(async (header) => {
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/processes/${RiskMatrixProcess}/instances`,
                data: JSON.stringify({}),
                ...header
            }
            await fetchInstance.apiRequest(config).then((res) => {
                if (res.status === 201) {
                    const progressInstance = res.data;
                    setActiveRiskID(progressInstance);
                }
                setIsLoading(false);
            });

        } catch (error) {
            setIsLoading(false);
            toast.error("Something went wrong, Please try again");
        }
    }, []);

    // useEffect(() => {
    //     if (riskMatrixCompleted) return;

    //     if (activeRiskID === null || header === null) {
    //         return;
    //     };

    //     if (activeRiskID > 0) {
    //         setIsLoading(true);
    //         getRiskMatrixTask(activeRiskID, header);
    //         // setProcessInstanceId(activeRiskID);
    //         return;
    //     } else {
    //         setIsLoading(true);
    //         createNewRiskMatrixInstance(header);
    //     }
    // }, [activeRiskID, createNewRiskMatrixInstance, getRiskMatrixTask, riskMatrixCompleted, header]);

    const submitExistingMatrixQuestion = () => {
        setSubmittingReq(true);
        const payload = {
            // existingMatrix: question1,
            existingMatrix: riskMatrixExists,
        }
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            data: JSON.stringify(payload),
            ...header
        }
        fetchInstance.apiRequest(config)
            .then((res) => {
                setSubmittingReq(false);
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                nextStep();
            })
            .catch((err) => {
                setSubmittingReq(false);
                toast.error("Something went wrong, Please try again")
            });
    }

    const submitMatrixDimensionsQuestion = () => {
        setSubmittingReq(true);
        const payload = {
            // dimensions: question2,
            dimensions: riskMatrixDimensions,
        }
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            data: JSON.stringify(payload),
            ...header
        }
        fetchInstance.apiRequest(config)
            .then((res) => {
                setSubmittingReq(false);
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                nextStep();
            })
            .catch((err) => {
                setSubmittingReq(false);
                toast.error("Something went wrong, Please try again")
            });
    }

    const submitLikelihoodQuestion = () => {
        setSubmittingReq(true);
        function dataForApi() {
            var dataObj = {};
            for (let index = 1; index < riskInformation.length; index++) {
                const element = riskInformation[index];
                dataObj[`likelihoodRow${index}`] = element["head"];
                dataObj[`likelihoodContent${index}`] = element["title"];
            }
            return dataObj;
        }
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            data: JSON.stringify(dataForApi()),
            ...header
        }
        fetchInstance.apiRequest(config)
            .then((res) => {
                setSubmittingReq(false);
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                nextStep();
                // Fetch latest data
                dispatch(toggleAllowFetchRiskMatrix(true));
            })
            .catch((err) => {
                setSubmittingReq(false);
                toast.error("Something went wrong, Please try again")
            });
    }

    const submitRiskConsequence = async () => {

        function dataForApi({ tabs, levels, fields }) {
            var obj = {};
            tabs.forEach(function (value, i) {
                //data from category tabs
                obj[`riskCategoryRow${i + 1}`] = value["name"];
                //data from descriptions
                for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
                    const value = fields.find(field => field.name === `consCat${levelIndex + 1}Row${i + 1}`);
                    obj[`consCat${levelIndex + 1}Row${i + 1}`] = value ? value["desc"] : "";
                    obj[`consequenceCategory${levelIndex + 1}`] = levels[levelIndex];
                }
            });
            return obj
        }

        let data = await dataForApi(riskConsequence);

        riskConsequence.tabs.forEach((riskCategory, index) => {
            data[`riskCategory${index + 1}`] = riskCategory.name;
        });

        // let impactDefinitionList = [];
        // riskConsequence.tabs.forEach((riskCategory, index) => {

        //     let obj = {};
        //     obj["title"] = riskCategory["name"];

        //     riskConsequence.levels.forEach((level, lIndex) => {
        //         obj[`c${lIndex + 1}Key`] = level;
        //         obj[`c${lIndex + 1}Value`] = riskConsequence.rawData[index][level];
        //     })

        //     impactDefinitionList.push({ "com.cisozen.ImpactDefinitionData": obj })

        // })

        // riskConsequence.tabs.map((riskCategory, index) => {
        //     riskConsequenceData[`riskCategory${index + 1}`] = riskCategory.name;
        // });

        setSubmittingReq(true);
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            data: JSON.stringify(data),
            // data: JSON.stringify({
            //     impactDefinitionList: impactDefinitionList
            // }),
            // data: JSON.stringify(riskConsequenceData),
            ...header,
        }
        fetchInstance
            .apiRequest(config)
            .then((res) => {
                setSubmittingReq(false);
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                nextStep();
                // Fetch latest data
                dispatch(toggleAllowFetchRiskMatrix(true));
            })
            .catch((err) => {
                setSubmittingReq(false);
                toast.error("Something went wrong, Please try again")
            });
    }

    const submitRiskActions = async () => {
        setSubmittingReq(true);
        function dataForApi({ levels, actions, plans }) {
            let obj = {};
            for (let index = 0; index < levels.length; index++) {
                obj[`rmRow${index + 1}`] = levels[index];
                obj[`rmAction${index + 1}`] = actions[index];
                obj[`rmTreatment${index + 1}`] = plans[index];
            }
            return obj
        }
        let data = await dataForApi(riskActions);
        let config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            // data: JSON.stringify(riskManagementData),
            data: JSON.stringify(data),
            ...header
        }
        fetchInstance
            .apiRequest(config)
            .then((res) => {
                setSubmittingReq(false);
                setIsLoading(true);
                getRiskMatrixTask(activeRiskID, header);
                nextStep();
                // Fetch latest data
                dispatch(toggleAllowFetchRiskMatrix(true));
            })
            .catch((err) => {
                setSubmittingReq(false);
                toast.error("Something went wrong, Please try again")
            });
    }

    const submitRiskFinal = useCallback(() => {
        if (!!riskFinalData["SelectedTolerance"]) {
            setSubmittingReq(true);
            let config = {
                method: 'put',
                url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
                data: JSON.stringify(riskFinalData),
                ...header
            }
            fetchInstance
                .apiRequest(config)
                .then((res) => {
                    setSubmittingReq(false);
                    setRiskMatrixCompleted(true);
                    setActiveRiskID(null);
                    dispatch(toggleAllowRiskUpdate(true));
                    // Fetch latest data
                    dispatch(toggleAllowFetchRiskMatrix(true));
                    reset();
                })
                .catch((err) => {
                    setSubmittingReq(false);
                    toast.error("Something went wrong, Please try again")
                });
        } else {
            toast.error("Select the tolerance threshold!")
        }

    }, [dispatch, header, riskFinalData, taskInstanceId])

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    if (completedHealthCheckID === 0) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to complete Cyber Security Health Check!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/overview")}>
                            Back To Dashboard
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>

        );
    }

    if (loading || fetching) {
        return <Loader height='90vh' />;
    }
    if (riskMatrixCompleted && !loading) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>Congratulations!</h1><br /><p> You now have one of the single most important tools to help you manage cyber security risks in your organisation.  This tool will help you identify where to best spend your cyber security budget to support your business goals and objectives.  It will guide you on only addressing the cyber security risks that your business can’t accept or tolerate.  Other risks can be managed as per you normal activities and within the usual constraints of time and money.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => handleBackbutton()}>
                            Done
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    function handleBackbutton() {
        // if (!activeRiskID) {
        history.push("/it-security/risk-matrix");
        // window.location.href = "/dashboard/information";
        // }
    }

    let content;
    if (!loading && !fetching && !riskMatrixState.loading) {
        switch (step) {
            case 0:
                content = (
                    <Fragment>
                        <ContentContainer smPad>
                            <RecHeading>
                                Do you have an existing risk matrix for your organization?
                                <StyledInformationIcon hideicon="true" onClick={handleOpenModal} />
                            </RecHeading>
                            <StyledFormControl>
                                <RadioGroup row value={riskMatrixExists} onChange={(e) => {
                                    // question1ChangeHandler(e);
                                    dispatch({
                                        type: 'RM_UPDATE_RISK_MATRIX_EXISTS',
                                        payload: e.target.value,
                                    })
                                }}>
                                    <FormControlLabel value="Yes" control={<StyledRadio />} label="Yes" />
                                    <FormControlLabel value="No" control={<StyledRadio />} label="No" />
                                </RadioGroup>
                            </StyledFormControl>
                        </ContentContainer>
                        <BtnsContainer >
                            <Button $success upper="true" disabled={riskMatrixExists === null} onClick={submitExistingMatrixQuestion} loading={submittingReq}>
                                Next
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                );
                break;
            case 1:
                content = (
                    <Fragment>
                        <ContentContainer sm>
                            <RecHeading riskPad>What are the dimensions of your existing or required risk matrix?</RecHeading>
                            <StyledTableFormControl>
                                <RadioGroup value={riskMatrixDimensions} onChange={(e) => {
                                    // question2ChangeHandler(e);
                                    dispatch({
                                        type: 'RM_UPDATE_RISK_MATRIX_DIMENSIONS',
                                        payload: e.target.value,
                                    })
                                }}>
                                    <StyledTableContainer>
                                        <FormControlLabel value="3x3" control={<StyledRadio />} label="3 x 3" />
                                        <Table tableData={Table3Data} headingColumns={["", "Insignificant", "Moderate", "Catastrophic"]} title="Consequence" />
                                    </StyledTableContainer>
                                    <StyledTableContainer>
                                        <FormControlLabel value="4x4" control={<StyledRadio />} label="4 x 4" />
                                        <Table tableData={Table4Data} headingColumns={["", "Insignificant", "Minor", "Moderate", "Catastrophic"]} title="Consequence" />
                                    </StyledTableContainer>
                                    <StyledTableContainer>
                                        <FormControlLabel value="5x5" control={<StyledRadio />} label="5 x 5" />
                                        <Table tableData={Table5Data} headingColumns={["", "Insignificant", "Minor", "Moderate", "Major", "Catastrophic"]} title="Consequence" />
                                    </StyledTableContainer>
                                </RadioGroup>
                            </StyledTableFormControl>
                        </ContentContainer>
                        <BtnsContainer smallMT >
                            <Button $success upper="true" disabled={riskMatrixDimensions === null} onClick={submitMatrixDimensionsQuestion} loading={submittingReq}>
                                Next
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                );
                break;
            case 2:
                content = (
                    <Fragment>
                        <RiskInformation riskInformation={riskInformation} handleEdit={handleEdit} parentCallback={updateLikelihood} />
                        <BtnsContainer >
                            <Button $success upper="true" onClick={submitLikelihoodQuestion} loading={submittingReq} disabled={isEdit}>
                                Next
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                )
                break;
            case 3:
                content = (
                    <Fragment>
                        <RiskTabs
                            riskConsequence={riskMatrixState?.riskConsequence || {
                                tabs: [],
                                levels: [],
                                fields: {}
                            }}
                            handleEdit={handleEdit}
                            parentCallback={updateConsequence}
                        />
                        {/* <RiskTabsV2
                            riskConsequence={riskMatrixState?.riskConsequence || {
                                tabs: [],
                                levels: [],
                                fields: {}
                            }}
                            handleEdit={handleEdit}
                            parentCallback={updateConsequence}
                        /> */}
                        <BtnsContainer >
                            <Button $success upper="true" onClick={submitRiskConsequence} loading={submittingReq} disabled={isEdit}>
                                Next
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                );
                break;
            case 4:
                content = (
                    <Fragment>
                        <ContentContainer noPad>
                            <RiskActions
                                riskActions={riskMatrixState?.riskActions || {
                                    levels: [],
                                    actions: [],
                                    plans: []
                                }}
                                handleEdit={handleEdit}
                                parentCallback={updateManagement}
                            />
                        </ContentContainer>
                        <BtnsContainer >
                            <Button $success upper="true" onClick={submitRiskActions} loading={submittingReq} disabled={isEdit}>
                                Next
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                )
                break;
            case 5:
                content = (
                    <Fragment>
                        <ContentContainer noPad>
                            <FinalRisk riskFinal={riskFinal} data={riskFinalSubmitted} parentCallback={handleCallback} selectedValue={riskMatrixState.riskMatrixTableData?.selectedValue} />
                        </ContentContainer>
                        <BtnsContainer >
                            <Button $success upper="true" onClick={submitRiskFinal} loading={submittingReq} >
                                Submit
                            </Button>
                        </BtnsContainer>
                    </Fragment>
                )
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Helmet>
                <title>Risk Matrix Progress - CisoZen</title>
            </Helmet>
            <CenterHeadingContainer>
                {riskCtx.step < 2 && (
                    <Heading marginMd="32px 0" marginSm="24px 18px">
                        Risk Management
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                )}
                {riskCtx.step === 2 && (
                    <Heading marginMd="32px 0" marginSm="24px 18px">
                        Likelihood description
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                )}
                {riskCtx.step === 3 && (
                    <Heading marginMd="32px 0" marginSm="24px 18px">
                        Impact definition
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                )}
                {riskCtx.step === 4 && (
                    <Heading marginMd="32px 0" marginSm="24px 18px">
                        Risk management actions
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                )}
                {riskCtx.step === 5 && (
                    <Heading marginMd="32px 0" marginSm="24px 18px">
                        Risk Management Matrix
                        <StyledInformationIcon onClick={handleOpenModal} />
                    </Heading>
                )}
            </CenterHeadingContainer>
            {content}

            <InfoModal
                open={openModal}
                handleClose={handleCloseModal}
                imgSrc={ModalImageSrc}
                title={""}
                list={["Maximum time you should spend on this risk management activity : 1 Weeks"]}
            />
        </>
    );
};

export default RiskMatrix;
