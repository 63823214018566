// import { Property } from "csstype";
import styled from "styled-components";

const FrameChild = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: #4d9f3f;
  width: 32px;
  height: 32px;
  z-index: 0;
  background-color: ${(p) => p.ellipseDivBackgroundColor};
`;
const Div = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 8.5px;
  left: 7px;
  letter-spacing: 0.4px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 15px;
  flex-shrink: 0;
  z-index: 1;
  color: #fff;
`;
const EllipseParent = styled.div`
  width: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const DefineNewAsset = styled.b`flex: 1;
  position: relative;
  font-size: 24px;
  letter-spacing: 0.4px;
  line-height: 20px;
  text-align: left;
  flex: ${(p) => p.defineNewAssetFlex}
  font-size: ${(p) => p.defineNewAssetFontSize}
  display: ${(p) => p.defineNewAssetDisplay}
  align-items: ${(p) => p.defineNewAssetAlignItems}
  width: ${(p) => p.defineNewAssetWidth}
  flex-shrink: ${(p) => p.defineNewAssetFlexShrink}
`;
const QuestionTitleRoot = styled.div`position: relative;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
  font-family: Rubik;
  position: ${(p) => p.questionTitlePosition}
  width: ${(p) => p.questionTitleWidth}
`;
const QuestionTitle = ({
  prop,
  defineNewAsset,
  questionTitlePosition,
  questionTitleWidth,
  ellipseDivBackgroundColor,
  defineNewAssetFlex,
  defineNewAssetFontSize,
  defineNewAssetDisplay,
  defineNewAssetAlignItems,
  defineNewAssetWidth,
  defineNewAssetFlexShrink,
}) => {
  return (
    <QuestionTitleRoot
      questionTitlePosition={questionTitlePosition}
      questionTitleWidth={questionTitleWidth}
    >
      <EllipseParent>
        <FrameChild ellipseDivBackgroundColor={ellipseDivBackgroundColor} />
        <Div>{prop}</Div>
      </EllipseParent>
      <DefineNewAsset
        defineNewAssetFlex={defineNewAssetFlex}
        defineNewAssetFontSize={defineNewAssetFontSize}
        defineNewAssetDisplay={defineNewAssetDisplay}
        defineNewAssetAlignItems={defineNewAssetAlignItems}
        defineNewAssetWidth={defineNewAssetWidth}
        defineNewAssetFlexShrink={defineNewAssetFlexShrink}
      >
        {defineNewAsset}
      </DefineNewAsset>
    </QuestionTitleRoot>
  );
};

export default QuestionTitle;
