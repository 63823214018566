import { Button } from "../../../../components/UI/Button/Button.styled";
import { FormControlLabel, Radio } from "@mui/material";
import styled from "styled-components";

export const CongratulationYouHave = styled.h3`
    margin: 0;
    align-self: stretch;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    line-height: 24px;
    font-weight: 700;
    font-family: inherit;
`;
export const LetsStartTasks = styled.p`
    margin: 0;
    align-self: stretch;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    line-height: 24px;
    font-weight: 500;
    font-family: inherit;
    color: #fff;
`;
export const LetsStartTasksContainer = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.46px;
  line-height: 26px;
  font-weight: 500;
  font-family: inherit;
  color: #fff;
  display: inline-block;
  width: 704px;
  height: 23px;
  flex-shrink: 0;
  z-index: 1;
`;
export const CzMap1Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 625.66px;
  height: 436.04px;
  object-fit: cover;
`;
export const LinesIcon = styled.img`
  position: absolute;
  top: 125.48px;
  left: 156px;
  width: 505.54px;
  height: 313.75px;
`;
export const Map1 = styled.div`
  position: relative;
  width: 661.47px;
  height: 436.04px;
  z-index: 2;
`;
export const Button1 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 294px;
  left: 19px;
  z-index: 3;
`;
export const Button2 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 131px;
  left: 19px;
  z-index: 4;
`;
export const Button3 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 141px;
  right: 15px;
  z-index: 5;
`;
export const Button4 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 294px;
  right: 15px;
  z-index: 6;
`;
export const Button5 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 473px;
  right: 0px;
  z-index: 7;
`;
export const Button6 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 474px;
  left: 19px;
  z-index: 8;
`;
export const Radio1 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 218px;
  left: 145px;
  z-index: 9;
`;
export const Radio2 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 178px;
  left: 304px;
  z-index: 10;
`;
export const Radio3 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 236px;
  left: 405px;
  z-index: 11;
`;
export const Radio4 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 336px;
  left: 424px;
  z-index: 12;
`;
export const Radio5 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 435px;
  left: 445px;
  z-index: 13;
`;
export const Radio6 = styled(FormControlLabel)`
  margin: 0 !important;
  position: absolute;
  top: 376px;
  left: 225px;
  z-index: 14;
`;
export const Mapgroup = styled.div`
  width: 704px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 20px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
    left: 185px;
}
`;
export const Usertypecardgroup2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
`;
export const ConfirmationscreengroupRoot = styled.div`
  position: relative;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: center;
  padding: 40px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 159px;
  text-align: left;
  font-size: 20px;
  font-family: Rubik;
  
`;