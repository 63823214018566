import { AES, enc } from 'crypto-js';
// This File For Js Functions Tha is Shared Across Your Entire Application.

export const Cookies = {
    getCookie: (name) => {
        const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return v ? v[2] : null;
    },

    setCookie: (name, value, days) => {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie =
            name + "=" + value + ";path=/;expires=" + d.toUTCString();
    },
    deleteCookie: (name) => {
        Cookies.setCookie(name, "", -1);
    },
};

export const updateObject = (oldObject, updatedObject) => {
    return {
        ...oldObject,
        ...updatedObject,
    };
};


export function retrieveData(data) {

    let bytes;

    try {
        bytes = AES.decrypt(data, "czenv");
        const decrypted = bytes.toString(enc.Utf8);
        //   console.log(decrypted);
        return decrypted;
    } catch (err) {
        //   console.log(err)
    }
}

export const getNumberFromCaseID = (str) => {
    const match = str.match(/(\d+)(?!.*\d)/);
    return match ? parseInt(match[0], 10) : 0;
};

export function isArrayOfStrings(arr) {
    // Check if arr is an array
    if (Array.isArray(arr)) {
        // Check if all elements in the array are strings
        return arr.every((item) => typeof item === 'string');
    }
    return false;
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}