
const initialState = {
  allowFetch: true, // Initialize as false
  loading: true,
  error: null,
  documentCaseData: null,
  documentList: null,
  documentFiles: {},
  documentContentFetching: false,
  documentContentFetchingError: null,
  createNewCaseLoading: false,
  generateDocumentLoading: false,
  generateDocumentDocumentID: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SPO_TOGGLE_ALLOW_FETCH':
      return { ...state, allowFetch: action.payload };
    case 'SPO_TOGGLE_LOADING':
      return { ...state, loading: action.payload };
    case 'SPO_FETCH_DATA_SUCCESS':
      return {
        ...state,
        documentCaseData: action.payload.documentCaseData,
        documentList: action.payload.documentList,
        documentFiles: action.payload.documentFiles,
        loading: false,
        error: null
      };
    case 'SPO_FETCH_DATA_ERROR':
      return {
        ...state,
        documentCaseData: null,
        documentList: null,
        documentFiles: {},
        loading: false,
        error: action.payload
      };
    case 'SPO_TOGGLE_DOCUMENT_CONTENT_FETCHING':
      return {
        ...state,
        documentContentFetching: action.payload,
      };
    case 'SPO_FETCH_DOCUMENT_CONTENT_DATA_SUCCESS':
      return {
        ...state,
        documentList: action.payload.documentList,
        documentFiles: action.payload.documentFiles,
        documentContentFetching: false,
        documentContentFetchingError: null
      };
    case 'SPO_FETCH_DOCUMENT_CONTENT_DATA_ERROR':
      return {
        ...state,
        documentFiles: {},
        documentContentFetching: false,
        documentContentFetchingError: action.payload
      };
    case 'SPO_TOGGLE_CREATE_NEW_CASE_LOADING':
      return {
        ...state,
        createNewCaseLoading: action.payload
      };
    case 'SPO_TOGGLE_GENERATE_DOCUMENT_LOADING':
      return {
        ...state,
        generateDocumentLoading: action.payload.loading,
        generateDocumentDocumentID: action.payload.documentID,
      };
    case 'SPO_UPDATE_DOCUMENT_CONTENT_DATA_SUCCESS':
      return {
        ...state,
        documentList: action.payload.documentList,
        documentFiles: action.payload.documentFiles,
      }
    default:
      return state;
  }
};

export default dataReducer;
