import styled, { css } from "styled-components";

export const DescriptionContent = styled.div`
    box-sizing: border-box;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    max-width: ${({ bgWidth }) => (bgWidth ? "990px" : "660px")};
    max-width: ${({ actionWidth }) => (actionWidth ? "875px" : "")};
    box-sizing: border-box;
`;

export const StyledList = styled.ul`
    overflow: ${({ overflow }) => (overflow ? "auto" : "")};
    margin-top: ${({ mZero }) => (mZero ? "0px" : "38px")};
    padding: ${({ actionPad }) => (actionPad ? "0 51px 16px 51px" : "0")};

    @media (max-width: 599.98px) {
        margin-top: ${({ mZero }) => (mZero ? "0px" : "16px")};
        padding: ${({ actionPad }) => (actionPad ? "0 5px 16px 5px" : "0")};
    }

    li {
        &:not(:first-of-type) {
            p {
                max-height: ${({ maxheight }) => (maxheight ? "52px" : "")};

                @media (max-width: 599.98px) {
                    max-height: ${({ maxheight }) => (maxheight ? "none" : "")};
                }
            }
        }
    }

    li:first-of-type {
        p {
            font-weight: 500;
            font-size: ${({ smsize }) => (smsize ? "14px" : "17px")};
        }
    }
`;

export const DescriptionTextHead = styled.p`
    background: red;
    padding: 14px 16px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
    width: ${({ smWidth }) => (smWidth ? " 154px" : "163px")};
    width: ${({ acSmWidth }) => (acSmWidth ? "133px" : "")};
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: ${({ bigfont }) => (bigfont ? 500 : 400)};
    font-size: ${({ bigfont }) => (bigfont ? "17px" : "14px")};
    align-self: stretch;
    @media (max-width: 599.98px) {
        min-width: 140px;
        width: ${({ acSmWidth }) => (acSmWidth ? " 82px" : "")};
        padding: ${({ acSmWidth }) => (acSmWidth ? "16px 0px 16px 8px" : "")};
        max-width: 140px;
    }

    ${({ $action }) =>
        $action &&
        css`
            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 120px;
                min-width: 120px;
            }
        `}
`;

export const DescriptionTextParagraph = styled.p`
    // max-width: ${({ mdWidth }) => (mdWidth ? "836px" : "497px")};
    // max-width: ${({ acMdWidth }) => (acMdWidth ? "436px" : "")};
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};
    & .MuiFormControl-root {
        flex-grow: 1;
    }
    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;

export const DescriptionTextP = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 306px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 599.98px) {
        padding: 16px 10px 16px 10px;
        flex: 0;
    }
`;

export const StyledInput = styled.input`
    outline: none;
    border: none;
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;
