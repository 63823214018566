import styled from "styled-components";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;

    margin-top: 64px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 0;
        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        right: 20px;
        bottom: 20px;

        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    }
`;

export const StyledInformationIcon = styled(ErrorOutlinedIcon)`
    color: #3a8d1c;
    margin-left: 10px;
    cursor: pointer;
    @media (max-width: 991.98px) {
        display: ${({ hideicon }) => (hideicon ? "none !important" : "")};
    }
`;

export const BoxContainer = styled.div`
    padding: ${({ value }) => (value ? "0 19px 95px" : "0 19px 22px")};

    @media (max-width: 599.98px) {
        padding-top: 0px;
        padding-bottom: 36px;
    }

    @media (max-width: 320px) {
        padding-left: 8px;
        padding-right: 8px;
    }
`;

export const ContentContainer = styled.div`
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};

    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    border-radius: 16px;
    font-family: "Rubik", sans-serif;
    overflow: hidden;

    .MuiPickerStaticWrapper-root {
        background-color: inherit;
        min-width: 260px;
        max-width: 310px;

        box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)" : "")};
        @media (max-width: 599.98px) {
            min-width: 257px;
            max-width: 257px;
        }
    }

    .MuiCalendarPicker-root {
        max-width: 310px;
        width: 100%;
        margin: 0;

        background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};

        border-radius: 4px;

        @media (max-width: 599.98px) {
            max-width: 257px;
        }
    }

    .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today,
    .MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),
    .MuiPickersDay-root {
        background-color: inherit;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
        border: none;

        @media (max-width: 599.98px) {
            width: 33px;
            height: 33px;
        }
    }

    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin,
    .css-1n2a3gk.Mui-selected {
        background: #007dff;
        color: #fff;

        @media (max-width: 599.98px) {
            width: 33px;
            height: 33px;
        }
    }

    .css-j7qwjs,
    .css-epd502 {
        max-width: 310px;

        @media (max-width: 599.98px) {
            max-width: 257px;
        }
    }

    .MuiTabs-flexContainer {
    }
`;

export const TabsContainer = styled(Tabs)`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};

    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
    }

    @media (max-width: 320px) {
        .MuiTabs-scroller {
            max-width: 272px;
            width: 100px;
        }
    }

    button {
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
        font-family: "Rubik", sans-serif;
        font-weight: 500;
        font-size: 15px;

        max-width: calc(100% / 2);
        width: calc(100% / 2);

        @media (max-width: 991.98px) {
            max-width: calc(100% / 2);
            width: calc(100% / 2);
        }

        @media (max-width: 599.98px) {
            max-width: max-content;
            width: max-content;
            font-size: 12px;
        }
    }

    button:first-of-type {
        color: ${({ error1 }) => (error1 ? "red" : "")};
    }

    button:nth-of-type(2) {
        color: ${({ error2 }) => (error2 ? "red" : "")};
    }

    button:last-of-type {
        color: ${({ error3 }) => (error3 ? "red" : "")};
    }

    button.Mui-selected {
        color: #3a8d1c;
    }

    span.MuiTabs-indicator {
        background-color: #3a8d1c;
    }
`;

export const StyledBox = styled(Box)`
    margin-bottom: 46.5px;

    .MuiTabs-flexContainer {
        @media screen and (max-width: 599.98px) {
            max-width: 100px;
        }
    }

    @media (max-width: 599.98px) {
        margin-bottom: 26.5px;
    }
`;

export const BtnsContainer = styled.div`
    box-sizing: border-box;
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    margin-top: ${({ value }) => (value ? "48px" : "119px")};
    font-family: "Rubik", sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 70px;

    @media (max-width: 991.98px) {
        margin-top: 35px;
        padding: 0 50px;
    }

    @media (max-width: 599.98px) {
        margin-top: 24px;
        padding: 0;
    }
`;

export const StyledText = styled.h3`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.5px;
    width: ${({ maxWidth }) => (maxWidth ? "80%" : "")};
    margin-bottom: 19px;

    @media (max-width: 599.98px) {
        font-size: 16px;
        width: ${({ maxWidth }) => (maxWidth ? "100%" : "")};
    }
`;

export const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
`;

export const StyledComplateContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;
