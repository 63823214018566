import E8Question from "../../../../components/E8Question/Question";
import { QuestionCard } from "./E8QuestionStepper.styled";

function E8QuestionsStepper(props) {
    const { questions, handleAnswer, onClick, qIndex, readOnly, domainControlMapper, ...restProps } = props;

    let renderedQuestions = questions.map((question, index) => {
        return <E8Question readOnly={readOnly} helpText={question?.helpText} onClick={onClick} key={question.qId + index} question={question} index={qIndex} handleAnswer={handleAnswer} ans1Index={question?.ans1Index} domainControlMapper={domainControlMapper} {...restProps} />;
    });

    return <QuestionCard>{renderedQuestions}</QuestionCard>;
}

export default E8QuestionsStepper;
