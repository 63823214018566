const mitigationDefaultColor = "yellow";
const mitHighlighColor = "#f5f505";
const riskNodeColor = "red";
const edgeHighlightColor = "white";
const edgeDefaultColor = "lightgrey";
const grey = "grey";
const red1 = "#fa0a0a";
const demainDefaultColor = "green";
const green1 = "#068f06";
const darkred = "maroon";
const linkColor = "#797979";

//error meesage
const apiCall_error = "Something went wrong. Please try again later.";
const mitigation_details_error = "No details available for selected mitigation.";
const domain_details_error = "No details available for selected domain.";
const risk_details_error = "No details available for selected risk.";

export {
    mitigationDefaultColor,
    mitHighlighColor,
    riskNodeColor,
    edgeHighlightColor,
    edgeDefaultColor,
    grey,
    red1,
    green1,
    demainDefaultColor,
    apiCall_error,
    mitigation_details_error,
    domain_details_error,
    risk_details_error,
    darkred,
    linkColor
}