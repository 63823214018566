import { useState } from "react";
import { validateInput } from "../validation";
import { FormControl, MenuItem, Select, InputLabel, TextField } from "@mui/material";
import { isArrayOfStrings } from "../../../../shared/utility";

const OptionQuestion = (props) => {
    const { isSub, sub, value, onSubChangeHandler, question, parentIndex, index, handleAnswer, readOnly } = props;

    // this is for dependent questions, only useful for isSub= true
    const [toggle, setToggle] = useState(!sub?.qAvailableAnswers.includes(value) && value);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    //

    function getOptionList(question) {
        let cards = [];
        try {
            cards.push(
                <MenuItem key={question.qId + question.qNum} value={""}>- Select -</MenuItem>
            )

            if (question.qAvailableAnswers) {
                if (isArrayOfStrings(question.qAvailableAnswers)) {
                    question.qAvailableAnswers.forEach((element, index) => {
                        cards.push(
                            <MenuItem key={index} value={element}>{element}</MenuItem>
                        )
                    })
                } else {
                    question.qAvailableAnswers.forEach((element, index) => {
                        cards.push(
                            <MenuItem key={index} value={element.value}>{element.label}</MenuItem>
                        )
                    })
                }
            }
        } catch (error) {
            console.error(error);
        }

        return cards;
    }

    if (isSub) {
        const onOptionChangeHandler = (e) => {
            if (e.target.value !== "Other") {
                setToggle(false);
                onSubChangeHandler(sub.qHeading, e.target.value);

            } else {
                onSubChangeHandler(sub.qHeading, "");
                setToggle(true);
            }
        }

        const onTextChangeHandler = (e) => {
            if (validateInput(e.target.value)) {
                setIsError(false);
                setErrorMessage("");
                onSubChangeHandler(sub.qHeading, e.target.value);
            } else {
                setIsError(true);
                setErrorMessage("Invalid Input!");
                onSubChangeHandler(sub.qHeading, "");
            }
        }


        return (
            <>
                <FormControl style={{ marginTop: "20px" }}>
                    <InputLabel shrink id={sub.qNum + "-label"} required={sub.required}>
                        {`${sub.qNum}. ${sub.qHeading}`}
                    </InputLabel>
                    <Select
                        notched
                        required={sub.required}
                        label={`${sub.qNum}. ${sub.qHeading}`}
                        value={toggle ? "Other" : value}
                        onChange={onOptionChangeHandler}
                        disabled={readOnly}
                    >
                        {sub.qAvailableAnswers.map((ans, index) => (
                            <MenuItem key={index} value={ans}>{ans}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {toggle && (
                    <TextField
                        style={{ marginTop: "20px" }}
                        label="Please specify"
                        variant="outlined"
                        error={isError}
                        disabled={readOnly}
                        helperText={errorMessage}
                        onChange={onTextChangeHandler}
                        defaultValue={value}
                        inputProps={{ maxLength: 255 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            </>
        );
    } else {
        const onSelectChangeHandler = (e) => {
            handleAnswer(e.target.value, parentIndex, index);
        }

        return (
            <FormControl>
                <InputLabel shrink id={question.qNum + "-label"} required={question.required}>
                    {`${question.qNum}. ${question.qHeading}`}
                </InputLabel>
                <Select
                    notched
                    required={question.required}
                    label={`${question.qNum}. ${question.qHeading}`}
                    value={question?.qAnswer}
                    onChange={onSelectChangeHandler}
                    disabled={readOnly}
                >
                    {getOptionList(question)}
                    {/* {question.qAvailableAnswers.map((ans, index) => (
                        <MenuItem key={index} value={ans}>{ans}</MenuItem>
                    ))} */}
                </Select>
            </FormControl>
        );
    }

}
export default OptionQuestion;