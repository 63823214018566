const initialState = {
  domains: null,
  domainControlMapper: null,
  domainLoading: false,
  domainError: null,
  allowDomainFetch: false, // Initialize as false,
  containerID: null,
  currentOrganisation: localStorage.getItem('current-organisation') || '',
  organisationList: [],
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MISC_DOMAIN_ALLOW_FETCH':
      return { ...state, allowDomainFetch: action.payload };
    case 'TOGGLE_MISC_DOMAIN_LOADING':
      return { ...state, loading: action.payload };
    case 'FETCH_MISC_DOMAIN_DATA_SUCCESS':
      return { ...state, domains: action.payload.data, domainControlMapper: action.payload.domainControlMapper, domainLoading: false, domainError: null };
    case 'FETCH_MISC_DOMAIN_DATA_ERROR':
      return { ...state, domains: [], domainControlMapper: {}, domainLoading: false, domainError: action.payload };
    case 'SET_MISC_ORGANISATION_LIST':
      return { ...state, organisationList: action.payload };
    case 'SET_MISC_CURRENT_ORGANISATION':
      return { ...state, currentOrganisation: action.payload.currentOrganisation, containerID: action.payload.containerID };
    default:
      return state;
  }
};

export default miscellaneousReducer;
