import styled from "@emotion/styled";

export const ContainerWrapper = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;