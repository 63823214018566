import { Box, ButtonBase, Chip, Grid } from "@mui/material";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, Wrapper } from "./E8Controls.styled";
import { useKeycloak } from "@react-keycloak/web";
import { CardHead, ProgressBlock, ProgressCard, E8ProgressCard } from "./E8.styled";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function E8Questions(props) {

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [subQuestions, setSubQuestions] = useState([]);
    const [answerList, setAnswerList] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState("");
    const [loading, setLoading] = useState(false);
    const e8NavHandler = () => history.push("/it-security/essential-eight/controls");
    const e8NavHandler2 = () => history.push("/it-security/essential-eight/controls/questions/sub");
    const [questionCaseID, setQuestionCaseID] = useState(null);
    const [controlName, setControlName] = useState(null);
    const [isReview, setIsReview] = useState(null);
    const [isControl, setIsControl] = useState("true");
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `ACSC Essential Eight - ${controlName}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [controlName, dispatch])


    useEffect(() => {
        setLoading(true);
        const processObject = window.sessionStorage.getItem("E8_ACTIVE_SUB_PROCESS");
        const controlObject = window.sessionStorage.getItem("E8_ACTIVE_CONTROL_NAME");
        const _isControl = window.sessionStorage.getItem("IS_CONTROL");
        setIsControl(_isControl);

        if (controlObject !== null) setControlName(JSON.parse(controlObject));
        if (processObject !== null) {
            setQuestionCaseID(JSON.parse(processObject)['correlation-key']);
            setProcessInstanceId(JSON.parse(processObject)["process-instance-id"]);
            //getUserTask(processInstanceId);   
        }

    }, []);

    useEffect(() => {
        if (questionCaseID === null) return;
        getQuestionsMileStones();

    }, [questionCaseID]);

    useEffect(() => {
        if (answerList.length === 0) return;

        const isFound = answerList.find(ans => ans.subAnswer1 === null);
        if (isFound === undefined) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }

        if (isFound === undefined && isControl !== "true") {
            endMileStone();
        }
    }, [answerList])

    function handleComplete() {
        e8NavHandler();
    }

    function handleSub() {
        e8NavHandler2();
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    async function getUserTask(processInstanceID) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstanceID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                // console.log(response.data);
                if (response.status == 200) {
                    const taskArray = response.data["active-user-tasks"]["task-summary"];
                    const task = taskArray.find(x => x["task-name"] === "Review");
                    const taskID = task["task-id"];
                    setTaskInstanceId(taskID);

                    getTaskDetail(taskID);

                    if (task["task-status"] !== "InProgress") {
                        var data = JSON.stringify(true);

                        var config2 = {
                            method: 'put',
                            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/states/started`,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${keycloak.token}`
                            },
                            data: data
                        };

                        fetchInstance.apiRequest(config2)
                            .then(function (response) {
                                //started user task
                            })
                            .catch(function (error) {
                            });
                    }
                }
            })
            .catch(function (error) {
            });
    }

    async function getTaskDetail(taskID) {

        let _config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/contents/output`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        }
        fetchInstance.apiRequest(_config).then((response) => { })

        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/contents/input`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    var taskQuestions = [];
                    questions.forEach((element, index) => {
                        const questionObj = JSON.parse(response.data[`question${index + 1}`]);
                        const answerArr = response.data.answers !== undefined ? JSON.parse(response.data.answers) : null;
                        var subQuestions = [];
                        questionObj.subQuestions.forEach(element => {
                            const subQuestion = {
                                qID: `${questionObj.id}.${element.id}`,
                                qNum: `${questionObj.id}.${element.id}`,
                                qHeading: element.title,
                                qAnswer: answerArr !== null ? answerArr[questionObj.id - 1][`subAnswer${element.id}`] : null,
                                type: element.type
                            };
                            subQuestions.push(subQuestion);
                        });

                        const question = {
                            title: questionObj.mainQuestion,
                            qId: questionObj.id,
                            qNum: questionObj.id,
                            subQuestions: subQuestions,
                        };
                        taskQuestions.push(question);
                    });
                    setSubQuestions(taskQuestions);
                }
            })
            .catch(function (error) {
            });
    }

    let excludeList = ["End", "init data", "Milestone", "Review", "inti data?"]
    //get all question milestones
    async function getQuestionsMileStones() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/milestones?achievedOnly=false&page=0`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    var questionList = [];
                    response.data["milestones"].forEach((element) => {
                        var question = {};
                        question.title = element["milestone-name"];


                        if (!excludeList.find((e) => e === question.title)) {
                            question.id = question.title.split('.')[0];
                            question.status = "incomplete";
                            questionList.push(question)
                        }
                    });

                    //TODO: sort controList by controlNumber
                    const sortedQList = [...questionList].sort((a, b) => {
                        return a.id > b.id ? 1 : -1
                    })

                    //setQuestions(questionList);
                    getAnswerList(sortedQList);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }
    //get all answers to questions
    async function getAnswerList(questionList) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/caseFile/controlData`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };
        //TO DOs: Problem is the questions are returned randomly but the answers are mapped to the question.
        // need to add ID in backend
        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const dataObjArr = JSON.parse(response.data);
                    setAnswerList(dataObjArr);
                    dataObjArr.forEach(answer => {
                        var index = questionList.findIndex(question => question.id == (answer.qNum));
                        let newQuestions = [...questionList];
                        newQuestions[index].status = answer.subAnswer1 === null ? "incomplete" : "completed";
                        newQuestions[index].type = answer.subAnswer1 === null ? "warning" : "success";
                        checkNodes(newQuestions);

                    });
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    async function checkNodes(questions) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/nodes/instances`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const nodeList = response.data["node-instance"];
                    const reviewNode = nodeList.find((node) => node["node-name"] === "Review");
                    if (reviewNode) {
                        const newQuestions = [];
                        questions.forEach(question => {
                            question.disabled = true;
                            newQuestions.push(question);
                        });

                        setQuestions(newQuestions);
                        setIsReview(true);
                        setProcessInstanceId(reviewNode["process-instance-id"]);
                    } else {
                        setQuestions(questions)
                        setIsReview(false);
                    }
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        if (isReview === null) return;
        if (processInstanceId === null) return;
        getUserTask(processInstanceId);
    }, [processInstanceId]);


    async function getAnswers() {
        getActiveNode();
    }

    async function endMileStone() {
        setLoading(true);
        const answerNotCompleted = answerList.find(answer => answer.subAnswer1 === null);
        if (answerNotCompleted) {
            toast.warning("Please answer all the questions!");
            return;
        }
        var data = JSON.stringify(true);

        var config = {
            method: 'post',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/caseFile/end`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: data
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status == 201 || response.status == 200) {
                    setLoading(false);
                    e8NavHandler();
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    async function triggerMileStone(question) {
        setLoading(true);
        if (question.disabled) {
            toast.warning("Waiting for review!");
            return;
        };
        var config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/tasks/${question.title}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify({})
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status == 201 || response.status == 200) {
                    setLoading(false);
                    window.sessionStorage.setItem('E8_ACTIVE_QUESTION', JSON.stringify(question));
                    e8NavHandler2();
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    async function startReview() {

        var config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/tasks/Review`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify({})
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status == 201 || response.status == 200) {
                    // startMileStone(controlName.toLowerCase());
                    setIsReview(true);
                    getQuestionsMileStones();
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    async function getActiveNode() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${questionCaseID}/nodes/instances?completed=false&page=0&pageSize=10`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const nodeList = response.data["node-instance"];
                    const reviewNode = nodeList.find((node) => node["node-name"] === "Review");
                    if (reviewNode) {
                        toast.warning("Your request to review was already submitted!");
                    } else {
                        startReview();
                        // const answerNotCompleted = answerList.find(answer => answer.subAnswer1 === null);
                        // if (!answerNotCompleted) {
                        //     startReview();
                        // } else {
                        //     toast.warning("Please answer all the questions!");
                        // }
                    }
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    async function submitReview() {
        setLoading(true);
        var ansObject = {
            "review": "ok"
        };

        var config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify(ansObject)
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                setIsReview(false);
                getQuestionsMileStones();
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    }

    function contentInProgress() {
        return (<>
            <Grid item xs={12} marginBottom="15px">
                {/* <Button disabled={isReview} $success onClick={(e) => endMileStone()}>Mark as completed</Button> */}
                {/* <Button variant="outlined" onClick={(e) => getAnswers()}>Submit For Review</Button> */}
            </Grid>
            {
                isControl !== "true" ? isCompleted ? "" : getCardList() : getCardList()
            }
        </>);
    }

    function getCardList() {
        let cards = [];
        questions.forEach((element, index) => {
            cards.push(
                <Grid item lg={12} md={12} sm={12} xs={12} key={index + element.id} onClick={(e) => triggerMileStone(element)}>
                    <ButtonBase style={{ width: "100%", height: "100%" }}>
                        <E8ProgressCard>
                            <ProgressBlock>
                                <CardHead style={{ textAlign: "left" }}>
                                    {element.title}?
                                </CardHead>
                            </ProgressBlock>
                            <Chip label={element.status} color={element.type} size="large" style={{ marginTop: "30px", float: "right" }} variant="outlined" />
                        </E8ProgressCard>
                    </ButtonBase>
                </Grid>
            )
        })
        return cards;
    }

    const handleReasonsChange = useCallback((value, id) => {

    }, [])

    const handleOptionChange = useCallback((value, id) => {

    }, [])

    function getReviewCardList() {
        let cards = [];

        subQuestions.map((item, index) => {
            cards.push(
                // <ReviewItem
                //     key={index}
                //     index={index + 1}
                //     number={index + 1}
                //     heading={item.title}
                //     subQuestions={item.subQuestions}
                //     reason={""}
                //     helpText={""}
                //     handleReasonsChange={handleReasonsChange}
                //     isCompleted={false}
                //     isAccepted={item.isAccepted}
                //     handleOptionChange={handleOptionChange}
                //     recommendations={""}
                // />
            )
        })
        return cards;
    }

    function contentInReview() {
        return (
            <>
                <Fragment>
                    <Grid container spacing={{ xs: 1, md: 2 }} style={{ padding: "15px" }}>
                        <Grid item xs={12}>
                            <ProgressCard>
                                <ProgressBlock>
                                    {getReviewCardList()}
                                </ProgressBlock>
                            </ProgressCard>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: "100%", position: "relative" }}>
                                <ActionsWrapper hasOne>
                                    <Button $success onClick={(e) => submitReview()}>
                                        submit
                                    </Button>
                                </ActionsWrapper>
                            </Box>
                        </Grid>
                    </Grid>
                    <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                    <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
                </Fragment>
            </>
        );
    }

    if (!loading) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    {
                        isReview ? contentInReview() : contentInProgress()
                    }
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>ACSC Essential Eight - {controlName || ''} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    ACSC Essential Eight - {controlName}
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default E8Questions;
