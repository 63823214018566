import { useState, useEffect, useCallback } from "react";

import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import { Button, CustomLoadingButton, VisuallyHiddenInput } from "../../../../../components/UI/Button/Button.styled";
import E8QuestionsStepper from "../../../components/E8QuestionStepper/E8QuestionStepper";
import ImageZoomInOut from "../../ThreatsEllicitation/components/ImageZoomInOut";
import { validInput } from "../../../../../components/E8Question/SubQuestion/SubQuestion";
import ControlSelectionDialog from "./ControlSelectionDialog";
import RecommendCounterMeasureForm from "./RecommendCounterMeasureForm";
import ThreatTable from "./ThreatTable";
import { useSelector } from "react-redux";
import FileSelection from "./FileSelection";
import CommentBox from "../../../../../components/CommentBox/CommentBox";

const ThreatFormDialog = (props) => {

    const { open, toggle, activeIndex, updatingCase, domainControlMapper, caseData, caseID } = props;
    const { data } = useSelector((state) => state.threats);

    // ** States
    const [fullScreen, setFullScreen] = useState(false);
    const [threats, setThreats] = useState([]);
    const [imageURL, setImageURL] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [questions, setQuestions] = useState(props.questions);
    const [showRecommendCounterMeasureForm, setShowRecommendCounterMeasureForm] = useState(false);
    const [showCompensationControlsForm, setShowCompensationControlsForm] = useState(false);
    const [errors, setErrors] = useState({}); // To manage error messages during form submission
    const [openControlSelectionDialog, setOpenControlSelectionDialog] = useState(false);
    const [controlSelectionDialogMetadata, setControlSelectionDialogMetadata] = useState(null);

    // ** flag to check if the form data is filled
    let isFormFilled = questions?.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        if (subQuestion.required) {
            if (subQuestion.key === "counterMeasureMapping" || subQuestion.key === "compensatingControls") {
                let isValid = (subQuestion.qAnswer || []).length > 0
                    && (subQuestion.qAnswer || []).every((i) => i.title !== '' && i.ismsMapping !== '' && i.status !== '');
                if (isValid && (subQuestion.qAnswer || []).some((i) => i.status === 'Completed')) {
                    isValid = (subQuestion.qAnswer || []).filter((i) => i.status === 'Completed').every((i) => (i.implementationEvidence || '') !== '')
                }
                return isValid
            } else {
                return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
            }
        } else {
            return true;
        }
    }) : false;

    const toggleRecommendCounterMeasureFormVisibility = (e) => {
        e?.preventDefault();
        setShowRecommendCounterMeasureForm((prev) => !prev)
    }

    const toggleCompensationControlsFormVisibility = (e) => {
        e?.preventDefault();
        setShowCompensationControlsForm((prev) => !prev)
    }

    const toggleControlSelectionDialog = (e, metadata = null) => {
        setOpenControlSelectionDialog((prev) => !prev);
        setControlSelectionDialogMetadata(metadata);
    }

    // ** Reset state on dialog open
    useEffect(() => {
        if (open) {
            setImageURL(null);
            setShowRecommendCounterMeasureForm(false);
            setShowCompensationControlsForm(false);
        }
    }, [open])

    // ** Update data for the threats table
    useEffect(() => {
        let newThreats = [];
        let foundQuestions = questions && questions[activeIndex] && questions[activeIndex].subQuestions.find((q) => q.key === "solutionBuildingBlockID");
        if (foundQuestions && foundQuestions.qAnswer) {
            newThreats = (data || []).filter((t) => t['solutionBuildingBlockID'] === foundQuestions.qAnswer) || [];
        }
        setThreats(newThreats);
    }, [activeIndex, data, questions])

    const openControlSelectionDialogForQuestion = useCallback((question) => {
        let q = questions[activeIndex].subQuestions.find((subQuestion) => subQuestion.key === question.key);
        if (q) {
            toggleControlSelectionDialog(null, { type: "question", key: q.key, singleSelection: false, selectedControls: q.qAnswer || [], title: "Current Controls" });
        }
    }, [activeIndex, questions]);

    // ** Update questions data to local state
    useEffect(() => {
        let newQuestions = props.questions.map((question) => {
            return {
                ...question,
                subQuestions: question.subQuestions.map((subQuestion) => {
                    if (subQuestion.key === "counterMeasureMapping") {
                        return {
                            ...subQuestion,
                        }
                    } else if (subQuestion.type === "controlMapping") {
                        return {
                            ...subQuestion,
                            componentProps: {
                                freeSolo: !!subQuestion.readOnly,
                            }
                        }
                    } else {
                        return { ...subQuestion }
                    }
                })
            }
        });
        setQuestions(newQuestions);
    }, [props.questions])

    const handleAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
    }, [questions]);

    const addNewRecommendCounterMeasure = (index, qKey) => {
        if (index > -1) {
            setQuestions(
                (prev) => {
                    return prev.map((question, i) => {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map((subQuestion, j) => {
                                if (subQuestion.key === qKey) {
                                    let newRecommendedCounterMeasures = [];
                                    (subQuestion.qAnswer || []).forEach((prevMeasure, i) => {
                                        if (i === index) {
                                            newRecommendedCounterMeasures.push({ id: new Date().getTime(), condition: "And", title: "", ismsMapping: "", status: "" });
                                        }
                                        newRecommendedCounterMeasures.push({ ...prevMeasure });
                                    })
                                    return {
                                        ...subQuestion,
                                        qAnswer: newRecommendedCounterMeasures,
                                    }
                                } else {
                                    return { ...subQuestion }
                                }
                            })
                        }
                    })
                }
            )
        } else {
            setQuestions(
                (prev) => {
                    return prev.map((question) => {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map((subQuestion) => {
                                if (subQuestion.key === qKey) {
                                    let newQAnswer = (subQuestion.qAnswer || []);
                                    newQAnswer.push({ id: new Date().getTime(), condition: "And", title: "", ismsMapping: "", status: "" });
                                    return {
                                        ...subQuestion,
                                        qAnswer: newQAnswer,
                                    }
                                } else {
                                    return { ...subQuestion }
                                }
                            })
                        }
                    })
                }
            )
        }
    }

    const removeRecommendCounterMeasure = (remIndex, qKey) => {
        setQuestions(
            (prev) => prev.map((question) => {
                return {
                    ...question,
                    subQuestions: question.subQuestions.map((subQuestion) => {
                        if (subQuestion.key === qKey) {
                            let newQAnswer = (subQuestion.qAnswer || []).filter((_, index) => remIndex !== index);
                            return {
                                ...subQuestion,
                                qAnswer: newQAnswer,
                            }
                        } else {
                            return { ...subQuestion }
                        }
                    })
                }
            })
        )
    }

    const handleChangeCondition = (condition, index, qKey) => {
        setQuestions(
            (prev) => {
                return prev.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === qKey) {
                                return {
                                    ...subQuestion,
                                    qAnswer: subQuestion.qAnswer.map(
                                        (item, i) => ({
                                            ...item,
                                            ...(index === i && { condition: condition })
                                        })
                                    )
                                }
                            } else {
                                return { ...subQuestion }
                            }
                        })
                    }
                })
            }
        )
    }

    const handleChangeCounterMeasure = (e, index, qKey) => {
        if (!validInput.test(e.target.value)) {
            setErrors((prev) => {
                let newErrors = { ...prev };
                if (typeof newErrors[qKey] === 'undefined') {
                    newErrors[qKey] = {};
                }
                if (typeof newErrors[qKey][index] === 'undefined') {
                    newErrors[qKey][index] = {};
                }
                newErrors[qKey][index]['title'] = "Invalid input";
                return { ...newErrors }
            });
        } else {
            setErrors((prev) => {
                let newErrors = { ...prev };
                if (newErrors[qKey] && newErrors[qKey][index] && newErrors[qKey][index]['title']) {
                    delete newErrors[qKey][index]['title'];
                }
                return { ...newErrors }
            });
        }
        setQuestions(
            (prev) => {
                return prev.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === qKey) {
                                return {
                                    ...subQuestion,
                                    qAnswer: subQuestion.qAnswer.map(
                                        (item, i) => ({
                                            ...item,
                                            ...(index === i && { title: e.target.value })
                                        })
                                    )
                                }
                            } else {
                                return { ...subQuestion }
                            }
                        })
                    }
                })
            }
        )
    }

    const handleChangeCurrentStatus = (status, index, qKey) => {
        setQuestions(
            (prev) => {
                return prev.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === qKey) {
                                return {
                                    ...subQuestion,
                                    qAnswer: subQuestion.qAnswer.map(
                                        (item, i) => ({
                                            ...item,
                                            ...(index === i && { status: status })
                                        })
                                    )
                                }
                            } else {
                                return { ...subQuestion }
                            }
                        })
                    }
                })
            }
        )
    }

    const handleControlSelectionDialogSubmit = useCallback((selectedControls) => {
        if (controlSelectionDialogMetadata?.singleSelection) {
            if (controlSelectionDialogMetadata?.type === "rcm" || controlSelectionDialogMetadata?.type === "control") {
                setQuestions(
                    (prev) => {
                        let newQuestions = prev.map((question) => {
                            return {
                                ...question,
                                subQuestions: question.subQuestions.map((subQuestion) => {
                                    if (subQuestion.key === controlSelectionDialogMetadata?.key) {
                                        return {
                                            ...subQuestion,
                                            qAnswer: subQuestion.qAnswer.map(
                                                (item, i) => ({
                                                    ...item,
                                                    ...(controlSelectionDialogMetadata?.index === i && { ismsMapping: selectedControls })
                                                })
                                            )
                                        }
                                    } else {
                                        return { ...subQuestion }
                                    }
                                })
                            }
                        })

                        // ** Auto-populate ismsMapping when counterMeasureMapping is updated
                        if (controlSelectionDialogMetadata?.key === "counterMeasureMapping") {
                            newQuestions.forEach((question, qIndex) => {
                                let rcmQuestionIndex = question.subQuestions.findIndex((q) => q.key === controlSelectionDialogMetadata?.key);
                                let ismsMappingQuestionIndex = question.subQuestions.findIndex((q) => q.key === "ismsMapping");
                                if (rcmQuestionIndex > -1 && ismsMappingQuestionIndex > -1) {
                                    let ismsMapping = (question.subQuestions[rcmQuestionIndex].qAnswer || []).map((q) => q.ismsMapping).filter((q) => q?.length > 0);
                                    ismsMapping = [...new Set(ismsMapping)];
                                    newQuestions[qIndex].subQuestions[ismsMappingQuestionIndex].qAnswer = ismsMapping;
                                }
                            })
                        }

                        return newQuestions;
                    }
                )
            }
        } else {
            if (controlSelectionDialogMetadata?.type === "question" && !!controlSelectionDialogMetadata?.key) {
                setQuestions(
                    (prev) => {
                        return prev.map((question, i) => {
                            if (activeIndex === i) {
                                return {
                                    ...question,
                                    subQuestions: question.subQuestions.map((subQuestion) => {
                                        if (subQuestion.key === controlSelectionDialogMetadata?.key) {
                                            return {
                                                ...subQuestion,
                                                qAnswer: selectedControls
                                            }
                                        } else {
                                            return { ...subQuestion }
                                        }
                                    })
                                }
                            } else {
                                return { ...question }
                            }
                        })
                    }
                )
            }
        }
        toggleControlSelectionDialog();
    }, [controlSelectionDialogMetadata, activeIndex])

    const handleChangeImplementationEvidence = (e, index, qKey) => {
        if (!validInput.test(e.target.value)) {
            setErrors((prev) => {
                let newErrors = { ...prev };
                if (typeof newErrors[qKey] === 'undefined') {
                    newErrors[qKey] = {};
                }
                if (typeof newErrors[qKey][index] === 'undefined') {
                    newErrors[qKey][index] = {};
                }
                newErrors[qKey][index]['implementationEvidence'] = "Invalid input";
                return { ...newErrors }
            });
        } else {
            setErrors((prev) => {
                let newErrors = { ...prev };
                if (newErrors[qKey] && newErrors[qKey][index] && newErrors[qKey][index]['implementationEvidence']) {
                    delete newErrors[qKey][index]['implementationEvidence'];
                }
                return { ...newErrors }
            });
        }
        setQuestions(
            (prev) => {
                return prev.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === qKey) {
                                return {
                                    ...subQuestion,
                                    qAnswer: subQuestion.qAnswer.map(
                                        (item, i) => ({
                                            ...item,
                                            ...(index === i && { implementationEvidence: e.target.value })
                                        })
                                    )
                                }
                            } else {
                                return { ...subQuestion }
                            }
                        })
                    }
                })
            }
        )
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={toggle}
                fullWidth
                maxWidth="md"
                scroll="paper"
                fullScreen={fullScreen}
            >
                <DialogTitle>Threat</DialogTitle>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <IconButton
                        title="toggle-fullscreen"
                        aria-label="toggle-fullscreen"
                        onClick={() => setFullScreen((prev) => !prev)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                    <IconButton
                        title="close"
                        aria-label="close"
                        onClick={toggle}
                        sx={{

                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent dividers>
                    <Grid container sx={{ height: '100%' }} spacing={4}>
                        <Grid item xs={12} md={7}>
                            <Collapse in={showRecommendCounterMeasureForm}>
                                <Box
                                    sx={{
                                        padding: "24px",
                                        border: "1px solid rgba(255, 255, 255, 0.12)",
                                        borderRadius: "8px",
                                        marginBottom: "24px"
                                    }}
                                >
                                    <RecommendCounterMeasureForm
                                        title="Recommended Counter Measure"
                                        key={"counterMeasureMapping"}
                                        qKey={"counterMeasureMapping"}
                                        errors={errors && errors['counterMeasureMapping']}
                                        recommendedCounterMeasures={questions[activeIndex]?.subQuestions?.find((i) => i.key === "counterMeasureMapping")?.qAnswer || []}
                                        addNewRecommendCounterMeasure={addNewRecommendCounterMeasure}
                                        toggleControlSelectionDialog={toggleControlSelectionDialog}
                                        removeRecommendCounterMeasure={removeRecommendCounterMeasure}
                                        handleChangeCondition={handleChangeCondition}
                                        handleChangeCounterMeasure={handleChangeCounterMeasure}
                                        handleChangeCurrentStatus={handleChangeCurrentStatus}
                                        handleChangeImplementationEvidence={handleChangeImplementationEvidence}
                                        domainControlMapper={domainControlMapper}
                                    />
                                </Box>
                            </Collapse>
                            <Collapse in={showCompensationControlsForm}>
                                <Box
                                    sx={{
                                        padding: "24px",
                                        border: "1px solid rgba(255, 255, 255, 0.12)",
                                        borderRadius: "8px",
                                        marginBottom: "24px"
                                    }}
                                >
                                    <RecommendCounterMeasureForm
                                        title="Compensating Controls"
                                        key={"compensatingControls"}
                                        qKey={"compensatingControls"}
                                        errors={errors && errors['compensatingControls']}
                                        recommendedCounterMeasures={questions[activeIndex]?.subQuestions?.find((i) => i.key === "compensatingControls")?.qAnswer || []}
                                        addNewRecommendCounterMeasure={addNewRecommendCounterMeasure}
                                        toggleControlSelectionDialog={toggleControlSelectionDialog}
                                        removeRecommendCounterMeasure={removeRecommendCounterMeasure}
                                        handleChangeCondition={handleChangeCondition}
                                        handleChangeCounterMeasure={handleChangeCounterMeasure}
                                        handleChangeCurrentStatus={handleChangeCurrentStatus}
                                        handleChangeImplementationEvidence={handleChangeImplementationEvidence}
                                        domainControlMapper={domainControlMapper}
                                    />
                                </Box>
                            </Collapse>
                            <Box sx={{ marginBottom: "24px" }}>
                                <FileSelection
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                    open={open}
                                    caseData={caseData}
                                    selectedTRA={props.selectedTRA}
                                    onChangeImage={({ doc, imgSrc }) => {
                                        setImageURL(imgSrc);
                                        setSelectedDocument(doc);
                                    }}
                                />
                                {/* {
                                    imageURL ? <Box>
                                        <Box
                                            sx={{
                                                background: "rgb(31, 33, 40)",
                                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
                                                borderRadius: "8px",
                                                marginBottom: "24px",
                                            }}
                                        >
                                            <ImageZoomInOut
                                                // image={URL.createObjectURL(imageURL)}
                                                image={imageURL}
                                                imageName={selectedDocument?.name || ''}
                                            />
                                        </Box>
                                        <Button
                                            $success
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            onClick={(e) => {
                                                setImageURL(null);
                                                setSelectedDocument(null);
                                            }}
                                        >
                                            Change Image
                                        </Button>
                                    </Box> : 
                                } */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} sx={{ height: '100%' }}>
                            <Box sx={{ height: '100%', overflowY: 'auto', }}>
                                {
                                    open && <>
                                        <E8QuestionsStepper
                                            questions={[...questions]}
                                            qIndex={activeIndex}
                                            handleAnswer={handleAnswer}
                                            domainControlMapper={domainControlMapper}
                                            actionFn={{
                                                counterMeasureMapping: {
                                                    toggleForm: (question) => {
                                                        if (question.key === "counterMeasureMapping") {
                                                            toggleRecommendCounterMeasureFormVisibility();
                                                        } else if (question.key === "compensatingControls") {
                                                            toggleCompensationControlsFormVisibility();
                                                        }
                                                    }
                                                },
                                                controlMapping: {
                                                    'popupIcon-onClick': (q) => openControlSelectionDialogForQuestion(q)
                                                }
                                            }}
                                        />
                                    </>
                                }
                            </Box>
                        </Grid>
                        {
                            open && <Grid item xs={12}>
                                <CommentBox caseID={caseID} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    padding: "24px",
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    borderRadius: "8px",
                                    marginBottom: "24px",
                                }}
                            >
                                <ThreatTable
                                    readOnly
                                    selectedTRA={props.selectedTRA}
                                    tableData={threats}
                                    domainControlMapper={domainControlMapper}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: "20px 24px" }}>
                    {
                        updatingCase ? <CustomLoadingButton
                            loading={true}
                            success
                            variant='contained'
                        >
                            Submit
                        </CustomLoadingButton> : <Button
                            disabled={!isFormFilled}
                            $success
                            onClick={(e) => props.submitData("detail", questions, { doc: selectedDocument, tra: props.selectedTRA })}
                        >
                            Submit
                        </Button>
                    }
                </DialogActions>
            </Dialog>

            <ControlSelectionDialog
                title={controlSelectionDialogMetadata?.title}
                domainData={props.domainData}
                open={openControlSelectionDialog}
                toggle={toggleControlSelectionDialog}
                selectedControls={controlSelectionDialogMetadata?.selectedControls}
                singleSelection={!!controlSelectionDialogMetadata?.singleSelection}
                onSubmit={handleControlSelectionDialogSubmit}
            />
        </>
    )

}

export default ThreatFormDialog;