import styled from "styled-components";

export const PolicyCard = styled.div`
    padding: 30px 16px 59px;
    background: ${({ theme }) => theme.palette.mode === "light" ? "#fff" : "#1F2128"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: left;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 30px 16px 33px;
        margin-bottom: 8px;
    }
`;