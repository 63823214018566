import { getCase, getDMN } from "../pages/Dashboard/nested/TRA/TRAApi";
import { AssessMaturity, CONTAINER_ID } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowDomainFetch = (allow) => {
  return { type: 'TOGGLE_MISC_DOMAIN_ALLOW_FETCH', payload: allow };
};

export const toggleDomainLoading = (loading) => {
  return { type: 'TOGGLE_MISC_DOMAIN_LOADING', payload: loading };
};

export const fetchDomainData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowDomainFetch } = getState().miscellaneous; // Get the allowFetch value from the state

    // Enable loading
    dispatch(toggleDomainLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-misc-domain');
      const cachedDomainMapperData = await getCachedData(keycloak.token, 'cached-misc-domain-mapper');

      if (cachedData && allowDomainFetch === false) {
        let domainControlMapper = cachedDomainMapperData || {};
        dispatch({ type: 'FETCH_MISC_DOMAIN_DATA_SUCCESS', payload: { data: cachedData, domainControlMapper: domainControlMapper } });
      } else {

        const domainsObj = await getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains');
        let domains = domainsObj.success ? domainsObj.data["dmnContext"]["domains"][0] : [];

        let domainControlMapper = {};
        let maturityScores = {};

        if (domains?.length) {

          try {
            const maturityLastCaseResponse = await getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'closed', 1);
            if (maturityLastCaseResponse?.success && maturityLastCaseResponse?.data?.instances?.length) {
              maturityScores = maturityLastCaseResponse.data.instances[0]['case-file']['case-data']['maturityScores'] || {};
            }
          } catch (e) {

          }

          domains.forEach((domain) => {
            domain.sections.forEach((section) => {
              section.controls.forEach((control) => {
                let key = control.key.replace(new RegExp("-", "g"), '');
                domainControlMapper[key] = {
                  ...control,
                  sectionHeading: section.sectionHeading,
                  sectionID: section.sectionID,
                  domainHeading: domain.domainHeading,
                  domainID: domain.domainID,
                  score: maturityScores[`score${key.replace("m", "")}`] ?? 0,
                };
              })
            })
          })
        }

        dispatch({ type: 'FETCH_MISC_DOMAIN_DATA_SUCCESS', payload: { data: domains, domainControlMapper: domainControlMapper }, });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-misc-domain', domains);
        cacheData(keycloak.token, 'cached-misc-domain-mapper', domainControlMapper);
        dispatch(toggleAllowDomainFetch(false));
      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_MISC_DOMAIN_DATA_ERROR', payload: error.message });
    }
  };
};

export const setOrganisationList = (keycloak, data) => {
  return async (dispatch, getState) => {
    const { currentOrganisation } = getState().miscellaneous;
    if (data?.length > 0) {

      if (!currentOrganisation) {
        dispatch(setCurrentOrganisation(keycloak, data[0]))
      }

      dispatch({ type: 'SET_MISC_ORGANISATION_LIST', payload: data });

    }
  };
};

export const setCurrentOrganisation = (keycloak, orgName) => {
  return async (dispatch, getState) => {
    const { currentOrganisation } = getState().miscellaneous;

    // Update organization
    dispatch({
      type: 'SET_MISC_CURRENT_ORGANISATION',
      payload: {
        currentOrganisation: orgName,
        containerID: `${orgName}_0.1`,
      }
    });

    // Cache the current organisation
    localStorage.setItem('current-organisation', orgName);

    if (currentOrganisation && (currentOrganisation !== orgName)) {
      setTimeout(() => window.location.reload(), 500);
    }

  };
};