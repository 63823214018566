import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValid } from 'date-fns';

const DateField = ({ parentIndex, index, value, onChange, data }) => {

  // Input validation: Check if the input contains less than 355 characters
  const validateInput = (inputValue) => {
    return isValid(inputValue);
  };

  // Handle the input change event
  const handleInputChange = (newValue) => {
    onChange(parentIndex, index, newValue);
  };

  // Check if the current input is invalid
  const hasError = value !== '' && !validateInput(value);

  return (
    <>
      {/* <TextField
        disabled={data["isFixed"] || false}
        multiline
        maxRows={7}
        label={`${data["qNum"]}. ${data["qHeading"]}`}
        placeholder={data["placeholder"]}
        variant="outlined"
        value={value}
        onChange={handleInputChange}
        error={hasError}
        helperText={hasError ? 'Exceed the maximum character limit' : ''}
        fullWidth
        required={data["isRequired" || false]}
      /> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{ width: "100%" }}
          disabled={data["isFixed"] || false}
          format="dd/MM/yyyy"
          label={`${data["qNum"]}. ${data["qHeading"]}`}
          placeholder={data["placeholder"]}
          variant="outlined"
          value={value ? new Date(value) : null}
          onChange={handleInputChange}
          error={hasError}
          helperText={hasError ? 'Enter valid date' : ''}
          fullWidth
          required={data["isRequired"] || false}
          slotProps={{
            textField: {
              readOnly: true,
              required: data["isRequired"] || false
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateField;
