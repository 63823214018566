import styled, { css } from "styled-components";
import { Grid } from "@mui/material";

export const StyledContainer = styled.div`
    box-sizing: border-box;
    margin-top: 24px;
    display: flex;
    gap: 16px;

    @media screen and (max-width: 1299.98px) {
        flex-direction: column;
    }
`;

export const ContentContainer = styled.div`
    box-sizing: border-box;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};

    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    border-radius: 8px;
    padding: 15px 62px 82px 16px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1399.98px) {
        width: 100%;
    }
    @media (max-width: 599.98px) {
        padding: 15px 0 21px 16px;
    }

    & > h6 {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        margin-top: 5px;
        text-align: right;
        color: #ffffff;
        position: relative;
        z-index: 10;

        @media (max-width: 599.98px) {
            font-size: 17px;

            margin-top: 16px;
        }
    }

    ${({ $flex1 }) =>
        $flex1 &&
        css`
            flex: 1;
            padding: 0 15px 0px 15px;
            height: auto;
            @media (max-width: 599.98px) {
                padding: 15px 8px 0px;
            }
        `}
`;

export const StyledHead = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding:10px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};

    @media (max-width: 599.98px) {
        margin-bottom: 24px;
    }

    ${({ $panel2 }) =>
        $panel2 &&
        css`
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#fff")};
        width:100%;
        `}
    
        ${({ $hidden }) =>
        $hidden &&
        css`
        opacity: 0;
        `}

    ${({ $mzero }) =>
        $mzero &&
        css`
            margin-bottom: 0;
            @media (max-width: 599.98px) {
                margin-bottom: 0px;
            }
        `}
`;

export const GraphContainer = styled.div`
    & canvas {
        width: 600px !important;
    }
    @media (max-width: 599.98px) {
        & canvas {
            width: 100% !important;
        }
    }
`;

export const NumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15.5px;
    padding-left: 8px;
    box-sizing: border-box;

    p {
        font-weight: 600;
        font-size: 13px;
        color: #808191;
        font-family: "Inter";
    }

    @media (max-width: 599.98px) {
        padding: 0;
    }
`;

export const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-top: 50px;
    padding-left: 8px;
    box-sizing: border-box;

    @media (max-width: 599.98px) {
        padding: 0;
    }

    li {
        display: flex;
        gap: 8px;
        align-items: center;
        span {
            width: 22px;
            height: 6px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
        }

        &:first-of-type {
            span {
                background-color: #46a11b;
            }
        }
        &:nth-child(2) {
            span {
                background-color: #ed1e24;
            }
        }
        &:last-of-type {
            span {
                height: 0;
                border-top: 1px dashed ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
            }
        }
    }
`;

export const StyledListBox = styled.ul`
    margin-bottom: 60px;
    display: flex;
    gap: 71px;
    position: relative;

    @media screen and (max-width: 991px) {
        gap: 40px;
    }

    @media (max-width: 599.98px) {
        gap: 8px;
        margin-bottom: 24px;
    }
`;

export const StyledListItem = styled.li`
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    color: #fff;
    p {
        transform: translateY(-25px);
    }
    &:first-of-type {
        & > div {
            div {
                height: 0px;
            }
        }
    }
    &:nth-child(2) {
        & > div {
            div {
                height: 0px;
            }
        }
    }
    &:nth-child(3) {
        & > div {
            div {
                height: 0px;
            }
        }
    }
    &:nth-child(4) {
        & > div {
            div {
                height: 0px;
            }
        }
    }
    &:last-of-type {
        & > div {
            div {
                height: 0px;
            }
        }
    }
`;

export const StyledListContent = styled.div`
    position: relative;
    width: 29px;
    height: 190px;
    background-color: #ed1e24;
`;

export const StyledGraph = styled.div`
    background-color: #46a11b;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const StyledPercent = styled.p`
    font-weight: 400;
    font-size: 10px;
`;

export const StyledName = styled.h6`
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #808191;

    @media (max-width: 320px) {
        font-size: 10px;
    }
`;

export const ImgContainer = styled.div`
    img {
        width: 100px;
        height: 100px;
    }
`;

export const BoxesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
`;

export const StyledBoxes = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 280px;
    position: relative;
    z-index: 10;

    @media (max-width: 599.98px) {
        min-width: 200px;
        max-width: 200px;
    }

    ${({ $center }) =>
        $center &&
        css`
            max-width: 280px;
            margin: 0 auto;
        `}

    ${({ $marginleft }) =>
        $marginleft &&
        css`
            margin-left: auto;
        `}

    ${({ $marginsmall }) =>
        $marginsmall &&
        css`
            margin-left: 17px;
        `}
`;

export const StyledBox = styled.div`
    width: 100%;
    padding: 2px 0;
    background: #ffdcf4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#000")};
    text-align: center;

    ${({ $gray }) =>
        $gray &&
        css`
            background-color: #c1bbdd;
        `}
    ${({ $lightblue }) =>
        $lightblue &&
        css`
            background-color: #add8e6;
        `}

    span {
        font-family: "Rubik", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.15px;
    }
`;

export const StyledHeadingCenter = styled.div`
    display: flex;
    align-items: center;
    gap: 13px;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;

    @media (max-width: 599.98px) {
        margin-bottom: 24px;
    }
`;

export const StyledBackgroundDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#333333")};
    z-index: 2;
    clip-path: polygon(0 0, 0 100%, 100% 0);

    ${({ $back }) =>
        $back &&
        css`
            background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#1f2128")};
            clip-path: polygon(100% 0, 0 100%, 100% 100%);
            z-index: 1;
        `}
`;

export const CustomGrid = styled(Grid)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#333333")};
    padding-bottom: 16px;
    ${({ $back }) =>
        $back &&
        css`
            background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#1f2128")};
        `}
`;
