import { useEffect, useState, Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Box, CircularProgress, Container, Grid, Tooltip } from "@mui/material";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import QuestionProgress from "../../components/QuestionProgress/QuestionProgress";
import { ActionsWrapper, Wrapper } from "./InformationAssetRegister.styled";
import BusinessImpactLevel from "./components/BusinessImpactLevel";
import NewInformationAssetCard from "./components/NewInformationAssetCard";
import { completeUserTask, getCase, getCaseFileData, getMileStone, getProcessIDByCase, getUserTask, startMileStone, startUserTask } from "../TRA/TRAApi";
import { CONTAINER_ID, IARadvanceProcess } from "../../../../store/auth-context";
import { useKeycloak } from "@react-keycloak/web";
import { getMileStones } from "../SecurityControlMaturityAssessment/SecurityControlMaturityAssessmentApi";
import ErrorBoundary from "./components/ErrorBoundary";
import { toast } from "react-toastify";
import Loader from "../../../../components/UI/Loader/Loader";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../../../images/logo0.2.png";
import Lottie from "lottie-react";
import doneAnimation from "../../../../assets/animations/done.json";
import { StyledComplateContainer } from "../HealthCheck/HealthCheck.styled";
import { Helmet } from "react-helmet-async";
import { toggleAllowFetch } from "../../../../utility/informationAssetRegister.action";

function AddInformationAssetRegister(props) {

    // ** Hooks
    const { id } = useParams();
    const dispatch = useDispatch();

    const { keycloak, initialized } = useKeycloak();
    const [activeStep, setActiveStep] = useState(0);
    const [questions, setQuestionsList] = useState([]);
    const [mileStones, setMilstonesList] = useState([]);
    const [caseID, setCaseID] = useState("");
    const [tasks, setTasksList] = useState([]);
    const [answers, setAnswersList] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [completed, setCompleted] = useState(false);

    const navHandler = () => history.push("/it-security/information-asset-register", {});

    function getQuestions() {
        getCaseFileData(CONTAINER_ID, keycloak.token, caseID).then((res) => {
            if (res.success === true) {
                let dataObject = res.data;
                try {
                    let tempQuestions = [];
                    Object.entries(dataObject).forEach(([key, value]) => {
                        if (key.includes('Questions')) {
                            let questionArr = JSON.parse(value);
                            if (Array.isArray(questionArr)) {
                                tempQuestions.push(...questionArr);
                            } else {
                                tempQuestions.push(questionArr);
                            }
                        }
                    });

                    tempQuestions.sort((a, b) => {
                        const aParts = a['questions'][0].qNum.split('.').map(Number);
                        const bParts = b['questions'][0].qNum.split('.').map(Number);

                        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                            const aValue = aParts[i] || 0;
                            const bValue = bParts[i] || 0;

                            if (aValue !== bValue) {
                                return aValue - bValue;
                            }
                        }

                        return 0;
                    })

                    tempQuestions = tempQuestions.map((questions, index) => {
                        if (index === 0) {
                            return {
                                ...questions,
                                questions: questions.questions.map((question) => ({
                                    ...question,
                                    subQuestions: question.subQuestions.map((subQ) => {
                                        if (subQ.key === "assetCategory") {
                                            return {
                                                ...subQ,
                                                qAvailableAnswers: subQ.qAvailableAnswers.filter((o) => o !== "People Assets")
                                            }
                                        } else {
                                            return { ...subQ }
                                        }
                                    })
                                }))
                            }
                        } else {
                            return { ...questions }
                        }
                    })
                    setQuestionsList(tempQuestions);

                    let tempAnswers = [];
                    Object.entries(dataObject).forEach(([key, value]) => {

                        if (key.startsWith('a-') || key == "assetInfo") {
                            let ansObject = {
                                key: key,
                                data: JSON.parse(value)
                            };

                            tempAnswers.push(ansObject);
                        }
                    });
                    setAnswersList(tempAnswers)
                } catch (error) {
                    console.log(error);
                }

            }
        })
    }

    const getAllInstances = useCallback(() => {
        setLoading(true);
        if (id) {
            setCaseID(id);
            setLoading(false);
        } else {
            getCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, 'open', 1, 0)
                .then((res) => {
                    if (res.success) {
                        const caseID = res.data.instances[0]?.['case-id'];
                        if (caseID) {
                            setCaseID(caseID);
                        }
                    }
                })
                .catch((error) => {
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id, keycloak?.token])

    useEffect(() => {
        if (!CONTAINER_ID) return;
        getAllInstances();
    }, [CONTAINER_ID, getAllInstances])

    useEffect(() => {
        if (caseID === "") return;
        getMileStone(CONTAINER_ID, keycloak.token, caseID).then((res) => {
            if (res.success === true) {
                try {
                    let tempmilestones = [];
                    res.data["milestones"].map((mileStone, index) => {
                        const msName = mileStone["milestone-name"];
                        if (msName !== "init data" && msName !== "end") {
                            let msObject = {
                                "id": msName.split(".")[0],
                                "name": msName
                            }
                            tempmilestones.push(msObject);
                        }
                    })
                    tempmilestones.sort((a, b) => a.id - b.id);
                    setMilstonesList(tempmilestones);

                } catch (error) {
                    console.log(error)
                }
            }
        })
    }, [caseID])

    useEffect(() => {
        if (mileStones === 0 || caseID === "") return;
        getQuestions();
    }, [mileStones])

    function handleAnswerChange(parentIndex, index, val) {
        let newQuestions = [...questions];
        newQuestions[0].touched = true;
        newQuestions[0].questions[parentIndex].touched = true;
        newQuestions[0].questions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestionsList(newQuestions);
    }

    function questionAndAnswerMapping() {
        try {
            let tempQuestions = [...questions];
            tempQuestions.forEach((question, index) => {
                let mileStone = "";
                if (index === 0) {
                    mileStone = "1";
                    let ms = mileStones.find(ms => ms.id === mileStone);
                    question["milestone"] = ms["name"];
                    question["answerKey"] = "assetInfo";
                    let ans = answers.find(ans => ans["key"] == question["answerKey"]);
                    if (ans) {
                        // question["questions"][0]["subQuestions"][0]['qAnswer'] = ans.data["assetName"] || "";
                        // question["questions"][0]["subQuestions"][1]['qAnswer'] = ans.data["assetCategory"] || "";
                        // question["questions"][0]["subQuestions"][2]['qAnswer'] = ans.data["assetdescription"] || "";
                        // question["questions"][1]["subQuestions"][0]['qAnswer'] = ans.data["creating"] || "";
                        // question["questions"][1]["subQuestions"][1]['qAnswer'] = ans.data["storing"] || "";
                        // question["questions"][1]["subQuestions"][2]['qAnswer'] = ans.data["consuming"] || "";
                        // question["questions"][2]["subQuestions"][0]['qAnswer'] = ans.data["riskOwner"] || "";
                        // question["questions"][2]["subQuestions"][1]['qAnswer'] = ans.data["assetOwner"] || "";

                        question["questions"] = question["questions"].map((question, qIndex) => {
                            return {
                                ...question,
                                subQuestions: question.subQuestions.map((subQuestion, sIndex) => {
                                    let qAnswer;
                                    if (subQuestion.type === "date") {
                                        qAnswer = ans.data[subQuestion.key] ? new Date(ans.data[subQuestion.key]) : null;
                                    } else {
                                        qAnswer = ans.data[subQuestion.key] || "";
                                    }
                                    return {
                                        ...subQuestion,
                                        qAnswer: qAnswer,
                                    }
                                })
                            }
                        })

                    }
                } else {
                    mileStone = `${parseInt(tempQuestions[index]["questions"][0]["qNum"].split('.')[1]) + 1}`;
                    let ms = mileStones.find(ms => ms.id === mileStone);
                    question["milestone"] = ms["name"];
                    question["answerKey"] = "a-" + ms["name"].split(' ')[1].replaceAll('-', '');
                    let ans = answers.find(ans => ans["key"] == question["answerKey"]);
                    if (ans) {
                        question['questions'][1]["qAnswer"] = ans.data['description'];
                        question['questions'][2]["qAnswer"] = ans.data['low'];
                        question['questions'][3]["qAnswer"] = ans.data['medium'];
                        question['questions'][4]["qAnswer"] = ans.data['high'];
                        question['questions'][5]["qAnswer"] = ans.data['impact'];
                        question['questions'][5]["isRequired"] = true;
                    }
                }
            })
            // setActiveStep(answers.length);
            setQuestionsList(tempQuestions);
            setLoading(false);
        } catch (e) {
            console.log("DEBUG e caught = ", e);
        }
    }

    useEffect(() => {
        if (answers.length === 0) {
            return;
        }

        questionAndAnswerMapping();

    }, [answers])

    async function handleNext(isSubmit) {
        if (questions[activeStep]['touched']) {

            // Toggle refetch information asset register
            dispatch(toggleAllowFetch(true));

            setBtnLoading(true);
            const newQuestions = [...questions];
            newQuestions[activeStep].touched = false;
            setQuestionsList(newQuestions);

            let mileStone = activeStep === 0 ? "1" : `${parseInt(questions[activeStep]["questions"][0]["qNum"].split('.')[1]) + 1}`;
            const ms = mileStones.find(ms => ms.id === mileStone);

            try {
                const res = await startMileStone(CONTAINER_ID, keycloak.token, caseID, ms.name);
                if (!res.success) {
                    // Handle error if necessary
                    return;
                }

                const res2 = await getProcessIDByCase(CONTAINER_ID, keycloak.token, caseID);
                if (!res2.success) {
                    // Handle error if necessary
                    return;
                }

                const res3 = await getUserTask(CONTAINER_ID, keycloak.token, res2.data['process-instance'][0]['process-instance-id']);
                if (res3.success) {
                    const taskArr = res3.data["active-user-tasks"]["task-summary"];
                    const dataObj = questions[activeStep]['questions'];

                    let found, data;
                    if (activeStep === 0) {
                        const taskName = "Asset information";
                        found = taskArr.find(t => t['task-name'] === taskName);
                        // data = {
                        //     "assetName": dataObj[0]["subQuestions"][0]['qAnswer'] || "",
                        //     "assetCategory": dataObj[0]["subQuestions"][1]['qAnswer'] || "",
                        //     "assetOwner": dataObj[2]["subQuestions"][0]['qAnswer'] || "",
                        //     "assetdescription": dataObj[0]["subQuestions"][2]['qAnswer'] || "",
                        //     "creating": dataObj[1]["subQuestions"][0]['qAnswer'] || "",
                        //     "storing": dataObj[1]["subQuestions"][1]['qAnswer'] || "",
                        //     "consuming": dataObj[1]["subQuestions"][2]['qAnswer'] || "",
                        //     "riskOwner": dataObj[2]["subQuestions"][0]['qAnswer'] || ""
                        // };

                        data = {};

                        dataObj.forEach((question) => {
                            question.subQuestions.forEach((subQuestion) => {
                                if (subQuestion.type === "date") {
                                    data[subQuestion.key] = new Date(subQuestion.qAnswer).getTime();
                                } else {
                                    data[subQuestion.key] = subQuestion.qAnswer || "";
                                }
                            })
                        })

                    } else {
                        const taskName = ms.name.replaceAll("-", "").split(' ')[1];
                        found = taskArr.find(t => t['task-name'].split(' ')[1] === taskName);
                        data = {
                            "description": dataObj[1]['qAnswer'],
                            "low": dataObj[2]['qAnswer'],
                            "medium": dataObj[3]['qAnswer'],
                            "high": dataObj[4]['qAnswer'],
                            "impact": dataObj[5]['qAnswer'],
                        };
                    }

                    if (found) {
                        if (found["task-status"] === "InProgress") {
                            const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, found['task-id'], data);
                            if (res5.success) {
                                if (isSubmit) {
                                    await endCase();
                                }
                            }
                        } else {
                            const res4 = await startUserTask(CONTAINER_ID, keycloak.token, found['task-id']);
                            if (res4.success) {
                                const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, found['task-id'], data);
                                if (isSubmit) {
                                    await endCase();
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                // Handle errors if necessary
            }
        }

        if (!isSubmit) {
            // setActiveStep(activeStep + 1);
            setBtnLoading(false);
            navHandler();
        }
    }

    async function endCase() {
        const res = await startMileStone(CONTAINER_ID, keycloak.token, caseID, "end");
        if (res.success) {
            setCompleted(true);
        }
    }

    let userAnsweredAllQuestions = questions.length !== 0 && questions[0].questions.every((question) => {
        return question.subQuestions.every((subQuestion) => {
            if (subQuestion.isRequired) {
                return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
            } else {
                return true;
            }
        });
    })

    let userAnsweredBilQuestions = questions.length !== 0 && questions[activeStep].questions.every((question) => {
        return question.qAnswer !== null && question.qAnswer !== "" && question.qAnswer !== undefined;
    })

    if (completed) {
        return (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                        <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }} alt="logo" />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1 style={{ color: "white", wordBreak: "break-all" }}>Congratulations!</h1><br /><p style={{ wordBreak: "break-all" }}> You have added asset named "{questions.length !== 0 ? questions[0]['questions'][0]["subQuestions"][0]['qAnswer'] : ""}" to your Information Asset Register.</p>
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/information-asset-register")}>
                            Done
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    } else {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                        {/* <Box sx={{ paddingTop: "40px" }}>
                            {questions !== 0 ? <QuestionProgress questionNumber={activeStep + 1} totalNumber={questions.length} /> : ""}
                        </Box> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", position: "relative" }}>
                            {
                                activeStep === 0 && questions.length !== 0 && (
                                    <NewInformationAssetCard question={questions[activeStep]} handleTextChange={handleAnswerChange} />
                                )
                            }
                            <ActionsWrapper>
                                {
                                    activeStep !== questions.length - 1 && (
                                        <Button disabled={(activeStep === 0 ? !userAnsweredAllQuestions : !userAnsweredBilQuestions) || btnLoading} $success onClick={(e) => handleNext(false)} style={{ height: "42.25px" }}>
                                            {btnLoading ? <CircularProgress size={24} /> : "submit"}
                                        </Button>
                                    )
                                }
                            </ActionsWrapper>
                        </Box>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>{id ? `Information Asset Register Edit ${id} - CisoZen` : "Information Asset Register Create New - CisoZen"}</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px">
                    Information Asset Register
                </Heading>
                <ErrorBoundary>
                    {content}
                </ErrorBoundary>
            </Wrapper>
        </>
    );
}

export default AddInformationAssetRegister;
