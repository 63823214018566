import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Box, Grid, Typography, Collapse, Alert, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';
import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import { Button, CustomLoadingButton } from '../../../../../components/UI/Button/Button.styled';
import E8QuestionsStepper from '../../../components/E8QuestionStepper/E8QuestionStepper';
import { ActionsWrapper, StyledComplateContainer } from '../ThreatElicitationPage.styled';
import { startUserTask, completeUserTask, getCaseFileData, startMileStone, getUserTask, getProcessIDByCase, startNewCase, getCase, reopenCase, getCaseInfoWithData } from '../../TRA/TRAApi';
import { AssessMaturity, CONTAINER_ID, Solution } from '../../../../../store/auth-context';
import keycloak from '../../../../../Keycloak';
import ImageZoomInOut from './ImageZoomInOut';
import { toast } from "react-toastify";

import SolutionQuestionData from '../SolutionData.json';
import { fetchData, getSolutionData, removeEmptyCase, toggleAllowFetch } from '../../../../../utility/solution.action';
import SolutionTable from './SolutionTable';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../../../../components/UI/Loader/Loader';

function BackgroundFetchLoader() {
    const { fetchingBackgroundData } = useSelector((state) => state.solutions);

    const [fetching, setFetching] = useState(fetchingBackgroundData);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showSuccessAlert])

    useEffect(() => {

        if (fetching !== fetchingBackgroundData) {
            if (fetching && !fetchingBackgroundData) {
                setFetching(fetchingBackgroundData);
                setShowSuccessAlert(true);
            }
        }

    }, [fetching, fetchingBackgroundData])

    return <>
        <Collapse in={fetchingBackgroundData}>
            <Alert severity="warning">Solutions is being updated.  Please wait...</Alert>
        </Collapse>
        <Collapse in={showSuccessAlert}>
            <Alert severity="success">Solutions is now fully loaded</Alert>
        </Collapse>
    </>
}

const SolutionSection = (props) => {

    const dispatch = useDispatch();
    const { data, error, allowFetch, loading } = useSelector((state) => state.solutions);
    const { data: projects } = useSelector((state) => state.projects);

    const [openModal, setOpenModal] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [_loading, setLoading] = useState(false);
    const [taskInstanceIDList, setTaskInstanceIDList] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [caseID, setCaseID] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [solutions, setSolutions] = useState([]);
    const [disableActionMeta, setDisableActionMeta] = useState(null);
    const [createNewCaseLoading, setCreateNewCaseLoading] = useState(false);
    const [updateCaseLoading, setUpdateCaseLoading] = useState(false);
    const [updatingCase, setUpdatingCase] = useState(false);
    const [isMaturityCompleted, setMaturityCompleted] = useState(null);
    const [checkingMaturityCompleted, setCheckingMaturityCompleted] = useState(true);

    const disableActions = createNewCaseLoading || updatingCase || updateCaseLoading;

    useEffect(() => {
        if (data) {
            setSolutions(data);
        }

        if (data === null && allowFetch === false && projects?.length > 0) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch, projects])

    useEffect(() => {
        if (allowFetch && projects?.length > 0) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch, projects]);

    // Check 
    useEffect(() => {
        setCheckingMaturityCompleted(true);
        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'closed', 1)
            .then((response) => {
                if (response.success) {
                    if (response.data?.instances) {
                        if (response.data.instances.length > 0) {
                            setMaturityCompleted(true);
                        }
                    };
                }
                setCheckingMaturityCompleted(false);
            })
            .catch((error) => {
                setCheckingMaturityCompleted(false);
            });
    }, [CONTAINER_ID])

    async function handleNewCase(containerId, token) {

        try {
            let obj = {
                "case-group-assignments": {
                    "admin": "cz-sa-riskassessment",
                    "owner": containerId.replaceAll("_0.1", "")
                }
            }
            // Start a new case
            const response = await startNewCase(containerId, token, Solution, obj);
            if (response.success) {
                setCaseID(response.data);
                // // Add a wait time of 1 second
                // await new Promise(resolve => setTimeout(resolve, 1000));
                // // Update case file
                // const caseResponse = await getCase(containerId, token, Solution, 'open', 1);

                // if (caseResponse.success) {
                //     const instances = caseResponse.data.instances;

                //     if (instances && instances.length > 0) {
                //         const caseID = instances[0]['case-id'];
                //         setCaseID(caseID);
                //     }
                // }
            } else {
                setCreateNewCaseLoading(false);
                toast.error("Failed to create new solution!");
            }
        } catch (error) {
            // Handle any errors that occur during the process
            console.error("An error occurred:", error);
            setCreateNewCaseLoading(false);
            toast.error("An error occurred: ", error);
        }
    }

    const handleOpenModal = () => {
        setLoading(true);
        setCreateNewCaseLoading(true);
        handleNewCase(CONTAINER_ID, keycloak.token);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setLoading(false);
        setUpdatingCase(false);

        // Resetting case ID and process instance ID and questions
        setCaseID(null);
        setProcessInstanceID(null);
        let newQuestions = [{ ...SolutionQuestionData[0] }];
        newQuestions = newQuestions.map((question) => {
            return {
                ...question,
                subQuestions: question.subQuestions.map((subQuestion) => {
                    return { ...subQuestion, qAnswer: "" }
                })
            }
        })
        setQuestions(newQuestions);
        dispatch(removeEmptyCase(keycloak))
    };

    const handleOneAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
        if (parentIndex === 3) {
            newQuestions[parentIndex].subQuestions[index].qAnswer = val;
            setImageURL(val)
        }
    }, [questions]);

    let userAnsweredAllQuestions = questions?.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        if (subQuestion.required) {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        } else {
            return true;
        }
    }) : false;

    function getActiveUserTasks(_processInstanceID, _taskName) {
        // setFetching(true);
        getUserTask(CONTAINER_ID, keycloak.token, _processInstanceID).then((response) => {
            if (response.success === true) {
                try {
                    let taskList = response.data['active-user-tasks']['task-summary'];
                    let detailTask = taskList.find(task => task['task-name'] === 'Solution Detail');
                    let tasks = [];
                    if (detailTask !== undefined) {
                        tasks.push(detailTask);
                    }
                    setTaskInstanceIDList(tasks);
                    setFetching(false);
                } catch (error) {
                    //error
                }
                // setFetching(false);
            }

            if (response.success === false) {
                // setFetching(false);
            }
        });
    }

    function submitUserData(task, dataObj, end) {
        if (task['task-status'] !== "InProgress") {
            startUserTask(CONTAINER_ID, keycloak.token, task['task-id']).then((response) => {
                if (response.success === true) {
                    completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                        if (response.success) {
                            dispatch(toggleAllowFetch(true));
                            handleCloseModal();
                        }
                        if (response.success === false) {
                            setLoading(false);
                            setUpdatingCase(false);
                        }
                    });
                }

                if (response.success === false) {
                    setLoading(false);
                    setUpdatingCase(false);
                }
            });
        } else {
            completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                if (response.success) {
                    handleCloseModal();
                    dispatch(toggleAllowFetch(true));
                }
            });
        }
    }

    //trigger milestone to start applicable controls
    function triggerMileStone(_caseID) {
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Start Solution Form')
            .then((response) => {
                if (response.success) {
                    getActiveUserTasks(processInstanceID);
                    setOpenModal(true);
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                } else {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong");
            })
    }

    async function submitData(type) {

        try {
            setLoading(true);
            setUpdatingCase(true);

            if (activeIndex === 0) {
                const task = taskInstanceIDList.find((t) => t["task-name"] === 'Solution Detail');

                let dataObj = {};
                questions[activeIndex].subQuestions.forEach((question) => {
                    dataObj[`${question.key}`] = question.qAnswer.toString();
                });

                if (dataObj['dueDate']) {
                    dataObj['dueDate'] = (new Date(dataObj['dueDate'])).getTime();
                }

                let data = {
                    "solutionData": {
                        "com.cisozen.SolutionData": dataObj
                    }
                }

                submitUserData(task, data)
            }

        } catch (error) {
            setLoading(false);
            setUpdatingCase(true);
            toast.error(error?.message || "Something went wrong");
        }
    }

    const toggleDisableAction = (meta = null) => {
        setDisableActionMeta(meta)
    }

    const handleEditClick = (caseID, status) => {
        setUpdateCaseLoading(true);
        toggleDisableAction({ action: 'edit', caseID: caseID });
        if (status === 1) {
            setCaseID(caseID);
        } else if (status === 2) {
            reopenCase(CONTAINER_ID, keycloak.token, Solution, caseID).then((response) => {
                if (response.success) {
                    getCaseInfoWithData(CONTAINER_ID, keycloak.token, caseID).then((response1) => {
                        if (response1.success) {
                            getSolutionData(response1.data)
                                .then((solutionData) => {
                                    if (response.success) {
                                        setCaseID(caseID);
                                        dispatch(toggleAllowFetch(true))
                                    } else {

                                    }
                                })
                                .catch((error) => {
                                    setUpdateCaseLoading(false);
                                    toast.error("Failed to reopen case. Please try again later");
                                });
                        } else {
                            toggleDisableAction();
                            setUpdateCaseLoading(false);
                            toast.error(response.error);
                        }
                    })
                } else {
                    toggleDisableAction();
                    setUpdateCaseLoading(false);
                    toast.error(response.error);
                }
            });
        }

    };

    //get process instance id
    function getProcessInstanceID(_caseID) {
        getProcessIDByCase(CONTAINER_ID, keycloak.token, _caseID)
            .then((response) => {
                if (response.success) {
                    let processInstanceID = response.data['process-instance'][0]['process-instance-id'];
                    setProcessInstanceID(processInstanceID);
                }
                if (response.success === false) {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong")
                dispatch(toggleAllowFetch(true));
            })
    }

    //get process instance id
    useEffect(() => {
        if (caseID === null) return;
        getProcessInstanceID(caseID);
        // getMileStones(caseID);
    }, [caseID])

    //trigger threat detail milestone
    useEffect(() => {
        if (caseID === null || processInstanceID === null) return;
        triggerMileStone(caseID);
    }, [caseID, processInstanceID]);

    function getQuestions() {
        getCaseFileData(CONTAINER_ID, keycloak.token, caseID).then((response) => {
            if (response.success === true) {

                let caseData;
                try {
                    caseData = response.data?.solutionData?.["com.cisozen.SolutionData"];
                } catch (error) {
                    console.error("An error occurred:", error);
                    // Handle the error as needed
                }

                let newQuestions = [{ ...SolutionQuestionData[0] }];
                newQuestions = newQuestions.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === "projectID") {
                                return {
                                    ...subQuestion,
                                    qAnswer: "",
                                    qAvailableAnswers: projects
                                        .filter((p) => p?.name?.length > 0 && p?.projectID?.length > 0)
                                        .map(
                                            (p) => ({ label: `${p.name || ''} - ${p.projectID || ''}`, value: p.caseID })
                                        )
                                }
                            } else {
                                return { ...subQuestion, qAnswer: "" }
                            }
                        })
                    }
                })
                let questionObj = newQuestions[0];
                if (questionObj) {
                    let question = questionObj;
                    if (caseData) {
                        let q = question?.subQuestions?.map((subQ) => {
                            let newSubQ = subQ;
                            if (newSubQ.type === 'date') {
                                newSubQ["qAnswer"] = caseData[subQ.key] ? new Date(caseData[subQ.key]['java.util.Date']) : "";
                            } else {
                                newSubQ["qAnswer"] = caseData[subQ.key] || "";
                            }
                            return newSubQ;
                        });
                        question.subQuestions = q;
                    }
                    setQuestions([{ ...question }]);
                }

                setFetching(false);
            }

            if (response.success === false) {
                setFetching(false);
            }
        });
    }

    useEffect(() => {
        if (processInstanceID === null) {
            return;
        }
        getQuestions(processInstanceID);
    }, [taskInstanceIDList]);

    let content = '';
    if (loading || checkingMaturityCompleted) {
        content = <Loader />
    } else if (!loading && !checkingMaturityCompleted && !isMaturityCompleted) {
        content = <>
            <Box sx={{ marginTop: '20px' }}>
                <Alert severity="info">You have to complete Maturity Assessment!</Alert>
            </Box>
        </>
    } else if (!(projects?.length > 0)) {
        content = <>
            <Box sx={{ marginTop: '20px' }}>
                <Alert severity="info">You have to define a project before adding solution.</Alert>
            </Box>
        </>
    } else {
        content = <>
            <SolutionTable
                tableData={solutions}
                handleOpenModal={handleOpenModal}
                createNewCaseLoading={createNewCaseLoading}
                disableActions={disableActions}
                disableActionMeta={disableActionMeta}
                handleEditClick={handleEditClick}
            />
        </>;
    }

    return (
        <>
            <BackgroundFetchLoader />
            <DomainCard>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5">
                            Solutions
                        </Typography>
                    </Grid>
                </Grid>

                {content}

                <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                    scroll="paper"
                >
                    <DialogTitle>Solution</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            width: (activeIndex === 4 ? "100%" : "50%"),
                            height: (activeIndex === 4 ? "100%" : null)
                        }}
                    >
                        <Button onClick={handleCloseModal} style={{ float: "right" }}>X</Button> */}
                    <DialogContent dividers>
                        <Grid container spacing={4}>
                            {activeIndex === 4 && <Grid item xs={8}>
                                <ImageZoomInOut
                                    image={imageURL}
                                    imageName={'tempAltName'}
                                />
                            </Grid>}
                            <Grid item xs={activeIndex === 4 ? 4 : 12}>
                                {
                                    openModal && <E8QuestionsStepper
                                        questions={[...questions]}
                                        qIndex={activeIndex}
                                        handleAnswer={handleOneAnswer}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {/* <Box sx={{ marginBottom: "10px" }}>
                            <ActionsWrapper hasOne>
                                {
                                    updatingCase ? <CustomLoadingButton
                                        loading={true}
                                        success
                                        variant='contained'
                                    >
                                        Submit
                                    </CustomLoadingButton> : <Button
                                        disabled={!userAnsweredAllQuestions}
                                        $success
                                        onClick={(e) => submitData("detail")}
                                    >
                                        Submit
                                    </Button>
                                }
                            </ActionsWrapper>
                        </Box>
                    </Box> */}
                    <DialogActions sx={{ padding: "20px 24px" }}>
                        {
                            updatingCase ? <CustomLoadingButton
                                loading={true}
                                success
                                variant='contained'
                            >
                                Submit
                            </CustomLoadingButton> : <Button
                                disabled={!userAnsweredAllQuestions}
                                $success
                                onClick={(e) => submitData("detail")}
                            >
                                Submit
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </DomainCard>
        </>
    );
};

export default SolutionSection;