import { styled as MuiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Container = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  color: "#000"
}))

function FontSizer(props) {

  return <Container>
    <Fab size="small" onClick={props.decreaseFontSize}>
      <RemoveIcon />
    </Fab>
    <Typography>Font Size</Typography>
    <Fab size="small" onClick={props.increaseFontSize}>
      <AddIcon />
    </Fab>
  </Container>
}

export default FontSizer