import React from "react";

import { Button } from "../../../../components/UI/Button/Button.styled";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import Table from "../EditableTable/EditableTableV2";
import { Table3Data, Table4Data, Table5Data } from "../EditableTable/EditableTableData";
import { ContentContainer, SpanContainer, StyledHead, TextContainer } from "./FinalRisk.styled";

const FinalRisk = (props) => {
    const { riskFinal, data, parentCallback, selectedValue } = props;

    const handleCallback = (childData) => {
        parentCallback(childData);
    }

    let content;

    const Table3DataDynamic = [
        {
            number: { "value": "Rare", "isSelected": false, "showLeftBorder": false },
            insignificant: { "value": data["a1"], "isSelected": false, "showLeftBorder": false },
            Moderate: { "value": data["b1"], "isSelected": false, "showLeftBorder": false },
            catastrophic: { "value": data["c1"], "isSelected": false, "showLeftBorder": false },
        },
        {
            number: { "value": "Possible", "isSelected": false, "showLeftBorder": false },
            insignificant: { "value": data["a2"], "isSelected": false, "showLeftBorder": false },
            Moderate: { "value": data["b2"], "isSelected": false, "showLeftBorder": false },
            catastrophic: { "value": data["c2"], "isSelected": true, "showLeftBorder": false },
        },
        {
            number: { "value": "Almost Certain", "isSelected": false, "showLeftBorder": false },
            insignificant: { "value": data["a3"], "isSelected": false, "showLeftBorder": false },
            Moderate: { "value": data["b3"], "isSelected": false, "showLeftBorder": false },
            catastrophic: { "value": data["c3"], "isSelected": true, "showLeftBorder": false },
        }
    ];

    const Table4DataDynamic = [
        {
            number: { "value": "Rare", "isSelected": false },
            insignificant: { "value": data["a1"], "isSelected": false },
            minor: { "value": data["b1"], "isSelected": false },
            Moderate: { "value": data["c1"], "isSelected": false },
            catastrophic: { "value": data["d1"], "isSelected": false },
        },
        {
            number: { "value": "Possible", "isSelected": false },
            insignificant: { "value": data["a2"], "isSelected": false },
            minor: { "value": data["b2"], "isSelected": false },
            Moderate: { "value": data["c2"], "isSelected": false },
            catastrophic: { "value": data["d2"], "isSelected": true },
        },
        {
            number: { "value": "Likely", "isSelected": false },
            insignificant: { "value": data["a3"], "isSelected": false },
            minor: { "value": data["b3"], "isSelected": false },
            Moderate: { "value": data["c3"], "isSelected": false },
            catastrophic: { "value": data["d3"], "isSelected": true },
        },
        {
            number: { "value": "Almost Certain", "isSelected": false },
            insignificant: { "value": data["a4"], "isSelected": false },
            minor: { "value": data["b4"], "isSelected": false },
            Moderate: { "value": data["c4"], "isSelected": false },
            catastrophic: { "value": data["d4"], "isSelected": true },
        }
    ];

    const Table5DataDynamic = [
        {
            number: { "value": "Rare", "isSelected": false },
            insignificant: { "value": data["a1"], "isSelected": false },
            minor: { "value": data["b1"], "isSelected": false },
            Moderate: { "value": data["c1"], "isSelected": false },
            Major: { "value": data["d1"], "isSelected": false },
            catastrophic: { "value": data["e1"], "isSelected": false },
        },
        {
            number: { "value": "Unlikely", "isSelected": false },
            insignificant: { "value": data["a2"], "isSelected": false },
            minor: { "value": data["b2"], "isSelected": false },
            Moderate: { "value": data["c2"], "isSelected": false },
            Major: { "value": data["d2"], "isSelected": false },
            catastrophic: { "value": data["e2"], "isSelected": false },
        },
        {
            number: { "value": "Possible", "isSelected": false },
            insignificant: { "value": data["a3"], "isSelected": false },
            minor: { "value": data["b3"], "isSelected": false },
            Moderate: { "value": data["c3"], "isSelected": false },
            Major: { "value": data["d3"], "isSelected": false },
            catastrophic: { "value": data["e3"], "isSelected": false },
        },
        {
            number: { "value": "Likely", "isSelected": false },
            insignificant: { "value": data["a4"], "isSelected": false },
            minor: { "value": data["b4"], "isSelected": false },
            Moderate: { "value": data["c4"], "isSelected": false },
            Major: { "value": data["d4"], "isSelected": false },
            catastrophic: { "value": data["e4"], "isSelected": false },
        },
        {
            number: { "value": "Almost Certain", "isSelected": false },
            insignificant: { "value": data["a5"], "isSelected": false },
            minor: { "value": data["b5"], "isSelected": false },
            Moderate: { "value": data["c5"], "isSelected": false },
            Major: { "value": data["d5"], "isSelected": false },
            catastrophic: { "value": data["e5"], "isSelected": false },
        },
    ];


    if (riskFinal == 3) {
        content = (
            <Table
                selectedValue={selectedValue}
                tableData={Table3DataDynamic}
                headingColumns={[
                    "",
                    "Insignificant",
                    "Moderate",
                    "Catastrophic",
                ]}
                title="Consequence"
                parentCallback={handleCallback}
            />
        );
    } else if (riskFinal == 4) {
        content = (
            <Table
                selectedValue={selectedValue}
                tableData={Table4DataDynamic}
                headingColumns={[
                    "",
                    "Insignificant",
                    "Minor",
                    "Moderate",
                    "Catastrophic",
                ]}
                title="Consequence"
                parentCallback={handleCallback}
            />
        );
    } else if (riskFinal == 5) {
        content = (
            <Table
                selectedValue={selectedValue}
                tableData={Table5DataDynamic}
                headingColumns={[
                    "",
                    "Insignificant",
                    "Minor",
                    "Moderate",
                    "Major",
                    "Catastrophic",
                ]}
                title="Consequence"
                parentCallback={handleCallback}
            />
        );
    }

    return (
        <>
            {/* {parentCallback(Table3DataDynamic)} */}
            <RecHeading finalRisk bigWidth>
                {/* Change the definition of likelihood and the description of
                likelihood to match your business requirements or accept the
                following : */}
                Every Risk Management Matrix should clearly identify a Risk Tolerance Line that indicates which risk can be accepted and treated by routine management, and those risks that must be escalated to senior management for risk visibility and treatment approval.<br />
                Select the lowest risk level that is beyond your organisation’s acceptance level :
            </RecHeading>

            <ContentContainer>
                <div style={{ marginBottom: "24px" }}>{content}</div>

                {/* <Button $edit book="true" upper="true" info="true" disabled>
                    Edit
                </Button> */}

                {/* <StyledHead>
                Select lowest level of risk that is beyond your org tolerance threshold{" "}
                </StyledHead> */}

                <SpanContainer>
                    {/* <p></p> uncomment this to show circle*/}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#92d050"
                        style={{ marginRight: "-10px" }}
                    >
                        <path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path>
                    </svg>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#f00"
                        style={{ marginLeft: "-10px" }}
                    >
                        <path d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"></path>
                    </svg>
                </SpanContainer>


            </ContentContainer>

            <TextContainer>
                <p>Routine Oversight</p>
                <p>Senior Management Oversight</p>
            </TextContainer>
        </>
    );
};

export default FinalRisk;
