import { useState, useEffect } from "react";
import { Country, State } from 'country-state-city';
import { TextField, Autocomplete, InputLabel, Select, MenuItem, FormControl, FormLabel } from "@mui/material";
import { validateInput } from "../validation";

const options = ["AWS", "GCP", "Azure", "On premise", "Other"];

const DataStore = (props) => {

    const countries = Country.getAllCountries().map(country => { return ({ name: country.name, code: country.isoCode }) });
    const { question, parentIndex, index, handleAnswer, readOnly } = props;

    const [isCountry, setIsCountry] = useState(question.qAnswer.country ? true : false);
    const [country, setCountry] = useState(question.qAnswer.country);
    const [states, setStates] = useState([]);
    const [toggle, setToggle] = useState(!options.includes(question.qAnswer.dataCenter) && question.qAnswer.dataCenter);
    const [textError, setTextError] = useState(false);
    const [textErrorMessage, setTextErrorMessage] = useState("");

    useEffect(() => {
        if (country) {
            const availableStates = State.getStatesOfCountry(country.code).map(info => info.name);
            if (availableStates.length === 0) {
                setStates(["No state available"]);
            } else {
                setStates(availableStates);
            }
        }
    }, [country]);

    const onCountryChangeHandler = (event, value) => {
        if (value) {
            setIsCountry(true);
            question.qAnswer.country = value;
            handleAnswer(question.qAnswer, parentIndex, index);
            setCountry(value);
        } else {
            setIsCountry(false);
            question.qAnswer.country = "";
            handleAnswer(question.qAnswer, parentIndex, index);
            setCountry("");
        }
        question.qAnswer.state = "";
        handleAnswer(question.qAnswer, parentIndex, index);
    }

    const onStateChangeHandler = (event, value) => {
        if (value) {
            question.qAnswer.state = value;
            handleAnswer(question.qAnswer, parentIndex, index);
        } else {
            question.qAnswer.state = "";
            handleAnswer(question.qAnswer, parentIndex, index);
        }
    }

    const onDataCenterChangeHandler = (event) => {
        if (event.target.value !== "Other") {
            setToggle(false);
            question.qAnswer.dataCenter = event.target.value;
            handleAnswer(question.qAnswer, parentIndex, index);
        } else {
            question.qAnswer.dataCenter = "";
            handleAnswer(question.qAnswer, parentIndex, index)
            setToggle(true);
        }
    }

    const onTextChangeHandler = (event) => {
        if (validateInput(event.target.value)) {
            setTextError(false);
            setTextErrorMessage("");
            question.qAnswer.dataCenter = event.target.value;
            handleAnswer(question.qAnswer, parentIndex, index);
        } else {
            setTextError(true);
            setTextErrorMessage("Invalid Input!");
            question.qAnswer.dataCenter = "";
            handleAnswer(question.qAnswer, parentIndex, index);
        }
    }

    return (
        <FormControl style={{ border: '1px solid #696969', padding: '10px', borderRadius: '10px' }}>
            <FormLabel required={question.required}>{question.qHeading}</FormLabel>
            <Autocomplete
                style={{ marginTop: "20px" }}
                value={country ? country : null}
                onChange={onCountryChangeHandler}
                disablePortal
                options={countries}
                disabled={readOnly}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        fullWidth
                        label={question.qAvailableAnswers[0]}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />

            <Autocomplete
                disabled={!isCountry || readOnly}
                style={{ marginTop: "20px" }}
                value={question.qAnswer.state ? question.qAnswer.state : null}
                onChange={onStateChangeHandler}
                disablePortal
                options={states}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        fullWidth
                        label={question.qAvailableAnswers[1]}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />

            <FormControl style={{ marginTop: "20px" }}>
                <InputLabel shrink id={question.qNum + "-label"} required>{question.qAvailableAnswers[2]}</InputLabel>
                <Select
                    notched
                    required
                    label={question.qAvailableAnswers[2]}
                    labelId={question.qNum + "-label"}
                    value={toggle ? "Other" : question.qAnswer.dataCenter}
                    onChange={onDataCenterChangeHandler}
                >
                    {options.map((option, index) => (
                        <MenuItem value={option} key={index}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {toggle && (
                <TextField
                    required
                    style={{ marginTop: "20px" }}
                    label="Please specify"
                    variant="outlined"
                    error={textError}
                    disabled={readOnly}
                    helperText={textErrorMessage}
                    onChange={onTextChangeHandler}
                    defaultValue={question.qAnswer.dataCenter}
                    inputProps={{ maxLength: 255 }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        </FormControl>
    );
}


export default DataStore;