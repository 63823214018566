import { baseUrl } from "../../../../../store/auth-context";
import { retrieveData } from "../../../../../shared/utility";
let CONTAINER_ID = "UserProvisioning_0.1";
let UserProVisioningProcess = "UserProvisioning";

//authenticate provisioning user
export async function authenticate() {
    let result = {};
    var qs = require('qs');
    var data = qs.stringify({
        'grant_type': 'password',
        'username': retrieveData(process.env.REACT_APP_SA_USERNAME),
        'password': retrieveData(process.env.REACT_APP_SA_SECRET),
        'client_id': 'cz-sa-client',
        'client_secret': ''
    });
    const error = {
        "success": false,
        "message": "Something went wrong! Please try again",
        "result": 0
    };
    try {
        await fetch(`${retrieveData(process.env.REACT_APP_SA_URL)}/protocol/openid-connect/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(data)
        }).then(async (response) => {
            if (response.status === 200) {
                const responseData = await response.json();
                result["success"] = true;
                result["id"] = responseData.access_token;
            } else {
                result["success"] = false;
            }
        });
    } catch (err) {
        console.log(err);
        result = error;
    }
    return result;
}

//function to start new instance
export async function startNewInstance(userData, token) {
    let result = {success: false};
    const error = {
        "success": false,
        "message": "Something went wrong! Please try again",
        "result": 0
    };
    try {
        await fetch(`${baseUrl}/services/rest/server/containers/${CONTAINER_ID}/processes/${UserProVisioningProcess}/instances`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(userData)
        }).then(async (response) => {
            if (response.status === 201) {
                const data = await response.json();
                result["success"] = true;
                result["id"] = data;
            } else {
                result = error;
            }
        });
    } catch (err) {
        result = error;
    }
    return result;
}

//get user task
export async function getProvisioningTaskID(processInstanceID, token) {
    let result = {};
    const error = {
        "success": false,
        "message": "Something went wrong! Please try again",
        "result": 0
    };
    try {
        await fetch(`${baseUrl}/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processInstanceID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(async (res) => {
            if (res.status === 200) {
                const responseData = await res.json();
                const _taskInstanceID = responseData["active-user-tasks"]["task-summary"][0]["task-id"];
                const _taskStatus = responseData["active-user-tasks"]["task-summary"][0]["task-status"];

                result.success = true;
                result.id = _taskInstanceID;
                result.status = _taskStatus;
            } else {
                result = error;
            }
        });
    } catch (err) {
        result = error;
    }
    return result;
}

//start user task
export async function startProvisioningTask(taskInstanceID, token) {
    let result = {};
    const error = {
        "success": false,
        "message": "Something went wrong! Please try again",
        "result": 0
    };
    try {
        await fetch(`${baseUrl}/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceID}/states/started`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({})
        }).then((res) => {
            if (res.status === 201) {
                result["success"] = true;
            } else {
                result = error;
            }
        });
    } catch (err) {
        result = error;
    }
    return result;
}

//complete user task
export async function submitUserInfo(taskInstanceID, userData, token) {
    let result = {};
    const error = {
        "success": false,
        "message": "Something went wrong! Please try again",
        "result": 0
    };
    try {
        await fetch(`${baseUrl}/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceID}/states/completed`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(userData)
        }).then((res) => {
            if (res.status === 201) {
                result["success"] = true;
            } else {
                result = error;
            }
        });
    } catch (err) {
        result = error;
    }
    return result;
}