import { useState } from "react";
import dayjs from 'dayjs';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiLoadingButton from "@mui/lab/LoadingButton";

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Table from "../../../../../components/UI/Table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowFetch } from '../../../../../utility/solutionBuildingBlock.action';
import SolutionBuildingBlockDetailDialog from './SolutionBuildingBlockDetailDialog';
import TruncateText from '../../../../../components/TruncateItems/TruncateText';

const SolutionBuildingBlockTable = (props) => {

    const dispatch = useDispatch();
    const { fetchingBackgroundData } = useSelector((state) => state.solutionBuildingBlocks);

    const { tableData, handleOpenModal, createNewCaseLoading, disableActions, disableActionMeta, handleEditClick } = props;

    // ** States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    const columns = [
        {
            id: "solutionBuildingBlockID",
            header: "SBB ID",
            sort: true,
            width: '120px',
            pinLeft: true,
            sortFn: (a, b) => (a.solutionBuildingBlockID || '').localeCompare(b.solutionBuildingBlockID || '')
        },
        {
            flex: 1,
            id: "solutionName",
            header: "Solution Name",
            sort: true,
            minWidth: '250px',
            sortFn: (a, b) => (a.solution?.solutionName || '').localeCompare(b.solution?.solutionName || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row.solution?.solutionName || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "solutionID",
            header: "Solution ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.solution?.solutionID || '').localeCompare(b.solution?.solutionID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solution?.solutionID || ''}
                </>
            ),
        },
        {
            flex: 1,
            id: "project",
            header: "Project Name",
            sort: true,
            minWidth: '200px',
            sortFn: (a, b) => (a.project?.name || '').localeCompare(b.project?.name || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row.project?.name || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "projectID",
            header: "Project ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.project?.projectID || '').localeCompare(b.project?.projectID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.projectID || ''}
                </>
            ),
        },
        {
            flex: 1,
            id: "name",
            header: "Solution Building Block Name",
            sort: true,
            minWidth: '250px',
            searchable: true,
            sortFn: (a, b) => (a.name || '').localeCompare(b.name || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["name"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.5,
            id: "label",
            header: "Solution Label",
            sort: true,
            minWidth: '150px',
            sortFn: (a, b) => (a.label || '').localeCompare(b.label || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["label"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.5,
            id: "vendor",
            header: "Solution Vendor",
            sort: true,
            minWidth: '150px',
            sortFn: (a, b) => (a.vendor || '').localeCompare(b.vendor || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["vendor"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.5,
            id: "informationAssetID",
            header: "Information Asset ID",
            sort: true,
            minWidth: '200px',
            sortFn: (a, b) => (a.informationAssetID || '').localeCompare(b.informationAssetID || ''),
        },
        {
            flex: 0.5,
            id: "systemSolutionID",
            header: "System Solution ID",
            sort: true,
            minWidth: '170px',
            sortFn: (a, b) => (a.systemSolutionID || '').localeCompare(b.systemSolutionID || '')
        },
        {
            id: 'actions',
            header: '',
            sortable: false,
            disableColumnFilter: true,
            pinRight: true,
            width: "100px",
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        title="edit"
                        onClick={(e) => handleEditClick(row.caseID, row.status)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                tableData={tableData}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <MuiLoadingButton
                                aria-label="create new case"
                                title="create new case"
                                as={IconButton}
                                size="small"
                                onClick={handleOpenModal}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <AddCircleIcon />
                            </MuiLoadingButton>
                            <MuiLoadingButton
                                aria-label="refresh"
                                title="refresh"
                                as={IconButton}
                                size="small"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={fetchingBackgroundData}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />

            <SolutionBuildingBlockDetailDialog
                open={dialogOpen}
                toggle={toggleDetailDialog}
                data={dialogData}
                handleEditClick={handleEditClick}
            />
        </>
    )
}

export default SolutionBuildingBlockTable;