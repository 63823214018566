import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;

    margin-top: 64px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 0;
        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        right: 20px;
        bottom: 20px;

        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    }
`;

export const StyledComplateContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 600px;
    margin: 150px auto 0;
    text-align: center;
    padding: 20px;

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;
