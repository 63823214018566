import * as React from "react";
import { CustomLinearProgress, ProgressValue, ProgressWrapper } from "./LinearProgressWithLabel.styled";

const LinearProgressWithLabel = (props) => {
    const { value, children, color } = props;

    return (
        <ProgressWrapper>
            <CustomLinearProgress value={value} variant="determinate" size="150px" thickness={3} style={{ color: `${color && "red"}` }}></CustomLinearProgress>
            <ProgressValue>{children ? children : `${value.toFixed(0)}%`}</ProgressValue>
        </ProgressWrapper>
    );
};
export default LinearProgressWithLabel;
