import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useKeycloak } from "@react-keycloak/web";
import Grid from "@mui/material/Grid";

import Heading from "../../../../components/UI/Heading/Heading";
import { Wrapper } from "./RiskRegisterLandingPage.styled";
import RiskRegisterTable from "./components/RiskRegisterTable";
import { CONTAINER_ID, RiskRegisterProcessID } from "../../../../store/auth-context";
import RiskRegisterFormDialog from "./components/RiskRegisterFormDialog";
import RiskRegisterQuestionData from "./components/RiskRegisterQuestionData.json";
import { toggleAllowDomainFetch } from "../../../../utility/miscellaneous.action";
import { fetchData, toggleAllowFetch } from "../../../../utility/riskRegister.action";
import { completeUserTask, getProcessIDByCase, getUserTask, reopenCase, startMileStone, startUserTask } from "../TRA/TRAApi";

function RiskRegisterLandingPage(props) {

    // ** Hooks
    const dispatch = useDispatch();

    // ** Redux states
    const { data, caseData, allowFetch } = useSelector((state) => state.riskRegister);
    const { domains, allowDomainFetch } = useSelector((state) => state.miscellaneous);

    const { keycloak } = useKeycloak();

    const [modalOpen, setModalOpen] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [disableActionMeta, setDisableActionMeta] = useState(null);
    const [riskRegisterFormDialogOpen, setRiskRegisterFormDialogOpen] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [caseID, setCaseID] = useState(null);
    const [caseControlData, setCaseControlData] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [taskInstanceIDList, setTaskInstanceIDList] = useState(null);
    const [submittingForm, setSubmittingForm] = useState(false);

    const handleClick = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        if (domains === null && allowDomainFetch === false) {
            dispatch(toggleAllowDomainFetch(true))
        }
    }, [domains, allowDomainFetch])

    useEffect(() => {
        if (!(domains?.length > 0)) return;
        if (data) {
        }

        if (data === null && allowFetch === false) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch, domains])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    const toggleDisableAction = (meta = null) => {
        setDisableActions(prev => !prev);
        setDisableActionMeta(meta);
    }

    const toggleFormDialog = (e) => {
        e?.preventDefault();
        setRiskRegisterFormDialogOpen(prev => !prev);
    }

    const closeFormDialog = (e) => {
        e?.preventDefault();
        toggleDisableAction();
        setCaseID(null);
        setCaseControlData();
        setProcessInstanceID();
        setTaskInstanceIDList();
        setRiskRegisterFormDialogOpen(prev => !prev);
        setQuestions([])
    }

    function getQuestions() {
        let caseData = caseControlData || {};
        let newQuestions = [{ ...RiskRegisterQuestionData[0] }];
        newQuestions = newQuestions.map((question) => {
            return {
                ...question,
                subQuestions: question.subQuestions.map((subQuestion) => {
                    return { ...subQuestion, qAnswer: "" }
                })
            }
        })
        let questionObj = newQuestions[0];
        setQuestions([{ ...questionObj }]);

        if (questionObj) {
            let question = questionObj;
            if (caseData) {
                let q = question?.subQuestions?.map((subQ) => {
                    if (subQ.type === "date") {
                        let date = caseData[subQ.key] && caseData[subQ.key]['java.util.Date'];
                        if (date) {
                            date = new Date(date);
                        }
                        return {
                            ...subQ,
                            qAnswer: date || null
                        };
                    } else {
                        return {
                            ...subQ,
                            qAnswer: caseData[subQ.key] || ""
                        };
                    }
                });
                question.subQuestions = q;
            }
        }
    }

    const handleEditClick = useCallback((data) => {
        const { caseID } = data;
        setCaseControlData(data);
        toggleDisableAction({ action: "edit", caseID: caseID });
        setLoading(true);

        if (caseData?.status === 1) {
            setCaseID(caseID);
            toggleFormDialog();
        } else if (caseData?.status === 2) {
            reopenCase(CONTAINER_ID, keycloak.token, RiskRegisterProcessID, caseID)
                .then((response) => {
                    if (response.success) {
                        setCaseID(caseID);
                        toggleFormDialog();
                    } else {
                        setLoading(false);
                        toggleDisableAction();
                        toast.error(response.error || "Failed to reopen case. Please try again later");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toggleDisableAction();
                    toast.error(error.message || "Failed to reopen case. Please try again later");
                });
        }
    }, [caseData])

    function getProcessInstanceID(_caseID) {
        getProcessIDByCase(CONTAINER_ID, keycloak.token, _caseID)
            .then((response) => {
                if (response.success) {
                    let processInstanceID = response.data['process-instance'][0]['process-instance-id'];
                    setProcessInstanceID(processInstanceID);
                } else {
                    setLoading(false);
                    toast.error(response?.error?.message || "Something went wrong")
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.message || "Something went wrong")
            })
    }

    function triggerMileStone(_caseID) {
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Start Risk Register Form')
            .then((response) => {
                if (response.success) {
                    getProcessInstanceID(_caseID);
                } else {
                    setLoading(false);
                    toast.error(response?.error?.message || "Something went wrong");
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.message || "Something went wrong");
            })
    }

    function getActiveUserTasks(_processInstanceID) {
        getUserTask(CONTAINER_ID, keycloak.token, _processInstanceID)
            .then((response) => {
                if (response.success === true) {
                    try {
                        let taskList = response.data['active-user-tasks']['task-summary'];
                        let detailTask = taskList.find(task => task['task-name'] === 'Risk Register Detail');
                        let tasks = [];
                        if (detailTask !== undefined) {
                            tasks.push(detailTask);
                        }
                        setTaskInstanceIDList(tasks);
                    } catch (error) {
                        //error
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!caseID) return;
        triggerMileStone(caseData.caseID);
    }, [caseID])

    useEffect(() => {
        if (!processInstanceID) return;
        getActiveUserTasks(processInstanceID);
        getQuestions(processInstanceID);
    }, [processInstanceID])

    function submitUserData(task, dataObj) {
        if (task['task-status'] !== "InProgress") {
            startUserTask(CONTAINER_ID, keycloak.token, task['task-id'])
                .then((response) => {
                    if (response.success === true) {
                        completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj)
                            .then((response) => {
                                if (response.success) {
                                    closeFormDialog();
                                    dispatch(toggleAllowFetch(true));
                                } else {

                                }
                            })
                            .finally(() => {
                                setSubmittingForm(false);
                            })
                    } else {
                        setSubmittingForm(false);
                    }
                })
        } else {
            completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj)
                .then((response) => {
                    if (response.success) {
                        closeFormDialog();
                        dispatch(toggleAllowFetch(true));
                    } else {

                    }
                })
                .finally(() => {
                    setSubmittingForm(false);
                });
        }
    }

    const handleSubmit = useCallback((questions) => {
        setSubmittingForm(true);
        const task = taskInstanceIDList.find((t) => t["task-name"] === 'Risk Register Detail');
        let dataObj = {
            controlID: caseID,
        };
        questions.forEach((question) => {
            question.subQuestions.forEach((subQ) => {
                dataObj[`${subQ.key}`] = subQ.qAnswer;
            })
        })
        submitUserData(task, dataObj);
    }, [caseID, taskInstanceIDList])

    return (
        <>
            <Helmet>
                <title>Risk Register - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Risk Register
                </Heading>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RiskRegisterTable
                            disableActions={disableActions}
                            disableActionMeta={disableActionMeta}
                            handleEditClick={handleEditClick}
                        />
                    </Grid>
                </Grid>
            </Wrapper>

            <RiskRegisterFormDialog
                submittingForm={submittingForm}
                loading={loading}
                questions={questions}
                open={riskRegisterFormDialogOpen}
                toggle={closeFormDialog}
                handleSubmit={handleSubmit}
            />
        </>
    );
}

export default RiskRegisterLandingPage;
