//Code component for custom_database to drag and drop from sidebar

import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Position, NodeResizer, useUpdateNodeInternals, NodeToolbar, useReactFlow, useOnSelectionChange, NodeResizeControl } from 'reactflow';

import { styled as MuiStyled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';

import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CodeIcon from '@mui/icons-material/Code';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import xPic from './x-icon.png';
import { Button } from './components/button';
import { RFCard, RFNodeCard } from './components/card';
import { Android12Switch } from './components/switch';
import MethodologySelector from './components/MethodologySelector';
import { MethodologyList } from './components/list';
import InformationClassificationSelector from './components/InformationClassificationSelector';
import EditableDiv from './components/EditableDiv2';
import ResizeIcon from './node-content-icons/ResizeIcon';
import Handle from './components/Handle';

const InformationClassificationMapper = {
  "Official": {
    color: "#92d050",
    label: "Official",
  },
  "Sensitive": {
    color: "#ffc000",
    label: "Sensitive",
  },
  "Protected": {
    color: "#ff0000",
    label: "Protected",
  }
}

const LabelBox = MuiStyled(Box)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: 'black',
  position: 'absolute',
  width: '100%',
}))

const OutOfBoxImgContainer = MuiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-10px',
  top: '-12px',
  '& img': {
    width: '20px',
    height: '20px',
  }
}))

const MoreOptionBox = MuiStyled(Box)(({ theme }) => ({
  textAlign: 'start',
  marginTop: '4px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
}))

const CustomDivider = MuiStyled(Divider)(({ theme }) => ({
  fontSize: '10px',
  opacity: 0.6,
}))

const controlStyle = {
  background: 'transparent',
  border: 'none',
};

function SolutionBuildingBlockNode({ id, data, selected, isConnectable, width, height }) {

  // ** Hooks
  const theme = useTheme();
  const { setNodes, setEdges } = useReactFlow();

  // ** Stats
  const [isFocused, setFocused] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [type, setType] = useState(data?.type || '');
  const [name, setName] = useState(data?.name || '');
  const [label, setLabel] = useState(data?.label || '');
  const [configurationSectionEnabled, setConfigurationSectionEnabled] = useState(data?.configurationSectionEnabled ?? false);
  const [configurationMethodology, setConfigurationMethodology] = useState(data?.configurationMethodology ?? []);
  const [codeSectionEnabled, setCodeSectionEnabled] = useState(data?.codeSectionEnabled ?? false);
  const [codeMethodology, setCodeMethodology] = useState(data?.codeMethodology ?? []);
  const [dataSectionEnabled, setDataSectionEnabled] = useState(data?.dataSectionEnabled ?? false);
  const [dataMethodology, setDataMethodology] = useState(data?.dataMethodology ?? []);
  const [selectedInformationClassification, setSelectedInformationClassification] = useState(data?.informationClassification ?? '');
  const [isOutOfScope, setOutOfScope] = useState(data?.isOutOfScope ?? false);

  const onChange = useCallback(({ nodes, edges }) => {
    setSelectedNodes(nodes.map((node) => node.id));
  }, [])

  useOnSelectionChange({
    onChange
  });

  useEffect(() => {
    setFocused(!!(id && selectedNodes.includes(id)))
  }, [id, selectedNodes])

  // ** Update to Edge
  useEffect(() => {
    setNodes(
      (nodes) => nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              type: type,
              name: name,
              label: label,
              isOutOfScope: isOutOfScope,
              configurationSectionEnabled: configurationSectionEnabled,
              configurationMethodology: configurationMethodology,
              codeSectionEnabled: codeSectionEnabled,
              codeMethodology: codeMethodology,
              dataSectionEnabled: dataSectionEnabled,
              dataMethodology: dataMethodology,
              informationClassification: selectedInformationClassification,
            }
          }
        } else {
          return { ...node }
        }
      })
    )
  }, [codeMethodology, codeSectionEnabled, configurationMethodology, configurationSectionEnabled, dataMethodology, dataSectionEnabled, id, isOutOfScope, label, name, selectedInformationClassification, setNodes, type])

  const handleTypeChange = (newValue) => {
    setType(newValue);
  }

  const handleNameChange = (newValue) => {
    setName(newValue);
  }

  const handleLabelChange = (newValue) => {
    setLabel(newValue);
  }

  const handleToggleConfigurationMethodologyChange = (event, newValue) => {
    setConfigurationMethodology(newValue);
  };

  const handleToggleCodeMethodologyChange = (event, newValue) => {
    setCodeMethodology(newValue);
  };

  const handleToggleDataMethodologyChange = (event, newValue) => {
    setDataMethodology(newValue);
  };

  const handleInformationClassificationChange = (e) => {
    setSelectedInformationClassification(e.target.value);
  }

  const handleRemoveNodeClick = () => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((node) => !((node.source === id) || (node.target === id))));
  }

  return (
    <>
      <NodeToolbar isVisible={data.toolbarVisible} position={'relative'}>
        <RFCard sx={{ p: 1, minWidth: 250 }} className='nodrag nopan'>
          <Box>
            <ToggleButton
              fullWidth
              size="small"
              color="red"
              selected={isOutOfScope}
              onChange={() => setOutOfScope((prev) => !prev)}
              sx={{ fontSize: '14px', fontWeight: 400, padding: '7px', lineHeight: 'normal' }}
            >
              {isOutOfScope ? "Out of Scope" : "In-Scope"}
            </ToggleButton>
          </Box>

          <MoreOptionBox>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Android12Switch
                  color='green'
                  checked={configurationSectionEnabled}
                  onChange={(e) => {
                    setConfigurationSectionEnabled(e.target.checked);
                  }}
                />
              }
              label="Configuration"
            />
            {
              configurationSectionEnabled && <Box>
                <MethodologySelector
                  id={`MethodologySelector-configuration-${id}`}
                  key={`MethodologySelector-configuration-${id}`}
                  value={configurationMethodology}
                  onChange={handleToggleConfigurationMethodologyChange}
                />
              </Box>
            }
          </MoreOptionBox>

          <MoreOptionBox>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Android12Switch
                  color='green'
                  checked={codeSectionEnabled}
                  onChange={(e) => {
                    setCodeSectionEnabled(e.target.checked);
                  }}
                />
              }
              label="Code"
            />
            {
              codeSectionEnabled && <Box>
                <MethodologySelector
                  id={`MethodologySelector-code-${id}`}
                  key={`MethodologySelector-code-${id}`}
                  value={codeMethodology}
                  onChange={handleToggleCodeMethodologyChange}
                />
              </Box>
            }
          </MoreOptionBox>

          <MoreOptionBox>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Android12Switch
                  color='green'
                  checked={dataSectionEnabled}
                  onChange={(e) => {
                    setDataSectionEnabled(e.target.checked);
                  }}
                />
              }
              label="Data"
            />
            {
              dataSectionEnabled && <Box>
                <MethodologySelector
                  id={`MethodologySelector-data-${id}`}
                  key={`MethodologySelector-data-${id}`}
                  value={dataMethodology}
                  onChange={handleToggleDataMethodologyChange}
                />
              </Box>
            }
          </MoreOptionBox>

          <Box sx={{ marginTop: '4px' }}>
            <InformationClassificationSelector
              value={selectedInformationClassification}
              onChange={handleInformationClassificationChange}
            />
          </Box>

          <Chip
            variant="outlined"
            color="error"
            size="small"
            label="Remove"
            icon={<RemoveCircleOutlineIcon />}
            sx={{ marginTop: '4px' }}
            onClick={handleRemoveNodeClick}
          />

        </RFCard>
      </NodeToolbar>
      <Handle id={`handle-a-${id}`} type="target" position="left" />
      <Handle id={`handle-b-${id}`} type="target" position="top" />
      <Handle id={`handle-c-${id}`} type="source" position="right" />
      <Handle id={`handle-d-${id}`} type="source" position="bottom" />
      {
        selected && <NodeResizeControl style={controlStyle} minWidth={100} minHeight={50}>
          <ResizeIcon />
        </NodeResizeControl>
      }

      <RFNodeCard
        sx={{
          ...(!!selectedInformationClassification ? {
            border: `2px dashed ${InformationClassificationMapper[selectedInformationClassification].color}`,
          } : {
            border: 0
          })
        }}
      >
        <EditableDiv
          id={`sbb-type-${id}`}
          initialText={type}
          style={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            padding: '8px 12px',
            minHeight: '32px',
          }}
          onChange={handleTypeChange}
          editable={isFocused}
          placeholder="Enter type..."
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.25 }}>
          <EditableDiv
            id={`sbb-name-${id}`}
            initialText={name}
            style={{
              cursor: 'text',
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '16px',
              padding: '4px 12px',
              minHeight: '24px',
              background: theme.palette.action.selected,
              minWidth: '120px',
            }}
            onChange={handleNameChange}
            editable={isFocused}
            placeholder="Enter name..."
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0.25 }}>
          <EditableDiv
            id={`sbb-label-${id}`}
            initialText={label}
            style={{
              ...(!isFocused && { border: `1px solid ${theme.palette.divider}` }),
              cursor: 'text',
              fontSize: '12px',
              lineHeight: '16px',
              padding: '4px 4px',
              minHeight: '24px',
              minWidth: '50px',
            }}
            onChange={handleLabelChange}
            editable={isFocused}
            placeholder="Enter label..."
          />
        </Box>

        {
          configurationSectionEnabled && <Box sx={{ mt: 1.25 }}>
            <CustomDivider>Configuration</CustomDivider>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ width: '35%', textAlign: 'start', padding: '8px 16px' }}>
                <RoomPreferencesIcon sx={{ fontSize: '64px', opacity: 0.5 }} />
              </Box>
              <Box sx={{ width: '65%' }}>
                <MethodologyList methodology={configurationMethodology} />
              </Box>
            </Box>
          </Box>
        }

        {
          codeSectionEnabled && <Box sx={{ mt: 1.25 }}>
            <CustomDivider>Code</CustomDivider>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ width: '35%', textAlign: 'start', padding: '8px 16px' }}>
                <CodeIcon sx={{ fontSize: '64px', opacity: 0.5 }} />
              </Box>
              <Box sx={{ width: '65%' }}>
                <MethodologyList methodology={codeMethodology} />
              </Box>
            </Box>
          </Box>
        }

        {
          dataSectionEnabled && <Box sx={{ mt: 1.25 }}>
            <CustomDivider>Data</CustomDivider>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ width: '35%', textAlign: 'start', padding: '8px 16px' }}>
                <SdStorageIcon sx={{ fontSize: '64px', opacity: 0.5 }} />
              </Box>
              <Box sx={{ width: '65%' }}>
                <MethodologyList methodology={dataMethodology} />
              </Box>
            </Box>
          </Box>
        }

        {isOutOfScope ? (
          <OutOfBoxImgContainer>
            <img src={xPic} alt='out-of-scope' />
          </OutOfBoxImgContainer>
        ) : (
          <div />
        )}
      </RFNodeCard>

    </>
  );
}

export default SolutionBuildingBlockNode;