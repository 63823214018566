import { useContext } from 'react';
import {
  LegendBox,
  YellowIndicator,
  ExtremeRisk,
  HighRisk,
  MediumRisk,
  LowRisk,
  Item,
  Item2
} from "../Common/Graph.styled";
import Grid from '@mui/material/Grid';
import ThemeContext from '../../../../../store/theme-context';
export function isRiskAboveOrEqualTolerance(currentRisk, toleranceRisk) {
  try {
    const extractRiskInfo = (riskString) => {
      const [category, number] = riskString.split('-');
      return { category, number: parseInt(number) };
    };

    const currentRiskInfo = extractRiskInfo(currentRisk);
    const toleranceRiskInfo = extractRiskInfo(toleranceRisk);

    if (currentRiskInfo.category !== toleranceRiskInfo.category) {
      // If categories are different, compare based on category order
      const riskOrder = ['Low', 'Medium', 'High', 'Extreme'];
      const currentCategoryIndex = riskOrder.indexOf(currentRiskInfo.category);
      const toleranceCategoryIndex = riskOrder.indexOf(toleranceRiskInfo.category);
      return currentCategoryIndex > toleranceCategoryIndex;
    } else {
      // If categories are the same, compare based on numbers
      return currentRiskInfo.number >= toleranceRiskInfo.number;
    }
  } catch (e) {
    return false;
  }
}
const LegendPage = ({ ...props }) => {

  const { mode } = useContext(ThemeContext);

  return (
    // <LegendBox>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {/* <Grid md={12}><MyTextColor><Item2>Legend</Item2></MyTextColor></Grid> */}
      <Grid item sm={3} md={3}>
        <Item><YellowIndicator /></Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 2 }}>Mitigation</p></Item2>
      </Grid>
      <Grid item sm={3} md={3}>
        <Item>
          <ExtremeRisk style={{ ...(mode === 'light' && { borderColor: 'white' }) }} />
        </Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>Extreme Risk : {props.extreme}</p></Item2>
      </Grid>
      <Grid item sm={3} md={3}>
        <Item>
          <HighRisk
            underTolerance={isRiskAboveOrEqualTolerance("High-999", props.toleranceRisk)}
            style={{ ...(mode === 'light' && { borderColor: 'white' }) }}
          />
        </Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>High Risk : {props.high}</p></Item2>
      </Grid>
      <Grid item sm={3} md={3}>
        <Item>
          <MediumRisk
            underTolerance={isRiskAboveOrEqualTolerance("Medium-999", props.toleranceRisk)}
            style={{ ...(mode === 'light' && { borderColor: 'white' }) }}
          />
        </Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>Medium Risk : {props.medium}</p></Item2>
      </Grid>
      <Grid item sm={3} md={3}>
        <Item>
          <LowRisk
            underTolerance={isRiskAboveOrEqualTolerance("Low-999", props.toleranceRisk)}
            style={{ ...(mode === 'light' && { borderColor: 'white' }) }}
          />
        </Item>
      </Grid>
      <Grid item sm={9} md={9}>
        <Item2><p style={{ paddingTop: 5 }}>Low Risk : {props.low}</p></Item2>
      </Grid>
    </Grid>
    // </LegendBox>
  );
};

export default LegendPage;