import React from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import MuiLoadingButton from '@mui/lab/LoadingButton';

import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';

import Table from "../../../../../components/UI/Table/Table";
import { toggleAllowFetch } from "../../../../../utility/informationAssetRegister.action";
import { minWidth } from "@mui/system";

const getOrder = (impact) => {

    // Assign weights based on sequence
    switch (impact) {
        case 'High':
            return 30;
        case 'Medium':
            return 20;
        case 'Low':
            return 10;
        default:
            return 0;
    }
};

const InformationSecurityClassificationTable = ({ disableActions, disableActionMeta, handleEditClick }) => {

    // ** Hooks
    const dispatch = useDispatch();

    // ** Redux states
    const { data, loading, fetchingBackgroundData } = useSelector((state) => state.informationAssetRegisters);

    const columns = [
        // {
        //     id: "id",
        //     header: "Asset ID",
        //     sort: true,
        //     sortFn: (a, b) => (a.id || '').localeCompare(b.id || '')
        // },
        {
            id: "assetID",
            header: "Asset ID",
            sort: true,
            width: "175px",
            pinLeft: true,
            sortFn: (a, b) => (a.assetID || '').localeCompare(b.assetID || '')
        },
        {
            flex: 0.2,
            id: "assetName",
            header: "Asset Title",
            sort: true,
            searchable: true,
            minWidth: "200px",
            sortFn: (a, b) => (a.assetName || '').localeCompare(b.assetName || '')
        },
        {
            flex: 0.3,
            id: "c",
            header: "Confidentiality",
            sort: true,
            minWidth: "175px",
            sortFn: (a, b) => {
                const orderA = getOrder(a.c);
                const orderB = getOrder(b.c);
                return orderA - orderB;
            }
        },
        {
            flex: 0.3,
            id: "i",
            header: "Integrity",
            sort: true,
            minWidth: "175px",
            sortFn: (a, b) => {
                const orderA = getOrder(a.i);
                const orderB = getOrder(b.i);
                return orderA - orderB;
            }
        },
        {
            flex: 0.3,
            id: "a",
            header: "Availability",
            sort: true,
            minWidth: "175px",
            sortFn: (a, b) => {
                const orderA = getOrder(a.a);
                const orderB = getOrder(b.a);
                return orderA - orderB;
            }
        },
        {
            id: "businessCriticality",
            header: "Business Criticality",
            width: "175px",
            sort: true,
            sortFn: (a, b) => {
                const getOrder = (c) => {

                    // Assign weights based on sequence
                    switch (c) {
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };

                const orderA = getOrder(a.businessCriticality || '');
                const orderB = getOrder(b.businessCriticality || '');

                return orderA - orderB;
            }
        },
        {
            id: "informationProtectiveMarkings",
            header: "Information Protective Markings",
            width: "275px",
            sort: true,
            sortFn: (a, b) => (a.informationProtectiveMarkings || '').localeCompare(b.informationProtectiveMarkings || '')
        },
        {
            id: "actions",
            header: "",
            width: "60px",
            disableColumnFilter: true,
            pinRight: true,
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        title="edit"
                        aria-label="edit"
                        onClick={(e) => handleEditClick(row)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                skeletonLoader
                loading={loading}
                pageSize={5}
                tableData={data || []}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => {
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={loading || fetchingBackgroundData}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default InformationSecurityClassificationTable;