import React, { useState, useEffect } from "react";
import { CustomizedCardHeader } from "../Common/Graph.styled";
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CustomDialog, CustomDialogTitle } from "../Common/CustomizedDialog";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const getColor = (details) => {
    let color = "inherit";
    switch (details["Inherent Risk Rating"]?.split("-")[0]) {
        case "Medium": color = "orange";
            break;
        case "Extreme": color = "red";
            break;
        case "High": color = "red";
            break;
        default:
    }
    return color;
}

const RiskGraphPage = (props) => {

    const { open, toggle, data } = props;

    const [dialogTitle, setDialogTitle] = useState('');

    useEffect(() => {
        if (open) {
            setDialogTitle(`Details of ${data?.riskTitle}`);
        }
    }, [data?.riskTitle, open])

    return (
        <>
            {/* start of dialog show details of selected node  */}
            <CustomDialog onClose={toggle} aria-labelledby="customized-dialog-title" open={open}>
                <CustomDialogTitle id="customized-dialog-title" onClose={toggle}>
                    {dialogTitle}
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1} >
                        {
                            data?.riskDetails ? Object.keys(data.riskDetails).map((ele) =>
                                <Grid item xs={12} sm={4} md={4} key={ele}>
                                    <Card shadow={4} style={{ minWidth: '450', margin: 'auto' }}>
                                        <CustomizedCardHeader title={ele} style={{ textAlign: 'center', padding: 0 }} />
                                        <CardContent sx={{ minHeight: 100, textAlign: 'center', color: (ele === "Inherent Risk Rating" ? getColor(data.riskDetails) : "inherit"), wordWrap: 'break-word', }} >
                                            {data.riskDetails[ele]}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ) : ""
                        }
                    </Grid>
                </DialogContent>
                <DialogActions />
            </CustomDialog>
            {/******** end of dialog show details of selected node  ********/}

        </>
    );
};

export default RiskGraphPage;