import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Box, Grid, Typography, Collapse, Alert, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';
import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import { Button, CustomLoadingButton, VisuallyHiddenInput } from '../../../../../components/UI/Button/Button.styled';
import E8QuestionsStepper from '../../../components/E8QuestionStepper/E8QuestionStepper';
import { ActionsWrapper } from '../ThreatElicitationPage.styled';
import { startUserTask, completeUserTask, getCaseFileData, startMileStone, getUserTask, getProcessIDByCase, startNewCase, getCase, reopenCase, getCaseInfoWithData } from '../../TRA/TRAApi';
import { CONTAINER_ID, Project } from '../../../../../store/auth-context';
import keycloak from '../../../../../Keycloak';
import ImageZoomInOut from './ImageZoomInOut';
import { toast } from "react-toastify";

import ProjectQuestionData from '../QuestionData.json';
import { fetchData, getProjectData, removeEmptyCase, toggleAllowFetch, updateDataByCaseID } from '../../../../../utility/project.action';
import ProjectTable from './ProjectTable';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function BackgroundFetchLoader() {
    const { fetchingBackgroundData } = useSelector((state) => state.projects);

    const [fetching, setFetching] = useState(fetchingBackgroundData);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showSuccessAlert])

    useEffect(() => {

        if (fetching !== fetchingBackgroundData) {
            if (fetching && !fetchingBackgroundData) {
                setFetching(fetchingBackgroundData);
                setShowSuccessAlert(true);
            }
        }

    }, [fetching, fetchingBackgroundData])

    return <>
        <Collapse in={fetchingBackgroundData}>
            <Alert severity="warning">Projects is being updated.  Please wait...</Alert>
        </Collapse>
        <Collapse in={showSuccessAlert}>
            <Alert severity="success">Projects is now fully loaded</Alert>
        </Collapse>
    </>
}

const ProjectSection = (props) => {

    const dispatch = useDispatch();
    const { data, error, allowFetch } = useSelector((state) => state.projects);

    const [openModal, setOpenModal] = useState(false);
    const [questions, setQuestions] = useState([{ ...ProjectQuestionData[0] }]);
    const [loading, setLoading] = useState(false);
    const [taskInstanceIDList, setTaskInstanceIDList] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [caseID, setCaseID] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [projects, setProjects] = useState([]);
    const [disableActionMeta, setDisableActionMeta] = useState(null);
    const [createNewCaseLoading, setCreateNewCaseLoading] = useState(false);
    const [updateCaseLoading, setUpdateCaseLoading] = useState(false);
    const [updatingCase, setUpdatingCase] = useState(false);

    const disableActions = createNewCaseLoading || updatingCase || updateCaseLoading;

    useEffect(() => {
        if (data) {
            setProjects(data);
        }

        if (data === null && allowFetch === false) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    async function handleNewCase(containerId, token) {

        try {
            let obj = {
                "case-group-assignments": {
                    "admin": "cz-sa-riskassessment",
                    "owner": containerId.replaceAll("_0.1", "")
                }
            }
            // Start a new case
            const response = await startNewCase(containerId, token, Project, obj);
            if (response.success) {
                let caseID = response.data;
                // Add a wait time of 1 second
                await new Promise(resolve => setTimeout(resolve, 1000));
                // Update case file
                // const caseResponse = await getCase(containerId, token, Project, 'open', 1);
                const caseResponse = await getCaseInfoWithData(containerId, token, caseID);

                if (caseResponse.success) {
                    const instances = caseResponse.data;

                    if (instances) {
                        const caseID = instances['case-id'];

                        let newProject = {};

                        let projectDetail = instances["case-file"]["case-data"]["projectData"];
                        let projectID = instances["case-file"]["case-data"]["projectID"];
                        if (projectDetail) {
                            newProject = { ...projectDetail };
                        }

                        newProject["date"] = instances["case-started-at"] || 0;
                        newProject["status"] = instances["case-status"] || 0;
                        let cDate = new Date(instances["case-started-at"]);

                        newProject["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        });

                        newProject.caseID = instances["case-id"];
                        newProject.projectID = projectID;

                        setCaseID(caseID);
                        dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { ...newProject } }));
                    }
                }
            } else {
                setCreateNewCaseLoading(false);
                toast.error("Failed to create new project!");
            }
        } catch (error) {
            // Handle any errors that occur during the process
            console.error("An error occurred:", error);
            setCreateNewCaseLoading(false);
            toast.error("An error occurred: ", error);
        }
    }

    const handleOpenModal = () => {
        setLoading(true);
        setCreateNewCaseLoading(true);
        handleNewCase(CONTAINER_ID, keycloak.token);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setLoading(false);
        setUpdatingCase(false);

        // Resetting case ID and process instance ID and questions
        setCaseID(null);
        setProcessInstanceID(null);
        let newQuestions = [{ ...ProjectQuestionData[0] }];
        newQuestions = newQuestions.map((question) => {
            return {
                ...question,
                subQuestions: question.subQuestions.map((subQuestion) => {
                    return { ...subQuestion, qAnswer: "" }
                })
            }
        })
        setQuestions(newQuestions);
        dispatch(removeEmptyCase(keycloak))
    };

    const handleOneAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        newQuestions[parentIndex].touched = true;
        newQuestions[parentIndex].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
        if (parentIndex === 3) {
            newQuestions[parentIndex].subQuestions[index].qAnswer = val;
            setImageURL(val)
        }
    }, [questions]);

    let userAnsweredAllQuestions = questions?.length > 0 ? questions[activeIndex].subQuestions.every((subQuestion) => {
        if (subQuestion.required) {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        } else {
            return true;
        }
    }) : false;

    function getActiveUserTasks(_processInstanceID, _taskName) {
        // setFetching(true);
        getUserTask(CONTAINER_ID, keycloak.token, _processInstanceID).then((response) => {
            if (response.success === true) {
                try {
                    let taskList = response.data['active-user-tasks']['task-summary'];
                    let detailTask = taskList.find(task => task['task-name'] === 'Project Detail');
                    let tasks = [];
                    if (detailTask !== undefined) {
                        tasks.push(detailTask);
                    }
                    setTaskInstanceIDList(tasks);
                    setFetching(false);
                } catch (error) {
                    //error
                }
                // setFetching(false);
            }

            if (response.success === false) {
                // setFetching(false);
            }
        });
    }

    function submitUserData(task, dataObj, end) {
        if (task['task-status'] !== "InProgress") {
            startUserTask(CONTAINER_ID, keycloak.token, task['task-id']).then((response) => {
                if (response.success === true) {
                    completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                        if (response.success) {
                            dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { ...dataObj['projectData']['com.cisozen.ProjectData'], status: 2 }, refetchData: true }));
                            handleCloseModal();
                        }
                        if (response.success === false) {
                            setLoading(false);
                            setUpdatingCase(false);
                            toast.error(response.error);
                        }
                    });
                }

                if (response.success === false) {
                    setLoading(false);
                    setUpdatingCase(false);
                    toast.error(response.error);
                }
            });
        } else {
            completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                if (response.success) {
                    dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { ...dataObj['projectData']['com.cisozen.ProjectData'], status: 2 }, refetchData: true }));
                    handleCloseModal();
                }

                if (response.success === false) {
                    setLoading(false);
                    setUpdatingCase(false);
                    toast.error(response.error);
                }
            });
        }
    }

    //trigger milestone to start applicable controls
    function triggerMileStone(_caseID) {
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Start Project Form')
            .then((response) => {
                if (response.success) {
                    getActiveUserTasks(processInstanceID);
                    setOpenModal(true);
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                } else {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong");
            })
    }

    async function submitData(type) {

        try {
            setLoading(true);
            setUpdatingCase(true);

            if (activeIndex === 0) {
                const task = taskInstanceIDList.find((t) => t["task-name"] === 'Project Detail');

                let dataObj = {};
                questions[activeIndex].subQuestions.forEach((question) => {
                    dataObj[`${question.key}`] = question.qAnswer.toString();
                });

                const startDate = (new Date(dataObj.startDate)).getTime();
                const endDate = (new Date(dataObj.endDate)).getTime();

                let data = {
                    "projectData": {
                        "com.cisozen.ProjectData": {
                            "name": dataObj.name,
                            "description": dataObj.description,
                            "specifications": dataObj.specifications,
                            "budget": dataObj.budget,
                            "managerName": dataObj.managerName,
                            "leadName": dataObj.leadName,
                            "startDate": { "java.util.Date": startDate },
                            "endDate": { "java.util.Date": endDate }
                        }
                    }
                }

                submitUserData(task, data)
            }

        } catch (error) {
            setLoading(false);
            setUpdatingCase(true);
            toast.error(error?.message || "Something went wrong");
        }
    }

    const toggleDisableAction = (meta = null) => {
        setDisableActionMeta(meta)
    }

    // const handleEditClick = (caseID, status) => {
    //     setUpdateCaseLoading(true);
    //     toggleDisableAction({ action: 'edit', caseID: caseID });
    //     if (status === 1) {
    //         setCaseID(caseID);
    //     } else if (status === 2) {
    //         reopenCase(CONTAINER_ID, keycloak.token, Project, caseID)
    //             .then((response) => {
    //                 if (response.success) {
    //                     getCaseInfoWithData(CONTAINER_ID, keycloak.token, caseID).then((response1) => {
    //                         console.log('DEBUG getCaseInfoWithData response1 = ', response1)
    //                         if (response1.success) {
    //                             getProjectData(response1.data)
    //                                 .then((projectData) => {
    //                                     if (response.success) {
    //                                         setCaseID(caseID);
    //                                     } else {
    //                                         toast.error("Failed to get data. Please try again later");
    //                                     }
    //                                 })
    //                                 .catch((error) => {
    //                                     setUpdateCaseLoading(false);
    //                                     toast.error("Failed to reopen case. Please try again later");
    //                                 });
    //                         } else {
    //                             toggleDisableAction();
    //                             setUpdateCaseLoading(false);
    //                             toast.error(response.error);
    //                         }
    //                     })
    //                 } else {
    //                     toggleDisableAction();
    //                     setUpdateCaseLoading(false);
    //                     toast.error(response.error);
    //                 }
    //             })
    //             .catch((error) => {
    //                 setUpdateCaseLoading(false);
    //                 toast.error("Failed to reopen case. Please try again later");
    //             });
    //     }

    // };

    const handleEditClick = (caseID, status) => {
        setUpdateCaseLoading(true);
        toggleDisableAction({ action: 'edit', caseID: caseID });
        if (status === 1) {
            setCaseID(caseID);
        } else if (status === 2) {
            reopenCase(CONTAINER_ID, keycloak.token, Project, caseID)
                .then((response) => {
                    if (response.success) {
                        // Change status of the case
                        dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { status: 1 } }));
                        setCaseID(caseID);
                    } else {
                        toggleDisableAction();
                        setUpdateCaseLoading(false);
                        toast.error(response.error);
                    }
                })
                .catch((error) => {
                    setUpdateCaseLoading(false);
                    toast.error("Failed to reopen case. Please try again later");
                });
        }

    };

    //get process instance id
    function getProcessInstanceID(_caseID) {
        getProcessIDByCase(CONTAINER_ID, keycloak.token, _caseID)
            .then((response) => {
                if (response.success) {
                    let processInstanceID = response.data['process-instance'][0]['process-instance-id'];
                    setProcessInstanceID(processInstanceID);
                }
                if (response.success === false) {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong")
            })
    }

    //get process instance id
    useEffect(() => {
        if (caseID === null) return;
        getProcessInstanceID(caseID);
        // getMileStones(caseID);
    }, [caseID])

    //trigger threat detail milestone
    useEffect(() => {
        if (caseID === null || processInstanceID === null) return;
        triggerMileStone(caseID);
    }, [caseID, processInstanceID]);

    // function getQuestions() {
    //     getCaseFileData(CONTAINER_ID, keycloak.token, caseID)
    //         .then((response) => {
    //             console.log('DEBUG getCaseFileData response = ', response)

    //             if (response.success === true) {

    //                 let caseData;
    //                 try {
    //                     caseData = response.data?.projectData?.["com.cisozen.ProjectData"];
    //                 } catch (error) {
    //                     console.error("An error occurred:", error);
    //                     // Handle the error as needed
    //                 }

    //                 let newQuestions = [{ ...ProjectQuestionData[0] }];
    //                 newQuestions = newQuestions.map((question) => {
    //                     return {
    //                         ...question,
    //                         subQuestions: question.subQuestions.map((subQuestion) => {
    //                             return { ...subQuestion, qAnswer: "" }
    //                         })
    //                     }
    //                 })
    //                 let questionObj = newQuestions[0];
    //                 if (questionObj) {
    //                     let question = questionObj;
    //                     if (caseData) {
    //                         let q = question?.subQuestions?.map((subQ) => {
    //                             let newSubQ = subQ;
    //                             if (newSubQ.type === 'date') {
    //                                 newSubQ["qAnswer"] = caseData[subQ.key] ? new Date(caseData[subQ.key]['java.util.Date']) : "";
    //                             } else {
    //                                 newSubQ["qAnswer"] = caseData[subQ.key] || "";
    //                             }
    //                             return newSubQ;
    //                         });
    //                         question.subQuestions = q;
    //                     }
    //                     setQuestions([{ ...question }]);
    //                 }

    //                 setFetching(false);
    //             }

    //             if (response.success === false) {
    //                 setFetching(false);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error("Something went wrong");
    //         });
    // }

    function getQuestions() {
        getCaseFileData(CONTAINER_ID, keycloak.token, caseID)
            .then((response) => {
                if (response.success === true) {

                    let caseData;
                    try {
                        caseData = response.data?.projectData?.["com.cisozen.ProjectData"];
                        if (caseData) {
                            dispatch(updateDataByCaseID(keycloak, { caseID: caseID, data: { ...caseData, status: 1 } }))
                        }
                    } catch (error) {
                        console.error("An error occurred:", error);
                        // Handle the error as needed
                    }

                    let newQuestions = [{ ...ProjectQuestionData[0] }];
                    newQuestions = newQuestions.map((question) => {
                        return {
                            ...question,
                            subQuestions: question.subQuestions.map((subQuestion) => {
                                return { ...subQuestion, qAnswer: "" }
                            })
                        }
                    })
                    let questionObj = newQuestions[0];
                    if (questionObj) {
                        let question = questionObj;
                        if (caseData) {
                            let q = question?.subQuestions?.map((subQ) => {
                                let newSubQ = subQ;
                                if (newSubQ.type === 'date') {
                                    newSubQ["qAnswer"] = caseData[subQ.key] ? new Date(caseData[subQ.key]['java.util.Date']) : "";
                                } else {
                                    newSubQ["qAnswer"] = caseData[subQ.key] || "";
                                }
                                return newSubQ;
                            });
                            question.subQuestions = q;
                        }
                        setQuestions([{ ...question }]);
                    }

                    setFetching(false);
                }

                if (response.success === false) {
                    setFetching(false);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    useEffect(() => {
        if (processInstanceID === null) {
            return;
        }
        getQuestions(processInstanceID);
    }, [taskInstanceIDList]);

    return (
        <>
            <BackgroundFetchLoader />
            <DomainCard>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5">
                            Projects
                        </Typography>
                    </Grid>
                </Grid>

                <ProjectTable
                    tableData={projects}
                    handleOpenModal={handleOpenModal}
                    createNewCaseLoading={createNewCaseLoading}
                    disableActions={disableActions}
                    disableActionMeta={disableActionMeta}
                    handleEditClick={handleEditClick}
                />

                <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                    scroll="paper"
                >
                    <DialogTitle>Project</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            width: (activeIndex === 4 ? "100%" : "50%"),
                            height: (activeIndex === 4 ? "100%" : null)
                        }}
                    >
                        <Button onClick={handleCloseModal} style={{ float: "right" }}>X</Button> */}

                    <DialogContent dividers>
                        <Grid container spacing={4}>
                            {activeIndex === 4 && <Grid item xs={8}>
                                {
                                    imageURL ? <ImageZoomInOut
                                        image={imageURL}
                                        imageName={'tempAltName'}
                                    /> : <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                }
                            </Grid>}
                            <Grid item xs={activeIndex === 4 ? 4 : 12}>
                                {
                                    openModal && <E8QuestionsStepper
                                        key={caseID}
                                        questions={[...questions]}
                                        qIndex={activeIndex}
                                        handleAnswer={handleOneAnswer}
                                    />
                                }
                            </Grid>

                        </Grid>


                    </DialogContent>

                    {/* <Box sx={{ marginBottom: "10px" }}>
                        <ActionsWrapper hasOne>
                            {
                                updatingCase ? <CustomLoadingButton
                                    loading={true}
                                    success
                                    variant='contained'
                                >
                                    Submit
                                </CustomLoadingButton> : <Button
                                    disabled={!userAnsweredAllQuestions}
                                    $success
                                    onClick={(e) => submitData("detail")}
                                >
                                    Submit
                                </Button>
                            }
                        </ActionsWrapper>
                    </Box>
                </Box> */}

                    <DialogActions sx={{ padding: "20px 24px" }}>
                        {
                            updatingCase ? <CustomLoadingButton
                                loading={true}
                                success
                                variant='contained'
                            >
                                Submit
                            </CustomLoadingButton> : <Button
                                disabled={!userAnsweredAllQuestions}
                                $success
                                onClick={(e) => submitData("detail")}
                            >
                                Submit
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </DomainCard >
        </>
    );
};

export default ProjectSection;