import { Badge, Card, CardActions, CardContent, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Typography } from "@mui/material";
import { DomainCard } from "./ApplicableControl.styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { QuestionHeadWrapper, QuestionNumber } from "../../../../../../components/Question/QuestionHead/QuestionHead.styled";
import QuestionHeading from "../../../../../../components/Question/QuestionHead/QuestionHeading/QuestionHeading";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ApplicableControls(props) {
  const { controls, handleExpandClick, handleCheckBoxClick, riskTitle } = props;
  function getTotalSelected(domain) {
    let count = 0;
    domain.sections.map((section) => {
      section.controls.map((control) => {
        if (control.isChecked) count++;
      })
    })
    return count;
  }

  let renderControls = controls.map((domain, index) => {
    return <div key={index} style={{ marginLeft: "40px" }}>
      <CardActions disableSpacing >
        <Badge badgeContent={getTotalSelected(domain)} color="success">
          <Typography variant="title1" fontWeight={500} onClick={(e) => handleExpandClick(index)}>{domain?.domainID} . {domain?.domainHeading} &nbsp;</Typography>
        </Badge>
        <ExpandMore
          expand={domain.isExpanded}
          onClick={(e) => handleExpandClick(index)}
          aria-expanded={domain.isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={domain.isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ControlCheckList parentIndex={index} key={index} sections={domain.sections} handleCheckBoxClick={handleCheckBoxClick} />
        </CardContent>
      </Collapse>
      <Divider />
    </div>;
  });

  return (
    <DomainCard>
      {
        riskTitle?.length > 0 && <QuestionHeadWrapper>
          <QuestionNumber>2.</QuestionNumber>
          <QuestionHeading hasInfo={""}>
            Select applicable controls to treat the risk title "{riskTitle}"
          </QuestionHeading>
        </QuestionHeadWrapper>
      }
      {renderControls}
    </DomainCard>
  );
}

function ControlCheckList(props) {
  const { parentIndex, sections, handleCheckBoxClick } = props;

  return (
    <CardContent>
      <Grid container spacing={{ xs: 1, md: 4 }}>
        {sections.map((section, sIndex) => (
          <Grid item xs={12} md={6} key={`section-${sIndex}`} >
            <Typography variant="subtitle1" gutterBottom style={{ fontWeight: "500" }}>
              {section.sectionID}. {section.sectionHeading}
            </Typography>
            <FormControl component="fieldset">
              {section.controls.map((control, cIndex) => (
                <FormControlLabel
                  key={`control-${sIndex}-${cIndex}`}
                  value="end"
                  control={
                    <Checkbox
                      checked={control?.isChecked || false}
                      onClick={() => handleCheckBoxClick(parentIndex, sIndex, cIndex)}
                      style={{ color: '#3a8d1c' }}
                    />
                  }
                  label={`${control.controlID}. ${control.controlHeading}`}
                  labelPlacement="end"
                />
              ))}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  );
}




export default ApplicableControls;
