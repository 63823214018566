
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import {
    StyledTable
} from "../../nested/SecurityControlMaturityAssessment/MaturityScores/MaturityScores.styled";


function MaturityScoresStaticTable (props) {
    const {data} = props;
    const assessmentScaleData = data;
    return (<div>
    <TableContainer component={Paper}>
        <StyledTable size="small" >
            <TableHead>
                <TableRow>
                    <TableCell>Score</TableCell>
                    <TableCell align="left">
                        Description
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {assessmentScaleData.map((row) => (
                    <TableRow key={row.scoreId} >
                        <TableCell component="th" scope="row"
                            style={{
                                color: `${row.scoreId === 0 ? "#ff0000" : row.scoreId === 1 ? "#ff4500" : row.scoreId === 2 ? "#ff9966" : row.scoreId === 3 ? "#fbec5d" : row.scoreId === 4 ? "#addfad" : "#008000"
                                    }`,
                            }}>
                            {row.scoreId}  -  {row.scoreTxt}
                        </TableCell>
                        <TableCell align="left">
                            {row.description}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    </TableContainer>
</div>);

}



export default MaturityScoresStaticTable;
