import React, { useState, useRef, useEffect } from 'react';
import { Handle, Position, NodeResizer, NodeToolbar } from 'reactflow';

import { styled as MuiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FontSizer from './components/FontSizer';

const LabelContainer = MuiStyled(Box)(({ theme }) => ({
  border: '1px solid #000000',
  backgroundColor: '#ffffff',
}))

const Label = MuiStyled(Box)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: '#000000',
}))

const LabelNode = ({ selected, data }) => {

  const [fontSize, setFontSize] = useState(14);
  const [label, setLabel] = useState(data.label);
  const [isEditing, setIsEditing] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const handleInputChange = (event) => {
    setLabel(event.target.value);
  };

  const toggleEditing = () => {
    if (label === '') {
      setLabel('Label');
    }
    setIsEditing(!isEditing);
  };

  const increaseFontSize = () => {
    setFontSize(fontSize + 2);
  }

  const decreaseFontSize = () => {
    if (fontSize > 6) {
      setFontSize(fontSize - 2);
    }
  }

  return (
    <>
      <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
        <Box>
          <FontSizer increaseFontSize={increaseFontSize} decreaseFontSize={decreaseFontSize} />
        </Box>
      </NodeToolbar>
      <NodeResizer isVisible={selected} direction="bottom-right" />

      <LabelContainer>
        {isEditing ? (
          <textarea ref={textAreaRef} value={label} onChange={handleInputChange} onBlur={toggleEditing} style={{ whiteSpace: 'pre-wrap', width: '100%' }} autofocus />
        ) : (
          <Label onClick={toggleEditing} sx={{ fontSize: `${fontSize}px` }}>
            {label}
          </Label>
        )}
      </LabelContainer>
    </>
  );
};

export default LabelNode;
