import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import Table from '../../../../../components/UI/Table/Table';
import { toggleAllowFetch } from '../../../../../utility/soa.action';
import SOADetailDialog from './SOADetailDialog';
import TruncateText from '../../../../../components/TruncateItems/TruncateText';

const extractNumbers = (str) => {
    return str.split(':')[0].split('.').slice(1).map(Number);
};


const SOATable = (props) => {

    // ** Hooks
    const dispatch = useDispatch();

    // ** Redux states
    const { data, loading } = useSelector((state) => state.soa);

    const { disableActionMeta, disableActions, handleEditClick } = props;

    // ** States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    const columns = [
        {
            flex: 0.3,
            id: "securityControlClauses",
            header: "Security Control Clauses",
            sort: true,
            minWidth: '350px',
            sortFn: (a, b) => {
                const numA = extractNumbers(a.securityControlClauses || '');
                const numB = extractNumbers(b.securityControlClauses || '');

                for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
                    if (numA[i] !== numB[i]) {
                        return (numA[i] || 0) - (numB[i] || 0);
                    }
                }
                return 0;
            },
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["securityControlClauses"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.3,
            id: "securityCategories",
            header: "Security Categories",
            sort: true,
            minWidth: '350px',
            sortFn: (a, b) => {
                const numA = extractNumbers(a.securityCategories || '');
                const numB = extractNumbers(b.securityCategories || '');

                for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
                    if (numA[i] !== numB[i]) {
                        return (numA[i] || 0) - (numB[i] || 0);
                    }
                }
                return 0;
            },
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["securityCategories"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.3,
            id: "securityControl",
            header: "Security Control",
            sort: true,
            minWidth: '350px',
            sortFn: (a, b) => {
                const numA = extractNumbers(a.securityControl || '');
                const numB = extractNumbers(b.securityControl || '');

                for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
                    if (numA[i] !== numB[i]) {
                        return (numA[i] || 0) - (numB[i] || 0);
                    }
                }
                return 0;
            },
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["securityControl"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "applicability",
            header: "Applicability",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a.applicability || '').localeCompare(b.applicability || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["applicability"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.2,
            id: "reason",
            header: "Reason",
            sort: false,
            minWidth: '250px',
            sortFn: (a, b) => (a.reason || '').localeCompare(b.reason || ''),
            renderCell: ({ row }) => (<>
                {row.reason?.length > 0 ? `This control is applicable as it is a countermeasure for threat ID: ${row.reason}` : ""}
            </>)
        },
        {
            id: "owner",
            header: "Owner/Position",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => (a.owner || '').localeCompare(b.owner || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["owner"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "implementation",
            header: "Implementation Status",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => (a.implementation || '').localeCompare(b.implementation || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["implementation"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "gapAssessment",
            header: "Maturity Assessment",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => ((a.score || 0) - (b.score || 0)),
            renderCell: ({ row }) => {
                return <>
                    <span style={{ color: row.score <= 2.5 ? "red" : "green" }}>
                        {row.score}
                    </span>
                </>
            }
        },
        {
            id: 'actions',
            header: '',
            sortable: false,
            disableColumnFilter: true,
            pinRight: true,
            width: '100px',
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        title="edit"
                        onClick={(e) => handleEditClick(row)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <DomainCard>
            <Table
                skeletonLoader
                loading={loading}
                tableData={data || []}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <MuiLoadingButton
                                aria-label="refresh"
                                title="refresh"
                                as={IconButton}
                                size="small"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={loading}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />

            <SOADetailDialog
                open={dialogOpen}
                toggle={toggleDetailDialog}
                data={dialogData}
                handleEditClick={handleEditClick}
            />
        </DomainCard>
    );
};

export default SOATable;