//Code component for custom_database to drag and drop from sidebar

import { useState, useEffect, useRef, useContext } from 'react';
import { Handle, Position, NodeResizer, useUpdateNodeInternals, NodeToolbar } from 'reactflow';

import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import filePic from '../graph-images/file_icon.png';
import DoubleArrow from './components/DoubleArrow';
import { ToolbarActionCard } from './components/card';
import { display } from '@mui/system';

const ControlLabelContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2px',
  '& p': {
    color: 'white',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '18px',
    width: '18px',
    borderRadius: '9px',
    backgroundColor: 'red'
  }
}))

const ControlText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% + 10px)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '2px',
  padding: '0 20px',
  color: 'green',
  fontSize: '10px'
}))

const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  height: '100%',
  justifyContent: 'space-between',
}))

function ArrowConnectorNode({ id, data, selected, isConnectable, width, height }) {

  // ** Hooks

  const butStyle = {
    backgroundColor: '#ff0015',
    borderRadius: '50%',
    border: 'none',
    padding: '5px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '10px',
    margin: '4px 2px',
    color: 'white',
    fontWeight: 'bold',
    width: '30px',
    height: '30px',
  };

  const but2Style = {
    backgroundColor: '#4eaccc',
    borderRadius: '8px',
    border: 'none',
    padding: '5px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '10px',
    margin: '4px 2px',
    color: 'white',
    fontWeight: 'bold',

  };

  const [label, setLabel] = useState(data.label);
  const [isEditing, setIsEditing] = useState(false);
  const [isX, setIsX] = useState(false);
  const [paragraphs, setParagraphs] = useState([]);
  const textAreaRef = useRef(null);

  //Editable Label
  useEffect(() => {
    if (isEditing) {
      textAreaRef.current.focus();

    }
  }, [isEditing]);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setLabel(event.target.value);
  };

  const generateLabel = () => {
    if (label === '') {
      setLabel('label');
    }
  }

  //Toggle Out of Scope
  const toggleX = () => {
    setIsX(!isX);
  };


  //Add STRIDE Content
  const generateS = () => {
    const newParagraph = {
      id: 's' + paragraphs.length, // This will serve as a unique id
      text: 'Spoofing controls',
      icon: './node-content-icons/s-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }
  const generateT = () => {
    const newParagraph = {
      id: 't' + paragraphs.length, // This will serve as a unique id
      text: 'Integrity controls',
      icon: '/node-content-icons/t-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }
  const generateR = () => {
    const newParagraph = {
      id: 'r' + paragraphs.length, // This will serve as a unique id
      text: 'Non-repudiation controls',
      icon: '/node-content-icons/r-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }
  const generateI = () => {
    const newParagraph = {
      id: 'i' + paragraphs.length, // This will serve as a unique id
      text: 'Integrity controls',
      icon: '/node-content-icons/i-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }
  const generateD = () => {
    const newParagraph = {
      id: 'd' + paragraphs.length, // This will serve as a unique id
      text: 'Availability controls',
      icon: '/node-content-icons/d-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }
  const generateE = () => {
    const newParagraph = {
      id: 'e' + paragraphs.length, // This will serve as a unique id
      text: 'PAM controls',
      icon: '/node-content-icons/e-Icon.png',
    };
    setParagraphs([...paragraphs, newParagraph]);
  }

  const deleteP = () => {
    setParagraphs(paragraphs.slice(0, -1));
  }

  return (
    <>
      <NodeToolbar isVisible={data.toolbarVisible} position={'relative'}>
        <ToolbarActionCard style={{ width: '350px', height: '100px' }}>
          <ToolbarContainer>
            <Typography>Add Content</Typography>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
              <Chip label="S" onClick={generateS} color="red" sx={{ minWidth: "32px" }} />
              <Chip label="T" onClick={generateT} color="red" sx={{ minWidth: "32px" }} />
              <Chip label="R" onClick={generateR} color="red" sx={{ minWidth: "32px" }} />
              <Chip label="I" onClick={generateI} color="red" sx={{ minWidth: "32px" }} />
              <Chip label="D" onClick={generateD} color="red" sx={{ minWidth: "32px" }} />
              <Chip label="E" onClick={generateE} color="red" sx={{ minWidth: "32px" }} />
              {/* <button style={butStyle} onClick={generateS}>S</button>
            <button style={butStyle} onClick={generateT}>T</button>
            <button style={butStyle} onClick={generateR}>R</button>
            <button style={butStyle} onClick={generateI}>I</button>
            <button style={butStyle} onClick={generateD}>D</button>
            <button style={butStyle} onClick={generateE}>E</button>
            <br></br> */}
            </Box>
            <Chip label="Delete" size="small" onClick={deleteP} />
            {/* <button style={but2Style} onClick={deleteP}>Delete</button> */}
          </ToolbarContainer>
        </ToolbarActionCard>
      </NodeToolbar>

      <NodeResizer isVisible={selected} direction="bottom-right" />

      <Box sx={{ height: "100%" }}>
        <DoubleArrow />

        <ControlLabelContainer>
          {paragraphs.map(({ id, text, icon }) => {
            if (id.charAt(0) === 's' || 't' || 'r' || 'i' || 'd' || 'e') {
              return <Box key={"d" + id} >
                <p key={"icon" + id} style={{}}>
                  {id.charAt(0)}
                </p>
              </Box>;
            }
            return null;
          })}
        </ControlLabelContainer>

        <ControlText style={{}}>
          {paragraphs.filter(({ id }) => id.charAt(0) === 's' || 't' || 'r' || 'i' || 'd' || 'e').map(({ text }) => text).join(' & ')}
        </ControlText>

      </Box>
    </>
  );
}

export default ArrowConnectorNode;