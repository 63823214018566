import styled from "styled-components";

const ButtonStyled = styled.button`
    font-family: "Rubik", sans-serif;
    background-color: ${({ blue }) => (blue ? "#2F75B5" : "#46a11b")};
    background-color: ${({ red }) => (red ? "#ED1E24" : "")};
    border-radius: 4px;
    border: none;
    color: white;
    width: ${({ confirm }) => (confirm ? "139px" : "90px")};
    width: ${({ info }) => (info ? "79px" : "")};
    width: ${({ risk }) => (risk ? "104px" : "")};
    width: ${({ submit }) => (submit ? "103px" : "")};
    width: ${({ complete }) => (complete ? "127px" : "")};

    height: ${({ book }) => (book ? "42px" : "48px")};
    height: ${({ avatar }) => (avatar ? "32px" : "")};

    font-size: ${({ sm }) => (sm ? "14px" : "16px")};
    text-transform: ${({ upper }) => (upper ? "uppercase" : "")};

    align-self: ${({ center }) => center};

    box-sizing: border-box;
    cursor: pointer;

    &:disabled {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0.12)
            ),
            #ffffff;
        color: rgba(0, 0, 0, 0.26);
        cursor: not-allowed;
    }
`;

export default ButtonStyled;
