import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MuiLoadingButton from "@mui/lab/LoadingButton";

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Table from "../../../../../components/UI/Table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowFetch } from '../../../../../utility/threat.action';
import ThreatDetailDialog from "./ThreatDetailDialog";
import TruncateText from "../../../../../components/TruncateItems/TruncateText";
import { generateTRAReport } from "../../../../../utility/tra.report.utils";
import { ReportTypes } from "../../../../../shared/constants";
import { CustomLoadingButton } from "../../../../../components/UI/Button/Button.styled";

const ThreatTable = (props) => {

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();

    const { data: risks } = useSelector((state) => state.data);
    const { data, fetchingBackgroundData } = useSelector((state) => state.threats);
    const { data: tras } = useSelector((state) => state.tras);

    const { tableData, selectedTRA, onChangeTRA, handleOpenModal, createNewCaseLoading, disableActions, disableActionMeta, handleEditClick, readOnly = false, domainControlMapper } = props;

    // ** States
    const [threats, setThreats] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    useEffect(() => {
        if (history?.location?.state?.from === "search") {
            if (history?.location?.state?.caseData) {
                toggleDetailDialog(null, JSON.parse(history.location.state.caseData));
            }
        }
    }, [history?.location?.state])

    useEffect(() => {
        if (selectedTRA) {
            setThreats([...(data || []).filter((t) => t["traID"] === selectedTRA["caseID"])]);
        } else {
            setThreats([...(data || [])]);
        }
    }, [data, selectedTRA])

    const columns = readOnly ? [
        {
            id: "threatID",
            header: "Threat ID",
            sort: true,
            width: '120px',
            pinLeft: true,
            sortFn: (a, b) => (a.threatID || '').localeCompare(b.threatID || '')
        },
        {
            id: "solutionBuildingBlock",
            header: "Solution Building Block Name",
            sort: true,
            width: '250px',
            sortFn: (a, b) => (a.solutionBuildingBlock?.name || '').localeCompare(b.solutionBuildingBlock?.name || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solutionBuildingBlock?.name || ''}
                </>
            ),
        },
        {
            id: "solutionBuildingBlockID",
            header: "Solution Building Block ID",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a.solutionBuildingBlock?.solutionBuildingBlockID || '').localeCompare(b.solutionBuildingBlock?.solutionBuildingBlockID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solutionBuildingBlock?.solutionBuildingBlockID || ''}
                </>
            )
        },
        {
            id: "solutionName",
            header: "Solution Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.solution?.solutionName || '').localeCompare(b.solution?.solutionName || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solution?.solutionName || ''}
                </>
            ),
        },
        {
            id: "solutionID",
            header: "Solution ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.solution?.solutionID || '').localeCompare(b.solution?.solutionID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solution?.solutionID || ''}
                </>
            ),
        },
        {
            id: "projectName",
            header: "Project Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.project?.name || '').localeCompare(b.project?.name || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.name || ''}
                </>
            ),
        },
        {
            id: "projectID",
            header: "Project ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.project?.projectID || '').localeCompare(b.project?.projectID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.projectID || ''}
                </>
            ),
        },
        {
            flex: 1,
            id: "title",
            header: "Threat Title",
            sort: true,
            minWidth: '200px',
            searchable: true,
            sortFn: (a, b) => (a.title || '').localeCompare(b.title || '')
        },
        {
            flex: 1,
            id: "description",
            header: "Threat Description",
            sort: true,
            minWidth: '250px',
            sortFn: (a, b) => (a.description || '').localeCompare(b.description)
        },
        {
            id: "threatCategory",
            header: "Threat Categorisation",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a.threatCategory || '').localeCompare(b.threatCategory || '')
        },
        {
            id: "riskCategory",
            header: "CIA Impact",
            sort: true,
            width: '120px',
            sortFn: (a, b) => (a.riskCategory || '').localeCompare(b.riskCategory || '')
        },
        {
            id: "priority",
            header: "Priority",
            sort: true,
            width: '120px',
            sortFn: (a, b) => {
                const getOrder = (priority) => {
                    // Assign weights based on sequence
                    switch (priority) {
                        case 'Extreme':
                            return 4;
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };
                const orderA = getOrder(a.priority || '');
                const orderB = getOrder(b.priority || '');
                return orderA - orderB;
            }
        },
        {
            id: "recommendation",
            header: "Recommendation",
            width: '150px',
        }
    ] : [
        {
            id: "threatID",
            header: "Threat ID",
            sort: true,
            width: '120px',
            pinLeft: true,
            sortFn: (a, b) => (a.threatID || '').localeCompare(b.threatID || '')
        },
        {
            id: "solutionBuildingBlock",
            header: "Solution Building Block Name",
            sort: true,
            width: '250px',
            sortFn: (a, b) => (a.solutionBuildingBlock?.name || '').localeCompare(b.solutionBuildingBlock?.name || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solutionBuildingBlock?.name || ''}
                </>
            ),
        },
        {
            id: "solutionBuildingBlockID",
            header: "Solution Building Block ID",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a.solutionBuildingBlock?.solutionBuildingBlockID || '').localeCompare(b.solutionBuildingBlock?.solutionBuildingBlockID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solutionBuildingBlock?.solutionBuildingBlockID || ''}
                </>
            )
        },
        {
            id: "solutionName",
            header: "Solution Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.solution?.solutionName || '').localeCompare(b.solution?.solutionName || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solution?.solutionName || ''}
                </>
            ),
        },
        {
            id: "solutionID",
            header: "Solution ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.solution?.solutionID || '').localeCompare(b.solution?.solutionID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.solution?.solutionID || ''}
                </>
            ),
        },
        {
            id: "projectName",
            header: "Project Name",
            sort: true,
            width: '150px',
            sortFn: (a, b) => (a.project?.name || '').localeCompare(b.project?.name || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.name || ''}
                </>
            ),
        },
        {
            id: "projectID",
            header: "Project ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.project?.projectID || '').localeCompare(b.project?.projectID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.projectID || ''}
                </>
            ),
        },
        {
            flex: 1,
            id: "title",
            header: "Threat Title",
            sort: true,
            minWidth: '250px',
            searchable: true,
            sortFn: (a, b) => (a.title || '').localeCompare(b.title || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["title"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 1,
            id: "description",
            header: "Threat Description",
            sort: true,
            minWidth: '250px',
            sortFn: (a, b) => (a.description || '').localeCompare(b.description),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["description"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "threatCategory",
            header: "Threat Categorisation",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a.threatCategory || '').localeCompare(b.threatCategory || '')
        },
        {
            id: "riskCategory",
            header: "CIA Impact",
            sort: true,
            width: '120px',
            sortFn: (a, b) => (a.riskCategory || '').localeCompare(b.riskCategory || '')
        },
        {
            id: "priority",
            header: "Priority",
            sort: true,
            width: '120px',
            sortFn: (a, b) => {
                const getOrder = (priority) => {
                    // Assign weights based on sequence
                    switch (priority) {
                        case 'Extreme':
                            return 4;
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };
                const orderA = getOrder(a.priority || '');
                const orderB = getOrder(b.priority || '');
                return orderA - orderB;
            }
        },
        {
            id: "recommendation",
            header: "Recommendation",
            width: '150px',
        },
        {
            id: 'actions',
            header: '',
            sortable: false,
            pinRight: true,
            disableColumnFilter: true,
            width: "100px",
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        title="edit"
                        onClick={(e) => handleEditClick(row.caseID, row.status, row)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    const handleGenerateReport = () => {
        if (selectedTRA) {

            function onSuccess() {
                history.push({
                    pathname: '/it-security/reports',
                    state: {
                        type: ReportTypes.TRA,
                    }
                });
            }

            let risksData = risks?.filter(
                (r) => (
                    (r.caseID === selectedTRA['aRiskID'])
                    || (r.caseID === selectedTRA['cRiskID'])
                    || (r.caseID === selectedTRA['iRiskID'])
                )
            )
            generateTRAReport(selectedTRA, risksData, threats, domainControlMapper, onSuccess)
        }
    }

    return (
        <>

            {
                !!selectedTRA && <Box sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomLoadingButton
                        success
                        onClick={handleGenerateReport}
                    >
                        Download Report
                    </CustomLoadingButton>
                </Box>
            }

            <Table
                tableData={readOnly ? tableData : threats}
                columns={columns}
                tableTitle={
                    <Autocomplete
                        fullWidth
                        disablePortal
                        size="small"
                        id="tra-autocomplete"
                        sx={{ maxWidth: "300px" }}
                        getOptionLabel={(option) => option.name}
                        value={selectedTRA}
                        options={
                            (tras || []).sort((a, b) => -(b.name || '').localeCompare(a.name || ''))
                        }
                        onChange={(e, value) => {
                            onChangeTRA(value);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select TRA" />}
                    />
                }
                {...(!readOnly && {
                    actionButtons:
                        <>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <MuiLoadingButton
                                    aria-label="create new case"
                                    title="create new case"
                                    as={IconButton}
                                    size="small"
                                    onClick={handleOpenModal}
                                    loading={createNewCaseLoading}
                                    disabled={disableActions}
                                >
                                    <AddCircleIcon />
                                </MuiLoadingButton>
                                <MuiLoadingButton
                                    aria-label="refresh"
                                    title="refresh"
                                    as={IconButton}
                                    size="small"
                                    onClick={(e) => {
                                        e?.preventDefault();
                                        dispatch(toggleAllowFetch(true));
                                    }}
                                    loading={fetchingBackgroundData}
                                    disabled={disableActions}
                                >
                                    <SyncIcon />
                                </MuiLoadingButton>
                            </Box>
                        </>
                })}
            />

            {
                !readOnly && <ThreatDetailDialog
                    open={dialogOpen}
                    toggle={toggleDetailDialog}
                    data={dialogData}
                    handleEditClick={handleEditClick}
                    domainControlMapper={domainControlMapper}
                />
            }
        </>
    )
}

export default ThreatTable;