import React from "react";
import {
    CustomModal,
    VectorWrapper,
    TextBox,
    ModalHeading,
    VectorImage,
    InfoList,
    Wrapper,
    StyledCloseIcon
} from "./InfoModal.styled";

function InfoModal({ open, handleClose, imgSrc, title, list }) {
    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Wrapper>
                <StyledCloseIcon onClick={handleClose} />
                <VectorWrapper elevate={24}>
                    <VectorImage
                        src={imgSrc}
                        maxWidth="200px"
                        height="188px"
                        translateY="17px"
                    />
                </VectorWrapper>

                <TextBox>
                    <ModalHeading>{title}</ModalHeading>
                    <InfoList>
                        {list.map((item, index) => {
                            return <li key={index}>{item}</li>;
                        })}
                    </InfoList>
                </TextBox>
            </Wrapper>
        </CustomModal>
    );
}

export default InfoModal;
