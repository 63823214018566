import { Radio, TextField } from "@mui/material";
import styled from "styled-components";

export const RecHeading = styled.h5`
    font-weight: 500;
    font-size: 20px;

    width: ${({ maxWidth }) => (maxWidth ? "70%" : "")};
    width: ${({ bigWidth }) => (bigWidth ? "75%" : "")};
    width: ${({ MdWidth }) => (MdWidth ? "60%" : "")};

    padding: ${({ padding }) => (padding ? "52px 0px 0 42px" : "")};
    padding: ${({ finalRisk }) => (finalRisk ? "40px 134px 16px 51px" : "")};
    padding: ${({ actionsPad }) => (actionsPad ? "31px 0 32px 51px" : "")};

    @media (max-width: 599.98px) {
        font-size: 18px;
        width: ${({ maxWidth, MdWidth }) => (maxWidth || MdWidth ? "90%" : "")};
        padding: ${({ padding }) => (padding ? "16px 0px 0 17px" : "")};
        padding: ${({ riskPad }) => (riskPad ? "0 0 0 16px" : "")};
        padding: ${({ actionsPad }) => (actionsPad ? "34px 0 16px 17px" : "")};
        padding: ${({ finalRisk }) => (finalRisk ? "34px 6px 16px 16px" : "")};
    }
`;

export const StyledQuestion = styled.p`
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
    font-size: 17px;
    padding-left: 40px;
    margin-right: 91px;

    @media (max-width: 599.98px) {
        margin-right: 0px;
        font-size: 15px;
    }
`;

export const RecContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    min-height:48px;

    label {
        margin: 0 !important;
    }

    label:not(:last-of-type) {
        margin-right: 50px !important;
    }

    @media (max-width: 991.98px) {
        gap: 17px;
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 599.98px) {
        label:not(:last-of-type) {
            margin-right: 15px !important;
        }
    }
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;

    .MuiFormControlLabel-label {
        color: ${({ theme }) =>
            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    }

    @media (max-width: 599.98px) {
        justify-content: space-between;
        width: 100%;
    }

    @media (max-width: 320px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        .MuiFormGroup-root {
            padding-left: 25px;
        }
    }
`;

export const StyledInput = styled(TextField)`
    box-sizing: border-box;
    min-height: 48px;
    width: 340px;
    max-height: 120px;
    border-radius: 4px;
    border: 1px solid #828282;
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};

    &:focus {
        outline: none;
    }

    @media (max-width: 991.98px) {
        align-self: flex-start;
        /* margin-left: 20px; */
    }

    @media (max-width: 599.98px) {
        width: 100%;
    }
`;

export const StyledContent = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 41.5px;
    }

    @media (max-width: 599.98px) {
        &:not(:last-of-type) {
            margin-bottom: 17px;
        }
    }
`;

export const StyledRadio = styled(Radio)`
    &.Mui-checked {
        color: #2f80ed !important;
    }
`;
