import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@mui/material";
import QuestionTitle from "./QuestionTitle";
import styled from "styled-components";
import TextValidationTextField from "./TextValidationTextField";
import { useEffect, useState } from "react";
import DynamicDropdownList from "./DynamicDropDownList";
import DateField from "./DateField";

const Label11 = styled.div`
  top: 0%;
  left: 0%;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding-left: 46px;
  font-size: 16px;
`;
const Label2 = styled.div`
  position: relative;
`;
const MainQuestion = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
const FrameChild = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#ccc" : "#000")};
  width: 32px;
  height: 32px;
  z-index: 0;
`;
const Div = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 8px;
  left: 2px;
  letter-spacing: 0.4px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 15px;
  flex-shrink: 0;
  z-index: 1;
  color: #fff;
`;
const EllipseParent = styled.div`
  width: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const Title1 = styled.b`
  flex: 1;
  position: relative;
  font-size: 17px;
  letter-spacing: 0.4px;
  line-height: 20px;
  text-align: left;
`;
const Subquestionwithnumber1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
`;
const Inputoutlined1 = styled(TextField)`
  border: none;
  background-color: transparent;
`;
const Selectoutlined = styled(FormControl)`
  align-self: stretch;
`;
const InputoutlinedParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const Inputoutlined11 = styled(TextField)`
  border: none;
  background-color: transparent;
  width:100%;
`;
const DetailGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 21px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
`;
const DetailsSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
`;
const Divider = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.5px solid rgba(255, 255, 255, 0.24);
  box-sizing: border-box;
  height: 0.5px;
`;
const Subquestionwithnumber11 = styled.div`
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
`;
const InputoutlinedGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 21px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
`;
const Inputoutlined4 = styled(TextField)`
  border: none;
  background-color: transparent;
  align-self: stretch;
`;
const SystemGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const DetailsSectionParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  text-align: center;
  font-family: Rubik;
`;
const CardRoot = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 33px 37px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
  @media screen and (max-width: 420px) {
    padding:18px;
  }
`;

const NewInformationAssetCard = ({ question, handleTextChange }) => {

  return (
    <CardRoot>
      <MainQuestion>
        <QuestionTitle
          prop={question["qNum"]}
          defineNewAsset={question["title"]}
          questionTitlePosition="unset"
          questionTitleWidth="unset"
          ellipseDivBackgroundColor="#4d9f3f"
          defineNewAssetFlex="unset"
          defineNewAssetFontSize="20px"
          defineNewAssetDisplay="flex"
          defineNewAssetAlignItems="center"
          defineNewAssetWidth="205px"
          defineNewAssetFlexShrink="0"
        />
        <Label2>
          <Label11>Provide a comprehensive level of detail to ensure appropriate security controls are applied to safeguard your business critical assets.</Label11>
        </Label2>
      </MainQuestion>
      <DetailsSectionParent>
        {
          question["questions"] && (
            question["questions"].map((q, parentIndex) => {
              return <DetailsSection key={parentIndex}>
                <Subquestionwithnumber11>
                  <EllipseParent>
                    <FrameChild />
                    <Div>{q["qNum"]}</Div>
                  </EllipseParent>
                  <Title1>{q["qHeading"]}</Title1>
                </Subquestionwithnumber11>
                <Grid container spacing={2} style={{ paddingLeft: "26px" }}>
                  {
                    q["subQuestions"] && (
                      q["subQuestions"].map((subQ, index) => {

                        return <Grid item xs={12} md={6} key={subQ["qNum"]}>
                          {
                            subQ['type'] === "text" && (
                              <TextValidationTextField parentIndex={parentIndex} index={index} data={subQ} value={subQ["qAnswer"]} onChange={handleTextChange} />
                            )
                          }
                          {
                            subQ['type'] === "option" && (
                              <DynamicDropdownList placeHolder={subQ["placeholder"]} label={`${subQ["qNum"]}. ${subQ["qHeading"]}`} data={subQ["qAvailableAnswers"]} value={subQ['qAnswer']} parentIndex={parentIndex} index={index} onChange={handleTextChange} />
                            )
                          }
                          {
                            subQ['type'] === "date" && (
                              <DateField parentIndex={parentIndex} index={index} data={subQ} value={subQ["qAnswer"]} onChange={handleTextChange} />
                            )
                          }
                        </Grid>
                      })
                    )
                  }
                </Grid>
              </DetailsSection>
            })
          )
        }
      </DetailsSectionParent>
    </CardRoot>
  );
};

export default NewInformationAssetCard;
