import { getCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, SolutionBuildingBlock } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { fetchData as fetchThreatData } from './threat.action';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_SOLUTION_BUILDING_BLOCK_ALLOW_FETCH', payload: allow };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_SOLUTION_BUILDING_BLOCK_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractSolutionBuildingBlockDataFromCaseFile(caseFile) {
  let newSolutionBuildingBlock = {};

  let solutionBuildingBlockDetail = caseFile["case-file"]["case-data"]["solutionBuildingBlockData"];
  let solutionBuildingBlockID = caseFile["case-file"]["case-data"]["solutionBuildingBlockID"];
  if (solutionBuildingBlockDetail) {
    newSolutionBuildingBlock = { ...solutionBuildingBlockDetail };
  }

  newSolutionBuildingBlock["date"] = caseFile["case-started-at"] || 0;
  newSolutionBuildingBlock["status"] = caseFile["case-status"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);

  newSolutionBuildingBlock["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  newSolutionBuildingBlock.caseID = caseFile["case-id"];
  // newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(solutionBuildingBlockID);
  newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(caseFile["case-id"]);
  return newSolutionBuildingBlock;
}

export const getSolutionBuildingBlockData = async (caseFile) => {
  let newSolutionBuildingBlock = extractSolutionBuildingBlockDataFromCaseFile(caseFile);
  newSolutionBuildingBlock.caseID = caseFile["case-id"];
  return newSolutionBuildingBlock;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().solutionBuildingBlocks; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;
    const { data: solutions } = getState().solutions;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-solutionBuildingBlocks');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_SUCCESS', payload: { data: cachedData } });

      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let solutionBuildingBlocks = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, SolutionBuildingBlock, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, SolutionBuildingBlock, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const solutionBuildingBlockData = [...closedData, ...openedData];

        if (solutionBuildingBlockData?.length > 0) {
          solutionBuildingBlockData.forEach((solutionBuildingBlock, index) => {
            let newSolutionBuildingBlock = {};

            let solutionBuildingBlockDetail = solutionBuildingBlock["case-file"]["case-data"]["solutionBuildingBlockData"];
            let solutionBuildingBlockID = solutionBuildingBlock["case-file"]["case-data"]["solutionBuildingBlockID"];
            if (solutionBuildingBlockDetail) {
              newSolutionBuildingBlock = { ...solutionBuildingBlockDetail };
            }

            newSolutionBuildingBlock["date"] = solutionBuildingBlock["case-started-at"] || 0;
            newSolutionBuildingBlock["status"] = solutionBuildingBlock["case-status"] || 0;
            let cDate = new Date(solutionBuildingBlock["case-started-at"]);

            newSolutionBuildingBlock["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            newSolutionBuildingBlock.caseID = solutionBuildingBlock["case-id"];
            // newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(solutionBuildingBlockID);
            newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(solutionBuildingBlock["case-id"]);

            // Find and add solution data
            if (solutions?.length > 0) {
              let foundSolution = solutions.find((s) => s.caseID === newSolutionBuildingBlock.solutionID);
              if (foundSolution) {
                newSolutionBuildingBlock.solution = foundSolution;
                newSolutionBuildingBlock.solutionID = foundSolution.caseID;

                // Find and add project data
                if (projects?.length > 0) {
                  let foundProject = projects.find((p) => p.caseID === foundSolution.projectID);
                  if (foundProject) {
                    newSolutionBuildingBlock.project = foundProject;
                    newSolutionBuildingBlock.projectID = foundProject.caseID;
                  }
                }
              }
            }

            if (newSolutionBuildingBlock?.name) {
              solutionBuildingBlocks.push(newSolutionBuildingBlock);
            }
          })

          solutionBuildingBlocks.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_SUCCESS', payload: { data: solutionBuildingBlocks }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'SOLUTION_BUILDING_BLOCK_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-solutionBuildingBlocks', solutionBuildingBlocks);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_SOLUTION_BUILDING_BLOCK_FETCH_COUNTER' });

          // dispatch fetch threat data if it's for the first time
          {
            const { fetchCounter } = getState().threats;
            if (fetchCounter === 0 && solutionBuildingBlockData?.length > 0) {
              dispatch(fetchThreatData(keycloak, 0));
            }
          }
        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().solutionBuildingBlocks; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;
    const { data: solutions } = getState().solutions;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-solutionBuildingBlocks');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let solutionBuildingBlocks = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, SolutionBuildingBlock, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, SolutionBuildingBlock, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const solutionBuildingBlockData = [...closedData, ...openedData];

      if (solutionBuildingBlockData) {
        solutionBuildingBlockData.forEach((solutionBuildingBlock, index) => {
          let newSolutionBuildingBlock = {};

          let solutionBuildingBlockDetail = solutionBuildingBlock["case-file"]["case-data"]["solutionBuildingBlockData"];
          let solutionBuildingBlockID = solutionBuildingBlock["case-file"]["case-data"]["solutionBuildingBlockID"];
          if (solutionBuildingBlockDetail) {
            newSolutionBuildingBlock = { ...solutionBuildingBlockDetail };
          }

          newSolutionBuildingBlock["date"] = solutionBuildingBlock["case-started-at"] || 0;
          newSolutionBuildingBlock["status"] = solutionBuildingBlock["case-status"] || 0;
          let cDate = new Date(solutionBuildingBlock["case-started-at"]);

          newSolutionBuildingBlock["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          newSolutionBuildingBlock.caseID = solutionBuildingBlock["case-id"];
          // newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(solutionBuildingBlockID);
          newSolutionBuildingBlock.solutionBuildingBlockID = beautifyUniqueID(solutionBuildingBlock["case-id"]);

          // Find and add solution data
          if (solutions?.length > 0) {
            let foundSolution = solutions.find((s) => s.caseID === newSolutionBuildingBlock.solutionID);
            if (foundSolution) {
              newSolutionBuildingBlock.solution = foundSolution;
              newSolutionBuildingBlock.solutionID = foundSolution.caseID;

              // Find and add project data
              if (projects?.length > 0) {
                let foundProject = projects.find((p) => p.caseID === foundSolution.projectID);
                if (foundProject) {
                  newSolutionBuildingBlock.project = foundProject;
                  newSolutionBuildingBlock.projectID = foundProject.caseID;
                }
              }
            }
          }

          if (newSolutionBuildingBlock?.name) {
            solutionBuildingBlocks.push(newSolutionBuildingBlock);
          }
        })

        if (cachedData?.length > 0) {
          solutionBuildingBlocks = [...cachedData, ...solutionBuildingBlocks];
        }

        solutionBuildingBlocks.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_SUCCESS', payload: { data: solutionBuildingBlocks }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'SOLUTION_BUILDING_BLOCK_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_SOLUTION_BUILDING_BLOCK_FETCH_COUNTER' });

          // dispatch fetch threat data if it's for the first time
          {
            const { fetchCounter } = getState().threats;
            if (fetchCounter === 0) {
              dispatch(fetchThreatData(keycloak, 0));
            }
          }
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-solutionBuildingBlocks', solutionBuildingBlocks);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG error = ', error)
      dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_ERROR', payload: error.message });
    }
  };
};

export const removeEmptyCase = (keycloak) => {
  return async (dispatch, getState) => {
    try {
      let cachedData = await getCachedData(keycloak.token, 'cached-solutionBuildingBlocks');
      if (cachedData?.length > 0) {
        cachedData = cachedData.filter((c) => c["name"]);
        cachedData = cachedData.map((c) => ({ ...c }));
      }

      cachedData.sort((a, b) => b["date"] - a["date"]);

      dispatch({ type: 'FETCH_SOLUTION_BUILDING_BLOCK_DATA_SUCCESS', payload: { data: cachedData }, });

      // Cache the fetched data
      cacheData(keycloak.token, 'cached-solutionBuildingBlocks', cachedData);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  }
}