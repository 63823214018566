import { getCaseDocumentContent, getCaseDocumentList, getCaseFileData, getDocumentProcessCase, startDocumentProcess } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { getFileName, getFileType } from "./utility";

export const toggleAllowFetch = (allow) => {
  return { type: 'SPO_TOGGLE_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'SPO_TOGGLE_LOADING', payload: loading };
};

export const toggleDocumentContentFetching = (fetching) => {
  return { type: 'SPO_TOGGLE_DOCUMENT_CONTENT_FETCHING', payload: fetching };
};

export const toggleCreateNewCaseLoading = (loading) => {
  return { type: 'SPO_TOGGLE_CREATE_NEW_CASE_LOADING', payload: loading };
};

export const toggleGenerateDocumentLoading = (loading, documentID = null) => {
  return { type: 'SPO_TOGGLE_GENERATE_DOCUMENT_LOADING', payload: { loading, documentID } };
};

const cachedKeys = {
  DOCUMENT_CASE_DATA: 'cached-documentCaseData',
  DOCUMENT_LIST: 'cached-documentList',
  DOCUMENT_FILES: 'cached-documentFiles',
}

export const fetchDocumentData = (keycloak) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().documents; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, cachedKeys.DOCUMENT_CASE_DATA);
      const cachedDocumentListData = await getCachedData(keycloak.token, cachedKeys.DOCUMENT_LIST);
      const cachedDocumentFilesData = await getCachedData(keycloak.token, cachedKeys.DOCUMENT_FILES);

      if (cachedData && cachedDocumentListData && allowFetch === false) {
        dispatch({
          type: 'SPO_FETCH_DATA_SUCCESS',
          payload: {
            documentCaseData: cachedData,
            documentList: cachedDocumentListData,
            documentFiles: cachedDocumentFilesData,
          }
        });
      } else {

        // Enable loading
        dispatch(toggleLoading(true));

        let response = await getDocumentProcessCase("open");
        // if (!(response?.data?.instances?.length > 0)) {
        //   response = await getDocumentProcessCase("closed");
        // }

        // if not case found generate new case
        if (!(response?.data?.instances?.length > 0)) {
          dispatch(toggleCreateNewCaseLoading(true));
          await startDocumentProcess();
          return;
        }

        let caseData = response.data.instances[0];

        let response2 = await getCaseDocumentList(caseData['case-id']);

        let documentList = [];
        let documentFiles = {};
        if (response2?.success && response2?.data?.length > 0) {
          documentList = response2.data.map((doc) => {
            return {
              ...doc,
              isDocumentGenerated: false,
              filename: getFileName(doc.name),
              type: getFileType(doc.name),
              fileContentKey: null,
              fileContent: null,
            };
          })
        }

        // Dispatch the fetched data
        dispatch({
          type: 'SPO_FETCH_DATA_SUCCESS',
          payload: {
            documentCaseData: caseData,
            documentList: documentList,
            documentFiles: documentFiles,
          }
        });

        // Cache the fetched data
        cacheData(keycloak.token, cachedKeys.DOCUMENT_CASE_DATA, caseData);
        cacheData(keycloak.token, cachedKeys.DOCUMENT_LIST, documentList);
        cacheData(keycloak.token, cachedKeys.DOCUMENT_FILES, documentFiles);

        dispatch(toggleAllowFetch(false));
        dispatch(toggleLoading(false));

        // dispatch(fetchDocumentContentData(keycloak, caseData['case-id']));

      }
    } catch (error) {
      console.error('DEBUG error = ', error)
      dispatch(toggleLoading(false));
      dispatch({ type: 'SPO_FETCH_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchDocumentContentData = (keycloak, caseID) => {
  return async (dispatch, getState) => {

    try {
      // Enable loading
      dispatch(toggleDocumentContentFetching(true));

      let response1 = await getCaseFileData(CONTAINER_ID, keycloak.token, caseID);

      let documentList = [];
      let documentFiles = {};

      if (response1?.data?.documentList?.length > 0) {
        documentList = response1.data.documentList.map((doc) => {
          const fileContentKey = `FILE${doc['id']}`
          const isDocumentGenerated = response1.data.hasOwnProperty(fileContentKey);
          const fileContent = response1.data[fileContentKey];
          if (isDocumentGenerated) {
            documentFiles[fileContentKey] = fileContent;
          }
          return {
            ...doc,
            isDocumentGenerated: isDocumentGenerated,
            filename: getFileName(doc.name),
            type: getFileType(doc.name),
            fileContentKey: fileContentKey,
            fileContent: fileContent,
          };
        });
      }

      // Dispatch the fetched data
      dispatch({
        type: 'SPO_FETCH_DOCUMENT_CONTENT_DATA_SUCCESS',
        payload: {
          documentList: documentList,
          documentFiles: documentFiles,
        }
      });

      // Cache the fetched data
      cacheData(keycloak.token, cachedKeys.DOCUMENT_LIST, documentList);
      cacheData(keycloak.token, cachedKeys.DOCUMENT_FILES, documentFiles);

      // dispatch(toggleDocumentContentFetching(false));

    } catch (error) {
      console.error('DEBUG error = ', error)
      // dispatch(toggleDocumentContentFetching(false));
      dispatch({ type: 'SPO_FETCH_DOCUMENT_CONTENT_DATA_ERROR', payload: error.message });
    }
  };
};

export const getDocumentContentData = (keycloak, caseID, documentID) => {
  return async (dispatch, getState) => {
    try {
      let res = await getCaseDocumentContent(caseID, `FILE${documentID}`);

      if (res.data && res.data['org.kie.server.api.model.type.JaxbByteArray'] && res.data['org.kie.server.api.model.type.JaxbByteArray']['value']) {
        let documentContent = res.data['org.kie.server.api.model.type.JaxbByteArray']['value'];
        dispatch(updateDocumentContentData(keycloak, documentID, documentContent));
      }
    } catch (error) {
      console.error('DEBUG error = ', error);
    }
  };
};

export const updateDocumentContentData = (keycloak, documentID, documentContent) => {
  return async (dispatch, getState) => {
    const { documentFiles, documentList } = getState().documents; // Get the allowFetch value from the state
    try {

      let newDocumentList = [...(documentList || [])];
      let newDocumentFiles = documentFiles || {};

      newDocumentList = newDocumentList.map((doc) => {
        if (doc['id'] === documentID) {
          const fileContentKey = `FILE${doc['id']}`
          const isDocumentGenerated = !!documentContent;
          const fileContent = documentContent;
          if (isDocumentGenerated) {
            newDocumentFiles[fileContentKey] = fileContent;
          }
          if (documentContent) {
            delete newDocumentFiles[fileContentKey];
          }
          return {
            ...doc,
            isDocumentGenerated: isDocumentGenerated,
            filename: getFileName(doc.name),
            type: getFileType(doc.name),
            fileContentKey: fileContentKey,
            fileContent: fileContent,
          };
        } else {
          return { ...doc }
        }
      });

      // Dispatch the updated data
      dispatch({
        type: 'SPO_UPDATE_DOCUMENT_CONTENT_DATA_SUCCESS',
        payload: {
          documentList: newDocumentList,
          documentFiles: newDocumentFiles,
        }
      });

      // Cache the fetched data
      cacheData(keycloak.token, cachedKeys.DOCUMENT_LIST, newDocumentList);
      cacheData(keycloak.token, cachedKeys.DOCUMENT_FILES, newDocumentFiles);

      // Set the document generate document loading false
      dispatch(toggleGenerateDocumentLoading(false));

    } catch (error) {
      console.error('DEBUG error = ', error);
      dispatch(toggleGenerateDocumentLoading(false));
    }
  };
};