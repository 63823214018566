import { Alert, Box, Chip, Grid, Grow } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./RiskAssessment.styled";
import E8QuestionsStepper from "../../components/E8QuestionStepper/E8QuestionStepper";
import QuestionProgress from "../../components/QuestionProgress/QuestionProgress";
import logo from "../../../../images/logo0.2.png";
import { useKeycloak } from "@react-keycloak/web";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function RiskAssessmentDetail(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const goBack = () => history.goBack();
    const [activeIndex, setActiveIndex] = useState(0);
    const [processID, setProcessID] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState(null);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);
    // const baseUrl="http://localhost:8080/kie-server/services"
    // const containerID="asde8_1.0.0-SNAPSHOT"

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    useEffect(() => {
        try {
            const data = window.sessionStorage.getItem('RISK_ASSESSMENT_DETAIL');
            if (data !== undefined) {

                setQuestions(JSON.parse(data));
            }
        } catch (error) {
            goBack();
        }
        setFetching(false);

    }, []);



    const handleOneAnswer = useCallback((val, parentIndex, index) => {

    }, [questions]);

    function getDataToUpload() {
        window.sessionStorage.setItem('RISK_ASSESSMENT_INDEX', JSON.stringify(activeIndex + 1));
        setActiveIndex(activeIndex + 1);
    }

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `3rd Party Risk Assessment Detail - ${questions[activeIndex]?.title}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [activeIndex, dispatch, questions])

    if (!fetching) {
        content = (
            <Fragment>
                {
                    questions.length === 5 ?
                        <Grid container spacing={{ xs: 1, md: 2 }}>
                            <Grid item xs={12}>
                                <Box sx={{ paddingTop: "40px" }}>
                                    <QuestionProgress questionNumber={activeIndex + 1} totalNumber={questions.length - 1} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ width: "100%", position: "relative" }}>
                                    <E8QuestionsStepper readOnly={true} questions={[questions[activeIndex]]} qIndex={activeIndex} handleAnswer={handleOneAnswer}></E8QuestionsStepper>

                                    <ActionsWrapper hasOne>
                                        {
                                            activeIndex === 0 ? "" : <Button $red onClick={(e) => setActiveIndex(activeIndex - 1)} style={{ marginRight: "10px" }}>
                                                back
                                            </Button>
                                        }
                                        {
                                            questions.length === activeIndex + 1 ? "" : <Button $success onClick={(e) => getDataToUpload()}>
                                                next
                                            </Button>
                                        }

                                    </ActionsWrapper>
                                </Box>
                            </Grid>
                        </Grid> : ""
                }

                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>3rd Party Risk Assessment Details - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    3rd Party Risk Assessment
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default RiskAssessmentDetail;
