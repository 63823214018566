import styled from "styled-components";

export const DNDNode = styled.div`
  /* height: 100px; */
  height: 60px;
  /* width: 45%; */
  width: 60px;
  padding: 4px;
  border: 0px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  cursor: grab;
`