import { useState } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

function TruncateText(props) {

  const { text = "", maxChars = 75, onClickReadMore } = props;
  const [show, setShow] = useState(false);

  const truncatedDescription = text.slice(0, maxChars);
  const showReadMore = text.length > maxChars;

  const handleShowClick = () => {
    setShow(true);
  };

  return (
    <>
      {!show && showReadMore ? (
        <Box>
          {truncatedDescription.trim()}{'... '}
          <Button
            onClick={(e) => {
              if (onClickReadMore) {
                onClickReadMore()
              } else {
                handleShowClick();
              }
            }}
            sx={{ p: 0, textTransform: "lowercase", lineHeight: 1 }}
          >
            Read More
          </Button>
        </Box>
      ) : (
        <Box>{text}</Box>
      )}
    </>
  );
}

export default TruncateText;