import styled from "styled-components";
export const GraphContainer = styled.div`
    & canvas {
        width: 40%;
        height: 300px;
    }
    @media (max-width: 599.98px) {
        & canvas {
            width: 100% !important;
            height: 300px;
        }
    }
`;