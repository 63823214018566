// dataActions.js

import { getCompletedOrganization, getProcessVariables } from "../pages/Dashboard/InformationAPI";
import { getCase, getCaseFileData, getDMN } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, RiskAssessment } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_ALLOW_FETCH', payload: allow };
};

export const toggleAllowRiskUpdate = (allow) => {
  return { type: 'TOGGLE_ALLOW_RISK_UPDATE', payload: allow };
};

export const toggleUpdateRisk = (riskID) => {
  return { type: 'TOGGLE_RISK_UPDATE', payload: riskID };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

export const setOrganisationDetail = (orgData) => {
  return { type: 'SET_ORGANISATION_DETAIL', payload: orgData };
};

export const setOrganisationList = (data) => {
  return { type: 'SET_ORGANISATION_LIST', payload: data };
};

export const setSelectedOrganisation = (selectedOrg) => {
  return { type: 'SET_SELECTED_ORGANISATION', payload: selectedOrg };
};

export const setProjectDetails = (projData) => {
  return { type: 'SET_PROJECT_DATA', payload: projData };
};

const riskSorting = (a, b) => {
  const getOrder = (rating) => {
    if (!rating) return 0;
    const match = rating.match(/(\w+)-(\d+)/);
    if (match) {
      const category = match[1];
      const level = parseInt(match[2]);

      // Assign weights based on sequence
      switch (category) {
        case 'Extreme':
          return level + 400;
        case 'High':
          return level + 300;
        case 'Medium':
          return level + 200;
        case 'Low':
          return level + 100;
        default:
          return 0;
      }
    }
    return 0;
  };

  // const getOrder2 = (rating) => {
  //   if (!rating) return 0;
  //   const match = rating.match(/(\w+)-(\d+)/);
  //   if (match) {
  //     const category = match[1];
  //     const level = parseInt(match[2]);

  //     // Assign weights based on sequence
  //     switch (category) {
  //       case 'Extreme':
  //         return level + 40;
  //       case 'High':
  //         return level + 30;
  //       case 'Medium':
  //         return level + 20;
  //       case 'Low':
  //         return level + 10;
  //       default:
  //         return 0;
  //     }
  //   }
  //   return 0;
  // };

  const orderA = getOrder(a.inherentRiskRating || '');
  // const orderA2 = getOrder2(a.residualRiskRating || '');
  const orderB = getOrder(b.inherentRiskRating || '');
  // const orderB2 = getOrder2(b.residualRiskRating || '');

  // return (orderB + orderB2) - (orderA + orderA2);
  return (orderB) - (orderA);
}

export const extractCaseDataFromCaseFile = (risk, domains = []) => {
  let newRisk = {};

  let riskDetail = risk["case-file"]["case-data"]["riskData"];
  if (riskDetail) {
    newRisk = riskDetail;
  }

  // const temp_maturityScores = risk["case-file"]["case-data"]["MaturityScores"];
  const temp_maturityScores = risk["case-file"]["case-data"]["maturityScores"];
  if (temp_maturityScores) {
    // newRisk["maturityScores"] = JSON.parse(temp_maturityScores);
    newRisk["maturityScores"] = { ...temp_maturityScores };
  }

  newRisk["date"] = risk["case-started-at"] || 0;
  newRisk["status"] = risk["case-status"] || 0;
  let cDate = new Date(risk["case-started-at"]);

  newRisk["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  let applicableControls = risk["case-file"]["case-data"]?.applicableControls;
  if (applicableControls) {
    let controls = [];
    applicableControls.forEach(control => {
      if (control !== "") {
        try {

          let id = control.replace('m', '');
          let domainID = id.charAt(0);
          let sectionID = id.charAt(1);
          if (domainID === '1') {
            domainID = id.substring(0, 2);
            sectionID = id.charAt(2);
          }

          let filteredDomain = domains.find(domain => domain.domainID === domainID);
          if (filteredDomain) {
            let sections = filteredDomain.sections.find(section => section.sectionID === `${domainID}.${sectionID}`);

            if (sections) {
              let controlObj = sections?.controls.find(control => control.controlID.replaceAll('.', '') === id);
              if (controlObj) {
                controls.push(controlObj);
              }
            }
          }
          newRisk.controls = controls;
        } catch (err) {
          // console.log(err);
        }
      }
    });
  }
  return newRisk;
}

export const extractRisksGraphDataFromRisks = (risks, domains) => {

  let mitigationKeys = domains.reduce((acc, newValue) => {
    let keys = [];
    newValue.sections.forEach((section) => {
      let controlKeys = section.controls.map((c) => c.key);
      keys = keys.concat(controlKeys);
    })
    return [...acc, ...keys]
  }, []);

  let risksGraphData = risks
    .filter((risk) => {
      return risk['inherentRiskRating'] && risk['residualRiskRating'] && (!(risk["riskTreatment"] && (risk["riskTreatment"] === "Completed")))
    })
    .map((risk) => {

      let riskDetail = {
        "Detailed Description of Risk": risk["riskDescription"],
        "Motivation for Attacker": risk["attackerMotivation"],
        "CIA Class": risk["ciaClass"],
        "System": risk["system"],
        "Inherent Likelihood": risk["inherentLikelihood"],
        "Inherent Consequence": risk["inherentConsequence"],
        "Inherent Risk Rating": risk["inherentRiskRating"],
        "Threat Actors": risk["threatActors"],
        "Current Control Maturity": risk["maturityScore"],
        "Residual Likelihood": risk["residualLikelihood"],
        "Residual Consequence": risk["residualConsequence"],
        "Residual Risk Rating": risk["residualRiskRating"],
      };

      const tempRisk = {
        date: risk["date"],
        detail: riskDetail,
        riskID: risk["caseID"] || "",
        title: risk["riskTitle"] || "",
        residualRiskRating: risk?.residualRiskRating?.split("-")[0] || "",
      };

      let hasMulti = false;
      mitigationKeys.forEach((mKey) => {
        const mitigation = risk["controls"]?.find((m) => m?.key == mKey.replaceAll('.', '-'));

        if (mitigation) {
          const scoreKey = mKey.replaceAll('.', '').replace('m', "score");
          tempRisk[mKey] = risk["maturityScores"] && risk["maturityScores"][scoreKey] !== null ? risk["maturityScores"][scoreKey] : 1;
          if (tempRisk[mKey] !== null) {
            hasMulti = true;
          }
        } else {
          tempRisk[mKey] = null;
        }
      });
      return hasMulti ? tempRisk : null;
    }).filter((risk) => risk !== null);
  return risksGraphData;
}

export const updateData = (keycloak) => {
  return async (dispatch, getState) => {
    const { updatedCaseID } = getState().data;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-risks');
      const cachedGraphData = await getCachedData(keycloak.token, 'cached-graphData');
      const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');
      const closedRisks = await getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'closed');

      if (updatedCaseID) {
        // Make an API call after 2 seconds
        setTimeout(async () => {
          const riskReponse = await getCaseFileData(CONTAINER_ID, keycloak.token, updatedCaseID);
          if (riskReponse.success && riskReponse.data) {
            const risk = riskReponse.data;
            let newRisk = {};

            let riskDetail = risk["riskData"]["com.cisozen.RiskRegisterData"];
            if (riskDetail) {
              let riskObj = JSON.parse(riskDetail);
              newRisk = riskObj
            }

            // const temp_maturityScores = risk["MaturityScores"] || null;
            const temp_maturityScores = risk["maturityScores"] || null;
            if (temp_maturityScores) {
              // newRisk["maturityScores"] = JSON.parse(temp_maturityScores);
              newRisk["maturityScores"] = temp_maturityScores;
            }

            newRisk["date"] = risk["case-created-at"] || "";
            newRisk["status"] = risk["case-status"] || 0;
            let cDate = new Date(risk["case-started-at"]);
            var date = cDate.getDate() + "/" + (cDate.getMonth() + 1) + "/" + cDate.getFullYear() + ", " + cDate.getHours() > 12 ? cDate.getHours() / 2 : cDate.getHours() + ":" + cDate.getMinutes() + " " + (cDate.getHours() > 12 ? "pm" : "am");

            newRisk["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });
            //get domains


            let applicableControls = risk["ApplicableControls"];
            if (applicableControls) {
              let controls = [];
              let riskObj = JSON.parse(applicableControls);
              Object.keys(riskObj).map((cKey) => {
                if (riskObj[cKey] === "Yes") {
                  try {
                    let indexList = cKey.split('-');
                    let filteredDomain = cachedDomainData.find(domain => domain.domainID === indexList[0].replace('m', ''));
                    if (filteredDomain) {
                      let sections = filteredDomain.sections.find(section => section.sectionID === `${indexList[0].replace('m', '')}.${indexList[1]}`);

                      if (sections) {
                        let control = sections?.controls.find(control => control.key === cKey);
                        if (control) {
                          controls.push(control);
                        }
                      }
                    }
                  } catch (err) {

                  }
                }
              })
              newRisk.controls = controls;
            }
            newRisk.caseID = risk["case-id"];
            newRisk.isExpanded = false;
            // if (newRisk.riskTitle !== "") {
            //   cachedData.push(newRisk);
            // }

            // Find the index of the object with id: 2
            var index = cachedData.findIndex(obj => obj["case-id"] === updatedCaseID);
            newRisk["case-id"] = updatedCaseID;
            // Check if the index is valid
            if (index !== -1) {

              // Replace the object at that index with the new object
              cachedData[index] = newRisk;
            } else {
              cachedData.push(newRisk);
            }

            // cachedData.sort((a, b) => b["case-id"] - a["case-id"]);
            cachedData.sort(riskSorting);

            let risksGraph = extractRisksGraphDataFromRisks(cachedData, cachedDomainData);


            dispatch({ type: 'FETCH_DATA_SUCCESS', payload: cachedData, graphData: risksGraph });
            cacheData(keycloak.token, 'cached-risks', cachedData);
            cacheData(keycloak.token, 'cached-graphData', risksGraph);
            dispatch(toggleUpdateRisk(null));
          }

        }, 5000);
      }

    } catch (e) {
      console.log("Error caught while update data = ", e)
    }
  }
}

export const getRiskData = async (keycloak, risk) => {

  const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');

  let domains;
  if (cachedDomainData) {
    // If the data is in cache, use it
    domains = cachedDomainData;
  } else {
    // If the data is not in cache, make the API call
    const domainsObj = await getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains');
    domains = domainsObj.success ? domainsObj.data["dmnContext"]["domains"][0] : [];
    // Cache the data
    cacheData(keycloak.token, 'cached-domainData', domains);
  }

  let newRisk = extractCaseDataFromCaseFile(risk, domains);
  newRisk.caseID = risk["case-id"];
  newRisk.isExpanded = false;
  return newRisk;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().data; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-risks');
      const cachedGraphData = await getCachedData(keycloak.token, 'cached-graphData');
      const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');

      let domains;
      if (cachedDomainData) {
        // If the data is in cache, use it
        domains = cachedDomainData;
      } else {
        // If the data is not in cache, make the API call
        const domainsObj = await getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains');
        domains = domainsObj.success ? domainsObj.data["dmnContext"]["domains"][0] : [];
        // Cache the data
        cacheData(keycloak.token, 'cached-domainData', domains);
      }

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_DATA_SUCCESS', payload: cachedData, graphData: cachedGraphData });

      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let risks = [];
        const closedRisks = await getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'closed', 10, pageParam);
        const activeRisks = await getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'open', 10, pageParam);

        const closedData = closedRisks.success ? closedRisks.data?.instances : [];
        const openedData = activeRisks.success ? activeRisks.data?.instances : [];
        const riskData = [...closedData, ...openedData];

        if (riskData) {
          riskData.map((risk, index) => {
            let newRisk = {};

            // const temp_maturityScores = risk["case-file"]["case-data"]["MaturityScores"];
            const temp_maturityScores = risk["case-file"]["case-data"]["maturityScores"];
            if (temp_maturityScores) {
              // newRisk["maturityScores"] = JSON.parse(temp_maturityScores);
              newRisk["maturityScores"] = temp_maturityScores;
            }

            let riskDetail = risk["case-file"]["case-data"]["riskData"];
            if (riskDetail) {
              newRisk = riskDetail;
            }

            newRisk["date"] = risk["case-started-at"] || 0;
            newRisk["status"] = risk["case-status"] || 0;
            let cDate = new Date(risk["case-started-at"]);
            var date = cDate.getDate() + "/" + (cDate.getMonth() + 1) + "/" + cDate.getFullYear() + ", " + cDate.getHours() > 12 ? cDate.getHours() / 2 : cDate.getHours() + ":" + cDate.getMinutes() + " " + (cDate.getHours() > 12 ? "pm" : "am");

            newRisk["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });
            //get domains

            // if (domains.length === 0) {
            //   let caseData = risk["case-file"]["case-data"];
            //   let domainKeys = Object.keys(caseData).filter(key => key.startsWith("Domain"));

            //   domainKeys.map((key) => {
            //     let domainData = JSON.parse(caseData[key]);
            //     try {
            //       let domainNumber = key.replaceAll("Domain", "");
            //       let mitigationKey = `m${domainNumber}Data`;
            //       let mitigationData = JSON.parse(caseData[mitigationKey]);

            //       domainData['domainScore'] = mitigationData[`AverageControl-${domainNumber}`] || 0;
            //     } catch (error) {

            //     }
            //     domains.push(domainData);
            //   });
            // }

            let applicableControls = risk["case-file"]["case-data"]?.applicableControls;
            if (applicableControls) {
              let controls = [];
              applicableControls.forEach(control => {
                if (control !== "") {
                  try {

                    let id = control.replace('m', '');
                    let domainID = id.charAt(0);
                    let sectionID = id.charAt(1);
                    if (domainID === '1') {
                      domainID = id.substring(0, 2);
                      sectionID = id.charAt(2);
                    }

                    let filteredDomain = domains.find(domain => domain.domainID === domainID);
                    if (filteredDomain) {
                      let sections = filteredDomain.sections.find(section => section.sectionID === `${domainID}.${sectionID}`);

                      if (sections) {
                        let controlObj = sections?.controls.find(control => control.controlID.replaceAll('.', '') === id);
                        if (controlObj) {
                          controls.push(controlObj);
                        }
                      }
                    }
                    newRisk.controls = controls;
                  } catch (err) {
                    // console.log(err);
                  }
                }
              });
            }
            newRisk.threatList = risk["case-file"]["case-data"]["threatList"] || [];
            newRisk.caseID = risk["case-id"];
            newRisk.inherentRiskRatingLevel = Number(risk["inherentRiskRating"]?.split("-")[1] || 0);
            newRisk.isExpanded = false;
            if (newRisk.riskTitle !== "") {
              risks.push(newRisk);
            }
          })

          let risksGraph = extractRisksGraphDataFromRisks(risks, domains);

          // risks.sort((a, b) => b["case-id"] - a["case-id"]);
          risks.sort(riskSorting);

          dispatch({ type: 'FETCH_DATA_SUCCESS', payload: risks, graphData: risksGraph, domainData: domains });

          let paginationTrackerPayload = {
            lastPageFetched: {
              open: 0,
              closed: 0,
            },
            hasMore: {
              open: openedData.length === 10,
              closed: closedData.length === 10,
            }
          }
          dispatch({
            type: 'PAGINATION_TRACKER',
            payload: paginationTrackerPayload
          });

          // Cache the fetched data
          cacheData(keycloak.token, 'cached-risks', risks);
          cacheData(keycloak.token, 'cached-graphData', risksGraph);
          dispatch(toggleAllowFetch(false));

          // Check if risk does not have more data than stop background fetch
          if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
            dispatch(toggleFetchingBackgroundData(false));
          }

          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });

        }
      }
    } catch (error) {
      dispatch({ type: 'FETCH_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { allowFetch, lastPageFetched, hasMore } = getState().data; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-risks');
      const cachedGraphData = await getCachedData(keycloak.token, 'cached-graphData');
      const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');

      let domains;
      if (cachedDomainData) {
        // If the data is in cache, use it
        domains = cachedDomainData;
      } else {
        // If the data is not in cache, make the API call
        const domainsObj = await getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains');
        domains = domainsObj.success ? domainsObj.data["dmnContext"]["domains"][0] : [];
        // Cache the data
        cacheData(keycloak.token, 'cached-domainData', domains);
      }

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let risks = [];
      const closedRisks = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'closed', 10, nextClosedPage) : [];
      const activeRisks = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'open', 10, nextOpenPage) : [];

      const closedData = closedRisks.success ? closedRisks.data?.instances : [];
      const openedData = activeRisks.success ? activeRisks.data?.instances : [];
      const riskData = [...closedData, ...openedData];

      if (riskData) {
        riskData.forEach((risk, index) => {
          let newRisk = extractCaseDataFromCaseFile(risk, domains);
          newRisk.caseID = risk["case-id"];
          newRisk.isExpanded = false;
          if (newRisk.riskTitle !== "") {
            risks.push(newRisk);
          }
        })

        if (cachedData?.length > 0) {
          risks = [...cachedData, ...risks];
        }

        let risksGraph = extractRisksGraphDataFromRisks(risks, domains);

        // risks.sort((a, b) => b["case-id"] - a["case-id"]);
        risks.sort(riskSorting);

        dispatch({ type: 'FETCH_DATA_SUCCESS', payload: risks, graphData: risksGraph, domainData: domains });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if risk does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-risks', risks);
        cacheData(keycloak.token, 'cached-graphData', risksGraph);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_DATA_ERROR', payload: error.message });
    }
  };
};

export const updateDataByCaseId = (keycloak, { caseID, riskData }) => {
  return async (dispatch, getState) => {

    const cachedData = await getCachedData(keycloak.token, 'cached-risks');
    const cachedGraphData = await getCachedData(keycloak.token, 'cached-graphData');
    const cachedDomainData = await getCachedData(keycloak.token, 'cached-domainData');

    let domains;
    if (cachedDomainData) {
      // If the data is in cache, use it
      domains = cachedDomainData;
    } else {
      // If the data is not in cache, make the API call
      const domainsObj = await getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains');
      domains = domainsObj.success ? domainsObj.data["dmnContext"]["domains"][0] : [];
      // Cache the data
      cacheData(keycloak.token, 'cached-domainData', domains);
    }

    let risks = [...(cachedData || [])];

    let riskDataIndex = risks.findIndex((r) => r["caseID"] === caseID);
    if (riskDataIndex > -1) {
      risks[riskDataIndex] = {
        ...risks[riskDataIndex],
        ...riskData
      }
    } else {
      risks.push({
        caseID: caseID,
        ...riskData,
      })
    }
    // risks.sort((a, b) => b["case-id"] - a["case-id"]);
    // risks.sort((a, b) => b["caseID"] - a["caseID"]);
    risks.sort(riskSorting);

    let risksGraph = extractRisksGraphDataFromRisks(risks, domains);
    dispatch({ type: 'FETCH_DATA_SUCCESS', payload: risks, graphData: risksGraph, domainData: domains });

    // Cache the fetched data
    cacheData(keycloak.token, 'cached-risks', risks);
    cacheData(keycloak.token, 'cached-graphData', risksGraph);
    dispatch(toggleAllowFetch(false));

  }
}

export const fetchOrganisationDetails = (keycloak, email) => {
  return async (dispatch, getState) => {
    try {
      let response = await getCompletedOrganization(keycloak.token, email);
      let organisationID = response.data;
      if (organisationID > 0) {
        //get variables
        let response1 = await getProcessVariables(organisationID, keycloak?.token);
        const orgData = {
          "organisationalName": response1.data.filter(obj => { return obj.name === "OrganisationName" })[0]?.value ?? "",
          "organisationSize": response1.data.filter(obj => { return obj.name === "OrganisationSize" })[0]?.value ?? "",
          "industryType": response1.data.filter(obj => { return obj.name === "IndustryType" })[0]?.value ?? "",
          "emailAddress": response1.data.filter(obj => { return obj.name === "EmailAddress" })[0]?.value ?? "",
          "subscriptionType": response1.data.filter(obj => { return obj.name === "SubscriptionType" })[0]?.value ?? "",
          "serviceHours": response1.data.filter(obj => { return obj.name === "ServiceHours" })[0]?.value * 1 || 0,
          "totalDuration": response1.data.filter(obj => { return obj.name === "TotalDuration" })[0]?.value * 1 || 0,
          "paymentType": response1.data.filter(obj => { return obj.name === "PaymentType" })[0]?.value ?? "",
          "subscriptionEndDate": response1.data.filter(obj => { return obj.name === "SubscriptionEndDate" })[0]?.value ?? "",
        };
        dispatch(setOrganisationDetail(orgData));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
