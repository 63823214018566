import { FormControl } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    align-items: center;
    position: fixed;
    bottom: 50px;
    right: 50px;

    margin-top: 64px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        padding: 0;
        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        right: 20px;
        bottom: 20px;

        justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
    }
`;

export const GraphContainer = styled.div`
    & canvas {
        width: 40%;
        height: 300px;
    }
    @media (max-width: 599.98px) {
        & canvas {
            width: 100% !important;
            height: 300px;
        }
    }
`;

export const ContentContainer = styled.div`
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};

    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    border-radius: 16px;
    font-family: "Rubik", sans-serif;
    overflow: hidden;
    padding: 32px 42px 77px;

    padding-top: ${({ sm }) => (sm ? "18px" : "")};
    padding-bottom: ${({ sm }) => (sm ? "24px" : "")};
    padding: ${({ noPad }) => (noPad ? "0" : "")};
    padding: ${({ actionsPad }) => (actionsPad ? "31px 10px 23px 51px" : "")};

    @media (max-width: 599.98px) {
        padding: ${({ sm }) => (sm ? "32px 0px 34px 0px" : "")};
        padding: ${({ actionsPad }) => (actionsPad ? "34px 10px 23px 17px" : "")};
        padding: ${({ smPad }) => (smPad ? "32px 10px 59px 16px " : "")};
    }
`;

export const StyledFormControl = styled(FormControl)`
    .MuiFormGroup-root {
        margin-top: 43px;
        margin-left: 23px;

        @media (max-width: 599.98px) {
            margin-top: 25px;
        }
    }

    .MuiFormControlLabel-root:first-of-type {
        margin-right: 70px;
    }
`;

export const BtnsContainer = styled.div`
    box-sizing: border-box;
    max-width: 1260px;
    margin: 0 auto;
    font-family: "Rubik", sans-serif;
    position: fixed;
    bottom: 60px;
    right: 50px;

    @media (max-width: 599.98px) {
        right: 20px;
        bottom: 20px;
    }
`;

export const StyledTableContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 135px;

    &:first-of-type {
        margin-top: 17px;
    }

    &:not(:last-of-type) {
        margin-bottom: 25px;
        @media (max-width: 599.98px) {
            margin-bottom: 38px;
        }
    }

    @media (max-width: 991.98px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .MuiFormControlLabel-root {
        @media (max-width: 599.98px) {
            margin-left: 27px;
        }
    }
`;

export const StyledTableFormControl = styled(FormControl)`
    .MuiFormGroup-root {
        margin-left: 11px;

        @media (max-width: 599.98px) {
            margin: 0;
        }
    }

    @media (max-width: 599.98px) {
        width: 100%;
    }
`;

export const CenterHeadingContainer = styled.div`
    text-align: center;
`;

export const StyledComplateContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;
