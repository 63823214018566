import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import {
    StyledTableContainer,
    StyledSmallTable
} from "../../nested/SecurityControlMaturityAssessment/MaturityScores/MaturityScores.styled";


function DomainTable(props) {
    const { domain, scores } = props;

    return (<StyledTableContainer component={Paper}>
        <StyledSmallTable>
            <TableBody>
                <TableRow >
                    <TableCell> Aggregate Score (Domain)</TableCell>
                    <TableCell className="agregateScore" key={domain.domainID} style={{ color: scores[`averageControl_${domain.domainID}`] <= 2.5 ? "red" : "green" }}>{scores ? Math.floor(scores[`averageControl_${domain.domainID}`]) : ""}</TableCell>
                </TableRow>
            </TableBody>
        </StyledSmallTable>
    </StyledTableContainer>);

}



export default DomainTable;
