import styled from "styled-components";

export const QuestionTitleWrapper = styled.div`
    display: flex;
    margin-bottom: 15px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 15px;
    }
`;

export const QuestionNum = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.38);
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 10px;
`;
