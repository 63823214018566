import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, Box, Grid, Typography, Collapse, Alert, DialogTitle, DialogContent, IconButton, DialogActions, InputAdornment } from '@mui/material';
import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import { Button } from '../../../../../components/UI/Button/Button.styled';
import { startUserTask, completeUserTask, getCaseFileData, startMileStone, getUserTask, getProcessIDByCase, startNewCase, getCase, reopenCase, getCaseInfoWithData, getDMN, updateCaseFileDocumentID, updateCaseFileData, closeCase } from '../../TRA/TRAApi';
import { CONTAINER_ID, TRAProcess, Threat } from '../../../../../store/auth-context';
import keycloak from '../../../../../Keycloak';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

import ThreatQuestionData from '../ThreatData.json';
import { fetchData, getThreatData, removeEmptyCase, toggleAllowFetch } from '../../../../../utility/threat.action';
import { fetchData as fetchDataTRA, toggleAllowFetch as toggleAllowFetchTRA } from '../../../../../utility/tra.action';
import ThreatTable from './ThreatTable';
import Lottie from "lottie-react";
import sorryAnimation from "../../../../../assets/animations/sorry.json";
import logo from "../../../../../images/logo0.2.png";
import { StyledComplateContainer } from '../ThreatLanding.styled';
import { useHistory } from 'react-router-dom';
import ApplicableControls from '../../TRA/RiskAssessment/components/ApplicableControls';
import ThreatFormDialog from './ThreatFormDialog';
import { decomposeCounterMeasure, formatRecommendedCounterMeasure } from '../../../../../components/E8Question/SubQuestion/SubQuestion';

function BackgroundFetchLoader() {
    const { fetchingBackgroundData, threatHuntingProgress } = useSelector((state) => state.threats);

    const [fetching, setFetching] = useState(fetchingBackgroundData);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showSuccessAlert])

    useEffect(() => {

        if (fetching !== fetchingBackgroundData) {
            if (fetching && !fetchingBackgroundData) {
                setFetching(fetchingBackgroundData);
                setShowSuccessAlert(true);
            }
        }

    }, [fetching, fetchingBackgroundData])


    return <>
        <Collapse in={threatHuntingProgress.status}>
            <Alert severity="info">{threatHuntingProgress.message}</Alert>
        </Collapse>
        <Collapse in={fetchingBackgroundData}>
            <Alert severity="warning">Threats table is being updated.  Please wait...</Alert>
        </Collapse>
        <Collapse in={showSuccessAlert}>
            <Alert severity="success">Threats table is now fully loaded</Alert>
        </Collapse>
    </>
}

const ThreatSection = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { data, error, allowFetch } = useSelector((state) => state.threats);
    const { data: solutionBuildingBlocks } = useSelector((state) => state.solutionBuildingBlocks);
    const { data: tras, allowFetch: allowFetchTRA } = useSelector((state) => state.tras);

    const [openModal, setOpenModal] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taskInstanceIDList, setTaskInstanceIDList] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [caseID, setCaseID] = useState(null);
    const [processInstanceID, setProcessInstanceID] = useState(null);
    const [threats, setThreats] = useState([]);
    const [disableActionMeta, setDisableActionMeta] = useState(null);
    const [createNewCaseLoading, setCreateNewCaseLoading] = useState(false);
    const [updateCaseLoading, setUpdateCaseLoading] = useState(false);
    const [updatingCase, setUpdatingCase] = useState(false);
    const [domainData, setDomainData] = useState(null);
    const [domainControlMapper, setDomainControlMapper] = useState({});
    const [selectedTRA, setSelectedTRA] = useState(null);

    const disableActions = createNewCaseLoading || updatingCase || updateCaseLoading;

    useEffect(() => {
        if (data) {
            // setThreats(data);
        }

        if (data === null && allowFetch === false && solutionBuildingBlocks?.length) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch, solutionBuildingBlocks])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    useEffect(() => {
        if (tras === null && allowFetchTRA === false) {
            dispatch(toggleAllowFetchTRA(true))
        }

    }, [tras, allowFetchTRA])

    useEffect(() => {
        if (allowFetchTRA) {
            dispatch(fetchDataTRA(keycloak, 0));
        }
    }, [dispatch, allowFetchTRA]);

    useEffect(() => {
        if (domainData) {
            let domainControlMapper = {};
            domainData.forEach((domain) => {
                domain.sections.forEach((section) => {
                    section.controls.forEach((control) => {
                        domainControlMapper[`${control.key.replaceAll("-", "")}`] = `${control.controlID} : ${control.controlHeading}`;
                    })
                })
            })
            setDomainControlMapper(domainControlMapper);
        }
    }, [domainData])

    function getDomains() {
        getDMN(CONTAINER_ID, keycloak.token, 'riskAssessmentDomains').then((response1) => {
            if (response1.success) {
                let data = response1.data["dmnContext"]["domains"][0];
                setDomainData(data);
            }
        })
    }

    useEffect(() => {
        if (!CONTAINER_ID) return;
        getDomains();
    }, [CONTAINER_ID])

    async function handleNewCase(containerId, token, selectedTRA) {

        try {
            let obj = {
                "case-group-assignments": {
                    "admin": "cz-sa-riskassessment",
                    "owner": containerId.replaceAll("_0.1", "")
                }
            }
            // Start a new case
            const response = await startNewCase(containerId, token, Threat, obj);
            if (response.success) {
                let caseID = response.data;

                let data = {
                    "traID": selectedTRA["caseID"],
                }

                updateCaseFileData(CONTAINER_ID, keycloak.token, caseID, data).then(async (response) => {
                    setCaseID(caseID)
                })

                // // Add a wait time of 1 second
                // await new Promise(resolve => setTimeout(resolve, 1000));
                // // Update case file
                // const caseResponse = await getCase(containerId, token, Threat, 'open', 1);

                // if (caseResponse.success) {
                //     const instances = caseResponse.data.instances;

                //     if (instances && instances.length > 0) {
                //         const caseID = instances[0]['case-id'];
                //         setCaseID(caseID);
                //     }
                // }
            } else {
                setCreateNewCaseLoading(false);
                toast.error("Failed to create new threat!");
            }
        } catch (error) {
            // Handle any errors that occur during the process
            console.error("An error occurred:", error);
            setCreateNewCaseLoading(false);
            toast.error("An error occurred: ", error);
        }
    }

    const handleOpenModal = useCallback(() => {
        if (selectedTRA) {
            setLoading(true);
            setCreateNewCaseLoading(true);
            handleNewCase(CONTAINER_ID, keycloak.token, selectedTRA);
        } else {
            toast.warning("Please select a TRA first");
        }
    }, [selectedTRA]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setLoading(false);
        setUpdatingCase(false);

        // Resetting case ID and process instance ID and questions
        setCaseID(null);
        setProcessInstanceID(null);
        let newQuestions = [{ ...ThreatQuestionData[0] }];
        newQuestions = newQuestions.map((question) => {
            return {
                ...question,
                subQuestions: question.subQuestions.map((subQuestion) => {
                    return { ...subQuestion, qAnswer: "" }
                })
            }
        })
        setQuestions(newQuestions);
        dispatch(removeEmptyCase(keycloak));
    };

    function getActiveUserTasks(_processInstanceID, _taskName) {
        // setFetching(true);
        getUserTask(CONTAINER_ID, keycloak.token, _processInstanceID).then((response) => {
            if (response.success === true) {
                try {
                    let taskList = response.data['active-user-tasks']['task-summary'];
                    let detailTask = taskList.find(task => task['task-name'] === 'Threat Detail');
                    let tasks = [];
                    if (detailTask !== undefined) {
                        tasks.push(detailTask);
                    }
                    setTaskInstanceIDList(tasks);
                    setFetching(false);
                } catch (error) {
                    //error
                }
                // setFetching(false);
            }

            if (response.success === false) {
                // setFetching(false);
            }
        });
    }

    function submitUserData(task, dataObj) {
        if (task['task-status'] !== "InProgress") {
            startUserTask(CONTAINER_ID, keycloak.token, task['task-id']).then((response) => {
                if (response.success === true) {
                    completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                        if (response.success) {
                            dispatch(toggleAllowFetch(true));
                            dispatch(toggleAllowFetchTRA(true));
                            setSelectedTRA(null);
                            handleCloseModal();
                        }
                        if (response.success === false) {
                            setLoading(false);
                            setUpdatingCase(false);
                        }
                    });
                }

                if (response.success === false) {
                    setLoading(false);
                    setUpdatingCase(false);
                }
            });
        } else {
            completeUserTask(CONTAINER_ID, keycloak.token, task['task-id'], dataObj).then((response) => {
                if (response.success) {
                    handleCloseModal();
                    dispatch(toggleAllowFetch(true));
                }
            });
        }
    }

    //trigger milestone to start applicable controls
    function triggerMileStone(_caseID) {
        startMileStone(CONTAINER_ID, keycloak.token, _caseID, 'Start Threat Form')
            .then((response) => {
                if (response.success) {
                    getActiveUserTasks(processInstanceID);
                    setOpenModal(true);
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                } else {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong");
            })
    }

    function updateDocumentToTRA({ tra, doc }) {
        return new Promise(async (resolve, reject) => {
            try {

                if (tra?.status === 2) {
                    const reopenCaseResponse = await reopenCase(CONTAINER_ID, keycloak.token, TRAProcess, tra?.caseID);
                    if (!reopenCaseResponse?.success) {
                        reject(reopenCaseResponse.error || "Failed to reopen case. Please try again later");
                        return;
                    }
                }
                updateCaseFileDocumentID(tra?.caseID, JSON.stringify(doc?.id || ""))
                    .then((response) => {
                        closeCase(CONTAINER_ID, keycloak.token, tra?.caseID)
                            .then((response) => {
                                resolve();
                            })
                            .catch((error) => {
                                console.log('DEBUG error closeCase = ', error);
                                reject();
                            })
                    })
                    .catch((error) => {
                        console.log('DEBUG error = ', error);
                        reject()
                    })
            } catch (error) {
                console.log('DEBUG error = ', error);
                reject()
            }
        })
    }

    async function submitData(type, questions, traDoc) {

        try {
            setLoading(true);
            setUpdatingCase(true);

            if (activeIndex === 0) {
                const task = taskInstanceIDList.find((t) => t["task-name"] === 'Threat Detail');

                let dataObj = {};
                questions[activeIndex].subQuestions.forEach((question) => {
                    if (question.key === "reference") {
                        dataObj[`${question.key}`] = [question.qAnswer.toString()];
                    } else if (question.key === "counterMeasureMapping") {
                        let counterMeasureMapping = formatRecommendedCounterMeasure(question.qAnswer);
                        counterMeasureMapping = counterMeasureMapping.map((item) => {
                            return item.map(
                                ({ title, ismsMapping, status, implementationEvidence }) => ({
                                    "com.cisozen.CounterMeasureData": {
                                        title,
                                        ismsMapping,
                                        status,
                                        implementationEvidence: status === "Completed" ? implementationEvidence : ''
                                    }
                                })
                            )
                        });
                        dataObj[`${question.key}`] = counterMeasureMapping;
                    } else if (question.key === "compensatingControls") {
                        let counterMeasureMapping = formatRecommendedCounterMeasure(question.qAnswer || []);
                        counterMeasureMapping = counterMeasureMapping.map((item) => {
                            return item.map(
                                ({ title, ismsMapping, status, implementationEvidence }) => ({
                                    "com.cisozen.CounterMeasureData": {
                                        title,
                                        ismsMapping,
                                        status,
                                        implementationEvidence: status === "Completed" ? implementationEvidence : ''
                                    }
                                })
                            )
                        });
                        dataObj[`${question.key}`] = counterMeasureMapping;
                    } else if (question.key === "ismsMapping" || question.key === "currentControls") {
                        dataObj[`${question.key}`] = question.qAnswer;
                    } else {
                        if (Array.isArray(question.qAnswer)) {
                            dataObj[`${question.key}`] = question.qAnswer;
                        } else {
                            dataObj[`${question.key}`] = question.qAnswer.toString();
                        }
                    }
                });

                if (dataObj['dueDate']) {
                    dataObj['dueDate'] = (new Date(dataObj['dueDate'])).getTime();
                }

                let data = {
                    "threatData": {
                        "com.cisozen.ThreatData": dataObj
                    }
                }

                // Update selected document id to the case file
                updateDocumentToTRA({ tra: traDoc?.tra, doc: traDoc?.doc })
                    .then((response) => {
                        submitUserData(task, data)
                    })
                    .catch((error) => {
                        console.log('DEBUG error = ', error);
                        setLoading(false);
                        setUpdatingCase(false);
                        toast.error(error?.message || "Something went wrong");
                    })

            }

        } catch (error) {
            setLoading(false);
            setUpdatingCase(false);
            toast.error(error?.message || "Something went wrong");
        }
    }

    const toggleDisableAction = (meta = null) => {
        setDisableActionMeta(meta)
    }

    const handleEditClick = (caseID, status, caseData) => {
        if (!selectedTRA) {
            if (caseData['traID'] && tras.find((t) => t['caseID'] === caseData['traID'])) {
                setSelectedTRA(tras.find((t) => t['caseID'] === caseData['traID']));
            }
        }

        setUpdateCaseLoading(true);
        toggleDisableAction({ action: 'edit', caseID: caseID });
        if (status === 1) {
            setCaseID(caseID);
        } else if (status === 2) {
            reopenCase(CONTAINER_ID, keycloak.token, Threat, caseID).then((response) => {
                if (response.success) {
                    getCaseInfoWithData(CONTAINER_ID, keycloak.token, caseID).then((response1) => {
                        if (response1.success) {
                            getThreatData(response1.data)
                                .then((threatData) => {
                                    if (response.success) {
                                        setCaseID(caseID);
                                        dispatch(toggleAllowFetch(true))
                                    } else {

                                    }
                                })
                                .catch((error) => {
                                    setUpdateCaseLoading(false);
                                    toast.error("Failed to reopen case. Please try again later");
                                });
                        } else {
                            toggleDisableAction();
                            setUpdateCaseLoading(false);
                            toast.error(response.error);
                        }
                    })
                } else {
                    toggleDisableAction();
                    setUpdateCaseLoading(false);
                    toast.error(response.error);
                }
            });
        }

    };

    //get process instance id
    function getProcessInstanceID(_caseID) {
        getProcessIDByCase(CONTAINER_ID, keycloak.token, _caseID)
            .then((response) => {
                if (response.success) {
                    let processInstanceID = response.data['process-instance'][0]['process-instance-id'];
                    setProcessInstanceID(processInstanceID);
                }
                if (response.success === false) {
                    setCreateNewCaseLoading(false);
                    setUpdateCaseLoading(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((error) => {
                setCreateNewCaseLoading(false);
                setUpdateCaseLoading(false);
                toast.error("Something went wrong")
                dispatch(toggleAllowFetch(true));
            })
    }

    //get process instance id
    useEffect(() => {
        if (caseID === null) return;
        getProcessInstanceID(caseID);
        // getMileStones(caseID);
    }, [caseID])

    //trigger threat detail milestone
    useEffect(() => {
        if (caseID === null || processInstanceID === null) return;
        triggerMileStone(caseID);
    }, [caseID, processInstanceID]);

    function getQuestions() {
        getCaseFileData(CONTAINER_ID, keycloak.token, caseID).then((response) => {
            if (response.success === true) {

                let caseData;
                try {
                    caseData = response.data?.threatData?.["com.cisozen.ThreatData"];
                } catch (error) {
                    console.error("An error occurred:", error);
                    // Handle the error as needed
                }

                let newQuestions = [{ ...ThreatQuestionData[0] }];
                newQuestions = newQuestions.map((question) => {
                    return {
                        ...question,
                        subQuestions: question.subQuestions.map((subQuestion) => {
                            if (subQuestion.key === "solutionBuildingBlockID") {
                                return {
                                    ...subQuestion,
                                    qAnswer: "",
                                    qAvailableAnswers: solutionBuildingBlocks
                                        .filter((p) => (p?.name?.length > 0 && p?.solutionBuildingBlockID?.length > 0))
                                        .map(
                                            (p) => ({ label: `${p.name} - ${p.solutionBuildingBlockID}`, value: p.caseID })
                                        )
                                }
                            } else if (subQuestion.key === "ismsMapping" || subQuestion.key === "currentControls") {
                                return { ...subQuestion, qAnswer: [] }
                            } else {
                                return { ...subQuestion, qAnswer: "" }
                            }
                        })
                    }
                })
                let questionObj = newQuestions[0];
                if (questionObj) {
                    let question = questionObj;
                    if (caseData) {
                        let q = question?.subQuestions?.map((subQ) => {
                            let newSubQ = subQ;
                            if (newSubQ.key === 'counterMeasureMapping' || newSubQ.key === 'compensatingControls') {
                                newSubQ["qAnswer"] = decomposeCounterMeasure(caseData[subQ.key] || []);
                            } else if (newSubQ.type === 'date') {
                                newSubQ["qAnswer"] = caseData[subQ.key] ? new Date(caseData[subQ.key]['java.util.Date']) : "";
                            } else if (newSubQ.key === "reference") {
                                newSubQ["qAnswer"] = caseData[subQ.key] && caseData[subQ.key][0] ? caseData[subQ.key][0] : "";
                            } else {
                                newSubQ["qAnswer"] = caseData[subQ.key] || "";
                            }
                            return newSubQ;
                        });
                        question.subQuestions = q;
                    }
                    setQuestions([{ ...question }]);
                }

                setFetching(false);
            }

            if (response.success === false) {
                setFetching(false);
            }
        });
    }

    useEffect(() => {
        if (processInstanceID === null) {
            return;
        }
        getQuestions(processInstanceID);
    }, [taskInstanceIDList]);

    let content = '';
    if (!(solutionBuildingBlocks?.length > 0)) {
        content = <>
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img alt="" src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to add Solution Building Block!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/project-and-solutions")}>
                            Back To Solution Building Blocks
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        </>;
    } else {
        content = <>
            <DomainCard>

                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h5">
                            Threats
                        </Typography>
                    </Grid>
                </Grid>

                <ThreatTable
                    // tableData={threats}
                    selectedTRA={selectedTRA}
                    onChangeTRA={(value) => setSelectedTRA(value)}
                    handleOpenModal={handleOpenModal}
                    createNewCaseLoading={createNewCaseLoading}
                    disableActions={disableActions}
                    disableActionMeta={disableActionMeta}
                    handleEditClick={handleEditClick}
                    domainControlMapper={domainControlMapper}
                />

                <ThreatFormDialog
                    caseID={caseID}
                    selectedTRA={selectedTRA}
                    caseData={caseID && data.find((t) => t.caseID === caseID)}
                    questions={questions}
                    open={openModal}
                    toggle={handleCloseModal}
                    activeIndex={activeIndex}
                    updatingCase={updatingCase}
                    submitData={submitData}
                    domainData={domainData}
                    domainControlMapper={domainControlMapper}
                />

            </DomainCard >
        </>;
    }

    return (
        <>
            <BackgroundFetchLoader />
            {content}
        </>
    );
};

export default ThreatSection;