import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";

import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import InsertChartRoundedIcon from "@mui/icons-material/InsertChartRounded";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import SecurityIcon from '@mui/icons-material/Security';
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import DevicesIcon from '@mui/icons-material/Devices';
import MemoryIcon from '@mui/icons-material/Memory';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';
import MapIcon from '@mui/icons-material/Map';
import PolicyIcon from '@mui/icons-material/Policy';
import SchoolIcon from '@mui/icons-material/School';

import { useHistory, useLocation } from "react-router";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { DrawerWrapper, CustomMenuItem, CustomDrawer, SideDrawerLogo, CustomNavLink, CustomList, helpBgColor, CustomMenuItemIcon, CustomMenuItemText } from "./SideDrawer.styled";
import AuthContext from "../../../../store/auth-context";
import ThemeContext from "../../../../store/theme-context";

import { useKeycloak } from '@react-keycloak/web';

import sidebarLogo from "../../../../images/sidebar-logo.png";
import "./SideDrawer.styled";
import { PopupModal } from "react-calendly";
import sidebarLogo2 from "../../../../images/logo0.2.png";
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip } from "@mui/material";
import OrganisationSelect from "../../../../components/OrganisationSelect/OrganisationSelect";

const menuCategoryList = [
    {
        text: "",
        icon: <DashboardIcon />,
        path: "/home",
        menuIconList: [
            {
                text: "Dashboard",
                icon: <DashboardIcon />,
                path: "/home",
            }
        ]
    },
    {
        text: "ISMS",
        icon: null,
        menuIconList: [
            {
                icon: <MapIcon />,
                path: "/map",
                text: "Map"
            },
            {
                icon: <DevicesIcon />,
                path: "/it-security",
                text: "IT Security"
            },
            {
                icon: <MemoryIcon />,
                path: "/ot-security",
                text: "OT Security"
            },
            {
                icon: <SchoolIcon />,
                path: "/training",
                text: "Training"
            }
        ]
    }
];


function MainDrawer(props) {

    let { container, mobileOpen, handleDrawerToggle, handleDrawerShrink, drawerWidth } = props;

    const { keycloak } = useKeycloak();
    const trackerData = useSelector((state) => state.tracker);

    const { organisationDetail } = useSelector((state) => state.data);

    let { path, url } = useRouteMatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext?.theme?.palette?.mode === "dark" ? true : false;
    const [isOpen, setIsOpen] = useState(false);
    const [customAnswers, setCustomAnswers] = useState({});
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        setCustomAnswers(
            (prev) => ({
                ...prev,
                a1: trackerData?.meta?.title || '',
                a2: trackerData?.meta?.href || '',
            })
        )
    }, [trackerData])

    const logOutHandler = () => {
        history.replace("/login");
        sessionStorage.clear();
        keycloak.logout()
    };

    const helpHandler = () => {
        setIsOpen(true)
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setUserInfo(res);
            });
        }
    }, [keycloak.authenticated])

    const drawer = (
        <DrawerWrapper>
            {/* <SideDrawerLogo src={sidebarLogo} style={{ opacity: "0" }} /> */}
            <Box sx={{ height: 82, padding: '8px 16px' }}>
                <OrganisationSelect />
            </Box>
            <Divider />
            <CustomList style={{ paddingTop: "0px" }}>
                {
                    menuCategoryList.map((category, catIndex) => {
                        return (
                            <li key={catIndex}>
                                <ul>
                                    <ListSubheader>{category.text}</ListSubheader>
                                    {category.menuIconList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <CustomNavLink to={`${item.path}`} key={index} >
                                                {/* TODO: update color based on theme */}
                                                <CustomMenuItem style={{ backgroundColor: item.path === pathname ? "#3a8d1c" : "" }}>
                                                    <Tooltip title={item.text} placement="right-start">
                                                        {
                                                            drawerWidth > 75 ? <><CustomMenuItemIcon>{item.icon}</CustomMenuItemIcon><CustomMenuItemText>{item.text}</CustomMenuItemText></> : item.icon
                                                        }
                                                    </Tooltip>
                                                </CustomMenuItem>
                                            </CustomNavLink>
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </li>
                        )
                    })
                }
                <CustomMenuItem></CustomMenuItem>
                {
                    organisationDetail?.serviceHours > 0 && <CustomMenuItem onClick={helpHandler}>
                        <Tooltip title="Security Expert" placement="right-start">
                            {
                                drawerWidth > 75 ? <><CustomMenuItemIcon><SupportAgentIcon /></CustomMenuItemIcon><CustomMenuItemText>Security Expert</CustomMenuItemText></> : <LiveHelpIcon />
                            }
                        </Tooltip>
                    </CustomMenuItem>
                }
                <CustomMenuItem></CustomMenuItem>
                <CustomMenuItem
                    onClick={() => {
                        handleDrawerShrink();
                    }}
                >
                    {
                        drawerWidth > 75 ? <><CustomMenuItemIcon><KeyboardDoubleArrowLeftIcon /></CustomMenuItemIcon><CustomMenuItemText>Minimize</CustomMenuItemText></> : <KeyboardDoubleArrowRightIcon />
                    }
                </CustomMenuItem>
            </CustomList>
        </DrawerWrapper >
    );

    return (
        <>
            <PopupModal
                pageSettings={{
                    backgroundColor: isDarkTheme ? '1f2128' : 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '3a8d1c',
                    textColor: isDarkTheme ? 'ffffff' : '4d5055'
                }}
                prefill={{
                    email: userInfo?.email || '',
                    firstName: userInfo?.given_name || '',
                    lastName: userInfo?.family_name || '',
                    name: userInfo?.name || '',
                    customAnswers: customAnswers
                }}
                url="https://calendly.com/cisozen/support"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
            />
            {mobileOpen ?
                <CustomDrawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: `${drawerWidth}px`,
                        },
                    }}
                >
                    {drawer}
                </CustomDrawer>
                :
                <CustomDrawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </CustomDrawer>
            }
        </>
    );
}

export default MainDrawer;
