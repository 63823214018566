import QuestionTitle from "./QuestionTitle/QuestionTitle";
import { CustomFormControl, CustomFormControlLabel, SubQuestionWrapper } from "./SubQuestion.styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";

const SubQuestion = (props) => {
    const { question, parentIndex, index, handleAnswer, ans1Index, readOnly } = props;

    const [val, setVal] = useState(question.qAnswer ? question.qAnswer : "");

    const handleChange = (e) => {
        setVal(e.target.value);

        if (question.qType === "maturity") {
            handleAnswer(e.target.value, parentIndex, index, question.qNum);
        } else {
            handleAnswer(e.target.value, parentIndex, index);
        }
    };

    function checkDisableIndex(indexAns) {
        if (question.qAvailableAnswers.length < 4) {
            return false;
        }
        var result = true;
        if (index > 0) {
            if (ans1Index < 2) {
                //enable all
                result = false;
            } else if (ans1Index === indexAns) {
                result = false;
            } else {
                result = true;
            }
        } else {
            result = false;
        }
        return result;
    }

    function handleValue() {
        var result = "";
        if (question.qAnswer !== null || question.qAnswer !== "") {
            result = question.qAnswer;
        } else {
            result = question.qAvailableAnswers[ans1Index];
        }
        return result;
    }

    return (
        <SubQuestionWrapper>
            <QuestionTitle question={question} qNum={question.qNum} />
            <CustomFormControl >
                {
                    question.qType === "maturity" ? <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={question.qAnswer} onChange={handleChange}>
                        {question.qAvailableAnswers.map((answer, indexAns) => {
                            return <CustomFormControlLabel style={{ width: "100%" }} key={indexAns} value={answer} control={<Radio />} label={answer} />;
                        })}
                    </RadioGroup> :
                        <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={handleValue()} onChange={handleChange}>
                            {question.qAvailableAnswers.map((answer, indexAns) => {
                                return <CustomFormControlLabel disabled={readOnly ? readOnly : question.touched ? true : checkDisableIndex(indexAns)} key={indexAns} value={answer} control={<Radio />} label={answer} />;
                            })}
                        </RadioGroup>
                }
            </CustomFormControl>
        </SubQuestionWrapper>
    );
};
export default SubQuestion;
