import { Alert, appBarClasses, Box, Chip, Grid, Grow } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../../assets/animations/done.json";
import { Button } from "../../../../../components/UI/Button/Button.styled";
import Heading from "../../../../../components/UI/Heading/Heading";
import Loader from "../../../../../components/UI/Loader/Loader";
import modalImg from "../../../../../images/health-check-modal-img.png";
import { AssessMaturity, CONTAINER_ID, HealthCheckProcess, RiskAssessment, RiskAssessmentProcess, TRAProcess } from "../../../../../store/auth-context";
import InfoModal from "../../../components/InfoModal/InfoModal";
import { ActionsWrapper, StyledComplateContainer, Wrapper } from "./RiskAssessmentPage.styled";
import E8QuestionsStepper from "../../../components/E8QuestionStepper/E8QuestionStepper";
import questionData from "./QuestionData.json";
import QuestionProgress from "../../../components/QuestionProgress/QuestionProgress";
import logo from "../../../../../images/logo0.2.png";
import { useKeycloak } from "@react-keycloak/web";
import ApplicableControls from "./components/ApplicableControls";
import Risks from './Risks.json';
import RiskAssessmentCard from "./components/RiskMainCard";
import { getCase, getCaseFileData, getCaseInfo, getCaseInfoWithData, getCases, getProcessIDByCase, getProcessVariables, reopenCase, startNewCase, updateCaseFileData, updateCaseFileVariable, updateCaseRole } from "../TRAApi";
import { startMileStone } from "../TRAApi";
import { retrieveData } from "../../../../../shared/utility";
import RiskReductionTable from "./components/RiskReductionTable";
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, getRiskData, toggleAllowFetch, updateData, updateDataByCaseId } from "../../../../../utility/dataAction";
import { getCompletedRiskMatrix } from "../../../InformationAPI";
import sorryAnimation from "../../../../../assets/animations/sorry.json";
import RiskMainCard from "./components/RiskMCard";
import { updateTrackerData } from "../../../../../utility/tracker.action";
import { Helmet } from "react-helmet-async";
import RiskMCardNewTable from "./components/RiskMCardNewTable";

function BackgroundFetchLoader() {
    const { fetchingBackgroundData } = useSelector((state) => state.data);

    const [fetching, setFetching] = useState(fetchingBackgroundData);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showSuccessAlert])

    useEffect(() => {

        if (fetching !== fetchingBackgroundData) {
            if (fetching && !fetchingBackgroundData) {
                setFetching(fetchingBackgroundData);
                setShowSuccessAlert(true);
            }
        }

    }, [fetching, fetchingBackgroundData])

    return <>
        <Collapse in={fetchingBackgroundData}>
            <Alert severity="warning">Risk Assessment is being updated.  Please wait...</Alert>
        </Collapse>
        <Collapse in={showSuccessAlert}>
            <Alert severity="success">Risk Assessment is now fully loaded</Alert>
        </Collapse>
    </>
}

function RiskAssessmentLanding(props) {

    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const history = useHistory();
    const [questions, setQuestions] = useState(questionData);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    // const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const e8NavHandler = (process) => history.push("/it-security/essential-eight/controls/questions", { process });
    const goBack = () => history.goBack();
    const [activeIndex, setActiveIndex] = useState(0);
    const [processID, setProcessID] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState(null);
    const [assessmentCompleted, setAssessmentCompleted] = useState(false);
    const [applicableControls, setApplicableControl] = useState(false);
    const [risks, setRisks] = useState(null);
    const [riskReductionData, setRiskReductionData] = useState([]);
    const [riskCaseID, setRiskCaseID] = useState(null);
    const [riskReductionKey, setRiskReductionKey] = useState("");
    const [completedRiskID, setcompletedRiskID] = useState(null);
    const [riskDimensions, setRiskDimensions] = useState(null);
    const [isRiskCreating, setIsRiskCreating] = useState(null);
    const [isMaturityCompleted, setMaturityCompleted] = useState(null);
    const [checkingMaturityCompleted, setCheckingMaturityCompleted] = useState(true);
    const [disableActions, setDisableActions] = useState(false);
    const [disableActionMeta, setDisableActionMeta] = useState(null);

    const dispatch = useDispatch();
    const { data, loading, error, allowFetch, updatedCaseID, allowRiskUpdate } = useSelector(
        (state) => state.data
    );

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Risk Assessment`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    function refreshRiskRegister() {
        dispatch(toggleAllowFetch(true));
    }

    useEffect(() => {
        if (data) {
            setRisks(data);
        }

        if (data === null && allowFetch === false) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch])

    useEffect(() => {
        if (updatedCaseID) {
            try {
                var index = risks.findIndex(obj => obj["case-id"] === updatedCaseID);
                if (index !== -1) {
                    // Replace the object at that index with the new object
                    var tempRisks = [...risks];
                    var updatedRisk = tempRisks[index];
                    updatedRisk.isUpdating = true;
                    tempRisks[index] = updatedRisk;
                    setRisks(tempRisks);
                }
                dispatch(updateData(keycloak));
            } catch (error) {

            }

        }
    }, [updatedCaseID])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];



    useEffect(() => {
        if (CONTAINER_ID === "" || keycloak.token === "") return;

        getCompletedRiskMatrix(keycloak.token, "").then((response) => {
            if (response.data > 0) {
                getProcessVariables(CONTAINER_ID, keycloak.token, response.data).then((responseData) => {
                    try {
                        let variableInstances = responseData.data["variable-instance"];
                        if (variableInstances) {
                            let dimensions = variableInstances.filter(obj => { return obj.name === "Dimensions" })[0]?.value ?? "";
                            setRiskDimensions(dimensions);
                            setcompletedRiskID(response.data);
                        }
                    } catch (error) {

                    }
                })
            } else {
                setcompletedRiskID(response.data);
            }

        });

        getCase(CONTAINER_ID, keycloak.token, "riskReduction", 'closed', 1).then((response) => {
            if (response.success) {
                let instances = response.data.instances;
                if (instances) {
                    if (instances.length > 0) {
                        try {
                            let caseData = response.data.instances[0]["case-file"]["case-data"]?.riskReductionData;
                            if (caseData) {
                                let arr = [];
                                for (let index = 0; index < 6; index++) {
                                    let row = {
                                        "ControlMaturity": index,
                                        "MaxLikelihoodReduction": caseData[`maxLikelihoodReduction${index}`],
                                        "MaxConsequenceReduction": caseData[`maxConsequenceReduction${index}`],
                                    }
                                    arr.push(row);
                                }
                                setRiskReductionData(arr);
                            }

                        } catch (error) {

                        }
                    }
                }
            }
        });

        setCheckingMaturityCompleted(true);
        getCase(CONTAINER_ID, keycloak.token, AssessMaturity, 'closed', 1)
            .then((response) => {
                if (response.success) {
                    if (response.data?.instances) {
                        if (response.data.instances.length > 0) {
                            setMaturityCompleted(true);
                        }
                    };
                }
                setCheckingMaturityCompleted(false);
            })
            .catch((error) => {
                setCheckingMaturityCompleted(false);
            });

    }, [CONTAINER_ID]);

    const handleExpandClick = (index) => {
        let newRisks = [...risks];
        newRisks[index].isExpanded = !newRisks[index].isExpanded;
        setRisks(newRisks);
    };

    const toggleDisableAction = (meta = null) => {
        setDisableActions(prev => !prev);
        setDisableActionMeta(meta)
    }

    const handleEditClick = (caseID, status) => {
        // if (!allowRiskUpdate) {
        //     toast.error("We're updating your Risk Register! In the meantime you won't be able to update risks.");
        //     return;
        // };
        if (status === 1) {
            history.push(`/it-security/risk-assessment/edit/${caseID}`);
        } else if (status === 2) {
            toggleDisableAction({ action: 'edit', caseID: caseID })
            reopenCase(CONTAINER_ID, keycloak.token, RiskAssessment, caseID).then((response) => {
                if (response.success) {
                    getCaseInfoWithData(CONTAINER_ID, keycloak.token, caseID).then((response1) => {
                        if (response1.success) {
                            getRiskData(keycloak, response1.data)
                                .then((riskData) => {
                                    dispatch(updateDataByCaseId(keycloak, { caseID: caseID, riskData: riskData || {} }))
                                    toggleDisableAction();
                                    if (response.success) {
                                        history.push(`/it-security/risk-assessment/edit/${caseID}`);
                                        // dispatch(toggleAllowFetch(true))
                                    } else {

                                    }
                                })
                                .catch((error) => {
                                    toast.error("Failed to reopen case. Please try again later");
                                });
                        } else {
                            toggleDisableAction();
                            toast.error(response.error);
                        }
                    })
                } else {
                    toggleDisableAction();
                    toast.error(response.error);
                }
            });
        }

    };

    function startNewRiskAssessment() {

        // if (!allowRiskUpdate) {
        //     toast.error("We're updating your Risk Register! In the meantime you won't be able to add new risks.");
        //     return;
        // };
        if (isRiskCreating) return;

        setIsRiskCreating(true);
        startNewCase(CONTAINER_ID, keycloak.token, "startRiskRegister", {}).then((response) => {
            if (response.success === true) {
                //add wait time
                setTimeout(() => {
                    //update casefile
                    getCase(CONTAINER_ID, keycloak.token, RiskAssessment, 'open', 1).then(async (response) => {
                        if (response.success) {
                            let instances = response.data.instances;
                            if (instances) {
                                if (instances.length > 0) {
                                    try {
                                        let caseID = response.data.instances[0]['case-id'];

                                        // history.push(`/it-security/risk-assessment/edit/${caseID}`);

                                        let data = {
                                            "env": retrieveData(process.env.REACT_APP_DOMAIN) || "",
                                            "containerId": CONTAINER_ID || "",
                                            "isStartedRM": true,
                                            "riskData": {
                                                "com.cisozen.RiskRegisterData": {
                                                    "riskTitle": "New Risk",
                                                }
                                            }
                                        }

                                        await updateCaseFileData(CONTAINER_ID, keycloak.token, caseID, data).then(async (response) => {
                                            await getRiskData(keycloak, instances[0]).then((riskData) => {
                                                dispatch(updateDataByCaseId(keycloak, { caseID: caseID, riskData: riskData || {} }))
                                                setRiskCaseID(caseID);
                                                if (caseID) {
                                                    // dispatch(toggleAllowFetch(true))

                                                }
                                            });
                                        })

                                        setIsRiskCreating(false);

                                        history.push(`/it-security/risk-assessment/edit/${caseID}`);

                                    } catch (error) {
                                        console.log("error");
                                    }
                                }
                            }
                        }
                    })
                }, 1000);
            } else {
                setIsRiskCreating(false);
                toast.error("Failed to create new risk!")
            }

        });
    }

    function handleRiskReductionTableUpdate() {
        updateCaseFileVariable(CONTAINER_ID, keycloak.token, riskCaseID, riskReductionKey, JSON.stringify(riskReductionData));
    }

    let content;

    if (fetching || !checkingMaturityCompleted) {
        content = <Loader height="40vh" />;
    }

    if (!fetching && completedRiskID === 0) {
        content = (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img alt="" src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to complete Risk Matrix!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/risk-matrix")}>
                            Back To Risk Matrix
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    if (!fetching && !isMaturityCompleted && !checkingMaturityCompleted) {
        content = (
            <StyledComplateContainer>
                <Grid container>
                    <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <Lottie loop animationData={sorryAnimation} style={{ marginTop: "-30px" }} />
                        <img alt="" src={logo} width="60%" style={{ marginTop: "0px", marginLeft: "19%" }}></img>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                        <br /><h1>You have to complete Maturity Assessment!</h1><br />
                        <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={() => history.push("/it-security/maturity-assessment")}>
                            Back To Maturity Assessment
                        </Button>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </StyledComplateContainer>
        );
    }

    if (!fetching && completedRiskID > 0 && isMaturityCompleted) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    {/* <Grid item xs={12}>
                        <RiskMainCard
                            isLoading={isRiskCreating}
                            risks={risks === null ? [] : risks}
                            handleEditClick={handleEditClick}
                            handleExpandClick={handleExpandClick}
                            refreshRiskRegister={refreshRiskRegister}
                            handleAddButton={startNewRiskAssessment}
                            isUpdating={!allowRiskUpdate}
                            disableActions={disableActions}
                            disableActionMeta={disableActionMeta}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <RiskMCardNewTable
                            createNewCaseLoading={isRiskCreating}
                            risks={risks === null ? [] : risks}
                            handleEditClick={handleEditClick}
                            handleExpandClick={handleExpandClick}
                            refreshRiskRegister={refreshRiskRegister}
                            handleAddButton={startNewRiskAssessment}
                            isUpdating={!allowRiskUpdate}
                            disableActions={disableActions}
                            disableActionMeta={disableActionMeta}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        {
                            <RiskAssessmentCard risks={risks === null ? [] : risks} isLoading={isRiskCreating} handleExpandClick={handleExpandClick} handleAddButton={startNewRiskAssessment} handleEditClick={handleEditClick} isUpdating={!allowRiskUpdate}></RiskAssessmentCard>
                        }
                    </Grid> */}
                    <Grid item xs={12}>
                        {
                            riskReductionData.length > 0 ? <RiskReductionTable tableData={riskReductionData} refreshRiskRegister={refreshRiskRegister} setTableData={setRiskReductionData} handleUpdate={handleRiskReductionTableUpdate} /> : ""
                        }
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0].helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>Risk Assessment - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Risk Assessment
                </Heading>
                <BackgroundFetchLoader />
                {
                    content
                }
            </Wrapper>
        </>
    );
}

export default RiskAssessmentLanding;

