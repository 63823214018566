import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const ProgressWrapper = styled(Box)`
    position: relative;
    display: inline-flex;
    margin-bottom: 21px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 13px;
    }
`;

export const CustomCircularProgress = styled(CircularProgress)`
    &&.MuiCircularProgress-root {
        width: 150px;
        height: 150px;
        position: relative;
        z-index: 4;
        color: #46a11b;
    }
`;

export const ProgressValue = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.palette.text.primary};
    border-radius: 50%;
    border: 10px solid;
    border-color: ${({ theme }) => theme.palette.mode === "light" ? "#F2F2F2" : "#F2F2F2"};
`;