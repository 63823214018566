import styled from "styled-components";

export const StyledTableContainer = styled.div`
    @media (max-width: 599.98px) {
        width: 100%;
    }
`;

export const TableContainerTitle = styled.div`
    background: #2f74b5;
    color: #fff;
    text-align: center;
    padding: 6px 0;

    @media (max-width: 599.98px) {
        padding: 2px 0;
    }

    h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;

        @media (max-width: 599.98px) {
            font-size: 11px;
            line-height: 15px;
        }
    }
`;

export const StyledHeading = styled.h3`
    font-size: 14px;
    background: #2f74b5;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: white;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;

    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;

    /* Gray 6 */

    color: #f2f2f2;

    @media (max-width: 599.98px) {
        padding: 0;
        font-size: 11px;
        line-height: 15px;
    }
`;

export const StyledTable = styled.table`
    width: 100%; 
    border-collapse: collapse;
    color: #000;
    background-color: #fff;

    td,
    th {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        letter-spacing: 0.15px;

        color: rgba(0, 0, 0, 0.87);
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        
        padding: 8px 6px;
        @media  (min-width: ${({ theme }) => theme.breakpoints.values.lg + 101}px) and
        (max-width: ${({ theme }) => theme.breakpoints.values.lg + 300}px) {
            padding: 8px 0px;
        }

        @media screen and 
        (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) and
        (max-width: ${({ theme }) => theme.breakpoints.values.lg + 100}px)
        {
            max-width: 65px;
            padding: 8px 0px;
        }

        @media screen and 
        (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) and
        (max-width: ${({ theme }) => theme.breakpoints.values.sm + 100}px)
        {
            padding: 8px 1px;
        }

        @media (max-width: 599.98px) {
            padding: 5px 0px;
            border: 0.5px solid #ddd;
            font-size: 10px;
        }

        @media (max-width: 320px) {
            font-size: 9px;
            max-width: 45px;
        }
    }

    tr {
        &:nth-child(even) {
            background-color: $bg-color;
        }
    }
`;

export const StyledBgTd = styled.td`
    background-color: ${({ datavalue }) =>
        datavalue === "Low"
            ? "#92D050"
            : datavalue === "Medium"
                ? "#FFFF00"
                : datavalue === "High"
                    ? "#FFC000"
                    : datavalue === "Extreme"
                        ? "#FF0000"
                        : "transparent"};
`;
