import styled, { css } from "styled-components";
import { Grid, Button, ListItemButton } from "@mui/material";

export const Wrapper = styled.div`
    text-align: center;
`;

export const MainGrid = styled(Grid)`
    min-height: 310px;
    min-width: 250px;
`;

export const StatsWrapper = styled.div`
    padding-top: 17px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-top: 28px;
    }
`;

export const E8ProgressCard = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    width: 70%;
    height: 100%;
    border-radius: 8px;
    padding: 15px 16px;

    &.MuiChip-root{
        float:left;
    }
`;
export const ProgressCard = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    width: 95%;
    height: 100%;
    border-radius: 8px;
    padding: 20px 16px;

    &.MuiChip-root{
        float:left;
    }
`;

export const CardHead = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
`;

export const ProgressValue = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
`;

export const ProgressBlock = styled.div`
    text-align: center;
    margin-top: 10px;
`;

export const TextBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const DotSign = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 13px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#BDBDBD" : "#fff")};
`;

export const StatsText = styled.div`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
`;

export const RecommendationsBlock = styled(ProgressCard)`
    padding: 17px 16px;
`;

export const RecommendationsList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 19px;
    & .MuiListItem-root {
        height: auto;
        padding: 0;
    }
`

export const RecommendationsTextBlock = styled(ListItemButton)`
    &.MuiListItemButton-root {
        height: auto;
        padding: 0px 7px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        ${({ $smallmargin }) => $smallmargin && css`
            &:first-of-type {
                margin-top: 31px;
            }
        `}
    }
`;

export const RecommendationsStatsText = styled(StatsText)`
    font-size: 16px;
    line-height: 24px;
`;

export const RiskMatrixBlock = styled(RecommendationsBlock)``;

export const RiskMatrixHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const RiskMatrixHeadBtn = styled(Button)`
    &.MuiButton-root {
        text-transform: capitalize;
        width: 160px;
        height: 42px;
        padding: 8px 22px;
        background-color: #46a11b;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-family: "Rubik", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.46px;
        margin-top: -9px;
        margin-right: -6px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            width: auto;
            height: 32px;
            padding: 4px 10px;
            margin-top: -4px;
            margin-right: -4px;
            font-family: "Rubik", sans-serif;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
        }
    }

    &.MuiButton-root:hover {
        background-color: #46a11b;
    }
`;

export const RistMatrixTableWrapper = styled.div`
    width: 100%;
    margin-top: 10px;

    ${({ $blur }) =>
        $blur &&
        css`
            filter: blur(5px);
        `}
`;

export const WelcomeBlock = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 534px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1f2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "0px 4px 4px rgba(70, 161, 27, 0.5)")};
    border-radius: 8px;
    margin-top: 24px;
    padding: 23px 29px;
    padding-bottom: 58px;
    position: relative;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
        background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1f2128")};
    }
`;

export const WelcomeBlockHeadText = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    display: block;
`;

export const WelcomeStatsBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    width: 80%;
    padding-left: 10px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;
export const StatBox = styled.p`
    width: 30%;
    height: 95px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000;
    margin-top: 4px;
    span {
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const TextContainer = styled.div`
    width: 100%;
    height: 100%;
  `;

  export const Text = styled.div`
  font-size: 16px;
  display: block;
`;

export const InfoWrapper = styled.div`
    position: relative;
    z-index: 8;
`;

export const WelcomeBottomText = styled.p`
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    text-align: center;
    margin-top: 37px;
`;

export const WelcomeBottomBtn = styled(RiskMatrixHeadBtn)`
    &.MuiButton-root {
        width: auto;
        height: auto;
        min-height:32px;
        margin-top: 24px;
        margin-right: 0px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        &.MuiButton-root {
            margin-top: 24px;
            margin-right: 0px;
        }
    }
`;

export const BottomBannerWrapper = styled.div`
    position: absolute;
    top: 59px;
    right: 0;
    width: 100%;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
        display: none;
    }
`;

export const BottomBannerImg = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 300px;
    position: relative;
`;

export const DashboardBoxImg = styled.img`
    height: 300px;
    width: 300px;
    background-color: #000;
    border-radius: 50%;
`;
