import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
    VectorWrapper,
    TextBox,
    ModalHeading,
    VectorImage,
    InfoList,
} from "./InfoModal.styled";

function InfoDialog({ open, handleClose, imgSrc, title, list }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            scroll="body"
            PaperProps={{
                style: {
                    borderRadius: "16px",
                    background: "none",
                    boxShadow: "none",
                },
            }}
            style={{ backdropFilter: "blur(2px)" }}
        >
            <DialogTitle sx={{ height: 56 }}></DialogTitle>
            <Box
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <IconButton
                    title="close"
                    aria-label="close"
                    onClick={handleClose}
                    sx={{

                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ p: 0 }}>
                <VectorWrapper elevate={24}>
                    <VectorImage
                        src={imgSrc}
                        maxWidth="200px"
                        height="188px"
                        translateY="17px"
                    />
                </VectorWrapper>
                <TextBox>
                    <ModalHeading>{title}</ModalHeading>
                    <InfoList>
                        {list.map((item, index) => {
                            return <li key={index}>{item}</li>;
                        })}
                    </InfoList>
                </TextBox>
            </DialogContent>
        </Dialog>
    );
}

export default InfoDialog;
