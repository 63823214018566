import { useState } from "react";
import Heading from "../../../components/UI/Heading/Heading";
import { Wrapper } from "../RiskAssessment/RiskAssessment.styled";
import PrivacyPolicyCard from "./components/PrivacyPolicyCard";
import { Helmet } from "react-helmet-async";

let data = [
    {
        "mainID": "1.0",
        "mainHeading": "Introduction",
        "mainDescription": "When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.",
        "subSections": [],
        "isExpanded": true
    },
    {
        "mainID": "2.0",
        "mainHeading": "Information collection",
        "mainDescription": "When you create a CisoZen Account, you provide us with personal information that includes your name, a password, an email, Organisation Name, Industry and size. You can also choose to add payment information to your account. Even if you aren’t signed in to a CisoZen Account, you might choose to provide us with information — like an email address to communicate with CisoZen or receive updates about our services.We also collect the content you create, upload, or receive from others when using our services. This includes things like third-party risk assessments or spreadsheets.",
        "subSections": [
            {
                "sectionID": "2.1",
                "sectionHeading": "Browser & devices",
                "sectionDescription": "We collect information about the apps, browsers, and devices you use to access CisoZen services, which helps us provide features. The information we collect includes unique identifiers, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including IP address, crash reports, system activity, and the date, time, and referrer URL of your request."
            },
            {
                "sectionID": "2.2",
                "sectionHeading": "Your activity",
                "sectionDescription": "We collect information about your activity in our services, which we use to do things like recommend a content you might need. The activity information we collect may include:\nTerms you search for\nViews and interactions with content and ads\nPurchase activity\nPeople with whom you communicate or share content\nActivity on third-party sites and apps that use our services"
            },
            {
                "sectionID": "2.3",
                "sectionHeading": "Your location information",
                "sectionDescription": "We collect information about your location when you use our services, which helps us offer features like risks bases on your location.Your location can be determined with varying degrees of accuracy by: Information provided in your settings, IP address"
            }
        ],
        "isExpanded": true
    },
    {
        "mainID": "3.0",
        "mainHeading": "Information usage",
        "mainDescription": "We use the information we collect from all our services for the following purposes:",
        "subSections": [
            {
                "sectionID": "3.1",
                "sectionHeading": "Provide our services",
                "sectionDescription": "We use your information only to deliver our services, like processing the risks provided against our business logic to help you better understand your cyber security posture. We do not divulge your information to any other third party. Staff at CisoZen only access your information based on your request. This is usually where you need our cyber security experts to help you in utilizing CisoZen more effectively (E.g.: understanding common threats within an industry to help other customers without compromising your privacy)."
            },
            {
                "sectionID": "3.2",
                "sectionHeading": "Maintain & improve our services",
                "sectionDescription": "We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our services (E.g.: understanding which features are less frequently used helps us improve features used across our services)."
            },
            {
                "sectionID": "3.3",
                "sectionHeading": "Develop new services",
                "sectionDescription": "We use the information we collect in existing services to help us develop new ones (E.g.: understanding common threats within an industry to help other costumers without compromising privacy of other costumers)."
            },
            {
                "sectionID": "3.4",
                "sectionHeading": "Provide personalized services",
                "sectionDescription": "We use the information we collect to customize our services for you, including providing recommendations, personalized content, and customized results."
            },
            {
                "sectionID": "3.5",
                "sectionHeading": "Measure performance",
                "sectionDescription": "We use data for analytics and measurement to understand how our services are used. For example, we analyze data about your visits to our sites to do things like optimize product design. Minimal identifiable data will be used in a variety of tools to do this, including Google Analytics & Microsoft Monitoring. When you visit sites or use apps that use Google Analytics & Microsoft Monitoring, a CisoZen employees may choose to link information about your activity from that site or app with activity from other sites or apps."
            },
            {
                "sectionID": "3.6",
                "sectionHeading": "Communicate with you",
                "sectionDescription": "We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity, like an attempt to sign in to your CisoZen Account from an unusual location. Or we may let you know about upcoming changes or improvements to our services. And if you contact CisoZen, we’ll keep a record of your request in order to help solve any issues you might be facing."
            },
            {
                "sectionID": "3.7",
                "sectionHeading": "Protect CisoZen, our users, and the public",
                "sectionDescription": "We use information to help improve the safety and reliability of our services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm CisoZen, our users, or the public."
            }
        ],
        "isExpanded": true
    },
    {
        "mainID": "4.0",
        "mainHeading": "Information management",
        "mainDescription": "This section describes key controls for managing your privacy across our services.",
        "subSections": [
            {
                "sectionID": "4.1",
                "sectionHeading": "Managing, reviewing, and updating your informationProtect CisoZen, our users, and the public",
                "sectionDescription": "When you're signed in, you can always review and update information by visiting the settings page. You can request all the information CisoZen has about your organisation based on applicable law and our policies by emailing our support. Your data will be provided within 30 days from the request."
            },
            {
                "sectionID": "4.2",
                "sectionHeading": "Ways to review & update your information",
                "sectionDescription": "Manage your contact information, such as your name, email, and phone number in the settings page."
            },
            {
                "sectionID": "4.3",
                "sectionHeading": "When CisoZen shares your information",
                "sectionDescription": "We do not share your personal information with companies, organizations, or individuals outside of CisoZen except in the following cases:With your consent - We’ll share personal information outside of CisoZen when we have your consent."
            },
            {
                "sectionID": "4.4",
                "sectionHeading": "For legal reasons",
                "sectionDescription": "We will share personal information outside of CisoZen if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to: 1.Meet any applicable law, regulation, legal process, or enforceable governmental request. We share information about the number and type of requests we receive from governments in our Transparency Report. 2.Enforce applicable Terms of Service, including investigation of potential violations. 3.Detect, prevent, or otherwise address fraud, security, or technical issues. 4.Protect against harm to the rights, property or safety of CisoZen, our users, or the public as required or permitted by law. We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our services. If CisoZen is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure the confidentiality of your personal information and give affected users notice before personal information is transferred or becomes subject to a different privacy policy."
            }
        ],
        "isExpanded": true
    },
    {
        "mainID": "5.0",
        "mainHeading": "Information Security",
        "mainDescription": "We build security into our services to protect your information.All CisoZen products are built with strong security features that continuously protect your information. The insights we gain from maintaining our services help us detect and automatically block security threats from ever reaching you. And if we do detect something risky that we think you should know about, we’ll notify you and help guide you through steps to stay better protected. We work hard to protect you and CisoZen from unauthorized access, alteration, disclosure, or destruction of information we hold, including: 1.We use encryption to keep your data private while in transit, 2.We offer a range of security features, like Safe Browsing, and 2 Step Verification to help you protect your account, 3.We review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems, 4.We restrict access to personal information to CisoZen employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.",
        "subSections": [],
        "isExpanded": true
    },
    {
        "mainID": "6.0",
        "mainHeading": "Information Deletion & Retention",
        "mainDescription": "You can delete it from your CisoZen Account at any time",
        "subSections": [
            {
                "sectionID": "6.1",
                "sectionHeading": "Deleting Information",
                "sectionDescription": "You can request to remove content from CisoZen services based on applicable law and our policies by emailing our support. Your data will be deleted within 30 days from the request."
            },
            {
                "sectionID": "6.2",
                "sectionHeading": "Retaining Information",
                "sectionDescription": "All your information will be retained for the period that is required to provide effective cyber security guidance. Post this period, the information will be removed from the system. You will be provided with an export of the data prior to its removal from our system."
            },
            {
                "sectionID": "6.3",
                "sectionHeading": "Compliance",
                "sectionDescription": "We regularly review this Privacy Policy and make sure that we process your information in ways that comply with the following Act & Principles: Privacy Act 1998 (Australian Privacy Principles (APP)), and Information Privacy Act (Information Privacy Principles (IPP))"
            },
            {
                "sectionID": "6.4",
                "sectionHeading": "Data transfers",
                "sectionDescription": "Our servers are allocated within Australia and your data is processed and stored in Australia."
            },
        ],
        "isExpanded": true
    },
    {
        "mainID": "7.0",
        "mainHeading": "When this policy applies",
        "mainDescription": "This Privacy Policy applies to all of the services offered by CisoZen.",
        "subSections": [],
        "isExpanded": true
    },
    {
        "mainID": "8.0",
        "mainHeading": "Changes to this policy",
        "mainDescription": "We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review. If changes are significant, we’ll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).",
        "subSections": [],
        "isExpanded": true
    },
]


function PrivacyPolicy(props) {
    const [policyList, setPolicyList] = useState(data);

    const handleExpandClick = (index) => {
        let newPolicy = [...policyList];
        newPolicy[index].isExpanded = !newPolicy[index].isExpanded;
        setPolicyList(newPolicy);
    };
    return (
        <>
            <Helmet>
                <title>Privacy Policy - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px">
                    Privacy Policy
                </Heading>
                <PrivacyPolicyCard data={data} handleExpandClick={handleExpandClick}></PrivacyPolicyCard>
            </Wrapper>
        </>
    )
}

export default PrivacyPolicy;