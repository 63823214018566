import { Box, Grid } from "@mui/material";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { ActionsWrapper, Wrapper } from "./E8Controls.styled";
import { useKeycloak } from "@react-keycloak/web";
import E8QuestionsStepper from "../../components/E8QuestionStepper/E8QuestionStepper";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function E8SubQuestions(props) {

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [taskInstanceId, setTaskInstanceId] = useState("");

    const [loading, setLoading] = useState(false);
    const e8NavHandler = (process) => history.push("/dashboard/essential-eight/controls/questions", { process });
    const goBack = () => history.goBack();
    const [processID, setProcessID] = useState(null);
    const [question, setQuestion] = useState(null);
    const [controlName, setControlName] = useState(null);
    const [questionId, setquestionId] = useState(null);
    //const [qtitle, setQtitle] = useState(null);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `ACSC Essential Eight - ${controlName} Progress ${questions && questions[0] && (`${questions[0].qNum} ${questions[0].title}` || '')}`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [controlName, dispatch, questions])

    useEffect(() => {
        setLoading(true);
        const processObject = window.sessionStorage.getItem("E8_ACTIVE_SUB_PROCESS");
        const questionObject = window.sessionStorage.getItem("E8_ACTIVE_QUESTION");
        const controlObject = window.sessionStorage.getItem("E8_ACTIVE_CONTROL_NAME");
        if (processObject !== null) setProcessID(JSON.parse(processObject)["process-instance-id"]);
        if (questionObject !== null) {
            setQuestion(JSON.parse(questionObject));
            //setquestionId(JSON.parse(questionObject)["id"]); //get current question id
        }
        if (controlObject !== null) setControlName(JSON.parse(controlObject));

    }, []);

    useEffect(() => {
        if (processID === null) return;
        getUserTask();
    }, [processID]);

    function handleComplete() {
        submitAnswers();
    }
    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];


    async function getUserTask() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {


                    const [qNo, ...title] = question.title.split('.');
                    setquestionId(qNo);
                    const taskArray = response.data["active-user-tasks"]["task-summary"];
                    const task = taskArray.find(x => x["task-name"] === title.join('.'));
                    const taskID = task["task-id"];
                    setTaskInstanceId(taskID);

                    getTaskDetail(taskID);

                    if (task["task-status"] !== "InProgress") {
                        var data = JSON.stringify(true);

                        var config2 = {
                            method: 'put',
                            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/states/started`,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${keycloak.token}`
                            },
                            data: data
                        };

                        fetchInstance.apiRequest(config2)
                            .then(function (response) {
                                //started user task
                            })
                            .catch(function (error) {
                                // setLoading(false);
                                console.log(error);
                            });
                    }
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                goBack();
            });
    }

    async function getTaskDetail(taskID) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/contents/input`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const questionObj = JSON.parse(response.data.question);
                    const answerArr = response.data.answer !== undefined ? JSON.parse(response.data.answer) : null;
                    var subQuestions = [];
                    questionObj.subQuestions.forEach(element => {
                        const subQuestion = {
                            qID: `${questionObj.id}.${element.id}`,
                            qNum: `${questionObj.id}.${element.id}`,
                            qHeading: element.title,
                            qAnswer: answerArr !== null ? answerArr[questionObj.id - 1][`subAnswer${element.id}`] : null,
                            type: element.type
                        };

                        subQuestions.push(subQuestion);

                    });

                    const question = {
                        title: questionObj.mainQuestion,
                        qId: questionObj.id,
                        qNum: questionObj.id,
                        subQuestions: subQuestions,
                    };

                    setQuestions([question]);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }
    //save submitted answers
    async function submitAnswers() {
        const answers = questions[0].subQuestions;
        var ansObject = {};
        answers.forEach((answer, index) => {
            ansObject[`subAnswer${index + 1}`] = answer.qAnswer
        });

        var config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskInstanceId}/states/completed`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify(ansObject)
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                setLoading(false);
                window.sessionStorage.setItem("IS_CONTROL", JSON.stringify(false));
                goBack();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    }

    const handleOneAnswer = useCallback((val, parentIndex, index) => {
        let newQuestions = [...questions];
        // newQuestions[parentIndex].touched = true;
        newQuestions[0].subQuestions[index].qAnswer = val;
        setQuestions(newQuestions);
    }, [questions]);

    let userAnsweredAllQuestions = questions.every((question) => {
        return question.subQuestions.every((subQuestion) => {
            return subQuestion.qAnswer !== null && subQuestion.qAnswer !== "";
        });
    });

    if (!loading) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", position: "relative" }}>
                            <E8QuestionsStepper questions={questions} handleAnswer={handleOneAnswer}></E8QuestionsStepper>
                            <ActionsWrapper hasOne>
                                <Button $red onClick={(e) => goBack()} style={{ margin: "10px" }}>
                                    close
                                </Button>
                                <Button disabled={!userAnsweredAllQuestions} $success onClick={(e) => handleComplete()} style={{ margin: "10px" }}>
                                    submit
                                </Button>
                            </ActionsWrapper>
                        </Box>
                    </Grid>
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>ACSC Essential Eight - {controlName || ''} Progress {questions[0]?.["qNum"] || ''} - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    ACSC Essential Eight - {controlName}
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default E8SubQuestions;
