import styled, { css } from "styled-components";
import { Grid, Button, ListItemButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

//reuse 
export const Wrapper = styled.div`
    text-align: center;
`;

export const MainGrid = styled(Grid)`
    min-height: 310px;
`;

export const StatsWrapper = styled.div`
    padding-top: 17px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-top: 28px;
    }
`;

export const SubHeading = styled.div`
    text-align: center;
    font-size:20px;
`;

export const StyledSubHeading = styled.div`
    text-align: center;
    font-size:16px;
    padding-top: 20px;
    padding-bottom:20px;
`;

export const StyledHeading = styled.div`
    text-align: left;
    font-size:16px;
    padding-top: 20px;
    padding-bottom:20px;

    span.agregateScore{
        color: #ff4500;
        font-weight: bold;
    }
`;

export const StyledTableContainer = styled.div`
    &.MuiPaper-root-MuiTableContainer-root {
        width: 300px;
        background-color: #121212;
        border-radius: 4px;
    }
    

`;

export const StyledTable = styled.table`
    width: 100%; 
    border-collapse: collapse;
    padding: 70px;

    tr,
    th {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;
        text-align: left;       
        padding: 8px 18px;

        @media (max-width: 599.98px) {
            padding: 5px 0px;
            border: 0.5px solid #ddd;
            font-size: 10px;
        }

        @media (max-width: 320px) {
            font-size: 9px;
            max-width: 45px;
        }
    }

    tr {
        &:nth-child(even) {
            background-color: $bg-color;
        }
        padding: 10px;
    }

    td{
        font-weight:100;
    }
    th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ckw3mp-MuiTableCell-root {
        color:white;
    }
    .sc-hAiVDd.cYIiLW{
        margin: 70px;
    }
    .score {
        color: yellow;
    }
`;

export const StyledSmallTable = styled.table`
    width: 300px; 
    border-collapse: collapse;
    padding: 70px;

    .agregateScore{
        color: #ff4500;
        font-weight: bold;
    }
    
`;


export const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin-left: 0px;
        width: 100%;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
            margin-left: 50px;
        }
        @media screen and (max-width: 400px) {
            margin-left: 0;
        }
        & .MuiFormGroup-root {
            flex-direction: row;
            justify-content: flex-start;
            gap: 15px 50px;
            max-width: 90%;
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
                max-width: 100%;
            }

            ${({ $between }) =>
        $between &&
        css`
                    justify-content: space-between;
                    gap: 0px;
                `}
        }
    }
`;

export const CustomAccordion = styled(Accordion)`
    &.MuiPaper-root {
        background: transparent;
        box-shadow: none;
        &::before {
            content: unset;
        }
        &.Mui-expanded {
            margin: 0;
            min-height: 18px;
        }
        & .MuiAccordionSummary-root {
            &.Mui-expanded {
                min-height: 18px;
            }
            & .MuiTypography-root {
                font-family: "Rubik", sans-serif;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: ${({ theme }) => theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : theme.palette.text.primary};
            }
        }
        & .MuiAccordionSummary-content {
            &.Mui-expanded {
                margin: 12px 0;
            }
        }
    }
    .css-i4bv87-MuiSvgIcon-root{
        margin-top: -20px;
    }
    .MuiAccordionSummary-expandIconWrapper.css-19hn6xk-MuiAccordionSummary-expandIconWrapper, .css-19hn6xk-MuiAccordionSummary-expandIconWrapper{
        margin-top: -80px;
    }

`;

export const CustomSubQAccordionDetails = styled(AccordionDetails)`
    &.MuiAccordionDetails-root {
        padding: 0 16px;
        overflow: auto;
        max-height: calc(358px * 6);
        min-height: 0;
        margin-right: 20px;
        &::-webkit-scrollbar {
            height: 7px;
            width: 8px;
            background-color: ${({ theme }) => theme.palette.action.selected};
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            margin-left: 2px;
            background: #2f80ed;
            border-radius: 10px;
            cursor: pointer;
        }
    }
   



`;

export const QuestionHeadWrapper = styled.div`
    
    margin-bottom: 43px;
    text-align:left;

    ${({ $smsize }) =>
        $smsize &&
        css`
            align-items: center;
            margin-bottom: 31px;
        `}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        margin-bottom: 20px;
        ${({ $smsize }) =>
        $smsize &&
        css`
            margin-bottom: 16px;
        `}
    }
`;

export const QuestionNumber = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width:32px;
    height:32px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #46a11b;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 16px;

    ${({ $smsize }) =>
        $smsize &&
        css`
            width: 24px;
            height: 24px;
        `}
`;

export const SubQuestionNumber = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width:28px;
    height:28px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #000;
    color: #fff;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 16px;
    padding: 20px;
    margin-top:20px;
    margin-left:-17px;

    ${({ $smsize }) =>
        $smsize &&
        css`
            width: 24px;
            height: 24px;
        `}

`;

export const ButtonWrapper = styled.div`
display: flex;
justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
align-items: center;
position: fixed;
bottom: 50px;
right: 50px;

margin-top: 64px;
@media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
    padding: 0;
    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-evenly")};
}
@media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
    right: 20px;
    bottom: 20px;

    justify-content: ${(props) => (props.hasOne ? "flex-end" : "space-between")};
}
`;

export const StyledCompleteContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;