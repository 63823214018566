import styled, { css } from "styled-components";

export const CommentBoxContainer = styled.div`
    padding: 30px 16px;
    background: ${({ theme }) => theme.palette.mode === "light" ? "#fff" : "#1F2128"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: left;
`;

export const Heading = styled.h3`
    display: inline-flex;
    position: relative;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: ${({ theme }) =>
        theme.palette.mode === "light"
            ? "#4F4F4F"
            : theme.palette.text.primary};
    max-width: 85%;
    margin-top: 3px;
    letter-spacing: 0.46px;

    ${({ $smsize }) =>
        $smsize &&
        css`
            font-size: 18px;
            line-height: 31px;
            max-width: 100%;
            margin-top: 0;
            letter-spacing: 1px;
        `}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md - 1}px) {
        font-size: 20px;
        line-height: 26px;

        ${({ $smsize }) =>
        $smsize &&
        css`
            font-size: 16px;
            line-height: 25px;
        `}
        max-width: 100%;
    }
`;