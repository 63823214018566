import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';

import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import Table from '../../../../../components/UI/Table/Table';

import { AiOutlineSync, AiOutlineDownload } from "react-icons/ai";

const DocumentsTable = (props) => {

    const { documentCaseData, documentList, documentContentFetching, createNewCaseLoading, generateDocumentLoading, generateDocumentDocumentID } = useSelector((state) => state.documents);

    const disableActions = documentContentFetching || createNewCaseLoading || generateDocumentLoading;

    const columns = [
        {
            id: "lastModifiedDateTime",
            header: "Date Created",
            sort: true,
            width: '150px',
            sortFn: (a, b) => {
                const dateA = new Date(a.lastModifiedDateTime);
                const dateB = new Date(b.lastModifiedDateTime);

                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                return dayjs(row.lastModifiedDateTime).format('DD/MM/YYYY')
            }
        },
        // {
        //     id: "type",
        //     header: "Type",
        //     sort: true,
        //     width: '90px',
        //     sortFn: (a, b) => a.type.localeCompare(b.type)
        // },
        // {
        //     id: "risk",
        //     header: "Risk",
        //     sort: true,
        //     width: '90px',
        // sortFn: (a, b) => {
        //     const getOrder = (category) => {

        //         // Assign weights based on sequence
        //         switch (category) {
        //             case 'EXTREME':
        //                 return 40;
        //             case 'HIGH':
        //                 return 30;
        //             case 'MEDIUM':
        //                 return 20;
        //             case 'LOW':
        //                 return 10;
        //             default:
        //                 return 0;
        //         }
        //     };

        //     const orderA = getOrder(a.risk);
        //     const orderB = getOrder(b.risk);

        //     return orderA - orderB;
        // }
        // },
        // {
        //     id: "version",
        //     header: "Version",
        //     renderCell: ({ row }) => {
        //         return (
        //             <Box>
        //                 <Typography noWrap variant='body2'>
        //                     {row.version || ''}
        //                 </Typography>
        //                 <Typography noWrap variant='caption'>
        //                     {/* {"list"} */}
        //                 </Typography>
        //             </Box>
        //         )
        //     }
        // },
        {
            flex: 1,
            id: "filename",
            header: "Name",
            minWidth: "250px",
            searchable: true,
        },
        // {
        //     id: "dueDate",
        //     header: "Due Date",
        //     renderCell: ({ row }) => {
        //         return (
        //             <Box>
        //                 <Typography noWrap variant='body2'>
        //                     {row.dueDate || ''}
        //                 </Typography>
        //                 <Typography noWrap variant='caption'>
        //                     {/* {"the data driver"} */}
        //                 </Typography>
        //             </Box>
        //         )
        //     }
        // },
        // {
        //     id: "status",
        //     header: "Status",
        //     sort: true,
        //     sortFn: (a, b) => a.type.localeCompare(b.type)
        // },
        {
            id: "actions",
            header: "",
            disableColumnFilter: true,
            pinRight: true,
            width: "60px",
            renderCell: ({ row }) => {
                let loading = documentContentFetching || (generateDocumentLoading && generateDocumentDocumentID === row.id);
                if (loading) {
                    return <MuiLoadingButton as={IconButton} loading={loading}>
                        <AiOutlineSync />
                    </MuiLoadingButton>
                }

                if (row.isDocumentGenerated) {
                    return <MuiLoadingButton
                        as={IconButton}
                        onClick={() => props.downloadDocument(row)}
                        title='Download'
                        disabled={disableActions}
                    >
                        <AiOutlineDownload color={'#46a11b'} />
                    </MuiLoadingButton>
                }
                return <MuiLoadingButton
                    as={IconButton}
                    onClick={() => props.generateDocument(row)}
                    title='Generate Document'
                    disabled={disableActions}
                >
                    <AiOutlineSync />
                </MuiLoadingButton>
            }
        },
    ];

    return (
        <DomainCard>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h5">
                        Documents List
                    </Typography>
                </Grid>
            </Grid>
            <Table
                tableTitle={
                    <>
                        {
                            !!(documentCaseData && documentCaseData['case-started-at']) && <small>
                                {`Generated on : ${dayjs(new Date(documentCaseData['case-started-at'])).format('DD/MM/YYYY')}`}
                            </small>
                        }
                    </>
                }
                tableData={documentList}
                columns={columns}
                actionButtons={
                    <>
                        <Box>
                            <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => { props.regenerateCase() }}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />
        </DomainCard>
    );
};

export default DocumentsTable;