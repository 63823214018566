import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@mui/material";
import styled from "styled-components";
import NumberTextField from "./NumberTextField";
import { useState } from "react";
import TextValidationTextField from "./TextValidationTextField";
import DynamicDropdownList from "./DynamicDropDownList";

const FrameChild = styled.div`
    position: relative;
    border-radius: 50%;
    background-color: #4d9f3f;
    width: 32px;
    height: 32px;
    z-index: 0;
  `;
const Div = styled.div`
    position: absolute;
    margin: 0 !important;
    top: 8.5px;
    left: 7px;
    letter-spacing: 0.4px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 15px;
    flex-shrink: 0;
    z-index: 1;
  `;
const EllipseParent = styled.div`
    width: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  `;
const DefineBusinessImpact = styled.b`
    flex: 1;
    position: relative;
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 24px;
    display: flex;
    text-align: left;
    align-items: center;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
    word-break:break-all;
  `;

  const DefineBusinessImpact2 = styled.b`
    flex: 1;
    position: relative;
    font-size: 17px;
    letter-spacing: 0.4px;
    line-height: 24px;
    display: flex;
    text-align: left;
    align-items: center;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};
  `;

const QuestionTitle1 = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
  `;
const FrameItem = styled.div`
    position: relative;
    border-radius: 50%;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#ccc" : "#000")};
    width: 32px;
    height: 32px;
    z-index: 0;
  `;
const Div1 = styled.div`
    position: absolute;
    margin: 0 !important;
    top: 8.5px;
    left: 5px;
    letter-spacing: 0.4px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 15px;
    flex-shrink: 0;
    z-index: 1;
    font-size: 12px;
  `;
const QuestionTitle11 = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
  `;
const TextAreaoutlined = styled(TextField)`
    align-self: stretch;
  `;
const TextAreaoutlined2 = styled(TextField)`
    align-self: stretch;
    flex: 1;
    @media screen and (max-width: 420px) {
      flex: unset;
      align-self: stretch;
    }
  `;
const TextAreaoutlinedGroup = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
  `;
const TextAreaoutlinedParent = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 49px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
  `;
const TextAreaoutlined1 = styled(FormControl)`
    align-self: stretch;
  `;
const SelectoutlinedWrapper = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
  `;
const QuestionTitleParent = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 17px;
  `;
const BusinessimpactlevelRoot = styled.div`
    position: relative;
    border-radius: 8px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 33px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-family: Rubik;
    padding-bottom: 35px;
    padding-left: 37px;
      padding-right: 37px;
  `;
const BusinessImpactLevel = ({ index, question, handleTextChange, assetName }) => {

  return (
    <BusinessimpactlevelRoot key={question["qNum"]}>
      <QuestionTitleParent>
        <QuestionTitle1>
          <EllipseParent>
            <FrameChild />
            <Div>2</Div>
          </EllipseParent>
          <DefineBusinessImpact>
            Define business impact level for asset named "{assetName || ''}"
          </DefineBusinessImpact>
        </QuestionTitle1>
        <QuestionTitle11>
          <EllipseParent>
            <FrameItem />
            <Div1>{question["questions"][0]["qNum"].slice(0,-2)}</Div1>
          </EllipseParent>
          <DefineBusinessImpact2>
            Risk Impact - {question["title"]}
          </DefineBusinessImpact2>
        </QuestionTitle11>
          <Grid container spacing={2} style={{ paddingLeft: "26px" }}>
            {
              question["questions"] && (
                question["questions"].map((subQ, qIndex) => {

                  return <Grid item xs={12} md={6} key={subQ["qNum"]}>
                    {
                      subQ['type'] === "text" && (
                        <TextValidationTextField parentIndex={index} index={qIndex} data={subQ} value={subQ["qAnswer"] || ''} onChange={handleTextChange} />
                      )
                    }
                    {
                      subQ['type'] === "option" && (
                        <DynamicDropdownList isRequired={true} placeHolder={subQ["placeholder"]} label={`${subQ["qNum"]}. ${subQ["qHeading"]}`} data={subQ["qAvailableAnswers"]} value={subQ['qAnswer']} parentIndex={index} index={qIndex} onChange={handleTextChange} />
                      )
                    }
                  </Grid>
                })
              )

            }
          </Grid>
      </QuestionTitleParent>
    </BusinessimpactlevelRoot>
  );
};

export default BusinessImpactLevel;
