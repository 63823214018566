import React, { useCallback, useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";

import { CardHead, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer } from "../BusinessImpactAssessmentLandingPage.styled";
import { CONTAINER_ID, IARadvanceProcess } from "../../../../../store/auth-context";
import InformationSecurityClassificationTable from "./InformationSecurityClassificationTable";
import { useHistory } from "react-router-dom";
import { completeUserTask, getCaseFileData, getMileStone, getProcessIDByCase, getUserTask, reopenCase, startMileStone, startUserTask } from "../../TRA/TRAApi";
import * as fetchInstance from "../../../../../utility/fetch-instance";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";

import { Button } from "../../../../../components/UI/Button/Button.styled";
import { ContainerWrapper } from "../../../../../components/ErrorPage/NotFoundPage.styled";
import doneAnimation from "../../../../../assets/animations/done.json";
import logo from "../../../../../images/logo0.2.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const WarningDialog = ({ open, toggle, onConfirm }) => {

    return <Dialog
        open={open}
        onClose={toggle}
        fullWidth
        PaperProps={{ sx: { borderRadius: "12px" } }}
    >
        <ContainerWrapper style={{ margin: 0 }}>
            <Grid container>
                <Grid item xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <Lottie loop animationData={doneAnimation} style={{ marginTop: "-50px" }} />
                    <img src={logo} width="60%" style={{ marginTop: "-50px", marginLeft: "19%" }} alt='' />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}>
                    <br /><h1>Asset Register Incomplete!</h1><br />
                    {"Complete asset register first before the Business Impact Assessment."}
                    <Button $success upper="true" style={{ width: "80%", margin: "10%" }} onClick={onConfirm}>
                        Edit Asset Register
                    </Button>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </ContainerWrapper>
    </Dialog>
}

const InformationSecurityClassificationCard = () => {

    // ** Redux states
    const { loading, data } = useSelector((state) => state.informationAssetRegisters);

    const { keycloak } = useKeycloak();
    const history = useHistory();

    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const [warningDialogData, setWarningDialogData] = useState(null);
    const [disableActions, setDisableActions] = useState(false);
    const [disableActionMeta, setDisableActionMeta] = useState(null);

    const toggleDisableAction = (meta = null) => {
        setDisableActions(prev => !prev);
        setDisableActionMeta(meta)
    }

    const toggleWarningDialog = (e, data = null) => {
        e?.preventDefault();
        setWarningDialogOpen(prev => !prev);
        setWarningDialogData(data);
    }

    function handleNavigate(caseID) {
        history.push(`/it-security/business-impact-assessment/edit/${caseID}`);
    }

    function handleEditIAR(caseID) {
        history.push(`/it-security/information-asset-register/edit/${caseID}`);
    }

    // function updateDataToNewCase(oldCaseData, newCaseID) {
    //     getMileStone(CONTAINER_ID, keycloak.token, newCaseID).then((res) => {
    //         if (res.success === true) {
    //             try {
    //                 let tempmilestones = [];
    //                 res.data["milestones"].forEach((mileStone, index) => {
    //                     const msName = mileStone["milestone-name"];
    //                     if (msName !== "init data" && msName !== "end") {
    //                         let msObject = {
    //                             "id": msName.split(".")[0],
    //                             "name": msName
    //                         }
    //                         tempmilestones.push(msObject);
    //                     }
    //                 })
    //                 tempmilestones.sort((a, b) => a.id - b.id);
    //                 let mileStones = [...tempmilestones];

    //                 getCaseFileData(CONTAINER_ID, keycloak.token, newCaseID).then(async (res) => {
    //                     if (res.success === true) {
    //                         let dataObject = res.data;
    //                         try {
    //                             let tempQuestions = [];
    //                             Object.entries(dataObject).forEach(([key, value]) => {
    //                                 if (key.includes('Questions')) {
    //                                     let questionArr = JSON.parse(value);
    //                                     if (Array.isArray(questionArr)) {
    //                                         tempQuestions.push(...questionArr);
    //                                     } else {
    //                                         tempQuestions.push(questionArr);
    //                                     }
    //                                 }
    //                             });

    //                             tempQuestions.sort((a, b) => {
    //                                 const aParts = a['questions'][0].qNum.split('.').map(Number);
    //                                 const bParts = b['questions'][0].qNum.split('.').map(Number);

    //                                 for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    //                                     const aValue = aParts[i] || 0;
    //                                     const bValue = bParts[i] || 0;

    //                                     if (aValue !== bValue) {
    //                                         return aValue - bValue;
    //                                     }
    //                                 }

    //                                 return 0;
    //                             })

    //                             let questions = [...tempQuestions];

    //                             let tempAnswers = [];
    //                             Object.entries(dataObject).forEach(([key, value]) => {

    //                                 if (key.startsWith('a-') || key == "assetInfo") {
    //                                     let ansObject = {
    //                                         key: key,
    //                                         data: JSON.parse(value)
    //                                     };

    //                                     tempAnswers.push(ansObject);
    //                                 }
    //                             });
    //                             let answers = [...tempAnswers];

    //                             // Start milestone
    //                             let mileStone = "1";
    //                             const ms = mileStones.find(ms => ms.id === mileStone);

    //                             try {
    //                                 const res = await startMileStone(CONTAINER_ID, keycloak.token, newCaseID, ms.name);
    //                                 if (!res.success) {
    //                                     // Handle error if necessary
    //                                     return;
    //                                 }

    //                                 const res2 = await getProcessIDByCase(CONTAINER_ID, keycloak.token, newCaseID);
    //                                 if (!res2.success) {
    //                                     // Handle error if necessary
    //                                     return;
    //                                 }

    //                                 const res3 = await getUserTask(CONTAINER_ID, keycloak.token, res2.data['process-instance'][0]['process-instance-id']);
    //                                 if (res3.success) {
    //                                     const taskArr = res3.data["active-user-tasks"]["task-summary"];
    //                                     const dataObj = JSON.parse(oldCaseData['case-data']['assetInfoAdvance']);

    //                                     let found, data;
    //                                     const taskName = "Asset information";
    //                                     found = taskArr.find(t => t['task-name'] === taskName);
    //                                     data = {
    //                                         "assetName": dataObj["assetName"] || "",
    //                                         "assetCategory": dataObj["assetCategory"] || "",
    //                                         "assetOwner": dataObj["assetOwner"] || "",
    //                                         "assetdescription": dataObj["assetdescription"] || "",
    //                                         "creating": dataObj["creating"] || "",
    //                                         "storing": dataObj["storing"] || "",
    //                                         "consuming": dataObj["consuming"] || "",
    //                                         "riskOwner": dataObj["riskOwner"] || ""
    //                                     };


    //                                     if (found) {
    //                                         if (found["task-status"] === "InProgress") {
    //                                             const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, found['task-id'], data);
    //                                             handleNavigate(newCaseID)
    //                                         } else {
    //                                             const res4 = await startUserTask(CONTAINER_ID, keycloak.token, found['task-id']);
    //                                             if (res4.success) {
    //                                                 const res5 = await completeUserTask(CONTAINER_ID, keycloak.token, found['task-id'], data);
    //                                                 handleNavigate(newCaseID)
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             } catch (error) {
    //                                 // Handle errors if necessary
    //                                 console.log(error);
    //                                 setEditLoading(false);
    //                             }


    //                         } catch (error) {
    //                             console.log(error);
    //                             setEditLoading(false);
    //                         }

    //                     }
    //                 })


    //             } catch (error) {
    //                 console.log(error)
    //                 setEditLoading(false);
    //             }
    //         }
    //     })
    // }

    // function reopenCaseProcess(data) {
    //     let config = {
    //         method: 'get',
    //         url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${data.caseID}/processes/instances?page=0&pageSize=10&sortOrder=true`,
    //     };

    //     fetchInstance.apiRequest(config)
    //         .then(function (response) {
    //             //check status code
    //             if (response.status === 200) {

    //                 let processID = response.data["process-instance"][0]["process-instance-id"];

    //                 let config2 = {
    //                     method: 'get',
    //                     url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}/processes`,
    //                 };

    //                 fetchInstance.apiRequest(config2)
    //                     .then(function (response) {
    //                         //check status code
    //                         if (response.status === 200) {
    //                             if (response.data["process-instance"].length > 0) {
    //                                 let newCaseID = response.data["process-instance"][0]["correlation-key"];
    //                                 updateDataToNewCase(data, newCaseID);
    //                             }
    //                         }
    //                     })
    //                     .catch(function (error) {
    //                         setEditLoading(false);
    //                         console.log(error);
    //                     });

    //             }
    //         })
    //         .catch(function (error) {
    //             setEditLoading(false);
    //             console.log(error);
    //         });
    // }

    function isStepOneCompleted(data) {
        return data['case-data'].hasOwnProperty('assetInfo');
    }

    function handleEditClick(data) {
        const { status, caseID } = data;
        if (status === 1) {
            if (isStepOneCompleted(data)) {
                handleNavigate(caseID)
            } else {
                toggleWarningDialog(null, data);
            }
        } else if (status === 2) {
            // setEditLoading(true);
            // reopenCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, caseID).then((response) => {
            //     if (response.success) {
            //         reopenCaseProcess(dummyData);
            //     }
            // });
            toggleDisableAction({ action: 'edit', caseID: caseID })
            reopenCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, caseID)
                .then((response) => {
                    if (response.success) {
                        handleNavigate(caseID);
                    } else {
                        toast.error(response?.error?.message || "Failed to reopen case. Please try again later");
                    }
                })
                .catch((error) => {
                    toast.error(error?.message || "Failed to reopen case. Please try again later");
                })
                .finally(() => {
                    toggleDisableAction();
                });
        }
    }

    const onConfirmWarning = useCallback(() => {
        if (warningDialogData) {
            handleEditIAR(warningDialogData.caseID)
        }
    }, [warningDialogData])

    return (
        <>
            <ProgressCard>
                <ProgressCardContent>
                    <ProgressCardContentTop>
                        <CardHead>Information Security Classification</CardHead>
                        <ProgressContainer>
                            {
                                (!loading && data?.length === 0) ? <ListItem>
                                    <Alert severity="info">Information Security Classification will be created once you add Information Asset Register.</Alert>
                                </ListItem> : <InformationSecurityClassificationTable
                                    handleEditClick={handleEditClick}
                                    disableActions={disableActions}
                                    disableActionMeta={disableActionMeta}
                                />
                            }
                        </ProgressContainer>
                    </ProgressCardContentTop>
                </ProgressCardContent>
            </ProgressCard>

            <WarningDialog
                open={warningDialogOpen}
                toggle={toggleWarningDialog}
                onConfirm={onConfirmWarning}
            />
        </>
    );
};

export default InformationSecurityClassificationCard;