import React, { useState } from "react";
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import "../pages/Dashboard/nested/Information/introjs-modern.css";

export const GuidanceKeyPrefix = {
    MATURITY_ASSESSMENT: "guide_maturityAssessment",
    DASHBOARD: "guide_dashboard",
}

const GuideContext = React.createContext({
    guideDashBoardState: false,
    isIntroActive: false,
    toggleGuideDashboardMode: () => { },
    launchTour: () => { },
    hasTakenTourBefore: () => { },
});

export const GuideContextProvider = (props) => {

    const intialGuideDashboard = localStorage.getItem("guideDashboard") || false;
    const [guideDashboard, setGuideDashboard] = useState(intialGuideDashboard);
    const [isIntroActive, setIntroActive] = useState(false);

    const toggleDashboardGuideHandler = (value) => {
        setGuideDashboard(() => {
            localStorage.setItem("guideDashboard", value);
            return value;
        });
    };

    const hasTakenTourBefore = (guidanceKey) => {
        let guidanceKeyState = localStorage.getItem(guidanceKey) ?? "false";
        return guidanceKeyState === "true";
    }

    const launchTour = (guidanceKey, steps) => {
        setIntroActive(true);
        const intro = IntroJs();
        const guidanceKeyState = localStorage.getItem(guidanceKey) ?? "false";

        const allowExit = false;
        intro.setOptions({
            disableInteraction: true,
            steps: steps,
            showProgress: true,
            showBullets: false,
            showCloseButton: false,
            hidePrev: true,
            showStepNumbers: true,
            //positionPrecedence: ['top', 'bottom', 'left', 'right'],
            overlayOpacity: 0.91
        });

        if (allowExit === false) {
            intro.onbeforeexit(function () {
                setIntroActive(false);
                let canExit = false;
                if (guidanceKeyState === "true") {
                    canExit = true;
                }
                // returning false means don't exit the intro
                return canExit;
            });
        }

        // set the oncomplete callback function
        intro.oncomplete(function () {
            setIntroActive(false);
            // check & store if the user has completed the tour
            if (guidanceKeyState !== "true") {
                localStorage.setItem(guidanceKey, "true");
            }
            intro.exit(true);
        });

        intro.start();
    }

    const contextValue = {
        guideDashBoardState: guideDashboard,
        isIntroActive: isIntroActive,
        toggleGuideDashboardMode: toggleDashboardGuideHandler,
        launchTour: launchTour,
        hasTakenTourBefore: hasTakenTourBefore,
    };

    return (
        <GuideContext.Provider value={contextValue}>
            {props.children}
        </GuideContext.Provider>
    );
};

export default GuideContext;
