import socketIOClient from 'socket.io-client';

class SocketIOInstance {
  constructor() {
    if (!SocketIOInstance.instance) {
      this.socket = null;
      this.jwtToken = null;
      SocketIOInstance.instance = this;
    }

    return SocketIOInstance.instance;
  }

  connect(endpoint, jwtToken) {
    if (this.socket) {
      // Already connected, so return.
      return;
    }

    this.jwtToken = jwtToken;

    this.socket = socketIOClient(endpoint, {
      extraHeaders: {
        jwtToken: this.jwtToken,
      }
    });

    this.socket.on('connect', () => {
      // console.log('Connected to Socket.IO server');
    });

    this.socket.on('disconnect', () => {
      // console.log('Disconnected from Socket.IO server');
    });
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    } else {
      // console.error('Socket is not connected.');
    }
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    } else {
      // console.error('Socket is not connected.');
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

const instance = new SocketIOInstance();
export default instance;
