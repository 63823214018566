import React from "react";
import Box from "@mui/material/Box";
import { List, ListItem, Typography } from "@mui/material";
import { UserTypeCard1, Title, DescriptionGroup, Description, Icon1 } from "./UserTypeCard.styled";

const UserTypeCardV2 = ({ title, description, imgSrc, borderColor, isRippleEffectEnabled }) => {

  return (
    <Box sx={{ borderColor: borderColor }}>
      <Box
        sx={{
          height: '248px',
        }}
      >
        <img
          src={imgSrc}
          loading="lazy"
          alt={"Icon"}
          style={{
            height: '320px',
            width: '100%',
            objectFit: 'contain',
            zIndex: 2,
            position: 'relative',
            top: '-72px'
          }}
        />
      </Box>
      <Box sx={{ padding: '0 32px 18px' }}>
        <Typography variant="h6" textAlign="center" textTransform="capitalize">{title}</Typography>
        <Box>
          <List sx={{ listStyleType: 'disc' }}>
            {
              description ? description.map((desc, index) => {
                return (
                  <ListItem sx={{ display: 'list-item', pl: 0 }} key={index}>
                    <Typography variant="body2" >
                      {desc}
                    </Typography>
                  </ListItem>
                )
              }) : ""
            }
          </List>
        </Box>
      </Box >
    </Box >
  );
}

export default UserTypeCardV2;