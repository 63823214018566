import { getCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, Threat } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleThreatHunting = (allow) => {
  return { type: 'TOGGLE_THREAT_HUNTING_PROGRESS', payload: allow };
};

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_THREAT_ALLOW_FETCH', payload: allow };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_THREAT_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractThreatDataFromCaseFile(caseFile) {
  let newThreat = {};

  let threatDetail = caseFile["case-file"]["case-data"]["threatData"];
  let threatID = caseFile["case-file"]["case-data"]["threatID"];
  let traID = caseFile["case-file"]["case-data"]["traID"];
  if (threatDetail) {
    newThreat = { ...threatDetail };
  }

  newThreat["date"] = caseFile["case-started-at"] || 0;
  newThreat["status"] = caseFile["case-status"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);

  newThreat["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  newThreat.caseID = caseFile["case-id"];
  // newThreat.threatID = beautifyUniqueID(threatID);
  newThreat.threatID = beautifyUniqueID(caseFile["case-id"]);
  newThreat.traID = traID;
  return newThreat;
}

export const getThreatData = async (caseFile) => {
  let newThreat = extractThreatDataFromCaseFile(caseFile);
  newThreat.caseID = caseFile["case-id"];
  return newThreat;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().threats; // Get the allowFetch value from the state
    const { data: solutionBuildingBlocks } = getState().solutionBuildingBlocks;
    const { data: solutions } = getState().solutions;
    const { data: projects } = getState().projects;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-threats');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_THREAT_DATA_SUCCESS', payload: { data: cachedData } });

      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let threats = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, Threat, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, Threat, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const threatData = [...closedData, ...openedData];

        if (threatData?.length > 0) {
          threatData.forEach((threat, index) => {
            let newThreat = {};

            let threatDetail = threat["case-file"]["case-data"]["threatData"];
            let threatID = threat["case-file"]["case-data"]["threatID"];
            let traID = threat["case-file"]["case-data"]["traID"];
            if (threatDetail) {
              newThreat = { ...threatDetail };
            }

            newThreat["date"] = threat["case-started-at"] || 0;
            newThreat["status"] = threat["case-status"] || 0;
            let cDate = new Date(threat["case-started-at"]);

            newThreat["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            newThreat.caseID = threat["case-id"];
            // newThreat.threatID = beautifyUniqueID(threatID);
            newThreat.threatID = beautifyUniqueID(threat["case-id"]);
            newThreat.traID = traID;

            // Find and add hierarchy data
            if (solutionBuildingBlocks?.length > 0) {
              let foundSolutionBuildingBlocks = solutionBuildingBlocks.find((sbb) => sbb.caseID === newThreat.solutionBuildingBlockID);
              if (foundSolutionBuildingBlocks) {
                newThreat.solutionBuildingBlock = foundSolutionBuildingBlocks;

                // Find and add solution data
                if (solutions?.length > 0) {
                  let foundSolution = solutions.find((s) => s.caseID === foundSolutionBuildingBlocks.solutionID);
                  if (foundSolution) {
                    newThreat.solution = foundSolution;
                    newThreat.solutionID = foundSolution.caseID;

                    // Find and add project data
                    if (projects?.length > 0) {
                      let foundProject = projects.find((p) => p.caseID === foundSolution.projectID);
                      if (foundProject) {
                        newThreat.project = foundProject;
                        newThreat.projectID = foundProject.caseID;
                      }
                    }
                  }
                }
              }
            }

            if (newThreat?.title) {
              threats.push(newThreat);
            }
          })

          threats.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_THREAT_DATA_SUCCESS', payload: { data: threats }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'THREAT_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-threats', threats);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_THREAT_FETCH_COUNTER' });
        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_THREAT_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().threats; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;
    const { data: solutions } = getState().solutions;
    const { data: solutionBuildingBlocks } = getState().solutionBuildingBlocks;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-threats');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let threats = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, Threat, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, Threat, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const threatData = [...closedData, ...openedData];

      if (threatData) {
        threatData.forEach((threat, index) => {
          let newThreat = {};

          let threatDetail = threat["case-file"]["case-data"]["threatData"];
          let threatID = threat["case-file"]["case-data"]["threatID"];
          let traID = threat["case-file"]["case-data"]["traID"];
          if (threatDetail) {
            newThreat = { ...threatDetail };
          }

          newThreat["date"] = threat["case-started-at"] || 0;
          newThreat["status"] = threat["case-status"] || 0;
          let cDate = new Date(threat["case-started-at"]);

          newThreat["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          newThreat.caseID = threat["case-id"];
          // newThreat.threatID = beautifyUniqueID(threatID);
          newThreat.threatID = beautifyUniqueID(threat["case-id"]);
          newThreat.traID = traID;

          if (solutionBuildingBlocks?.length > 0) {
            let foundSolutionBuildingBlocks = solutionBuildingBlocks.find((sbb) => sbb.caseID === newThreat.solutionBuildingBlockID);
            if (foundSolutionBuildingBlocks) {
              newThreat.solutionBuildingBlock = foundSolutionBuildingBlocks;

              // Find and add solution data
              if (solutions?.length > 0) {
                let foundSolution = solutions.find((s) => s.caseID === foundSolutionBuildingBlocks.solutionID);
                if (foundSolution) {
                  newThreat.solution = foundSolution;
                  newThreat.solutionID = foundSolution.caseID;

                  // Find and add project data
                  if (projects?.length > 0) {
                    let foundProject = projects.find((p) => p.caseID === foundSolution.projectID);
                    if (foundProject) {
                      newThreat.project = foundProject;
                      newThreat.projectID = foundProject.caseID;
                    }
                  }
                }
              }
            }
          }

          if (newThreat?.title) {
            threats.push(newThreat);
          }
        })

        if (cachedData?.length > 0) {
          threats = [...cachedData, ...threats];
        }

        threats.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_THREAT_DATA_SUCCESS', payload: { data: threats }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'THREAT_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_THREAT_FETCH_COUNTER' });
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-threats', threats);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_THREAT_DATA_ERROR', payload: error.message });
    }
  };
};

export const removeEmptyCase = (keycloak) => {
  return async (dispatch, getState) => {
    try {
      let cachedData = await getCachedData(keycloak.token, 'cached-threats');
      if (cachedData?.length > 0) {
        cachedData = cachedData.filter((c) => c["title"]);
        cachedData = cachedData.map((c) => ({ ...c }));

        cachedData.sort((a, b) => b["date"] - a["date"]);
      }

      dispatch({ type: 'FETCH_THREAT_DATA_SUCCESS', payload: { data: cachedData }, });

      // Cache the fetched data
      cacheData(keycloak.token, 'cached-threats', cachedData);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  }
}