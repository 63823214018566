import { Alert, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Collapse, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Pagination, Select, Stack, TextField, Typography } from "@mui/material";
import { DomainCard } from "./ApplicableControl.styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { useContext, useState } from "react";
import RiskChip from "./RiskChip";
import { RiskCardAction, RiskHeader } from "./RiskMainCard.styled";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddCircleOutlined';
import ThemeContext from "../../../../../../store/theme-context";
import EditIcon from '@mui/icons-material/Edit';
import Loader from "../../../../../../components/UI/Loader/Loader";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function RiskAssessmentCard(props) {
    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext.theme.palette.mode === "dark" ? true : false;
    const { risks, handleExpandClick, handleAddButton, handleEditClick, isLoading, isUpdating, refreshRiskRegister } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    const totalPages = Math.ceil(risks.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let renderedRisks = [];
    for (
        let index = startIndex;
        index < endIndex && index < risks.length;
        index++
    ) {
        const risk = risks[index];
        // Convert risk properties to lowercase for case-insensitive search
        const lowerCaseRiskTitle = risk.riskTitle?.toLowerCase() || "";
        const lowerCaseCiaClass = risk.ciaClass?.toLowerCase() || "";
        const lowerCaseThreatActors = risk.threatActors?.toLowerCase() || "";
        const lowerCaseResidualRiskRating = risk.inherentRiskRating?.toLowerCase() || "";
        if (
            lowerCaseRiskTitle.includes(searchTerm.toLowerCase()) ||
            lowerCaseCiaClass.includes(searchTerm.toLowerCase()) ||
            lowerCaseThreatActors.includes(searchTerm.toLowerCase()) ||
            lowerCaseResidualRiskRating.includes(searchTerm.toLowerCase())
        ) {
            renderedRisks.push(
                <div key={index}>
                    <RiskCardAction>
                        <Grid container spacing={{ xs: 1, md: 2 }}>
                            <Grid item xs={6} md={3}>
                                <Typography>
                                    {risk?.riskTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <RiskChip rating={risk.inherentRiskRating}></RiskChip>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                {
                                    risk?.riskTreatment === "Completed" ? <RiskChip rating={risk.residualRiskRating ? risk.residualRiskRating : risk.inherentRiskRating}></RiskChip> : ""
                                }
                            </Grid>
                            <Grid item xs={3} md={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                <Typography paddingLeft={"20px"}>
                                    {risk?.riskTreatment}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Typography paddingLeft={"20px"} sx={{ fontSize: 14 }}>
                                    {risk?.createdDate}
                                </Typography>
                                {/* {
                                    risk?.ResidualRisk ? risk?.status === 2 ? <Chip label="closed" /> : <Chip label="unsaved" /> : <Chip label="new" />
                                } */}
                            </Grid>
                        </Grid>
                        {
                            risk?.isUpdating ? "Updating..." : <IconButton onClick={(e) => handleEditClick(risk.caseID, risk.status)} aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        }

                        <ExpandMore
                            expand={risk.isExpanded}
                            onClick={(e) => handleExpandClick(index)}
                            aria-expanded={risk.isExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </RiskCardAction>
                    <Collapse in={risk.isExpanded} timeout="auto" unmountOnExit>
                        <Divider />
                        <CardContent style={{ paddingTop: "20px" }}>
                            <Grid container spacing={{ xs: 4, md: 4 }} >
                                <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Risk Category
                                    </Typography>
                                    <Chip label={risk.ciaClass}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Risk Description
                                    </Typography>
                                    <Typography>
                                        {risk.riskDescription}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Attacker Motivation
                                    </Typography>
                                    <Chip label={risk.attackerMotivation}></Chip>
                                </Grid>
                                {/* <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">
                                CIA Class
                            </Typography>
                            <Typography>
                                {risk.ciaClass}
                            </Typography>
                        </Grid> */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        System
                                    </Typography>
                                    <Typography>
                                        {risk.system}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Residual Likelihood
                                    </Typography>
                                    <Chip label={risk.residualLikelihood ? risk.residualLikelihood : risk.inherentLikelihood}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Residual Consequence
                                    </Typography>
                                    <Chip label={risk.residualConsequence ? risk.residualConsequence : risk.inherentConsequence}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Inherent Likelihood
                                    </Typography>
                                    <Chip label={risk.inherentLikelihood}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Inherent Consequence
                                    </Typography>
                                    <Chip label={risk.inherentConsequence}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Risk Category
                                    </Typography>
                                    <Chip label={risk.ciaClass}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Threat actors
                                    </Typography>
                                    <Chip label={risk.threatActors}></Chip>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                    <Typography variant="subtitle2" fontWeight={500}>
                                        Status
                                    </Typography>
                                    <Chip label={risk.riskTreatment}></Chip>
                                </Grid>
                                <ControlCard controls={risk.controls}></ControlCard>
                            </Grid>
                        </CardContent>
                    </Collapse>
                    <Divider />
                </div>
            );
        }
    }

    const noDataFound = (<RiskCardAction>
        <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={12}>
                No risk has been registered.
            </Grid></Grid>
    </RiskCardAction>);

    return <DomainCard>
        {
            isUpdating ? <Grid container spacing={2}>
                <Grid item xs={12} md={12}><Alert severity="info">Risk Register updating is in progress! In the meantime you won't be able to add or update your risks.</Alert></Grid>
            </Grid> : <></>
        }

        <Grid container spacing={2}>
            <Grid item xs={12} md={9}><Typography variant="h5" onClick={() => refreshRiskRegister}>Risk List</Typography></Grid>
            <Grid item xs={10} md={2} style={{ paddingBottom: "20px" }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={2} md={1} style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px' }} >
                {
                    isLoading ? <Loader height="30px" /> : <IconButton color="success"
                        onClick={handleAddButton} >
                        <AddIcon style={{ color: isDarkTheme ? "#fff" : "#000" }} /> </IconButton>
                }

            </Grid>


        </Grid>
        <RiskHeader container>
            <Grid item xs={4} md={3} sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, marginLeft: "-10px" }}>
                    Risk Title
                </Typography>
            </Grid>
            <Grid item xs={3} md={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, marginLeft: "-6px" }}>
                    Inherent Risk Rating
                </Typography>
            </Grid>
            <Grid item xs={4} md={2} sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, marginLeft: "-15px" }}>
                    Residual Risk Rating
                </Typography>
            </Grid>
            <Grid item xs={3} md={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, marginLeft: "-12px" }}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={4} md={2} sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500, marginLeft: "-30px" }}>
                    Last Updated Date
                </Typography>
            </Grid>
        </RiskHeader>
        <Divider />
        {renderedRisks.length === 0 ? noDataFound : <>{renderedRisks}<CardActions >
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
            />
            <Select
                variant="standard"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                label="Items per page"
            >

                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
            </Select>
        </CardActions></>}

    </DomainCard>;
}

export default RiskAssessmentCard;

function ControlCard({ controls }) {

    const [isHidden, setHidden] = useState(true);

    function handleControls(hidden) {
        setHidden(!hidden);
    }

    return (<Grid item xs={12} md={12} style={{ paddingTop: "16px" }}>
        {
            !isHidden && (
                <Typography variant="subtitle2" onClick={() => handleControls(isHidden)}>Applicable Controls </Typography>
            )
        }
        {
            isHidden && (
                <Button color="success" variant="outlined" style={{ textTransform: "capitalize" }} onClick={() => handleControls(isHidden)}>Show Applicable Controls..</Button>
            )
        }
        {!isHidden ? controls ? controls.length > 0 ? (
            <Grid container spacing={1}>
                {controls.map((control) => (
                    <Grid item key={control.key}>
                        <Chip label={`${control.controlID} : ${control.controlHeading}`} variant="outlined" />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography variant="body1">None</Typography>
        ) : "" : ""}
    </Grid>);
} 