/* eslint-disable no-unused-vars */ // remove it after refactor
import { Box, Button, CssBaseline } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../components/UI/Loader/Loader";
import { CONTAINER_ID, setContainerID } from "../../store/auth-context";
import Header from "./components/Header/Header";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import { ContentWrapper, MainContent, GraphContent } from "./Dashboard.styled";
import HealthCheck from "./nested/HealthCheck/HealthCheck";
import Help from "./nested/Help/Help";
import Information from "./nested/Information/Information";
import PdfTest from "./nested/PdfTest/PdfTest";
import Recommendations from "./nested/Recommendations/Recommendations";
import RiskMatrix from "./nested/RiskMatrix/RiskMatrix";
import Settings from "./nested/Settings/Settings";
import { Cookies } from "../../shared/utility";
import HealthCheckLandingPage from "./nested/HealthCheck/HealthCheckLandingPage";
import RiskAssessment from "./nested/RiskAssessment/RiskAssessment";
import RiskAssessmentLandingPage from "./nested/RiskAssessment/RiskAssessmentLandingPage";
import jwt_decode from "jwt-decode";
import { useIdleTimer } from 'react-idle-timer';
import InfoModal from "./components/InfoModal/InfoModal";
import ModalImageSrc from "../../images/reccomendationImg.png";
import RiskMatrixLandingPage from "./nested/RiskMatrix/RiskMatrixLandingPage";
import NotFound from "../../components/ErrorPage/NotFoundPage";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import HubIcon from '@mui/icons-material/Hub';
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ExposureIcon from '@mui/icons-material/Exposure';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GridViewIcon from '@mui/icons-material/GridView';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import RiskAssessmentDetail from "./nested/RiskAssessment/RiskAssessmentDetail";
import Vulnerabilities from "./nested/Vulnerabilities/Vulnerabilities";
import ApplicationControlIcon from '@mui/icons-material/VerifiedUser';
import SourceIcon from '@mui/icons-material/Source';

import E8LandingPage from "./nested/ASDE8/E8LandingPage";
import E8Controls from "./nested/ASDE8/E8Controls";
import E8Questions from "./nested/ASDE8/E8QuestionList";
import E8SubQuestions from "./nested/ASDE8/E8SubQuestions";
import SecurityControlMaturityAssessment from "./nested/SecurityControlMaturityAssessment/SecurityControlMaturityAssessment";
import ControlMaturityQuestion from "./nested/SecurityControlMaturityAssessment/ControlMaturityQuestion/ControlMaturityQuestion";
import MaturityScores from "./nested/SecurityControlMaturityAssessment/MaturityScores/MaturityScores";
import InformationAssetRegister from "./nested/InformationAssetRegister/InformationAssetRegister";
import AddInformationAssetRegister from "./nested/InformationAssetRegister/AddInformationAssetRegister";
import InformationAssetRegisterLandingPage from "./nested/InformationAssetRegister/InformationAssetRegisterLandingPage";
import RiskAssessmentLanding from "./nested/TRA/RiskAssessment/RiskAssessmentLandingPage";
import AddRiskAssessment from "./nested/TRA/RiskAssessment/AddRiskAssessment";
import RiskGraphPage from "./nested/Graph/Risk&Mitigation/Risk&Mitigation";
import ZenView from "./nested/Graph/ZenView";
import DFDLandingPage from "./nested/DFD/DFDLandingPage";
import DocumentsLandingPage from "./nested/Documents/DocumentsLandingPage";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux"
import { setOrganisationList, setSelectedOrganisation } from "../../utility/dataAction";
import BusinessImpactAssessmentLandingPage from "./nested/BusinessImpactAssessment/BusinessImpactAssessmentLandingPage";
import ThreatsEllicitationPage from "./nested/ThreatsEllicitation/ThreatsEllicitationPage";
import ThreatLandingPage from "./nested/Threat/ThreatLandingPage";
import ReportsLandingPage from "./nested/Reports/ReportsLandingPage";

import FileOpenIcon from '@mui/icons-material/FileOpen';

var drawerWidth = 259;

function Dashboard(props) {
    let { path } = useRouteMatch();

    const dispatch = useDispatch();

    const { selectedOrganisation } = useSelector((state) => state.data);

    const menuCategoryList = [
        {
            text: "IT Security",
            menuIconList: [
                {
                    icon: <GridViewIcon />,
                    path: "/overview",
                    text: "Overview",
                },
                {
                    icon: <MonitorHeartIcon />,
                    path: "/health-check",
                    text: "Health Check"
                },
                {
                    icon: <GppMaybeIcon />,
                    path: "/recommendations",
                    text: "Recommendations",
                },
                {
                    icon: <AdminPanelSettingsIcon />,
                    path: "/third-party-risk-assessment",
                    text: "3rd Party Risk Management",
                },
                {
                    icon: <FileOpenIcon />,
                    path: "/documents",
                    text: "Documents",
                },
                {
                    icon: <FileOpenIcon />,
                    path: "/reports",
                    text: "Reports",
                },
            ]
        },
        {
            text: "Risk Management",
            menuIconList: [
                {
                    icon: <HubIcon />,
                    path: "/zenview",
                    text: "Zen View",
                },
                {
                    icon: <DataThresholdingOutlinedIcon />,
                    path: "/risk-matrix",
                    text: "Risk Matrix",
                },
                {
                    icon: <FormatListNumberedIcon />,
                    path: "/information-asset-register",
                    text: "Information Asset",
                },
                {
                    icon: <CrisisAlertIcon />,
                    path: "/business-impact-assessment",
                    text: "Business Impact",
                },
                {
                    icon: <ExposureIcon />,
                    path: "/maturity-assessment",
                    text: "Maturity Assessment",
                },
                {
                    icon: <AssessmentIcon />,
                    path: "/threat-model/create",
                    text: "Threat Model",
                },
                {
                    icon: <AssessmentIcon />,
                    path: "/risk-assessment",
                    text: "Risk Assessment",
                },
                {
                    icon: <ApplicationControlIcon />,
                    path: "/essential-eight",
                    text: "ACSC E8",
                },
                {
                    icon: <GpsFixedIcon />,
                    path: "/threats",
                    text: "Threats",
                },
                {
                    icon: <SourceIcon />,
                    path: "/project-and-solutions",
                    text: "Project and Solutions",
                },
                {
                    icon: <CoronavirusIcon />,
                    path: "/vulnerabilities",
                    text: "Vulnerabilities",
                },

            ]
        },
    ];

    const { keycloak, initialized } = useKeycloak();
    const kcToken = keycloak?.token ?? '';

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isShrink, setIsShrink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [containerName, setContainerName] = useState(null);
    const [signal, setSignal] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [endDate, setEndDate] = useState(null);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    let timer = null;

    const location = useLocation()

    const history = useHistory();

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        handleOpenModal();
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    const onAction = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    //1 hour idle
    const idleTimer = useIdleTimer({ onIdle, onActive, onAction, timeout: 1000 * 60 * 60 })
    const [timerText, setTimerText] = useState(`Are you still there? Your session will expire in 30 seconds. Click "Continue" if you want to continue and stay logged in.`);


    useEffect(() => {
        if (!keycloak.authenticated) {
            // history.replace("/")
        } else {
            keycloak.loadUserInfo().then((res) => {
                setEmail(res.email);
                setUsername(res.name);
            });
        }
    }, [history, keycloak.authenticated, location])


    useEffect(() => {
        if (openModal) {
            timer = setTimeout(() => {
                history.replace("/login");
                sessionStorage.clear();
                keycloak.logout()
            }, 1000 * 30);
        } else {
            clearTimeout(timer);
        }
    }, [openModal])

    function handleContinue() {
        clearTimeout(timer);
        handleCloseModal();
    }

    useEffect(() => {
        if (keycloak) {
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(50).then((refreshed) => {
                    if (refreshed) {
                        setSignal(keycloak.tokenParsed.exp);
                    } else {
                        // console.log('not refreshed ' + new Date());
                    }
                })
            }
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };

    }, [keycloak.authenticated]);

    useEffect(() => {
        if (!keycloak.authenticated) {
            // history.replace("/")
        } else {
            try {
                var decoded = jwt_decode(kcToken);
                var resource = decoded["resource_access"];
                let userName = decoded["name"] ?? "";
                setUsername(userName);
                // var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
                // dispatch(setOrganisationList(clientRoles))
                // const orgName = clientRoles.filter(role => role !== "cz-users")[0];
                // dispatch(setSelectedOrganisation(orgName));
                // setContainerID(orgName + "_0.1");
                // setContainerName(orgName + "_0.1");
            } catch (error) {
                console.log(error);
            }
        }
    }, [history, keycloak.authenticated, location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleShrinkToggle = () => {
        if (!isShrink) {
            drawerWidth = 75
        } else {
            drawerWidth = 259
        }
        setIsShrink(!isShrink);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    let content;

    if (loading) {
        content = <Loader height="90vh" />;
    }


    content = (
        <Switch>
            <Route path={`${path}/overview`} exact>
                <MainContent>
                    <Information signal={signal} containerName={containerName} />
                </MainContent>
            </Route>
            <Route path={`${path}/health-check`} exact>
                <MainContent>
                    <HealthCheckLandingPage signal={signal} CONTAINER_ID={containerName} />
                </MainContent>
            </Route>
            <Route path={`${path}/health-check/health-check-progress`} exact>
                <MainContent>
                    <HealthCheck signal={signal} containerName={containerName} />
                </MainContent>
            </Route>

            <Route path={`${path}/recommendations`} exact>
                <MainContent>
                    <Recommendations signal={signal} kcToken={kcToken} />
                </MainContent>
            </Route>

            <Route path={`${path}/settings`} exact>
                <MainContent>
                    <Settings signal={signal} />
                </MainContent>https://dev.azure.com/CISOZEN/Frontend-Guest/_build
            </Route>
            <Route path={`${path}/risk-matrix`} exact>
                <MainContent>
                    <RiskMatrixLandingPage signal={signal} email={email} kcToken={kcToken} />
                </MainContent>
            </Route>
            <Route path={`${path}/risk-matrix/risk-matrix-progress`} exact>
                <MainContent>
                    <RiskMatrix signal={signal} email={email} kcToken={kcToken} />
                </MainContent>
            </Route>

            <Route path={`${path}/third-party-risk-assessment`} exact>
                <MainContent>
                    <RiskAssessmentLandingPage signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/third-party-risk-assessment/detail`} exact>
                <MainContent>
                    <RiskAssessmentDetail signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/third-party-risk-assessment/progress`} exact>
                <MainContent>
                    <RiskAssessment signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/documents`} exact>
                <MainContent>
                    <DocumentsLandingPage signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/reports`} exact>
                <MainContent fullWidth>
                    <ReportsLandingPage signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/risk-assessment/new`} exact>
                <MainContent>
                    <AddRiskAssessment signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/risk-assessment/edit/:data`} exact>
                <MainContent>
                    <AddRiskAssessment signal={signal} />
                </MainContent>
            </Route>

            {/* <Route path={`${path}/threat-model/create`} exact>
                <GraphContent>
                    <DFDLandingPage signal={signal} email={email} kcToken={kcToken} />
                </GraphContent>
            </Route> */}

            <Route path={`${path}/risk-assessment`} exact>
                <MainContent fullWidth>
                    <RiskAssessmentLanding signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/essential-eight`} exact>
                <MainContent>
                    <E8LandingPage></E8LandingPage>
                </MainContent>
            </Route>
            <Route path={`${path}/essential-eight/controls`} exact>
                <MainContent>
                    <E8Controls></E8Controls>
                </MainContent>
            </Route>
            <Route path={`${path}/essential-eight/controls/questions`} exact>
                <MainContent>
                    <E8Questions></E8Questions>
                </MainContent>
            </Route>
            <Route path={`${path}/essential-eight/controls/questions/sub`} exact>
                <MainContent>
                    <E8SubQuestions></E8SubQuestions>
                </MainContent>
            </Route>
            <Route path={`${path}/vulnerabilities`} exact>
                <MainContent>
                    <Vulnerabilities signal={signal} />
                </MainContent>
            </Route>

            <Route path={`${path}/maturity-assessment/new`} exact>
                <MainContent>
                    <SecurityControlMaturityAssessment signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/maturity-assessment/question`} exact>
                <MainContent>
                    <ControlMaturityQuestion signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/maturity-assessment`} exact>
                <MainContent>
                    <MaturityScores signal={signal} />
                </MainContent>
            </Route>
            <Route path={`${path}/zenview`} exact>
                <GraphContent>
                    <ZenView />
                </GraphContent>
            </Route>
            <Route path={`${path}/help`} exact>
                <Help></Help>
            </Route>

            <Route path={`${path}/threats`} exact>
                <MainContent fullWidth>
                    <ThreatLandingPage />
                    {/* <NotFound info="Targeted mid March 2024" /> */}
                </MainContent>
            </Route>

            <Route path={`${path}/project-and-solutions`} exact>
                <MainContent fullWidth>
                    <ThreatsEllicitationPage signal={signal} />
                </MainContent>
            </Route>

            <Route path={`${path}/information-asset-register`} exact>
                <MainContent fullWidth>
                    <InformationAssetRegisterLandingPage />
                </MainContent>
            </Route>
            <Route path={`${path}/information-asset-register/new`} exact>
                <MainContent>
                    <AddInformationAssetRegister />
                </MainContent>
            </Route>
            {/* <Route path={`${path}/information-asset-register/question`} exact>
                <MainContent>
                    <InformationAssetRegister />
                </MainContent>
            </Route> */}
            <Route path={`${path}/information-asset-register/edit/:id`} exact>
                <MainContent>
                    <AddInformationAssetRegister />
                </MainContent>
            </Route>

            <Route path={`${path}/business-impact-assessment`} exact>
                <MainContent fullWidth>
                    <BusinessImpactAssessmentLandingPage />
                </MainContent>
            </Route>
            <Route path={`${path}/business-impact-assessment/edit/:id`} exact>
                <MainContent>
                    <InformationAssetRegister />
                </MainContent>
            </Route>
            <Route path={path} exact>
                <Redirect to={`${path}/overview`} />
            </Route>
            <Route>
                <NotFound info="Targeted end of August 2024" />
            </Route>
        </Switch>
    );


    return (
        <>
            <Helmet>
                <title>IT Security - CisoZen</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <SideDrawer menuCategoryList={menuCategoryList} container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerShrink={handleShrinkToggle} drawerWidth={drawerWidth} />
                </Box>
                <ContentWrapper drawerWidth={drawerWidth}>
                    <Header username={username} email={email} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
                    {content}
                    <InfoModal
                        open={openModal}
                        handleClose={handleCloseModal}
                        imgSrc={ModalImageSrc}
                        title={timerText}
                        list={[<Button onClick={() => handleContinue()}>Continue...</Button>]}
                    />
                </ContentWrapper>
            </Box>
        </>
    );
}

export default Dashboard;

