import styled from "styled-components";

export const ContentContainer = styled.div`
    max-width: 574px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SpanContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 49px;

    p {
        width: 20px;
        height: 20px;
        position: absolute;
        background: #4f4f4f;
        border-radius: 50%;
        cursor: pointer;
    }

    span {
        width: calc(100% / 5);
        height: 12px;

        @media (max-width: 599.98px) {
            width: calc(100% / 6);
        }

        &:first-of-type {
            background: linear-gradient(
                90deg,
                #92d050 0%,
                rgba(70, 161, 27, 0.5) 100%
            );
        }

        &:nth-of-type(2) {
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1)
                ),
                linear-gradient(
                    90deg,
                    #feff04 0%,
                    rgba(254, 255, 4, 0) 100%,
                    rgba(254, 255, 4, 0.38) 100%
                );
        }

        &:nth-of-type(3) {
            background: linear-gradient(
                90deg,
                #2d9cdb 0%,
                rgba(45, 156, 219, 0.15) 50.52%,
                #2d9cdb 100%
            );
        }

        &:nth-of-type(4) {
            background: linear-gradient(
                270deg,
                #fcc200 0%,
                rgba(252, 194, 0, 0.38) 100%
            );
        }

        &:nth-of-type(5) {
            position: relative;
            z-index: 100;
            background: linear-gradient(
                270deg,
                #ff0000 0%,
                rgba(255, 0, 0, 0.23) 100%
            );
        }
    }
`;

export const StyledHead = styled.h4`
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 7px;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};

    @media (max-width: 599.98px) {
        width: 60%;
    }
`;

export const TextContainer = styled.div`
    box-sizing: border-box;
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#000" : "#fff")};

    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 143%;

    margin-top: 11px;
    margin-bottom: 26px;
    padding: 0 10px;

    p {
        &:first-of-type {
            @media (max-width: 599.98px) {
                padding-left: 5px;
                max-width: 72px;
            }
        }
        &:last-of-type {
            @media (max-width: 599.98px) {
                max-width: 121px;
            }
        }
    }
`;
