/* eslint-disable no-unused-vars */ // remove it after refactor
import { Box, Button, CssBaseline } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { setContainerID } from "../../store/auth-context";
import Header from "./components/Header/Header";
import jwt_decode from "jwt-decode";
import { useIdleTimer } from 'react-idle-timer';
import InfoModal from "./components/InfoModal/InfoModal";
import ModalImageSrc from "../../images/reccomendationImg.png";
import MainDrawer from "./components/SideDrawer/MainDrawer";
import { Helmet } from "react-helmet-async";
import { ContentWrapper, MainContent, Wrapper } from "./SearchPage.styled";
import Heading from "../../components/UI/Heading/Heading";
import SearchComponent from "../../components/AppSearch/SearchComponent";
import { DomainCard } from "./nested/TRA/RiskAssessment/components/ApplicableControl.styled";

var drawerWidth = 259;

function SearchPage(props) {
  let { path } = useRouteMatch();

  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isShrink, setIsShrink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [containerName, setContainerName] = useState(null);
  const [signal, setSignal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  let timer = null;

  const location = useLocation()

  const history = useHistory();

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    handleOpenModal();
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  const onAction = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  //1 hour idle
  const idleTimer = useIdleTimer({ onIdle, onActive, onAction, timeout: 1000 * 60 * 60 })
  const [timerText, setTimerText] = useState(`Are you still there? Your session will expire in 30 seconds. Click "Continue" if you want to continue and stay logged in.`);


  useEffect(() => {
    if (!keycloak.authenticated) {
      history.replace("/")
    } else {
      keycloak.loadUserInfo().then((res) => {
        setEmail(res.email);
        setUsername(res.name);
      });
    }
  }, [history, keycloak.authenticated, location])


  useEffect(() => {
    if (openModal) {
      timer = setTimeout(() => {
        history.replace("/login");
        sessionStorage.clear();
        keycloak.logout()
      }, 1000 * 30);
    } else {
      clearTimeout(timer);
    }
  }, [openModal])

  function handleContinue() {
    clearTimeout(timer);
    handleCloseModal();
  }

  useEffect(() => {
    if (keycloak) {
      keycloak.onTokenExpired = () => {
        keycloak.updateToken(50).then((refreshed) => {
          if (refreshed) {
            setSignal(keycloak.tokenParsed.exp);
          } else {
            // console.log('not refreshed ' + new Date());
          }
        })
      }
    }

    return () => {
      if (keycloak) keycloak.onTokenExpired = () => { };
    };

  }, [keycloak.authenticated]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      history.replace("/")
    } else {
      try {
        var decoded = jwt_decode(kcToken);
        var resource = decoded["resource_access"];
        let userName = decoded["name"] ?? "";
        setUsername(userName);
        var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
        const orgName = clientRoles.filter(role => role !== "cz-users")[0];
        setContainerID(orgName + "_0.1");
        setContainerName(orgName + "_0.1");
      } catch (error) {
        console.log(error);
      }
    }
  }, [history, keycloak.authenticated, location])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleShrinkToggle = () => {
    if (!isShrink) {
      drawerWidth = 75
    } else {
      drawerWidth = 259
    }
    setIsShrink(!isShrink);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Helmet>
        <title>Search - CisoZen</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
          <MainDrawer container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerShrink={handleShrinkToggle} drawerWidth={drawerWidth} />
        </Box>
        <ContentWrapper drawerWidth={drawerWidth}>
          <Header username={username} email={email} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />

          <MainContent>
            <Wrapper>
              <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={() => { }}>
                Search
              </Heading>
              <DomainCard style={{ width: '100%', padding: 0 }}>
                <SearchComponent />
              </DomainCard>

            </Wrapper>
          </MainContent>

          <InfoModal
            open={openModal}
            handleClose={handleCloseModal}
            imgSrc={ModalImageSrc}
            title={timerText}
            list={[<Button onClick={() => handleContinue()}>Continue...</Button>]}
          />
        </ContentWrapper>
      </Box>


    </>
  );
}

export default SearchPage;

