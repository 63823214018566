import React, { useCallback, useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";

import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

import { CommentBoxContainer, Heading } from "./CommentBox.styled";
import { validInput } from "../E8Question/SubQuestion/SubQuestion";
import { CustomLoadingButton } from "../UI/Button/Button.styled";

import AvatarImg from "../../images/avatar-img.png";
import { getCaseComment, postCaseComment } from "../../pages/Dashboard/nested/TRA/TRAApi";
import TruncateItems from "../TruncateItems/TruncateItems";

function CommentBox({ caseID }) {

    const { keycloak } = useKeycloak();

    const [value, setValue] = useState("");
    const [hasError, setHasError] = useState(null);
    const [submittingComment, setSubmittingComment] = useState(false);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);

    const handleInputChange = (e) => {
        if (!validInput.test(e.target.value)) {
            setHasError("Invalid input!")
        } else {
            setHasError(null)
        }
        setValue(e?.target?.value)
    }

    const fetchComments = useCallback(() => {
        getCaseComment(caseID)
            .then((response) => {
                if (response.success) {
                    if (response?.data?.comments?.length > 0) {
                        let comments = response.data.comments.map((comment) => {
                            let text;
                            try {
                                text = JSON.parse(comment.text);
                            } catch (err) {

                            }

                            const date = new Date(comment["added-at"]["java.util.Date"]);
                            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                            const timeOptions = { hour: "numeric", minute: "numeric" }
                            const dateString = date.toLocaleDateString("en-AU", dateOptions) + ", " + date.toLocaleTimeString("en-AU", timeOptions);

                            return {
                                firstName: text['firstName'] || '',
                                lastName: text['lastName'] || '',
                                email: text['email'] || '',
                                comment: text['comment'] || '',
                                id: comment.id,
                                author: comment.author,
                                date: date,
                                dateString: dateString,
                            }
                        })

                        comments.sort((a, b) => b.date - a.date)

                        setComments(comments);
                    } else {
                        setComments([])
                    }
                }
            })
            .catch((error) => {
                toast.error(error.message || "Something went wrong.");
            })
            .finally(() => {
                setTimeout(() => setLoading(false), 1000);
            })
    }, [caseID])

    useEffect(() => {
        fetchComments();
    }, [])

    const handleSubmitComment = useCallback((e) => {
        e?.preventDefault();
        setSubmittingComment(true);
        let dataObj = {
            "firstName": keycloak.userInfo?.given_name,
            "lastName": keycloak.userInfo?.family_name,
            "email": keycloak.userInfo?.email,
            "comment": value,
        }
        postCaseComment(caseID, JSON.stringify(dataObj))
            .then((response) => {
                if (response.success) {
                    fetchComments();
                    setValue("");
                } else {
                    toast.error(response?.error?.data?.message || "Something went wrong.");
                }
            })
            .catch((error) => {
                toast.error(error.message || "Something went wrong.");
            })
            .finally(() => {
                setSubmittingComment(false);
            })
    }, [caseID, keycloak, value])

    return <CommentBoxContainer>
        <Heading>Comments</Heading>
        {
            caseID ? <>
                <Box sx={{ marginTop: 2 }}>
                    <TextField
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="Comment"
                        placeholder="Enter comment"
                        variant="outlined"
                        value={value}
                        onChange={handleInputChange}
                        error={hasError}
                        helperText={hasError ?? ''}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                    />
                    <CustomLoadingButton
                        success
                        variant="contained"
                        sx={{ marginTop: 2 }}
                        disabled={!(value?.length > 0 && !hasError)}
                        loading={submittingComment}
                        onClick={handleSubmitComment}
                    >
                        Submit
                    </CustomLoadingButton>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <List sx={{ width: '100%' }}>
                        {
                            loading ? Array.from(new Array(2)).map((_, index) => {
                                return <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start" key={index}>
                                        <ListItemAvatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            component="div"
                                            primary={<Skeleton />}
                                            secondary={
                                                <>
                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                        <Box sx={{ width: "20%" }}>
                                                            <Skeleton />
                                                        </Box>
                                                        <Box sx={{ width: "100%" }}>
                                                            <Skeleton />
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    {
                                        (index < 1) && <Divider key={"divider-" + index} variant="inset" component="li" />
                                    }
                                </React.Fragment>
                            }) : <>
                                <TruncateItems>
                                    {
                                        comments?.length > 0 ? comments.map((comment, index) => {
                                            return <React.Fragment key={index}>
                                                <ListItem alignItems="flex-start" key={comment.id}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={`${comment["firstName"] || ''} ${comment["lastName"] || ''}`} src={AvatarImg} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography gutterBottom variant="body2">
                                                                {comment["comment"] || ''}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <>
                                                                <Typography display="block" variant="caption" color="text.secondary">
                                                                    {`${comment["firstName"] || ''} ${comment["lastName"] || ''}`} • {comment['dateString'] || ''}
                                                                </Typography>
                                                                {/* <Typography variant="caption" color="text.secondary">
                                                        {comment['dateString'] || ''}
                                                    </Typography> */}
                                                            </>
                                                        }
                                                    />
                                                    {/* <ListItemText
                                            primary={comment['dateString'] || ''}
                                            secondary={
                                                <>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {`${comment["firstName"] || ''} ${comment["lastName"] || ''}`}
                                                    </Typography>
                                                    {` - ${comment["comment"]}`}
                                                </>
                                            }
                                        /> */}
                                                </ListItem>
                                                {
                                                    (index < (comments.length - 1)) && <Divider key={"divider-" + comment.id} variant="inset" component="li" />
                                                }
                                            </React.Fragment>
                                        }) : <>
                                            <Typography variant="caption">No comments</Typography>
                                        </>
                                    }
                                </TruncateItems>
                            </>
                        }
                    </List>
                </Box>
            </> : ''
        }
    </CommentBoxContainer>
}

export default CommentBox;