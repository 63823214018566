import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

import Box from "@mui/material/Box";
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import ThreatQuestionData from '../ThreatData.json';
import TruncateItems from '../../../../../components/TruncateItems/TruncateItems';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ThreatDetailDialog = (props) => {

  // ** Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, toggle, data, handleEditClick, domainControlMapper } = props;

  const [dialogTitle, setDialogTitle] = useState('');

  useEffect(() => {
    if (open && data) {
      setDialogTitle(data["title"] || '');
    }
  }, [open, data])

  return (
    <Dialog
      open={open}
      onClose={toggle}
      scroll="paper"
      size="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ paddingRight: "80px" }} noWrap as={Typography} title={dialogTitle}>
        {dialogTitle}
      </DialogTitle>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <IconButton
          title="edit"
          aria-label="edit"
          onClick={(e) => {
            handleEditClick(data.caseID, data.status, data);
            toggle(e);
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="close"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        {
          open && data && <>
            <Card>
              <CardHeader title="Threat Details" />
              <Divider />
              {
                ThreatQuestionData[0].subQuestions.map((question, index) => {
                  let value = data[question.key];

                  if (['threatType', 'threatCategory', 'riskCategory', 'priority'].includes(question.key)) {
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        <Box>
                          {
                            Array.isArray(value) ?
                              value.map((val) => <Chip label={val} key={val} />)
                              : <Chip label={value} />
                          }
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  } else if (['attackVectors'].includes(question.key)) {
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        <Box component="ul" sx={{ listStyle: "none", display: 'flex', flexWrap: 'wrap' }}>

                          {
                            (value || []).map((val) => <ListItem key={val}>
                              <Chip label={val} key={val} />
                            </ListItem>)
                          }
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  } else if (['currentControls', 'ismsMapping'].includes(question.key)) {
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        <Box component="ul" sx={{ listStyle: "none", display: 'flex', flexWrap: 'wrap' }}>
                          <TruncateItems
                            itemCount={2}
                            slots={{
                              more: ({ onClick }) => <ListItem key={"slot-more"}>
                                <Chip label={`+ ${value.length - 2}`} key={"slot-more-chip"} onClick={onClick} />
                              </ListItem>
                            }}
                          >
                            {
                              (value || []).map((val) => <ListItem key={val}>
                                <Chip label={domainControlMapper[val] || val} key={val} />
                              </ListItem>)
                            }
                          </TruncateItems>
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  } else if (['reference'].includes(question.key)) {
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        <Box
                          component="ul"
                          sx={{
                            listStyle: 'circle',
                            paddingLeft: 2,
                            marginBottom: 1,
                          }}
                        >
                          <TruncateItems itemCount={2}>
                            {
                              (value || []).map((val) => <ListItem key={val}>
                                <Typography>{val}</Typography>
                              </ListItem>)
                            }
                          </TruncateItems>
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  } else if (['counterMeasureMapping', 'compensatingControls'].includes(question.key)) {
                    return <React.Fragment key={`${data.caseID}-${question.key}`}>
                      <Box sx={{ px: 2, py: 1.25 }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                          {question.qHeading}
                        </Typography>
                        {
                          value?.length > 0 ? <Box>
                            <TableContainer
                              sx={{
                                '& .MuiTableCell-root': {
                                  borderBottom: 0,
                                  borderRight: '1px solid rgba(81, 81, 81, 1)',
                                  wordBreak: 'break-word',
                                  '&:last-child': {
                                    borderRight: 0,
                                  },
                                },
                                '& .MuiTableCell-head': {
                                  border: '1px solid rgba(81, 81, 81, 1)',
                                },
                                '& .MuiTableRow-root': {
                                  borderLeft: '1px solid rgba(81, 81, 81, 1)',
                                  borderRight: '1px solid rgba(81, 81, 81, 1)',
                                }
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      {question.key === "counterMeasureMapping" ? "Counter Measure" : "Title"}
                                    </TableCell>
                                    <TableCell>
                                      Status
                                    </TableCell>
                                    <TableCell>
                                      ISMS Mapping
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    value.map((item, index) => {

                                      return <React.Fragment key={index}>
                                        {
                                          item.map((sItem, sIndex) => {
                                            return <React.Fragment key={sIndex}>
                                              {
                                                sIndex > 0 && <TableRow
                                                  sx={{
                                                    '& .MuiTableCell-root': {
                                                      padding: "0 16px",
                                                      height: "auto",
                                                    }
                                                  }}
                                                >
                                                  <TableCell>
                                                    <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                    <Typography key={sIndex} variant="caption" color="darkgrey">And</Typography>
                                                  </TableCell>
                                                </TableRow>
                                              }
                                              <TableRow
                                                key={`${index}-${sIndex}`}
                                                sx={{
                                                  ...(((item.length - 1) === sIndex) && {
                                                    borderBottom: '1px solid rgba(81, 81, 81, 1)',
                                                  }),

                                                }}
                                              >
                                                <TableCell>
                                                  <Typography >{sItem.title}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <Typography >{sItem.status}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <Typography>
                                                    {domainControlMapper[sItem.ismsMapping] || sItem.ismsMapping}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </React.Fragment>
                                          })
                                        }
                                      </React.Fragment>
                                    })
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box> : <Box>{"-"}</Box>
                        }
                      </Box>
                      <Divider />
                    </React.Fragment>
                  }

                  return <React.Fragment key={`${data.caseID}-${question.key}`}>
                    <Box sx={{ px: 2, py: 1.25 }}>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                        {question.qHeading}
                      </Typography>
                      <Typography variant="h5" sx={{ fontSize: 16 }} component="div">
                        {typeof value === 'string' ? value : '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </React.Fragment>
                })
              }
            </Card>
          </>
        }
      </DialogContent>
    </Dialog>
  );
};

export default ThreatDetailDialog;