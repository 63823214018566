import { Alert, Box, Grid, Grow, TextField } from "@mui/material";
import Lottie from "lottie-react";
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import doneAnimation from "../../../../assets/animations/done.json";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, HealthCheckProcess, RiskAssessmentProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { getActiveHealthCheck, getCompletedHealthCheck, getCompletedHealthCheckList, getCompletedTPRMList, getHealthCheckDuration, getProcessVariables } from "../../InformationAPI";
import { useKeycloak } from "@react-keycloak/web";
import logo from "../../../../images/logo0.2.png";
import { CardHead, DotSign, ProgressBlock, ProgressCard, ProgressValue, StatsText, TextBlock } from "../Information/Information.styled";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { QuestionNumber } from "../../../../components/Question/QuestionHead/QuestionHead.styled";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ThemeContext from "../../../../store/theme-context";
import { Wrapper } from "../HealthCheck/HealthCheck.styled";
import { DescriptionContent, DescriptionTextHead, DescriptionTextP, DescriptionTextParagraph, GraphContainer, StyledInput, StyledList } from "./RiskAssessment.styled";
import { retrieveData } from "../../../../shared/utility";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function RiskAssessmentLandingPage(props) {

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [kcToken, setKcToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [header, setHeader] = useState(null);

    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [taskInstanceId, setTaskInstanceId] = useState("");
    const [completedTPRMList, setcompletedTPRMList] = useState(null);
    const [tprmDataList, settprmDataList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [isHistoryLoading, setHistoryLoading] = useState(true);

    const [activeHealthCheckID, setActiveHealthCheckID] = useState(null);

    const [processID, setProcessID] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [orgName, setOrgName] = useState("");
    const [tprmLoading, setTprmLoading] = useState(false);
    const [tprmLink, setTprmLink] = useState("");
    const questionNavHandler = () => history.push("/it-security/third-party-risk-assessment/progress");
    const detailNavHandler = () => history.push("/it-security/third-party-risk-assessment/detail");

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: '3rd Party Risk Assessment',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setKcToken(keycloak.token);
                setEmail(res.sub);
            });
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        if (processID === null) return;
        // const data = window.sessionStorage.getItem('RISK_ASSESSMENT');
        // const index = window.sessionStorage.getItem('RISK_ASSESSMENT_INDEX');
        // if (data) {
        //     setQuestions(JSON.parse(data));
        //     if (index) setActiveIndex(JSON.parse(index));
        // }
        setFetching(false);
        getUserTask(processID);

    }, [processID]);

    useEffect(() => {
        if (CONTAINER_ID === "" || kcToken === null) return;
        getCompletedTprmList();
        getActiveCaseID();
    }, [CONTAINER_ID, kcToken]);

    function getCompletedTprmList() {
        setLoading(true);
        getCompletedTPRMList(kcToken, "").then((response) => {
            setcompletedTPRMList(response.data);
            setLoading(false);
        });
    }

    async function startNewCasse() {
        try {
            let config = {
                method: 'get',
                url: `/services/rest/server/containers/${CONTAINER_ID}/cases/definitions`,
                headers: { "Authorization": `Bearer ${kcToken}`, 'Content-Type': 'application/json' }
            }
            const res = fetchInstance.apiRequest(config);
            if (res.status === 200) {
                console.log((await res).data);
            }
        } catch (error) {
            toast.error("Failed to start new 3rd party risk assessment");
        }
    }

    async function startNewCase() {
        setFetching(true);
        try {
            let config = {
                method: 'post',
                url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${RiskAssessmentProcess}/instances`,
                data: JSON.stringify({}),
                headers: { "Authorization": `Bearer ${kcToken}`, 'Content-Type': 'application/json' }
            }
            const res = await fetchInstance.apiRequest(config);
            if (res.status === 201) {
                setProcessID(null);
                getActiveCaseID();
            }
            setFetching(false);
        } catch (error) {
            setFetching(false);
            toast.error("Failed to start new 3rd party risk assessment");
        }
    }

    async function getActiveCaseID() {
        if (!kcToken) return;
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${RiskAssessmentProcess}/instances?status=open&page=0&pageSize=1&sortOrder=false&withData=false`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status === 200) {
                    if (response.data !== "") {
                        const caseID = response.data["instances"][0]["case-id"] ?? false;
                        if (caseID) {
                            //store active caseID in local storage
                            getProcessID(caseID)
                        }
                    }

                } else {
                    sessionStorage.clear();
                    setProcessID(0);
                }
            })
            .catch(function (error) {
                setProcessID(0);
            });
    }

    async function getProcessID(caseID) {
        setFetching(true);
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/processes/instances?page=0&pageSize=1&sortOrder=true`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    let processInstanceID = response.data["process-instance"][0]["process-instance-id"] ?? 0;
                    setProcessID(processInstanceID);
                    setFetching(false);
                } else {
                    setProcessID(0);
                    setFetching(false);
                }
            })
            .catch(function (error) {
                setProcessID(0);
                setFetching(false);
                console.log(error);
            });
    }

    async function getUserTask(processID) {
        if (processID === 0) return;
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const task = response.data["active-user-tasks"]["task-summary"][0];
                    const taskID = task["task-id"];
                    setTaskInstanceId(taskID);

                    // getTaskDetail(taskID);
                    getData(processID);

                    if (task["task-status"] !== "InProgress") {
                        var data = JSON.stringify(true);

                        var config2 = {
                            method: 'put',
                            url: `/services/rest/server/containers/${CONTAINER_ID}/tasks/${taskID}/states/started`,
                            headers: {
                                "Authorization": `Bearer ${kcToken}`
                            },
                            data: data
                        };

                        fetchInstance.apiRequest(config2)
                            .then(function (response) {
                                //started user task
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getData(processID) {

        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}/variables`,
            headers: {
                "Authorization": `Bearer ${kcToken}`
            },
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status === 200) {
                    var objArr = [];
                    Object.keys(response.data).forEach(key => {
                        if (key.includes("ans")) {
                            const questionObj = JSON.parse(response.data[`${key}`]);
                            objArr.push(questionObj);
                        }
                    });
                    objArr.sort((a, b) => { return a.qID - b.qID });

                    for (let question of objArr) {
                        if (!("touched" in question)) {
                            setActiveIndex(question.qID - 1);
                            break;
                        };
                    }
                    setQuestions(objArr);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClick2 = () => {
        setModal2Open(true);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    let content;

    if (fetching) {
        content = <Loader height="40vh" />;
    }

    function contentInProgress() {
        return (<>
            {
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead>Current progress</CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={7} xs={12}>
                                <TextBlock style={{ marginTop: "15px", height: "100%", textAlign: "left", fontSize: "16px" }}><QuestionNumber>{questions[activeIndex]?.qNum}</QuestionNumber>{questions[activeIndex]?.title}</TextBlock>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <ProgressBlock>
                                    <CircularProgressWithLabel value={(activeIndex) / questions.length * 100}>
                                        <ProgressValue>{Math.round((activeIndex) / questions.length * 100)}% <br />completed</ProgressValue>
                                    </CircularProgressWithLabel>
                                </ProgressBlock>
                            </Grid>
                            <Grid item md={2} xs={0}></Grid>
                            <Grid item md={10} xs={12} style={{ textAlign: "left" }}>
                                <Button $success onClick={(e) => questionNavHandler()}>Continue</Button>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            }
        </>);
    }

    function contentNewHealthCheck() {
        return (
            <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={6} xs={12} style={{ marginTop: "5%", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <TextBlock>CisoZen will help your organisation to assess the cyber security risk associated with third-party suppliers, manufacturers, distributors, and retailers (i.e. businesses that constitute their cyber supply chain).</TextBlock>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => startNewCase()}>Start 3rd Party Risk Assessment</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5} xs={0} sm={0} display={{ xs: "none", lg: "block", md: "block" }}>
                                <ProgressBlock>
                                    <img src="https://www.color.com/wp-content/uploads/2022/01/home-program-management-launch-illustration.png" width="70%" height="70%"></img>
                                </ProgressBlock>
                            </Grid>
                        </Grid>
                    </ProgressCard>
                </Grid>
            </>
        );
    }

    function handleTextChange(e) {
        setOrgName(e.target.value);
    }

    function handleGenerate() {
        if (orgName === "") {
            toast.error("Please enter third party organization name")
        } else {
            setTprmLoading(true);
            var data = JSON.stringify({
                "case-data": {
                    "orgName": CONTAINER_ID.split("_")[0],
                    "thirdPartyOrgName": orgName
                },
                "case-user-assignments": {
                    "owner": process.env.REACT_APP_DEPLOYMENT === "PROD" ? "1b780abd-5f02-4be0-854b-c55d64d62b86" : "e53cf896-fd01-4b85-aaac-2ac1b5e2668f"
                }
            });

            var config = {
                method: 'post',
                url: `/services/rest/server/containers/ptprm_0.1/cases/${RiskAssessmentProcess}/instances`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${kcToken}`,
                },
                data: data
            };

            fetchInstance.apiRequest(config)
                .then(function (response) {
                    const caseID = response.data;
                    const tprmUrl = `${window.location.origin}/form/risk-assessment?id=${caseID}`;
                    setTprmLink(tprmUrl);
                    setTprmLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setTprmLoading(false);
                });
        }
    }

    function handleCopy() {
        navigator.clipboard.writeText(tprmLink);
        toast.success("Link Copied!");
    }

    function handleReset() {
        setTprmLink("");
    }

    function handleDetail(data) {
        window.sessionStorage.setItem("RISK_ASSESSMENT_DETAIL", JSON.stringify(data));
        detailNavHandler();
    }

    useEffect(() => {
        if (completedTPRMList === null) return;
        completedTPRMList.map((instanceID, index) => {

            getProcessVariables(instanceID, kcToken).then((res) => {
                if (res.status && res.status === "success") {
                    const myobj = { "id": instanceID, "index": index };
                    // myobj["orgName"] = res.data.filter(obj => { return obj.name === "orgName" })[0]?.value ?? "";
                    const ans1 = res.data.filter(obj => { return obj.name === "ans1Data" })[0]?.value || res.data.filter(obj => { return obj.name === "ans1Data" })[0]?.["old-value"];
                    const ans2 = res.data.filter(obj => { return obj.name === "ans2Data" })[0]?.value || res.data.filter(obj => { return obj.name === "ans2Data" })[0]?.["old-value"];
                    const ans3 = res.data.filter(obj => { return obj.name === "ans3Data" })[0]?.value || res.data.filter(obj => { return obj.name === "ans3Data" })[0]?.["old-value"];
                    const ans4 = res.data.filter(obj => { return obj.name === "ans4Data" })[0]?.value || res.data.filter(obj => { return obj.name === "ans4Data" })[0]?.["old-value"];
                    const ans5 = res.data.filter(obj => { return obj.name === "ans5Data" })[0]?.value || res.data.filter(obj => { return obj.name === "ans5Data" })[0]?.["old-value"];
                    myobj["questions"] = [];

                    try {
                        myobj["questions"].push(JSON.parse(ans1));
                        myobj["questions"].push(JSON.parse(ans2));
                        myobj["questions"].push(JSON.parse(ans3));
                        myobj["questions"].push(JSON.parse(ans4));
                        myobj["questions"].push(JSON.parse(ans5));
                    } catch (err) {
                        // console.log(err);
                    }

                    try {
                        let filtered = myobj.questions[0].subQuestions.find(question => question.qHeading === "Company" || question.qHeading === "Company Name");
                        myobj.orgName = filtered.qAnswer;

                    } catch (error) {
                        //error
                        // console.log(error);
                    }

                    const endDate = res.data.filter(obj => { return obj.name === "ans5Data" })[0]["modification-date"]["java.util.Date"];
                    const date = new Date(parseInt(endDate));
                    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                    myobj["date"] = date.toLocaleDateString("en-US", dateOptions);
                    myobj["risk"] = "";

                    const found = tprmDataList.find(obj => obj.id === myobj.id);
                    if (found) {
                        found = myobj;
                    } else {
                        settprmDataList(oldDataArr => [...oldDataArr, myobj].sort((a, b) => a.id - b.id));
                    }

                }

            });
        })
    }, [completedTPRMList])

    let contentTPRMData = <></>;
    if (completedTPRMList !== null && completedTPRMList.length > 0) {
        contentTPRMData = (tprmDataList.map((organization, index) => {
            return (
                <li key={organization.id} onClick={(e) => handleDetail(organization.questions)}>
                    <DescriptionContent actionWidth>
                        <DescriptionTextHead acSmWidth>
                            {index + 1}
                        </DescriptionTextHead>
                        {
                            <DescriptionTextParagraph acMdWidth>{organization.orgName}</DescriptionTextParagraph>}
                        <DescriptionTextP acMdWidth>{organization.date}</DescriptionTextP>
                        <DescriptionTextP acMdWidth>{"Coming soon..."}</DescriptionTextP>
                    </DescriptionContent>
                </li>
            )
        }));
    }

    function contentTPRMList() {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProgressCard>
                    <CardHead>Third Party Risk Assessment List</CardHead>
                    <ProgressBlock >
                        <GraphContainer>
                            <StyledList mZero smsize style={{ textAlign: "left" }}>
                                <li key={"tprm0"}>
                                    <DescriptionContent
                                        actionWidth
                                    >
                                        <DescriptionTextHead
                                            acSmWidth
                                        >
                                            No.
                                        </DescriptionTextHead>
                                        <DescriptionTextParagraph
                                            acMdWidth
                                        >
                                            Organization
                                        </DescriptionTextParagraph>
                                        <DescriptionTextP>Submitted Date</DescriptionTextP>
                                        <DescriptionTextP>Risk Level</DescriptionTextP>
                                    </DescriptionContent>
                                </li>
                                {contentTPRMData}
                            </StyledList>
                        </GraphContainer>
                    </ProgressBlock>
                </ProgressCard>
            </Grid>
        )
    }

    if (!fetching) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>

                    {
                        processID === null ? <Grid item xs={12} sm={12} md={6} lg={6}><ProgressCard><Loader height="20vh" /></ProgressCard></Grid> : processID === 0 ?
                            contentNewHealthCheck() : contentInProgress()
                    }
                    {/* {
                        processID === null ? <Grid item xs={12} sm={12} md={6} lg={6}><ProgressCard><Loader height="20vh" /></ProgressCard></Grid> : <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ProgressCard>
                                <CardHead>Third Party Risk Assessment Form</CardHead>
                                {
                                    tprmLoading === true ? <Loader height="10vh" /> :
                                        tprmLink === "" ?
                                            <Grid container style={{ marginTop: "20px" }}>
                                                <Grid item xs={12}>
                                                    <label style={{ textAlign: "justify" }}>You can use our link generator to create a form and send it to your third-party suppliers.</label>
                                                </Grid>
                                                <Grid item xs={12} marginTop={"20px"}>
                                                    <TextField variant="outlined" label="Enter the name of organization" id="tprmOrgName" type="text" onChange={(event) => handleTextChange(event)} style={{ width: "80%" }}></TextField>
                                                </Grid>
                                                <Grid item xs={12} marginTop={"10px"}>
                                                    <Button $success onClick={(e) => handleGenerate()}>Generate</Button>
                                                </Grid>
                                            </Grid> :
                                            <Grid container style={{ marginTop: "20px" }}>
                                                <Grid item xs={12}>
                                                    <label>{tprmLink}</label>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: "20px" }}>
                                                    <Button $success onClick={(e) => handleReset()} style={{ marginRight: "5px" }}>Start New</Button>
                                                    <Button $success onClick={(e) => handleCopy()}>Copy</Button>
                                                </Grid>
                                            </Grid>
                                }
                            </ProgressCard>
                        </Grid>
                    } */}
                    {
                        loading === true ? <Grid item xs={12} sm={12} md={6} lg={6}><ProgressCard><Loader height="20vh" /></ProgressCard></Grid> : completedTPRMList.length > 0 ? contentTPRMList() : ""

                    }
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>3rd Party Risk Assessment - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" hasInfo handleClick={handleClick}>
                    3rd Party Risk Assessment
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default RiskAssessmentLandingPage;
