import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { Alert, Box, Grid, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID, IARadvanceProcess } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { CardHead, ProgressBlock, ProgressCard, ProgressValue, StatsText, TextBlock } from "../Information/Information.styled";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel"

import ThemeContext from "../../../../store/theme-context";
import { Wrapper } from "../ASDE8/E8LandingPage.styled";
import AssetTable from "./components/AssetTable";
import { reopenCase, startNewCase } from "../TRA/TRAApi";
import { fetchData, toggleAllowFetch } from "../../../../utility/informationAssetRegister.action";

import { getMileStones } from "../SecurityControlMaturityAssessment/SecurityControlMaturityAssessmentApi";
import SortableTable from "../../components/StyleTable/SortableTable";
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";

function InformationAssetRegisterLandingPage(props) {

    // ** Redux states
    const { data, error, loading, allowFetch } = useSelector((state) => state.informationAssetRegisters);

    // ** Hooks
    const dispatch = useDispatch();
    const theme = useTheme();

    const { keycloak } = useKeycloak();
    const themContext = useContext(ThemeContext);
    const isDarkTheme = themContext?.theme?.palette?.mode === "dark" ? true : false;

    const [header, setHeader] = useState(null);
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);
    // const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [assets, setAssetList] = useState([]);
    const [createNewCaseLoading, setCreateNewCaseLoading] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [disableActionMeta, setDisableActionMeta] = useState(null);

    const e8NavHandler = useCallback((caseID) => {
        // history.push("/it-security/information-asset-register/new", { caseID })
        history.push(`/it-security/information-asset-register/edit/${caseID}`);
    }, [history]);

    // useEffect(() => {
    //     if (!CONTAINER_ID) return;
    //     getAssets();
    // }, [CONTAINER_ID]);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `Information Asset Register`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    useEffect(() => {
        if (data) {
            setAssetList(data);
        }

        if (data === null && allowFetch === false) {
            dispatch(toggleAllowFetch(true))
        }

    }, [data, allowFetch])

    useEffect(() => {
        if (allowFetch) {
            dispatch(fetchData(keycloak, 0));
        }
    }, [dispatch, allowFetch]);

    const toggleDisableAction = (meta = null) => {
        setDisableActions(prev => !prev);
        setDisableActionMeta(meta)
    }

    // async function getAssets() {
    //     setFetching(true);
    //     let assets = [];

    //     let openAssetsRegisterResponse = await getOpenAssetsRegister();
    //     if (openAssetsRegisterResponse?.data?.length > 0) {
    //         assets = [...assets, ...openAssetsRegisterResponse.data];
    //     }
    //     let closeAssetsRegisterResponse = await getClosedAssetsRegister();
    //     if (closeAssetsRegisterResponse?.data?.length > 0) {
    //         assets = [...assets, ...closeAssetsRegisterResponse.data];
    //     }
    //     // setAssetList([...assets]);
    //     setLoading(false);
    //     setFetching(false);
    // }

    // async function startNewMainCase() {
    //     setCreateNewCaseLoading(true);
    //     let data = JSON.stringify({});
    //     let config = {
    //         method: 'post',
    //         url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${IARmainProcess}/instances`,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${keycloak.token}`
    //         },
    //         data: data
    //     };

    //     fetchInstance.apiRequest(config)
    //         .then(function (response) {
    //             var config2 = {
    //                 method: 'get',
    //                 url: `/services/rest/server/containers/${CONTAINER_ID}/cases/${IARadvanceProcess}/instances?status=open&page=0&pageSize=1&sortOrder=false&withData=true`,
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${keycloak.token}`
    //                 }
    //             };
    //             fetchInstance.apiRequest(config2)
    //                 .then(function (response) {
    //                     //check status code
    //                     if (response.status == 200) {

    //                         const caseID = response.data.instances[0]["case-id"];
    //                         if (caseID !== null) {
    //                             //store active caseID in local storage
    //                             window.sessionStorage.setItem("IAR_ACTIVE_CASE", JSON.stringify(caseID));
    //                             e8NavHandler(response.data.instances[0]);
    //                         }
    //                         setCreateNewCaseLoading(false);
    //                     } else {
    //                         setCreateNewCaseLoading(false);
    //                     }
    //                 })
    //                 .catch(function (error) {
    //                     setCreateNewCaseLoading(false);
    //                 });
    //         })
    //         .catch(function (error) {
    //             setCreateNewCaseLoading(false);
    //         });
    // }

    const handleCreateNewCaseClick = useCallback(async () => {
        setCreateNewCaseLoading(true);
        try {
            let obj = {
                "case-group-assignments": {
                    "admin": "cz-sa-riskassessment",
                    "owner": CONTAINER_ID.replaceAll("_0.1", "")
                }
            }
            // Start a new case
            const response = await startNewCase(CONTAINER_ID, keycloak?.token, IARadvanceProcess, obj);
            if (response.success) {
                let caseID = response.data;
                window.sessionStorage.setItem("IAR_ACTIVE_CASE", JSON.stringify(caseID));
                dispatch(toggleAllowFetch(true));
                e8NavHandler(caseID);
            } else {
                setCreateNewCaseLoading(false);
                toast.error("Failed to create new information asset register!");
            }
        } catch (error) {
            // Handle any errors that occur during the process
            setCreateNewCaseLoading(false);
            toast.error("An error occurred: ", error);
        }
    }, [dispatch, e8NavHandler, keycloak?.token]);

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    let modalTitle = "";
    let modalList = [""];

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    }

    function contentNewHealthCheck() {
        return (
            <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ProgressCard>
                        <CardHead></CardHead>
                        <Grid container>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item md={12} xs={12} style={{ marginTop: "5%", textAlign: "left" }}>
                                <Grid container>
                                    <Grid item md={12} style={{ height: "60%" }}>
                                        <TextBlock>
                                            Use this feature to manage and maintain an inventory of an organization's information assets
                                        </TextBlock>
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ marginTop: "10px" }}></Grid>
                                    <Grid item md={12} lg={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <Button $success onClick={(e) => handleCreateNewCaseClick()}>Add new asset</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item md={5} xs={0} sm={0} display={{ xs: "none", lg: "block", md: "block" }}>
                                <ProgressBlock>
                                    <img src="https://abcountrywide.com/wp-content/uploads/2020/10/ASD.jpg" width="70%" height="70%"></img>
                                </ProgressBlock>
                            </Grid> */}
                        </Grid>
                    </ProgressCard>
                </Grid>
            </>
        );
    }

    function totalCriticalAssets(_assets) {
        let count = 0;
        _assets.map((asset) => {
            try {
                if (asset?.c == "High" || asset?.a == "High" || asset?.i == "High") {
                    count++;
                }
            } catch (error) {
            }
        })
        return count;
    }

    function handleEditClick({ status, caseID }) {
        if (status === 1) {
            history.push(`/it-security/information-asset-register/edit/${caseID}`);
        } else if (status === 2) {
            toggleDisableAction({ action: 'edit', caseID: caseID })
            reopenCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, caseID)
                .then((response) => {
                    if (response.success) {
                        history.push(`/it-security/information-asset-register/edit/${caseID}`);
                    } else {
                        toast.error(response?.error?.message || "Failed to reopen case. Please try again later");
                    }
                })
                .catch((error) => {
                    toast.error(error?.message || "Failed to reopen case. Please try again later");
                })
                .finally(() => {
                    toggleDisableAction();
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>Information Asset Register - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    Information Asset Register
                </Heading>
                <Fragment>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        {contentNewHealthCheck()}
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <ProgressCard style={{ width: "100%" }}>
                                <CardHead>Total Assets</CardHead>
                                {
                                    loading ? <Loader height="171px" /> : <ProgressBlock style={{ padding: "50px" }}>
                                        <Typography variant="h2" style={{ height: "100%" }}>{assets.length}</Typography>
                                    </ProgressBlock>
                                }
                            </ProgressCard>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <ProgressCard style={{ width: "100%" }}>
                                <CardHead>High Impact Assets</CardHead>
                                {
                                    loading ? <Loader height="171px" /> : <ProgressBlock>
                                        <CircularProgressWithLabel value={(totalCriticalAssets(assets)) / assets.length * 100} color={theme.palette.impact.high}>
                                            <ProgressValue>{(totalCriticalAssets(assets))} / {assets.length}<br /></ProgressValue>
                                        </CircularProgressWithLabel>
                                    </ProgressBlock>
                                }
                            </ProgressCard>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <ProgressCard style={{ width: "100%" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={7}>
                                        <CardHead style={{ textAlign: "left" }}>
                                            Assets List
                                        </CardHead>
                                    </Grid>
                                </Grid>
                                <ProgressBlock>
                                    {
                                        !loading && assets.length === 0 ? <Alert severity="info">
                                            Table will be generated once you have added asset to your Information Asset Register.
                                        </Alert> : <AssetTable
                                            data={assets}
                                            loading={loading}
                                            fetching={fetching}
                                            handleEditClick={handleEditClick}
                                            startNewMainCase={handleCreateNewCaseClick}
                                            createNewCaseLoading={createNewCaseLoading}
                                            disableActions={disableActions}
                                            disableActionMeta={disableActionMeta}
                                        />
                                    }
                                </ProgressBlock>
                            </ProgressCard>
                        </Grid>
                    </Grid>
                    <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                </Fragment>
            </Wrapper>
        </>
    );
}

export default InformationAssetRegisterLandingPage;
