import styled from "styled-components";

export const ContentWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    min-height: 100vh;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        width: calc(100% - ${(props) => props.drawerWidth}px);
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: 100%;
    }
`;

export const MainContent = styled.div`
    max-width: 42cm !important;
    padding: 0 5% 5%;
    margin:0 auto;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg - 1}px) {
        padding: 0 5% 5%;
    }
`;

export const Wrapper = styled.div`
    text-align: center;
`;