import { useCallback, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { Button } from "../../../../components/UI/Button/Button.styled";
import { useDispatch, useSelector } from "react-redux";

const DeleteRiskCategory = ({ value }) => {

    const dispatch = useDispatch();
    const { riskConsequence } = useSelector((state) => state.riskMatrix);
    const [deleteTabAlertOpen, setDeleteTabAlertOpen] = useState(false);
    const [deleteTabData, setDeleteTabData] = useState(null);

    const toggleDeleteTabAlert = (e, tabData = null) => {
        e?.preventDefault();
        setDeleteTabAlertOpen((prev) => !prev);
        setDeleteTabData(tabData);
    }

    const onDeleteCategory = useCallback((e) => {
        e?.preventDefault();
        let tabs = riskConsequence.tabs.filter((item, index) => index !== deleteTabData.index);
        let fields = [];

        let deleteRowIndex = deleteTabData.index + 1;
        riskConsequence.fields.forEach((field) => {
            let [column, row] = field.name.split('Row');
            row = parseInt(row);
            if (row !== deleteRowIndex) {
                if (row > deleteRowIndex) {
                    fields.push({ name: `${column}Row${row - 1}`, desc: field.desc });
                } else {
                    fields.push(field);
                }
            }
        })
        dispatch({
            type: 'RM_UPDATE_RISK_MATRIX_CONSEQUENCE',
            payload: {
                tabs: tabs,
                levels: riskConsequence.levels,
                fields: fields,
                // rawData: riskConsequence.rawData.filter((item, index) => index !== deleteTabData.index)
            }
        })
        toggleDeleteTabAlert(e);
    }, [deleteTabData, dispatch, riskConsequence])

    return (<>

        <Button
            $error
            style={{ marginRight: '10px' }}
            onClick={(e) => {
                toggleDeleteTabAlert(e, { name: riskConsequence.tabs[value].name, index: value })
            }}
        >
            Delete
        </Button>

        <Dialog
            open={deleteTabAlertOpen}
            onClose={toggleDeleteTabAlert}
        >
            <DialogTitle>
                Delete Impact Definition
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Are you sure you want to delete "${deleteTabData?.name || ""}" impact definition?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleDeleteTabAlert} variant="outlined">Cancel</Button>
                <Button $error onClick={(e) => { onDeleteCategory(e); }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>

    </>
    );
};

export default DeleteRiskCategory;

