import { getCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, Solution } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { fetchData as fetchSolutionBuildingBlockData } from './solutionBuildingBlock.action';
import { fetchData as fetchTRAData, toggleAllowFetch as toggleAllowFetchTRA } from './tra.action';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_SOLUTION_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'TOGGLE_SOLUTION_LOADING', payload: loading };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_SOLUTION_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractSolutionDataFromCaseFile(caseFile) {
  let newSolution = {};

  let solutionDetail = caseFile["case-file"]["case-data"]["solutionData"];
  let solutionID = caseFile["case-file"]["case-data"]["solutionID"];
  let traID = caseFile["case-file"]["case-data"]["traID"];
  if (solutionDetail) {
    newSolution = { ...solutionDetail };
  }

  newSolution["date"] = caseFile["case-started-at"] || 0;
  newSolution["status"] = caseFile["case-status"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);

  newSolution["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  newSolution.caseID = caseFile["case-id"];
  // newSolution.solutionID = beautifyUniqueID(solutionID);
  newSolution.solutionID = beautifyUniqueID(caseFile["case-id"]);
  newSolution.traID = traID;
  return newSolution;
}

export const getSolutionData = async (caseFile) => {
  let newSolution = extractSolutionDataFromCaseFile(caseFile);
  newSolution.caseID = caseFile["case-id"];
  return newSolution;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().solutions; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;

    // Enable loading
    dispatch(toggleLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-solutions');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_SOLUTION_DATA_SUCCESS', payload: { data: cachedData } });

      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let solutions = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, Solution, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, Solution, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const solutionData = [...closedData, ...openedData];

        if (solutionData?.length > 0) {
          solutionData.forEach((solution, index) => {
            let newSolution = {};

            let solutionDetail = solution["case-file"]["case-data"]["solutionData"];
            let solutionID = solution["case-file"]["case-data"]["solutionID"];
            let traID = solution["case-file"]["case-data"]["traID"];
            if (solutionDetail) {
              newSolution = { ...solutionDetail };
            }

            newSolution["date"] = solution["case-started-at"] || 0;
            newSolution["status"] = solution["case-status"] || 0;
            let cDate = new Date(solution["case-started-at"]);

            newSolution["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            newSolution.caseID = solution["case-id"];
            // newSolution.solutionID = beautifyUniqueID(solutionID);
            newSolution.solutionID = beautifyUniqueID(solution["case-id"]);
            newSolution.traID = traID;

            // Find and add project data
            if (projects?.length > 0) {
              let foundProject = projects.find((p) => p.caseID === newSolution.projectID);
              if (foundProject) {
                newSolution.project = foundProject;
                newSolution.projectID = foundProject.caseID;
              }
            }

            if (newSolution?.solutionName) {
              solutions.push(newSolution);
            }
          })

          solutions.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_SOLUTION_DATA_SUCCESS', payload: { data: solutions }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'SOLUTION_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-solutions', solutions);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_SOLUTION_FETCH_COUNTER' });

          // dispatch fetch solution building block data if it's for the first time
          {
            const { fetchCounter } = getState().solutionBuildingBlocks;
            if (fetchCounter === 0 && solutionData?.length > 0) {
              dispatch(fetchSolutionBuildingBlockData(keycloak, 0));
            }
          }

          // dispatch fetch TRA
          dispatch(toggleAllowFetchTRA(true));
          dispatch(fetchTRAData(keycloak, 0));
        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_SOLUTION_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().solutions; // Get the allowFetch value from the state
    const { data: projects } = getState().projects;

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-solutions');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let solutions = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, Solution, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, Solution, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const solutionData = [...closedData, ...openedData];

      if (solutionData) {
        solutionData.forEach((solution, index) => {
          let newSolution = {};

          let solutionDetail = solution["case-file"]["case-data"]["solutionData"];
          let solutionID = solution["case-file"]["case-data"]["solutionID"];
          let traID = solution["case-file"]["case-data"]["traID"];
          if (solutionDetail) {
            newSolution = { ...solutionDetail };
          }

          newSolution["date"] = solution["case-started-at"] || 0;
          newSolution["status"] = solution["case-status"] || 0;
          let cDate = new Date(solution["case-started-at"]);

          newSolution["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          newSolution.caseID = solution["case-id"];
          // newSolution.solutionID = beautifyUniqueID(solutionID);
          newSolution.solutionID = beautifyUniqueID(solution["case-id"]);
          newSolution.traID = traID;

          // Find and add project data
          if (projects?.length > 0) {
            let foundProject = projects.find((p) => p.caseID === newSolution.projectID);
            if (foundProject) {
              newSolution.project = foundProject;
              newSolution.projectID = foundProject.caseID;
            }
          }

          if (newSolution?.solutionName) {
            solutions.push(newSolution);
          }
        })

        if (cachedData?.length > 0) {
          solutions = [...cachedData, ...solutions];
        }

        solutions.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_SOLUTION_DATA_SUCCESS', payload: { data: solutions }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'SOLUTION_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_SOLUTION_FETCH_COUNTER' });

          // dispatch fetch solution building block data if it's for the first time
          {
            const { fetchCounter } = getState().solutionBuildingBlocks;
            if (fetchCounter === 0) {
              dispatch(fetchSolutionBuildingBlockData(keycloak, 0));
            }
          }

          // dispatch fetch TRA
          dispatch(toggleAllowFetchTRA(true));
          dispatch(fetchTRAData(keycloak, 0));
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-solutions', solutions);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_SOLUTION_DATA_ERROR', payload: error.message });
    }
  };
};

export const removeEmptyCase = (keycloak) => {
  return async (dispatch, getState) => {
    try {
      let cachedData = await getCachedData(keycloak.token, 'cached-solutions');
      if (cachedData?.length > 0) {
        cachedData = cachedData.filter((c) => c["solutionName"]);
        cachedData = cachedData.map((c) => ({ ...c }));
      }

      cachedData.sort((a, b) => b["date"] - a["date"]);

      dispatch({ type: 'FETCH_SOLUTION_DATA_SUCCESS', payload: { data: cachedData }, });

      // Cache the fetched data
      cacheData(keycloak.token, 'cached-solutions', cachedData);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  }
}