import React, { useContext } from "react";

import annotationPlugin from "chartjs-plugin-annotation";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ThemeContext from "../../../../../store/theme-context";
import 'react-medium-image-zoom/dist/styles.css'
import { CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapperBar, StatsText, TextBlock } from "../BusinessImpactAssessmentLandingPage.styled";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels);

ChartJS.defaults.plugins.legend = {
    display: false,
};

const BusinessCriticalityCard = () => {
    const { mode } = useContext(ThemeContext);

    let targetData = [41, 36, 48, 27, 47, 25];
    let currentData = [19, 24, 12, 33, 13, 35];

    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                enabled: false // <-- this option disables tooltips
            }
        },
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: { color: mode === 'light' ? '#000' : '#fff', beginAtZero: true }
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                lineThickness: 0,
                tickThickness: 0,
                stacked: true,
                ticks: {
                    min: 0,
                    color: mode === 'light' ? '#000' : '#fff', beginAtZero: true,
                    stepSize: 0.5,
                },
            },
        },
    };

    const data = {
        labels: ["SharePoint", "ServiceNOW", "Xero", "Azure DevOps", "AWS", "SAP"],
        current: {
            dataSet: targetData,
        },
        target: {
            dataSet: currentData,
        },
    };

    return (
        <ProgressCard>
            <ProgressCardContent>
                <ProgressCardContentTop>
                    <CardHead>Business Criticality</CardHead>
                    <ProgressContainer>
                        <ProgressWrapperBar>
                            <Bar
                                options={options}
                                data={{
                                    labels: data.labels,
                                    offset: true,
                                    datasets: [
                                        {
                                            label: "Current Maturity",
                                            barThickness: 20,
                                            data: data.target.dataSet,
                                            datalabels: {
                                                anchor: "center",
                                                color: "#fff",
                                                font: {
                                                    size: 12,
                                                    weight: "bold"
                                                }
                                            },
                                            backgroundColor: "red",
                                        },
                                        {
                                            label: "Work Remaining",
                                            data: data.current.dataSet,
                                            barThickness: 20,
                                            datalabels: {
                                                labels: {
                                                    title: false,
                                                },
                                            },
                                            backgroundColor: "#5e5e5e",
                                        },
                                    ],
                                }}
                            />
                        </ProgressWrapperBar>
                    </ProgressContainer>
                    <LegendContainer>
                        <TextBlock >
                            <DotSign style={{ backgroundColor: 'rgb(237, 30, 36)' }} />
                            <StatsText style={{ textAlign: 'left' }}>{"Number of core line of business applications containing high impact assets"}</StatsText>
                        </TextBlock>
                    </LegendContainer>
                </ProgressCardContentTop>
            </ProgressCardContent>
        </ProgressCard>
    );
};

export default BusinessCriticalityCard;