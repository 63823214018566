import styled, { css } from "styled-components";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const FormWrapper = styled.form`
    max-width: 933px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding-top: 63px;
    padding-bottom: 63px;
    margin: 0 auto;
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        max-width: 100%;
        /* padding: 31px 15px 28px; */
        padding-top: 31px;
        margin-top: 16px;
    }
`;

export const StyledTextField = styled(TextField)`
    width: 100%;
    max-width: 482px;
    border-radius: 4px;
    margin: 0 auto !important;
    margin-bottom: 32px !important;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 1}px) {
        max-width: 366px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 100}px) {
        max-width: 305px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 250}px) {
        max-width: 260px;
    }

    & .MuiOutlinedInput-root {
        padding-left: 24px;
        height: 48px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            padding-left: 0;
        }
    }

    & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #828282;

        ${({ $organisationalNameError }) =>
            $organisationalNameError &&
            css`
                border: 1px solid #ff0000 !important;
            `}
        ${({ $headOfficeAddressError }) =>
            $headOfficeAddressError &&
            css`
                border: 1px solid #ff0000 !important;
            `}
        ${({ $industryTypeError }) =>
            $industryTypeError &&
            css`
                border: 1px solid #ff0000 !important;
            `}
        ${({ $emailAddressError }) =>
            $emailAddressError &&
            css`
                border: 1px solid #ff0000 !important;
            `}
        ${({ $phoneNumberError }) =>
            $phoneNumberError &&
            css`
                border: 1px solid #ff0000 !important;
            `}
    }

    & .MuiInputLabel-root {
        font-family: Rubik, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding-left: 24px;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(51,51,51, 0.52)" : "rgba(255,255,255, 0.52)")};
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            padding: 0;
        }

        ${({ $organisationalNameError }) =>
            $organisationalNameError &&
            css`
                color: #ff0000 !important;
            `}
        ${({ $headOfficeAddressError }) =>
            $headOfficeAddressError &&
            css`
                color: #ff0000 !important;
            `}
        ${({ $industryTypeError }) =>
            $industryTypeError &&
            css`
                color: #ff0000 !important;
            `}
        ${({ $emailAddressError }) =>
            $emailAddressError &&
            css`
                color: #ff0000 !important;
            `}
        ${({ $phoneNumberError }) =>
            $phoneNumberError &&
            css`
                color: #ff0000 !important;
            `}

        &.Mui-focused {
            padding-left: 0px;
        }
    }

    & .MuiFormHelperText-root {
        color: #ff0000;
    }
`;

export const FormBtn = styled(LoadingButton)`
    &.MuiButton-root {
        width: auto;
        min-height: 42px;
        background-color: #46a11b;
        color: #fff;
        padding: 8px 22px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-family: Rubik, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        margin: auto;
        margin-top: 36px;
    }

    &.MuiButton-root:hover {
        background-color: #46a11b;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 100}px) {
        &.MuiButton-root {
            width: 280px;
            padding: 2px 4px;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm - 250}px) {
        &.MuiButton-root {
            width: 250px;
            padding: 2px 4px;
            font-size: 13px;
        }
    }
`;
