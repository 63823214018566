import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const DynamicDropdownList = ({ parentIndex, index, value,data, label, placeHolder, onChange, isRequired }) => {

  const handleChange = (event) => {
    onChange(parentIndex, index, event.target.value)
  };

  return (
    <FormControl variant="outlined" fullWidth required={isRequired || true}>
      <InputLabel>{label}</InputLabel>
      <Select value={value || ''} onChange={handleChange} label={label}>
        <MenuItem value="">
          <em>{placeHolder}</em>
        </MenuItem>
        {data.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DynamicDropdownList;
