import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import styled from "@emotion/styled";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        backgroundImage: "",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
        borderRadius: "16px",
        border: "0"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function InfoModalWithItem({ open, handleClose, content }) {
    return (
        <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        borderRadius: "16px",
                        backgroundColor: "#12121200"
                    },
                }}
                style={{ backdropFilter: "blur(8px)" }}
            >

                <DialogContent>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {content}
                    </div>
                </DialogContent>
            </BootstrapDialog >
    );
}

export default InfoModalWithItem;
