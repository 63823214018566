import { UserTypes } from "./constants";

export const MaturityAssessmentIntroData = {
    "5.1": {
        "5.1.1": {
            "controlHeading": "Policies for information security",
            "controlDescription": "A set of policies for information security shall be defined, approved by management, published and communicated to employees and relevant external parties."
        },
        "5.1.2": {
            "controlHeading": "Review of the policies for information security",
            "controlDescription": "Control The policies for information security shall be reviewed at planned intervals or if significant changes occur to ensure their continuing suitability, adequacy and effectiveness."
        }
    },
    "6.1": {
        "6.1.1": {
            "controlHeading": "Information security roles and responsibilities",
            "controlDescription": "All information security responsibilities shall be defined and allocated."
        },
        "6.1.2": {
            "controlHeading": "Segregation of duties",
            "controlDescription": "Conflicting duties and areas of responsibility shall be segregated to reduce opportunities for unauthorized or unintentional modification or misuse of the organization’s assets"
        },
        "6.1.3": {
            "controlHeading": "Contact with authorities",
            "controlDescription": "Appropriate contacts with relevant authorities shall be maintained."
        },
        "6.1.4": {
            "controlHeading": "Contact with special interest groups",
            "controlDescription": "Appropriate contacts with special interest groups or other specialist security forums and professional associations shall be maintained."
        },
        "6.1.5": {
            "controlHeading": "Information security in project management",
            "controlDescription": "Information security shall be addressed in project management, regardless of the type of the project."
        }
    },
    "6.2": {
        "6.2.1": {
            "controlHeading": "Mobile device policy",
            "controlDescription": "A policy and supporting security measures shall be adopted to manage the risks introduced by using mobile devices."
        },
        "6.2.2": {
            "controlHeading": "Teleworking",
            "controlDescription": "A policy and supporting security measures shall be implemented to protect information accessed, processed or stored at teleworking sites."
        }
    },
    "7.1": {
        "7.1.1": {
            "controlHeading": "Screening",
            "controlDescription": "Background verification checks on all candidates for employment shall be carried out in accordance with relevant laws, regulations and ethics and shall be proportional to the business requirements, the classification of the information to be accessed and the perceived risks."
        },
        "7.1.2": {
            "controlHeading": "Terms and conditions of employment",
            "controlDescription": "The contractual agreements with employees and contractors shall state their and the organization’s responsibilities for information security."
        }
    },
    "7.2": {
        "7.2.1": {
            "controlHeading": "Management responsibilities",
            "controlDescription": "Management shall require all employees and contractors to apply information security in accordance with the established policies and procedures of the organization."
        },
        "7.2.2": {
            "controlHeading": "Information security awareness, education and training",
            "controlDescription": "All employees of the organization and, where relevant, contractors shall receive appropriate awareness education and training and regular updates in organizational policies and procedures, as relevant for their job function."
        },
        "7.2.3": {
            "controlHeading": "Disciplinary process",
            "controlDescription": "There shall be a formal and communicated disciplinary process in place to take action against employees who have committed an information security breach."
        }
    },
    "7.3": {
        "7.3.1": {
            "controlHeading": "Termination or change of employment responsibilities",
            "controlDescription": "Information security responsibilities and duties that remain valid after termination or change of employment shall be defined, communicated to the employee or contractor and enforced."
        }
    },
    "8.1": {
        "8.1.1": {
            "controlHeading": "Inventory of assets",
            "controlDescription": "Information, other assets associated with information and information processing facilities shall be identified and an inventory of these assets shall be drawn up and maintained."
        },
        "8.1.2": {
            "controlHeading": "Ownership of assets",
            "controlDescription": "Assets maintained in the inventory shall be owned."
        }
    },
    "8.2": {
        "8.2.1": {
            "controlHeading": "Classification of information",
            "controlDescription": "Information shall be classified in terms of legal requirements, value, criticality and sensitivity to unauthorised disclosure or modification."
        },
        "8.2.2": {
            "controlHeading": "Labelling of information",
            "controlDescription": "An appropriate set of procedures for information labelling shall be developed and implemented in accordance with the information classification scheme adopted by the organization."
        }
    },
    "8.3": {
        "8.3.1": {
            "controlHeading": "Management of removable media",
            "controlDescription": "Procedures shall be implemented for the management of removable media in accordance with the classification scheme adopted by the organization."
        }
    },
    "9.1": {
        "9.1.1": {
            "controlHeading": "Access control policy",
            "controlDescription": "An access control policy shall be established, documented and reviewed based on business and information security requirements."
        },
        "9.1.2": {
            "controlHeading": "Access to networks and network services",
            "controlDescription": "Users shall only be provided with access to the network and network services that they have been specifically authorized to use."
        }
    },
    "9.2": {
        "9.2.1": {
            "controlHeading": "User registration and deregistration",
            "controlDescription": "A formal user registration and de-registration process shall be implemented to enable assignment of access rights."
        },
        "9.2.2": {
            "controlHeading": "User access provisioning",
            "controlDescription": "A formal user access provisioning process shall be implemented to assign or revoke access rights for all user types to all systems and services."
        }
    },
    "9.3": {
        "9.3.1": {
            "controlHeading": "Use of secret authentication information",
            "controlDescription": "Users shall be required to follow the organization’s practices in the use of secret authentication information."
        }
    },
    "9.4": {
        "9.4.1": {
            "controlHeading": "Information access restriction",
            "controlDescription": "Access to information and application system functions shall be restricted in accordance with the access control policy."
        },
        "9.4.2": {
            "controlHeading": "Secure log-on procedures",
            "controlDescription": "Where required by the access control policy, access to systems and applications shall be controlled by a secure log-on procedure."
        },
        "9.4.3": {
            "controlHeading": "Password management system",
            "controlDescription": "Password management systems shall be interactive and shall ensure quality passwords."
        },
        "9.4.4": {
            "controlHeading": "Use of privileged utility programs",
            "controlDescription": "Control the use of utility programs that might be capable of overriding system and application controls shall be restricted and tightly controlled."
        }
    },
    "10.1": {
        "10.1.1": {
            "controlHeading": "Policy on the use of cryptographic controls",
            "controlDescription": "A policy on the use of cryptographic controls for protection of information shall be developed and implemented."
        }
    },
    "10.2": {
        "10.2.1": {
            "controlHeading": "Key management",
            "controlDescription": "A policy on the use, protection and lifetime of cryptographic keys shall be developed and implemented through their whole lifecycle."
        }
    },
    "11.1": {
        "11.1.1": {
            "controlHeading": "Physical security perimeter",
            "controlDescription": "Security perimeters shall be defined and used to protect areas that contain either sensitive or critical information and information processing facilities."
        },
        "11.1.2": {
            "controlHeading": "Physical entry controls",
            "controlDescription": "Secure areas shall be protected by appropriate entry controls to ensure that only authorized personnel are allowed access."
        }
    },
    "11.2": {
        "11.2.1": {
            "controlHeading": "Equipment siting and protection",
            "controlDescription": "Equipment shall be sited and protected to reduce the risks from environmental threats and hazards, and opportunities for unauthorized access."
        },
        "11.2.2": {
            "controlHeading": "Supporting utilities",
            "controlDescription": "Equipment shall be protected from power failures and other disruptions caused by failures in supporting utilities."
        }
    },
    "12.1": {
        "12.1.1": {
            "controlHeading": "Documented operating procedures",
            "controlDescription": "Operating procedures shall be documented and made available to all users who need them."
        },
        "12.1.2": {
            "controlHeading": "Change management",
            "controlDescription": "Changes to the organization, business processes, information processing facilities and systems that affect information security shall be controlled."
        }
    },
    "12.2": {
        "12.2.1": {
            "controlHeading": "Controls against malware",
            "controlDescription": "Detection, prevention and recovery controls to protect against malware shall be implemented, combined with appropriate user awareness."
        }
    },
    "12.3": {
        "12.3.1": {
            "controlHeading": "Information backup",
            "controlDescription": "Backup copies of information, software and system images shall be taken and tested regularly in accordance with an agreed backup policy."
        }
    },
    "12.4": {
        "12.4.1": {
            "controlHeading": "Event logging",
            "controlDescription": "Event logs recording user activities, exceptions, faults and information security events shall be produced, kept and regularly reviewed."
        }
    },
    "12.5": {
        "12.5.1": {
            "controlHeading": "Installation of software on operational systems",
            "controlDescription": "Procedures shall be implemented to control the installation of software on operational systems."
        }
    },
    "14.1": {
        "14.1.1": {
            "controlHeading": "Information security policy for supplier relationships",
            "controlDescription": "Information security requirements for mitigating the risks associated with supplier’s access to the organization’s assets shall be agreed with the supplier and documented."
        }
    },
    "14.2": {
        "14.2.1": {
            "controlHeading": "Secure development policy",
            "controlDescription": "Rules for the development of software and systems shall be established and applied to developments within the organization."
        },
        "14.2.2": {
            "controlHeading": "System change control procedures",
            "controlDescription": "Changes to systems within the development lifecycle shall be controlled by the use of formal change control procedures."
        }
    },
    "16.1": {
        "16.1.1": {
            "controlHeading": "Responsibilities and procedures",
            "controlDescription": "Management responsibilities and procedures shall be established to ensure a quick, effective and orderly response to information security incidents."
        },
        "16.1.2": {
            "controlHeading": "Reporting information security events",
            "controlDescription": "Information security events shall be reported through appropriate management channels as quickly as possible."
        }
    },
    "17.1": {
        "17.1.1": {
            "controlHeading": "Planning information security continuity",
            "controlDescription": "The organization shall determine its requirements for information security and the continuity of information security management in adverse situations, e.g. during a crisis or disaster."
        },
        "17.1.2": {
            "controlHeading": "Implementing information security continuity",
            "controlDescription": "The organization shall establish, document, implement and maintain processes, procedures and controls to ensure the required level of continuity for information security during an adverse situation."
        }
    },
    "17.2": {
        "17.2.1": {
            "controlHeading": "Availability of information processing facilities",
            "controlDescription": "Information processing facilities shall be implemented with redundancy sufficient to meet availability requirements."
        }
    },
    "18.1": {
        "18.1.1": {
            "controlHeading": "Identification of applicable legislation and contractual requirements",
            "controlDescription": "All relevant legislative statutory, regulatory, contractual requirements and the organization’s approach to meet these requirements shall be explicitly identified, documented and kept up to date for each information system and the organization."
        }
    }
}

export const getMAIntroSteps = (qNum) => {
    let introData = MaturityAssessmentIntroData[qNum] || {};
    let introSteps = Object.keys(introData).map((key) => {
        return {
            element: `#intro-ma-${key.replaceAll(".", "")}`,
            intro: MaturityAssessmentIntroData[qNum][key]['controlDescription'],
            highlightClass: 'myHighlightClass',
        }
    })
    return introSteps;
}

export const getDashboardIntroSteps = (username, userType) => {
    const guidanceByUserType = {
        [UserTypes.novice]: [
            {
                title: `<b> Welcome to CisoZen, ${username} - Your Partner in Elevating Business Cyber Security!  </b>`,
                intro: `We're thrilled to have you on board, exciting times lie ahead as you embark on a journey to strengthen your business's cyber security with CisoZen. We introduce you to a new level of cyber security that puts you in control and keeps your online presence secure. CisoZen empowers you with the tools and knowledge you need to enhance your cyber security posture. Now, let's tailor your experience based on your expertise: New to cyber security? We've got you covered with simple views and lots of guidance. Dive into the complete app with minimal guidance to streamline your journey. Your business's cyber security transformation begins now. Let's create a shield against cyber threats together. To start this empowering expedition, simply hit 'Next' and let's sail!  `,
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#duration',
                title: "Duration",
                intro: 'This circular countdown indicator visually signifies the optimal timing for conducting your next Rapid Health Check (RHC). In this timer, the circle is divided into two distinct segments: white and green. The white portion of the circle signifies the total duration for completing the Rapid Health Check. It represents the starting point of the countdown. The green portion of the circle represents the time remaining to do your next RHC. As time moves forward, this green segment gradually decreases, visually indicating the requirement of next Health Check. Upon expiration, the circle reads "0 days left," emphasizing the immediacy of the RHC. ',
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#score',
                title: "CisoZen Score",
                intro: "This circular score indicator is for you to keep tabs of your CisoZen score and see the optimal security score to maintain your business cyber security health. Meanwhile, the red progress indicator illustrates the score you'll achieve upon completing the Rapid Health Check (RHC). This score tile lets you know what the perfect Cisozen score should be for your business along with your actual score. If you complete the Rapid Health Check within prescribe time frame, then you will be rewarded with CisoZen score. ",
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#recommendation',
                title: "Recommendation",
                intro: "This will be list of the recommendations for improving your security posture which will be generated after you have completed your Rapid Health Check (RHC). These are the recommendations for improving your security posture which are generated after you have completed your Rapid Health Check (RHC). You should socialize this recommendation to your senior to secure appropriate management for implementation of the require security controls and use this recommendation to improve your business’s cyber security health. ",
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#riskmanagement',
                title: "Risk Management",
                intro: `Check sky/ doesn’t make sense but checking Weather app could be your risk matrix. This is the tool to use for identifying which cyber security risk are beyond your organization tolerance levels. It will help your organization to only focus on solving problems that will cost most pain to your business if unaddressed. For example, using a risk matrix is like while planning a picnic, there’s a high chance of rain (likely and a bit inconvenient) or a high chance of purple elephants appearing (unlikely and very chaotic). This helps you prepare accordingly.  `,
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#currentmaturity',
                title: "Current Maturity",
                intro: `This is the maturity bar graph that shows how strong your organization is in handling cyber challenges. The green bar shows where your organization stands right now. There's also a white dashed line at level 3, which is the first target for you to aim for. 

                Here's the key: the higher you keep your green bar above this white line, the safer your business becomes from cyber criminals. It's like a game - the more you score above 3, the better you're doing in maintaining your organization’s information security posture. 
                
                Let’s see what each of the level signifies: 
                <br/>0 – Non-existent: It's like not having a plan at all, leading to risks and problems because there's no organized way to do things. 
                
                <br/>1 – Initial/Ad Hoc: It's like knowing there are problems, but the way to solve them is messy and disorganized, with no clear plan or responsibility. 
                
                <br/>2 – Repeatable but intuitive: The process is done but not documented and there are many problems and uncertainties that could cause serious trouble if not handled carefully. 
                
                <br/>3 – Defined: There's a planned way of doing things that works, problems are checked often, management can handle most issues, and everyone knows the steps to follow, even though some mistakes might be missed. (Recommended) 
                
                <br/>4 – Managed & Measurable: The process is controlled by defined rules, goals are set and monitored, problems are addressed, and tools are utilized for improvement. 
                
                <br/>5 – Optimized: The process is continuously made improved to fit what's needed, using new ideas and technology, with clear goals, everyone making sure it works, and everyone finding ways to improve it. `,
                //position: 'right',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#usersecurityposture',
                title: "User Security Posture",
                intro: `Your journey begins with the castle wall, representing your starting point. As you address risks according to recommendations then you will see your castle evolve, reflecting your progress. Your Cisozen score and maturity level boost the castle strength. You will be awarded with points and badge for your timely task completion. Remember, you're building more than a fortress – you're advancing your business cyber security posture. `,
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#idealsecurityposture',
                title: "Ideal Security Posture",
                intro: `Congratulations on your progress! Imagine your cybersecurity at its peak – a complete castle. Your tasks shape this castle; high CisoZen Score fortifies, Maturity level score adds depth. Keep both scores balanced for castle perfection. With each task, your castle grows, and your security strengthens. It's more than tasks; it's crafting excellence. Embrace gamification as tasks build your castle and make your journey of securing your organization enjoyable. `,
                highlightClass: 'myHighlightClass',
            },

        ],
        [UserTypes.intermediate]: [
            {
                title: `<b> Welcome to CisoZen, ${username} - Your Partner in Elevating Business Cyber Security!  </b>`,
                intro: `We're thrilled to have you on board, exciting times lie ahead as you embark on a journey to strengthen your business's cyber security with CisoZen. We introduce you to a new level of cyber security that puts you in control and keeps your online presence secure. CisoZen empowers you with the tools and knowledge you need to enhance your cyber security posture. Now, let's tailor your experience based on your expertise: Cyber security pro? Dive into the complete app with minimal guidance to streamline your journey. Your business's cyber security transformation begins now. Let's create a shield against cyber threats together. To start this empowering expedition, simply hit 'Next' and let's sail!  `,
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#duration',
                title: "Duration",
                intro: 'This circular countdown indicator visually signifies the optimal timing for conducting your next Rapid Health Check (RHC). In this timer, the circle is divided into two distinct segments: white and green. The white portion of the circle signifies the total duration for completing the Rapid Health Check. It represents the starting point of the countdown. The green portion of the circle represents the time remaining to do your next RHC. As time moves forward, this green segment gradually decreases, visually indicating the requirement of next Health Check. Upon expiration, the circle reads "0 days left," emphasizing the immediacy of the RHC. ',
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#score',
                title: "CisoZen Score",
                intro: "This circular score indicator is for you to keep tabs of your CisoZen score and see the optimal security score to maintain your business cyber security health. Meanwhile, the red progress indicator illustrates the score you'll achieve upon completing the Rapid Health Check (RHC). This score tile lets you know what the perfect Cisozen score should be for your business along with your actual score. If you complete the Rapid Health Check within prescribe time frame, then you will be rewarded with CisoZen score. ",
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#recommendation',
                title: "Recommendation",
                intro: "This will be list of the recommendations for improving your security posture which will be generated after you have completed your Rapid Health Check (RHC). These are the recommendations for improving your security posture which are generated after you have completed your Rapid Health Check (RHC). You should socialize this recommendation to your senior to secure appropriate management for implementation of the require security controls and use this recommendation to improve your business’s cyber security health. ",
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#riskmanagement',
                title: "Risk Management",
                intro: `This is the tool to use for identifying which cyber security risk are beyond your organization tolerance levels. It will help your organization to focus financial and Human resources to risk that are most impactful to your business. Ideally this matrix is the same as any risk management matrix that an organization already has. It is configurable. `,
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#currentmaturity',
                title: "Current Maturity",
                intro: "This is the current maturity bar graph, displaying your organization’s current maturity score depicted by the green bar alongside the recommended maturity score set at 3 denoted by the white dash line. Maturity level three is the first goal to achieve in terms of desired maturity level for your security controls. Let’s explore the maturity level from 0 to 5 and see what each level signifies in different processes: <br/>0 – Non-existent: No process or recognition. High risk, no evidence of achievement. <br/>1 – Initial/Ad Hoc: Recognizing issues but disorganized. No clear approach, deficiencies unnoticed. <br/>2 – Repeatable but intuitive: Process implemented but not documented. Depends on individuals’ knowledge. Many weaknesses exist, management actions inconsistent. (Recommended) <br/>3 – Defined: Process implemented with defined approach. Some weaknesses remain with potential severity, Mandated procedures, operating effectiveness evaluated.<br/>4 – Managed & Measurable: Process operates within defined limits. Frequent evaluations, compliance monitored. Quantitative goals set, automation in place. <br/>5 – Optimized: Continuously improved. New tech and concepts used. Improvement objectives defined. Proactive employees for enhancing controls, accountability established. ",
                // position: 'right',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#usersecurityposture',
                title: "User Security Posture",
                intro: `Your journey begins with the castle wall, representing your starting point. As you address risks according to recommendations then you will see your castle evolve, reflecting your progress. Your Cisozen score and maturity level boost the castle strength. You will be awarded with points and badge for your timely task completion. Remember, you're building more than a fortress – you're advancing your business cyber security posture. `,
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#idealsecurityposture',
                title: "Ideal Security Posture",
                intro: `Congratulations on your progress! Imagine your cybersecurity at its peak – a complete castle. Your tasks shape this castle; high CisoZen Score fortifies, Maturity level score adds depth. Keep both scores balanced for castle perfection. With each task, your castle grows, and your security strengthens. It's more than tasks; it's crafting excellence. Embrace gamification as tasks build your castle and make your journey of securing your organization enjoyable. `,
                highlightClass: 'myHighlightClass',
            },

        ],
        [UserTypes.advance]: [
            {
                title: `<b> Welcome to CisoZen, ${username} - Your Partner in Elevating Business Cyber Security!  </b>`,
                intro: `We're thrilled to have you on board, exciting times lie ahead as you embark on a journey to strengthen your business's cyber security with CisoZen. We introduce you to a new level of cyber security that puts you in control and keeps your online presence secure. CisoZen empowers you with the tools and knowledge you need to enhance your cyber security posture. Now, let's tailor your experience based on your expertise: Cyber security pro? Dive into the complete app with minimal guidance to streamline your journey. Your business's cyber security transformation begins now. Let's create a shield against cyber threats together. To start this empowering expedition, simply hit 'Next' and let's sail!  `,
                tooltipClass: 'myTooltipClass',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#duration',
                title: "Duration",
                intro: 'This circular countdown indicator visually signifies the optimal timing for conducting your next Rapid Health Check (RHC). In this timer, the circle is divided into two distinct segments: white and green. The white portion of the circle signifies the total duration for completing the Rapid Health Check. It represents the starting point of the countdown. The green portion of the circle represents the time remaining to do your next RHC. As time moves forward, this green segment gradually decreases, visually indicating the requirement of next Health Check. Upon expiration, the circle reads "0 days left," emphasizing the immediacy of the RHC. ',
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#score',
                title: "CisoZen Score",
                intro: "This circular score indicator is for you to keep tabs of your CisoZen score and see the optimal security score to maintain your business cyber security health. Meanwhile, the red progress indicator illustrates the score you'll achieve upon completing the Rapid Health Check (RHC). This score tile lets you know what the perfect Cisozen score should be for your business along with your actual score. If you complete the Rapid Health Check within prescribe time frame, then you will be rewarded with CisoZen score. ",
                position: 'top',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#recommendation',
                title: "Recommendation",
                intro: "This will be list of the recommendations for improving your security posture which will be generated after you have completed your Rapid Health Check (RHC). These are the recommendations for improving your security posture which are generated after you have completed your Rapid Health Check (RHC). You should socialize this recommendation to your senior to secure appropriate management for implementation of the require security controls and use this recommendation to improve your business’s cyber security health. ",
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#riskmanagement',
                title: "Risk Management",
                intro: 'This is the tool to use for identifying which cyber security risk are beyond your organization tolerance levels. It will help your organization to focus financial and Human resources to risk that are most impactful to your business. Ideally this matrix is the same as any risk management matrix that an organization already has. It is configurable. ',
                //position: 'bottom',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#currentmaturity',
                title: "Current Maturity",
                intro: "This is the current maturity bar graph, displaying your organization’s current maturity score depicted by the green bar alongside the recommended maturity score set at 3 denoted by the white dash line. Maturity level three is the first goal to achieve in terms of desired maturity level for your security controls. Let’s explore the maturity level from 0 to 5 and see what each level signifies in different processes: <br/>0 – Non-existent: No process or recognition. High risk, no evidence of achievement. <br/>1 – Initial/Ad Hoc: Recognizing issues but disorganized. No clear approach, deficiencies unnoticed. <br/>2 – Repeatable but intuitive: Process implemented but not documented. Depends on individuals’ knowledge. Many weaknesses exist, management actions inconsistent. (Recommended) <br/>3 – Defined: Process implemented with defined approach. Some weaknesses remain with potential severity, Mandated procedures, operating effectiveness evaluated.<br/>4 – Managed & Measurable: Process operates within defined limits. Frequent evaluations, compliance monitored. Quantitative goals set, automation in place. <br/>5 – Optimized: Continuously improved. New tech and concepts used. Improvement objectives defined. Proactive employees for enhancing controls, accountability established. ",
                //position: 'right',
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#usersecurityposture',
                title: "User Security Posture",
                intro: "Your journey begins with the castle wall, representing your starting point. As you address risks according to recommendations then you will see your castle evolve, reflecting your progress. Your Cisozen score and maturity level boost the castle strength. You will be awarded with points and badge for your timely task completion. Remember, you're building more than a fortress – you're advancing your business cyber security posture. ",
                highlightClass: 'myHighlightClass',
            },
            {
                element: '#idealsecurityposture',
                title: "Ideal Security Posture",
                intro: "Congratulations on your progress! Imagine your cybersecurity at its peak – a complete castle. Your tasks shape this castle; high Cisozen Score fortifies, Maturity level score adds depth. Keep both scores balanced for castle perfection. With each task, your castle grows, and your security strengthens. It's more than tasks; it's crafting excellence. Embrace gamification as tasks build your castle and make your journey of securing your organization enjoyable. ",
                highlightClass: 'myHighlightClass',
            }

        ],
    };

    return guidanceByUserType[userType] || [];
}

export const IntroHealthCheckCardData = {
    centerLabelTitle: "9 days remaining",
    remainingDays: 9,
    data: [
        {
            "label": "Your Progress",
            "value": 18.367346938775512,
            "color": "rgb(237, 30, 36)",
            "tooltipValue": 54
        },
        {
            "label": "Target Progress",
            "value": 81.63265306122449,
            "color": "rgb(64, 64, 64)",
            "tooltipValue": 294
        }
    ]
}

export const IntroCisoZenScoreData = {
    "centerLabelTitle": "186 / 236",
    "data": [
        {
            "label": "Your Score",
            "value": 78.8135593220339,
            "color": "rgb(70, 161, 27)",
            "tooltipValue": 186
        },
        {
            "label": "Target Score",
            "value": 21.186440677966104,
            "color": "rgb(189, 189, 189)",
            "tooltipValue": 236
        }
    ]
}

export const IntroRiskAssessmentData = [
    {
        "label": "Extreme Risks",
        "value": 3,
        "color": "#ff0000",
        "key": "Extreme"
    },
    {
        "label": "High Risks",
        "value": 6,
        "color": "#ffc000",
        "key": "High"
    },
    {
        "label": "Medium Risks",
        "value": 3,
        "color": "#ffff00",
        "key": "Medium"
    },
    {
        "label": "Low Risks",
        "value": 16,
        "color": "#92d050",
        "key": "Low"
    }
]

export const IntroCisoZenSupportData = {
    "centerLabelTitle": "7 days left",
    "remainingDays": 7,
    "data": [
        {
            "label": "Time Left",
            "value": 70,
            "color": "rgb(70, 161, 27)",
            "tooltipValue": 7
        },
        {
            "label": "Total Duration",
            "color": "rgb(189, 189, 189)",
            "value": 30,
            "tooltipValue": 10
        }
    ]
}

export const IntroOrganisationMaturityData = [
    3,
    3,
    4,
    4,
    4
]

export const IntroSecurityPostureData = {
    "loading": false,
    "onDate": "16/05/2024",
    "progress": "Rapid Health Check",
    "score": {
        "maxScore": 236,
        "currentScore": 186
    },
    "stageNumber1": 3,
    "oldSecondLabels": [
        3,
        3,
        4,
        4,
        4
    ]
}