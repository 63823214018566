import { isValid } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateQuestion = (props) => {
    const { isSub, readOnly } = props;

    const validateInput = (inputValue) => {
        return isValid(inputValue);
    };

    if (isSub) {
        const { sub, value, onSubChangeHandler } = props;

        // const [day, month, year] = value.split('/');
        // const currDate = dayjs(`${year}-${month}-${day}`);

        const onDateChangeHandler = (newDate) => {
            // let date;
            // if (!newDate || newDate.format("DD/MM/YYYY") === "Invalid Date") {
            //     date = "";
            // } else {
            //     date = newDate.format("DD/MM/YYYY");
            // }
            onSubChangeHandler(sub.qHeading, newDate);
        }

        // Check if the current input is invalid
        const hasError = value && !validateInput(value);

        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        fullWidth
                        sx={{ marginTop: "20px" }}
                        format="dd/MM/yyyy"
                        value={value ? new Date(value) : null}
                        maxDate={new Date()}
                        onChange={onDateChangeHandler}
                        disabled={readOnly}
                        label={`${sub["qNum"]}. ${sub["qHeading"]}`}
                        placeholder={sub["placeholder"]}
                        variant="outlined"
                        error={hasError}
                        helperText={hasError ? 'Enter valid date' : ''}
                        required={sub["required"] || false}
                        slotProps={{
                            textField: {
                                readOnly: true,
                                required: sub["required"] || false,
                                InputLabelProps: {
                                    shrink: true,
                                }
                            },
                        }}
                    />
                </LocalizationProvider>
            </>
        );
    } else {
        const { question, parentIndex, index, handleAnswer } = props;

        const onDateChangeHandler = (newDate) => {
            // let date;
            // if (!newDate || newDate.format("DD/MM/YYYY") === "Invalid Date") {
            //     date = "";
            // } else {
            //     date = newDate.format("DD/MM/YYYY");
            // }
            handleAnswer(newDate.toISOString(), parentIndex, index);
        }

        // Check if the current input is invalid
        const hasError = question?.qAnswer && !validateInput(question?.qAnswer);

        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        fullWidth
                        format="dd/MM/yyyy"
                        value={question.qAnswer ? new Date(question.qAnswer) : null}
                        maxDate={new Date()}
                        onChange={onDateChangeHandler}
                        disabled={readOnly}
                        label={`${question["qNum"]}. ${question["qHeading"]}`}
                        placeholder={question["placeholder"]}
                        variant="outlined"
                        error={hasError}
                        helperText={hasError ? 'Enter valid date' : ''}
                        required={question["required"] || false}
                        slotProps={{
                            textField: {
                                readOnly: true,
                                required: question["required"] || false,
                                InputLabelProps: {
                                    shrink: true,
                                }
                            },
                        }}
                    />
                </LocalizationProvider>
            </>
        );
    }


}

export default DateQuestion;