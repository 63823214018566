import styled from "styled-components";

export const ImgIcon = styled.img`
  position: relative;
  max-width: 170px;
  max-height: 357px;
  object-fit: cover;
  mix-blend-mode: normal;
`;
export const Title = styled.b`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
`;
export const DescriptionGroup = styled.div`
  font-style: normal;
  font-family: Rubik;
  font-weight: 400;
  font-size: 16px;
  text-align:left;
`;
export const Description = styled.div`
  text-align: left;
  white-space: pre-wrap;
  font-synthesis: none;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  text-decoration: none;
  line-height: 24px;
  text-transform: none;
  align-self: stretch;
  margin: 5px 0px 0px 0px;

  &::before {
    content: '•';
    padding-right: 8px;
  }
`;
export const Infoalignment = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const Buttonalignment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const UsertypecardsettingsRoot = styled.div`
  font-family: "Rubik", sans-serif;  
  position: relative;
  border-radius: 8px;
  max-height: 800px;
  background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-size: 24px;

  background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 15px 16px;
  
`;