import { useState, useContext, useEffect } from 'react'
import MuiButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ActionContainer, CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapper, StatsText, TextBlock } from '../Information.styled';
import { Doughnut } from 'react-chartjs-2';
import ThemeContext from '../../../../../store/theme-context';
import { Button } from '../../../../../components/UI/Button/Button.styled';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { PopupModal } from "react-calendly";
import InfoModal from '../../../components/InfoModal/InfoModal';
import ModalImageSrc from "../../../../../images/reccomendationImg.png";
import { paymentUrlInstructor, paymentUrlMonthly, paymentUrlYearly } from '../../../../../store/auth-context';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import SubscriptionDialog from '../../../../../components/Dialog/SubscriptionDialog';

function CisoZenSupportCard(props) {

    const { centerLabelTitle, remainingDays, data } = props;

    const { theme } = useContext(ThemeContext);
    const { keycloak } = useKeycloak();

    const [isOpen, setIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [email, setEmail] = useState(null);

    const { organisationDetail } = useSelector((state) => state.data);

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((res) => {
                setEmail(res.email);
            });
        }
    }, [keycloak, keycloak.authenticated])

    const helpHandler = () => {
        setIsOpen(true)
    };

    const handleCloseModal = () => setOpenModal(false);

    const graphData = data.some((d) => d?.value > 0) ? {
        labels: data.map((d) => d.label),
        datasets: [
            {
                data: data.map((d) => d.value),
                backgroundColor: data.map((d) => d.color), // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ],
    } : {
        labels: ["Time Left"],
        datasets: [
            {
                data: [100],
                backgroundColor: ["rgb(189, 189, 189)"], // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ]
    };

    const options = {
        responsive: true,
        cutout: '85%', // Inner radius of the Doughnut chart
        borderColor: theme?.palette?.mode === 'dark' ? 'rgb(31, 33, 40)' : '#fff',
        plugins: {
            datalabels: {
                display: false, // Hide data labels
            },
            centerLabelPlugin: {
                title: centerLabelTitle || '',
                titleColor: theme?.palette?.text?.primary
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const tooltipValue = data[dataIndex].tooltipValue;
                        return `${tooltipValue}`;
                    },
                },
            },
        },
    };

    return (
        <>
            <ProgressCard>
                <ProgressCardContent>
                    <ProgressCardContentTop>
                        <CardHead>CisoZen Support</CardHead>
                        <ProgressContainer>
                            <ProgressWrapper>
                                <Doughnut data={graphData} options={options} />
                            </ProgressWrapper>
                        </ProgressContainer>
                        <LegendContainer>
                            {
                                data.map((d, index) => (
                                    <TextBlock key={d.label + index}>
                                        <DotSign style={{ backgroundColor: d.color }} />
                                        <StatsText>{d.label}</StatsText>
                                    </TextBlock>
                                ))
                            }
                        </LegendContainer>
                    </ProgressCardContentTop>
                    <ActionContainer>
                        {
                            remainingDays === 0 ? <Button $success variant="filled" startIcon={<WorkspacePremiumIcon />} fullWidth onClick={() => setOpenModal(true)}>
                                Upgrade Subscription
                            </Button> : <MuiButton color='success' variant='outlined' fullWidth size='large' borderWidth={"2px"} onClick={() => helpHandler()}>
                                Book Now
                            </MuiButton>
                        }
                    </ActionContainer>
                </ProgressCardContent>
            </ProgressCard>
            <PopupModal pageSettings={{
                backgroundColor: 'fff',
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: '00a2ff',
                textColor: '4d5055'
            }}
                url="https://calendly.com/cisozen/support"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
            />
            <SubscriptionDialog
                email={email}
                open={openModal}
                toggle={handleCloseModal}
            />
        </>
    );
}
export default CisoZenSupportCard;   