import * as React from "react";
import {
    CustomCircularProgress,
    ProgressValue,
    ProgressWrapper,
} from "./CircularProgressWithLabel.styled";

const CircularProgressWithLabel = (props) => {
    const { value, children, color } = props;

    return (
        <ProgressWrapper sx={{ position: "relative", display: "inline-flex" }}>
            <CustomCircularProgress
                value={value}
                variant="determinate"
                size="150px"
                thickness={3}
                style={{ color: `${color && "red"}` }}
            />
            <ProgressValue>
                {children ? children : `${value.toFixed(0)}%`}
            </ProgressValue>
        </ProgressWrapper>
    );
};
export default CircularProgressWithLabel;