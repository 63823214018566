import { useState, useCallback } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { Button } from "../../../../../components/UI/Button/Button.styled";
import { validInput } from "../../../../../components/E8Question/SubQuestion/SubQuestion";
import { StyledHeading } from "../../../../../components/Question/QuestionHead/QuestionHeading/QuestionHeading.styled";

const RecommendCounterMeasureForm = (props) => {

    const {
        title,
        qKey,
        recommendedCounterMeasures,
        addNewRecommendCounterMeasure,
        toggleControlSelectionDialog,
        removeRecommendCounterMeasure,
        handleChangeCondition,
        handleChangeCounterMeasure,
        handleChangeCurrentStatus,
        handleChangeImplementationEvidence,
        errors,
        domainControlMapper
    } = props;

    const displayImplementationEvidenceField = (recommendedCounterMeasures || []).some((c) => c.status === "Completed");

    return (
        <>
            <Box>
                <StyledHeading style={{ marginBottom: "24px" }}>{title}</StyledHeading>
                <Grid container spacing={2}>
                    <TableContainer sx={{ paddingTop: "12px" }}>
                        <Table
                            sx={{
                                minWidth: 650,
                                '& td, & th': {
                                    border: 0
                                },
                                '& th': {
                                    padding: '4px 16px'
                                },
                                '& td': {
                                    padding: '8px 8px !important'
                                }
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: "112px" }}></TableCell>
                                    <TableCell sx={{ width: "150px" }}>And/Or</TableCell>
                                    <TableCell sx={{ minWidth: "250px" }}>{qKey === "counterMeasureMapping" ? "Counter Measure" : "Title"}</TableCell>
                                    <TableCell sx={{ minWidth: "250px" }}>Current Status</TableCell>
                                    <TableCell sx={{ minWidth: "250px" }}>ISMS Mapping</TableCell>
                                    {
                                        displayImplementationEvidenceField && <TableCell sx={{ minWidth: "250px" }}>
                                            Implementation Evidence
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    recommendedCounterMeasures.map((counterMeasure, index) => (
                                        <TableRow key={counterMeasure.id}>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="add"
                                                    id={"add-btn-" + counterMeasure.id}
                                                    onClick={(e) => addNewRecommendCounterMeasure(index, qKey)}
                                                    color="success"
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="delete"
                                                    id={"delete-btn-" + counterMeasure.id}
                                                    onClick={(e) => removeRecommendCounterMeasure(index, qKey)}
                                                    color="error"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    index !== 0 && <FormControl fullWidth>
                                                        <Select
                                                            id={"condition-select-" + counterMeasure.id}
                                                            value={counterMeasure.condition}
                                                            onChange={(e) => handleChangeCondition(e.target.value, index, qKey)}
                                                        >
                                                            <MenuItem value={"And"}>And</MenuItem>
                                                            <MenuItem value={"Or"}>Or</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id={"counter-measure-textfield-" + counterMeasure.id}
                                                        variant="outlined"
                                                        defaultValue={counterMeasure.title}
                                                        onChange={(e) => handleChangeCounterMeasure(e, index, qKey)}
                                                        inputProps={{ maxLength: 255 }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={!!(errors && errors[index]?.title)}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id={"current-status-select-" + counterMeasure.id}
                                                        value={counterMeasure.status}
                                                        onChange={(e) => handleChangeCurrentStatus(e.target.value, index, qKey)}
                                                    >
                                                        <MenuItem value={"Not started"}>Not started</MenuItem>
                                                        <MenuItem value={"In progress"}>In progress</MenuItem>
                                                        <MenuItem value={"Completed"}>Completed</MenuItem>
                                                        <MenuItem value={"Not Achievable"}>Not Achievable</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={1}
                                                        id={"isms-mapping-autocomplete-" + counterMeasure.id}
                                                        options={[]}
                                                        disableClearable
                                                        readOnly
                                                        value={(counterMeasure.ismsMapping !== "" ? [counterMeasure.ismsMapping] : []).map((control) => domainControlMapper[control] || control)}
                                                        getOptionLabel={(option) => option?.label || option} // Function to get the label for each option
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params} // Spread other TextField props if needed
                                                                value={""}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        )}
                                                        popupIcon={
                                                            <IconButton
                                                                size="small"
                                                                onClick={(e) => {
                                                                    toggleControlSelectionDialog(e, { type: "control", key: qKey, index: index, singleSelection: true, selectedControls: counterMeasure.ismsMapping })
                                                                }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        }
                                                        sx={{
                                                            '& .MuiAutocomplete-input': {
                                                                width: '0 !important',
                                                                minWidth: '0 !important',
                                                                maxWidth: '0 !important',
                                                            }
                                                        }}
                                                        ChipProps={{
                                                            onDelete: undefined
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            {
                                                displayImplementationEvidenceField && <TableCell>
                                                    {
                                                        counterMeasure.status === 'Completed' && <TextField
                                                            id={"counter-measure-implementation-evidence-textfield-" + counterMeasure.id}
                                                            variant="outlined"
                                                            defaultValue={counterMeasure.implementationEvidence}
                                                            onChange={(e) => handleChangeImplementationEvidence(e, index, qKey)}
                                                            inputProps={{ maxLength: 255 }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!(errors && errors[index]?.implementationEvidence)}
                                                        />
                                                    }
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid item xs={12}>
                        <Button $success onClick={() => addNewRecommendCounterMeasure(-1, qKey)} startIcon={<AddIcon />}>
                            Add new counter Measure
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default RecommendCounterMeasureForm;