import { FormControl, FormLabel, RadioGroup, Radio } from "@mui/material";
import { CustomFormControlLabel } from "../SubQuestion.styled";
import { useState } from "react";
import RadioQuestion from "./RadioQuestion";
import DateQuestion from "./DateQuestion";
import OptionQuestion from "./OptionQuestion";
import AppGuidance from "../components/AppGuidance";


const DependencyQuestion = (props) => {
    const { question, parentIndex, index, handleAnswer, readOnly } = props;

    const [isToggle, setIsToggle] = useState(question.qAnswer.main === "Yes");

    const onRadioChangeHandler = (e) => {
        const ans = e.target.value;
        if (ans === "Yes") {
            //prevent select yes again.
            if (question.qAnswer.main === "Yes") {
                return;
            }
            const dependency = {};
            question.dependencies.map(q => dependency[q.qHeading] = "");
            setIsToggle(true);
            handleAnswer({ main: ans, dependency: dependency }, parentIndex, index);
        } else {
            setIsToggle(false);
            handleAnswer({ main: ans, dependency: undefined }, parentIndex, index);
        }
    }

    function onSubChangeHandler(key, value) {
        const obj = question.qAnswer;
        obj.dependency[`${key}`] = value;
        handleAnswer(obj, parentIndex, index);
    }


    return (
        <>
            <FormControl >
                <FormLabel required={question.required}>
                    {/* {question.qNum + ". " + question.qHeading} <AppGuidance guidance={question.guidance} /> */}
                    {question.qNum + ". " + question.qHeading}
                </FormLabel>
                <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={question.qAnswer.main ?? ""} onChange={onRadioChangeHandler} disabled={readOnly}>
                    {
                        question.qAvailableAnswers.map((answer, indexAns) => {
                            return <CustomFormControlLabel key={indexAns} value={answer} control={<Radio style={{ color: '#3a8d1c' }} />} label={answer} />;
                        })
                    }
                </RadioGroup>
            </FormControl>
            {
                isToggle && (question.dependencies.map((q, index) => {
                    if (q.type === "radio") {
                        return <RadioQuestion
                            key={index}
                            isSub sub={q}
                            value={question.qAnswer.dependency[`${q.qHeading}`]}
                            onSubChangeHandler={onSubChangeHandler}
                            readOnly={readOnly}
                        />
                    } else if (q.type === "date") {
                        return <DateQuestion key={index} isSub sub={q} value={question.qAnswer.dependency[`${q.qHeading}`]} onSubChangeHandler={onSubChangeHandler} readOnly={readOnly} />
                    } else if (q.type === "option") {
                        return <OptionQuestion key={index} isSub sub={q} value={question.qAnswer.dependency[`${q.qHeading}`]} onSubChangeHandler={onSubChangeHandler} readOnly={readOnly} />
                    } else {
                        return ""
                    }
                }))
            }
        </>
    );
}

export default DependencyQuestion;