import { openDB } from 'idb';
import CryptoJS from 'crypto-js';

const dbName = 'cz-db';
const storeName = 'cached-data';

const dbPromise = openDB(dbName, 1, {
  upgrade(db) {
    db.createObjectStore(storeName);
  },
});

const encryptData = (sessionId, data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), sessionId).toString();
};

const decryptData = (sessionId, encryptedData) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, sessionId);
    return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
};

export const cacheData = async (sessionId, key, value) => {
  const encryptedValue = encryptData(sessionId, value);
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put(encryptedValue, `${key}`);
  await tx.done;
};

export const getCachedData = async (sessionId, key) => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const encryptedData = await store.get(`${key}`);
  if (encryptedData) {
    const decryptedData = decryptData(sessionId,encryptedData);
    return decryptedData;
  }
  return null;
};

export const cleanupSessionData = async () => {
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  store.clear();
  await tx.done;
};