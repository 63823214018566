import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MuiLoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/AddCircleOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import AssetDetailDialog from './AssetDetailDialog';
import Table from '../../../../../components/UI/Table/Table';
import { toggleAllowFetch } from '../../../../../utility/informationAssetRegister.action';
import { getNumberFromCaseID } from '../../../../../shared/utility';
import TruncateText from '../../../../../components/TruncateItems/TruncateText';

const AssetTable = ({ loading, fetching, data, handleEditClick, startNewMainCase, createNewCaseLoading, disableActions: disableActionProp, disableActionMeta }) => {

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();

    // ** Redux states
    const { fetchingBackgroundData } = useSelector((state) => state.informationAssetRegisters);

    const disableActions = loading || fetching || createNewCaseLoading || disableActionProp;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    useEffect(() => {
        if (history?.location?.state?.from === "search") {
            if (history?.location?.state?.caseData) {
                toggleDetailDialog(null, JSON.parse(history.location.state.caseData));
            }
        }
    }, [history?.location?.state])

    const columns = [
        // {
        //     id: "caseID",
        //     header: "Asset ID",
        //     width: "175px",
        //     sort: true,
        //     searchable: true,
        //     sortFn: (a, b) => (a.caseID || '').localeCompare(b.caseID || '')
        // },
        {
            id: "assetID",
            header: "Asset ID",
            width: "175px",
            sort: true,
            searchable: true,
            pinLeft: true,
            sortFn: (a, b) => {
                const orderA = getNumberFromCaseID(a.assetID || '');
                const orderB = getNumberFromCaseID(b.assetID || '');

                return orderA - orderB;
            }
        },
        {
            flex: 0.3,
            id: "assetName",
            header: "Asset Name",
            minWidth: "200px",
            sort: true,
            searchable: true,
            sortFn: (a, b) => (a.assetName || '').localeCompare(b.assetName || '')
        },
        {
            flex: 0.2,
            id: "assetCategory",
            header: "Asset Category",
            minWidth: "200px",
            sort: true,
            sortFn: (a, b) => (a.assetCategory || '').localeCompare(b.assetCategory || '')
        },
        {
            flex: 0.6,
            id: "assetdescription",
            header: "Description",
            minWidth: "250px",
            sort: true,
            sortFn: (a, b) => (a.assetdescription || '').localeCompare(b.assetdescription || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["assetdescription"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            id: "creationDate",
            header: "Original acquisition/ creation date",
            sort: true,
            defaultHidden: true,
            width: '280px',
            sortFn: (a, b) => {
                const dateA = new Date(a.creationDate);
                const dateB = new Date(b.creationDate);

                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                return dayjs(row.creationDate).format('DD/MM/YYYY')
            }
        },
        {
            id: "keywords",
            header: "Keywords",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.keywords || '').localeCompare(b.keywords || '')
        },
        {
            id: "useConstraints",
            header: "Use constraints",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.useConstraints || '').localeCompare(b.useConstraints || '')
        },
        {
            id: "informationExcahngeCapability",
            header: "Information exchange capability",
            width: "275px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.informationExcahngeCapability || '').localeCompare(b.informationExcahngeCapability || '')
        },
        {
            id: "creating",
            header: "Creating",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.creating || '').localeCompare(b.creating || '')
        },
        {
            id: "storing",
            header: "Storing",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.storing || '').localeCompare(b.storing || '')
        },
        {
            id: "consuming",
            header: "Consuming",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.consuming || '').localeCompare(b.consuming || '')
        },
        {
            id: "riskOwner",
            header: "Risk owner",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.riskOwner || '').localeCompare(b.riskOwner || '')
        },
        {
            id: "assetOwner",
            header: "Asset owner",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.assetOwner || '').localeCompare(b.assetOwner || '')
        },
        {
            id: "assetCustodian",
            header: "Asset custodian",
            width: "175px",
            sort: true,
            defaultHidden: true,
            sortFn: (a, b) => (a.assetCustodian || '').localeCompare(b.assetCustodian || '')
        },
        {
            id: "c",
            header: "Confidentiality Impact",
            width: "175px",
            sort: true,
            sortFn: (a, b) => {
                const getOrder = (c) => {

                    // Assign weights based on sequence
                    switch (c) {
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };

                const orderA = getOrder(a.c || '');
                const orderB = getOrder(b.c || '');

                return orderA - orderB;
            }
        },
        {
            id: "i",
            header: "Integrity Impact",
            width: "175px",
            sort: true,
            sortFn: (a, b) => {
                const getOrder = (c) => {

                    // Assign weights based on sequence
                    switch (c) {
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };

                const orderA = getOrder(a.i || '');
                const orderB = getOrder(b.i || '');

                return orderA - orderB;
            }
        },
        {
            id: "a",
            header: "Availability Impact",
            width: "175px",
            sort: true,
            sortFn: (a, b) => {
                const getOrder = (c) => {

                    // Assign weights based on sequence
                    switch (c) {
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };

                const orderA = getOrder(a.a || '');
                const orderB = getOrder(b.a || '');

                return orderA - orderB;
            }
        },
        {
            id: "businessCriticality",
            header: "Business Criticality",
            width: "175px",
            sort: true,
            sortFn: (a, b) => {
                const getOrder = (c) => {

                    // Assign weights based on sequence
                    switch (c) {
                        case 'High':
                            return 3;
                        case 'Medium':
                            return 2;
                        case 'Low':
                            return 1;
                        default:
                            return 0;
                    }
                };

                const orderA = getOrder(a.businessCriticality || '');
                const orderB = getOrder(b.businessCriticality || '');

                return orderA - orderB;
            }
        },
        {
            id: "informationProtectiveMarkings",
            header: "Information Protective Markings",
            width: "275px",
            sort: true,
            sortFn: (a, b) => (a.informationProtectiveMarkings || '').localeCompare(b.informationProtectiveMarkings || '')
        },
        {
            id: "actions",
            header: "",
            disableColumnFilter: true,
            pinRight: true,
            minWidth: '100px',
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        onClick={(e) => handleEditClick(row)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                skeletonLoader
                loading={loading}
                tableData={data}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => { startNewMainCase() }}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <AddIcon />
                            </MuiLoadingButton>
                            <MuiLoadingButton
                                as={IconButton}
                                size="small"
                                onClick={() => {
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={fetching || loading || fetchingBackgroundData}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                            {/* <IconButton sx={{ padding: 0 }} size="small" onClick={() => startNewMainCase()}>
                                <AddIcon style={{ color: isDarkTheme ? "#fff" : "#000" }} />
                            </IconButton>
                            <IconButton sx={{ padding: 0 }} size="small" onClick={() => getAssets()}>
                                <SyncIcon style={{ color: isDarkTheme ? "#fff" : "#000" }} />
                            </IconButton> */}
                        </Box>
                    </>
                }
            />

            <AssetDetailDialog
                open={dialogOpen}
                toggle={toggleDetailDialog}
                data={dialogData}
                handleEditClick={handleEditClick}
            />
        </>
    );
};

export default AssetTable;