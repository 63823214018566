import { getCase, startNewCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { CONTAINER_ID, SOAProcessID } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_SOA_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'TOGGLE_SOA_LOADING', payload: loading };
};

export const initiateNewCase = (keycloak) => {
  return async (dispatch) => {
    let obj = {
      "case-group-assignments": {
        "admin": "cz-sa-riskassessment",
        "owner": CONTAINER_ID.replaceAll("_0.1", "")
      }
    }

    const newCaseResponse = await startNewCase(CONTAINER_ID, keycloak.token, SOAProcessID, obj);
    if (newCaseResponse.success) {
      dispatch(fetchData(keycloak, 0));
    } else {
      dispatch({ type: 'FETCH_SOA_DATA_ERROR', payload: newCaseResponse.error?.message || "Failed to create new case" });
    }
  }
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().soa; // Get the allowFetch value from the state
    const { domainControlMapper = {} } = getState().miscellaneous;

    // Enable loading
    dispatch(toggleLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-soa');
      const cachedCaseID = await getCachedData(keycloak.token, 'cached-soa-caseID');
      const cachedCaseData = await getCachedData(keycloak.token, 'cached-soa-caseData');

      if (cachedData && allowFetch === false) {
        dispatch({ type: 'FETCH_SOA_DATA_SUCCESS', payload: { data: cachedData, caseID: cachedCaseID, caseData: cachedCaseData } });
      } else {

        let soas = [];
        let caseID = null;
        let caseData = null;

        const openCases = await getCase(CONTAINER_ID, keycloak.token, SOAProcessID, 'open', 1, pageParam);
        const openedData = openCases.success ? openCases.data?.instances : [];

        let soaData = [...openedData];

        // If not open case then check closed case
        if (!(soaData?.length > 0)) {
          const closedCases = await getCase(CONTAINER_ID, keycloak.token, SOAProcessID, 'closed', 1, pageParam);
          const closedData = closedCases.success ? closedCases.data?.instances : [];

          soaData = [...closedData];
        }

        // Check if not case found start new case
        if (!(soaData?.length > 0)) {
          dispatch(initiateNewCase(keycloak));
          return;
        }

        if (soaData?.length > 0) {
          soaData.forEach((s, index) => {
            caseData = {}
            caseData["date"] = s["case-started-at"] || 0;
            caseData["status"] = s["case-status"] || 0;
            let cDate = new Date(s["case-started-at"]);

            caseData["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            caseData.caseID = s["case-id"];
            caseID = s["case-id"];

            let soaList = s['case-file']['case-data']['soaList'] ?? {};

            soas = Object.keys(soaList).map((key) => {
              let control = domainControlMapper[key];
              return {
                ...soaList[key],
                applicability: soaList[key]["applicability"] || "No",
                reason: soaList[key]['threatList']?.map((threatID) => beautifyUniqueID(threatID)).join(', ') ?? '',
                securityControlClauses: control ? `A.${control.domainID}: ${control.domainHeading}` : '',
                securityCategories: control ? `A.${control.sectionID}: ${control.sectionHeading}` : '',
                securityControl: control ? `A.${control.controlID}: ${control.controlHeading}` : '',
                score: control?.score || 0,
                key: key,
                caseID: key,
              }
            })

            const extractNumbers = (str) => {
              return str.split(':')[0].split('.').slice(1).map(Number);
            };

            soas = soas.sort((a, b) => {
              const numA = extractNumbers(a.securityControl || '');
              const numB = extractNumbers(b.securityControl || '');

              for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
                if (numA[i] !== numB[i]) {
                  return (numA[i] || 0) - (numB[i] || 0);
                }
              }
              return 0;
            })

          })
        }

        dispatch({ type: 'FETCH_SOA_DATA_SUCCESS', payload: { data: soas, caseID: caseID, caseData: caseData }, });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-soa', soas);
        cacheData(keycloak.token, 'cached-soa-caseID', caseID);
        cacheData(keycloak.token, 'cached-soa-caseData', caseData);
        dispatch(toggleAllowFetch(false));

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_SOA_DATA_ERROR', payload: error.message });
    }
  };
};