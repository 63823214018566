import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ZoomOut, ZoomIn, RestartAlt } from '@mui/icons-material';
import { Button } from '../../../../../components/UI/Button/Button.styled';
import { Divider } from '@mui/material';

const ImageZoomInOut = ({ image, imageName }) => {
    return (
        <div>
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div>
                            <Button onClick={() => zoomIn()}><ZoomIn /></Button>
                            <Button onClick={() => zoomOut()}><ZoomOut /></Button>
                            <Button onClick={() => resetTransform()}><RestartAlt /></Button>
                        </div>
                        <Divider />
                        <TransformComponent>
                            <img src={image} alt={imageName} width={"100%"} />
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </div>
    )
}

export default ImageZoomInOut