import { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom"

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

import { styled, useTheme } from '@mui/material/styles'

import SearchIcon from "@mui/icons-material/Search";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

import { useDebounce } from "../../shared/hooks";
import { useSelector } from "react-redux";

const categoryTitle = {
  threat: 'Threats',
  riskAssessment: 'Risks',
  informationAsset: 'Information Assets',
}

const NoResult = ({ value }) => {

  if (!(value?.length > 0)) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word' }}>
        No results for{' '}
        <Typography variant='h6' component='span' sx={{ wordWrap: 'break-word' }}>
          {`"${value}"`}
        </Typography>
      </Typography>
    </Box>
  )
}

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& fieldset': {
    border: 0
  },
  '& + .MuiAutocomplete-popper': {
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
      height: '100%',
      maxHeight: 'inherit',
      '& .MuiListSubheader-root': {
        top: 0,
        fontWeight: 400,
        lineHeight: '15px',
        fontSize: '0.75rem',
        letterSpacing: '1px',
        color: theme.palette.text.disabled,
        padding: theme.spacing(1.25, 6, 1)
      }
    },
    '& .MuiAutocomplete-paper': {
      border: 0,
      height: '100%',
      borderRadius: 0,
      boxShadow: 'none'
    },
    '& .MuiListItem-root.suggestion': {
      padding: 0,
      '& .MuiListItemSecondaryAction-root': {
        display: 'flex'
      },
      '&.Mui-focused.Mui-focusVisible, &:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '& .MuiListItemButton-root: hover': {
        backgroundColor: 'transparent'
      },
      '&:not(:hover)': {
        '& .MuiListItemSecondaryAction-root': {
          display: 'none'
        },
        '&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)': {
          '& .MuiListItemSecondaryAction-root': {
            display: 'flex'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root': {
            display: 'none'
          }
        }
      }
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'grid',
      minHeight: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(10)
    }
  }
}))

const SearchComponent = () => {

  const theme = useTheme();

  // ** Hooks
  let history = useHistory();
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null);

  // ** Redux States
  const { data: threats, fetchingBackgroundData: fetchingBackgroundThreat, loading: loadingThreat } = useSelector((state) => state.threats);
  const { data: riskAssessments, fetchingBackgroundData: fetchingBackgroundRisk, loading: loadingRisk } = useSelector((state) => state.data);
  const { data: informationAssetRegisters, fetchingBackgroundData: fetchingBackgroundInformationAsset, loading: loadingInformationAsset } = useSelector((state) => state.informationAssetRegisters);

  // ** States
  const [isMounted, setIsMounted] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  let showLoader = loading || fetchingBackgroundRisk || loadingRisk || fetchingBackgroundThreat || loadingThreat || fetchingBackgroundInformationAsset || loadingInformationAsset

  useEffect(() => {
    if (isMounted) {
      if (history?.location?.state?.searchValue) {
        setSearchValue(history.location.state.searchValue);
        if (inputRef.current) {
          // autoCompleteRef.current.focus();
          inputRef.current.focus();
          // inputRef.current.value = history.location.state.searchValue;
        }
      }
    }
  }, [isMounted])


  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, [])

  const debouncedSearch = useDebounce((value) => {
    if (value?.trim()?.length > 0) {
      let searchTextRegExp = new RegExp(value, 'i');

      let hasMore = false;
      let newOptions = [];

      // Threats
      if (threats?.length > 0) {
        let filteredData = threats.filter((t) => {
          return (t.threatID?.length > 0 && searchTextRegExp.test(t.threatID))
            || (t.title?.length > 0 && searchTextRegExp.test(t.title))
            || (t.description?.length > 0 && searchTextRegExp.test(t.description))
        })

        newOptions = newOptions.concat(
          filteredData.map((t) => ({
            id: t.caseID,
            uniqueID: t.threatID,
            title: t.title,
            description: t.description,
            url: "/it-security/threats",
            category: "threat",
            caseData: t,
          }))
        );
      }

      // Risk Assessment
      if (riskAssessments?.length > 0) {
        let filteredData = riskAssessments.filter((t) => {
          return (t.caseID?.length > 0 && searchTextRegExp.test(t.caseID))
            || (t.riskTitle?.length > 0 && searchTextRegExp.test(t.riskTitle))
            || (t.riskDescription?.length > 0 && searchTextRegExp.test(t.riskDescription))
        })

        newOptions = newOptions.concat(
          filteredData.map((t) => ({
            id: t.caseID,
            uniqueID: t.caseID,
            title: t.riskTitle,
            description: t.riskDescription,
            url: "/it-security/risk-assessment",
            category: "riskAssessment",
            caseData: t,
          }))
        );
      }

      // Information Asset
      if (informationAssetRegisters?.length > 0) {
        let filteredData = informationAssetRegisters.filter((t) => {
          return (t.caseID?.length > 0 && searchTextRegExp.test(t.caseID))
            || (t.assetName?.length > 0 && searchTextRegExp.test(t.assetName))
            || (t.assetdescription?.length > 0 && searchTextRegExp.test(t.assetdescription))
        })

        newOptions = newOptions.concat(
          filteredData.map((t) => ({
            id: t.caseID,
            uniqueID: t.assetID,
            title: t.assetName,
            description: t.assetdescription,
            url: "/it-security/information-asset-register",
            category: "informationAsset",
            caseData: t,
          }))
        );
      }

      if (hasMore) {
        newOptions.push({
          id: 'see-more',
          uniqueID: "",
          title: "see more",
          description: "",
          url: "/search",
          category: '',
        })
      }

      setOptions([...newOptions]);
    } else {
      setOptions([])
    }
    setLoading(false);
  }, 500)

  // Populate options with search input value
  useEffect(() => {
    setLoading(true);
    debouncedSearch(searchValue);
  }, [searchValue])

  // Handle click event on a list item in search result
  const handleOptionClick = useCallback((obj) => {
    setSearchValue('')
    if (obj.url) {
      history.push({
        pathname: obj.url,
        state: {
          from: 'search',
          searchValue: searchValue,
          caseData: obj?.caseData ? JSON.stringify(obj.caseData) : null,
        }
      });
    }
  }, [history, searchValue]);

  // Handle the input change event
  const handleInputChange = (event) => {
    const inputValue = event.target.value.trimStart();
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (regex.test(inputValue)) {
      setSearchValue(inputValue);
    }
  };

  if (!isMounted) return null;

  return <>
    <Box sx={{ top: 0, width: '100%', position: 'sticky' }}>
      <Autocomplete
        autoFocus
        autoHighlight
        disablePortal
        clearOnBlur={false}
        clearOnEscape={false}
        ref={autoCompleteRef}
        open={searchValue?.length > 0}
        loading={showLoader}
        options={options}
        inputValue={searchValue}
        id='appBar-search'
        isOptionEqualToValue={() => true}
        filterOptions={(options) => options}
        // onInputChange={(event, value) => setSearchValue(value)}
        onChange={(event, obj) => handleOptionClick(obj)}
        noOptionsText={<NoResult value={searchValue} />}
        getOptionLabel={option => option.title}
        groupBy={option => (searchValue.length ? ((option.category && categoryTitle[option.category]) || '') : '')}
        sx={{
          '& + .MuiAutocomplete-popper': {
            ...(searchValue.length && {
              overflow: 'auto',
              maxHeight: '60vh',
              // height: '60vh'
            })
          }
        }}
        getOptionKey={option => option.id}
        renderInput={params => {
          return (
            <TextField
              {...params}
              autoFocus
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Search for Information assets, Threats, and Risks"
              // inputRef={input => {
              //     if (input) {
              //         input.focus()
              //     }
              // }}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                sx: {
                  // p: `${theme.spacing(3.75, 6)} !important`,
                  p: `1rem !important`,
                },
                startAdornment: (
                  <InputAdornment position='start' sx={{ color: 'text.primary' }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                  >
                    {showLoader ? <CircularProgress color="inherit" size={20} /> : null}
                  </InputAdornment>
                )
              }}
            />
          )
        }}
        renderOption={(props, option) => {
          return searchValue.length ? (
            <ListItem
              {...props}
              key={option.title}
              className={`suggestion ${props.className}`}
              onClick={() => handleOptionClick(option)}
              secondaryAction={
                <SubdirectoryArrowLeftIcon fontSize='small' sx={{ cursor: 'pointer', color: 'text.disabled' }} />
              }
            >
              <ListItemText
                sx={{
                  py: 0.25,
                  px: ` ${theme.spacing(6)} !important`,
                }}
                primary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="inherit"
                      color="text.secondary"
                    >
                      {option.uniqueID}
                    </Typography>
                    {" "}{option.title}
                  </>
                }
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: '0.85rem'
                }}
                secondary={option.description || ''}
                secondaryTypographyProps={{
                  noWrap: true,
                  fontSize: '0.75rem'
                }}
              />
            </ListItem>
          ) : null
        }}
      />
    </Box>
    {/* {searchValue.length === 0 ? (
            <Box
                sx={{
                    p: 10,
                    display: 'grid',
                    overflow: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTop: `1px solid ${theme.palette.divider}`,
                    height: '60vh',
                    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1e1e1e'
                }}
            >
                Search for Information assets, Threats, and Risks
            </Box>
        ) : null} */}
  </>

}

export default SearchComponent;