import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { validateCompany } from '../validation';

const GUID = process.env.REACT_APP_ABN_LOOKUP_GUID;
const MAX_RESULTS = 5;

const CompanyABN = (props) => {
    const { question, parentIndex, index, handleAnswer, readOnly } = props;

    const [abnOptions, setAbnOptions] = useState([]);
    const [inputValue, setInputValue] = useState(question.qAnswer.company);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [abn, setAbn] = useState(question.qAnswer.abn);
    let fetchData = useRef(false);
    const [loading, setLoading] = useState(false);
    const [debouncedInputValue, setDebouncedInputValue] = useState('');

    useEffect(() => {
        //prevent trigger when the component mounts initially.
        if (fetchData.current) {
            if (validateCompany(debouncedInputValue)) {
                const controller = new AbortController();
                (async () => {
                    setLoading(true);
                    try {
                        const url = `https://abr.business.gov.au/json/MatchingNames.aspx?name=${debouncedInputValue}&maxResults=${MAX_RESULTS}&guid=${GUID}`;
                        const response = await fetch(url, { signal: controller.signal }).then((response) => response.text());
                        const data = response;
                        const jsonString = data.replace(/^callback\((.*)\)$/, '$1');
                        const jsonObject = JSON.parse(jsonString);
                        const options = jsonObject.Names.map((company) => ({ company: company.Name, abn: company.Abn }));
                        setAbnOptions(options);
                    } catch (error) {
                        console.log(error);
                        setAbnOptions([]);
                    } finally {
                        setLoading(false);
                    }
                })();
                return () => controller.abort();
            } else {
                setAbnOptions([]);
            }
        } else {
            fetchData.current = true;
        }
    }, [debouncedInputValue]);

    //debounce user input
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(inputValue);
        }, 600);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [inputValue]);


    const onChangeHandler = (event, newValue) => {
        if (newValue) {
            handleAnswer(newValue, parentIndex, index);
            setAbn(newValue.abn);
        } else {
            handleAnswer({ company: "", abn: "" }, parentIndex, index);
            setAbn("");
        }
    }

    return (
        <>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.company === value.company && option.abn === value.abn}
                value={question.qAnswer.company ? question.qAnswer : null}
                options={abnOptions}
                loading={loading}
                getOptionLabel={option => option.company}
                disablePortal
                noOptionsText="No Results"
                filterOptions={(x) => x}
                includeInputInList
                filterSelectedOptions
                disabled={readOnly}
                onInputChange={(event, newInputValue) => {
                    setAbnOptions([]);
                    if (validateCompany(newInputValue)) {
                        setIsError(false);
                        setErrorMessage("");
                    } else {
                        setIsError(true);
                        setErrorMessage("Invalid company name");
                    }
                    setInputValue(newInputValue);
                }}
                onChange={onChangeHandler}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={isError}
                        helperText={errorMessage}
                        label={question.qHeading}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required={!!question?.required}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.company + option.abn}>
                        {option.company} <br />
                        {option.abn}
                    </li>
                )}
            />

            <TextField
                style={{ marginTop: "30px" }}
                label="ABN (Australian Business Number)"
                variant="outlined"
                disabled={true}
                value={abn}
                inputProps={{ maxLength: 255 }}
            />
        </>
    );
};

export default CompanyABN;