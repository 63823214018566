import { Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import Navbar from "../../components/LandingPage/Navbar.js";
import RiskGraphPage from "./Risk&Mitigation/Risk&Mitigation.js";
import Footer from "../../components/Footer/Footer.js";

import PrivacyPolicy from "../../../Public/PrivacyPolicy/PrivacyPolicy";
import React from "react";
import InfoModalWithItem from "../../../Dashboard/components/InfoModal/InfoModalWithItem.js";
import { Helmet } from 'react-helmet';
import SubscriptionPlans from "./components/SubscriptionPlans.js";
import TermsConditions from "../../../Public/TermsConditions/TermsConditions.js";

const HomelogoIcon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 20px;
  left: -20px;
  width: 298.67px;
  height: 70px;
  object-fit: cover;
  z-index: 0;
`;
const Home = styled.nav`
  margin: 0;
  align-self: stretch;
  height: 120px;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;
const WorldOfChaos = styled.b`
  align-self: stretch;
  position: relative;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    font-weight: 700;
    font-family: Rubik;
    font-size: 70px;
    text-align: left;
    align-self: stretch;
    width: auto;
  }
`;
const TitleAndSignup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 960px) {
    gap: 50px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    margin-top: -89px;
  }
`;
const Vector1Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 166px;
  left: 169.13px;
  width: 100px;
  height: 100px;
  z-index: 0;
`;
const Vector2Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 188px;
  left: 491.13px;
  width: 80px;
  height: 80px;
  z-index: 1;
`;
const Vector3Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 335px;
  left: 289.13px;
  width: 100px;
  height: 100px;
  z-index: 2;
`;
const Vector4Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 451px;
  left: 118.13px;
  width: 80px;
  height: 80px;
  z-index: 3;
`;
const Vector5Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 440px;
  left: 420.13px;
  width: 80px;
  height: 80px;
  z-index: 4;
`;
const Vector6Icon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 583px;
  left: 232.13px;
  width: 100px;
  height: 100px;
  z-index: 5;
`;
const VectorIcon = styled.img`
  position: absolute;
  margin: 0 !important;
  top: -580px;
  left: -632px;
  width: 1963.86px;
  height: 1930.2px;
  z-index: -1;
`;
const FinancialImpact = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 107px;
  left: 52px;
  display: inline-block;
  width: 253px;
  height: 37px;
  flex-shrink: 0;
  z-index: 7;
`;
const Spyware = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 136.59px;
  left: 531.13px;
  display: inline-block;
  width: 134px;
  height: 40px;
  flex-shrink: 0;
  z-index: 8;
`;
const BrandDamage = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 293.59px;
  left: 118.13px;
  display: inline-block;
  width: 232px;
  height: 35px;
  flex-shrink: 0;
  z-index: 9;
`;
const DataBreach = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 400px;
  left: 50px;
  display: inline-block;
  width: 232px;
  height: 35px;
  flex-shrink: 0;
  z-index: 10;
`;
const Ransomware = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 395px;
  left: 460.13px;
  display: inline-block;
  width: 232px;
  height: 35px;
  flex-shrink: 0;
  z-index: 11;
`;
const Phishing = styled.b`
  position: absolute;
  margin: 0 !important;
  top: 539px;
  left: 282.13px;
  display: inline-block;
  width: 232px;
  height: 35px;
  flex-shrink: 0;
  z-index: 12;
`;
const Image1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  text-align: left;
  font-size: 32px;
  font-family: Roboto;
`;
const Worldofchaos = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 70px;
  color: #ff0000;
  @media screen and (max-width: 960px) {
    margin-top:0;
    align-self: stretch;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const ArrowIcon = styled.img`
  position: relative;
  width: 68.3px;
  height: 23.08px;
  margin-top:50px;
  @media screen and (max-width: 420px) {
    margin-top: 30px;
  }
`;
const VectorIcon1 = styled.img`
  position: absolute;
  margin: 0 !important;
  top: -702px;
  left: -521px;
  width: 1805.57px;
  height: 1821.75px;
  z-index: -1;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const Title = styled.b`
  position: relative;
  display: inline-block;
  width: 400px;
`;
const Text1 = styled.b`
  position: relative;
  font-size: 24px;
  display: inline-block;
  color: #e5e5e5;
  width: 486px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const OurVision = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;
  padding-top:50px;
  padding-bottom:50px;
`;
const Text11 = styled.b`
  position: relative;
  font-size: 24px;
  display: inline-block;
  color: #e5e5e5;
  width: 486px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const VisionAndPlan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 82px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
`;
const Zenview1 = styled.iframe`
  position: relative;
  width: 750.15px;
  height: 411px;
  background-image: url("/public/zenview1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 2;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`;
const Vision = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 50px;
  font-size: 40px;
  color: #0d8f01;
  @media screen and (max-width: 420px) {
    gap: 70px;
    align-items: center;
    justify-content: flex-start;
  }
`;
const VectorIcon2 = styled.img`
  position: relative;
  width: 80%;
  max-width: 400px;
  z-index: 0;
  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;
const VectorIcon3 = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 5.54px;
  left: -4px;
  width: 501px;
  height: 329.54px;
  opacity: 0.3;
  z-index: 1;
`;
const ButtonDarkGreen = styled(Button)`
  z-index: 2;
`;
const Czleftgroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 40px;
`;
const Title2 = styled.b`
  align-self: stretch;
  position: relative;
  @media screen and (max-width: 420px) {
    font-weight: 700;
    font-family: Rubik;
  }
`;
const Securemation1 = styled.span`
  text-decoration: underline;
`;
const Securemation = styled.a`
  color: inherit;
`;
const WeBelieveEveryOrganisation = styled.p`
  margin-block-start: 0;
  margin-block-end: 30px;
`;
const PenetrationTesting = styled.li`
  margin-bottom: 0px;
`;
const PenetrationTestingVulnerabil = styled.ul`
  margin: 0;
  padding-left: 8px;
  list-style:disc;
`;
const SomeOfThese = styled.p`
  
`;
const Text2 = styled.b`
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-align: left;
`;
const Czrightgroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  @media screen and (max-width: 420px) {
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
  }
`;
const Czfeatures = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Title3 = styled.b`
  align-self: stretch;
  position: relative;
`;
const Text3 = styled.b`
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 30px;
  font-family: inherit;
  color: inherit;
  text-align: left;
`;
const Hcleftgroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-right:20px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    padding-right:0px;
  }
`;
const HcimageIcon = styled.img`
  margin: 0 !important;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  @media screen and (max-width: 960px) {
    position: static;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    object-fit: cover;
  }
`;
const Hcrightgroup = styled.div`
  width: 400px;
  height: 411px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`;
const Rapidhc = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const RecomimageIcon = styled.img`
  margin: 0 !important;
  object-fit: cover;
  z-index: 0;
  width:100%;
  @media screen and (max-width: 960px) {
    position: static;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    object-fit: cover;
  }
`;
const Recomleftgroup = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const Text4 = styled.b`
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 34px;
  font-family: inherit;
  text-align: left;
`;
const Recomrightgroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 45px;
  margin-left:50px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
    margin-left:0px;
  }
`;
const Recommendations = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding-top:50px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    gap: 0px;
  }
`;
const Text5 = styled.b`
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
`;
const Rmleftgroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 46px;
  padding-right:20px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
    padding-right:0;
  }
`;
const RmimageIcon = styled.img`
  margin: 0 !important;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  @media screen and (max-width: 960px) {
    position: static;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    object-fit: cover;
  }
`;
const Rmrightgroup = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const RiskManagement = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const VectorIcon4 = styled.img`
  position: absolute;
  margin: 0 !important;
  top: -550px;
  left: -100px;
  width: 1800px;
  height: 1820px;
  z-index: -1;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const ImageIcon = styled.img`
  margin: 0 !important;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  @media screen and (max-width: 960px) {
    position: static;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    object-fit: cover;
  }
`;
const Gamileftgroup = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`;
const CisozenAdoptsA = styled.li`
  margin-bottom: 30px;
`;
const Gamirightgroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Gamitheme = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0px 10px 100px;
  align-items: center;
  justify-content: center;
  gap: 130px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const SelectYourPlan = styled.b`
  position: relative;
`;
const Title7 = styled.div`
  width: 1057px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #fff;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
  }
`;
const Plans1 = styled.div`
  position: absolute;
  top: 0px;
  left: 19.5px;
  font-weight: 500;
`;
const Advanced2Icon = styled.img`
  position: absolute;
  top: 29px;
  left: 0px;
  width: 210px;
  height: 247px;
  object-fit: cover;
`;
const Plans = styled.div`
  position: absolute;
  top: 28px;
  left: 8.5px;
  width: 210px;
  height: 276px;
`;
const PlansWrapper = styled.div`
  position: relative;
  width: 241px;
  height: 330px;
  font-size: 24px;
  color: #fff;
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
const CisozenFeatures = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 24px;
  @media screen and (max-width: 960px) {
    color: #fff;
  }
  @media screen and (max-width: 420px) {
    color: #fff;
  }
`;
const RapidHealthCheck = styled.li`
  margin-bottom: 6px;
`;
const RapidHealthCheckRhcRhcR = styled.ul`
  margin: 0;
  padding-left: 15px;
`;
const RapidHealthCheckContainer = styled.div`
  position: relative;
  align-items: center;
  height: 149px;
  @media screen and (max-width: 960px) {
    color: #fff7f7;
  }
  @media screen and (max-width: 420px) {
    color: #fff;
  }
`;
const CisozenFeaturesParent = styled.div`
  width: 232px;
  height: 212px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const Features = styled.div`
margin-top:50px;
  height: 330px;
  display: none;
  flex-direction: row;
  padding: 33px 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #121212;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
const FreeChild = styled.div`
  position: absolute;
  top: 13px;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
  background-color: #1f2128;
  border: 1px solid #1f2128;
  box-sizing: border-box;
  width: 272px;
  height: 330px;
`;
const Free1 = styled.div`
  position: absolute;
  top: 45px;
  left: 33.5px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
`;
const AllTheSelfService = styled.div`
  position: absolute;
  top: 84px;
  left: 34.5px;
  font-size: 16px;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  width: 202px;
  height: 50px;
  @media screen and (max-width: 420px) {
    color: #fff;
  }
`;
const Tick13Icon = styled.img`
  position: relative;
  width: 32.5px;
  height: 20px;
  object-fit: cover;
`;
const CisozenFeatures1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 130px;
  height: 50px;
  flex-shrink: 0;
  font-size:12px;
  @media screen and (max-width: 960px) {
    color: #fff;
  }
`;
const Tick13Parent = styled.div`
  position: absolute;
  top: 170px;
  left: 18px;
  width: 235px;
  height: 38px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  color: #4a4949;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    height: 60px;
    padding-left: 10px;
    padding-top: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;
const B = styled.b`
  position: relative;
  display: flex;
  align-items: center;
  width: 31px;
  height: 30px;
  flex-shrink: 0;
`;
const FreeForever = styled.div`
  position: relative;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 196px;
  height: 30px;
  flex-shrink: 0;
`;
const Parent1 = styled.div`
  position: absolute;
  top: 147px;
  left: 33.5px;
  width: 196px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
`;
const GetStartedButton = styled(Button)`
  position: absolute;
  top: 276px;
  left: 18px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50px;
  background-color: #151515;
  border: 1px solid #46a11b;
  box-sizing: border-box;
  width: 103px;
  height: 29px;
`;
const MostUsed = styled.div`
  position: absolute;
  top: 2px;
  left: 14px;
`;
const RectangleParent = styled.div`
  position: absolute;
  top: 0px;
  left: 47px;
  width: 103px;
  height: 29px;
  text-align: center;
`;
const Free = styled.div`
  position: relative;
  width: 272px;
  height: 343px;
  color: #46a11b;
`;
const Paid = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  @media screen and (max-width: 960px) {
    color: #fff;
  }
`;
const SpecializedCyberSecurity = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 202px;
  height: 50px;
  flex-shrink: 0;
`;
const Span = styled.span`
  color: #fff;
`;
const DiscountBasedOn = styled.span`
  font-size: 12px;
  color: #fff;
`;
const Mo66Container1 = styled.span`
  width: 100%;
  font-size: 12px;
`;
const Mo66Container = styled.div`
  position: relative;
  align-items: center;
  width: 100%;
  height: 59px;
  flex-shrink: 0;
  font-size: 16px;
  color: #fff;
`;
const OneYearOfContainer = styled.div`
  position: relative;
  align-items: center;
  height: 31px;
  flex-shrink: 0;
`;
const CisozenFeatures2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 130px;
  height: 50px;
  flex-shrink: 0;
  font-size:12px;
`;
const Tick13Group = styled.div`
  width: 235px;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    height: 60px;
    padding-left: 10px;
    padding-top: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;
const OfflineSecurityServices = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 188px;
  height: 50px;
  flex-shrink: 0;
  font-size:12px;
`;
const Tick13Container = styled.div`
  width: 235px;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    height: 60px;
    padding-left: 10px;
    padding-top: 0px;
    box-sizing: border-box;
    margin-top: -10px;
  }
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;
const SelectYourPlan1 = styled(Button)`
  position: relative;
`;
const PaidParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: 23px 15px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    padding-left: 15px;
    box-sizing: border-box;
  }
`;
const Paid1 = styled.div`
  position: absolute;
  top: 28px;
  left: 38px;
  font-weight: 500;
`;
const SpecializedCyberSecurity1 = styled.div`
  position: absolute;
  top: 67px;
  left: 38.5px;
  font-size: 16px;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  width: 206px;
  height: 50px;
`;
const Span1 = styled.span`
  font-size: 16px;
  color: #fff;
`;
const DiscountBasedOn1 = styled.span`
  font-size: 16px;
  color: #fff;
`;
const Mo66Container2 = styled.div`
  position: absolute;
  top: 127px;
  left: 38.5px;
  display: flex;
  align-items: center;
  width: 206px;
  height: 59px;
`;
const GetStartedButton2 = styled(Button)`
  position: absolute;
  top: 263px;
  left: 22px;
`;
const OneYearOfContainer2 = styled.div`
  position: absolute;
  top: 197px;
  left: 37.5px;
  font-size: 16px;
  color: #e8e8e8;
  align-items: center;
  width: 100%;
  height: 31px;
  @media screen and (max-width: 960px) {
    color: #fff;
  }
  @media screen and (max-width: 420px) {
    background: linear-gradient(#fff, #fff), #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
const PaidGroup = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 272px;
  height: 330px;
  font-size: 24px;
  color: #fff;
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
const PaidMonthly = styled.div`
  position: relative;
  width: 272px;
  height: 330px;
`;
const Paid2 = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`;
const PriceForWholeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 59px;
  flex-shrink: 0;
  font-size: 20px;
  color: #fff;
`;
const ThisTrainingDoes = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 31px;
  flex-shrink: 0;
  font-size:12px;
`;
const PaidContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 261px;
  height: 325px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: 23px 13px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 960px) {
    display: flex;
    margin-top: 50px;
  }
  @media screen and (max-width: 420px) {
    display: flex;
    padding-top: 23px;
    padding-bottom: 23px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;
const Paid3 = styled.div`
  position: absolute;
  top: 28px;
  left: 40px;
  font-weight: 500;
`;
const InstructorLedTraining1 = styled.div`
  position: absolute;
  top: 61px;
  left: 39.5px;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 202px;
  height: 50px;
`;
const Span4 = styled.span`
  font-size: 12px;
`;
const PriceForWholeOrganisation = styled.span`
  color: #e8e8e8;
  font-size: 16px;
`;
const PriceForWholeContainer2 = styled.div`
  position: absolute;
  top: 111px;
  left: 39.5px;
  display: flex;
  align-items: center;
  width: 202px;
  height: 59px;
`;
const GetStartedButton4 = styled(Button)`
  position: absolute;
  top: 263px;
  left: 24px;
`;
const ThisTrainingDoes1 = styled.div`
  position: absolute;
  top: 198px;
  left: 40.5px;
  font-size: 12px;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  width: 197px;
  height: 31px;
`;
const PaidParent1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 272px;
  height: 330px;
  font-size: 24px;
  color: #fff;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const PlanList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Tick1Icon = styled.img`
  position: relative;
  width: 20.17px;
  height: 12.31px;
  object-fit: cover;
`;
const Tick2Icon = styled.img`
  position: relative;
  width: 20.17px;
  height: 14.55px;
  object-fit: cover;
`;
const Tick3Icon = styled.img`
  position: relative;
  width: 20.17px;
  height: 18.1px;
  object-fit: cover;
`;
const Tick4Icon = styled.img`
  position: relative;
  width: 20.17px;
  height: 14.48px;
  object-fit: cover;
`;
const Tick1Parent = styled.div`
  position: absolute;
  top: 42px;
  left: 648.1px;
  width: 20.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
`;
const Hoursmonth = styled.div`
  position: absolute;
  top: 234px;
  left: 615.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 15px;
`;
const Div = styled.div`
  position: absolute;
  top: 269px;
  left: 649.11px;
  font-size: 10px;
  color: #4a4949;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.09px;
  height: 14px;
`;
const Tick1Group = styled.div`
  position: absolute;
  top: 42px;
  left: 923.42px;
  width: 20.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
`;
const Tick7Icon = styled.img`
  position: absolute;
  top: 265px;
  left: 923.42px;
  width: 20.1px;
  height: 14.48px;
  object-fit: cover;
`;
const Div1 = styled.div`
  position: absolute;
  top: 238px;
  left: 924.43px;
  font-size: 10px;
  color: #4a4949;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.09px;
  height: 14px;
`;
const FeaturesTableChild = styled.div`
  position: absolute;
  top: 33px;
  left: 239.15px;
  background-color: #1f2128;
  border: 1px solid #1f2128;
  box-sizing: border-box;
  width: 273.31px;
  height: 261px;
`;
const Tick1Icon2 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20.1px;
  height: 12.31px;
  object-fit: cover;
`;
const Tick5Icon2 = styled.img`
  position: absolute;
  top: 99px;
  left: 0px;
  width: 20.1px;
  height: 14.48px;
  object-fit: cover;
`;
const Tick4Icon2 = styled.img`
  position: absolute;
  top: 74px;
  left: 0px;
  width: 20.1px;
  height: 14.48px;
  object-fit: cover;
`;
const Tick3Icon2 = styled.img`
  position: absolute;
  top: 46px;
  left: 0px;
  width: 20.1px;
  height: 18.1px;
  object-fit: cover;
`;
const Tick2Icon2 = styled.img`
  position: absolute;
  top: 23px;
  left: 0px;
  width: 20.1px;
  height: 14.55px;
  object-fit: cover;
`;
const Tick6Icon2 = styled.img`
  position: absolute;
  top: 129px;
  left: 0px;
  width: 20.1px;
  height: 14.48px;
  object-fit: cover;
`;
const Tick1Container = styled.div`
  position: absolute;
  top: 42px;
  left: 372.79px;
  width: 20.1px;
  height: 143.48px;
`;
const Div2 = styled.div`
  position: absolute;
  top: 238px;
  left: 373.79px;
  font-size: 10px;
  color: #4a4949;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.09px;
  height: 14px;
`;
const Div3 = styled.div`
  position: absolute;
  top: 269px;
  left: 373.79px;
  font-size: 10px;
  color: #4a4949;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.09px;
  height: 14px;
`;
const GroupItem = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  background-color: #1f2128;
  width: 1044px;
  height: 33px;
`;
const Support = styled.div`
  margin-top:5px;
  position: absolute;
  left: 21.5px;
  align-items: center;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1044px;
  height: 33px;
  text-align: left;
  font-size: 16px;
  color: #fff;
`;
const RectangleContainer = styled.div`
  position: absolute;
  top: 194px;
  left: 0px;
  width: 1044px;
  height: 33px;
  text-align: left;
  font-size: 16px;
  color: #fff;
`;
const RapidHealthCheck1 = styled.p`
`;
const RapidHealthCheckContainer1 = styled.div`
  position: absolute;
  top: 35px;
  left: 14.5px;
  text-align: left;
  align-items: center;
  width: 225px;
  height: 156px;
`;
const OfflineCyberSecurity = styled.p`
  margin-block-start: 0;
  margin-block-end: 10px;
`;
const FeaturesTraining8Container = styled.div`
  position: absolute;
  top: 265px;
  left: 14.5px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 225px;
`;
const OfflineCyberSecurityContainer = styled.div`
  position: absolute;
  top: 235px;
  left: 14.5px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 225px;
`;
const FeaturesTableItem = styled.div`
  position: absolute;
  top: 60.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTableInner = styled.div`
  position: absolute;
  top: 163.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const LineDiv = styled.div`
  position: absolute;
  top: 253.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTableChild1 = styled.div`
  position: absolute;
  top: 293.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTableChild2 = styled.div`
  position: absolute;
  top: 134.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTableChild3 = styled.div`
  position: absolute;
  top: 110.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTableChild4 = styled.div`
  position: absolute;
  top: 85.5px;
  left: 20.6px;
  border-top: 1px solid #1f2128;
  box-sizing: border-box;
  width: 1023.9px;
  height: 1px;
`;
const FeaturesTable = styled.div`
  position: relative;
  width: 1044px;
  height: 301px;
  text-align: center;
  font-size: 11px;
  color: #e8e8e8;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const SelectPlan = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px 49px;
  align-items: center;
  justify-content: center;
`;
const PlanSection1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 31px 0px;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Roboto;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    padding-top: 0px;
    box-sizing: border-box;
    margin-top: -80px;
  }
`;
const WchaosautoLayoutRoot = styled.div`
  position: relative;
  background-color: #1d1d1d;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6px 60px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 75px;
  z-index: -2;
  overflow-x: hidden;
  text-align: center;
  font-size: 16;
  color: #e5e5e5;
  font-family: Rubik;
  @media screen and (max-width: 960px) {
    z-index: -2;
    overflow-x: hidden;
  }
  @media screen and (max-width: 420px) {
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
  }
`;


const CzWebsite = () => {

  const [open, setOpen] = React.useState(false);
  const [openTnC, setOpenTnC] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const toggleTnC = () => {
    setOpenTnC((prev) => !prev);
  }

  return (
    <>
      <Helmet>
        <title>CisoZen</title>
      </Helmet>
      <Navbar />
      <Grid container style={{ background: "linear-gradient(#12660b, #051d08, #121212)", paddingLeft: "50px", paddingRight: "50px" }}>
        {/* <Grid item xs={12} md={12}>
          <Home position="fixed">
            <HomelogoIcon alt="" src="assets/images/homelogo@2x.png" />
          </Home>
        </Grid> */}
        <Grid item xs={12} md={12} style={{ height: "100px" }}></Grid>

        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={0} md={12} style={{ height: "100px" }} display={{ xs: "none", md: "block" }}></Grid>
            <Grid item xs={12} md={0} style={{ height: "40px" }} display={{ xs: "block", md: "none" }}></Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", paddingBottom: "50px" }}>
              {/* <AnimateZen /> */}
              <div style={{ justifyContent: "center" }}>
                <Typography variant="h2">World</Typography>
                <Typography variant="h2">of</Typography>
                <Typography variant="h2" style={{ color: "red", textDecoration: "line-through" }}>CHAOS</Typography>
                <Typography variant="h2" style={{ color: "green" }}>ZEN </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="outlined" color="success" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}>
                sign-up
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Worldofchaos>
            <Image1>
              <RiskGraphPage />
              {/* <img src="assets/images/ZenView.gif" width={"100%"}/>
              <Vector1Icon alt="" src="assets/images/vector1.svg" />
              <Vector2Icon alt="" src="assets/images/vector2.svg" />
              <Vector3Icon alt="" src="assets/images/vector1.svg" />
              <Vector4Icon alt="" src="assets/images/vector2.svg" />
              <Vector5Icon alt="" src="assets/images/vector2.svg" />
              <Vector6Icon alt="" src="assets/images/vector1.svg" />
              <VectorIcon alt="" src="assets/images/vector.svg" />
              <FinancialImpact>Financial Impact</FinancialImpact>
              <Spyware>Spyware</Spyware>
              <BrandDamage>Brand Damage</BrandDamage>
              <DataBreach>Data Breach</DataBreach>
              <Ransomware>Ransomware</Ransomware>FZ
              <Phishing>Phishing</Phishing> */}
            </Image1>
          </Worldofchaos>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ArrowIcon alt="" src="assets/images/arrow.svg" />
          </div>
        </Grid> */}
        <Grid item xs={0} md={12} style={{ height: "70px" }}></Grid>
        <Grid item xs={12} md={6}>
          <OurVision>
            <Typography variant="h4">Our Vision</Typography>
            <Typography variant="h5">
              Australian businesses will become the most secure globally by
              2030, regardless of budget constraints.
            </Typography>
          </OurVision>
        </Grid>
        <Grid item xs={12} md={6}>
          <OurVision>
            <Typography variant="h4">Our Plan</Typography>
            <Typography variant="h5">To keep CisoZen free for all businesses</Typography>
          </OurVision>
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <iframe width="1280" height="720" src="https://www.youtube.com/embed/6FxyJc5ndrY?rel=0" title="CisoZen Zen View" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            {/* <img src="assets/images/ZenView.gif" width={"100%"} style={{ maxWidth: "950px", paddingTop: "50px", paddingBottom: "50px" }} /> */}
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ background: "linear-gradient(#121212, #051d08, #12660b)", padding: "50px" }}>
        <Grid item xs={12} md={6}>
          <Czleftgroup>
            <VectorIcon2 alt="" src="assets/images/vector4.svg" />
            <ButtonDarkGreen variant="outlined" color="success" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}>
              sign-up
            </ButtonDarkGreen>
          </Czleftgroup>
        </Grid>
        <Grid item xs={12} display={{ xs: "block", md: "none" }} style={{ height: "50px" }} />

        <Grid item xs={12} md={6}>
          <Czrightgroup>
            <Typography variant="h4" textAlign={"center"}>CisoZen Features</Typography>
            <Typography>We believe every organisation is capable of improving their cyber security health by undertaking numerous self-service activities. This will help in reducing cyber security costs while rapidly improving cyber security health. Paid subscription of Cisozen will qualify you for the following offline cyber security services delivered by our partner Securemation for 10 days: </Typography>

            <PenetrationTestingVulnerabil>
              <PenetrationTesting>Penetration Testing</PenetrationTesting>
              <PenetrationTesting>Vulnerability management</PenetrationTesting>
              <PenetrationTesting>Security Strategy</PenetrationTesting>
              <PenetrationTesting>Security Architecture</PenetrationTesting>
              <PenetrationTesting>
                {`Threat and Risk Assessment & Security Audits`}
              </PenetrationTesting>
              <PenetrationTesting>Virtual CISO</PenetrationTesting>
              <PenetrationTesting>Secure By Design</PenetrationTesting>
              <PenetrationTesting>Zero Trust Framework</PenetrationTesting>
              <PenetrationTesting>
                Operational Security Activities (Firewalls, Endpoint protection,
                Cloud security etc.)
              </PenetrationTesting>
              <PenetrationTesting>
                ISMS (ISO27001 and ISO27002) Implementation
              </PenetrationTesting>
              <PenetrationTesting>OT Security</PenetrationTesting>
            </PenetrationTestingVulnerabil>
            <SomeOfThese>
              Some of these features are yet to be built in Cisozen, but these
              services will be delivered to your organisation in an offline
              mode.
            </SomeOfThese>
          </Czrightgroup>
        </Grid>
      </Grid>
      <Grid container style={{ background: "linear-gradient(#12660b, #051d08, #121212)", padding: "50px" }}>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none" }}>
          <HcimageIcon alt="" src="assets/images/image@2x.png" />
        </Grid>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none", height: "50px" }} />
        <Grid item xs={12} md={6}>
          <Hcleftgroup>
            <Typography variant="h4" textAlign={"center"}>Rapid Health Check</Typography>
            <Typography>
              Quickly establish the cyber security health of your organisation.
              Cisozen will guide you through the process. You can engage the
              services of a cyber security analyst at any stage of the process
              (requires paid subscription).
            </Typography>
            <Typography textAlign={"left"}>
              {`The latest statistics (from `}
              <Securemation
                href="https://www.cyber.gov.au/acsc/view-all-content/reports-and-statistics/acsc-annual-cyber-threat-report-july-2021-june-2022"
                target="_blank"
              >
                <Securemation1>{`ACSC `}</Securemation1>
              </Securemation>
              ) show
            </Typography>
            <PenetrationTestingVulnerabil>
              <PenetrationTesting>
                A rise in the average cost per cybercrime report to over $39,000
                for small business, $88,000 for medium business, and over
                $62,000 for large business an average increase of 14 per cent.
              </PenetrationTesting>
              <PenetrationTesting>
                A cybercrime report every 7 minutes on average compared to every
                8 minutes last financial year.
              </PenetrationTesting>
              <PenetrationTesting>
                Over 76,000 cybercrime reports an increase of 13 per cent from
                the previous financial year.
              </PenetrationTesting>
            </PenetrationTestingVulnerabil>
            <SomeOfThese>&nbsp;</SomeOfThese>
            <SomeOfThese>
              *You will be awarded points for completing the activities within
              the recommended time frame.
            </SomeOfThese>
          </Hcleftgroup>
        </Grid>
        <Grid item xs={0} md={6} display={{ xs: "none", md: "block" }}>

          <HcimageIcon alt="" src="assets/images/image@2x.png" />
        </Grid>
      </Grid>
      <Grid container style={{ background: "linear-gradient(#121212, #051d08, #12660b)", padding: "50px" }}>
        <Grid item xs={12} md={6}>
          <Recomleftgroup>
            <RecomimageIcon alt="" src="assets/images/image1@2x.png" />
          </Recomleftgroup>
        </Grid>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none", height: "50px" }} />
        <Grid item xs={12} md={6}>
          <Recomrightgroup>
            <Typography variant="h4">Recommendations</Typography>

            <PenetrationTestingVulnerabil>
              <PenetrationTesting>
                Once you have completed your rapid health check, Cisozen will
                provide you with series of recommendations. These
                recommendations must be socialized with your senior management
                to help you secure the required funding for improving your
                organisations' cyber security health. Cisozen has a guided
                process for this activity. You will be awarded points for
                completing the activities within the recommended time frame.
              </PenetrationTesting>
              <PenetrationTesting>{`Cisozen will require you to present the cyber security recommendations to your senior management team on a specific date. `}</PenetrationTesting>
            </PenetrationTestingVulnerabil>
            <SomeOfThese>
              *You will be awarded points for completing the activities within
              the recommended time frame.
            </SomeOfThese>
            <Button variant="outlined" color="success" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}>
              sign-up
            </Button>
          </Recomrightgroup>
        </Grid>

      </Grid>
      <Grid container style={{ background: "linear-gradient(#12660b, #051d08, #121212)", padding: "50px" }}>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none" }}>
          <Rmrightgroup>
            <RmimageIcon alt="" src="assets/images/image2@2x.png" />
          </Rmrightgroup>
        </Grid>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none", height: "50px" }} />
        <Grid item xs={12} md={6}>
          <Rmleftgroup>
            <Typography variant="h4" textAlign={"center"}>Risk Management</Typography>
            <PenetrationTestingVulnerabil>
              <PenetrationTesting>
                CisoZen creates a customized cyber security risk management tool
                for your organization.
              </PenetrationTesting>
              <PenetrationTesting>
                Assess and evaluate cyber security risks to determine the need
                for risk treatment.
              </PenetrationTesting>
              <PenetrationTesting>
                Customise CisoZen's default risk management tool to meet your
                organization's requirements.
              </PenetrationTesting>
              <PenetrationTesting>
                Define likelihood levels of risks occurring within your
                organization.
              </PenetrationTesting>
              <PenetrationTesting>
                Determine impact levels based on your organization's needs, with
                the option to update or accept default information.
              </PenetrationTesting>
              <PenetrationTesting>
                Identify and take actions to treating risks that are beyond your
                organisation’s tolerance level.
              </PenetrationTesting>
            </PenetrationTestingVulnerabil>
            <SomeOfThese>
              *You will be awarded points for completing the activities within
              the recommended time frame.
            </SomeOfThese>
          </Rmleftgroup>
        </Grid>
        <Grid item xs={0} md={6} display={{ xs: "none", md: "block" }}>
          <Rmrightgroup>
            <RmimageIcon alt="" src="assets/images/image2@2x.png" />
          </Rmrightgroup>
        </Grid>
      </Grid>
      <Grid container style={{ background: "linear-gradient(#121212, #051d08, #12660b)", padding: "50px" }}>
        <Grid item xs={12} md={6}>
          <Gamileftgroup>
            <ImageIcon alt="" src="assets/images/image3@2x.png" />
          </Gamileftgroup>
        </Grid>
        <Grid item xs={12} md={0} display={{ xs: "block", md: "none", height: "50px" }} />
        <Grid item xs={12} md={6}>
          <Gamirightgroup>
            <Typography variant="h4">Gamified Theme</Typography>
            <PenetrationTestingVulnerabil>
              <CisozenAdoptsA>
                Cisozen adopts a gamified approach to make the journey of
                securing your organization enjoyable. It awards points and
                badges for timely task completion and improving your security
                posture.
              </CisozenAdoptsA>
              <CisozenAdoptsA>
                Cisozen enhances visibility by helping you identify critical
                assets and pinpointing cyber security risks. It utilizes
                easy-to-understand graphics to illustrate your organization's
                cyber security health.
              </CisozenAdoptsA>
              <li>
                Cisozen is a gamified application that represents your
                organization's cyber security risks through a castle. It
                encourages you to build a strong castle by addressing risks
                according to recommendations. Cisozen integrates online and
                offline cyber security services seamlessly.
              </li>
            </PenetrationTestingVulnerabil>
          </Gamirightgroup>
        </Grid>
      </Grid>
      <Grid container style={{ background: "linear-gradient(#12660b, #051d08, #121212)", padding: "50px" }}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" textAlign={"center"}>Select Your Plan</Typography>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <PlanSection1>
            <SelectPlan>
              <PlanList>
                <PlansWrapper>
                  <Plans>
                    <Plans1><Typography variant="h5"> Plans</Typography></Plans1>
                    <Advanced2Icon alt="" src="assets/images/advanced-2@2x.png" />
                  </Plans>
                </PlansWrapper>
                <Features>
                  <CisozenFeaturesParent>
                    <CisozenFeatures><Typography variant="h6">CisoZen Features
                      <br /><span style={{ fontSize: "16px" }}> - Rapid Health Check (RHC)</span>
                      <br /><span style={{ fontSize: "16px" }}> - RHC Recommendations</span>
                      <br /><span style={{ fontSize: "16px" }}> - Risk Management</span>
                      <br /><span style={{ fontSize: "16px" }}> - Third Party Risk Management</span>
                      <br /><span style={{ fontSize: "16px" }}> - ASD Essential Eight(ASDE8)</span>
                      <br /><span style={{ fontSize: "16px" }}> - Other features as they are released</span>
                    </Typography></CisozenFeatures>
                  </CisozenFeaturesParent>
                </Features>
                <div style={{ height: "50px" }}></div>
                <Free>
                  <FreeChild />
                  <Free1><Typography variant="h5">Free</Typography></Free1>
                  <AllTheSelfService><Typography variant="body2">
                    All the self-service CisoZen benefits</Typography>
                  </AllTheSelfService>
                  <Tick13Parent>
                    <Tick13Icon alt="" src="assets/images/tick-13@2x.png" />
                    <CisozenFeatures1><Typography variant="body2">{`CisoZen Features `}</Typography></CisozenFeatures1>
                  </Tick13Parent>
                  <Parent1>
                    <B><Typography variant="h5">$0</Typography></B>
                    <FreeForever><Typography variant="body2">- free forever</Typography></FreeForever>
                  </Parent1>
                  <GetStartedButton
                    sx={{ width: 235 }}
                    variant="outlined"
                    color="success"
                    style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                  >
                    Get started
                  </GetStartedButton>
                  <RectangleParent>
                    <GroupChild />
                    <MostUsed><Typography>Most used</Typography></MostUsed>
                  </RectangleParent>
                </Free>
                <PaidMonthly>
                  <PaidParent>
                    <Typography variant="h5">Paid</Typography>
                    <Typography variant="body2">
                      Specialized cyber security services
                    </Typography>
                    <Mo66Container>
                      <b>
                        <Typography variant="h6" style={{ lineHeight: '1' }}>$667/month <span style={{ fontSize: "11px" }}>- 66% discount based on Australian market rate</span></Typography>
                        <Span>{` `}</Span>
                      </b>
                    </Mo66Container>
                    <OneYearOfContainer>
                      <Typography variant="body2">One year of commitment.</Typography>
                      <Typography variant="body2">$8,000 per year</Typography>
                    </OneYearOfContainer>
                    <Tick13Group>
                      <Tick13Icon alt="" src="assets/images/tick-13@2x.png" />
                      <CisozenFeatures2><Typography variant="body2">{`CisoZen Features `}</Typography></CisozenFeatures2>
                    </Tick13Group>
                    <Tick13Container>
                      <Tick13Icon alt="" src="assets/images/tick-13@2x.png" />
                      <OfflineSecurityServices><Typography variant="body2">
                        Offline security services 6.7 hours/month</Typography>
                      </OfflineSecurityServices>
                    </Tick13Container>
                    <SelectYourPlan1
                      sx={{ width: 235 }}
                      variant="contained"
                      color="success"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                    >
                      Buy now
                    </SelectYourPlan1>
                  </PaidParent>
                  <PaidGroup>
                    <Paid1><Typography variant="h5">Paid</Typography></Paid1>
                    <SpecializedCyberSecurity1>
                      <Typography variant="body2">
                        Specialized cyber security services</Typography>
                    </SpecializedCyberSecurity1>
                    <Mo66Container2>
                      <b>
                        <Typography variant="h6" style={{ lineHeight: "1" }}> $667/month <span style={{ fontSize: "12px" }}>- 66% discount based on Australian market rate</span></Typography>
                        <Span1>{` `}</Span1>
                      </b>
                    </Mo66Container2>
                    <GetStartedButton2
                      sx={{ width: 235 }}
                      variant="contained"
                      color="success"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                    >
                      Buy now
                    </GetStartedButton2>
                    <OneYearOfContainer2>
                      <Typography variant="body2">One year of commitment.</Typography>
                      <Typography variant="body2">$8,000 per year</Typography>
                    </OneYearOfContainer2>
                  </PaidGroup>
                </PaidMonthly>
                <PaidMonthly>
                  <PaidContainer>
                    <Typography variant="h5">Training</Typography>
                    <Typography variant="body2">
                      Instructor led training
                    </Typography>
                    <PriceForWholeContainer>
                      <b>
                        <Typography variant="h6" style={{ lineHeight: "1" }}> $2,000 <span style={{ fontSize: "12px" }}>- Price for whole organisation</span></Typography>
                        <Span>{` `}</Span>
                      </b>
                    </PriceForWholeContainer>
                    <ThisTrainingDoes><Typography variant="body2" fontSize={12}>
                      This training does not require any previous experience within
                      Cyber Security or IT</Typography>
                    </ThisTrainingDoes>
                    <Tick13Group>
                      <Tick13Icon alt="" src="assets/images/tick-13@2x.png" />
                      <CisozenFeatures2><Typography variant="body2">{`CisoZen Features `}</Typography></CisozenFeatures2>
                    </Tick13Group>
                    <Tick13Container>
                      <Tick13Icon alt="" src="assets/images/tick-13@2x.png" />
                      <OfflineSecurityServices><Typography variant="body2">
                        Features training (8 hours with 1 hour break)</Typography>
                      </OfflineSecurityServices>
                    </Tick13Container>
                    <SelectYourPlan1
                      sx={{ width: 235 }}
                      variant="contained"
                      color="success"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                    >
                      Buy now
                    </SelectYourPlan1>
                  </PaidContainer>
                  <PaidParent1>
                    <Paid3><Typography variant="h5">Paid</Typography></Paid3>
                    <InstructorLedTraining1><Typography variant="body2">
                      Instructor led training</Typography>
                    </InstructorLedTraining1>
                    <PriceForWholeContainer2>
                      <b>
                        <Typography variant="h6" style={{ lineHeight: '1' }}>$2,000 <span style={{ fontSize: "12px" }}>- Price for whole organisation</span></Typography>
                        <Span1>{` `}</Span1>
                      </b>
                    </PriceForWholeContainer2>
                    <GetStartedButton4
                      sx={{ width: 235 }}
                      variant="contained"
                      color="success"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      style={{ border: "2px solid green", color: "white", textTransform: "capitalize" }}
                    >
                      Buy now
                    </GetStartedButton4>
                    <ThisTrainingDoes1>
                      <Typography variant="body2">
                        This training does not require any previous experience within
                        Cyber Security or IT</Typography>
                    </ThisTrainingDoes1>
                  </PaidParent1>
                </PaidMonthly>
              </PlanList>
              <FeaturesTable>
                <Tick1Parent>
                  <Tick1Icon alt="" src="assets/images/tick-1@2x.png" />
                  <Tick2Icon alt="" src="assets/images/tick-2@2x.png" />
                  <Tick3Icon alt="" src="assets/images/tick-3@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-4@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-5@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-6@2x.png" />
                </Tick1Parent>
                <Hoursmonth><Typography style={{ fontSize: "11px", marginTop: "0px" }}>6.7 hours/month</Typography></Hoursmonth>
                <Div>➖</Div>
                <Tick1Group>
                  <Tick1Icon alt="" src="assets/images/tick-11@2x.png" />
                  <Tick2Icon alt="" src="assets/images/tick-21@2x.png" />
                  <Tick3Icon alt="" src="assets/images/tick-31@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-41@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-51@2x.png" />
                  <Tick4Icon alt="" src="assets/images/tick-61@2x.png" />
                </Tick1Group>
                <Tick7Icon alt="" src="assets/images/tick-7@2x.png" />
                <Div1>➖</Div1>
                <FeaturesTableChild />
                <Tick1Container>
                  <Tick1Icon2 alt="" src="assets/images/tick-12@2x.png" />
                  <Tick5Icon2 alt="" src="assets/images/tick-52@2x.png" />
                  <Tick4Icon2 alt="" src="assets/images/tick-52@2x.png" />
                  <Tick3Icon2 alt="" src="assets/images/tick-32@2x.png" />
                  <Tick2Icon2 alt="" src="assets/images/tick-22@2x.png" />
                  <Tick6Icon2 alt="" src="assets/images/tick-52@2x.png" />
                </Tick1Container>
                <Div2>➖</Div2>
                <Div3>➖</Div3>
                <RectangleGroup>
                  <GroupItem />
                  <Support><Typography>CisoZen Features</Typography></Support>
                </RectangleGroup>
                <RectangleContainer>
                  <GroupItem />
                  <Support><Typography>Support</Typography></Support>
                </RectangleContainer>
                <RapidHealthCheckContainer1>
                  <Typography style={{ fontSize: "11px", marginTop: "6px" }}>Rapid Health Check (RHC)</Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "7px" }}>RHC Recommendations</Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "8px" }}>Risk Management</Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "9px" }}>Third Party Risk Management</Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "9px" }}>ASD Essential Eight(ASDE8)</Typography>
                  <Typography style={{ fontSize: "11px", marginTop: "12px" }}>Other features as they are released</Typography>
                </RapidHealthCheckContainer1>
                <FeaturesTraining8Container>
                  <Typography style={{ fontSize: "11px", marginTop: "0px" }}>Features training (8 hours with 1 hour break)</Typography>
                </FeaturesTraining8Container>
                <OfflineCyberSecurityContainer>
                  <Typography style={{ fontSize: "11px", marginTop: "0px" }}>Offline cyber security services</Typography>
                </OfflineCyberSecurityContainer>
                <FeaturesTableItem />
                <FeaturesTableInner />
                <LineDiv />
                <FeaturesTableChild1 />
                <FeaturesTableChild2 />
                <FeaturesTableChild3 />
                <FeaturesTableChild4 />
              </FeaturesTable>
            </SelectPlan>
          </PlanSection1>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <SubscriptionPlans />
        </Grid>
      </Grid>
      <Footer handleClickOpen={handleClickOpen} toggleTnC={toggleTnC} />
      <InfoModalWithItem open={open} handleClose={handleClose} content={<PrivacyPolicy></PrivacyPolicy>} />
      <InfoModalWithItem
        open={openTnC}
        handleClose={toggleTnC}
        content={<TermsConditions
          openPrivacyPolicy={() => {
            toggleTnC();
            handleClickOpen();
          }}
        />}
      />

    </>
  );
};

export default CzWebsite;
