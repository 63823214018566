import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';

import { DomainCard } from '../../TRA/RiskAssessment/components/ApplicableControl.styled';
import Table from '../../../../../components/UI/Table/Table';
import { toggleAllowFetch } from '../../../../../utility/riskRegister.action';

const RiskRegisterTable = (props) => {


    // ** Hooks
    const dispatch = useDispatch();

    // ** Redux states
    const { data, loading } = useSelector((state) => state.riskRegister);

    const { disableActionMeta, disableActions, handleEditClick } = props;

    const columns = [
        {
            id: "riskID",
            header: "Risk ID",
            sort: true,
            defaultHidden: true,
            width: '150px',
            sortFn: (a, b) => (a["riskID"] || '').localeCompare(b["riskID"] || '')
        },
        {
            id: "riskStatement",
            header: "Risk Statement",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a["riskStatement"] || '').localeCompare(b["riskStatement"] || '')
        },
        {
            id: "informationAssets",
            header: "Information Assets",
            sort: false,
            defaultHidden: true,
            width: '200px',
            renderCell: ({ row }) => (
                <Typography>{(row.informationAssets || []).join(", ")}</Typography>
            )
        },
        {
            id: "informationSecurityClassification",
            header: "Information Security Classification",
            sort: false,
            defaultHidden: true,
            width: '250px',
            renderCell: ({ row }) => (
                <Typography>{(row.informationSecurityClassification || []).join(", ")}</Typography>
            )
        },
        {
            id: "currentControls",
            header: "Current Controls",
            sort: false,
            defaultHidden: true,
            width: '200px',
            renderCell: ({ row }) => (
                <Typography>{(row.currentControls || []).join(", ")}</Typography>
            )
        },
        {
            id: "controlAreasForExistingControls",
            header: "Control areas for existing controls",
            sort: false,
            defaultHidden: true,
            width: '250px',
            renderCell: ({ row }) => (
                <Typography>{(row.controlAreasForExistingControls || []).join(", ")}</Typography>
            )
        },
        {
            id: "effectivenessOfCurrentControls",
            header: "Effectiveness of Current Controls",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["effectivenessOfCurrentControls"] || '').localeCompare(b["effectivenessOfCurrentControls"] || '')
        },
        {
            id: "currentLikelihood",
            header: "Current Risk Evaluation: Likelihood",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["currentLikelihood"] || '').localeCompare(b["currentLikelihood"] || '')
        },
        {
            id: "currentConsequence",
            header: "Current Risk Evaluation: Consequence",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["currentConsequence"] || '').localeCompare(b["currentConsequence"] || '')
        },
        {
            id: "currentLikelihoodxConsequence",
            header: "Current Risk Evaluation: Likelihood x Consequence",
            sort: true,
            defaultHidden: true,
            width: '300px',
            sortFn: (a, b) => (a["currentLikelihoodxConsequence"] || '').localeCompare(b["currentLikelihoodxConsequence"] || '')
        },
        {
            id: "currentRiskRating",
            header: "Current Risk Evaluation: Current Risk Rating",
            sort: true,
            width: '300px',
            sortFn: (a, b) => (a["currentRiskRating"] || '').localeCompare(b["currentRiskRating"] || '')
        },
        {
            id: "currentRiskComments",
            header: "Current Risk Comments",
            sort: true,
            width: '250px',
            sortFn: (a, b) => (a["currentRiskComments"] || '').localeCompare(b["currentRiskComments"] || '')
        },
        {
            id: "riskTreatmentRecommendation",
            header: "Risk Treatment Recommendation",
            sort: true,
            defaultHidden: true,
            width: '300px',
            sortFn: (a, b) => (a["riskTreatmentRecommendation"] || '').localeCompare(b["riskTreatmentRecommendation"] || '')
        },
        {
            id: "riskTreatmentAction",
            header: "riskTreatmentAction",
            sort: false,
            width: '250px',
            renderCell: ({ row }) => (
                <Typography>{(row.riskTreatmentAction || []).join(", ")}</Typography>
            )
        },
        {
            id: "controlAreasForNewTreatmentMeasures",
            header: "Control areas for new treatment measures",
            sort: false,
            defaultHidden: true,
            width: '300px',
            renderCell: ({ row }) => (
                <Typography>{(row.controlAreasForNewTreatmentMeasures || []).join(", ")}</Typography>
            )
        },
        {
            id: "targetLikelihood",
            header: "Target Risk Evaluation: Likelihood",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["targetLikelihood"] || '').localeCompare(b["targetLikelihood"] || '')
        },
        {
            id: "targetConsequence",
            header: "Target Risk Evaluation: Consequence",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["targetConsequence"] || '').localeCompare(b["targetConsequence"] || '')
        },
        {
            id: "targetLikelihoodxConsequence",
            header: "Target Risk Evaluation: Likelihood x Consequence",
            sort: true,
            defaultHidden: true,
            width: '300px',
            sortFn: (a, b) => (a["targetLikelihoodxConsequence"] || '').localeCompare(b["targetLikelihoodxConsequence"] || '')
        },
        {
            id: "targetResidualRiskRating",
            header: "Target Risk Evaluation: Target Residual Risk",
            sort: true,
            width: '300px',
            sortFn: (a, b) => (a["targetResidualRiskRating"] || '').localeCompare(b["targetResidualRiskRating"] || '')
        },
        {
            id: "dateOfISSCApproval",
            header: "Date of ISSC Approval",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => {
                let dateA = a['dateOfISSCApproval'] && a['dateOfISSCApproval']['java.util.Date'] ? a['dateOfISSCApproval']['java.util.Date'] : 0;
                let dateB = b['dateOfISSCApproval'] && b['dateOfISSCApproval']['java.util.Date'] ? b['dateOfISSCApproval']['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row['dateOfISSCApproval'] && row['dateOfISSCApproval']['java.util.Date']) {
                    return dayjs(new Date(row['dateOfISSCApproval']['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: "riskTreatmentOwner",
            header: "Risk Treatment Owner",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => (a["riskTreatmentOwner"] || '').localeCompare(b["riskTreatmentOwner"] || '')
        },
        {
            id: "riskTreatmentStatus",
            header: "Risk Treatment Status",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a["riskTreatmentStatus"] || '').localeCompare(b["riskTreatmentStatus"] || '')
        },
        {
            id: "riskTreatmentDueDate",
            header: "Date Risk Treatment Due Date",
            sort: true,
            width: '200px',
            sortFn: (a, b) => {
                let dateA = a['riskTreatmentDueDate'] && a['riskTreatmentDueDate']['java.util.Date'] ? a['riskTreatmentDueDate']['java.util.Date'] : 0;
                let dateB = b['riskTreatmentDueDate'] && b['riskTreatmentDueDate']['java.util.Date'] ? b['riskTreatmentDueDate']['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row['riskTreatmentDueDate'] && row['riskTreatmentDueDate']['java.util.Date']) {
                    return dayjs(new Date(row['riskTreatmentDueDate']['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: "riskTreatmentImplementedDate",
            header: "Date Risk Treatment Implemented Date",
            sort: true,
            width: '250px',
            sortFn: (a, b) => {
                let dateA = a['riskTreatmentImplementedDate'] && a['riskTreatmentImplementedDate']['java.util.Date'] ? a['riskTreatmentImplementedDate']['java.util.Date'] : 0;
                let dateB = b['riskTreatmentImplementedDate'] && b['riskTreatmentImplementedDate']['java.util.Date'] ? b['riskTreatmentImplementedDate']['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row['riskTreatmentImplementedDate'] && row['riskTreatmentImplementedDate']['java.util.Date']) {
                    return dayjs(new Date(row['riskTreatmentImplementedDate']['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: "dateOfReviewAndNatureOfUpdate",
            header: "Date of Review and Nature of Update",
            sort: true,
            defaultHidden: true,
            width: '300px',
            sortFn: (a, b) => (a["dateOfReviewAndNatureOfUpdate"] || '').localeCompare(b["dateOfReviewAndNatureOfUpdate"] || '')
        },
        {
            id: "businessCriticality",
            header: "Business Impact/criticality",
            sort: true,
            defaultHidden: true,
            width: '250px',
            sortFn: (a, b) => (a["businessCriticality"] || '').localeCompare(b["businessCriticality"] || '')
        },
        {
            id: "controlMaturityScore",
            header: "Control Maturity Score",
            sort: true,
            defaultHidden: true,
            width: '200px',
            sortFn: (a, b) => ((a.controlMaturityScore || 0) - (b.controlMaturityScore || 0)),
            renderCell: ({ row }) => {
                return <>
                    <span style={{ color: row.controlMaturityScore <= 2.5 ? "red" : "green" }}>
                        {row.controlMaturityScore}
                    </span>
                </>
            }
        },
        {
            id: "actualResidualRiskRating",
            header: "Actual Residual Risk Rating",
            sort: true,
            width: '200px',
            sortFn: (a, b) => (a["actualResidualRiskRating"] || '').localeCompare(b["actualResidualRiskRating"] || '')
        },
        // {
        //     id: 'actions',
        //     header: '',
        //     sortable: false,
        //     disableColumnFilter: true,
        //     pinRight: true,
        //     width: '60px',
        //     renderCell: ({ row }) => (
        //         <>
        //             <MuiLoadingButton
        //                 aria-label="edit"
        //                 title="edit"
        //                 onClick={(e) => handleEditClick(row.caseID, row.status, row)}
        //                 as={IconButton}
        //                 disabled={disableActions}
        //                 loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
        //             >
        //                 <EditIcon />
        //             </MuiLoadingButton>
        //         </>
        //     ),
        // }
    ];

    return (
        <DomainCard>
            <Table
                loading={loading}
                tableTitle={
                    <Typography variant="h5">
                        Risk Register
                    </Typography>
                }
                tableData={data || []}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <MuiLoadingButton
                                aria-label="refresh"
                                title="refresh"
                                as={IconButton}
                                size="small"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={loading}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />
        </DomainCard>
    );
};

export default RiskRegisterTable;
