import React, { Fragment, useEffect, useState, } from "react";
import { renderToString } from 'react-dom/server';

import { jsPDF } from "jspdf";

import { CONTAINER_ID } from "../../../../../store/auth-context";
import {
    StyledListItem,
    StyledGraph,
    StyledPercent,
    StyledName,
    StyledListBox,
    StyledListContent,
    GraphContainer,
    NumberContainer,
} from "../../PdfTest/PdfTest.styled";

//jspdf is using to generate autotable
import '../../PdfTest/PdfTest.css';
import logo2 from '../../../../../images/logo0.2.png';
import pdfBackground from '../../../../../images/pdf-background.PNG';
import { getCompletedOrganization } from "../../../InformationAPI";
import * as fetchInstance from "../../../../../utility/fetch-instance";

function RHCDownloadReportButton(props) {

    // ** Props
    const { kcToken, email, historyData, data, date = "16/06/2022" } = props;

    const [isLoading, setIsloading] = useState(false);
    const containerID = CONTAINER_ID;
    var currentMaturityScore = [0, 0, 0, 0, 0];
    var organisationName = "Organisation";
    var presentationDate = "16/06/2022";
    var totalScore = "0";
    var [recommendationList, setRecommendationList] = useState([]);
    const [completedHealthCheckList, setcompletedHealthCheckList] = useState(historyData);

    useEffect(() => {
        setcompletedHealthCheckList(historyData);
    }, [historyData])

    function download() {
        setTimeout(() => {

            const content = renderToString(<Prints key={data.id} />);

            let doc = new jsPDF('p', 'pt', 'a4');

            doc.html(content, {
                autoPaging: true,
                callback() {
                    const pageCount = doc.internal.getNumberOfPages();
                    // From HTML
                    var finalY = doc.lastAutoTable.finalY || 200
                    doc.setPage(pageCount - 1)
                    doc.autoTable({
                        startY: finalY + 20,
                        html: `#mytable-${data.id}`,
                        theme: 'plain',
                        margin: { left: 55, right: 55, bottom: 120 },
                        headStyles: {
                            fillColor: '#46a11b',
                            textColor: '#fff'
                        },
                        didDrawPage: function (data) {
                            // Footer
                            var str = `${organisationName} - Cyber Security Health Check | Page ${doc.getCurrentPageInfo().pageNumber}`;

                            doc.setFontSize(10);

                            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                            var pageSize = doc.internal.pageSize;
                            var pageHeight = pageSize.height
                                ? pageSize.height
                                : pageSize.getHeight();
                            if (doc.getCurrentPageInfo().pageNumber > 5) {

                                doc.addImage(logo2, 'PNG', data.settings.margin.left, pageHeight - 60, 130, 30);
                                doc.text(str, pageSize.width - 50, pageHeight - 40, { align: 'right' });

                            }

                        }
                    })
                    setIsloading(false);
                    // Get the Blob object from the PDF
                    const pdfBlob = doc.output('blob');

                    // Create a Blob URL for the PDF
                    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

                    // Open the PDF in a new tab with a custom name
                    const fileName = `RHC-${organisationName}-${date}.pdf`;
                    const a = document.createElement('a');
                    a.href = pdfBlobUrl;
                    a.target = '_blank';
                    a.download = fileName.replaceAll('/', '-');
                    a.click();
                }
            });
        }, 3000)

    }

    function RecommendationsPage(props) {
        var { date, companyName, presentationDate } = props;
        return (
            <div className="container-center-horizontal">
                <div className="highlights-page screen">
                    <div className="flex-row3">
                        <div className="line-4"></div>
                        <div className="date2 inter-medium-balck-12px">
                            <span className="inter-medium-black-12px">{date}</span>
                        </div>
                    </div>
                    <h1 className="title3 inter-medium-black-36px">
                        <span className="inter-medium-black-36px">RECOMMENDATIONS</span>
                    </h1>
                    <div className="presentation-date inter-normal-black-12px">
                        <span className="inter-normal-black-12px"><b>Senior stakeholder presentation : {presentationDate}</b></span>
                        <span className="inter-normal-black-11px">
                            <br />
                            Please note that there are
                            several other recommendations that
                            can be made based on the
                            assessment conducted, but the focus
                            has been on high priority items that
                            best reduce the risks that your
                            organisation faces today.
                        </span>
                    </div>
                    <br></br>
                    <div style={{ height: '600px' }}></div>
                    <div className="flex-row-3-1">
                        <img className="logo-highlights" src={logo2} alt="logo" />
                        <p className="bottom-date inter-normal-black-10px">
                            <span className="inter-normal-black-10px">{companyName} - Cyber Security Health Check | Page 5</span>
                        </p>
                    </div>
                </div>
            </div >
        );
    }

    function MaturityPage(props) {
        var { date, identify, protect, detect, respond, recover, score, companyName } = props;
        return (
            <div className="container-center-horizontal">
                <div className="highlights-page screen">
                    <div className="flex-row3">
                        <div className="line-4"></div>
                        <div className="date2 inter-medium-black-12px">
                            <span className="inter-medium-black-12px">{date}</span>
                        </div>
                    </div>
                    <h1 className="title3 inter-medium-black-36px">
                        <span className="inter-medium-black-36px">SECURITY MATURITY LEVEL</span>
                    </h1>
                    <div className="flex-row-4-1">
                        <div className="group-4-1"><MaturityBarGraph identify={Math.floor(identify)} protect={Math.floor(protect)} detect={Math.floor(detect)} respond={Math.floor(respond)} recover={Math.floor(recover)}></MaturityBarGraph></div>
                        <p className="right-text inter-normal-black-11px">
                            <span className="inter-normal-black-11px">
                                <br />
                                It is recommended that a minimum
                                maturity level of 3 should be
                                targeted. Maturity Level 3 is when
                                your organization has processes that
                                are “well characterized and
                                understood and are described in
                                standards, procedures, tools and
                                methods.

                            </span>
                        </p>
                    </div>
                    <ul className="bottom-text">
                        <li><p className="inter-normal-black-10px">1. <b>Identify</b> - The Identify Function assists in developing an organizational understanding to managing cybersecurity risk to systems, people, assets, data, and
                            capabilities.</p></li>
                        <li><p className="inter-normal-black-10px">2. <b>Protect</b> - The Protect Function outlines appropriate safeguards to ensure delivery of critical infrastructure services. The Protect Function supports the
                            ability to limit or contain the impact of a potential cybersecurity event.</p></li>
                        <li><p className="inter-normal-black-10px">3. <b>Detect</b> - The Detect Function defines the appropriate activities to identify the occurrence of a cybersecurity event. The Detect Function enables timely
                            discovery of cybersecurity events.</p></li>
                        <li><p className="inter-normal-black-10px">4. <b>Respond</b> - The Respond Function includes appropriate activities to take action regarding a detected cybersecurity incident. The Respond Function supports
                            the ability to contain the impact of a potential cybersecurity incident.</p></li>
                        <li><p className="inter-normal-black-10px">5. <b>Recover</b> - The Recover Function identifies appropriate activities to maintain plans for resilience and to restore any capabilities or services that were
                            impaired due to a cybersecurity incident. The Recover Function supports timely recovery to normal operations to reduce the impact from a cybersecurity
                            incident</p></li>
                    </ul>
                    <br /><br />
                    <div className="flex-row-3-1">
                        <img className="logo-highlights" src={logo2} alt="logo-highlights" />
                        <p className="bottom-date inter-normal-black-10px">
                            <span className="inter-normal-black-10px">{companyName} - Cyber Security Health Check | Page 4</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    function getIndicesOfExtremes(arr) {
        let minIndices = [], maxIndices = [];
        let min = Math.min(...arr), max = Math.max(...arr);

        arr.forEach((value, index) => {
            if (value === min) minIndices.push(index);
            if (value === max) maxIndices.push(index);
        });

        return { minIndices, maxIndices };
    }

    function getIndicesOfIncreasedValues() {
        let increasedIndices = [];
        try {
            let hcHistory = completedHealthCheckList.length > 2 ? completedHealthCheckList.slice(-2) : completedHealthCheckList;

            let arr1 = hcHistory[0].maturity;
            let arr2 = hcHistory[1].maturity;
            for (let i = 0; i < arr1.length; i++) {
                if (arr2[i] > arr1[i]) {
                    increasedIndices.push(i);
                }
            }
        } catch (error) {
            // console.log('DEBUG ERROR = ', error);
        }

        return increasedIndices;
    }

    function HighlightPage(props) {
        var { date, identify, protect, detect, respond, recover, score, companyName } = props;
        const maturityList = [parseFloat(identify), parseFloat(protect), parseFloat(detect), parseFloat(respond), parseFloat(recover)];
        const titleList = ["Identify", "Protect", "Detect", "Respond", "Recover"];
        const max = Math.max(...maturityList);
        const min = Math.min(...maturityList);
        let indices = getIndicesOfExtremes(maturityList);
        let increasedIndices = getIndicesOfIncreasedValues();

        return (
            <div className="container-center-horizontal" >
                <div className="highlights-page screen" id="coverpage">
                    <div className="flex-row3">
                        <div className="line-4"></div>
                        <div className="date2 inter-medium-balck-12px">
                            <span className="inter-medium-black-12px">{date}</span>
                        </div>
                    </div>
                    <h1 className="title3 inter-medium-black-36px">
                        <span className="inter-medium-black-36px">HIGHLIGHTS & KEY UPDATES</span>
                    </h1>
                    <p className="recommend-text inter-normal-black-11px">
                        <span className="inter-normal-black-11px">It is recommended that a minimum maturity level of 3 should be targeted. Maturity Level 3 is when
                            your organization has processes that are “well characterized and understood and are described in
                            standards, procedures, tools and methods.
                        </span>
                    </p>
                    <div className="overlap-group-container">
                        <div className="overlap-group">
                            <div className="rectangle-3-1">
                            </div>
                            <div className="number-3-1 inter-bold-black-64px">
                                <span className="inter-bold-black-64px">
                                    1
                                </span>
                            </div>
                            <div className="label-3-1 inter-bold-black-14px">
                                <span className="inter-bold-black-14px">
                                    STRONGEST AREAS
                                </span>
                            </div>
                            <div className="line-3-1"></div>
                            <div className="label-result-3-1 inter-medium-black-14px">
                                <span className="inter-medium-black-24px">{Math.floor(max)}/5 </span><br />
                                {
                                    indices.maxIndices.map((index) => titleList[index]).join(', ')
                                }
                            </div>
                        </div>
                        <div className="overlap-group-1">
                            <div className="rectangle-3-2">
                            </div>
                            <div className="number-3-1 inter-bold-black-64px">
                                <span className="inter-bold-black-64px">
                                    2
                                </span>
                            </div>
                            <div className="label-3-1 inter-bold-black-14px">
                                <span className="inter-bold-black-14px">
                                    WEAKEST AREAS
                                </span>
                            </div>
                            <div className="line-3-2"></div>
                            <div className="label-result-3-1 inter-medium-black-14px">
                                <span className="inter-medium-black-24px">{Math.floor(min)}/5</span><br />
                                {
                                    indices.minIndices.map((index) => titleList[index]).join(', ')
                                }
                            </div>
                        </div>
                    </div>
                    <div className="overlap-group-container">
                        <div className="overlap-group">
                            <div className="rectangle-3-1">
                            </div>
                            <div className="number-3-1 inter-bold-black-64px">
                                <span className="inter-bold-black-64px">
                                    3
                                </span>
                            </div>
                            <div className="label-3-1 inter-bold-black-14px">
                                <span className="inter-bold-black-14px">
                                    IMPROVED AREAS
                                </span>
                            </div>
                            <div className="line-3-1"></div>
                            <div className="label-result-3-1 inter-medium-black-24px">
                                {
                                    completedHealthCheckList.length > 1 ? <>
                                        {
                                            increasedIndices.length > 0 ? <span className="inter-medium-black-14px">
                                                {increasedIndices.map((index) => titleList[index]).join(', ')}
                                            </span> : <span className="inter-medium-black-14px">
                                                No improvements
                                            </span>
                                        }
                                    </> : <span className="inter-medium-black-24px">
                                        TBD
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="overlap-group-1">
                            <div className="rectangle-3-1">
                            </div>
                            <div className="number-3-1 inter-bold-black-64px">
                                <span className="inter-bold-black-64px">
                                    4
                                </span>
                            </div>
                            <div className="label-3-1 inter-bold-black-14px">
                                <span className="inter-bold-black-14px">
                                    CISOZEN SCORE
                                </span>
                            </div>
                            <div className="line-3-1"></div>
                            <div className="label-result-3-1 inter-medium-black-24px">
                                <span className="inter-medium-black-14px">{score}/300</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="flex-row-3-1">
                        <img className="logo-highlights" src={logo2} alt="logo" />
                        <p className="bottom-date inter-normal-black-10px">
                            <span className="inter-normal-black-10px">{companyName} - Cyber Security Health Check | Page 3</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    function TableOfContentsPage(props) {
        return (
            <div className="container-center-horizontal">
                <div className="table-of-contents-page screen">
                    <img className="rectangle-3" src={pdfBackground} alt="logo" />
                    <h1 className="title2 inter-normal-black-48px">
                        <span className="inter-normal-black-48px">TABLE OF CONTENTS</span>
                    </h1>
                    <br></br>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">At a glance</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">3</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Security Maturity Level</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">4</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <div className="group-3">
                        <div className="flex-row2 inter-medium-black-14px">
                            <div className="at-a-glance">
                                <span className="inter-medium-black-14px">Recommendations</span>
                            </div>
                            <div className="number">
                                <span className="inter-medium-black-14px">5</span>
                            </div>
                        </div>
                        <div className="line-3" src="line-3-2.png"></div>
                    </div>
                    <img className="logo-sm" src={logo2} alt="logo" />
                </div>

            </div>
        );
    }

    function CoverPage(props) {
        var { companyName, date } = props;

        return (

            <div className="container-center-horizontal" >
                <div className="cover-page screen" id="coverpage">
                    <div className="rectangle-2"></div>
                    <div className="flex-col">
                        <div className="flex-row">
                            <img className="rectangle-1" src={logo2} alt="logo" />
                            <div className="line-1"></div>
                        </div>
                        <h1 className="title inter-bold-emperor-48px">
                            <span className="inter-bold-emperor-48px">HEALTH CHECK</span>
                        </h1>
                        <div className="line-2"></div>
                        <div className="securemation inter-normal-storm-dust-24px">
                            <span className="inter-normal-storm-dust-24px">{companyName}</span>
                        </div>
                        <div className="date inter-light-emperor-48px">
                            <span className="inter-light-emperor-48px">{date}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function MaturityBarGraph(props) {
        var { identify, protect, detect, respond, recover } = props;
        return (

            <GraphContainer>
                <NumberContainer>
                    <p>5</p>
                    <p>4</p>
                    <p>3</p>
                    <p>2</p>
                    <p>1</p>
                    <p>0</p>
                </NumberContainer>
                <StyledListBox>
                    <StyledListItem>
                        <StyledListContent>
                            <StyledGraph style={{ height: identify / 5 * 190 }}>
                                <StyledPercent>{identify}</StyledPercent>
                            </StyledGraph>
                        </StyledListContent>
                        <StyledName>Identify</StyledName>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListContent>
                            <StyledGraph style={{ height: protect / 5 * 190 }}>
                                <StyledPercent>{protect}</StyledPercent>
                            </StyledGraph>
                        </StyledListContent>
                        <StyledName>Protect</StyledName>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListContent>
                            <StyledGraph style={{ height: detect / 5 * 190 }}>
                                <StyledPercent>{detect}</StyledPercent>
                            </StyledGraph>
                        </StyledListContent>
                        <StyledName>Detect</StyledName>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListContent>
                            <StyledGraph style={{ height: respond / 5 * 190 }}>
                                <StyledPercent>{respond}</StyledPercent>
                            </StyledGraph>
                        </StyledListContent>
                        <StyledName>Respond</StyledName>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListContent>
                            <StyledGraph style={{ height: recover / 5 * 190 }}>
                                <StyledPercent>{recover}</StyledPercent>
                            </StyledGraph>
                        </StyledListContent>
                        <StyledName>Recover</StyledName>
                    </StyledListItem>
                </StyledListBox>
            </GraphContainer>
        );
    }

    const Prints = () => (
        <div>
            <div className="container-fixed" id="report">
                <CoverPage companyName={organisationName} date={date}></CoverPage>
                <TableOfContentsPage></TableOfContentsPage>
                <HighlightPage score={totalScore} date={date} companyName={organisationName} identify={currentMaturityScore[0]} protect={currentMaturityScore[1]} detect={currentMaturityScore[2]} respond={currentMaturityScore[3]} recover={currentMaturityScore[4]}></HighlightPage>
                <MaturityPage identify={currentMaturityScore[0]} protect={currentMaturityScore[1]} detect={currentMaturityScore[2]} respond={currentMaturityScore[3]} recover={currentMaturityScore[4]} date={date} companyName={organisationName}></MaturityPage>
                <RecommendationsPage date={date} companyName={organisationName} presentationDate={presentationDate}></RecommendationsPage>
            </div>
        </div>
    );

    //api 1 get completed health check
    //api 2 get completed org info
    //api 3 get list of recommendations
    const getHealthCheckProcessInstance = () => {
        setIsloading(true);
        const _processInstanceID = data.id;
        getVariables(_processInstanceID);
    }

    const getVariables = (processInstanceId) => {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${containerID}/processes/instances/${processInstanceId}/variables/instances`,
        }
        fetchInstance.apiRequest(config)
            .then((response) => {
                var responseArr = response.data["variable-instance"];
                currentMaturityScore = responseArr.filter(obj => { return obj.name === "CurrentMaturity" })[0]["value"].slice(1, -1).split(',');
                totalScore = responseArr.filter(obj => { return obj.name === "TotalScore" })[0]["value"];
                getRecommendations(responseArr);

            })
            .catch((error) => {
                setIsloading(false);
                console.log({ error });
                // Handle error
            });
    }

    const getRecommendations = (dataArr) => {
        const requestData = {
            "model-namespace": "https://kiegroup.org/dmn/_59F8E423-003F-47FD-A331-6E6C306126E1",
            "model-name": "recommendations",
            "dmn-context":
            {
                "answerSC1": dataArr.filter(obj => { return obj.name === "SC1a1" })[0]["value"],
                "answerSC2": dataArr.filter(obj => { return obj.name === "SC2a1" })[0]["value"],
                "answerSC3": dataArr.filter(obj => { return obj.name === "SC3a1" })[0]["value"],
                "answerSC4": dataArr.filter(obj => { return obj.name === "SC4a1" })[0]["value"],
                "answerSC5": dataArr.filter(obj => { return obj.name === "SC5a1" })[0]["value"],
                "answerSC6": dataArr.filter(obj => { return obj.name === "SC6a1" })[0]["value"],
                "answerSC7": dataArr.filter(obj => { return obj.name === "SC7a1" })[0]["value"],
                "answerSC8": dataArr.filter(obj => { return obj.name === "SC8a1" })[0]["value"],
                "answerSC9": dataArr.filter(obj => { return obj.name === "SC9a1" })[0]["value"],
                "answerSC10": dataArr.filter(obj => { return obj.name === "SC10a1" })[0]["value"],
                "answerSC11": dataArr.filter(obj => { return obj.name === "SC11a1" })[0]["value"],
                "answerSC12": dataArr.filter(obj => { return obj.name === "SC12a1" })[0]["value"],
                "answerSC13": dataArr.filter(obj => { return obj.name === "SC13a1" })[0]["value"],
                "answerSC14": dataArr.filter(obj => { return obj.name === "SC14a1" })[0]["value"],
                "answerSC15": dataArr.filter(obj => { return obj.name === "SC15a1" })[0]["value"],
                "answerSC16": dataArr.filter(obj => { return obj.name === "SC16a1" })[0]["value"],
                "answerSC17": dataArr.filter(obj => { return obj.name === "SC17a1" })[0]["value"],
                "answerSC18": dataArr.filter(obj => { return obj.name === "SC18a1" })[0]["value"],
                "answerSC19": dataArr.filter(obj => { return obj.name === "SC19a1" })[0]["value"],
                "answerSC20": dataArr.filter(obj => { return obj.name === "SC20a1" })[0]["value"],
                "answerSC21": dataArr.filter(obj => { return obj.name === "SC21a1" })[0]["value"],
                "answerSC22": dataArr.filter(obj => { return obj.name === "SC22a1" })[0]["value"],
                "answerSC23": dataArr.filter(obj => { return obj.name === "SC23a1" })[0]["value"],
                "answerSC24": dataArr.filter(obj => { return obj.name === "SC24a1" })[0]["value"],
                "answerSC25": dataArr.filter(obj => { return obj.name === "SC25a1" })[0]["value"],
                "answerSC26": dataArr.filter(obj => { return obj.name === "SC26a1" })[0]["value"],
                "answerSC27": dataArr.filter(obj => { return obj.name === "SC27a1" })[0]["value"],
                "answerSC28": dataArr.filter(obj => { return obj.name === "SC28a1" })[0]["value"],
                "answerSC29": dataArr.filter(obj => { return obj.name === "SC29a1" })[0]["value"],
                "answerSC30": dataArr.filter(obj => { return obj.name === "SC30a1" })[0]["value"],
                "answerSC31": dataArr.filter(obj => { return obj.name === "SC31a1" })[0]["value"],
                "answerSC32": dataArr.filter(obj => { return obj.name === "SC32a1" })[0]["value"]
            }
        }

        let config = {
            method: 'post',
            url: `/services/rest/server/containers/${containerID}/dmn`,
            data: JSON.stringify(requestData),
        }

        fetchInstance
            .apiRequest(config)
            .then((response) => {

                var responseArr = response.data["result"]["dmn-evaluation-result"]["dmn-context"]["Recommendations"];

                const filteredArray = responseArr.filter((a) => a);
                var newArray = [];
                filteredArray.forEach(element => {
                    let newItem = element;
                    newItem.userReason = dataArr.filter(obj => { return obj.name === `Reason${element["control-number"]}` })[0]?.value ?? "Accepted";
                    newArray.push(newItem);
                });
                presentationDate = dataArr.filter(obj => { return obj.name === `PresentationDate` })[0]?.value ?? "-";
                setRecommendationList(newArray);
                getOrganisationProcessInstance();
            })
            .catch((error) => {
                setIsloading(false);
                console.log({ error });
                // Handle error
            });
    }

    const getOrganisationProcessInstance = () => {
        getCompletedOrganization(kcToken, email).then((response) => {
            const _processInstanceID = response.data;
            if (_processInstanceID < 1) {
                //no record found
                download();
            } else {
                getOrganisationVariables(_processInstanceID);
            }
        });
    }

    const getOrganisationVariables = (processInstanceId) => {
        let config = {
            method: 'get',
            url: `/services/rest/server/containers/${containerID}/processes/instances/${processInstanceId}/variables/instances`,
        }
        fetchInstance
            .apiRequest(config)
            .then((response) => {
                var responseArr = response.data["variable-instance"];
                organisationName = responseArr.filter(obj => { return obj.name === "OrganisationName" })[0]["value"].toUpperCase();
                download();
            })
            .catch((error) => {
                setIsloading(false);
                console.log({ error });
                // Handle error
            });
    }

    return (
        <div>
            <div style={{ display: 'none' }}>
                <table id={`mytable-${data.id}`}>
                    <thead style={{ backgroundColor: "green" }}>
                        <tr>
                            <th><span className="inter-bold-black-14px">No.</span></th>
                            <th><span className="inter-bold-black-14px">Recommendations</span></th>
                            <th><span className="inter-bold-black-14px">Reasoning</span></th>
                            <th><span className="inter-bold-black-14px">Status</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recommendationList.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        <td><span className="inter-normal-black-10px">{key + 1}</span></td>
                                        <td><span className="inter-normal-black-10px">{value.recommendation}</span></td>
                                        <td><span className="inter-normal-black-10px">{value.reason}</span> </td>
                                        <td><span className="inter-normal-black-10px">{value.userReason.toLowerCase() === "accepted" ? value.userReason : `Rejected: ${value.userReason}`}</span> </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {props.children({ onClick: getHealthCheckProcessInstance, loading: isLoading })}

            {/* <Button $success onClick={getHealthCheckProcessInstance} disabled={isLoading}>
                {
                    isLoading ? <CircularProgress style={{ height: "26px", width: "87px", padding: "0px 30px" }}>Download Report</CircularProgress> : "Download Report"
                }
            </Button> */}
        </div>
    );
}

export default RHCDownloadReportButton;
