/* eslint-disable no-unused-vars */ // remove it after refactor
import { Box, Button, CssBaseline } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../components/UI/Loader/Loader";
import { CONTAINER_ID, setContainerID } from "../../store/auth-context";
import Header from "./components/Header/Header";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import { ContentWrapper, MainContent } from "./Dashboard.styled";
import HealthCheck from "./nested/HealthCheck/HealthCheck";
import Help from "./nested/Help/Help";
import Information from "./nested/Information/Information";
import PdfTest from "./nested/PdfTest/PdfTest";
import Recommendations from "./nested/Recommendations/Recommendations";
import RiskMatrix from "./nested/RiskMatrix/RiskMatrix";
import Settings from "./nested/Settings/Settings";
import { Cookies } from "../../shared/utility";
import HealthCheckLandingPage from "./nested/HealthCheck/HealthCheckLandingPage";
import RiskAssessment from "./nested/RiskAssessment/RiskAssessment";
import RiskAssessmentLandingPage from "./nested/RiskAssessment/RiskAssessmentLandingPage";
import jwt_decode from "jwt-decode";
import { useIdleTimer } from 'react-idle-timer';
import InfoModal from "./components/InfoModal/InfoModal";
import ModalImageSrc from "../../images/reccomendationImg.png";
import MainDrawer from "./components/SideDrawer/MainDrawer";
import NotFound from "../../components/ErrorPage/NotFoundPage";
import PrivacyPolicy from "../Public/PrivacyPolicy/PrivacyPolicy";
import { Helmet } from "react-helmet-async";
import { updateTrackerData } from "../../utility/tracker.action";
import { useDispatch } from "react-redux";

var drawerWidth = 259;

function MainMenu(props) {
    let { path } = useRouteMatch();

    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const kcToken = keycloak?.token ?? '';

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isShrink, setIsShrink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [username, setUsername] = useState(null);
    const [containerName, setContainerName] = useState(null);
    const [signal, setSignal] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    let timer = null;

    const location = useLocation()

    const history = useHistory();

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        handleOpenModal();
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    const onAction = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    //1 hour idle
    const idleTimer = useIdleTimer({ onIdle, onActive, onAction, timeout: 1000 * 60 * 60 })
    const [timerText, setTimerText] = useState(`Are you still there? Your session will expire in 30 seconds. Click "Continue" if you want to continue and stay logged in.`);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: 'Home',
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch, window])

    useEffect(() => {
        if (!keycloak.authenticated) {
            history.replace("/")
        } else {
            keycloak.loadUserInfo().then((res) => {
                setEmail(res.email);
                setUsername(res.name);
            });
        }
    }, [history, keycloak.authenticated, location])


    useEffect(() => {
        if (openModal) {
            timer = setTimeout(() => {
                history.replace("/login");
                sessionStorage.clear();
                keycloak.logout()
            }, 1000 * 30);
        } else {
            clearTimeout(timer);
        }
    }, [openModal])

    function handleContinue() {
        clearTimeout(timer);
        handleCloseModal();
    }

    useEffect(() => {
        if (keycloak) {
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(50).then((refreshed) => {
                    if (refreshed) {
                        setSignal(keycloak.tokenParsed.exp);
                    } else {
                        // console.log('not refreshed ' + new Date());
                    }
                })
            }
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };

    }, [keycloak.authenticated]);

    useEffect(() => {
        if (!keycloak.authenticated) {
            history.replace("/")
        } else {
            try {
                var decoded = jwt_decode(kcToken);
                var resource = decoded["resource_access"];
                let userName = decoded["name"] ?? "";
                setUsername(userName);
                // var clientRoles = resource[`${Object.keys(resource)[0]}`]["roles"];
                // const orgName = clientRoles.filter(role => role !== "cz-users")[0];
                // setContainerID(orgName + "_0.1");
                // setContainerName(orgName + "_0.1");
            } catch (error) {
                console.log(error);
            }
        }
    }, [history, keycloak.authenticated, location])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleShrinkToggle = () => {
        if (!isShrink) {
            drawerWidth = 75
        } else {
            drawerWidth = 259
        }
        setIsShrink(!isShrink);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    let content;

    if (loading) {
        content = <Loader height="90vh" />;
    }


    content = (
        <MainContent>
            <Switch>
                <Route path={`${path}/privacy-policy`} exact>
                    <PrivacyPolicy />
                </Route>
                <Route path={`${path}/`} exact>
                    <Information signal={signal} containerName={containerName} />
                </Route>
            </Switch>
        </MainContent>
    );


    return (
        <>
            <Helmet>
                <title>Home - CisoZen</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <MainDrawer container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} handleDrawerShrink={handleShrinkToggle} drawerWidth={drawerWidth} />
                </Box>
                <ContentWrapper drawerWidth={drawerWidth}>
                    <Header username={username} email={email} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
                    {content}
                    <InfoModal
                        open={openModal}
                        handleClose={handleCloseModal}
                        imgSrc={ModalImageSrc}
                        title={timerText}
                        list={[<Button onClick={() => handleContinue()}>Continue...</Button>]}
                    />
                </ContentWrapper>
            </Box>
        </>
    );
}

export default MainMenu;

