import { CONTAINER_ID } from '../../../../store/auth-context';
import * as fetchInstance from '../../../../utility/fetch-instance';

export async function getDMN(containerID, jwtToken, modelName) {
    let result = { success: false };

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${containerID}/dmn/models/${modelName}/dmnresult`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify({})
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

/*get case instances*/
export async function getCases(containerID, jwtToken) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

/*get case instance by case definition ID*/
//status open or close
export async function getCase(containerID, jwtToken, caseDefinitionID, status = "open", size = 10, page = 0) {
    let result = { success: false };
    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/${caseDefinitionID}/instances?status=${status}&sortOrder=false&withData=true&page=${page}&pageSize=${size}`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

/*start new case with caseID*/
export async function startNewCase(containerID, jwtToken, caseDefinition, data) {
    let result = { success: false };

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${containerID}/cases/${caseDefinition}/instances`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify(data)
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

/*reopen case with caseID*/
export async function reopenCase(containerID, jwtToken, caseDefinition, caseID) {
    let result = { success: false };
    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/cases/${caseDefinition}/instances/${caseID}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify({})
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

/*close case with caseID*/
export async function closeCase(containerID, jwtToken, caseID) {
    let result = { success: false };
    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get case file data
export async function getCaseFileData(containerID, jwtToken, caseID) {
    let result = { success: true };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/caseFile`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get case file data
export async function getCaseRole(containerID, jwtToken, caseID) {
    let result = { success: true };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/roles`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//update case file data
export async function updateCaseRole(containerID, jwtToken, caseID) {
    let result = {};

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/roles/admin?group=cz-sa-riskassessment`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//update case file data
export async function updateCaseFileData(containerID, jwtToken, caseID, data) {
    let result = {};

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/caseFile`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify(data)
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//add case file data
export async function addCaseFileData(containerID, jwtToken, caseID, data) {
    let result = {};

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/caseFile`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify(data)
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//update case file variable
export async function updateCaseFileVariable(containerID, jwtToken, caseID, varName, data) {
    let result = { success: false };

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/caseFile/${varName}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify(data)
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get user task
export async function getUserTask(containerID, jwtToken, processInstanceID) {
    let result = { success: false };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/processes/instances/${processInstanceID}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//complete user task
export async function startUserTask(containerID, jwtToken, taskInstanceID) {
    let result = { success: false };

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/tasks/${taskInstanceID}/states/started`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//complete user task
export async function completeUserTask(containerID, jwtToken, taskInstanceID, data) {
    let result = { success: false };

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/tasks/${taskInstanceID}/states/completed`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify(data)
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get user task input
export async function getUserTaskInput(containerID, jwtToken, taskInstanceID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/tasks/${taskInstanceID}/contents/input`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get user task input
export async function getUserTaskOutput(containerID, jwtToken, taskInstanceID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/tasks/${taskInstanceID}/contents/output`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get task info
export async function getTaskInfo(containerID, jwtToken, taskInstanceID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/tasks/${taskInstanceID}?withInputData=true&withOutputData=true`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//start milestone
export async function startMileStone(containerID, jwtToken, caseID, milestone) {
    let result = { success: false };

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/tasks/${milestone}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        data: JSON.stringify({})
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//start milestone
export async function getMileStone(containerID, jwtToken, caseID) {
    let result = { success: false };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/milestones?achievedOnly=false&pageSize=100`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get process instance id of Case
export async function getProcessIDByCase(containerID, jwtToken, caseID) {
    let result = { success: false };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}/processes/instances`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get process variables
export async function getProcessVariables(containerID, jwtToken, processInstanceID) {
    let result = { success: false };

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/processes/instances/${processInstanceID}/variables/instances`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

//get case info
export async function getCaseInfo(containerID, jwtToken, caseID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}?withData=false&withRoles=false&withMilestones=false&withStages=false`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getCaseInfoWithData(containerID, jwtToken, caseID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/instances/${caseID}?withData=true&withRoles=false&withMilestones=false&withStages=false`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        }
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getDocumentProcess(containerID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${containerID}/cases/documentProcess/instances?status=closed&page=0&pageSize=1&sortOrder=false&withData=true`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getDocumentProcessCase(status = "closed") {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/documentProcess/instances?status=${status}&page=0&pageSize=1&sortOrder=false`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getCaseDocumentList(caseID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile/documentList`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function startDocumentProcess() {
    let result = {};

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/startDocument/instances`,
        data: JSON.stringify({}),
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function initGenerateDocumentContent(caseID) {
    let result = {};

    let config = {
        method: 'put',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/tasks/GetContentTask`,
        data: JSON.stringify({}),
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getCaseDocumentContent(caseID, documentFileID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile/${documentFileID}`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function deleteCaseDocumentFile(caseID, documentFileID) {
    let result = {};

    let config = {
        method: 'delete',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile?dataId=${documentFileID}`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function updateCaseFileDocumentID(caseID, data) {
    let result = {};

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile/documentID`,
        data: data,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function postCaseComment(caseID, data) {
    let result = {};

    let config = {
        method: 'post',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/comments`,
        data: JSON.stringify(data),
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 201) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}

export async function getCaseComment(caseID) {
    let result = {};

    let config = {
        method: 'get',
        url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/comments`,
    };

    await fetchInstance.apiRequest(config)
        .then((response) => {
            if (response.status === 200) {
                result.success = true;
                result.data = response.data;
            }
        })
        .catch((error) => {
            result.success = false;
            result.error = error;
        });

    return result;
}