import React, { useState } from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

const ThemeContext = React.createContext({
    mode: "",
    direction: "",
    lang: "",
    toggleMode: () => { },
    toggleDirection: () => { },
    toggleLanguage: () => { },
    theme: null,
});

export const ThemeContextProvider = (props) => {
    const { i18n } = useTranslation();

    const intialMode = localStorage.getItem("mode") || "dark";
    const intialDirection = localStorage.getItem("direction") || "ltr";
    const intialLanguage = localStorage.getItem("language") || "en";

    const [mode, setMode] = useState(intialMode);
    const [direction, setDirection] = useState(intialDirection);
    const [language, setLanguage] = useState(intialLanguage);

    document.getElementsByTagName("body")[0].dir = direction;

    const toggleModeHandler = () => {
        setMode((prevState) => {
            if (prevState === "dark") {
                localStorage.setItem("mode", "light");
                return "light";
            } else {
                localStorage.setItem("mode", "dark");
                return "dark";
            }
        });
    };
    const toggleDirectionHandler = () => {
        setDirection((prevState) => {
            if (prevState === "ltr") {
                localStorage.setItem("direction", "rtl");
                return "rtl";
            } else {
                localStorage.setItem("direction", "ltr");
                return "ltr";
            }
        });
        setLanguage((prevState) => {
            if (prevState === "en") {
                localStorage.setItem("language", "ar");
                return "ar";
            } else {
                localStorage.setItem("language", "en");
                return "en";
            }
        });
    };
    const toggleLanguageHandler = () => {
        setLanguage((prevState) => {
            if (prevState === "en") {
                i18n.changeLanguage("ar");
                localStorage.setItem("language", "ar");
                return "ar";
            } else {
                i18n.changeLanguage("en");
                localStorage.setItem("language", "en");
                return "en";
            }
        });
        setDirection((prevState) => {
            if (prevState === "ltr") {
                localStorage.setItem("direction", "rtl");
                return "rtl";
            } else {
                localStorage.setItem("direction", "ltr");
                return "ltr";
            }
        });
    };

    const theme = React.useMemo(
        () =>
            createTheme({
                direction: direction,
                // vars: {
                //     primary: "#EF14E2",
                //     theme: "#96248E",
                //     secondary: "#96248e",
                // },
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 700,
                        md: 1150,
                        lg: 1670
                    },
                },
                fonts: {
                    ar: "'Cairo', sans-serif",
                    en: "'Rubik', sans-serif",
                },
                palette: {
                    mode,
                    impact: {
                        low: "rgb(70, 161, 27)",
                        medium: "rgb(255, 255, 0)",
                        high: "rgb(255, 192, 0)",
                    },
                    progress: {
                        completed: "rgb(70, 161, 27)",
                        main: "rgb(189, 189, 189)",
                    },
                    rf: {
                        red: "rgb(255, 0, 21)",
                    },
                    color: {

                    },
                    green: {
                        light: '#6bb348',
                        main: '#46a11b',
                        dark: '#317012',
                        contrastText: '#fff',
                    },
                    red: {
                        light: '#ff3343',
                        main: '#ff0015',
                        dark: '#b2000e',
                        contrastText: '#fff',
                    }
                },
                typography: {
                    fontFamily:
                        direction === "ltr"
                            ? "'Rubik', sans-serif"
                            : "'Cairo', sans-serif",
                },
                boxShadow: {
                    shadows:
                        mode === "dark"
                            ? "0px -2px 4px rgb(0 0 0 / 25%)"
                            : "0px 4px 4px rgb(70 161 27 / 25%)",
                },
            }),
        [mode, direction]
    );

    const contextValue = {
        mode: mode,
        direction: direction,
        lang: language,
        toggleMode: toggleModeHandler,
        toggleDirection: toggleDirectionHandler,
        toggleLanguage: toggleLanguageHandler,
        theme: theme,
    };
    // We Use Styled Componet To Pass The Theme
    // We Pass The Theme Through The Context To Access It In Our Components

    return (
        <ThemeContext.Provider value={contextValue}>
            <MuiThemeProvider theme={contextValue.theme}>
                <ThemeProvider theme={contextValue.theme}>
                    {props.children}
                </ThemeProvider>
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
