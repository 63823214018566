import { ButtonBase, Chip, Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useHistory, } from "react-router";

import ExcelJS from "@nbelyh/exceljs";
import { Button } from "../../../../components/UI/Button/Button.styled";
import Heading from "../../../../components/UI/Heading/Heading";
import Loader from "../../../../components/UI/Loader/Loader";
import modalImg from "../../../../images/health-check-modal-img.png";
import { CONTAINER_ID } from "../../../../store/auth-context";
import InfoModal from "../../components/InfoModal/InfoModal";
import { Wrapper } from "./E8Controls.styled";

import { useKeycloak } from "@react-keycloak/web";
import { CardHead, ProgressBlock, ProgressCard } from "./E8.styled";
import ApplicationControlIcon from '@mui/icons-material/VerifiedUser';
import PatchIcon from '@mui/icons-material/Construction';
import ComputerIcon from '@mui/icons-material/Computer';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LockPersonIcon from '@mui/icons-material/PersonOff';
import LockUser from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import BackupIcon from '@mui/icons-material/Backup';
import controlData from './data.json';
import * as fetchInstance from "../../../../utility/fetch-instance";
import { updateTrackerData } from "../../../../utility/tracker.action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

function E8Controls(props) {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const [questions, setQuestions] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [loading, setLoading] = useState(false);
    const [controls, setControls] = useState([]);
    const [caseID, setCaseID] = useState(null);
    const [answers, setAnswers] = useState([]);


    // list of available control milestones
    const dataArr = [
        { "id": 1, "title": "Application whitelisting", "icon": <ApplicationControlIcon style={{ height: "100px", width: "100px" }}></ApplicationControlIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 2, "title": "Patch applications", "icon": <PatchIcon style={{ height: "100px", width: "100px" }}></PatchIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 3, "title": "Patch operating systems", "icon": <ComputerIcon style={{ height: "100px", width: "100px" }}></ComputerIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 4, "title": "Minimise users with administrative privileges", "icon": <SupervisorAccountIcon style={{ height: "100px", width: "100px" }}></SupervisorAccountIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 5, "title": "Disable untrusted Microsoft Office macros", "icon": <LockPersonIcon style={{ height: "100px", width: "100px" }}></LockPersonIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 6, "title": "User application hardening", "icon": <LockUser style={{ height: "100px", width: "100px" }}></LockUser>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 7, "title": "Multi-factor authentication", "icon": <KeyIcon style={{ height: "100px", width: "100px" }}></KeyIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
        { "id": 8, "title": "Routine backup of important information", "icon": <BackupIcon style={{ height: "100px", width: "100px" }}></BackupIcon>, "status": <Chip label="incomplete" color="warning" size="small" variant="outlined" /> },
    ];

    const e8NavHandler = (process, parentCaseID, controlName) => history.push("/it-security/essential-eight/controls/questions", { process, parentCaseID, controlName });
    const e8NavHandlerToLandingPage = () => history.push("/it-security/essential-eight");

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClose2 = () => {
        setModal2Open(false);
    };

    let modalTitle = "Points will be awarded for completing your cyber security Health Check. The Health Check will assess the strengths and weaknesses of your cyber security posture. The areas of your cyber security posture assessment include identification and protection of your assets, detection and response of security incidents, and recovery of business operations.";
    let modalList = ["Maximum time you should spend on this health check activity : 2 Weeks"];

    useEffect(() => {
        setLoading(true);
        const data = window.sessionStorage.getItem('E8_ACTIVE_CASE');
        if (data !== null) setCaseID(JSON.parse(data));
    }, []);

    useEffect(() => {
        if (caseID === null) return;
        getAnswers();
    }, [caseID]);

    useEffect(() => {
        if (caseID === null) return;
        getControlMileStones();
    }, [answers, caseID]);

    useEffect(() => {
        let data = {
            path: window?.location?.pathname,
            meta: {
                title: `ACSC Essential Eight Controls`,
                href: window?.location?.href,
            },
        }
        dispatch(updateTrackerData(data));
    }, [dispatch])

    async function getAnswers() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const dataObj = response.data;
                    let answerList = []
                    Object.keys(dataObj).map((key) => {
                        const index = key.charAt(7);
                        try {
                            answerList.push(parseInt(index) - 1);
                        } catch (error) {

                        }

                    })
                    setAnswers(answerList);

                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    async function getControlMileStones() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/milestones?achievedOnly=false&page=0`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    var controlList = [];
                    response.data["milestones"].forEach(element => {
                        const control = dataArr.find(({ title }) => title === element["milestone-name"]);
                        if (control) {
                            controlList.push(control)
                        }
                    });
                    //TODO: sort controList by id
                    const sortedControlList = [...controlList].sort((a, b) => {
                        return a.id > b.id ? 1 : -1
                    })

                    if (answers.length > 0) {
                        answers.map((answer) => {
                            sortedControlList[answer].status = <Chip label="completed" color="success" size="small" variant="outlined" />
                        })
                    }

                    setControls(sortedControlList);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    async function triggerMileStone(controlName, controlIndex) {
        setLoading(true);

        let controlProcess = `e8Control${controlIndex}Questions`;
        var config = {
            method: 'put',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/tasks/${controlName}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            },
            data: JSON.stringify({})
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                if (response.status == 201 || response.status == 200) {

                    // startMileStone(controlName);
                    getProcessID(controlName, controlProcess);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    async function endMileStone() {

        let config = {
            method: 'post',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}`,
            // data: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        }
        fetchInstance.apiRequest(config).then((response) => {
            if (response.status === 201) {
                e8NavHandlerToLandingPage();
            }
        });
    }

    async function getProcessID(controlName, controlProcess) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/processes/instances?page=0&pageSize=10&sortOrder=true`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    getChildProcess(response.data["process-instance"][0]["process-instance-id"], controlName, controlProcess);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    async function getChildProcess(processID, controlName, controlProcess) {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/processes/instances/${processID}/processes`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    let dataArr = response.data["process-instance"];
                    let controlData = dataArr.find(process => process["process-id"] === controlProcess);
                    window.sessionStorage.setItem("E8_ACTIVE_SUB_PROCESS", JSON.stringify(controlData));
                    window.sessionStorage.setItem("E8_ACTIVE_CONTROL_NAME", JSON.stringify(controlName));
                    window.sessionStorage.setItem("IS_CONTROL", JSON.stringify(true));
                    setLoading(false);
                    e8NavHandler(controlData, caseID, controlName);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    let content;

    if (loading) {
        content = <Loader height="40vh" />;
    }

    async function getCaseData() {
        var config = {
            method: 'get',
            url: `/services/rest/server/containers/${CONTAINER_ID}/cases/instances/${caseID}/caseFile/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${keycloak.token}`
            }
        };

        fetchInstance.apiRequest(config)
            .then(function (response) {
                //check status code
                if (response.status == 200) {
                    const dataObj = response.data;

                    let answerList = []

                    Object.keys(dataObj).map((key) => {
                        let jsonString = dataObj[`${key}`]
                        let object = JSON.parse(jsonString);
                        let controlObject = {
                            "controlID": key,
                            "answer": object
                        }
                        answerList.push(controlObject);
                    })
                    //write data into excel
                    getDocumentUsingExcelJS(answerList);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }


    var docUrl = "https://raw.githubusercontent.com/securemationCode/e8document/main/e8-template.xlsx"
    // var docUrl = "https://oss.sheetjs.com/test_files/formula_stress_test.xlsx"

    async function getDocumentUsingExcelJS(answerList) {
        //load the e8 template file from remote url
        const f = await (await fetch(docUrl)).arrayBuffer();
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(f);
        //open work sheet

        const worksheet = workbook.getWorksheet("PR3");

        //update value for specific cell using data template
        controlData.forEach(control => {
            let answerArr = answerList.find(answer => answer.controlID === control.controlID); // get answerlist for every control
            control["questions"].forEach(question => {
                let answerObj = null;
                if (answerArr) {
                    answerObj = answerArr.answer[question.questionID - 1];
                }

                question["answers"].forEach(answer => {
                    if (answerObj !== null) {
                        let subAnswer = answerObj[`qNum`] === answer.id;
                        //get values/answers per question
                        const val = answerArr.answer[question["questionID"] - 1][`subAnswer${answer["id"]}`];
                        worksheet.getCell(answer["cell"]).value = answer["type"] === "number" ? parseInt(val) / 100 : val;
                    }



                });
            });
        });

        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        let date = dd + '-' + mm + '-' + yyyy;

        //write workbook
        workbook.xlsx.writeBuffer().then(function (e) {
            //excel xlsx file type
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const data = new Blob([e], { type: fileType });
            var fileUrl = URL.createObjectURL(data);

            // Create a link and click it to start the download
            var link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.download = `ACSC-E8-${date}.xlsx`; //download name
            link.click();
        });
    }


    function handleGenerateReport() {
        setLoading(true);
        getCaseData();
    }

    function contentInProgress() {
        return (<>
            <Grid item xs={12} marginBottom="15px">
                <Button $success onClick={(e) => handleGenerateReport()}>Generate Report</Button>
                <Button variant="outlined" onClick={(e) => endMileStone()} style={{ marginLeft: "10px" }}>Complete assessment</Button>
            </Grid>
            {
                getCardList()
            }
        </>);
    }

    function getCardList() {
        let cards = [];
        controls.forEach((element, index) => {
            cards.push(
                <Grid style={{ paddingLeft: "0" }} item lg={3} md={3} sm={6} xs={12} key={element.title + index} onClick={(e) => triggerMileStone(element.title, element.id)}>
                    <ButtonBase style={{ width: "100%", height: "100%" }}>
                        <ProgressCard>
                            <CardHead>{element.title}</CardHead>
                            <ProgressBlock>
                                {element.icon}
                            </ProgressBlock>
                            {element.status}
                            <br />
                            {/* <Button $success onClick={(e) => handleNewInstance()}>Start</Button> */}
                        </ProgressCard>
                    </ButtonBase>

                </Grid>
            )
        })
        return cards;
    }

    if (!loading) {
        content = (
            <Fragment>
                <Grid container spacing={{ xs: 1, md: 2 }}>

                    {
                        contentInProgress()
                    }
                </Grid>
                <InfoModal open={modalOpen} handleClose={handleClose} imgSrc={modalImg} title={modalTitle} list={modalList} />
                <InfoModal open={modal2Open} handleClose={handleClose2} imgSrc={modalImg} title={questions[0]?.helpText} list={[]} />
            </Fragment>
        );
    }

    return (
        <>
            <Helmet>
                <title>ACSC Essential Eight Controls - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                    ACSC Essential Eight Controls
                </Heading>
                {content}
            </Wrapper>
        </>
    );
}

export default E8Controls;
