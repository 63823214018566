
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import QuestionHeading from "../../../../components/Question/QuestionHead/QuestionHeading/QuestionHeading";

import {
    StyledTable,
    StyledTableContainer,
    StyledSmallTable,
    SubQuestionNumber
} from "../../nested/SecurityControlMaturityAssessment/MaturityScores/MaturityScores.styled";


function SectionTableData(props) {
    const { sections, data, scores } = props;
    const sectionData = sections;
    const item = data;

    return (
        sections.map((section, sIndex) => {
            return (
                <div key={`suQNo.${section?.sectionID}`}>
                    <SubQuestionNumber >{section?.sectionID}</SubQuestionNumber>
                    <QuestionHeading >
                        {section.sectionHeading}
                    </QuestionHeading>
                    <StyledTableContainer component={Paper}>
                        <StyledSmallTable key={`aggSec.${section?.sectionID}`}>
                            <TableBody>
                                <TableRow>
                                    <TableCell> Aggregate Score (Section)</TableCell>
                                    <TableCell className="agregateScore" key={section?.sectionID} style={{ color: scores[`average_${section?.sectionID.replace('.', '_')}`] <= 2.5 ? "red" : "green" }}>{scores ? Math.floor(scores[`average_${section?.sectionID.replace('.', '_')}`]) : ""}</TableCell>
                                </TableRow>
                            </TableBody>
                        </StyledSmallTable>
                    </StyledTableContainer>
                    <br />
                    <TableContainer component={Paper}>
                        <StyledTable size="small" key={`tableHeading.${section?.sectionID}`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Section</TableCell>
                                    {/* <TableCell align="left">
                                        Description
                                    </TableCell> */}
                                    <TableCell>Score</TableCell>
                                    {/* <TableCell>Controls with global scope or controls with information asset scope </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {section['controls'].map((control, mIndex) => (
                                    <TableRow key={`trow.${control.controlID}`} >
                                        <TableCell key={`tc.${control.controlID}.1`} component="th" scope="row"
                                        >
                                            {control.controlHeading}
                                        </TableCell>
                                        {/* <TableCell key={`tc.${control?.controlID}.2`} align="left">
                                            -
                                        </TableCell> */}
                                        <TableCell key={`tc.${control.controlID}.3`} align="left" className="score" style={{ color: scores[`score${control.controlID.replaceAll('.', '')}`] <= 2.5 ? "red" : "green" }}>
                                            {scores ? Math.floor(scores[`score${control.controlID.replaceAll('.', '')}`]) : ""}
                                        </TableCell>
                                        {/* <TableCell key={`tc.${control.controlID}.4`} align="left">
                                            -
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </div>
            )
        })
    );

}



export default SectionTableData;
