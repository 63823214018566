import { Fragment } from "react";
import { QuestionBody } from "./Question.styled";
import QuestionHead from "./QuestionHead/QuestionHead";
import SubQuestion from "./SubQuestion/SubQuestion";

const Question = (props) => {
    const { question, index: parentIndex, handleAnswer, ans1Index, onClick, helpText, readOnly } = props;
    return (
        <Fragment>
            <QuestionHead helpText={helpText} question={question} index={parentIndex} onClick={onClick} />
            <QuestionBody>
                {question.subQuestions.map((subQuestion, index) => {
                    return (
                        <SubQuestion
                            readOnly={readOnly}
                            key={subQuestion.qId}
                            question={subQuestion}
                            handleAnswer={handleAnswer}
                            parentIndex={parentIndex}
                            index={index}
                            ans1Index={ans1Index}
                        />
                    );
                })}
            </QuestionBody>
        </Fragment>
    );
};
export default Question;
