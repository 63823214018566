import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";

import EditIcon from '@mui/icons-material/Edit';

import { Button } from "../../../../components/UI/Button/Button.styled";
import RiskContext from "../../../../store/risk-context";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import RiskList from "../RiskList/RiskList";
import { ContentContainer, StyledBox, TabsContainer, CustomTabpanel, CenteredDiv } from "./RiskTabs.styled";
import DeleteRiskCategory from "./DeleteRiskCategory";
import AddNewRiskCategory from "./AddNewRiskCategory";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <CustomTabpanel role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </CustomTabpanel>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const RiskTabs = ({ parentCallback, handleEdit, viewOnly }) => {

    const { riskConsequence } = useSelector((state) => state.riskMatrix);
    const { levels, fields, tabs } = riskConsequence;
    const isViewOnly = viewOnly ?? false;
    const [value, setValue] = React.useState(0);
    const riskCtx = useContext(RiskContext);
    const [isEdit, setIsEdit] = useState(false);
    const [riskCategoryFormDialogOpen, setRiskCategoryFormDialogOpen] = useState(false);
    const [riskCategoryFormMode, setRiskCategoryFormMode] = useState("add");
    const [riskCategoryDataToEdit, setRiskCategoryDataToEdit] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleEditButtonClick() {
        // parentCallback(riskInformation);
        const hideConfrim = riskCtx.hideConfirmHandler;
        setIsEdit(!isEdit);
        handleEdit(!isEdit);
    }

    function handleTabOnClick() {
        setIsEdit(false);
        handleEdit(false);
    }

    const toggleRiskCategoryFormDialog = (e, mode = "add", category = null) => {
        e?.preventDefault();
        e?.stopPropagation();
        setRiskCategoryFormDialogOpen((prev) => !prev);
        setRiskCategoryFormMode(mode);
        setRiskCategoryDataToEdit(category);
    }

    return (
        <>
            <div>
                <ContentContainer noPad>
                    <StyledBox>
                        <TabsContainer value={value} onChange={handleChange} aria-label="Risk Tabs" variant="scrollable" allowScrollButtonsMobile scrollButtons="auto">
                            {riskConsequence.tabs.map((tab, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        label={
                                            isViewOnly ? tab.name : <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                {tab.name}
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        toggleRiskCategoryFormDialog(e, "edit", { name: tab.name, index: index });
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        }
                                        {...a11yProps(index)}
                                        onClick={handleTabOnClick}
                                    />
                                )
                            })}
                        </TabsContainer>

                        {
                            isViewOnly ? "" : <>
                                {
                                    riskConsequence.tabs?.length < 10 ? <Box sx={{ p: "24px 24px 0", display: "flex", justifyContent: "flex-end" }}>
                                        <Button $success onClick={(e) => toggleRiskCategoryFormDialog(e)}>Add New Impact Definition</Button>
                                    </Box> : null
                                }
                            </>
                        }

                        {
                            isViewOnly ? "" : <RecHeading maxWidth padding >
                                Change the impact definition to match your business requirements or accept the following:
                            </RecHeading>
                        }

                        {riskConsequence.tabs.map((tab, index) => {
                            return (
                                <TabPanel value={value} index={index} key={index} >
                                    <RiskList
                                        bigfont
                                        smPad
                                        bgWidth
                                        smWidth
                                        mdWidth
                                        mZero
                                        tabs={tabs}
                                        tabNum={index + 1}
                                        levels={levels}
                                        fields={fields}
                                        isEdit={isEdit}
                                        parentCallback={parentCallback}
                                        style={{ padding: 0 }}
                                    />
                                </TabPanel>
                            );
                        })}
                    </StyledBox>

                    <CenteredDiv style={{ marginTop: "16px" }}>
                        {/*
                    TODO: to be able to delete tabs in risk consequence
                     <Button upper="true" onClick={handleDeleteButtonClick}>
                        DELETE
                    </Button> */}
                        {
                            isViewOnly ? "" : <>
                                <DeleteRiskCategory value={value} />
                                <Button $edit onClick={handleEditButtonClick}>
                                    {
                                        !isEdit ? "Edit" : "Save"
                                    }
                                </Button>
                            </>
                        }

                    </CenteredDiv>
                </ContentContainer>
            </div>

            <AddNewRiskCategory
                mode={riskCategoryFormMode}
                open={riskCategoryFormDialogOpen}
                toggle={toggleRiskCategoryFormDialog}
                dataToEdit={riskCategoryDataToEdit}
            />
        </>
    );
};

// const RiskTabs = ({ riskConsequence, parentCallback, handleEdit, viewOnly }) => {

//     const { levels, fields, tabs } = riskConsequence;
//     const isViewOnly = viewOnly ?? false;
//     const [value, setValue] = React.useState(0);
//     const riskCtx = useContext(RiskContext);
//     const [isEdit, setIsEdit] = useState(false);
//     const [isDelete, setIsDelete] = useState(false);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     function handleEditButtonClick() {
//         // parentCallback(riskInformation);
//         const hideConfrim = riskCtx.hideConfirmHandler;
//         setIsEdit(!isEdit);
//         handleEdit(!isEdit);
//     }

//     function handleDeleteButtonClick() {

//         riskConsequence.tabs.splice(value, 1);
//         const itemsToRemove = riskConsequence.fields.filter(v => v["name"].startsWith(`consCat${value + 1}`));
//         const removeItems = (array, itemsToRemove) => {
//             return array.filter(v => {
//                 return !itemsToRemove.includes(v);
//             });
//         };
//         // riskConsequence.fields = removeItems(riskConsequence.fields, itemsToRemove);
//         setIsDelete(!isDelete);
//     }

//     function handleTabOnClick() {
//         setIsEdit(false);
//         handleEdit(false);
//     }

//     return (
//         <div>
//             <ContentContainer noPad>
//                 <StyledBox>
//                     <TabsContainer value={value} onChange={handleChange} aria-label="Risk Tabs" variant="scrollable" allowScrollButtonsMobile scrollButtons="auto">
//                         {riskConsequence.tabs.map((tab, index) => {
//                             return (
//                                 <Tab
//                                     key={index}
//                                     label={tab.name}
//                                     {...a11yProps(index)}
//                                     onClick={handleTabOnClick}
//                                 />
//                             )
//                         })}
//                     </TabsContainer>

//                     {
//                         isViewOnly ? "" : <RecHeading maxWidth padding>
//                             Change the impact definition to match your business requirements or accept the following:
//                         </RecHeading>
//                     }

//                     {riskConsequence.tabs.map((tab, index) => {
//                         return (
//                             <TabPanel value={value} index={index} key={index} >
//                                 <RiskList
//                                     bigfont
//                                     smPad
//                                     bgWidth
//                                     smWidth
//                                     mdWidth
//                                     mZero
//                                     tabs={tabs}
//                                     tabNum={index + 1}
//                                     levels={levels}
//                                     fields={fields}
//                                     isEdit={isEdit}
//                                     parentCallback={parentCallback}
//                                     style={{padding:0}}
//                                 />
//                             </TabPanel>
//                         );
//                     })}
//                 </StyledBox>

//                 <CenteredDiv>
//                     {/*
//                     TODO: to be able to delete tabs in risk consequence
//                      <Button upper="true" onClick={handleDeleteButtonClick}>
//                         DELETE
//                     </Button> */}
//                     {
//                         isViewOnly ? "" : <Button $edit onClick={handleEditButtonClick}>
//                         {
//                             !isEdit ? "Edit" : "Save"
//                         }
//                     </Button>
//                     }

//                 </CenteredDiv>
//             </ContentContainer>
//         </div>
//     );
// };

export default RiskTabs;

