import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Button } from "../../../../components/UI/Button/Button.styled";
import { RecHeading } from "../RecommendationItem/RecommendationItem.styled";
import { ContentContainer, StyledBox, TabsContainer, CustomTabpanel, CenteredDiv } from "./RiskTabs.styled";
import RiskListV2 from "../RiskList/RiskListV2";
import AddNewRiskCategory from "./AddNewRiskCategory";
import DeleteRiskCategory from "./DeleteRiskCategory";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <CustomTabpanel role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </CustomTabpanel>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const RiskTabsV2 = ({ parentCallback, handleEdit, viewOnly }) => {

    const { riskConsequence } = useSelector((state) => state.riskMatrix);
    const { tabs } = riskConsequence;
    const isViewOnly = viewOnly ?? false;
    const [value, setValue] = React.useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleEditButtonClick() {
        setIsEdit(!isEdit);
        handleEdit(!isEdit);
    }

    function handleTabOnClick() {
        setIsEdit(false);
        handleEdit(false);
    }

    return (<>
        <div>
            <ContentContainer noPad>
                <StyledBox>
                    <TabsContainer value={value} onChange={handleChange} aria-label="Risk Tabs" variant="scrollable" allowScrollButtonsMobile scrollButtons="auto">
                        {riskConsequence.tabs.map((tab, index) => {
                            return (
                                <Tab
                                    key={index}
                                    label={tab.name}
                                    {...a11yProps(index)}
                                    onClick={handleTabOnClick}
                                />
                            )
                        })}
                    </TabsContainer>

                    {
                        isViewOnly ? "" : <AddNewRiskCategory />
                    }

                    {
                        isViewOnly ? "" : <RecHeading maxWidth padding>
                            Change the impact definition to match your business requirements or accept the following:
                        </RecHeading>
                    }

                    {riskConsequence.tabs.map((tab, index) => {
                        return (
                            <TabPanel value={value} index={index} key={index} >
                                <RiskListV2
                                    bigfont
                                    smPad
                                    bgWidth
                                    smWidth
                                    mdWidth
                                    mZero
                                    tabs={tabs}
                                    tabNum={index + 1}
                                    isEdit={isEdit}
                                    parentCallback={parentCallback}
                                    style={{ padding: 0 }}
                                />
                            </TabPanel>
                        );
                    })}
                </StyledBox>

                <CenteredDiv>
                    {
                        isViewOnly ? "" : <>
                            <DeleteRiskCategory value={value} />
                            <Button $edit onClick={handleEditButtonClick}>
                                {
                                    !isEdit ? "Edit" : "Save"
                                }
                            </Button>
                        </>
                    }

                </CenteredDiv>
            </ContentContainer>
        </div>
    </>
    );
};

export default RiskTabsV2;

