import { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ActionContainer, CardHead, DotSign, LegendContainer, ProgressCard, ProgressCardContent, ProgressCardContentTop, ProgressContainer, ProgressWrapper, StatsText, TextBlock } from '../Information.styled';
import ThemeContext from '../../../../../store/theme-context';
import { IntroRiskAssessmentData } from '../../../../../shared/intro-data';

function RiskSummaryCard({ isIntroActive, hasTakenTourBefore }) {

    const { theme } = useContext(ThemeContext);
    const history = useHistory();
    const riskData = useSelector((state) => state.data.graphData);

    const [data, setData] = useState([
        {
            label: 'Extreme Risks',
            value: 0,
            color: '#ff0000',
            key: 'Extreme',
        },
        {
            label: 'High Risks',
            value: 0,
            color: '#ffc000',
            key: 'High',
        },
        {
            label: 'Medium Risks',
            value: 0,
            color: '#ffff00',
            key: 'Medium',
        },
        {
            label: 'Low Risks',
            value: 0,
            color: '#92d050',
            key: 'Low',
        },
    ])

    const [centerLabelTitle, setCenterLabelTitle] = useState('0');

    // useEffect(() => {
    //     if (isIntroActive && !hasTakenTourBefore) return;
    //     if (riskData?.length > 0) {
    //         const riskCounts = riskData.reduce((acc, node) => {
    //             const count = acc[node.residualRiskRating] || 0;
    //             return { ...acc, [node.residualRiskRating]: count + 1 };
    //         }, { Extreme: 0, High: 0, Medium: 0, Low: 0 });
    //         setData((prev) => prev.map((p) => ({ ...p, value: riskCounts[p.key] })))
    //     }
    // }, [hasTakenTourBefore, isIntroActive, riskData])

    useEffect(() => {
        if (isIntroActive && !hasTakenTourBefore) {
            setData([...IntroRiskAssessmentData])
        } else {
            const riskCounts = (riskData || []).reduce((acc, node) => {
                const count = acc[node.residualRiskRating] || 0;
                return { ...acc, [node.residualRiskRating]: count + 1 };
            }, { Extreme: 0, High: 0, Medium: 0, Low: 0 });
            setData((prev) => prev.map((p) => ({ ...p, value: riskCounts[p.key] })))
        }
    }, [hasTakenTourBefore, isIntroActive, riskData])

    useEffect(() => {
        setCenterLabelTitle(data.reduce((a, b) => a + b.value, 0));
    }, [data])

    const graphData = data.some((d) => d?.value > 0) ? {
        labels: data.map((d) => d.label),
        datasets: [
            {
                data: data.map((d) => d.value),
                backgroundColor: data.map((d) => d.color), // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ],
    } : {
        labels: [data.map((d) => d.label).join(", ")],
        datasets: [
            {
                data: [100],
                backgroundColor: ["rgb(189, 189, 189)"], // Use your provided colors
                borderWidth: 1, // Optional border width
                borderRadius: 0, // Optional border radius
            },
        ]
    };

    const options = {
        responsive: true,
        cutout: '85%', // Inner radius of the Doughnut chart
        borderColor: theme?.palette?.mode === 'dark' ? 'rgb(31, 33, 40)' : '#fff',
        plugins: {
            datalabels: {
                display: false, // Hide data labels
            },
            centerLabelPlugin: {
                title: centerLabelTitle,
                titleColor: theme?.palette?.text?.primary
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const dataIndex = context.dataIndex;
                        const tooltipValue = data[dataIndex].value > 0 ? data[dataIndex].tooltipValue : 0;
                        return `${tooltipValue}`;
                    },
                },
            },
        },
    };

    return (
        <ProgressCard>
            <ProgressCardContent>
                <ProgressCardContentTop>
                    <CardHead>Risk Assessment</CardHead>
                    <ProgressContainer>
                        <ProgressWrapper>
                            <Doughnut data={graphData} options={options} />
                        </ProgressWrapper>
                    </ProgressContainer>
                    <LegendContainer>
                        {
                            data.map((d, index) => (
                                <TextBlock key={d.label + index}>
                                    <DotSign style={{ backgroundColor: d.color }} />
                                    <StatsText>{d.label} ({d.value})</StatsText>
                                </TextBlock>
                            ))
                        }
                    </LegendContainer>
                </ProgressCardContentTop>
                <ActionContainer>
                    <Button color='success' variant='outlined' fullWidth size='large' borderWidth={"2px"} onClick={(e) => history.push('/it-security/risk-assessment')}>
                        Risk Treatment
                    </Button>
                </ActionContainer>
            </ProgressCardContent>
        </ProgressCard>
    );
}

export default RiskSummaryCard;   