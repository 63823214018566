import { useState } from "react";

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from "@mui/material/Box";

import Heading from "../../../../components/UI/Heading/Heading";
import { Wrapper } from "./DFDLandingPage.style";
import DFDFlow from "../../../../components/ReactFlowDFD/DFDFlow";

function DFDLandingPage(props) {

    const [modalOpen, setModalOpen] = useState(false);

    const handleClick = () => {
        setModalOpen(true);
    };

    const isSmallScreen = useMediaQuery('(max-width:768px)');

    return (
        <Wrapper>
            <Heading marginMd="32px 0" marginSm="24px 18px" handleClick={handleClick}>
                Threat Model
            </Heading>
            {/* <Box sx={{ height: 'calc(100vh - 96px)', overflow: 'auto' }}> */}
            <Box sx={{ height: 'calc(100vh - 198px)', overflow: 'auto' }}>
                <DFDFlow readOnly={isSmallScreen} />
            </Box>
        </Wrapper>
    );
}

export default DFDLandingPage