import { useState } from "react";
import Heading from "../../../components/UI/Heading/Heading";
import { Wrapper } from "../RiskAssessment/RiskAssessment.styled";
import TermsConditionsCard from "./components/TermsConditionsCard";
import { Helmet } from "react-helmet-async";
import { FooterLink } from "../../Auth/components/Footer/Footer.styled";

let data = (openPrivacyPolicy) => {
    return [
        {
            "mainID": "1.0",
            "mainHeading": "Introduction",
            "mainDescription": <>To use and access CisoZen, whether you are the CisoZen organisation manager (first person to sign-up on behalf of an organisation), are part of an organisation using CisoZen or are creating an organisation, you must accept (1) the CisoZen Terms of Service. Please read each of the sections in this document carefully. This document is known as the Terms They establish what you can expect from us as you use our services, and what we expect from you. Although it's not a part of these Terms, we encourage you to read our <FooterLink to="/" onClick={openPrivacyPolicy} style={{ textDecoration: 'underline' }}>Privacy & Policy</FooterLink> to better understand how you can update, manage, export and delete your information.</>,
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "2.0",
            "mainHeading": "General description of CisoZen",
            "mainDescription": "CisoZen is made available by CisoZen to provide you with a Information Security Management System (ISMS) platform. CisoZen features may include paid subscription plans for certain CisoZen offline cybersecurity services such as: Penetration Testing, Vulnerability management, Security Strategy, Security Architecture, Threat and Risk Assessment & Security Audits, Virtual CISO, Secure By Design, Zero Trust Framework, Operational Security Activities (Firewalls, Endpoint protection, Cloud security etc.), ISMS (ISO27001 and ISO27002) Implementation and OT Security.",
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "3.0",
            "mainHeading": "Paid accounts – Payment, subscription and refunds",
            "mainDescription": "",
            "subSections": [
                {
                    "sectionID": "3.1",
                    "sectionHeading": "Payments.",
                    "sectionDescription": "Only CisoZen organisation managers may purchase, upgrade, downgrade or cancel a CisoZen subscription. CisoZen accepts payment through any form of payment indicated prior to purchase. If you need to change the CisoZen Organisation Manager – please contact us."
                },
                {
                    "sectionID": "3.2",
                    "sectionHeading": "Subscription cancellations.",
                    "sectionDescription": "CisoZen will automatically take payment from the date that you sign up for a CisoZen subscription, and your subscription will renew automatically until it is cancelled. You may cancel at any time. If you cancel your subscription, you will retain access to CisoZen and the remaining days of your existing subscription. If, in addition, you select to delete your CisoZen account, you agree that you will lose access to CisoZen offline cybersecurity services and online functionalities immediately, without a refund for the remaining term of your existing subscription. When a CisoZen account is deleted, all the organisation data is also completely deleted within seven days. If you prefer to retain CisoZen online functionalities and the remaining days of your existing subscription for the term of your subscription, please cancel your subscription rather than deleting the CisoZen organisation."
                },
                {
                    "sectionID": "3.3",
                    "sectionHeading": "Right of withdrawal.",
                    "sectionDescription": "Any withdrawal request will follow the “2.2 Subscription cancellations” policy."
                },
                {
                    "sectionID": "3.4",
                    "sectionHeading": "Refunds.",
                    "sectionDescription": "No refunds or partial billing periods are available, except as required by applicable law."
                },
                {
                    "sectionID": "3.5",
                    "sectionHeading": "Changes to pricing.",
                    "sectionDescription": "We may change the CisoZen subscription price(s) in effect, but will give you prior notice of these changes. These changes will apply after your current payment period is complete, when the next payment is due from you after the notice. We will give you at least 30 days' prior notice of a price increase before you are charged. If you are given less than 30 days' prior notice, the change will not apply until the payment after the next payment is due. If you do not wish to continue with CisoZen at the new price, you may cancel or downgrade at any time from within your CisoZen subscription settings. Your cancellation or downgrade will apply to the next billing period after the current service term, unless otherwise set out in the applicable payment platform terms. Where the price increases and consent is required, your subscription may be cancelled unless you agree to the new price. If your subscription is cancelled and you later decide to resubscribe, you will be charged at the then-current subscription rate."
                }
            ],
            "isExpanded": true
        },
        {
            "mainID": "4.0",
            "mainHeading": "Customer support",
            "mainDescription": "CisoZen provides you with access to customer support across the Customer Support email for general enquires, and a dedicated Cybersecurity Support team channel for organisations with days available in its subscription. In the event that Customer Support is unable to assist you with your support request, we may transfer or redirect you to the Customer Support service of the CisoZen feature in question. If your CisoZen subscription is cancelled or suspended, your unresolved Customer Support issues may also be suspended and you may need to submit a new enquiry once you have reinstated your subscription.",
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "5.0",
            "mainHeading": "Limited Subscription Benefits",
            "mainDescription": "CisoZen may provide you with content, products and services that are discounted or at no charge ('Limited Subscription Benefits'). Limited Subscription Benefits may be limited by country, supply, duration, membership tier or other factors, and not all Limited Subscription Benefits will be available to all CisoZen subscribers. Some Limited Subscription Benefits may only be redeemable by the CisoZen plan manager, and some Limited Subscription Benefits may be redeemable by members of your organisation or only by the first organisation member activating the redemption. Some Limited Subscription Benefits are not redeemable by CisoZen free accounts. Other eligibility criteria may also apply. We may work with third parties to provide you with their services or content as Limited Subscription Benefits through CisoZen. In order to redeem a Limited Subscription Benefit provided by a third party, you acknowledge that CisoZen may provide the third party with any personal information necessary to process your redemption, in accordance with the CisoZen Privacy Policy. Your use of any third-party Limited Subscription Benefits may be governed by such third party's terms of use, licence agreement, privacy policy or other such agreement.",
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "6.0",
            "mainHeading": "Privacy",
            "mainDescription": "CisoZen collects and uses information provided by you in order to provide you with CisoZen as described in these Terms, in accordance with the CisoZen Privacy Policy. We may collect and use information about your use of CisoZen in order to provide the CisoZen features, offline services, process your transactions or to maintain and improve CisoZen. We may also use information about your use of other CisoZen services in order to improve CisoZen, to provide you with benefits or to market CisoZen. You can control the collection and use of your CisoZen activity by emailing our customer service team. We may share certain information about you with third parties, as necessary to provide CisoZen, including to determine your qualification for, or your redemption of, third-party Limited Subscription. In connection with your use of CisoZen, we may send you service announcements, administrative messages and other information. We may also send you emails and application notifications associated with your Limited Subscription Benefits. You may opt out of some of those communications.",
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "7.0",
            "mainHeading": "Changes",
            "mainDescription": "We reserve the right to make changes to CisoZen, and it may be revised to provide more, or different, features. You agree that your subscription to CisoZen is for CisoZen in its form at the time of subscription. As set out in Section 2 above, we may also, from time to time, offer different terms and tiers for CisoZen, and the subscription fee for such terms or tiers may vary.",
            "subSections": [],
            "isExpanded": true
        },
        {
            "mainID": "8.0",
            "mainHeading": "Termination",
            "mainDescription": "CisoZen may stop providing CisoZen to you at any time, including for the breach of these Terms. CisoZen reserves the right to suspend or terminate CisoZen at any time, upon reasonable notice to you.",
            "subSections": [],
            "isExpanded": true
        },
    ]
}


function TermsConditions(props) {
    const { openPrivacyPolicy } = props;
    const [policyList, setPolicyList] = useState(data);

    const handleExpandClick = (index) => {
        let newPolicy = [...policyList];
        newPolicy[index].isExpanded = !newPolicy[index].isExpanded;
        setPolicyList(newPolicy);
    };
    return (
        <>
            <Helmet>
                <title>Terms & Conditions - CisoZen</title>
            </Helmet>
            <Wrapper>
                <Heading marginMd="32px 0" marginSm="24px 18px">
                    Terms & Conditions
                </Heading>
                <TermsConditionsCard data={data(openPrivacyPolicy)} handleExpandClick={handleExpandClick} />
            </Wrapper>
        </>
    )
}

export default TermsConditions;