import { Box, Tabs } from "@mui/material";
import styled from "styled-components";

export const ContentContainer = styled.div`
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};

    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    border-radius: 16px;
    font-family: "Rubik", sans-serif;
    overflow: hidden;
    padding: 32px 42px 0;

    padding-top: ${({ sm }) => (sm ? "18px" : "")};
    padding: ${({ noPad }) => (noPad ? "0 0 0 0" : "")};
`;

export const TabsContainer = styled(Tabs)`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#000")};
    box-sizing: border-box;

    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
    }

    & .MuiTab-root {
        color: ${({ theme }) => (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
        font-family: "Rubik", sans-serif;
        font-weight: 500;
        font-size: 15px;
        flex-grow: 1;

        @media (max-width: 1199.98px) {
            width: auto;
        }

        &:not(:last-of-type) {
            border-right: ${({ theme }) => (theme.palette.mode === "light" ? "" : "0.5px solid rgba(70, 161, 27, 0.24)")};
        }
    }

    button.Mui-selected {
        color: #3a8d1c;
    }

    span.MuiTabs-indicator {
        background-color: #3a8d1c;
    }
`;

export const StyledBox = styled(Box)`
    .MuiBox-root {

        @media (max-width: 599.98px) {
            padding-top: 16px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .MuiTabs-flexContainer {
        @media screen and (max-width: 991px) {
            max-width: 100px;
        }
    }
`;

export const CenteredDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ acMargin }) => (acMargin ? "23px" : "16px")};

    @media (max-width: 599.98px) {
        margin-bottom: 16px;
    }
`;

export const CustomTabpanel = styled.div`
    overflow: auto;
`;

