import Keycloak from "keycloak-js";

const keycloak = process.env.REACT_APP_DEPLOYMENT === "PROD" ? new Keycloak({
    url: "https://yasawa.cisozen.com.au",
    realm: "cz-PROD",
    clientId: "cz-frontend-client",
}) : new Keycloak({
    url: "https://monuriki.cisozen.com.au",
    realm: "cz-DEV",
    clientId: "cz-frontend-client",
});

export default keycloak;