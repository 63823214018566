import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelectQuestion(props) {
  const { question, defaultValue, handleCheckChange } = props;
  const [personName, setPersonName] = React.useState([]);

  React.useEffect(() => {
    try {
      if (defaultValue !== "") {
        setPersonName(defaultValue.split(","))
      }
    } catch (e) {
      setPersonName(defaultValue);
    }
  }, [defaultValue])

  const handleChange = (event) => {
    const value = event.target.value;

    setPersonName(
      value
    );
    handleCheckChange(value);
  };

  return (
    <div>
      <FormControl sx={{ marginRight: 1, width: "100%" }}>
        <InputLabel shrink id={question.qNum + "-label"} required={question.required}>
          {`${question.qNum}. ${question.qHeading}`}
        </InputLabel>
        <Select
          multiple
          notched
          required={question.required}
          labelId={question.qNum + "-label"}
          id={question.qNum + "-checkbox"}
          value={personName}
          onChange={handleChange}
          input={
            <OutlinedInput
              label={`${question.qNum}. ${question.qHeading}`}
            />
          }
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {question.qAvailableAnswers.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}