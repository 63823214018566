import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

export const ProgressWrapper = styled(Box)`
    position: relative;
    width: 100%;
`;

export const CustomLinearProgress = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        position: relative;
        z-index: 4;
    }
    & .MuiLinearProgress-bar {
        background-color: #62d82b;
    }
`;

export const ProgressValue = styled.div`
    position: absolute;
    bottom: 20px;

    left: 10px;

    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.palette.text.primary};
`;
