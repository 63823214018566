import { getCase } from "../pages/Dashboard/nested/TRA/TRAApi";
import { getNumberFromCaseID } from "../shared/utility";
import { CONTAINER_ID, IARadvanceProcess } from "../store/auth-context";
import { cacheData, getCachedData } from './indexdb';
import { beautifyUniqueID } from "./utility";

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const toggleAllowFetch = (allow) => {
  return { type: 'TOGGLE_INFORMATION_ASSET_REGISTER_ALLOW_FETCH', payload: allow };
};

export const toggleLoading = (loading) => {
  return { type: 'TOGGLE_INFORMATION_ASSET_REGISTER_LOADING', payload: loading };
};

export const toggleFetchingBackgroundData = (isFetching) => {
  return { type: 'TOGGLE_INFORMATION_ASSET_REGISTER_FETCHING_BACKGROUND_DATA', payload: isFetching };
};

function extractInformationAssetRegisterDataFromCaseFile(caseFile) {
  let newInformationAssetRegister = {};

  let assetInfo = caseFile['case-file']['case-data']['assetInfo'] && JSON.parse(caseFile['case-file']['case-data']['assetInfo']);
  if (assetInfo?.assetName !== undefined) {
    assetInfo["c"] = caseFile['case-file']['case-data']['confidentiality'] || "";
    assetInfo["i"] = caseFile['case-file']['case-data']['integrity'] || "";
    assetInfo["a"] = caseFile['case-file']['case-data']['availability'] || "";
    newInformationAssetRegister = assetInfo;
  }
  newInformationAssetRegister["id"] = caseFile['case-id'];
  newInformationAssetRegister["caseID"] = caseFile['case-id'];
  newInformationAssetRegister["assetID"] = beautifyUniqueID(caseFile['case-id']);
  newInformationAssetRegister["status"] = caseFile['case-status'];
  newInformationAssetRegister["case-data"] = caseFile['case-file']['case-data'];
  newInformationAssetRegister["businessCriticality"] = caseFile['case-file']['case-data']['businessCriticality'];
  newInformationAssetRegister["informationProtectiveMarkings"] = caseFile['case-file']['case-data']['informationProtectiveMarkings'];

  newInformationAssetRegister["date"] = caseFile["case-started-at"] || 0;
  let cDate = new Date(caseFile["case-started-at"]);
  newInformationAssetRegister["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return newInformationAssetRegister;
}

export const getInformationAssetRegisterData = async (caseFile) => {
  let newInformationAssetRegister = extractInformationAssetRegisterDataFromCaseFile(caseFile);
  newInformationAssetRegister.caseID = caseFile["case-id"];
  return newInformationAssetRegister;
}

export const fetchData = (keycloak, pageParam) => {
  return async (dispatch, getState) => {
    const { allowFetch } = getState().informationAssetRegisters; // Get the allowFetch value from the state

    // Enable loading
    dispatch(toggleLoading(true));

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-informationAssetRegisters');

      if (cachedData && allowFetch === false) {
        let data = cachedData.filter((asset) => asset["assetName"]);
        dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_SUCCESS', payload: { data: data } });
      } else {

        // Enable the background data fetching started
        dispatch(toggleFetchingBackgroundData(true));

        let informationAssetRegisters = [];
        const closedCases = await getCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, 'closed', 10, pageParam);
        const openCases = await getCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, 'open', 10, pageParam);

        const closedData = closedCases.success ? closedCases.data?.instances : [];
        const openedData = openCases.success ? openCases.data?.instances : [];
        const informationAssetRegisterData = [...closedData, ...openedData];

        if (informationAssetRegisterData?.length > 0) {
          informationAssetRegisterData.forEach((data, index) => {
            let asset = {}
            let assetInfo = data['case-file']['case-data']['assetInfo'] && JSON.parse(data['case-file']['case-data']['assetInfo']);
            if (assetInfo?.assetName !== undefined) {
              assetInfo["c"] = data['case-file']['case-data']['confidentiality'] || "";
              assetInfo["i"] = data['case-file']['case-data']['integrity'] || "";
              assetInfo["a"] = data['case-file']['case-data']['availability'] || "";
              asset = assetInfo;
            }
            asset["id"] = data['case-id'];
            asset["caseID"] = data['case-id'];
            asset["assetID"] = beautifyUniqueID(data['case-id']);
            asset["status"] = data['case-status'];
            asset["case-data"] = data['case-file']['case-data'];
            asset["businessCriticality"] = data['case-file']['case-data']['businessCriticality'];
            asset["informationProtectiveMarkings"] = data['case-file']['case-data']['informationProtectiveMarkings'];

            asset["date"] = data["case-started-at"] || 0;
            let cDate = new Date(data["case-started-at"]);
            asset["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });

            if (asset["assetName"]) {
              informationAssetRegisters.push(asset);
            }
          })

          informationAssetRegisters.sort((a, b) => b["date"] - a["date"]);
        }

        dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_SUCCESS', payload: { data: informationAssetRegisters }, });

        let paginationTrackerPayload = {
          lastPageFetched: {
            open: 0,
            closed: 0,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          }
        }
        dispatch({
          type: 'INFORMATION_ASSET_REGISTER_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-informationAssetRegisters', informationAssetRegisters);
        dispatch(toggleAllowFetch(false));

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_INFORMATION_ASSET_REGISTER_FETCH_COUNTER' });
        }

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      console.log('DEBUG fetchData = ', error);
      dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_ERROR', payload: error.message });
    }
  };
};

export const fetchNextPageData = (keycloak) => {
  return async (dispatch, getState) => {
    const { lastPageFetched, hasMore } = getState().informationAssetRegisters; // Get the allowFetch value from the state

    try {
      const cachedData = await getCachedData(keycloak.token, 'cached-informationAssetRegisters');

      let nextClosedPage = lastPageFetched.closed + 1;
      let nextOpenPage = lastPageFetched.open + 1;

      let informationAssetRegisters = [];
      const closedCases = hasMore.closed ? await getCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, 'closed', 10, nextClosedPage) : [];
      const openCases = hasMore.open ? await getCase(CONTAINER_ID, keycloak.token, IARadvanceProcess, 'open', 10, nextOpenPage) : [];

      const closedData = closedCases.success ? closedCases.data?.instances : [];
      const openedData = openCases.success ? openCases.data?.instances : [];
      const informationAssetRegisterData = [...closedData, ...openedData];

      if (informationAssetRegisterData) {
        informationAssetRegisterData.forEach((data, index) => {
          let asset = {}
          let assetInfo = data['case-file']['case-data']['assetInfo'] && JSON.parse(data['case-file']['case-data']['assetInfo']);
          if (assetInfo?.assetName !== undefined) {
            assetInfo["c"] = data['case-file']['case-data']['confidentiality'] || "";
            assetInfo["i"] = data['case-file']['case-data']['integrity'] || "";
            assetInfo["a"] = data['case-file']['case-data']['availability'] || "";
            asset = assetInfo;
          }
          asset["id"] = data['case-id'];
          asset["caseID"] = data['case-id'];
          asset["assetID"] = beautifyUniqueID(data['case-id']);
          asset["status"] = data['case-status'];
          asset["case-data"] = data['case-file']['case-data'];
          asset["businessCriticality"] = data['case-file']['case-data']['businessCriticality'];
          asset["informationProtectiveMarkings"] = data['case-file']['case-data']['informationProtectiveMarkings'];

          asset["date"] = data["case-started-at"] || 0;
          let cDate = new Date(data["case-started-at"]);
          asset["createdDate"] = cDate.toLocaleDateString() + ", " + cDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

          if (asset["assetName"]) {
            informationAssetRegisters.push(asset);
          }
        })

        if (cachedData?.length > 0) {
          informationAssetRegisters = [...cachedData, ...informationAssetRegisters];
        }

        informationAssetRegisters.sort((a, b) => b["date"] - a["date"]);

        dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_SUCCESS', payload: { data: informationAssetRegisters }, });
        let paginationTrackerPayload = {
          lastPageFetched: {
            open: nextOpenPage,
            closed: nextClosedPage,
          },
          hasMore: {
            open: openedData.length === 10,
            closed: closedData.length === 10,
          },
        }
        dispatch({
          type: 'INFORMATION_ASSET_REGISTER_PAGINATION_TRACKER',
          payload: paginationTrackerPayload
        });

        // Check if cases does not have more data than stop background fetch
        if (!paginationTrackerPayload.hasMore.open && !paginationTrackerPayload.hasMore.closed) {
          dispatch(toggleFetchingBackgroundData(false));

          // Increment fetchCounter state when all data is fetched
          dispatch({ type: 'INCREMENT_INFORMATION_ASSET_REGISTER_FETCH_COUNTER' });
        }

        // Cache the fetched data
        cacheData(keycloak.token, 'cached-informationAssetRegisters', informationAssetRegisters);
        dispatch(toggleAllowFetch(false));

        if (paginationTrackerPayload.hasMore.open || paginationTrackerPayload.hasMore.closed) {
          // Fetch next page data
          delay(500).then(() => {
            dispatch(fetchNextPageData(keycloak));
          });
        }

      }
    } catch (error) {
      dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_ERROR', payload: error.message });
    }
  };
};

export const removeEmptyCase = (keycloak) => {
  return async (dispatch, getState) => {
    try {
      let cachedData = await getCachedData(keycloak.token, 'cached-informationAssetRegisters');
      if (cachedData?.length > 0) {
        cachedData = cachedData.filter((c) => c["assetName"]);
        cachedData = cachedData.map((c) => ({ ...c }));

        cachedData.sort((a, b) => b["date"] - a["date"]);
      }

      dispatch({ type: 'FETCH_INFORMATION_ASSET_REGISTER_DATA_SUCCESS', payload: { data: cachedData }, });

      // Cache the fetched data
      cacheData(keycloak.token, 'cached-informationAssetRegisters', cachedData);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  }
}