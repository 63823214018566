import styled, { css } from "styled-components";
import { AppBar, Toolbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";

export const HeaderWrapper = styled.div`
    height: 96px;
`;

export const CustomAppBar = styled(AppBar)`
    &.MuiAppBar-root {
        justify-content: center;
        height: 96px;
        width: calc(100% - ${(props) => props.$drawerWidth}px);
        background: ${({ theme }) =>
        theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.common.black};
        box-shadow: ${({ theme }) => theme.boxShadow.shadows};
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            width: 100%;
        }
    }
`;

export const CustomToolbar = styled(Toolbar)`
    &.MuiToolbar-root {
        padding: 0 35px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg - 1}px) {
            padding: 0 20px;
        }
    }
`

export const HeaderLogo = styled.img`
    display: block;
    max-width: 150px;
    object-fit: cover;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-width: 200px;
    }
`;

export const CustomIconButton = styled(IconButton)`
    &.MuiButtonBase-root {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#F2F2F2" : "#1F2128"};
        color: #3a8d1c;
        margin-right: 48px;
        @media screen and (max-width: 899.98px) {
            margin-right: 24px;
        }
        ${({ $black }) =>
        $black &&
        css`
                color: ${({ theme }) =>
                theme.palette.mode === "light" ? "#200E32" : "#fff"};
            `}
    }
`;
export const CustomBadge = styled(Badge)`
    & .MuiBadge-badge {
        min-width: 16px;
        height: 16px;
        padding: 0 4px;
        background-color: #ed1e24;
        right: -8px;
        font-family: "Rubik", sans-serif;
    }
`;
export const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        margin-right: 16px;
    }
    h6 {
        font-family: "Rubik", sans-serif;
        font-size: 17px;
        line-height: 16px;
        font-weight: 500;
        color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#11142D" : "#fff"};
        margin-left: 8px;
        @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            display: none;
        }
    }
`;

export const CustomAvatar = styled(Avatar)`
    &.MuiAvatar-root {
        cursor: pointer;
        background-color: #9b51e0;
        text-transform: uppercase;
        font-size: 14px;
        width: 49px;
        height: 49px;
        flex-shrink: 0;
    }
`;

export const DraweToggler = styled(IconButton)`
    &.MuiButtonBase-root {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #3a8d1c;
        color: #fff;
        position: absolute;
        left: 16px;
        bottom: 0;
        transform: translateY(50%);
        &:hover {
            background-color: #3a8d1c;
        }
        @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            display: none;
        }
    }
`;
