import React, { useState } from "react";
import { Cookies, retrieveData } from "../shared/utility";
import AdvancedImg from "../images/UserTypeSelection/advanced.png";
import IntermediateImg from "../images/UserTypeSelection/intermediate.png";
import NoviceImg from "../images/UserTypeSelection/novice.png";

// Global Variables for API Requests
export let CONTAINER_ID = "";
export const OrganisationalDetailsProcess = "organisationalProcess";
export const HealthCheckProcess = "RapidHealthCheck";
export const RiskMatrixProcess = "riskMatrixProcess";
export const RiskAssessmentProcess = "thirdPartyRiskAssessment";
export const TRAProcess = "tra";
export const RiskAssessment = "riskRegister";
export const Project = "project";
export const Solution = "solution";
export const SolutionBuildingBlock = "solutionBuildingBlock";
export const Threat = "threat";
export const AssessMaturity = "maturityAssessment";
export const VulnerabilitiesProcess = "vulnerabilities";
export const e8MainProcess = "e8MainProcess";
export const e8controlProcess = "e8controlProcess";
export const UserSelectionProcess = "userSelectionProcess";
export const IARmainProcess = "mainIAR";
export const IARadvanceProcess = "advanceIAR";
export const SOAProcessID = "soa";
export const RiskRegisterProcessID = "risk";
export const baseUrl = `https://${process.env.REACT_APP_DOMAIN}.cisozen.com.au/kie-server`;
export const paymentUrlYearly = process.env.REACT_APP_DEPLOYMENT === "PROD" ? "https://buy.stripe.com/5kA5me1kDc859C8002" : "https://buy.stripe.com/test_eVa9BNdX17KM50I5kl";
export const paymentUrlMonthly = process.env.REACT_APP_DEPLOYMENT === "PROD" ? "https://buy.stripe.com/7sI8yq1kD7RPbKgdQT" : "https://buy.stripe.com/test_bIY7tF4mr9SU8cU5kk";
export const paymentUrlInstructor = process.env.REACT_APP_DEPLOYMENT === "PROD" ? "https://buy.stripe.com/28obKC6EX2xvaGcaEI" : "https://buy.stripe.com/test_cN2eW73in0ikbp6bIK";
export const UserTypes = [
    { id: 1, title: "novice", icon: NoviceImg, desc: ["You do not have any prior experience or training in cybersecurity.", "You will require considerable guidance to use CisoZen.", "You will be presented with a simplified view of CisoZen."] },
    { id: 2, title: "intermediate", icon: IntermediateImg, desc: ["You have some prior experience or training in cybersecurity.", "You will require reduced guidance to use CisoZen.", "You will be presented with an enhanced view of CisoZen compared to the novice user."] },
    { id: 3, title: "advanced", icon: AdvancedImg, desc: ["You have substantial prior experience and/or training in cybersecurity.", "You will require minimal guidance to use CisoZen.", "You will be presented with a complete view of CisoZen."] }
]

export const subscriptions = process.env.REACT_APP_DEPLOYMENT === "PROD" ? [
    {
        title: "CisoZen 20 offline service days - A$1,250.00 Paid Monthly",
        paymentLink: "https://buy.stripe.com/3cs9Cufbtgolg0wbIO",
        description: "This option splits the payment into 12 equal parts. This entitles you to 20 days of cybersecurity professional services per year. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others."
    },
    {
        title: "CisoZen 20 offline service days - A$15,000.00 Paid Yearly",
        paymentLink: "https://buy.stripe.com/28obKCaVd0pn5lS3cj",
        description: "This is a yearly subscription that entitles you to 20 days of offline cyber security services once the payment is complete. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others"
    },
    {
        title: "CISOZEN Offline Services - A$667.00 Paid Monthly",
        paymentLink: "https://buy.stripe.com/7sI8yq1kD7RPbKgdQT",
        description: "This option splits the payment into 12 equal parts. This entitles you to 10 days of cybersecurity professional services per year"
    },
    {
        title: "CISOZEN Offline Services - A$8,000.00 Paid Yearly",
        paymentLink: "https://buy.stripe.com/cN215Y6EX3Bz29GfZ3",
        description: "This option is a recurring yearly payment. This entitles you to 10 days of cybersecurity professional services per year. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others"
    },
    {
        title: "CisoZen Instructor led training - A$2,000.00 One Time Payment",
        paymentLink: "https://buy.stripe.com/28obKC6EX2xvaGcaEI",
        description: "Unlimited organisation attendees, covers all features of CisoZen, 8 hours of training with 1 hour break provided by highly skilled and experienced instructor. This training does not require any previous experience within Cyber Security or IT."
    },
] : [
    {
        title: "CisoZen 20 offline service days - A$1,250.00 Paid Monthly",
        paymentLink: "https://buy.stripe.com/test_aEUdS34mr3uw1OwdQU",
        description: "This option splits the payment into 12 equal parts. This entitles you to 20 days of cybersecurity professional services per year. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others."
    },
    {
        title: "CisoZen 20 offline service days - A$15,000.00 Paid Yearly",
        paymentLink: "https://buy.stripe.com/test_fZeaFRbOT4yAgJq6ot",
        description: "This is a yearly subscription that entitles you to 20 days of offline cyber security services once the payment is complete. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others"
    },
    {
        title: "CISOZEN Offline Services - A$667.00 Paid Monthly",
        paymentLink: "https://buy.stripe.com/test_bIY7tF4mr9SU8cU5kk",
        description: "This option splits the payment into 12 equal parts. Each month entitles you to 0.83 days of offline cyber security services. This results in 10 days of services per calendar year"
    },
    {
        title: "CISOZEN Offline Services - A$8,000.00 Paid Yearly",
        paymentLink: "https://buy.stripe.com/test_eVa9BNdX17KM50I5kl",
        description: "This is a yearly subscription that entitles you to 10 days of offline cyber security services once the payment is complete. Some of the services included are: Penetration Testing, Threat & Risk Assessments, and several others"
    },
    {
        title: "CisoZen Instructor led training - A$2,000.00 One Time Payment",
        paymentLink: "https://buy.stripe.com/test_cN2eW73in0ikbp6bIK",
        description: "Unlimited organisation attendees, covers all features of CisoZen, 8 hours of training with 1 hour break provided by highly skilled and experienced instructor. This training does not require any previous experience within Cyber Security or IT."
    },
]

export function setContainerID(value) {
    CONTAINER_ID = value;
}

const AuthContext = React.createContext({
    token: "",
    email: "",
    password: "",
    isLoggedIn: false,
    login: () => { },
    logout: () => { },
});

export const AuthContextProvider = (props) => {
    // const intialToken = Cookies.getCookie("token");
    const intialEmail = Cookies.getCookie("email");
    const intialPassword = Cookies.getCookie("password");

    // const [token, setToken] = useState(intialToken);
    const [email, setEmail] = useState(intialEmail);
    const [password, setPassword] = useState(intialPassword);

    //const userIsLoggedIn = !!token;
    const userIsLoggedIn = email && password;

    const loginHandler = (email, password) => {
        // setToken('temporary toke');
        Cookies.setCookie("token", "temporary toke");
        setEmail(email);
        Cookies.setCookie("email", email);
        setPassword(password);
        Cookies.setCookie("password", password);
    };

    const logoutHandler = () => {
        // setToken(null);
        // Cookies.deleteCookie("token");
        setEmail("");
        Cookies.deleteCookie("email");
        setPassword("");
        Cookies.deleteCookie("password");
    };

    const contextValue = {
        //token: token,
        email: email,
        password: password,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
