import { useState } from 'react';
import dayjs from 'dayjs';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiLoadingButton from "@mui/lab/LoadingButton";

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Table from "../../../../../components/UI/Table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllowFetch } from '../../../../../utility/solution.action';
import SolutionDetailDialog from './SolutionDetailDialog';
import TruncateText from '../../../../../components/TruncateItems/TruncateText';

const SolutionTable = (props) => {

    const dispatch = useDispatch();
    const { fetchingBackgroundData } = useSelector((state) => state.solutions);

    const { tableData, handleOpenModal, createNewCaseLoading, disableActions, disableActionMeta, handleEditClick } = props;

    // ** States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const toggleDetailDialog = (e, data = null) => {
        e?.preventDefault();
        setDialogOpen(prev => !prev);
        setDialogData(data);
    }

    const columns = [
        {
            id: "solutionID",
            header: "Solution ID",
            sort: true,
            width: '120px',
            pinLeft: true,
            sortFn: (a, b) => (a.solutionID || '').localeCompare(b.solutionID || '')
        },
        {
            flex: 1,
            id: "project",
            header: "Project Name",
            sort: true,
            minWidth: '150px',
            sortFn: (a, b) => (a.project?.name || '').localeCompare(b.project?.name || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.name || ''}
                </>
            ),
        },
        {
            id: "projectID",
            header: "Project ID",
            sort: true,
            defaultHidden: true,
            width: '120px',
            sortFn: (a, b) => (a.project?.projectID || '').localeCompare(b.project?.projectID || ''),
            renderCell: ({ row }) => (
                <>
                    {row.project?.projectID || ''}
                </>
            ),
        },
        {
            flex: 1,
            id: "solutionName",
            header: "Solution Name",
            sort: true,
            minWidth: '200px',
            searchable: true,
            sortFn: (a, b) => (a.solutionName || '').localeCompare(b.solutionName || ''),
            renderCell: ({ row }) => {
                return <>
                    <TruncateText
                        text={row["solutionName"] || ''}
                        onClickReadMore={(e) => toggleDetailDialog(e, row)}
                    />
                </>
            }
        },
        {
            flex: 0.5,
            id: "solutionTechLead",
            header: "Solution Tech Lead",
            sort: true,
            minWidth: '180px',
            sortFn: (a, b) => (a.solutionTechLead || '').localeCompare(b.solutionTechLead || '')
        },
        {
            flex: 0.5,
            id: "securityArchitectName",
            header: "Security Architect Name",
            sort: true,
            minWidth: '210px',
            sortFn: (a, b) => (a.securityArchitectName || '').localeCompare(b.securityArchitectName || '')
        },
        {
            flex: 0.5,
            id: "solutionArchitectName",
            header: "Solution Architect Name",
            sort: true,
            minWidth: '210px',
            sortFn: (a, b) => (a.solutionArchitectName || '').localeCompare(b.solutionArchitectName || '')
        },
        {
            flex: 0.5,
            id: "implementationTeam",
            header: "Implementation Team",
            sort: true,
            minWidth: '200px',
            sortFn: (a, b) => (a.implementationTeam || '').localeCompare(b.implementationTeam || '')
        },
        // {
        //     id: "applicableInformationAssets",
        //     header: "Applicable Information Assets",
        //     sort: true,
        //     width: '90px',
        //     sortFn: (a, b) => (a.applicableInformationAssets || '').localeCompare(b.applicableInformationAssets || '')
        // },
        {
            id: "dueDate",
            header: "Due Date",
            sort: true,
            width: '120px',
            sortFn: (a, b) => {
                let dateA = a.dueDate && a.dueDate['java.util.Date'] ? a.dueDate['java.util.Date'] : 0;
                let dateB = b.dueDate && b.dueDate['java.util.Date'] ? b.dueDate['java.util.Date'] : 0;
                return dateA - dateB;
            },
            renderCell: ({ row }) => {
                if (row.dueDate && row.dueDate['java.util.Date']) {
                    return dayjs(new Date(row.dueDate['java.util.Date'])).format('DD/MM/YYYY')
                }
                return "";
            }
        },
        {
            id: 'actions',
            header: '',
            sortable: false,
            disableColumnFilter: true,
            width: '100px',
            pinRight: true,
            renderCell: ({ row }) => (
                <>
                    <MuiLoadingButton
                        aria-label="edit"
                        title="edit"
                        onClick={(e) => handleEditClick(row.caseID, row.status)}
                        as={IconButton}
                        disabled={disableActions}
                        loading={disableActions ? (disableActionMeta?.action === "edit" && disableActionMeta?.caseID === row.caseID) : false}
                    >
                        <EditIcon />
                    </MuiLoadingButton>

                    <IconButton onClick={(e) => toggleDetailDialog(e, row)} aria-label="view" title="view">
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                tableData={tableData}
                columns={columns}
                actionButtons={
                    <>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <MuiLoadingButton
                                aria-label="create new case"
                                title="create new case"
                                as={IconButton}
                                size="small"
                                onClick={handleOpenModal}
                                loading={createNewCaseLoading}
                                disabled={disableActions}
                            >
                                <AddCircleIcon />
                            </MuiLoadingButton>
                            <MuiLoadingButton
                                aria-label="refresh"
                                title="refresh"
                                as={IconButton}
                                size="small"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    dispatch(toggleAllowFetch(true));
                                }}
                                loading={fetchingBackgroundData}
                                disabled={disableActions}
                            >
                                <SyncIcon />
                            </MuiLoadingButton>
                        </Box>
                    </>
                }
            />

            <SolutionDetailDialog
                open={dialogOpen}
                toggle={toggleDetailDialog}
                data={dialogData}
                handleEditClick={handleEditClick}
            />
        </>
    )
}

export default SolutionTable;