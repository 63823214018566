import { CustomIconButton, StyledHeading } from "./Heading.styled";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const Heading = (props) => {
    const { marginMd, marginSm, hasInfo, handleClick } = props;

    let icon = (
        <CustomIconButton onClick={handleClick}>
            <ErrorOutlinedIcon />
        </CustomIconButton>
    );

    return (
        <StyledHeading marginMd={marginMd} marginSm={marginSm}>
            {props.children}
            {hasInfo && icon}
        </StyledHeading>
    );
};

export default Heading;
