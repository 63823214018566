import validator from "validator";

export const validateName = (name) => {
    const validName = new RegExp('^[a-zA-Z\\s]{3,40}$');
    return validName.test(name);
}

export const validateRole = (role) => {
    const validRole = new RegExp('^[a-zA-Z\\s.\'-]{3,30}$');
    return validRole.test(role);
}

export const validateCompany = (company) => {
    const validCompanyName = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9\\s.\'-]{3,60}$');
    return validCompanyName.test(company);
}

export const validateEmail = (email) => {
    return validator.isEmail(email);
}

export const validatePhone = (phone) => {
    return validator.isMobilePhone(phone);
}

export const validateInput = (input) => {
    const validInput = new RegExp('^([a-z]|[A-Z]|[0-9]|[@./ ]|){0,255}$');
    return validInput.test(input);
}