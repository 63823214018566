import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircleLoader, CustomizedCardHeader } from "./Graph.styled";
import { useKeycloak } from '@react-keycloak/web';
import {
  mitigationDefaultColor,
  mitHighlighColor,
  riskNodeColor,
  edgeHighlightColor,
  edgeDefaultColor,
  grey,
  red1,
  apiCall_error,
  mitigation_details_error,
} from "./GraphConfig";
import { Grid } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { CustomDialog, CustomDialogTitle } from "./CustomizedDialog";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import LegendPage from "./Legend";
import APIData from './RiskAndMitigationGraph.json';
import ReactForceGraph2d from "react-force-graph-2d";
import * as d3 from 'd3';
import fetchInstance from "../../../../../utility/fetch-instance";

const theme = createTheme({
  palette: {
    background: {
      paper: 'transparent', // card theme color
    },
  },
});

const RiskGraphPage = () => {
  const { keycloak } = useKeycloak();
  const forceRef = useRef();
  const nodes = [];
  const links = [];
  const baseURL = ""
  const [graph, setGraph] = useState({ nodes, links });
  const [showLoader, setShowLoader] = useState("none");
  const [nodeClicked, setNodeClicked] = useState(null);
  const [highlightNodes, setActiveNodes] = useState(new Set());
  const [highlightLinks, setActiveLinks] = useState(new Set());
  const [extremeNodes, setExtremeNodes] = useState([]);
  const [serverList, setServerList] = useState([])
  const [filter, setFilter] = useState('')
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [top, setTop] = useState(205);
  const [left, setLeft] = useState(75);
  const [open, setOpen] = React.useState(false);
  const [riskDetais, setRiskDetais] = useState([]);
  const [riskTitle, setRiskTitle] = useState("");
  const [showModal, setShowModal] = useState("none");
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessgae, setAlertMessgae] = useState("");
  const [riskCounts, setRiskCounts] = useState({
    extreme: '',
    high: '',
    medium: '',
    low: '',
  });
  const [isRiskOnly, setRiskOnly] = useState(null);

  useEffect(() => {
    setShowLoader("block");
    forceRef.current.zoomToFit();

    let apiHeaders = new Headers();

    let raw = JSON.stringify({});
    //Api call to get risk mitigation graph data      
    // fetch(RiskMitigationGraphBaseURL, requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     let riskMits = result.dmnContext.riskMitigationGraph; //list from api
    //     //riskMits= riskMits.splice(0,100); //for 100 nodes just for testing
    //     nodeCreator(riskMits);
    //     edgeCreator(riskMits); 
    //     setServerList(riskMits); //store api response for further usages
    //     updateLegendCount(riskMits);
    //   })
    //   .catch(error => {
    //     setShowLoader("none");
    //     console.log('error', error)
    //     setAlertMessgae(apiCall_error)
    //     setOpenAlertModal(true);
    //   });


    let riskMits = APIData.dmnContext.riskMitigationGraph; //list from api
    riskMits = riskMits.splice(0, 50); //for 100 nodes just for testing
    nodeCreator(riskMits);
    edgeCreator(riskMits);
    setServerList(riskMits); //store api response for further usages
    updateLegendCount(riskMits);


    //to avoid overlapping of nodes. Higher the charge value farther the nodes will be from each other   
    forceRef.current.d3Force("charge").strength(-3000);//add dynamic value to push further
    forceRef.current.d3Force("link").distance(100);
    forceRef.current.d3Force('collision', d3.forceCollide(50));
  }, [])


  // useEffect(()=>{
  //   forceRef.current.d3Force("charge").strength(-graph.nodes.length*30);
  //   forceRef.current.d3Force("link").distance(graph.nodes.length*5);
  //   forceRef.current.d3Force('collision', d3.forceCollide(4));
  // },[graph])

  //to get the mitigation connection count with  risk   
  function mitigationCal() {
    const serverListCopy = [...serverList]
    let mitList = { ...serverListCopy[0] }; //to add mitigation & mitigation connections
    for (let key in mitList) {
      mitList[key] = 0;
    }
    let mitCount;
    serverListCopy.forEach((element) => {
      for (let key in element) {
        mitCount = mitList[key]; //takes the current mitigation connection count
        if (key !== 'riskID' && key !== "residualRiskRating" && key !== "date") {
          if (element[key] !== null) {
            mitList[key] = mitCount + 1; //inceases the mitigation connection count if matches
          }
        }
      }
    })
    return mitList
  }

  /** to create the node list for the graph */
  function nodeCreator(inputNodes, inputMitList) {
    let mitList;
    if (typeof inputMitList != "undefined") {
      mitList = { ...inputMitList }
    }
    else { mitList = { ...inputNodes[0] }; } //let mitList = {...inputNodes[0]}; //to add mitigation & mitigation connections
    for (let key in mitList) {
      mitList[key] = 0;
    }
    let mitCount; //to check count of the mitigation connection
    var extremeList = [];
    inputNodes.forEach(element => {
      //set node size based on residualRiskRating
      let RiskVal = 10;
      switch (element.residualRiskRating) {
        case "High": RiskVal = RiskVal * 5;
          break;
        case "Medium": RiskVal = RiskVal * 3;
          break;
        case "Low": RiskVal = RiskVal * 1;
          break;
        case "Extreme": RiskVal = RiskVal * 7;
          break;
        default:
      }
      //adding risk nodes
      nodes.push({ id: element.riskID, color: riskNodeColor, label: element.title, val: RiskVal, riskLevel: element.residualRiskRating, date: element.date });

      //adding extreme risks
      if (element.residualRiskRating === "Extereme") {
        extremeList.push(element);
      }

      //adding score to mitigation
      for (let key in element) {
        mitCount = mitList[key]; //takes the current mitigation connection count
        if (key !== 'riskID' && key !== "residualRiskRating" && key !== "date") {
          if (element[key] !== null) {
            mitList[key] = mitCount + 1; //inceases the mitigation connection count if matches
          }
        }
        //}
      }
    })
    setExtremeNodes(extremeList);

    //adding mitigation as node
    const sumValues = Object.values(mitList).reduce((a, b) => a + b, 0);
    //set mitigation node size based on its connection count
    let scale = 0;
    switch (true) {
      case sumValues > 900:
        scale = 50;
        break;
      case sumValues > 600:
        scale = 35;
        break;
      default:
        scale = 10
    }
    /**
     * first get the connection percentage value based on total mitigation connection number for mitigation size
     * add minitigation nodes to node list
     */
    let mitigationTextList = ["Reduce Security Incidents", "Increase Visibility", "Increase Security", "Increase Control", "Reduce Stress", "Reduce Cost", "Reduce Security Incidents", "Increase Visibility", "Increase Security", "Increase Control", "Reduce Stress", "Reduce Cost", "Reduce Security Incidents", "Increase Visibility", "Increase Security", "Increase Control", "Reduce Stress", "Reduce Cost"]
    var idex = 0;
    for (let keyy in mitList) {
      if (mitList[keyy] !== mitList.riskID && mitList[keyy] !== mitList.residualRiskRating && mitList[keyy] !== mitList.date) {
        const connectionPerent = Math.floor((mitList[keyy] / sumValues) * 100);
        nodes.push({ id: keyy, color: mitigationDefaultColor, val: connectionPerent * scale, isMiti: true, label: mitigationTextList[idex] });
        idex++;
      }
    }
    // delete riskid, residualRiskRating and date from mitigation object
    delete mitList.riskID;
    delete mitList.residualRiskRating;
    delete mitList.date;

    setGraph({ ...graph, nodes: nodes, links: links }); //set the graph with updated nodes and links
  }

  /** create the edges/links list for the graph */
  function edgeCreator(node) {
    let i = 0;
    for (const element of node) {
      for (const key of Object.keys(element)) {
        if (key !== 'riskID' && key !== 'residualRiskRating' && key !== 'date') {
          if (element[key] !== null) {
            links.push({
              id: i + 1,
              source: element.riskID,
              target: key,
              hidden: false,
              color: edgeDefaultColor,
              neighbors: []
            });
          }
          i++;
        }
      }
    }
    setGraph({ ...graph, nodes, links });
    setShowLoader('none');
  }

  //to get the risk count of each type of risk
  const updateLegendCount = (nodes) => {
    const riskCounts = nodes.reduce((acc, node) => {
      const count = acc[node.residualRiskRating] || 0;
      return { ...acc, [node.residualRiskRating]: count + 1 };
    }, { Extreme: 0, High: 0, Medium: 0, Low: 0 });

    setRiskCounts({ ...riskCounts, extreme: riskCounts.Extreme, medium: riskCounts.Medium, high: riskCounts.High, low: riskCounts.Low });

  }

  //reset the nodes back to default mode 
  const resetHighLight = () => {
    if (nodeClicked != null) {
      setNodeClicked(null);
      forceRef.current.zoomToFit();
      highlightNodes.clear();
      highlightLinks.clear();

      graph.nodes.forEach((graphnode) => {
        if (graphnode.id.startsWith("Risk")) {
          graphnode.color = riskNodeColor; //set risk nodes back to default
        }
        else {
          graphnode.color = mitigationDefaultColor; //set mitigation nodes back to default
        }
      })

      graph.links.forEach((linknode) => {
        linknode.color = edgeDefaultColor;//set edges back to default color
      })
      setGraph({ ...graph });
      setNodeClicked(null);
    }
  }


  //to highlight the nodes on node Click
  const handleNodeClick = (selectedNode) => {

    highlightNodes.clear();
    highlightLinks.clear();
    resetHighLight();

    setNodeClicked(selectedNode || null);

    // Center/zoom on selected node
    selectedNode.fx = selectedNode.x;
    selectedNode.fy = selectedNode.y;
    selectedNode.fz = selectedNode.z;
    forceRef.current.zoom(1, 10);
    forceRef.current.centerAt(selectedNode.x, selectedNode.y, 10);


    // Highlight links connected to a Risk node
    if (selectedNode?.id.startsWith("Risk")) {

      graph.links.forEach((linknode) => {
        var count = 1;
        if (linknode.source.id === selectedNode.id) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = riskNodeColor;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
          count++;
        } else {
          linknode.source.color = grey;
          if (linknode.target.color === mitigationDefaultColor) {
            linknode.target.color = grey;
          }
          linknode.color = grey;
        }
      });
    }
    // Highlight links connected to a Mitigation node
    else if (selectedNode && !selectedNode.id.startsWith("Risk")) {
      graph.links.forEach((linknode) => {
        if (linknode.target.id === selectedNode.id) {
          linknode.target.color = mitHighlighColor;
          linknode.source.color = red1;
          linknode.color = edgeHighlightColor;
          forceRef.current.emitParticle(linknode);
          highlightLinks.add(linknode);
        } else {
          if (linknode.source.color !== red1) {
            linknode.source.color = grey;
          }
          if (linknode.source.color === riskNodeColor) {
            linknode.source.color = grey;
          }
          linknode.target.color = grey;
          linknode.color = grey;
        }
      });
    }
    // Reset all node colors to grey
    else {
      graph.nodes.forEach((graphnode) => {
        graphnode.color = grey;
      });
    }

    updateHighlight();
    updatePosition(selectedNode);
  };

  //surround the selected node with related nodes
  function updatePosition(node) {
    let tempLinks = new Set();
    let maxNodes = 12;
    let radius = 2 * maxNodes;

    //update the position of connected nodes
    Array.from(highlightLinks).map((link, index) => {
      let multi = index + 1 <= maxNodes ? 1 : Math.ceil(index / maxNodes);
      if (node.id.startsWith('Risk')) {
        link.target.fx = (node.x + (radius * maxNodes * multi) * Math.sin(2 * Math.PI * index / maxNodes));
        link.target.fy = (node.y + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        link.target.fz = (node.z + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
      } else {
        link.source.fx = (node.x + (radius * maxNodes * multi) * Math.sin(2 * Math.PI * index / maxNodes));
        link.source.fy = (node.y + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        link.source.fz = (node.z + (radius * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
      }
      tempLinks.add(link);
    });
    setActiveLinks(tempLinks);
  };

  const updateHighlight = () => {
    setActiveNodes(highlightNodes);
    setActiveLinks(highlightLinks);
  };

  //to filter only extreme risk
  function extremeHandler(e) {
    const serverListCopy = [...serverList];
    const extremeRisks = serverListCopy.filter((ele) => {
      return ele.residualRiskRating === "Extreme"
    })
    if (extremeRisks.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      nodeCreator(extremeRisks);
      edgeCreator(extremeRisks);
      updateLegendCount(extremeRisks);
    }
  }

  //to filter critical mitigation
  function criticalMitigationsFilter(e) {
    const serverListCopy = [...serverList];
    const mitigationsObjCopy = mitigationCal();
    const maxMiti = Object.keys(mitigationsObjCopy).reduce((a, b) => mitigationsObjCopy[a] > mitigationsObjCopy[b] ? a : b);
    const maxMitValue = mitigationsObjCopy[maxMiti];
    const eightyPercent = ((80 * maxMitValue) / 100);
    const absoluteNo = Math.floor(eightyPercent);

    let topMiti = {};
    for (let key in mitigationsObjCopy) {
      if (mitigationsObjCopy[key] >= absoluteNo) { //checking if mitigation in the desired range
        topMiti[key] = mitigationsObjCopy[key]; //if condition matches adding the mitingation
      }
    }

    const newRiskMitiList = []; //making list similar to api response list with topm mitigations

    serverListCopy.forEach((ele) => {
      let localRiskMitigations = {
        riskID: ele.riskID,
        residualRiskRating: ele.residualRiskRating
      }
      for (let key in topMiti) {
        localRiskMitigations[key] = ele[key];
      }
      newRiskMitiList.push(localRiskMitigations)
    })

    const connectedTopRiskMitiList = newRiskMitiList.map((ele) => {
      const tempObj = {};
      for (let key in ele) {
        if (key in topMiti && ele[key] !== null && ele[key] !== "residualRiskRating") {
          tempObj[key] = ele[key]
        }
      }
      if (Object.keys(tempObj).length > 0) {
        return { residualRiskRating: ele.residualRiskRating, riskID: ele.riskID, date: ele.date, ...tempObj }
      }
    })
    const nullFiltered = connectedTopRiskMitiList.filter((ele) => { return ele !== undefined })

    if (newRiskMitiList.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      nodeCreator(nullFiltered, topMiti);
      edgeCreator(nullFiltered,);
      updateLegendCount(nullFiltered);
    }
  }

  //to filter risk added in last 7 days
  function newRiskHandler(e) {
    const serverListCopy = [...serverList];
    const currentMilisecond = new Date().getTime()
    const sevenDayMili = 86400000 * 7
    const lastSevenday = currentMilisecond - sevenDayMili
    const newRisk = serverListCopy.filter((ele) => {
      return ele.date > lastSevenday
    })
    if (newRisk.length === 0) {
      setAlertMessgae("No data available.")
      setOpenAlertModal(true);
    } else {
      nodeCreator(newRisk);
      edgeCreator(newRisk);
      updateLegendCount(newRisk);
    }
    // riskConcentricHandler();
  }

  //to filter risks for concentric cricle
  function riskConcentricHandler(e) {
    const serverListCopy = [...serverList];
    const extremeList = serverListCopy.filter((risk) => {
      return risk.residualRiskRating === "Extreme";
    });
    const highList = serverListCopy.filter((risk) => {
      return risk.residualRiskRating === "High";
    });
    const mediumList = serverListCopy.filter((risk) => {
      return risk.residualRiskRating === "Medium";
    });
    const lowList = serverListCopy.filter((risk) => {
      return risk.residualRiskRating === "Low";
    });

    const sortedList = extremeList.concat(highList).concat(mediumList).concat(lowList);
    nodeCreator(sortedList);
    edgeCreator([]);
    updateLegendCount(sortedList);
    setRiskOnly(true)
  }

  useEffect(() => {
    if (isRiskOnly === true) {
      let maxNodes = 12;
      let radius = 2 * maxNodes;
      let x = 0;
      let y = 0;
      let z = 0;

      var previousRating = "";
      //update the position of connected nodes
      const newNodes = graph.nodes.map((node, index) => {
        if (previousRating === "") previousRating = node.riskLevel;

        var extraRing = 1;
        if (node.riskLevel !== previousRating) {
          previousRating = node.riskLevel;
          extraRing = 2;
        }
        let multi = index + 1 <= maxNodes ? 1 : Math.ceil(index / maxNodes);
        node.fx = (x + (radius * extraRing * maxNodes * multi) * Math.sin(2 * Math.PI * index / maxNodes));
        node.fy = (y + (radius * extraRing * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        node.fz = (z + (radius * extraRing * maxNodes * multi) * Math.cos(2 * Math.PI * index / maxNodes));
        return node;
      });
      setGraph({ nodes: newNodes, links: links });
    }
  }, [isRiskOnly])

  //to set the graph back to default mode
  function resetFilterHandler(e) {
    const serverListCopy = [...serverList];
    nodeCreator(serverListCopy);
    edgeCreator(serverListCopy);
    updateLegendCount(serverListCopy);
  }

  //to handle filter operation
  function handleFilter(e) {
    setFilter(e.target.value);
    if (e.target.value === "extreme") {
      extremeHandler(e);
    } else if (e.target.value === "mitigations") {
      criticalMitigationsFilter(e)
    } else if (e.target.value === "new") {
      newRiskHandler(e)
    } else {
      resetFilterHandler(e)
    }
  }

  //to handle node details
  const onClickViewDetails = () => {
    setShowLoader("block");
    setShowPopup(false);

    let authToken = keycloak.token;

    let apiHeaders = new Headers();
    apiHeaders.append("Content-Type", "application/json");
    apiHeaders.append("Authorization", "Bearer " + authToken);

    if (selectedNode.id.startsWith("Risk")) {
      callRiskDetailsApi(apiHeaders);
    }
    else {
      callMitigationDetailsApi(apiHeaders);
    }
  }

  //to show risk node details
  const callRiskDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_F6FFF2DF-2557-410E-9C01-6076D0831A16",
      "model-name": "RiskDetail",
      "dmn-context": {
        "riskID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };

    setShowLoader("none");
    setShowModal("block");
    let tempData = [{
      "Risk Category": "Confidentiality",
      "High Level Risk Scenario": "In approriate testing of the applications might result in generating incorrect pen test results",
      "Detailed Description of Risk": "While testing an application or a web page, if the applications are not properly configured, it might result in incorrect result( false positive or false negative )",
      "Motivation for Attacker": "High",
      "CIA Class": "C,I,A",
      "System": "Office 365, Staff laptops",
      "Inherent Likelihood": "Likely",
      "Inherent Consequence": "Major",
      "Inherent Risk Rating": "High-4",
      "Threat Actors": "Internal",
      "Current Control Maturity": "2.00",
      "Residual Likelihood": "Possible",
      "Residual Consequence": "Moderate",
      "Residual Risk Rating": "Medium-7",
    }]
    setRiskDetais(tempData);
    setRiskTitle(selectedNode.id);
    setOpen(true);
    setShowLoader("none");

    //Api call to get risk details for selected Risk     
    // fetch(baseURL, requestOptions)
    //   .then(response => response.json())
    //   .then(data => {
    //     setShowLoader("none");
    //     setShowModal("block");
    //     const details = data.result["dmn-evaluation-result"]["dmn-context"].detail;
    //     let temp = [];
    //     if (details !== null) {
    //       temp.push({
    //         "Inherent Risk Rating": details.inherentRiskRating,
    //         "Residual Likelihood": details.residualLikelihood,
    //         "Inherent Consequence": details.inherentConsequence,
    //         "Residual RiskRating": details.residualRiskRating,
    //         "Description": details.description,
    //         "Possible": details.possible
    //       })
    //       setRiskDetais(temp);
    //       setRiskTitle(selectedNode.id);
    //       setOpen(true);
    //     }
    //     else {
    //       setAlertMessgae(risk_details_error)
    //       setOpenAlertModal(true);
    //     }
    //   })
    //   .catch(error => {
    //     setShowLoader("none");
    //     console.log('error', error)
    //     setAlertMessgae(apiCall_error)
    //     setOpenAlertModal(true);
    //   });
  }

  //to show mitigation node details
  const callMitigationDetailsApi = (apiHeaders) => {
    let raw = JSON.stringify({
      "model-namespace": "https://kiegroup.org/dmn/_17F4956C-9B2A-4DD7-A83C-A39BFBA3B527",
      "model-name": "MitigationList",
      "dmn-context": {
        "mitigationID": selectedNode.id
      }
    });

    let requestOptions = {
      method: 'POST',
      headers: apiHeaders,
      body: raw,
      redirect: 'follow'
    };
    let config = {
      url: baseURL,
      ...requestOptions
    }
    //Api call to get risk details for selected mitigation     
    // fetch(baseURL, requestOptions)
    fetchInstance(config)
      .then(response => response.json())
      .then(data => {
        setShowLoader("none");
        setShowModal("block");
        const details = data.result["dmn-evaluation-result"]["dmn-context"].mitigation;
        let temp = [];
        if (details !== null) {
          temp.push({
            "Name": (details.name === undefined || details.name === null) ? "" : details.name,
            "Domain ID": (details.domainID === undefined || details.domainID === null) ? "" : details.domainID,
            "description": (details.description === undefined || details.description === null) ? "" : details.description,
          })
          setRiskDetais(temp);
          setRiskTitle(selectedNode.id);
          setOpen(true);
        }
        else {
          setAlertMessgae(mitigation_details_error)
          setOpenAlertModal(true);
        }
      })
      .catch(error => {
        setShowLoader("none");
        console.log('error', error)
        setAlertMessgae(apiCall_error)
        setOpenAlertModal(true);
      });
  }

  const handleModalClose = () => {
    setOpen(false);
    setShowPopup(false);
    setShowModal("none");
  };

  //to show option to view node details
  const onNodeRightClick = (node, event) => {
    if (node) {
      setShowPopup(true);
      setTop(event.pageY);
      setLeft(event.pageX);
      setSelectedNode(node);
    }
  }

  //to update residual risk rating color based ot is severity
  const getColor = (details) => {
    let color = "white";
    switch (details["Inherent Risk Rating"]) {
      case "Medium": color = "orange";
        break;
      case "Extreme": color = "red";
        break;
      case "High": color = "red";
        break;
      default:
    }
    return color;
  }

  //to add ring around extreme risk nodes
  const paintRing = useCallback((node, ctx) => {
    if (nodeClicked !== null) {
      const isFound = Array.from(highlightLinks).find(link => link.source.id === node.id) ?? false;
      if (isFound) {
        loopAni(node, ctx, riskNodeColor);
      } else {
        loopAni(node, ctx, grey);
      }
    } else {
      loopAni(node, ctx, riskNodeColor);
    }

  }, [nodeClicked]);

  function loopAni(node, ctx, color) {
    node.color = color;
    if (node['rSize'] === undefined) {
      node['rSize'] = 10
      node['rD'] = false
    } else {
      if (node.rD === false) {
        node.rSize = node.rSize + 1;
        if (node.rSize === 40) node['rD'] = true;
      } else if (node.rD === true) {
        node.rSize = node.rSize - 1;
        if (node.rSize === 10) node['rD'] = false;
      }
    }

    //change size of circle
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(node.x, node.y, node.rSize, 0, 2 * Math.PI);
    ctx.fill();

    //change size of ring
    ctx.beginPath();
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.arc(node.x, node.y, node.rSize + 10, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = 4;
    ctx.strokeStyle = color;
    ctx.arc(node.x, node.y, node.rSize + 25, 0, 2 * Math.PI);
    ctx.stroke();

  }

  function random(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
    setAlertMessgae("");
  };




  return (
    <div>
      <CircleLoader style={{ display: showLoader }} />
      <Grid container spacing={1} style={{ paddingRight: "10px" }}>
        <Grid item xs={12} sm={11} md={10} lg={11} xl={11}>

          <ReactForceGraph2d
            backgroundColor="transparent"
            ref={forceRef}
            graphData={graph}
            width={600}
            height={600}
            warmupTicks={200}
            cooldownTicks={1}
            nodeCanvasObjectMode={node => node.riskLevel?.startsWith("Extreme") || node.riskLevel?.startsWith("High") || node.riskLevel?.startsWith("Medium") ? 'after' : null}
            onEngineStop={() => forceRef.current.zoomToFit()}
            nodeLabel={"label"}
            // onNodeClick={handleNodeClick}
            // onBackgroundClick={resetHighLight}
            // onNodeRightClick={onNodeRightClick}
            linkDirectionalParticleColor={() => "red"}
            linkWidth={link => highlightLinks.has(link) ? 3 : 0.5}
            linkDirectionalParticles={2}
            linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 4 : 0}
            linkDirectionalArrowLength={3.5}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.35}
            linkOpacity={0.2}
            nodeCanvasObject={paintRing}
            autoPauseRedraw={true}
            // minZoom={0.2}
            // maxZoom={2}
            enableZoomInteraction={false}
            enablePanInteraction={false}
            enableNodeDrag={false}

            onNodeDragEnd={node => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
            dagMode={'zout'}
            nodeVisibility={(node) => node.id !== "0"}
          />
        </Grid>
      </Grid>

      {/* start of Popover to show option on node right click  */}
      <Popover
        id={'simple-popover'}
        open={showPopup}
        anchorReference="anchorPosition"
        anchorPosition={{ top: top, left: left }}
        onClose={() => { setShowPopup(false); }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <List>
          <ListItem onClick={onClickViewDetails}>View Details</ListItem>
        </List>
      </Popover>
      {/******** end of Popover to show option on node right click  ********/}

      {/* start of dialog show details of selected node  */}
      <CustomDialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={open}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleModalClose}>
          {"Details of " + riskTitle}
        </CustomDialogTitle>
        <DialogContent dividers>
          <Typography>
            <div style={{ display: showModal }}>
              <Grid container spacing={1} >
                {riskDetais.length > 0 ?
                  Object.keys(riskDetais[0]).map((ele) =>
                    <Grid item xs={12} sm={4} md={4} key={ele}>
                      <Card shadow={4} style={{ minWidth: '450', margin: 'auto' }}>
                        <CustomizedCardHeader title={ele} style={{ textAlign: 'center', padding: 0 }} />
                        <CardContent sx={{ minHeight: 100, textAlign: 'center', color: (ele === "Inherent Risk Rating" ? getColor(riskDetais[0]) : "white") }} >
                          {riskDetais[0][ele]}
                        </CardContent>
                      </Card>
                    </Grid>

                  )
                  : ""}
              </Grid>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions />
      </CustomDialog>
      {/******** end of dialog show details of selected node  ********/}

      {/* start of common alert dialog */}
      <CustomDialog
        open={openAlertModal}
        onClose={handleCloseAlertModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Alert"}
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" paddingLeft={2} paddingRight={5}>
            {alertMessgae}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertModal} style={{ color: "#46a11b" }} autoFocus>
            OK</Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
export default RiskGraphPage;