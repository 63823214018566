import React from 'react';
import TextField from '@mui/material/TextField';

const TextValidationTextField = ({ parentIndex, index, value, onChange, data }) => {
  // Input sanitization: Allow only alphanumeric characters and spaces
  const sanitizeInput = (inputValue) => {
    return inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
  };

  // Input validation: Check if the input contains less than 355 characters
  const validateInput = (inputValue) => {
    return inputValue.length < 355;
  };

  // Handle the input change event
  const handleInputChange = (event) => {
    const sanitizedValue = sanitizeInput(event.target.value);
    if (validateInput(sanitizedValue)) {
      onChange(parentIndex, index, sanitizedValue);
    }
  };

  // Check if the current input is invalid
  const hasError = value !== '' && !validateInput(value);

  return (
    <TextField
      disabled={data["isFixed"] || false}
      multiline
      maxRows={7}
      label={`${data["qNum"]}. ${data["qHeading"]}`}
      placeholder={data["placeholder"]}
      variant="outlined"
      value={value}
      onChange={handleInputChange}
      error={hasError}
      helperText={hasError ? 'Exceed the maximum character limit' : ''}
      fullWidth
      required={data["isRequired" || false]}
    />
  );
};

export default TextValidationTextField;
