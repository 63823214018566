import QuestionText from "./QuestionText/QuestionText";
import { QuestionNum, QuestionTitleWrapper } from "./QuestionTitle.styled";

const QuestionTitle = (props) => {
    const { question, qNum } = props;

    /* const [ modalOpened, setModalOpened ] = useState(false);

    const handleClick = () => {
        setModalOpened(true);
    }

    const handleClose = () => {
        setModalOpened(false);
    } */

    return (
        <QuestionTitleWrapper id={`intro-ma-${question.qNum.replaceAll(".", "")}`}>
            <QuestionNum>{qNum}</QuestionNum>
            <QuestionText hasInfo={question.hasDesc}>
                {question.qHeading}
            </QuestionText>
        </QuestionTitleWrapper>
    );
};
export default QuestionTitle;
