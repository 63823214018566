import styled from "styled-components";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { TextField } from "@mui/material";

export const StyledComplateContainer = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: 0px 4px 4px rgba(70, 161, 27, 0.25);
    border-radius: 12px;
    max-width: 800px;
    margin: 100px auto 0;
    text-align: center;
    padding: 20px;

    @media(max-width: 599.98px){
        margin: 20px auto 0;
    };

    & > h1 {
        font-size: 25px;
        font-weight: 600;
        color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
        margin-bottom: 20px;

        @media (max-width: 599.98px) {
            font-size: 20px;
        }
    }
`;

export const ProgressCard = styled.div`
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#fff" : "#1F2128")};
    box-shadow: ${({ theme }) => (theme.palette.mode === "light" ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "")};
    color: ${({ theme }) => (theme.palette.mode === "light" ? "#11142D" : "#fff")};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 15px 16px;
`;

export const ContentContainer = styled.div`
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    max-width: 1280px;
    margin: 0 auto;
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#1F2128"};

    box-shadow: ${({ theme }) =>
        theme.palette.mode === "light"
            ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
            : ""};
    border-radius: 8px;
    padding-top: ${({ pt }) => (pt ? "22px" : "26px")};
    padding-left: ${({ pl }) => (pl ? "40px" : "24px")};
    padding-bottom: ${({ pb }) => (pb ? "24px" : "26px")};
    padding-right: 10px;
    display: flex;
    gap: ${({ gap }) => (gap ? "52px" : "32px")};

    color: #fff;

    margin-top: ${({ mt }) => (mt ? "16px" : "")};

    @media (max-width: 991.98px) {
        max-width: 550px;
        flex-direction: column;
        gap: 18px;
        padding-left: ${({ pl }) => (pl ? "14px" : "16px")};
    }
    @media (max-width: 599.98px) {
        padding-bottom: ${({ pb }) => (pb ? "44px" : "33px")};
    }
`;

export const ImgContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 23px;

    @media (max-width: 991.98px) {
        width: 100%;
    }

    @media (max-width: 599.98px) {
        width: fit-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 14px;
    }
`;

export const SettingsImg = styled.img`
    width: 215px;
    height: 248px;
    object-fit: cover;
    border-radius: 8px;

    @media (max-width: 991.98px) {
        width: 100%;
        height: 248px;
    }

    @media (max-width: 599.98px) {
        width: 160px;
        height: 115px;
    }
`;

export const SettingsInput = styled.input`
    display: none;
`;

export const ImgIcon = styled(CameraAltIcon)`
    color: #ed1e24;
    position: absolute;
    right: 15px;
    top: 13px;
    cursor: pointer;

    @media (max-width: 599.98px) {
        right: 22px;
    }
`;

export const InfoContainer = styled.div`
    flex: 1;
    display: flex;
    margin-top: ${({ mt }) => (mt ? "21px" : "14px")};
    gap: 30px;

    @media (max-width: 991.98px) {
        flex-direction: column;
        gap: 18px;
    }
`;

export const InfoContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 599.98px) {
        gap: 16px;
    }
`;

export const InfoHeading = styled.h3`
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#11142D" : "#fff"};
`;

export const InfoTextContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 500px;
    flex-wrap: wrap;
`;

export const InfoText = styled.h6`
    margin-bottom:5px;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    flex: 1;
    max-width: 300px;
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#2F3B4C" : "#fff"};

    @media (max-width: 599.98px) {
        font-size: 15px;
        max-width: ${({ sw }) => (sw ? "145px" : "185px")};
    }

    @media (max-width: 320px) {
        max-width: ${({ sw }) => (sw ? "120px" : "155px")};
    }
`;

export const InfoParagraph = styled.p`
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#2F3B4C" : "#fff"};

    @media (max-width: 599.98px) {
        font-size: 15px;
    }
`;

export const PersonImg = styled.img`
    max-width: 180px;
    height: 180px;
    object-fit: cover;
`;

export const BtnsContainer = styled.div`
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 32px;

    @media (max-width: 599.98px) {
        gap: 23px;
    }
`;

export const StyledBackdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 999;
`;

export const StyledOverlay = styled.div`
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    background-color: ${({ theme }) => theme.palette.background.default};
    min-height: 200px;
    z-index: 2000;
    border-radius: 8px;
    padding: 32px 28px;
    padding: ${({ padding }) => (padding ? "49px 57px 69px" : "")};
    padding: ${({ paddingHigh }) => (paddingHigh ? "49px 86px 54px" : "")};
    padding: ${({ images }) => (images ? "35px 54px 27px" : "")};

    min-width: ${({ maxWidth }) => (maxWidth ? "730px" : "600px")};

    @media (max-width: 991.98px) {
        min-width: 450px;
    }

    @media (max-width: 599.98px) {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 50%;
        transform: translate(-50%, -50%);

        min-width: 300px;
        padding: 15px 12px;
        padding: ${({ padding }) => (padding ? "49px 16px 67px" : "")};
        padding: ${({ paddingHigh }) => (paddingHigh ? "37px 16px 49px" : "")};
        padding: ${({ images }) => (images ? "32px 15px 39px" : "")};
    }

    @media (max-width: 320px) {
        padding: ${({ images }) => (images ? "8px 28px 16px" : "")};
    }
`;

export const AvatarCenter = styled.div`
    text-align: center;
`;

export const AvatarHeading = styled.h2`
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#4F4F4F" : "#fff"};
    text-align: center;
    margin-bottom: 53px;

    @media (max-width: 599.98px) {
        margin-bottom: 30px;
    }

    @media (max-width: 320px) {
        margin-bottom: 10px;
    }
`;

export const ImgsContainer = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 96px;
    margin-bottom: 40px;
    justify-items: center;

    @media (max-width: 599.98px) {
        gap: 32px 16px;
        margin-bottom: 32px;
    }

    @media (max-width: 320px) {
        gap: 5px;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
    }
`;

export const AvatarImage = styled.img`
    max-width: 100px;
    height: 100px;
    cursor: pointer;
    border: 3px solid transparent;
    border: ${({ borderColor }) =>
        borderColor
            ? ({ theme }) =>
                  theme.palette.mode === "light"
                      ? "4px solid #11142D"
                      : "3px solid #3A8D1C"
            : ""};
    border-radius: 100%;
`;

export const OverlayBtns = styled.div`
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 29px;
`;

export const StyledQuestion = styled.h3`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#4F4F4F" : "#fff"};

    margin-bottom: ${({ pb }) => (pb ? "24px" : "67px")};

    @media (max-width: 599.98px) {
        font-size: ${({ bigSize }) => (bigSize ? "24px" : "20px")};
        margin-bottom: ${({ form }) => (form ? "16px" : "41px")};
    }
`;

export const StyledInput = styled(TextField, {
    shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
    "& label.Mui-focused": {
        color: p.focuscolor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: p.focuscolor,
        },
    },
    input: {
        border: "none",
        boxSizing: "border-box",
        padding: "0 18px",
        backgroundColor: "inherit",
        borderRadius: "4px",
        height: "48px",
        color: `${({ theme }) =>
            theme.palette.mode === "light" ? "#4F4F4F" : "#fff"}`,
    },
    div: {
        width: "482px",
        marginBottom: "24px",
        "@media screen and (max-width: 599.98px)": {
            width: "330px",
        },
        "@media screen and (max-width: 320px)": {
            width: "280px",
        },
    },

    label: {
        fontSize: "16px",
        lineHeight: "19px",
    },
}));

export const StyledInputInfo = styled(TextField, {
    shouldForwardProp: (props) => props !== "focuscolor",
})((p) => ({
    "& label.Mui-focused": {
        color: p.focuscolor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: p.focuscolor,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: p.focuscolor,
        },
    },
    input: {
        border: "none",
        boxSizing: "border-box",
        padding: "0 18px",
        backgroundColor: "inherit",
        borderRadius: "4px",
        height: "48px",
        color: `${({ theme }) =>
            theme.palette.mode === "light" ? "#4F4F4F" : "#fff"}`,
    },
    div: {
        width: "280px",
    },

    label: {
        fontSize: "16px",
        lineHeight: "19px",
    },
}));

export const FormCenter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 59px;

    @media (max-width: 599.98px) {
        margin-bottom: 48px;
    }
`;

export const StyledTextInput = styled.input`
    outline: none;
    border: none;
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;

export const StyledDropDown = styled.select`
outline: none;
    border: none;
    padding: ${({ smPad }) => (smPad ? "16px 10px 16px 0" : "14px 16px")};
    background-color: ${({ theme }) =>
        theme.palette.mode === "light" ? "#fff" : "#000"};
    color: ${({ theme }) =>
        theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff"};
    flex: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: ${({ smPad }) => (smPad ? "14px" : "14px")};

    @media (max-width: 599.98px) {
        width: 100%;
        padding: ${({ acMdWidth }) => (acMdWidth ? "16px 0px 16px 4px" : "")};
    }
`;